<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            v-model:modelValue="modelValue"
            :disabled="disabled"
            manualSortKey="tel"
            :sort="internalSort"
            :loading="loading"
            @update:sort="updateSort($event)"
            showSelect="multiple"
            @click:row="detailRowOnClick"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->

            <template #[`item.status`]="{ item }">
                <c-status :collectionName="PROBATIONARY_ASSESSMENTS_COLLECTION" :id="item.status"></c-status>
            </template>
            <template #[`item.id`]="{ item }">
                <span style="color: #62b1eb">{{ item.id }}</span>
            </template>
            <template #[`item.ss_status`]="{ item }">
                <c-status :collectionName="PROBATIONARY_ASSESSMENTS_COLLECTION" :id="item.ss_status"></c-status>
            </template>

            <template #[`item.asm_status`]="{ item }">
                <c-status :collectionName="PROBATIONARY_ASSESSMENTS_COLLECTION" :id="item.asm_status"></c-status>
            </template>

            <template #[`item.start_date`]="{ item }">
                {{ item.start_date ? dayjs(item.start_date).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>

            <template #[`item.end_date`]="{ item }">
                {{ item.end_date ? formatDate(item.end_date) : '' }}
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'

import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import useImage from '../../../../../shared-fe/composables/use-image'
import { useRecruitmentQuery } from '../../../../../shared-fe/services/recruitment-service'
import { ActionTableModel } from '../../../../../shared-fe/types/table-type'
import { FORMAT_TIME, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { formatMoney } from '../../../../../shared-fe/utils/convert-data'
import { NEGOTIATE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { PROBATIONARY_ASSESSMENTS_COLLECTION } from '../../../../../shared-fe/utils/table-name'
const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}

const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'deleteItem', 'updateSort'])

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 90,
    },
    {
        text: 'ID',
        value: 'id',
        width: 90,
    },
    {
        text: 'Chi nhánh',
        value: 'zone',
        width: 120,
    },
    {
        text: 'Khu vực',
        value: 'area',
        width: 120,
    },
    {
        text: 'Nhà phân phối',
        value: 'system_providers',
    },
    {
        text: 'SS',
        value: 'ss',
    },
    {
        text: 'Mã Route',
        value: 'route_code',
        width: 120,
    },
    {
        text: 'Mã DCR',
        value: 'code',
    },
    {
        text: 'Họ và tên',
        value: 'full_name',
    },
    {
        text: 'Bắt đầu thử việc',
        value: 'start_date',
    },
    {
        text: 'Kết thúc thử việc',
        value: 'end_date',
        width: 170,
    },
    {
        text: 'SS đánh giá',
        value: 'ss_status',
    },
    {
        text: 'ASM đánh giá',
        value: 'asm_status',
    },
    {
        text: 'Kết quả thử việc',
        value: 'status',
    },
])

const detailRowOnClick = (data: any) => {
    router.push({
        name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_DETAIL.NAME,
        params: {
            id: data.item.id,
        },
    })
}

const convertKeySort = (sortKey, desc) => {
    let key = ''
    switch (sortKey) {
        case 'zone':
            key = 'dcr.zone.name'
            break
        case 'area':
            key = 'dcr.area.name'
            break
        case 'system_providers':
            key = 'dcr.system_providers.provider_id.name'
            break
        case 'ss':
            key = 'dcr.parent_id.full_name'
            break
        case 'code':
            key = 'dcr.code'
            break
        case 'route_code':
            key = 'dcr.route_code.name'
            break
        case 'full_name':
            key = 'dcr.full_name'
            break
        case 'start_date':
            key = 'start_date'
            break
        case 'end_date':
            key = 'end_date'
            break
        case 'ss_status':
            key = 'ss_status'
            break
        case 'asm_status':
            key = 'asm_status'
            break
        case 'status':
            key = 'status'
            break
        default:
            key = sortKey
            break
    }
    if (desc) key = `-${key}`
    return key
}
const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    if (sort?.by !== 'stt') {
        emit('updateSort', convertKeySort(sort?.by, sort?.desc))
    }
}

const formatDate = (dateTimeString: string) => {
    if (!dateTimeString.includes("Z"))
        dateTimeString = `${dateTimeString}Z`
    return dayjs(dateTimeString).format(FORMAT_TIME.DDMMYYYY)
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
