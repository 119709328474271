import { useApi } from '@directus/extensions-sdk'
export const useSettingQuery = () => {
    const api = useApi()

    const getProjectSetting = async () => {
        return await api.get('settings', {
            params: {
                fields: [
                    'file_template_ob',
                    'file_template_ww',
                    'holidays',
                    'number_of_month',
                    'provider_wsr',
                    'file_template_wsr',
                ],
            },
        })
    }
    return { getProjectSetting }
}
