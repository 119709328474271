import { defineStore } from 'pinia'
import { Ref, onMounted, ref } from 'vue'
import { useWebSetting } from '../composables/use-web-setting'
import { LIVE_EDITOR } from '../utils/constant'

export const useStoreWebSetting = defineStore('useStoreWebSetting', () => {
    const imageWorkingEnv: Ref<any> = ref({})
    const webSetting = useWebSetting()

    onMounted(async () => {
        if (Object.keys(imageWorkingEnv.value).length === 0) {
            imageWorkingEnv.value = await webSetting.getWebSettingData(LIVE_EDITOR.LD)
        }
    })

    return { imageWorkingEnv }
})
