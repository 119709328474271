<template>
    <div
        data-dropzone
        class="c-upload"
        :class="{ dragging: dragging, uploading }"
        @dragenter.prevent="onDragEnter"
        @dragover.prevent
        @dragleave.prevent="onDragLeave"
        @drop.stop.prevent="onDrop"
    >
        <template v-if="dragging">
            <v-icon class="upload-icon" x-large name="file_upload" />
            <p class="type-label">{{ t('drop_to_upload') }}</p>
        </template>

        <template v-else-if="uploading">
            <p class="type-label">{{ progress }}%</p>
            <p class="type-text">
                {{
                    multiple && numberOfFiles > 1
                        ? t('upload_files_indeterminate', { done: done, total: numberOfFiles })
                        : t('upload_file_indeterminate')
                }}
            </p>
            <v-progress-linear :value="progress" rounded />
        </template>

        <template v-else>
            <div class="text-sm text-[#4B5563] font-medium">Kéo thả file</div>
            <div class="mb-6 mt-1 text-xs text-[#6B7280]">(File tải lên là xlsx)</div>
            <div class="actions">
                <v-button v-tooltip="'Tải file lên'" @click="openFileBrowser">
                    <input
                        ref="input"
                        class="browse"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        :multiple="multiple"
                        @input="onBrowseSelect"
                    />
                    <v-icon class="mr-2" name="file_upload" />
                    <span class="cursor-pointer">Tải file lên</span>
                </v-button>
            </div>
        </template>
    </div>
    <div class="flex flex-col gap-2 mt-3" v-for="(item, index) in props.fileNames" :key="index">
        <div class="text-sm text-[#4B5563] font-italic">
            {{ item }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
    multiple?: boolean
    preset?: Record<string, any>
    fileId?: string
    fileNames?: Array<string>
    /** In case that the user isn't allowed to upload files */
}

const props = withDefaults(defineProps<Props>(), {
    multiple: false,
    fileNames: [],
    preset: () => ({}),
})

const emit = defineEmits(['input', 'error'])
const fileNameData = ref([])

const { t } = useI18n()

const { uploading, progress, upload, onBrowseSelect, done, numberOfFiles } = useUpload()
const { onDragEnter, onDragLeave, onDrop, dragging } = useDragging()
const input = ref<HTMLInputElement>()

function validFiles(files: FileList) {
    if (files.length === 0) return false

    for (const file of files) {
        if (file.size === 0) return false
        if (file.name.split('.').pop() !== 'xlsx') {
            return false
        }
    }

    return true
}

function useUpload() {
    const uploading = ref(false)
    const progress = ref(0)
    const numberOfFiles = ref(0)
    const done = ref(0)

    return { uploading, progress, upload, onBrowseSelect, numberOfFiles, done }

    async function upload(files: FileList) {
        uploading.value = true
        progress.value = 0

        try {
            fileNameData.value = []
            if (!validFiles(files)) {
                throw new Error('Tải lên không thành công. File tải không hợp lệ.')
            }

            numberOfFiles.value = files.length
            for (const file of files) {
                if (file?.name) {
                    fileNameData.value.push(file?.name)
                }
            }
            if (props.multiple === true) {
                Array.from(files) && emit('input', Array.from(files), fileNameData.value)
            } else {
                Array.from(files)[0] && emit('input', Array.from(files)[0], fileNameData.value)
            }
        } catch (err: any) {
            emit('error')
            emit('input', null)
        } finally {
            uploading.value = false
            done.value = 0
            numberOfFiles.value = 0
        }
    }

    function onBrowseSelect(event: Event) {
        const files = (event.target as HTMLInputElement)?.files

        if (files) {
            upload(files)
        }
    }
}

function useDragging() {
    const dragging = ref(false)

    let dragCounter = 0

    return { onDragEnter, onDragLeave, onDrop, dragging }

    function onDragEnter() {
        dragCounter++

        if (dragCounter === 1) {
            dragging.value = true
        }
    }

    function onDragLeave() {
        dragCounter--

        if (dragCounter === 0) {
            dragging.value = false
        }
    }

    function onDrop(event: DragEvent) {
        dragCounter = 0
        dragging.value = false

        const files = event.dataTransfer?.files

        if (files) {
            upload(files)
        }
    }
}

function openFileBrowser() {
    input.value?.click()
}
</script>

<style lang="scss" scoped>
.c-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--input-height-tall);
    padding: 32px;
    text-align: center;
    border: 2px dashed var(--border-normal);
    border-radius: var(--border-radius);
    transition: var(--fast) var(--transition);
    transition-property: color, border-color, background-color;

    p {
        color: inherit;
    }

    &:not(.uploading):hover {
        border-color: var(--border-normal-alt);
    }
}

.actions {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;

    .v-button {
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.browse {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    // appearance: none;
    display: none;
}

.dragging {
    color: var(--primary);
    background-color: var(--primary-alt);
    border-color: var(--primary);

    * {
        pointer-events: none;
    }

    .upload-icon {
        margin: 0 auto;
        margin-bottom: 12px;
    }
}

.uploading {
    --v-progress-linear-color: var(--white);
    --v-progress-linear-background-color: rgb(255 255 255 / 0.25);
    --v-progress-linear-height: 8px;

    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
    border-style: solid;

    .v-progress-linear {
        position: absolute;
        bottom: 30px;
        left: 32px;
        width: calc(100% - 64px);
    }
}
</style>
