<template>
    <div class="flex justify-between flex-row items-center border-1 border-solid border-[#ddd] py-2 px-8 relative">
        <div class="absolute">
            <div class="flex">
                <v-button class="header-icon" rounded icon secondary exact @click="goBack">
                    <v-icon name="arrow_back" />
                </v-button>
                <div class="ml-3" v-if="enableToTest">
                    <v-button icon rounded secondary @click="startEvent()">
                        <v-icon name="manage_history"></v-icon>
                    </v-button>
                </div>
                <div class="ml-3">
                    <v-button icon rounded secondary @click="handleFullScreen">
                        <v-icon name="fullscreen"></v-icon>
                    </v-button>
                </div>
            </div>
        </div>
        <div class="w-full">
            <slot name="boxControl"></slot>
        </div>
        <div class="flex justify-between flex-row items-center absolute right-[20px]">
            <slot name="btnExcuteExam"></slot>
            <div id="time-down" class="ml-6">
                <div class="base-timer">
                    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <g class="base-timer__circle">
                            <circle
                                class="base-timer__path-elapsed"
                                :class="timeLeft === 0 ? 'active' : ''"
                                cx="50"
                                cy="50"
                                r="45"
                            ></circle>
                            <path
                                id="base-timer-path-remaining"
                                :stroke-dasharray="setCircleDasharray()"
                                class="base-timer__path-remaining"
                                :class="setRemainingPathColor(timeLeft)"
                                d="
                    M 50, 50
                    m -45, 0
                    a 45,45 0 1,0 90,0
                    a 45,45 0 1,0 -90,0
                    "
                            ></path>
                        </g>
                    </svg>
                    <span id="base-timer-label" class="base-timer__label">{{ formatTime(timeLeft) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onUnmounted, ref, watch, defineEmits, onMounted } from 'vue'
import { useProcessTrain } from '../../../../../shared-fe/stores/process-train'
import { useRoute } from 'vue-router'
import { Ref } from 'vue'
import { onBeforeUnmount } from 'vue'
import { useUserStore } from '@/stores/user'
import { ENABLE_TO_TEST } from '../../../../../shared-fe/utils/constant'
import { toggleFullScreen, isFullScreen } from '@/shared-fe/utils/convert-data'
const props = defineProps({
    module: {
        type: Object,
        default: {},
    },
})
const emit = defineEmits(['goBack', 'playVideo'])
const route = useRoute()
const userStore = useUserStore()
const FULL_DASH_ARRAY = 283
const WARNING_THRESHOLD = 10
const ALERT_THRESHOLD = 5
const isPaused = ref(false)
let id = route.params?.session_train + '_' + String(route.params?.module_id) + '_' + String(route.params?.provider_id)
let timeLeft = ref(0)
const processTrainStore = useProcessTrain()
let timerInterval = ref(<any>null)
const COLOR_CODES = {
    info: {
        color: 'green',
    },
    warning: {
        color: 'orange',
        threshold: WARNING_THRESHOLD,
    },
    alert: {
        color: 'red',
        threshold: ALERT_THRESHOLD,
    },
}
const TIME_LIMIT = computed(() => {
    processTrainStore.startExam = false
    let timing = localStorage.getItem('SATURDAY_MODULE' + id)
    let _time_left = props.module?.time_left
    timeLeft.value = timing ? Number(timing) : _time_left * 60
    return _time_left * 60
})

const goBack = () => {
    emit('goBack')
}

const handleFullScreen = () => {
    toggleFullScreen()
    emit('playVideo')
}

const startTimer = () => {
    timerInterval.value = setInterval(() => {
        if (timeLeft.value > 0) {
            if (!isPaused.value) {
                timeLeft.value -= 1
            }
        } else {
            processTrainStore.startExam = true
            clearInterval(timerInterval.value)
        }
    }, 1000)
}

document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
        isPaused.value = true
    } else {
        isPaused.value = false
    }
})

const startEvent = () => {
    document.dispatchEvent(new Event('reduce-timer'))
}

const reduceTimer = () => {
    timeLeft.value = 1
}

onMounted(() => {
    document.addEventListener('reduce-timer', reduceTimer)
})

onBeforeUnmount(() => {
    document.removeEventListener('reduce-timer', reduceTimer)
})

watch(
    () => props.module,
    () => {
        clearInterval(timerInterval.value)
        startTimer()
    },
    {
        immediate: true,
    }
)

onUnmounted(() => {
    clearInterval(timerInterval.value)
    localStorage.setItem('SATURDAY_MODULE' + id, String(timeLeft.value))
    return false
})

const confirmExit = (e: any) => {
    localStorage.setItem('SATURDAY_MODULE' + id, String(timeLeft.value))
    return false
}

const enableToTest = computed(() => {
    return ENABLE_TO_TEST.includes(userStore?.currentUser?.source)
})

window.onbeforeunload = confirmExit

const formatTime = (time) => {
    if (!time) {
        return `0:0`
    }
    const minutes = Math.floor(time / 60)
    let seconds: any = time % 60

    if (seconds < 10) {
        seconds = `0${seconds}`
    }
    return `${minutes}:${seconds}`
}

const setRemainingPathColor = (timeLeft) => {
    const { alert, warning, info } = COLOR_CODES
    if (timeLeft <= alert.threshold) {
        return alert.color
    } else if (timeLeft <= warning.threshold) {
        return warning.color
    }
    return info.color
}

const calculateTimeFraction = () => {
    const rawTimeFraction = timeLeft.value / TIME_LIMIT.value
    return rawTimeFraction - (1 / TIME_LIMIT.value) * (1 - rawTimeFraction)
}

const setCircleDasharray = () => {
    return `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`
}
</script>

<style lang="scss">
.header-process-train {
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.04), 0px 4px 6px -1px rgba(0, 0, 0, 0.04);
    position: relative;
    #time-down {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.base-timer {
    position: relative;
    width: 50px;
    height: 50px;
}

.base-timer__svg {
    transform: scaleX(-1);
}

.base-timer__circle {
    fill: none;
    stroke: none;
}

.base-timer__path-elapsed {
    stroke-width: 7px;
    stroke: #1e4ba1;
}
.base-timer__path-elapsed.active {
    stroke-width: 7px;
    stroke: red;
}

.base-timer__label {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: #1e4ba1;
}
</style>
