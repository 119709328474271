import { useApi } from '@directus/extensions-sdk'
import { USER_COLLECTION } from '../utils/table-name'

export const useCommonQuery = () => {
    const api = useApi()

    const deleteItem = async (batchPrimaryKeys: Array<string>, collectionName: string) => {
        return await api.delete(`/items/${collectionName}`, {
            data: batchPrimaryKeys,
        })
    }
    const patchArchiveItem = async (
        ids: Array<string>,
        archiveValue: any,
        archive_field: string,
        collectionName: string
    ) => {
        let tableName = collectionName === USER_COLLECTION ? `/${USER_COLLECTION}` : `/items/${collectionName}`
        return await api.patch(tableName, {
            keys: ids,
            data: {
                [archive_field]: archiveValue,
            },
        })
    }

    const getDetailItem = async (id: string, collectionName: string) => {
        return await api.get(`/items/${collectionName}/${id}`)
    }
    return { deleteItem, patchArchiveItem, getDetailItem }
}
