<template>
    <div class="gap-16 py-12 px-[70px] h-full w-full" :class="progressing.uploading.uploading ? 'hidden' : 'flex'">
        <div class="min-w-[329px]">
            <div
                class="px-4 py-3 text-[#374151] cursor-pointer"
                v-for="item in LIST_TAB_SATURDAY_REPORT"
                :key="item.key"
                :class="keyNumber === item.key ? 'bg-[#F1F5F9]' : ''"
                @click="openTab(item.key)"
            >
                {{ item.title }}
            </div>
            <c-access :roles="[USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2]">
                <div class="horizontal-dash"></div>
                <div
                    class="px-4 py-3 text-[#374151] cursor-pointer mt-2"
                    :class="keyNumber === 7 ? 'bg-[#F1F5F9]' : ''"
                    @click="openTab(7)"
                >
                    Import file Work With
                </div>
            </c-access>
        </div>
        <div class="vertical-dash"></div>
        <div v-if="keyNumber !== 7" class="w-full">
            <div class="flex gap-8 w-full mb-12">
                <div class="w-full">
                    <div class="type-label">Từ ngày</div>
                    <v-menu ref="dateTimeMenuStart" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="formData.start_date_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.start_date ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.start_date_display,
                                            'today-icon': !formData.start_date_display,
                                        }"
                                        v-on="{
                                            click:
                                                formData.start_date && !loadingExcel
                                                    ? unsetValue(e, 'start_date')
                                                    : null,
                                        }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <v-date-picker
                            type="date"
                            :include-seconds="true"
                            :disabled="loadingExcel"
                            :use-24="true"
                            :model-value="formData.start_date"
                            @update:model-value="(e) => handleChange(e, 'start_date')"
                            @close="dateTimeMenuStart?.deactivate"
                        />
                    </v-menu>
                    <div>
                        <span class="message-error" v-if="errors">Vui lòng nhập ngày bắt đầu và ngày kết thúc</span>
                    </div>
                </div>
                <div class="w-full">
                    <div class="type-label">Đến ngày</div>
                    <v-menu ref="dateTimeMenuEnd" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="formData.end_date_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.end_date ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.end_date_display,
                                            'today-icon': !formData.end_date_display,
                                        }"
                                        v-on="{
                                            click:
                                                formData.end_date && !loadingExcel ? unsetValue(e, 'end_date') : null,
                                        }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <v-date-picker
                            type="date"
                            :include-seconds="true"
                            :use-24="true"
                            :disabled="loadingExcel"
                            :model-value="formData.end_date"
                            @update:model-value="(e) => handleChange(e, 'end_date')"
                            @close="dateTimeMenuEnd?.deactivate"
                        />
                    </v-menu>
                    <div>
                        <span class="message-error" v-if="errors">Vui lòng nhập ngày bắt đầu và ngày kết thúc</span>
                    </div>
                </div>
            </div>
            <div class="flex gap-5 justify-end">
                <v-button @click="handleExportExcel" :loading="loadingExcel" :disabled="loadingExcel">
                    <v-icon class="mr-2" name="file_download" />
                    Xuất Excel
                </v-button>
            </div>
        </div>
        <c-access :roles="[USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2]">
            <div v-if="keyNumber === 7" class="w-full">
                <work-with-layout
                    ref="wwRef"
                    :total-success="totalWwSuccess"
                    @import="handleImportWW"
                ></work-with-layout>
            </div>
        </c-access>
    </div>

    <div :class="!progressing.uploading.uploading ? 'hidden' : ''">
        <c-upload-progress title="File Work With" :total="wwRef?.validData.length"></c-upload-progress>
    </div>
    <c-dialog-upload-success
        :is-show="isShowWwSuccess"
        @close="isShowWwSuccess = false"
        :total="totalWwSuccess"
    ></c-dialog-upload-success>
</template>
<script setup lang="ts">
import CDialogUploadSuccess from '@/shared-fe/components/c-dialog-upload-success/c-dialog-upload-success.vue'
import CUploadProgress from '@/shared-fe/components/c-upload-progress/c-upload-progress.vue'
import { useStoreLoading } from '@/shared-fe/stores/loading'
import { useApi } from '@directus/composables'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import {
    CHANNEL,
    EXAM_RESULT_REPORT_STATUS,
    EXAM_RESULT_STATUS,
    FORMAT_TIME,
    MODULE_VALIDATE,
    RECORD_IMPORT_NUMBER,
    RESULT_TRAINING_WW_AND_OB,
    TYPE_OF_TRAIN,
    USER_ROLE,
} from '../../../../../shared-fe/utils/constant'
import { formatDateObjectToQuery, hasSundayInRange, sortData } from '../../../../../shared-fe/utils/convert-data'
import { COURSES_COLLECTION, USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { useWorkWithValidate } from '../composables/useWorkWithValidate'
import WorkWithLayout from './work-with-upload/work-with-layout.vue'
import cAccess from '@/shared-fe/components/c-access/c-access.vue'
const dataDefault = {
    start_date: null,
    start_date_display: null,
    end_date: null,
    end_date_display: null,
}
const itemsExportExcel = ref(<any>[])
const { getAsmForReport } = useUserQuery()
const progressing = useStoreLoading()
const { messageLabels } = useWorkWithValidate()
const dataSurvey = ref(<any>[])
const dataExam = ref(<any>[])
const loadingExcel = ref(false)
const keyNumber = ref(8)
const dateTimeMenuStart = ref()
const dateTimeMenuEnd = ref()
const errors = ref('')
const pageExcel = ref(1)
const wwRef = ref(<any>null)
const totalWwSuccess = ref(0)
const isShowWwSuccess = ref(false)
const openTab = (key) => {
    keyNumber.value = key
}
const PASS = 'Đạt'
const NOT_PASS = 'Không đạt'
const formData = ref(JSON.parse(JSON.stringify(dataDefault)))

const LIST_TAB_SATURDAY_REPORT = computed(() => [
    {
        key: 8,
        title: 'Báo cáo kết quả Onboarding',
    },
    {
        key: 1,
        title: 'Báo cáo khóa học DCR Onboarding',
    },
    {
        key: 2,
        title: 'Chi tiết đánh giá khóa học',
    },
    {
        key: 3,
        title: 'Chi tiết bài thi',
    },
    {
        key: 4,
        title: 'Thống kê số lượt trả lời câu hỏi thi',
    },
    {
        key: 5,
        title: 'Báo cáo E-Learning',
    },
    {
        key: 6,
        title: 'Báo cáo Work With',
    },
])

const handleChange = (e: any, variable: any) => {
    if (!loadingExcel.value) {
        switch (variable) {
            case 'start_date':
                formData.value.start_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
                formData.value[variable] = e || ''
                break
            case 'end_date':
                formData.value.end_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
                formData.value[variable] = e || ''
                break
            default:
                break
        }
    }
}

const unsetValue = (e: any, variable: string) => (e) => {
    e.preventDefault()
    e.stopPropagation()

    switch (variable) {
        case 'start_date':
            formData.value.start_date = ''
            formData.value.start_date_display = ''
            break
        case 'end_date':
            formData.value.end_date = ''
            formData.value.end_date_display = ''
            break
        default:
            break
    }
}

// common data
const getAllTopics = async () => {
    const res = await api.get(`/items/${COURSES_COLLECTION}/1`, {
        params: {
            limit: -1,
            fields: ['topics.id', 'topics.channel', 'topics.title'],
        },
    })

    topicsOff.value = res?.data?.data?.topics?.filter((x) => x.channel == CHANNEL.OFF.value)?.sort()
    topicsOn.value = res?.data?.data?.topics?.filter((x) => x.channel == CHANNEL.ON.value)?.sort()
}
const sessionTrainLists = async () => {
    const params = {
        page: pageExcel.value,
        limit: 100,
        fields: [
            'date_created',
            'id',
            'channel',
            'course.topics.title',
            'course.topics.id',
            'course.topics.channel',
            'course.topics.random_number',
            'course.topics.quiz.limit_time',
            'course.topics.quiz.minimum_score',
            'user.directus_users_id.full_name',
            'user.directus_users_id.last_working_date',
            'user_created.id',
            'user_created.code',
            'user_created.full_name',
            'user_created.email',
            'zone.name',
            'area.name',
            'asm.id',
            'asm.code',
            'asm.full_name',
            'user.directus_users_id.route_code.name',
            'user.directus_users_id.id',
            'user.directus_users_id.code',
            'user.directus_users_id.email',
            'user.directus_users_id.start_date',
            'user.directus_users_id.date_created',
            'user.directus_users_id.resigns.last_working_day',
            'user.directus_users_id.sub_users.start_date',
            'exam_rooms.id',
            'exam_rooms.session_train',
            'exam_rooms.date_created',
            'exam_rooms.topic',
            'exam_rooms.user',
            'exam_rooms.results.status',
            'exam_rooms.results.date_created',
            'exam_rooms.results.total_score',
            'exam_rooms.results.detail',
            'exam_rooms.image.id',
            'exam_rooms.survey_submit',
            'exam_rooms.survey_submit.detail',
            'exam_rooms.user.id',
            'exam_rooms.user.full_name',
            'exam_rooms.user.code',
            'exam_rooms.user.route_code.name',
        ],
        filter: {
            _and: [
                {
                    date_created: {
                        _between: formatDateObjectToQuery(formData.value.start_date, formData.value.end_date),
                    },
                },
                {
                    course: {
                        channel: {
                            type_of_train: {
                                _eq: TYPE_OF_TRAIN.OB
                            }
                        }
                    }
                }
            ],
        },
        sort: ['-user.directus_users_id.resigns.date_created'],
    }
    const res = await api
        .post(`/api-learnings/session_train/report`, params)
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })
    itemsExportExcel.value = itemsExportExcel.value.concat(res.result)
    
    if (res.result.length > 0) {
        pageExcel.value++
        await sessionTrainLists().catch()
    } else {
        pageExcel.value = 1
    }
}
// common data

//////////////////////////////////////////////////////////// Tab 1
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

// format và lấy danh sách dcr chưa onboarding
const dcrHaventOnboard = ref(<any>[])
const dataASM = ref(<any>[])
const getDataASM = async () => {
    let res = await getAsmForReport()
    dataASM.value = res.data.data
}

const getDcrhaventOnboard = async () => {
    const params = {
        page: pageExcel.value,
        limit: 100,
        fields: [
            'parent_id.last_working_date',
            'parent_id.id',
            'parent_id.zone.name',
            'parent_id.code',
            'parent_id.sub_users',
            'parent_id.full_name',
            'parent_id.parent_id.id',
            'parent_id.parent_id.code',
            'parent_id.parent_id.full_name',
            'full_name',
            'route_code.name',
            'id',
            'code',
            'date_created',
            'resigns.last_working_day',
        ],
        filter: {
            _and: [
                {
                    date_created: {
                        _between: formatDateObjectToQuery(formData.value.start_date, formData.value.end_date),
                    },
                },
                {
                    role: {
                        short_name: USER_ROLE.DCR,
                    },
                },
            ],
        },
        sort: ['date_created'],
    }
    const res = await api
        .post(`api-learnings/not_onboarding/report`, params)
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })
    dcrHaventOnboard.value = dcrHaventOnboard.value.concat(res.result)

    if (res.result.length > 0) {
        pageExcel.value++
        await getDcrhaventOnboard().catch()
    } else {
        pageExcel.value = 1
    }
}

const formatDataDcrHaventOnboard = () => {
    if (dcrHaventOnboard.value?.length > 0) {
        let _temp: any = JSON.parse(JSON.stringify(dcrHaventOnboard.value))
        let asm: any = {}
        _temp = _temp.map((x) => {
            asm = dataASM.value?.find((k: any) => k?.id === x?.parent_id?.parent_id?.id)
            return {
                sd_id: '', //sd_id
                sd_name: x?.parent_id?.zone?.name, //sd
                asm_id: asm?.code, //ASM ID
                asm_name: asm?.full_name, //ASM
                ss_code: x?.parent_id?.code, //SS ID
                ss_id: x?.parent_id?.id, //SS ID
                ss_name: x?.parent_id?.full_name, //SS
                route_code: x?.route_code?.name, //Mã DCR
                code: x?.code, //DCR Code
                full_name: x?.full_name, //Họ và tên DCR
                start_date: x?.date_created ? new Date(x.date_created) : '', // Ngày DCR Onboard trên hệ thống
                last_working_day: x?.resigns.length > 0 ? new Date(x?.resigns[0]?.last_working_day) : '', //  Ngày nghỉ việc trên hệ thống,
                ss_last_working_date: x?.parent_id?.last_working_date ? new Date(x?.parent_id?.last_working_date) : '', // ngày làm việc cuối cùng của ss
                sub_users_by_ss: x?.sub_users, // lấy tất cả dcr dưới quyền ss trong khoảng time
            }
        })

        dcrHaventOnboard.value = _temp
    }
}

const addDcrNotOnboardAndOnboard = (data) => {
    let _temp: any = data
    if (_temp?.length > 0 && dcrHaventOnboard.value?.length > 0) {
        let ItemTemplate: any = []
        let idCode = ''
        for (let i = 0; i < _temp?.length; i++) {
            if (idCode === '' || idCode === _temp[i]?.code) {
                idCode = _temp[i]?.code
                ItemTemplate.push(_temp[i])
            }
        }
        dcrHaventOnboard.value.forEach((x) => {
            ItemTemplate.forEach((k) => {
                _temp.push({
                    ...x,
                    training_start_date: '', // Ngày SS bắt đầu huấn luyện Elearning trên hệ thống
                    training_end_date: '', // Hạn hoàn thành huấn luyện Onboard
                    module_title: k?.module_title, // Tên Module
                    schedule: '', // Lịch học
                    module_validity: 'Dcr chưa thực hiện onboard', // Tính hợp lệ của module
                    ss_teaching_day: '', // Ngày SS giảng dạy
                    minimum_score: k?.minimum_score, // chỉ tiêu
                    random_number: k?.random_number, // Số câu hỏi
                    right_answer: '', // Câu trả lời đúng
                    status_result: '', // Kết quả
                    exam_date: '', // Ngày thi
                    point_evaluation: '', // Điểm đánh giá
                    complete_status: 'Chưa thực hiện', // Trạng thái hoàn thành
                    note: '', //  Note
                    image_text: '', // Hình ảnh
                    _hyperlink: {
                        Target: '',
                        Tooltip: '',
                        Display: '',
                    },
                })
            })
        })
    }
    _temp.sort((a: any, b: any) => new Date(a?.start_date).getTime() - new Date(b?.start_date).getTime())
    _temp = _temp.map((x, index) => {
        return {
            ...x,
            no: index + 1,
        }
    })
    return _temp
}

// end format và lấy danh sách dcr chưa onboarding

// Sheet Chi tiết khoá học đã mở
const convertDataList = async () => {
    // await examRoomResultLists()
    let itemsListResult: any = []
    let index = 0
    let no = 1
    let trainingStartDate: any
    let trainingEndDate: any
    let totalScore = ''
    let detailSurvey: any = []
    let countSurveyRateItem = 0
    let totalRateItem = 0
    let totalRate = 0
    let flagFirstSurvey = false
    let listTopicByDcr: any = []
    itemsExportExcel.value.forEach((item_1: any, index_1: any) => {
        const { user, course } = item_1
        let { topics } = course
        // topics = topics.filter((x) => x.channel === item_1?.channel)
        user.forEach((item_2: any, index_2: any) => {
            const { full_name, route_code, code, resigns, date_created } = item_2?.directus_users_id
            listTopicByDcr = []
            topics.forEach((item_3: any, index_3: any) => {
                totalScore = ""
                let statusResult = ''
                let completeStatus = EXAM_RESULT_REPORT_STATUS.incomplete.text_2
                let dateExams: any
                let textImage = ''
                let image = ''
                let dateRoom: any
                let moduleValidity = ''
                if (index_3 == 0 && item_1?.date_created) {
                    trainingEndDate = new Date(date_created)
                    let duration = 14
                    trainingEndDate.setTime(trainingEndDate.getTime() + duration * 24 * 60 * 60 * 1000)

                    trainingStartDate = new Date(item_1?.date_created)
                }

                const roomExams = item_1.exam_rooms.filter(
                    (itemRoom: { topic: any; user: any }) =>
                        itemRoom.topic === item_3.id && itemRoom?.user?.id === item_2?.directus_users_id?.id
                )

                if (roomExams.length > 0) {
                    // nhớ khi chạy source thì check rồi sort lấy room sau cùng
                    const rooms = roomExams.sort(
                        (a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime()
                    )[0]
                    completeStatus = EXAM_RESULT_REPORT_STATUS.completed.text_2
                    dateRoom = rooms?.date_created ? new Date(rooms?.date_created) : ''
                    if (rooms?.image?.id) {
                        // textImage = 'Offline' // TODO
                        image = rooms?.image?.id
                    }
                    if (rooms?.results.length > 0) {
                        const resultsRoom = rooms?.results.sort(
                            (a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime()
                        )[0]
                        dateExams = resultsRoom?.date_created ? new Date(resultsRoom?.date_created) : ''
                        totalScore = resultsRoom?.total_score
                        statusResult = EXAM_RESULT_STATUS[resultsRoom.status]?.text
                    }
                    if (date_created && rooms?.date_created) {
                        const date1 = dayjs(date_created)
                        const date2 = dayjs(rooms?.date_created)
                        const diffInDays = date2.diff(date1, 'day')
                        const resDayExcludeSunday = diffInDays - hasSundayInRange(date1, date2)
                        if (resDayExcludeSunday <= 3 && index_3 === 0) {
                            moduleValidity = MODULE_VALIDATE.VALID.text
                        } else if (resDayExcludeSunday <= 6 && (index_3 === 1 || index_3 === 2)) {
                            moduleValidity = MODULE_VALIDATE.VALID.text
                        } else if (resDayExcludeSunday <= 12) {
                            moduleValidity = MODULE_VALIDATE.VALID.text
                        } else {
                            moduleValidity = MODULE_VALIDATE.INVALID.text
                        }
                    }

                    detailSurvey = []
                    countSurveyRateItem = 0
                    totalRateItem = 0
                    totalRate = 0
                    flagFirstSurvey = false
                    roomExams.forEach((k: any) => {
                        // xử lý đánh giá
                        if (k?.survey_submit?.length > 0 && !flagFirstSurvey) {
                            if (k?.survey_submit[0].detail) {
                                detailSurvey = JSON.parse(k?.survey_submit[0].detail)
                                if (detailSurvey.length > 0) {
                                    countSurveyRateItem = 0
                                    totalRateItem = 0
                                    // chỉ tính điểm đối với câu trả lời có type là rate
                                    detailSurvey.forEach((detail: any) => {
                                        if (detail.type === 'rate') {
                                            countSurveyRateItem++
                                            totalRateItem += +detail?.answer
                                        }
                                    })
                                    // tính trung bình của từng room và cộng dồn vào totalRate
                                    totalRate += Math.floor(totalRateItem / countSurveyRateItem)
                                    flagFirstSurvey = true
                                }
                            }
                        }
                    })
                }
                listTopicByDcr.push({
                    no, //Stt
                    sd_id: '', //sd_id
                    sd_name: item_1?.zone?.name, //sd
                    asm_id: item_1.asm?.code, //ASM ID
                    asm_name: item_1.asm?.full_name, //ASM
                    ss_id: item_1.user_created?.id, //SS ID
                    ss_code: item_1.user_created?.code, //SS ID
                    ss_name: item_1.user_created?.full_name, //SS
                    ss_email: item_1.user_created?.email,
                    route_code: route_code?.name, // Mã DCR
                    code, //DCR Code
                    full_name, //Họ và tên DCR
                    start_date: date_created ? new Date(date_created) : '', // Ngày DCR Onboard trên hệ thống
                    last_working_day: resigns.length > 0 ? new Date(resigns[0]?.last_working_day) : '', //  Ngày nghỉ việc trên hệ thống
                    training_start_date: date_created ? new Date(date_created) : '', // Ngày SS bắt đầu huấn luyện Elearning trên hệ thống
                    training_end_date: trainingEndDate, // Hạn hoàn thành huấn luyện Onboard
                    module_title: item_3.title, // Tên Module
                    module_id: item_3?.id, // module id
                    // topic_channel: item_3.channel == CHANNEL.ON.value ? CHANNEL.ON.text : CHANNEL.OFF.text, // Kênh bán hàng
                    topic_channel: '',
                    schedule: '', // Lịch học
                    module_validity: moduleValidity, // Tính hợp lệ của module
                    ss_teaching_day: dateRoom, // Ngày SS giảng dạy
                    minimum_score: item_3?.quiz?.minimum_score, // chỉ tiêu
                    random_number: item_3?.random_number, // Số câu hỏi
                    right_answer: totalScore, // Câu trả lời đúng
                    status_result: statusResult, // Kết quả
                    exam_date: dateExams, // Ngày thi
                    point_evaluation: totalRate, // Điểm đánh giá
                    complete_status: completeStatus, // Trạng thái hoàn thành
                    note: '', //  Note
                    image_text: textImage, // Hình ảnh
                    _hyperlink: {
                        Target: image ? displayLink(image) : '',
                        Tooltip: 'Click me!',
                        Display: 'View image',
                    },
                    ss_last_working_date: item_1.user_created?.last_working_date
                        ? new Date(item_1.user_created?.last_working_date)
                        : '', // ngày làm việc cuối cùng của ss
                    sub_users_by_ss: item_1.sub_users, // lấy tất cả dcr dưới quyền ss trong khoảng time
                })
            })

            listTopicByDcr.forEach((x) => {
                itemsListResult[index] = {
                    ...x,
                    point_evaluation: listTopicByDcr[listTopicByDcr.length - 1].point_evaluation,
                }
                index++
                no++
            })

            trainingStartDate = ''
            trainingEndDate = ''
            totalScore = ''
        })
    })
    return { itemsListResult }
}
const displayLink = (idFile) => {
    return `${window.location.origin}/assets/${idFile}`
}

const header = [
    'Stt',
    'Location',
    'SD',
    'ASM ID',
    'ASM',
    'SS ID',
    'SS',
    'SS Email',
    'Mã Route',
    'Mã DCR',
    'Họ và tên DCR',
    'Ngày DCR Onboard trên hệ thống',
    'Ngày nghỉ việc trên hệ thống',
    'Ngày SS bắt đầu huấn luyện Elearning trên hệ thống',
    'Hạn hoàn thành huấn luyện Onboard',
    'Tên Module',
    'Kênh bán hàng',
    'Lịch học',
    'Tính hợp lệ của module',
    'Ngày SS giảng dạy',
    'Chỉ tiêu',
    'Số câu hỏi',
    'Câu trả lời đúng',
    'Kết quả',
    'Ngày thi',
    'Điểm đánh giá',
    'Trạng thái hoàn thành',
    'Note',
    'Hình ảnh',
    '',
]

// end sheet chi tiết khoá học đã mở

// sheet DCR
const headerByDCR = [
    'Stt',
    'Location',
    'SD',
    'ASM ID',
    'ASM',
    'SS ID',
    'SS Name',
    'SS Email',
    'Mã Route',
    'Mã DCR',
    'Họ và tên DCR',
    'Kênh bán hàng',
    'Ngày gia nhập',
    'Ngày nghỉ việc',
    'DCR được Onboard E-Learning đúng quy định trong 6 ngày đầu',
    'DCR được Onboard E-Learning đúng theo quy định 12 ngày',
    'Tổng số module tham gia thi',
    'Tổng số module thi đạt',
    'Kết quả thi E-learning',
    'DCR hoàn thành 6 module E-Learning (đạt/ không đạt)',
]
const dataDCROrigin = ref(<any>[])
const genDataSheetDcr = (data: any) => {
    let dataDCR: any = []
    dataDCROrigin.value = []
    if (data?.length > 0) {
        let j = 0
        let no = 0
        let moduleCount = 0
        let flagCount = false
        let countDcrHaveExamResult = 0
        let countDcrPassExam = 0
        let dcrPass3Module = ''
        let dcrPass6Module = ''
        let dcrPassAllModule = ''
        let dcrCompleted = ''
        let arrModuleDcrPass: any = []
        for (let i = 0; i < data.length; i++) {
            j = i

            flagCount = false
            moduleCount = 0
            countDcrHaveExamResult = 0
            countDcrPassExam = 0
            arrModuleDcrPass = []
            while (j < data.length && data[j].code === data[i].code) {
                // đếm số module mà dcr đạt
                if (data[j].module_validity === MODULE_VALIDATE.VALID.text && !flagCount) {
                    // chỉ đếm module đạt liên tục
                    moduleCount++
                } else {
                    flagCount = true
                }

                if (data[j].exam_date !== '') {
                    countDcrHaveExamResult++
                }
                if (data[j].status_result === EXAM_RESULT_STATUS?.completed?.text) {
                    countDcrPassExam++
                    arrModuleDcrPass.push({
                        moduleId: data[j]?.module_id,
                    })
                }
                j++
            }
            dcrPass3Module = moduleCount <= 0 ? '' : moduleCount >= 3 ? PASS : NOT_PASS
            dcrPass6Module = moduleCount <= 0 ? '' : moduleCount >= 6 ? PASS : NOT_PASS
            dcrPassAllModule = countDcrPassExam >= 6 ? PASS : NOT_PASS
            dcrCompleted =
                dcrPass3Module === PASS && dcrPass6Module === PASS && dcrPassAllModule === PASS ? PASS : NOT_PASS
            dataDCR.push([
                no + 1, //STT
                '', //SD ID,
                data[i].sd_name, // SD
                data[i].asm_id, // ASM ID
                data[i].asm_name, // ASM NAME
                data[i].ss_code, // SS ID
                data[i].ss_name, // SS NAME,
                data[i].ss_email, // SS EMAIL,
                data[i].route_code, // Mã route
                data[i].code, // Mã nhân viên
                data[i].full_name, // Họ và tên DCR
                data[i].topic_channel, // Kênh bán hàng
                data[i].start_date, // Ngày gia nhập
                data[i].last_working_day, // Ngày nghỉ việc
                dcrPass3Module, // DCR được Onboard E-Learning đúng quy định trong 6 ngày đầu
                dcrPass6Module, // DCR được Onboard E-Learning đúng theo quy định 12 ngày
                countDcrHaveExamResult, //Tổng số module tham gia thi
                countDcrPassExam, //Tổng số module thi đạt
                dcrPassAllModule, // Kết quả thi E-learning
                dcrCompleted, // DCR hoàn thành 6 module E-Learning (đạt/ không đạt)
            ])
            dataDCROrigin.value.push({
                stt: no + 1, //STT
                sd_id: '', //SD ID,
                sd_name: data[i].sd_name, // SD
                asm_id: data[i].asm_id, // ASM ID
                asm_name: data[i].asm_name, // ASM NAME
                ss_id: data[i].ss_id, // SS ID
                ss_code: data[i].ss_code, // SS ID
                ss_name: data[i].ss_name, // SS NAME
                ss_email: data[i].ss_email, // SS Email
                route_code: data[i].route_code, // Mã route
                code: data[i].code, // Mã nhân viên
                full_name: data[i].full_name, // Họ và tên DCR
                topic_channel: data[i].topic_channel, // Kênh bán hàng
                start_date: data[i].start_date, // Ngày gia nhập
                last_working_day: data[i].last_working_day, // Ngày nghỉ việc
                dcr_pass_3_module: dcrPass3Module, // DCR được Onboard E-Learning đúng quy định trong 6 ngày đầu
                dcr_pass_6_module: dcrPass6Module, // DCR được Onboard E-Learning đúng theo quy định 12 ngày
                dcr_have_exam_result: countDcrHaveExamResult, //Tổng số module tham gia thi
                dcr_pass_exam: countDcrPassExam, //Tổng số module thi đạt
                dcr_pass_all_module: dcrPassAllModule, // Kết quả thi E-learning
                dcr_completed: dcrCompleted, // DCR hoàn thành 6 module E-Learning (đạt/ không đạt)
                ss_last_working_date: data[i].last_working_date, //Ngày nghỉ việc
                arr_module_dcr_pass: arrModuleDcrPass,
            })
            no++
            i = j - 1
        }
    }
    return dataDCR
}
// End sheet DCR

// Sheet SS
const headerBySS: any = ref(<any>[
    'Stt',
    'Location',
    'SD',
    'ASM ID',
    'ASM',
    'SS ID',
    'SS Name',
    'SS Email',
    'Ngày nghỉ việc',
    'Số DCR OB',
    'Số DCR OB kênh OFF',
    'Số DCR OB kênh ON',
    'Số DCR được Onboard E-Learning đúng quy định trong 6 ngày đầu',
    'Tỉ lệ %  DCR được Onboard E-Learning trong  6 ngày đầu',
    'Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 6 ngày đầu',
    'Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 6 ngày đầu',
    'Số lượng DCR được Onboard E-Learning đúng quy định trong 12 ngày',
    'Tỉ lệ %  DCR được Onboard E-Learning đúng quy định 12 ngày',
    'Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 12 ngày đầu',
    'Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 12 ngày đầu',
    'Số DCR thi đạt 6 module Elearning',
    '% Số  DCR thi đạt',
    'DCR hoàn thành 6 module E-Learning (đạt/ không đạt)',
    'DCR kênh OFF hoàn thành 6 module E-Learning (đạt/ không đạt)',
    'DCR kênh ON hoàn thành 6 module E-Learning (đạt/ không đạt)',
])
const customHeaderSS = () => {
    headerBySS.value.push('% Số DCR hoàn thành 6 module E-Learning Kênh OFF')

    topicsOff.value?.forEach((u: any, index: number) => {
        headerBySS.value.push(`Tỉ lệ đạt của DCR OFF tham gia huấn luyện E-Learning (Module ${index + 1})`)
    })

    headerBySS.value.push('% Số DCR hoàn thành 6 module E-Learning Kênh ON')

    topicsOn.value?.forEach((u: any, index: number) => {
        headerBySS.value.push(`Tỉ lệ đạt của DCR ON tham gia huấn luyện E-Learning (Module ${index + 1})`)
    })
}

const dataSsOrigin = ref(<any>[])
const genDataSheetSS = () => {
    let dataSS: any = []
    if (dataDCROrigin.value.length > 0) {
        let ssUnique: any = []
        dataDCROrigin.value.forEach((x) => {
            if (!ssUnique.find((k) => k?.id === x?.ss_id)) {
                ssUnique.push({
                    id: x?.ss_id,
                    code: x?.ss_code,
                    full_name: x?.ss_name,
                })
            }
        })
        if (ssUnique.length > 0) {
            let lstDcr: any = []
            let countDcrOnboard = 0
            let countDcrPass3Module = 0
            let countDcrPass3ModuleOn = 0
            let countDcrPass3ModuleOff = 0
            let percentDcrPass3Module = ''
            let countDcrPass6Module = 0
            let countDcrPass6ModuleOff = 0
            let countDcrPass6ModuleOn = 0
            let percentDcrPass6Module = ''
            let countDcrPassExam = 0
            let percentDcrPassExam = ''
            let dcrCompletedModule = 0
            let dcrCompletedModuleOff = 0
            let dcrCompletedModuleOn = 0
            let percentDcrCompletedModuleOff = ''
            let percentDcrCompletedModuleOn = ''
            let totalDcrOnboardOn = 0
            let totalDcrOnboardOff = 0
            for (let i = 0; i < ssUnique.length; i++) {
                lstDcr = dataDCROrigin.value.filter((x) => x.ss_id === ssUnique[i].id)
                // số dcr OB
                countDcrOnboard = lstDcr.filter((x) => x.dcr_pass_3_module !== '')?.length
                // totalDcrOnboardOn = lstDcr.filter(
                //     (x) => x.dcr_pass_3_module !== '' && x.topic_channel === CHANNEL.ON.text
                // )?.length
                // totalDcrOnboardOff = lstDcr.filter(
                //     (x) => x.dcr_pass_3_module !== '' && x.topic_channel === CHANNEL.OFF.text
                // )?.length
                countDcrPass3Module = 0
                percentDcrPass3Module = ''
                countDcrPass6Module = 0
                percentDcrPass6Module = ''
                countDcrPassExam = 0
                percentDcrPassExam = ''
                dcrCompletedModule = 0
                percentDcrCompletedModuleOff = ''
                percentDcrCompletedModuleOn = ''
                if (countDcrOnboard !== 0) {
                    //  Số DCR được Onboard E-Learning đúng quy định trong 6 ngày đầu,
                    countDcrPass3Module = lstDcr.filter((x) => x.dcr_pass_3_module === PASS)?.length
                    // //  Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 6 ngày đầu
                    // countDcrPass3ModuleOff = lstDcr.filter(
                    //     (x) => x.dcr_pass_3_module === PASS && x.topic_channel === CHANNEL.OFF.text
                    // )?.length
                    // //  Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 6 ngày đầu
                    // countDcrPass3ModuleOn = lstDcr.filter(
                    //     (x) => x.dcr_pass_3_module === PASS && x.topic_channel === CHANNEL.ON.text
                    // )?.length
                    // Tỉ lệ %  DCR được Onboard E-Learning trong  6 ngày đầu,
                    percentDcrPass3Module = String(Math.round((countDcrPass3Module / countDcrOnboard) * 100)) + ' %'
                    // Số lượng DCR được Onboard E-Learning đúng quy định trong 12 ngày,
                    countDcrPass6Module = lstDcr.filter((x) => x.dcr_pass_6_module === PASS)?.length
                    // Tỉ lệ %  DCR được Onboard E-Learning đúng quy định 12 ngày,
                    percentDcrPass6Module = String(Math.round((countDcrPass6Module / countDcrOnboard) * 100)) + ' %'
                    // // Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 12 ngày đầu
                    // countDcrPass6ModuleOff = lstDcr.filter(
                    //     (x) => x.dcr_pass_6_module === PASS && x.topic_channel === CHANNEL.OFF.text
                    // )?.length
                    // // Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 12 ngày đầu
                    // countDcrPass6ModuleOn = lstDcr.filter(
                    //     (x) => x.dcr_pass_6_module === PASS && x.topic_channel === CHANNEL.ON.text
                    // )?.length
                    // Số DCR thi đạt 6 module Elearning,
                    countDcrPassExam = lstDcr.filter((x) => x.dcr_pass_exam === 6)?.length
                    // % Số  DCR thi đạt,
                    percentDcrPassExam = String(Math.round((countDcrPassExam / countDcrOnboard) * 100)) + ' %'
                    // DCR hoàn thành 6 module E-Learning (đạt/ không đạt),
                    dcrCompletedModule = lstDcr.filter((x) => x.dcr_completed === PASS)?.length
                    // // DCR kênh OFF hoàn thành 6 module E-Learning (đạt/ không đạt)
                    // dcrCompletedModuleOff = lstDcr.filter(
                    //     (x) => x.dcr_completed === PASS && x.topic_channel === CHANNEL.OFF.text
                    // )?.length
                    // // DCR kênh ON hoàn thành 6 module E-Learning (đạt/ không đạt)
                    // dcrCompletedModuleOn = lstDcr.filter(
                    //     (x) => x.dcr_completed === PASS && x.topic_channel === CHANNEL.ON.text
                    // )?.length
                    // // % Số DCR hoàn thành 6 module E-Learning Kênh OFF
                    // percentDcrCompletedModuleOff =
                    //     String(Math.round((dcrCompletedModuleOff / countDcrOnboard) * 100)) + ' %'
                    // // % Số DCR hoàn thành 6 module E-Learning Kênh ON
                    // percentDcrCompletedModuleOn =
                    //     String(Math.round((dcrCompletedModuleOn / countDcrOnboard) * 100)) + ' %'
                }

                if (lstDcr.length > 0) {
                    let _itemSS = [
                        i + 1, // NO
                        '', // SD ID,
                        lstDcr[0].sd_name, // SD,
                        lstDcr[0].asm_id, // ASM ID,
                        lstDcr[0].asm_name, // ASM,
                        lstDcr[0].ss_code, // SS ID,
                        lstDcr[0].ss_name, // SS Name,
                        lstDcr[0].ss_email, // SS email,
                        lstDcr[0].ss_last_working_date, // Ngày nghỉ việc,
                        countDcrOnboard, // Số DCR OB,
                        totalDcrOnboardOff, // Số DCR OB kênh OFF,
                        totalDcrOnboardOn, // Số DCR OB kênh ON,
                        countDcrPass3Module, // Số DCR được Onboard E-Learning đúng quy định trong 6 ngày đầu,
                        percentDcrPass3Module, // Tỉ lệ %  DCR được Onboard E-Learning trong  6 ngày đầu,
                        countDcrPass3ModuleOff, // Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 6 ngày đầu
                        countDcrPass3ModuleOn, // Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 6 ngày đầu
                        countDcrPass6Module, // Số lượng DCR được Onboard E-Learning đúng quy định trong 12 ngày,
                        percentDcrPass6Module, // Tỉ lệ %  DCR được Onboard E-Learning đúng quy định 12 ngày,
                        countDcrPass6ModuleOff, // Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 12 ngày đầu
                        countDcrPass6ModuleOn, // Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 12 ngày đầu
                        countDcrPassExam, // Số DCR thi đạt 6 module Elearning,
                        percentDcrPassExam, // % Số  DCR thi đạt,
                        dcrCompletedModule, // DCR hoàn thành 6 module E-Learning (đạt/ không đạt),
                        dcrCompletedModuleOff, //DCR kênh OFF hoàn thành 6 module E-Learning (đạt/ không đạt)
                        dcrCompletedModuleOn, // DCR kênh ON hoàn thành 6 module E-Learning (đạt/ không đạt)
                    ]

                    let _itemSsOrigin = {
                        no: i + 1, // NO
                        sd_id: '', // SD ID,
                        sd: '', // SD,
                        asm_id: lstDcr[0].asm_id, // ASM ID,
                        asm_name: lstDcr[0].asm_name, // ASM,
                        ss_code: lstDcr[0].ss_code, // SS ID,
                        ss_id: lstDcr[0].ss_id, // SS ID,
                        ss_name: lstDcr[0].ss_name, // SS Name,
                        ss_email: lstDcr[0].ss_email, // SS Name,
                        ss_last_working_date: lstDcr[0].ss_last_working_date, // Ngày nghỉ việc,
                        count_dcr_onboard: countDcrOnboard, // Số DCR OB,
                        total_dcr_onboard_off: totalDcrOnboardOff, // Số DCR OB kênh OFF,
                        total_dcr_onboard_on: totalDcrOnboardOn, // Số DCR OB kênh ON,
                        count_dcr_pass_3_module: countDcrPass3Module, // Số DCR được Onboard E-Learning đúng quy định trong 6 ngày đầu,
                        percent_dcr_pas_3_module: percentDcrPass3Module, // Tỉ lệ %  DCR được Onboard E-Learning trong  6 ngày đầu,
                        count_dcr_pass_3_module_off: countDcrPass3ModuleOff, //Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 6 ngày đầu
                        count_dcr_pass_3_module_on: countDcrPass3ModuleOn, // Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 6 ngày đầu
                        count_dcr_pass_6_module: countDcrPass6Module, // Số lượng DCR được Onboard E-Learning đúng quy định trong 12 ngày,
                        percent_dcr_pas_6_module: percentDcrPass6Module, // Tỉ lệ %  DCR được Onboard E-Learning đúng quy định 12 ngày,
                        count_dcr_pass_6_module_off: countDcrPass6ModuleOff, // Số DCR Kênh OFF được Onboard E-Learning đúng quy định trong 12 ngày đầu
                        count_dcr_pass_6_module_on: countDcrPass6ModuleOn, // Số DCR Kênh ON được Onboard E-Learning đúng quy định trong 12 ngày đầu
                        count_dcr_pass_exam: countDcrPassExam, // Số DCR thi đạt 6 module Elearning,
                        percent_dcr_pass_exam: percentDcrPassExam, // % Số  DCR thi đạt,
                        dcr_completed_module: dcrCompletedModule, // DCR hoàn thành 6 module E-Learning (đạt/ không đạt),
                        dcr_completed_module_off: dcrCompletedModuleOff, //DCR kênh OFF hoàn thành 6 module E-Learning (đạt/ không đạt)
                        dcr_completed_module_on: dcrCompletedModuleOn, // DCR kênh ON hoàn thành 6 module E-Learning (đạt/ không đạt)
                    }

                    // Tỉ lệ đạt của DCR OFF tham gia huấn luyện E-Learning
                    _itemSS.push(percentDcrCompletedModuleOff)
                    _itemSsOrigin = { ..._itemSsOrigin, percent_dcr_completed_module_off: percentDcrCompletedModuleOff }

                    topicsOff.value?.forEach((u) => {
                        let countDcrPassModule = lstDcr.filter((x) =>
                            x.arr_module_dcr_pass.find((k) => k.moduleId === u?.id)
                        )?.length
                        let percentDcrPassModule =
                            countDcrPassModule === 0 || countDcrOnboard === 0
                                ? '0%'
                                : String(Math.round((countDcrPassModule / countDcrOnboard) * 100)) + ' %'
                        _itemSS.push(percentDcrPassModule)
                        _itemSsOrigin = { ..._itemSsOrigin, ['percentDcrPassModule' + u?.id]: percentDcrPassModule }
                    })

                    _itemSS.push(percentDcrCompletedModuleOn)
                    _itemSsOrigin = { ..._itemSsOrigin, percent_dcr_completed_module_on: percentDcrCompletedModuleOn }

                    topicsOn.value?.forEach((u) => {
                        let countDcrPassModule = lstDcr.filter((x) =>
                            x.arr_module_dcr_pass.find((k) => k.moduleId === u?.id)
                        )?.length
                        let percentDcrPassModule =
                            countDcrPassModule === 0 || countDcrOnboard === 0
                                ? '0%'
                                : String(Math.round((countDcrPassModule / countDcrOnboard) * 100)) + ' %'
                        _itemSS.push(percentDcrPassModule)
                        _itemSsOrigin = { ..._itemSsOrigin, ['percentDcrPassModule' + u?.id]: percentDcrPassModule }
                    })

                    dataSS.push(_itemSS)
                    dataSsOrigin.value.push(_itemSsOrigin)
                }
            }
        }
    }
    return dataSS
}

// End sheet ss

const generateExcelKey1 = async () => {
    customHeaderSS()

    const { utils, writeFile } = XLSX
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet([header])
    const wsDCR = utils.aoa_to_sheet([headerByDCR])
    const wsSS = utils.aoa_to_sheet([headerBySS.value])
    let { itemsListResult } = await convertDataList()
    itemsListResult = addDcrNotOnboardAndOnboard(itemsListResult)
    // thêm data các dcr chưa được huấn luyện trong khoảng time filter
    const dataSheetDCR = genDataSheetDcr(itemsListResult)
    const dataSheetSS = genDataSheetSS()
    const transformedItems = itemsListResult.map((item) => {
        if (item._hyperlink) {
            const { Target, Tooltip } = item._hyperlink
            const imageLinkCell = [
                item.no,
                item.sd_id,
                item.sd_name,
                item.asm_id,
                item.asm_name,
                item.ss_code,
                item.ss_name,
                item.ss_email,
                item.route_code,
                item.code,
                item.full_name,
                item.start_date,
                item.last_working_day,
                item.training_start_date,
                item.training_end_date,
                item.module_title,
                item.topic_channel,
                item.schedule,
                item.module_validity,
                item.ss_teaching_day,
                item.minimum_score,
                item.random_number,
                item.right_answer,
                item.status_result,
                item.exam_date,
                item.point_evaluation,
                item.complete_status,
                item.note,
                item.image_text,
                {
                    t: 's',
                    l: { Target, Tooltip },
                    v: item._hyperlink.Display,
                },
            ]

            return imageLinkCell
        }

        return null
    })
    utils.sheet_add_aoa(ws, transformedItems, { origin: -1 })
    utils.sheet_add_aoa(wsDCR, dataSheetDCR, { origin: -1 })
    utils.sheet_add_aoa(wsSS, dataSheetSS, { origin: -1 })
    utils.book_append_sheet(wb, ws, 'Chi tiết khoá học đã mở')
    utils.book_append_sheet(wb, wsDCR, 'Theo DCR')
    utils.book_append_sheet(wb, wsSS, 'Theo SS')
    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_Details_of_the_opened_course_onboarding.xlsx'
    )
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////// End Tab 1

//////////////////////////////////////////////////////////// Tab 2
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

const headerDetailSurvey = [
    'STT',
    'Zone',
    'SD',
    'Area',
    'ASM Name',
    'SS ID',
    'SS Name',
    'SS Email',
    'Mã Route',
    'Mã DCR',
    'Họ và tên DCR',
    'Tên khoá học',
    'Ngày mở',
    'Câu hỏi đánh giá',
    'Điểm',
    'Nội dung',
]
const convertDetailSurvey = () => {
    let dataOuput: any = []
    if (itemsExportExcel.value.length > 0) {
        let detailSurvey: any
        itemsExportExcel.value.forEach((x: any) => {
            if (x.exam_rooms?.length > 0) {
                x.exam_rooms.forEach((k: any) => {
                    if (k?.survey_submit?.length > 0 && k?.survey_submit[0].detail) {
                        detailSurvey = JSON.parse(k?.survey_submit[0].detail)
                        detailSurvey.forEach((j: any) => {
                            dataOuput.push({
                                stt: '', // STT
                                zone: x?.zone?.name, // Zone
                                sd: '', // SD
                                area: x?.area?.name,
                                asm_full_name: x?.asm?.full_name, // ASM Name
                                ss_id: x?.user_created?.code, // SS ID
                                ss_full_name: x?.user_created?.full_name, // SS full name
                                ss_email: x?.user_created?.email, // SS email
                                dcr_route: k?.user?.route_code?.name, // Mã DCR
                                dcr_code: k?.user?.code, // Mã DCR
                                dcr_full_name: k?.user?.full_name,
                                course_name: k?.topic?.title, // Tên khoá học
                                open_date: k?.date_created ? new Date(k?.date_created) : '', // Ngày mowr
                                survey_question: j?.title, // Câu hỏi đánh giá
                                point: j?.type === 'rate' ? j?.answer : '', // Điểm
                                content_answer: j?.type !== 'rate' ? j?.answer?.toString() : '', // Nội dung
                            })
                        })
                    }
                })
            }
        })
    }
    dataSurvey.value = []
    if (dataOuput?.length > 0) {
        dataOuput = sortData(dataOuput, 'dcr_full_name')
        dataOuput = dataOuput.map((x: any, index: number) => {
            return {
                ...x,
                stt: index + 1,
            }
        })
        dataSurvey.value = dataOuput
    }
}
const generateExcelKey2 = async () => {
    const { utils, writeFile } = XLSX
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet([headerDetailSurvey])

    utils.sheet_add_aoa(
        ws,
        dataSurvey.value.map((x) => Object.values(x)),
        { origin: -1 }
    )
    utils.book_append_sheet(wb, ws, 'Chi tiết')
    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_Details_Survey_Of_Onboarding_learning.xlsx'
    )
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////// End Tab 2

/////////////////////////////////////////////////////// tab 3
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

const headerDetailExam = [
    'STT',
    'Zone',
    'SD',
    'Area',
    'ASM Name',
    'SS ID',
    'SS Name',
    'SS Email',
    'Mã Route',
    'Mã DCR',
    'Họ và tên DCR',
    'Tên khoá học',
    'Ngày mở',
    'Lần thi',
    'Câu hỏi',
    // 'DCR trả lời',
    // 'Câu trả lời đúng',
    'Kết quả'
]
const convertDetailExam = () => {
    let dataOuput: any = []
    if (itemsExportExcel.value.length > 0) {
        itemsExportExcel.value.forEach((x: any) => {
            if (x.exam_rooms?.length > 0) {
                x.exam_rooms.forEach((k: any) => {
                    if (k?.results?.length > 0) {
                        k?.results.forEach((examResult: any, index: number) => {
                            let detailExam: any
                            if (!examResult.detail) return
                            detailExam = JSON.parse(examResult.detail)
                            if (detailExam && detailExam.length > 0) {
                                detailExam.forEach((j: any) => {
                                    dataOuput.push({
                                        stt: '', // STT
                                        zone: x?.zone?.name, // Zone
                                        sd: '', // SD
                                        area: x?.area?.name,
                                        asm_full_name: x?.asm?.full_name, // ASM Name
                                        ss_id: x?.user_created?.code, // SS ID
                                        ss_full_name: x?.user_created?.full_name, // SS full name
                                        ss_email: x?.user_created?.email, // SS email
                                        dcr_route: k?.user?.route_code?.name, // Mã route
                                        dcr_code: k?.user?.code, // Mã DCR
                                        dcr_full_name: k?.user?.full_name,
                                        course_name: k?.topic?.title, // Tên khoá học
                                        open_date: k?.date_created ? new Date(k?.date_created) : '', // Ngày mowr
                                        number_of_exam: index + 1, // Lần thi
                                        title_question: j?.title_question, // Câu hỏi
                                        // answer_dcr: j?.answers.map((answer: any) => answer.answer).join(', '), // DCR trả lời
                                        // answer_correct:
                                        //     j?.answer_correct?.map((answer: any) => answer).join(', ') || '', // Câu trả lời đúng
                                        result: !j?.is_correct ? 'Sai' : 'Đúng' //// kết quả
                                    })
                                })
                            }
                        })
                    }
                })
            }
        })
    }
    dataExam.value = []
    if (dataOuput.length > 0) {
        dataOuput = sortData(dataOuput, 'dcr_full_name')
        dataOuput = dataOuput.map((x: any, index: number) => {
            return {
                ...x,
                stt: index + 1,
            }
        })
        dataExam.value = dataOuput
    }
}
const generateExcelKey3 = async () => {
    const { utils, writeFile } = XLSX
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet([headerDetailExam])

    utils.sheet_add_aoa(
        ws,
        dataExam.value.map((x) => Object.values(x)),
        { origin: -1 }
    )
    utils.book_append_sheet(wb, ws, 'Chi tiết bài thi')
    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_Details_Exam_Of_onboarding_learning.xlsx'
    )
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// end tab 3

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// tab 8

const headerWordWith = [
    'Tháng tính KPIs',
    'Tháng bắt đầu làm việc',
    'Khu vực',
    'SD',
    'ASM',
    'SS ID',
    'SS',
    'Email SS',
    'SS chịu trách nhiệm PDR',
    'Mã DCR',
    'Mã Route',
    'Tên DCR',
    'Ngày DCR Onboard trên hệ thống',
    'Ngày SS bắt đầu huấn luyện Elearning trên hệ thống',
    'Hạn hoàn thành huấn luyện Onboard',
    'Ngày nghỉ việc trên hệ thống',
    'Số ngày WW',
    'Số ngày học Elearning',
    'Số ngày WW trùng huấn luyện Elearning',
    'Kết quả hệ thống',
    'Kết quả Sales Training',
    'Note',
    'Phân tích lý do không hoàn thành',
    'Kết quả Backcheck',
    'ST in put WW',
    'ST Input Elearning',
    'ST input W trùng Elearning',
    'Kết quả Sales Training',
    'status',
]

const dataWordWith = ref(<any>[])

const getListDcr = async () => {
    return await api.get(`/${USER_COLLECTION}`, {
        params: {
            fields: ['id'],
            filter: {
                date_created: {
                    _between: formatDateObjectToQuery(formData.value.start_date, formData.value.end_date),
                },
                role: {
                    short_name: USER_ROLE.DCR,
                },
            },
            limit: -1,
        },
    })
}

const getDataResultOnboard = async (id: any) => {
    const params = {
        page: pageExcel.value,
        limit: 1,
        fields: ['*'],
    }
    const res = await api
        .get(
            `api-learnings/report/onboarding-result/${id}/${dayjs(dayjs(formData.value.end_date).toISOString()).format(
                FORMAT_TIME.YYYMMDD
            )}`
        )
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            return {
                data: [],
            }
        })

    const x = res?.result
    dataWordWith.value = dataWordWith.value.concat({
        month_kpi: x?.month_kpi, // 'Tháng tính KPIs',
        month_onboarding: x?.month_onboarding, // 'Tháng bắt đầu làm việc',
        dcr_zone: x?.dcr_zone, // 'Khu vực',
        sd: x?.sd, // 'SD',
        asm_name: x?.asm_name, // 'ASM',
        ss_code: x?.ss_code, // 'SS ID',
        ss_name: x?.ss_name, // 'SS',
        ss_email: x?.ss_email, // 'Email SS',
        ss_responsible_pdr: x?.ss_responsible_pdr, // 'SS chịu trách nhiệm PDR',
        dcr_code: x?.dcr_code, // 'Mã DCR',
        dcr_route: x?.dcr_route, // 'Mã Route',
        dcr_name: x?.dcr_name, // 'Tên DCR',
        onboarding_date: x?.onboarding_date ? new Date(x?.onboarding_date) : '', // 'Ngày DCR Onboard trên hệ thống',
        start_training_date: x?.start_training_date ? new Date(x?.start_training_date) : '', // 'Ngày SS bắt đầu huấn luyện Elearning trên hệ thống',
        completed_training_date: x?.completed_training_date ? new Date(x?.completed_training_date) : '', // 'Hạn hoàn thành huấn luyện Onboard',
        last_working_date: x?.last_working_date ? new Date(x?.last_working_date) : '', // 'Ngày nghỉ việc trên hệ thống',
        number_day_ww: x?.number_day_ww, // 'Số ngày WW',
        number_exam_success: x?.number_exam_success, // 'Số ngày học Elearning',
        same_date: x?.same_date, // 'Số ngày WW trùng huấn luyện Elearning',
        result_system: RESULT_TRAINING_WW_AND_OB[x?.result_system]?.interface, // 'Kết quả hệ thống',
        result_sales_training: RESULT_TRAINING_WW_AND_OB[x?.result_sales_training]?.interface, // 'Kết quả Sales Training',
        note: x?.note, // 'Note',
        analyze_reasons_failure: x?.analyze_reasons_failure, // 'Phân tích lý do không hoàn thành',
        backcheck_result: x?.backcheck_result, // 'Kết quả Backcheck',
        work_with: x?.work_with, // 'ST in put WW',
        elearning: x?.elearning, // 'ST Input Elearning',
        duplicate: x?.duplicate, // 'ST input W trùng Elearning',
        result_modify_onboarding: RESULT_TRAINING_WW_AND_OB[x?.result_modify_onboarding]?.interface, // 'Kết quả Sales Training'
        status: x?.status,
    })
}
const getWordWithOfDcr = async () => {
    let dcrs = await getListDcr()
    if (dcrs?.data?.data?.length > 0) {
        const promises = dcrs?.data?.data.map(async (x) => {
            await getDataResultOnboard(x.id)
        })
        await Promise.all(promises)
    }
}

const generateExcelKey8 = async () => {
    const { utils, writeFile } = XLSX
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet([headerWordWith])

    utils.sheet_add_aoa(
        ws,
        dataWordWith.value.map((x) => Object.values(x)),
        { origin: -1 }
    )
    utils.book_append_sheet(wb, ws, 'Báo cáo kết quả OB')
    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_report_result_onboarding.xlsx'
    )
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// end tab 8

//////////////////////////////////////////////////////////// Tab 7
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

// import WW
const runImport = async (data: any[]) => {
    return await api
        .post(`/api-learnings/work-with`, data)
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return _e.response.data
        })
}

const handleImportWW = async (validData: any[] = [], rawData: any[] = []) => {
    if (validData.length > 0) {
        let dataImport = []
        totalWwSuccess.value = validData.length
        progressing.handleUpload(true)
        progressing.handleProgressing(0)
        let totalSuccess = 0
        for (let i = 0; i < validData.length; i += RECORD_IMPORT_NUMBER) {
            dataImport = validData.slice(i, i + RECORD_IMPORT_NUMBER)
            const res = await runImport(dataImport).catch()
            progressing.handleProgressing(i + RECORD_IMPORT_NUMBER)
            if (res?.code === 'success') {
                if (res?.errors && res?.errors.length > 0) {
                    res?.errors.map((error) => {
                        let errorArr = error.split('||')
                        if (errorArr.length > 1) {
                            let dcrCode = errorArr[0]
                            let index = rawData.findIndex((x: any) => x.dcr_code === dcrCode)
                            let message = errorArr[1]
                            Object.keys(messageLabels).map((key: string) => {
                                if (message && message.includes(key)) {
                                    message = message.replace(key, messageLabels[key] || '')
                                }
                            })
                            wwRef?.value?.handleShowError(message, index)
                        }
                    })
                }
                totalSuccess += res?.success.length
            }
        }
        totalWwSuccess.value = totalSuccess
        isShowWwSuccess.value = true
        progressing.handleUpload(false)
    }
}

watch(
    () => isShowWwSuccess.value,
    () => {
        if (isShowWwSuccess.value) {
            setTimeout(() => {
                isShowWwSuccess.value = false
            }, 3000)
        }
    }
)

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// end tab 7

const api = useApi()
const topicsOff: any = ref([])
const topicsOn: any = ref([])
const handleExportExcel = async () => {
    dataSurvey.value = []
    itemsExportExcel.value = []
    dcrHaventOnboard.value = []
    dataASM.value = []
    topicsOff.value = []
    topicsOn.value = []
    errors.value = ''
    dataWordWith.value = []
    if (!formData.value.end_date || !formData.value.start_date) {
        errors.value = 'error'
        return
    }
    loadingExcel.value = true
    await sessionTrainLists()
    await getAllTopics()

    switch (keyNumber.value){
        //Báo cáo khóa học DCR Onboarding
        case 1:
            await getDcrhaventOnboard()
            await getDataASM()
            formatDataDcrHaventOnboard()
            await generateExcelKey1()
            break;
        // Chi tiết đánh giá khóa học
        case 2:
            convertDetailSurvey()
            await generateExcelKey2()
            break;
        // Chi tiết bài thi
        case 3:
            convertDetailExam()
            await generateExcelKey3()
            break;
        // Thống kê số lượt trả lời câu hỏi thi
        case 4:
            break;
        // Báo cáo E-Learning
        case 5:
            break;
        // Báo cáo Work With
        case 6:
            break;
        // Báo cáo kết quả Onboarding
        case 8:
            await getWordWithOfDcr()
            await generateExcelKey8()
            break;
    }

    loadingExcel.value = false
}
</script>

<style lang="scss" scoped>
.vertical-dash {
    width: 1px;
    background-color: #e2e8f0;
}
.horizontal-dash {
    height: 1px;
    background-color: #e2e8f0;
}
</style>
