import { useExamResultQuery } from '../services/exam-results-service'
import { useTrainingQuery } from '../services/training-service'
import { ExamResultsItem } from '../types/exam-result'
import { EXAM_STATUS } from '../utils/constant'

export default function useExamResult() {
    const { getItemExamResultList, getItemExamResultsDetail } = useExamResultQuery()
    const { getDataSessionTrainSaturday } = useTrainingQuery()
    const getExamResultList = async (examRoom: number, userId: string) => {
        const response = await getItemExamResultList(examRoom, userId)
        let result: ExamResultsItem[] = []
        if (response.data && response.data.length > 0) {
            const res = response.data

            res.map((item: any, index: number) => {
                result.push({
                    ...item,
                    is_pass: item.status === EXAM_STATUS.PASS ? true : false,
                    total_incorrect_score: item.quiz?.questions.length - item?.total_score,
                    examinations: index,
                })
            })
        }
        return result
    }

    const getExamResultDetail = async (id: number, user_id: string) => {
        const res = await getItemExamResultsDetail(id)
        let result: ExamResultsItem = {
            id: 0,
            date_created: '',
            detail: '',
            exam_room: 0,
            status: '',
            title: '',
            total_score: 0,
            total_time: 0,
            is_pass: false,
            total_incorrect_score: 0,
            examinations: 0,
            questions: [],
        }

        if (res.data?.data) {
            const times = await getItemExamResultList(res.data?.data?.exam_room.id, user_id)

            let idx = 0
            if (times.data?.data && times.data?.data.length > 0) {
                idx = times?.data.data.findIndex((x: any) => x.id === res?.data?.data.id)
            }
            let questions = []
            if (
                res.data?.data?.quiz?.questions &&
                res.data?.data?.quiz?.questions.length > 0 &&
                res.data?.data?.detail
            ) {
                const dataQuestion = res?.data.data.quiz.questions
                const detail = JSON.parse(res?.data.data.detail)

                questions = detail.map((item: any) => {
                    let answerChosen = []
                    const questionDetail = dataQuestion.find((x: any) => x.id === item.id)

                    if (questionDetail) {
                        answerChosen = questionDetail.answers.map((answer: any) => {
                            const answerData = item.answers.find((x: any) => x.answer === answer.answer)
                            if (answerData) {
                                return { ...answerData, isChosen: true }
                            }
                            return { ...answer }
                        })
                    }
                    const noAnswer = answerChosen.find((x: any) => x.isChosen)

                    return {
                        id: questionDetail.id,
                        explain: questionDetail.explain,
                        question: questionDetail.question,
                        answers: answerChosen,
                        noAnswer: !noAnswer ? true : false,
                    }
                })
            }
            let totalQuestions = 0
            if (res?.data?.data?.exam_room?.topic?.random_number) {
                if (res?.data?.data?.exam_room?.topic?.random_number <= res?.data?.data.quiz?.questions.length) {
                    totalQuestions = Number(res?.data?.data?.exam_room?.topic?.random_number)
                } else {
                    totalQuestions = res?.data?.data.quiz?.questions.length
                }
            } else {
                totalQuestions = res?.data?.data.quiz?.questions.length
            }

            result = {
                ...res?.data.data,
                title: res?.data.data.exam_room.topic.title,
                is_pass: res?.data.data.status === EXAM_STATUS.PASS ? true : false,
                percent_total_score: Math.round(
                    (res?.data.data?.total_score * 100) / res?.data.data?.quiz?.questions.length
                ),
                total_incorrect_score: totalQuestions - res?.data.data?.total_score,
                examinations: idx + 1,
                questions: questions,
            }
        }

        return result
    }

    const getCurrentSaturdaySessionTrain = async () => {
        const data = await getDataSessionTrainSaturday();
        if (data.data.data && data?.data?.data?.length > 0) {
            return data.data.data
        }
        return null
    }

    return {
        getExamResultList,
        getExamResultDetail,
        getCurrentSaturdaySessionTrain,
    }
}
