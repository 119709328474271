<template>
	<svg
		viewBox="0 0 24 24"
		width="24"
		height="24"
		fill="none"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.1875 6L11.1562 8.01562H20.0156V18H3.98438V6H9.1875ZM9.98438 3.98438H3.98438C2.90625 3.98438 2.01562 4.92188 2.01562 6V18C2.01562 19.0781 2.90625 20.0156 3.98438 20.0156H20.0156C21.0938 20.0156 21.9844 19.0781 21.9844 18V8.01562C21.9844 6.89062 21.0938 6 20.0156 6H12L9.98438 3.98438Z"
		/>
		<rect x="13" y="12" width="6" height="5" rx="1" />
		<rect x="17" y="11" width="1" height="1" />
		<rect x="14" y="11" width="1" height="1" />
		<path
			d="M17 11H18C18 9.89543 17.1046 9 16 9C14.8954 9 14 9.89543 14 11H15C15 10.4477 15.4477 10 16 10C16.5523 10 17 10.4477 17 11Z"
		/>
	</svg>
</template>

<script lang="ts">
export default {};
</script>
