import { FORMAT_TIME } from '@/shared-fe/utils/constant'
import { ageValidation, isValidDate, parseToValidDate, toLowerCaseNonAccentVietnamese } from '@/shared-fe/utils/convert-data'
import dayjs from 'dayjs'
import Joi from 'joi'

export const useImportWsrValidate = () => {
    const columnLabels = {
        id: 'stt',
        zone: 'chi nhanh',
        asm_name: 'asm',
        asm_code: 'ma asm',
        asm_email: 'email asm',
        ss: 'ss',
        ss_code: 'ma ss',
        ss_email: 'email ss',
        route_code: 'ma route',
        dcr_code: 'ma dcr',
        full_name: 'ho va ten',
        date_of_birth: 'ngay sinh',
        gender: 'gioi tinh',
        phone_number: 'so dien thoai',
        phone_number_zalo: 'so dien thoai su dung zalo',
        email: 'email',
        education_level: 'trinh do hoc van',
        married: 'tinh trang hon nhan',
        id_card_number: 'cccd',
        id_card_date: 'ngay cap',
        id_card_place: 'noi cap',
        address: 'dia chi',
        district: 'quan / huyen',
        province: 'tinh / thanh pho',
        start_date: 'ngay bat dau',
        last_working_date: 'ngay lv cuoi cung',
        employment_status: 'trang thai',
        note: 'ghi chu'
    }
    const messageLabels = {
        zone: 'Cột chi nhánh',
        asm_name: 'Cột ASM',
        asm_code: 'Cột mã ASM',
        asm_email: 'Cột email ASM',
        ss: 'Cột SS',
        ss_code: 'Cột Mã SS',
        ss_email: 'Cột Email SS',
        route_code: 'Cột Mã route',
        dcr_code: 'Cột Mã DCR',
        full_name: 'Cột Họ và Tên',
        date_of_birth: 'Cột Ngày sinh',
        gender: 'Cột Giới tính',
        phone_number: 'Cột Số điện thoại',
        phone_number_zalo: 'Cột Số điện thoại sử dụng Zalo',
        email: 'Cột Email',
        education_level: 'Cột Trình độ học vấn',
        married: 'Cột tình trạng hôn nhân',
        id_card_number: 'Cột CCCD',
        id_card_date: 'Cột Ngày cấp',
        id_card_place: 'Cột Nơi cấp',
        address: 'Cột Địa chỉ',
        district: 'Cột Quận / Huyện',
        province: 'Cột Tỉnh / Thành phố',
        start_date: 'Cột Ngày bắt đầu',
        last_working_date: 'Cột Ngày lv cuối cùng',
        employment_status: 'Cột Trạng thái',
    }
    const customMessages = {
        'number.base': 'lỗi định dạng',
        'string.base': 'lỗi định dạng',
        'string.empty': 'đang trống dữ liệu',
        'any.required': 'đang trống dữ liệu',
    }

    const schema = Joi.object({
        zone: Joi.string().required().messages(customMessages),
        asm_name: Joi.string().required().messages(customMessages),
        asm_code: Joi.string().required().messages(customMessages),
        asm_email: Joi.string().required().messages(customMessages),
        ss: Joi.string().required().messages(customMessages),
        ss_code: Joi.string().required().messages(customMessages),
        ss_email: Joi.string().required().messages(customMessages),
        route_code: Joi.string().required().messages(customMessages),
        dcr_code: Joi.string().required().messages(customMessages),
        full_name: Joi.string().required().messages(customMessages),
        date_of_birth: Joi.any().required().messages(customMessages),
        gender: Joi.string().required().messages(customMessages),
        phone_number: Joi.string().required().messages(customMessages),
        phone_number_zalo: Joi.string().allow('').messages(customMessages),
        email: Joi.string().allow('').messages(customMessages),
        education_level: Joi.string().allow('').messages(customMessages),
        married: Joi.string().allow('').messages(customMessages),
        id_card_date: Joi.any().required().messages(customMessages),
        id_card_place: Joi.string().required().messages(customMessages),
        address: Joi.string().required().messages(customMessages),
        district: Joi.string().required().messages(customMessages),
        province: Joi.string().required().messages(customMessages),
        start_date: Joi.any().required().messages(customMessages),
        employment_status: Joi.string().required().messages(customMessages),
    }).unknown(true)

    const genderArray = ['nam', 'nu']
    const marriedArray = ['doc than', 'da ket hon']
    const employmentStatusArray = ['on', 'off', 'process resign']

    const getIndexOfColumnDependOnHeader = (headers: string[], columnName: string) => {
        const rawHeader = headers.map((header: string) => {
            return header ? header.split("(")[0]?.trim() : ''
        })
        let columnNameAscii = toLowerCaseNonAccentVietnamese(columnName.trim())
        return rawHeader.indexOf(columnNameAscii)
    }

    const validate = (formData: any) => {
        const { error } = schema.validate(formData, { abortEarly: false })
        let messages: string[] = []
        if (error?.details) {
            error.details.map((detail) => {
                const customLabel = messageLabels[detail.context.key]
                messages.push(`\"${customLabel} ${detail.message}\"`)
            })
        }

        const { phone_number, phone_number_zalo, date_of_birth, id_card_date, gender, married, id_card_number, start_date, last_working_date, employment_status } = formData
        if (phone_number && `${phone_number}`.trim().length !== 10) {
            messages.push("Cột số điện thoại lỗi định dạng")
        }
        if (phone_number_zalo && `${phone_number_zalo}`.trim().length !== 10) {
            messages.push("Cột số điện thoại sử dụng Zalo lỗi định dạng")
        }
        if (date_of_birth) {
            if (!isValidDate(date_of_birth)) {
                messages.push("Cột Ngày sinh lỗi định dạng")
            } else if (!ageValidation(new Date(parseToValidDate(date_of_birth).format(FORMAT_TIME.DDMMYYYY)))) {
                messages.push("Cột Ngày sinh DCR chưa đủ 18 tuổi")
            }
        }

        if (id_card_date && !isValidDate(id_card_date)) {
            messages.push("Cột Ngày cấp lỗi định dạng")
        }
        if (start_date && !isValidDate(start_date)) {
            messages.push("Cột Ngày bắt đầu lỗi định dạng")
        }
        if (last_working_date && !isValidDate(last_working_date)) {
            messages.push("Cột Ngày lv cuối cùng lỗi định dạng")
        }
        if (gender && !genderArray.includes(toLowerCaseNonAccentVietnamese(gender.trim()))) {
            messages.push("Cột giới tính lỗi định dạng")
        }
        if (married && !marriedArray.includes(toLowerCaseNonAccentVietnamese(married.trim()))) {
            messages.push("Cột Tình trạng hôn nhân lỗi định dạng")
        }
        if (employment_status && !employmentStatusArray.includes(toLowerCaseNonAccentVietnamese(employment_status.trim()))) {
            messages.push("Cột Trạng thái lỗi định dạng")
        }
        if (id_card_number && (id_card_number.trim().length !== 9 && id_card_number.trim().length !== 12)) {
            messages.push("Cột CCCD lỗi định dạng")
        }

        return {
            error: !!error || messages.length > 0,
            data: messages,
        }
    }

    return {
        messageLabels,
        schema,
        validate,
        columnLabels,
        getIndexOfColumnDependOnHeader,
    }
}
