<template>
    <div class="flex flex-col items-start justify-center p-4 bg-[#F8FAFC]">
        <v-breadcrumb
            :items="[
                { icon: 'local_library', to: '/ld' },
                { name: `${labelUserType} Onboarding `, to: '/ld' },
                {
                    name: 'Danh sách bài giảng',
                    to: PATH_ROUTER.MODULE_LIST.PATH.replaceAll(':channel', String(props.channel)),
                },
            ]"
        />
        <div class="py-7">
            <h1 class="font-bold text-2xl text-[#1939AE]">Bài giảng và tải giáo án PDF</h1>
        </div>
        <div class="w-full h-full px-6 py-10 grid grid-cols-1 card-list xl:grid-cols-3 md:grid-cols-2 gap-8">
            <!-- <div class="col-span-1 mb-5 w-11/12 h-full" > -->
            <div
                class="col-span-1 custom-v-card mb-5 bg-v-card hover:shadow-xl flex flex-col"
                v-for="item in props.lstModule"
            >
                <div>
                    <img
                        v-if="item?.image"
                        class="object-cover aspect-16/9 w-full"
                        :src="usedImage.idToUrl(item?.image)"
                        alt="image"
                    />
                    <div v-else class="h-[208px]"></div>
                </div>
                <div class="px-4">
                    <div class="text-[#1E293B] font-semibold text-base py-5">{{ item?.title }}</div>
                    <div class="bg-[#F1F5F9] my-5 py-1 px-2 w-max text-[#475569] font-medium">
                        Cập nhật ngày: {{ dayjs(item?.date_updated).format(FORMAT_TIME.DDMMYYYY) }}
                    </div>
                    <div class="icon-list text-[#1E293B] my-5">
                        <v-icon class="text-[#5BABAC]" name="schedule" />
                        Thời gian thi: {{ item?.quiz?.limit_time }}
                    </div>
                    <div class="icon-list text-[#1E293B] my-5">
                        <v-icon name="contact_support" />
                        Số câu hỏi: {{ item?.random_number || item?.quiz?.questions?.length }}
                    </div>
                    <div class="icon-list text-[#1E293B] my-5">
                        <v-icon name="check_circle_outline" />
                        Số câu đạt: {{ item?.quiz?.minimum_score }}
                    </div>
                </div>
                <div class="custom-card mt-auto">
                    <v-card-actions>
                        <div class="w-full">
                            <div class="h-[1px] w-full bg-[#E2E8F0] mb-4"></div>
                            <div
                                v-if="!displayTrain"
                                class="grid gap-2 w-full"
                                :class="sstrain ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1'"
                            >
                                <button
                                    class="w-full col-span-1 text-[#67C2C3] icon-size border gap-3 border-[#67C2C3] border-solid rounded-lg px-2 py-1"
                                >
                                    <v-icon name="download" />
                                    <a :href="usedImage.idToUrlDownload(item?.training_file)">Tải tài liệu</a>
                                </button>
                                <button
                                    v-if="sstrain"
                                    @click="gotoModuleDetail(item)"
                                    class="w-full col-span-1 text-white icon-size bg-[#67C2C3] gap-3 border border-[#67C2C3] border-solid rounded-lg px-2 py-1"
                                >
                                    <v-icon name="school" />
                                    Đào tạo
                                </button>
                            </div>

                            <div v-else class="grid grid-cols-1` gap-2 w-full">
                                <button
                                    class="w-full col-span-1 text-[#67C2C3] icon-size border gap-3 border-[#67C2C3] border-solid rounded-lg px-2 py-1"
                                >
                                    <v-icon name="download" />
                                    <a :href="usedImage.idToUrlDownload(item?.training_file)">Tải tài liệu</a>
                                </button>
                            </div>
                        </div>
                    </v-card-actions>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import useImage from '../../../../../shared-fe/composables/use-image'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();
const usedImage = useImage()
const router = useRouter()
const props = defineProps({
    lstModule: {
        type: Array<Object>,
        default: [],
    },
    channel: {
        type: String,
        default: '',
    },
})
const route = useRoute()
let sstrain = route.params?.session_train

const displayTrain = computed(() => {
    return route.fullPath === PATH_ROUTER.MODULE_LIST.PATH
})
const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})
const gotoModuleDetail = (item) => {
    router.push({
        name: PATH_ROUTER.PROCESS_MODULE_TRAIN.NAME,
        params: {
            session_train: sstrain,
            module_id: item?.id,
        },
    })
}
</script>
<style lang="scss">
.icon-list {
    .v-icon {
        --v-icon-color: #5babac;
        --v-icon-size: 16px;
    }
}
.button-lesson {
    .v-icon {
        --v-icon-color: red;
        --v-icon-size: 16px;
    }
}
.v-breadcrumb {
    --v-breadcrumb-size: 14px;
    --v-breadcrumb-color: #475569;
}
.router-link-active {
    --v-breadcrumb-color: #1939ae;
}
.bg-v-card {
    background: white !important;
}
.icon-size {
    .v-icon {
        --v-icon-size: 16px;
    }
}
.card-list {
    .custom-v-card {
        max-width: 100% !important;
    }
}
</style>
