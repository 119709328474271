<template>
    <div class="c-date-picker">
        <div ref="wrapper" class="v-date-picker">
            <input class="input" type="text" data-input />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue'
import Flatpickr from 'flatpickr'
import { format, formatISO } from 'date-fns'
import { CURRENT_DAY } from '../../utils/message'

interface Props {
    type: 'date' | 'time' | 'dateTime' | 'timestamp'
    modelValue?: Array<String>
    disabled?: boolean
    includeSeconds?: boolean
    use24?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    disabled: false,
    includeSeconds: false,
    use24: true,
})

const emit = defineEmits(['update:modelValue', 'close'])

const wrapper = ref<HTMLElement | null>(null)
let flatpickr: Flatpickr.Instance | null

const flatpickrLocale = {
    weekdays: {
        shorthand: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        longhand: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
    },

    months: {
        shorthand: ['Th1', 'Th2', 'Th3', 'Th4', 'Th5', 'Th6', 'Th7', 'Th8', 'Th9', 'Th10', 'Th11', 'Th12'],
        longhand: [
            'Tháng một',
            'Tháng hai',
            'Tháng ba',
            'Tháng tư',
            'Tháng năm',
            'Tháng sáu',
            'Tháng bảy',
            'Tháng tám',
            'Tháng chín',
            'Tháng mười',
            'Tháng mười một',
            'Tháng mười hai',
        ],
    },

    firstDayOfWeek: 1,
    rangeSeparator: ' đến ',
}

onMounted(async () => {
    if (wrapper.value) {
        flatpickr = Flatpickr(wrapper.value, {
            ...flatpickrOptions.value,
            mode: 'range',
            locale: flatpickrLocale,
        } as any)
    }

    watch(
        () => props.modelValue,
        () => {
            props.modelValue
            if (props.modelValue) {
                flatpickr?.setDate(props.modelValue, false)
            } else {
                // flatpickr?.clear()
            }
        },
        { immediate: true }
    )
})

onBeforeUnmount(() => {
    if (flatpickr) {
        flatpickr.close()
        flatpickr = null
    }
})

const defaultOptions = {
    static: true,
    inline: true,
    nextArrow:
        '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>',
    prevArrow:
        '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/></svg>',
    wrap: true,

    onChange(selectedDates: Date[], _dateStr: string, _instance: Flatpickr.Instance) {
        const selectedDate = selectedDates.length > 0 ? selectedDates : null
        emitValue(selectedDate)
    },
    onClose(selectedDates: Date[], _dateStr: string, _instance: Flatpickr.Instance) {
        const selectedDate = selectedDates.length > 0 ? selectedDates : null
        selectedDate && emitValue(selectedDate)
    },
    onReady(_selectedDates: Date[], _dateStr: string, instance: Flatpickr.Instance) {
        const setToNowButton: HTMLElement = document.createElement('button')
        setToNowButton.innerHTML = CURRENT_DAY
        setToNowButton.classList.add('set-to-now-button')
        setToNowButton.tabIndex = -1
        setToNowButton.addEventListener('click', setToNow)
        instance.calendarContainer.appendChild(setToNowButton)

        if (!props.use24) {
            instance.amPM?.addEventListener('keyup', enterToClose)
        } else if (props.includeSeconds) {
            instance.secondElement?.addEventListener('keyup', enterToClose)
        } else {
            instance.minuteElement?.addEventListener('keyup', enterToClose)
        }
    },
}

const flatpickrOptions = computed<Record<string, any>>(() => {
    return Object.assign({}, defaultOptions, {
        enableSeconds: props.includeSeconds,
        enableTime: ['dateTime', 'time', 'timestamp'].includes(props.type),
        noCalendar: props.type === 'time',
        time_24hr: props.use24,
    })
})

function emitValue(value: Date[] | null) {
    if (!value) return emit('update:modelValue', null)
    let rangeValue: string[] = []
    if (value.length > 1) {
        switch (props.type) {
            case 'dateTime':
                rangeValue = value.map((item) => {
                    return format(item, "dd-MM-yyyy'T'HH:mm:ss")
                })
                emit('update:modelValue', rangeValue)
                break
            case 'date':
                emit('update:modelValue', value)
                break
            case 'time':
                rangeValue = value.map((item) => {
                    return format(item, 'HH:mm:ss')
                })
                emit('update:modelValue', rangeValue)
                break
            case 'timestamp':
                rangeValue = value.map((item) => {
                    return formatISO(item)
                })
                emit('update:modelValue', rangeValue)
                break
        }
        // close the calendar on input change if it's only a date picker without time input
        emit('close')
    }
}

function setToNow() {
    flatpickr?.setDate(new Date(), true)
}

function enterToClose(e: any) {
    if (e.key !== 'Enter') return
    flatpickr?.close()
    emit('close')
}
</script>

<style lang="scss" scoped>
.c-date-picker:deep {
    .input {
        display: none;
    }
    .rangeMode {
        .flatpickr-day.inRange {
            border-radius: 0;
            box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
            background: #e6e6e6;
            border-color: #e6e6e6;
        }
    }
}
</style>
