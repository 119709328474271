<template>
    <c-access :roles="[USER_ROLE.HR]">
        <sidebar-detail icon="pending" class="interview-steps" title="Thư nhận việc">
            <v-skeleton-loader v-if="loadingInterview" class="c-loading" type="input-tall" />
            <div v-else>
                <div class="mb-2 text-[#1E293B]">
                    Ngày nhận việc (dự kiến)
                    <span class="required">*</span>
                </div>
                <div class="mb-6">
                    <v-menu ref="dateTimeMenuCard" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="startDateDisplay"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="startDate ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': startDateDisplay,
                                            'today-icon': !startDateDisplay,
                                        }"
                                        v-on="{ click: startDate ? unsetValueDate : null }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <c-date-picker
                            type="date"
                            :include-seconds="true"
                            :use-24="true"
                            :disableSunday="true"
                            :specificdays="customSpecificDay"
                            :model-value="startDate"
                            :minDate="minStartDate"
                            @update:model-value="(e) => handleChangeDate(e)"
                            @close="dateTimeMenuCard?.deactivate"
                        />
                    </v-menu>
                    <span class="message-error" v-if="errorDate">{{ errorDate }}</span>
                </div>
                <div class="list-btn flex gap-3 w-full mt-6 pt-6 border-t-1px border-t-solid border-[#D3DAE4]">
                    <v-button class="w-full btn-pass" @click="() => submit()">
                        <div class="">Gửi thông tin nhận việc</div>
                    </v-button>
                </div>
                <div class="mt-3 text-[#D97706]">{{ messageInterview }}</div>
            </div>
        </sidebar-detail>
    </c-access>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import CAccess from '../../../../../../shared-fe/components/c-access/c-access.vue'
import CDatePicker from '../../../../../../shared-fe/components/c-datepicker/c-datepicker.vue'
import { useInterviewDetailQuery } from '../../../../../../shared-fe/services/interview-detail-service'
import { useOptionQuery } from '../../../../../../shared-fe/services/option-service'
import { CANDIDATE_STATUS, FORMAT_TIME, USER_ROLE } from '../../../../../../shared-fe/utils/constant'
import dayjs from 'dayjs'
import { useSettingQuery } from '@/shared-fe/services/setting-service'
import { useUserStore } from '@/stores/user'
import { onMounted } from 'vue'
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})
const content = ref('')
const loadingInterview = ref(false)
const messageInterview = ref('')
const arrStatus = ref([])
const errorDate = ref('')
const startDateDisplay = ref('')
const { postItemInterviewDetail, sendService } = useInterviewDetailQuery()
const userStore: any = useUserStore()
const emit = defineEmits(['handleSubmit'])
const startDate = ref('')
const dateTimeMenuCard = ref()
const handleChangeDate = (e) => {
    startDate.value = e || ''
    startDateDisplay.value = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
}
const unsetValueDate = (e) => {
    e.preventDefault()
    e.stopPropagation()
    startDate.value = ''
    startDateDisplay.value = ''
}

onMounted(async () => {
    const options = userStore.currentUser.options
    let _arrStatus: any = []
    if (options.length > 0) {
        options.forEach((x) => {
            if (x.opt_group === 'candidate_status') {
                _arrStatus.push({
                    opt_key: x.opt_key,
                    id: x.id,
                })
            }
        })
    }
    arrStatus.value = _arrStatus
})

const customSpecificDay = ref()
const minStartDate = ref()
const { getProjectSetting } = useSettingQuery()
getProjectSetting().then((res) => {
    if (res?.data?.data) {
        customSpecificDay.value = res?.data?.data?.holidays
            ?.split(',')
            ?.map((x) => x.replace('\n', '').split('/').reverse().join('-'))
    }
})

function findStartDate(countDate, countResult) {
    let date = dayjs().add(countDate, 'day').format(FORMAT_TIME.YYYMMDD)
    // skip => the date is the specific day
    let resultDate = customSpecificDay.value?.find((x) => x === date)
    // skip => the date is the weekend
    if (new Date(dayjs().add(countDate, 'day')).getDay() !== 0 && !resultDate) {
        countResult++
    }

    if (countResult == 3) {
        minStartDate.value = date
        return
    }

    countDate--
    findStartDate(countDate, countResult)
}
watch(
    () => customSpecificDay.value,
    () => {
        findStartDate(-1, 0)
    },
    {
        immediate: true,
    }
)

const submit = async () => {
    errorDate.value = ''
    if (!startDate.value) {
        errorDate.value = 'Vui lòng nhập ngày route ride'
        return
    }

    // Ngày LVCC của DCR cũ không được lớn hơn ngày bắt đầu làm việc của DCR mới nếu là thay thế
    if (
        props.item?.recruitment_request?.hiring_reason === 'replacement' &&
        (dayjs(props.item?.recruitment_request?.resign_id.last_working_day).isAfter(startDate.value, 'day') ||
            dayjs(props.item?.recruitment_request?.resign_id.last_working_day).isSame(startDate.value, 'day'))
    ) {
        errorDate.value = `Trùng ngày làm việc với DCR cũ. Vui lòng chọn ngày khác`
        return
    }

    loadingInterview.value = true
    let findIdStatus = arrStatus.value?.find((x: any) => x.opt_key === CANDIDATE_STATUS.waiting_for_job.value)
    let params: any = {
        date_work_est: startDate.value,
        candidate_status: 'send_mail',
        id: props.item.candidate,
        interview_round: props.item?.id,
        candidate_status_id: findIdStatus?.id,
    }

    await postItemInterviewDetail({
        ...params,
    })
    setTimeout(() => {
        messageInterview.value = 'Đã hoàn thành gửi thư'
        loadingInterview.value = false
        content.value = ''
        setTimeout(() => {
            emit('handleSubmit')
        }, 2000)
    }, 2000)
}
</script>
