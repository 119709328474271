<template>
    <div class="mx-9">
        <div class="info-request font-bold text-[var(--foreground-normal-alt)] text-6 mt-10">
            Thông tin xin duyệt miễn trừ
        </div>

        <div class="mt-3 text-sm">
            <span class="font-bold">Trạng thái:{{ ' ' }}</span>
            <span class="mr-8">{{ item?.status }}</span>
            <span class="font-bold">Miễn trừ:{{ ' ' }}</span>
            <span>{{ item?.exemption_case }}</span>
        </div>

        <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mt-6">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div class="col-span-1 w-full">
                    <div class="type-label">
                        Họ và tên
                        <span class="required">*</span>
                    </div>
                    <v-input :model-value="item?.full_name" :disabled="true"></v-input>
                </div>
                <div class="col-span-1 w-full">
                    <div class="type-label">
                        Trường hợp miễn trừ
                        <span class="required">*</span>
                    </div>
                    <v-input :model-value="item?.exemption_case" :disabled="true"></v-input>
                </div>
                <div class="col-span-1 w-full">
                    <div class="type-label">
                        Thời gian nghỉ việc gđ trước
                        <span class="required">*</span>
                    </div>
                    <v-input :model-value="item.resign_before" :disabled="true"></v-input>
                </div>

                <div class="col-span-1 w-full">
                    <div class="type-label">
                        Tổng thâm niên
                        <span class="required">*</span>
                    </div>
                    <v-input type="text" disabled :model-value="item.total_seniority"></v-input>
                </div>
                <div class="col-span-1 w-full">
                    <div class="type-label">Mã Route gđ làm việc cũ</div>
                    <v-input type="text" disabled :model-value="item.route_old"></v-input>
                </div>
                <div class="col-span-1 w-full">
                    <div class="type-label">
                        Mã route hiện tại
                        <span class="required">*</span>
                    </div>
                    <v-input :model-value="item.route_name" :disabled="true"></v-input>
                </div>
                <div class="w-full col-span-3">
                    <div class="type-label">Lý do xin miễn trừ</div>
                    <v-textarea :disabled="true" :model-value="item.reason"></v-textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from 'vue'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { USER_ROLE } from '../../../../../shared-fe/utils/constant'

const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})

const { item } = toRefs(props)
const dataDefault = {
    id: -1,
    full_name: '',
    exemption_case: '',
    resign_before: '',
    total_seniority: '',
    route_old: '',
    route_name: '',
    reason: '',
    hero_sku_one: '',
    hero_sku_two: '',
    hero_sku_three: '',
    ebd_one: '',
    ebd_two: '',
    ebd_three: '',
    aso_one: '',
    aso_two: '',
    aso_three: '',
    display_one: '',
    display_two: '',
    display_three: '',
}

const formData = ref(JSON.parse(JSON.stringify(dataDefault)))

const { canAccess } = usePermission()
const fullAccess = computed(() => {
    return canAccess([USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2, USER_ROLE.SS])
})
</script>

<style lang="scss" scoped></style>
