<template>
	<svg
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
	>
		<path d="M3 3h18v18H3z" />
	</svg>
</template>

<script lang="ts">
export default {};
</script>
