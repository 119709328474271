<template>
    <div class="mx-4">
        <div class="text-[#1E4BA1] text-lg font-bold mb-6">Danh sách SS</div>
        <div class="searchbar-list-ss">
            <list-ss-searchbar @update:handleSearch="handleSearch"></list-ss-searchbar>
        </div>
        <list-ss
            :items="itemsRender"
            :modelSelected="modelSelected"
            @update:modelSelected="modelSelectedFun"
            :totalPages="totalPages"
            :page="page"
            @update:toPage="handleToPage"
            @updateSort="handleUpdateSort"
            :loading="loading"
            @refresh="refresh"
        ></list-ss>
    </div>
</template>

<script lang="ts" setup>
import { useBatch } from '@/shared-fe/composables/use-batch'
import { useItems } from '@directus/composables'
import { computed, ref } from 'vue'
import { RECORD_PER_PAGE } from '../../../../../shared-fe/utils/constant'
import { USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import listSsSearchbar from './list-ss-searchbar.vue'
import ListSs from './list-ss.vue'

const sort = ref(['-id'])
const filter = ref(null)
const page = ref(1)
const search = ref(null)
const { modelSelected } = useBatch(USER_COLLECTION)
const emit = defineEmits(['success'])

const customFilter = computed(() => {
    let _filter = filter.value
    _filter = {
        _and: [
            {
                role: {
                    short_name: 'ss',
                },
            },
            {
                onboarding_status: {
                    _neq: 'off',
                },
            },
        ],
    }
    return _filter
})
const { items, totalPages, loading, getItems, getItemCount, getTotalCount } = useItems(ref('directus_users'), {
    fields: ref([
        '*',
        'full_name',
        'zone.*',
        'route_code.name',
        'code',
        'system_providers.provider_id.name',
        'phone_number',
        'parent_id.full_name',
        'parent_id.role.short_name',
        'email',
        'area.name',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search,
    filter: customFilter,
    page: page,
})

const itemsRender = computed(() => {
    let _itemsRender: any = []
    items.value.forEach((x) => {
        _itemsRender.push({
            ...x,
            area_name: x?.area?.name,
            zone: x?.zone?.name,
            route_name: x?.route_code?.name,
            system_providers: x.system_providers?.length > 0 ? x.system_providers[0].provider_id?.name : '',
        })
    })

    return _itemsRender
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}

const handleToPage = (_toPage: number) => {
    page.value = _toPage
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

const handleSearch = (_data: any) => {
    const data = JSON.parse(JSON.stringify(_data))
    search.value = data.title
}

const refresh = () => {
    getItems()
    getItemCount()
    getTotalCount()
}
</script>

<style>
.searchbar-list-ss {
    button {
        background-color: #334155;
    }
}
</style>
