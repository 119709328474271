<template>
    <div class="regulation w-full">
        <div
            class="header-banner relative w-full md:min-h-[550px] min-h-[930px] rounded-md flex"
            :style="{ 'background-image': `url(${usedImage.idToUrl(settings.target_bg)})` }"
        >
            <div class="lg:w-2/3 w-full flex flex-col items-center justify-between py-10 z-2 px-4">
                <div
                    class="banner-header w-full relative flex justify-center items-center font-bold text-[#1e4ba1] pl-25 pr-14 py-5"
                    :style="{ 'background-image': `url(${usedImage.idToUrl(settings.target_character_bg)})` }"
                >
                    <p><span v-html="settings.target_1"></span></p>
                    <button
                        class="rounded-button left-0 top-44 -rotate-[10deg] px-3 rounded-full absolute font-bold text-2xl text-center text-white uppercase border border-white bg-[#1e4ba1]"
                    >
                        <span class="">mục tiêu</span>
                    </button>
                </div>
                <div class="flex flex-wrap justify-evenly w-full gap-y-9">
                    <v-card
                        class="card-banner text-black md:w-[196px] w-full md:px-4 py-4 px-2 h-max-content bg-white rounded-t-2xl"
                    >
                        <v-card-title class="card-logo"></v-card-title>
                        <v-card-text
                            class="card-text font-medium flex justify-center items-center text-[#1e4ba1] text-center"
                        >
                            <strong>
                                <p><span v-html="settings.target_2"></span></p>
                            </strong>
                        </v-card-text>
                    </v-card>
                    <v-card class="card-banner text-black md:w-[196px] w-full p-4 h-max-content bg-white rounded-t-2xl">
                        <v-card-title class="card-logo"></v-card-title>
                        <v-card-text
                            class="card-text font-medium flex justify-center items-center text-[#1e4ba1] text-center"
                        >
                            <strong>
                                <p><span v-html="settings.target_3"></span></p>
                            </strong>
                        </v-card-text>
                    </v-card>
                    <v-card class="card-banner text-black md:w-[196px] w-full p-4 h-max-content bg-white rounded-t-2xl">
                        <v-card-title class="card-logo"></v-card-title>
                        <v-card-text
                            class="card-text font-mediu flex justify-center items-center text-[#1e4ba1] text-center"
                        >
                            <strong>
                                <p><span v-html="settings.target_4"></span></p>
                            </strong>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
            <div class="lg:block hidden absolute right-10 bottom--2">
                <img :src="usedImage.idToUrl(settings.target_character)" />
            </div>
        </div>

        <div class="mt-28 grid grid-cols-3 gap-x-8">
            <div
                v-for="(item, index) in INTRODUCTION_2"
                :key="index"
                class="onboard-list-card col-span-1 relative flex justify-center items-center"
            >
                <div class="">
                    <img class="h-full object-contain object-bottom w-full" :src="usedImage.idToUrl(item.image)"  alt=""/>
                </div>

                <div class="absolute w-full bottom-6 px-6">
                    <a
                        href="javascript:"
                        class="bg-[#1E4BA1] rounded-md flex justify-center items-center gap-2 py-5 text-center cursor-pointer"
                        @click="handleDownload(item.file)"
                    >
                        <span class="text-lg text-white">{{ item.title }}</span>
                        <div class="bg-#62B1EB bottom-[15px] px-2 py-1 rounded-full">
                            <v-icon class="" name="download" small />
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="banner-footer relative flex justify-center items-center flex-col mt-40">
            <div
                class="banner-background w-full h-[356px]"
                :style="{ 'background-image': `url(${usedImage.idToUrl(settings.footer_bg)})` }"
            ></div>

            <div class="list-item flex justify-center items-center flex-col absolute bottom-28">
                <img :src="usedImage.idToUrl(settings.footer_logo)" />
                <h5 class="font-bold md:text-[26px] text-white mt-10">
                    {{ settings.footer_title }}
                </h5>

                <p class="font-normal text-[#e2e8f0] mt-4 mb-10 text-center">
                    {{ settings.footer_intro }}
                </p>

                <div class="flex justify-center gap-4">
                    <v-button class="bg-white rounded-md" outlined @click="handleDownload(settings.footer_file)">
                        Tải PDF
                        <v-icon class="ml-2" name="download" />
                    </v-button>

                    <a :href="`tel:${settings.footer_hotline}`">
                        <v-button>{{ settings.footer_hotline }}</v-button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useStoreWebSetting } from '../../../../../shared-fe/stores/web-setting'
import useImage from '../../../../../shared-fe/composables/use-image'
import { useApi } from '@directus/composables'

const usedImage = useImage()
const imageWorkingEnv = useStoreWebSetting()

const INTRODUCTION_2 = computed(() => [
    {
        key: 1,
        title: settings.value.faq_title ?? '',
        image: settings.value.faq_img ?? '',
        file: settings.value.faq_file ?? '',
    },
    {
        key: 2,
        title: settings.value.policy_title ?? '',
        image: settings.value.policy_img ?? '',
        file: settings.value.policy_file ?? '',
    },
    {
        key: 3,
        title: settings.value.trick_title ?? '',
        image: settings.value.trick_img ?? '',
        file: settings.value.trick_file ?? '',
    },
])

const handleDownload = (key) => {
    if (!key) {
        alert('Chưa có file để tải')
        return
    }

    window.open(usedImage.idToUrlDownload(key))
}

const api = useApi()
const settings = ref({})

onMounted(async () => {
    api.get('/items/live_editor', {
        params: {
            filter: {
                group: 'on_boarding',
            },
            sort: ['-group'],
        },
    }).then(async (res) => {
        const [m] = res.data.data
        settings.value = JSON.parse(m.option_value)
    })
})
</script>
<style lang="scss" scoped>
.header-banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .banner-header {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        width: 372px;
        height: 228px;
    }
    .rounded-button {
        width: 100px;
        height: 100px;
    }
    .card-banner {
        background-color: white !important;
        border-radius: 16px;
        border: 1px solid var(--primary);
    }
    .card-logo {
        background-image: url('/images/logo_icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}

.onboard-list-card {
    height: 397px;
    background: linear-gradient(0deg, #e5eef8, #e5eef8), #b7e9ea;
    border-radius: 6px;
    .v-icon {
        color: white;
    }
}
.banner-footer {
    .banner-background {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
</style>
