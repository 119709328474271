<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            v-model:modelValue="modelValue"
            :disabled="disabled"
            manualSortKey="tel"
            :sort="internalSort"
            :loading="loading"
            @update:sort="updateSort($event)"
            showSelect="multiple"
            @click:row="detailRowOnClick"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->

            <template #[`item.status`]="{ item }">
                <c-status :collectionName="RESIGN_COLLECTION" :id="item.status"></c-status>
            </template>
            <template #[`item.date_created`]="{ item }">
                {{ item.date_created ? dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.date_approved`]="{ item }">
                {{ item.date_approved ? dayjs(item.date_approved).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.last_working_day`]="{ item }">
                {{ item.last_working_day ? dayjs(item.last_working_day).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.id`]="{ item }">
                <span style="color: #62b1eb">{{ item.id }}</span>
            </template>
            <template #[`item.status_pv`]="{ item }">
                <span
                    v-if="item.status_pv"
                    class="px-2 py-1 rounded-2 inline-block"
                    :style="{ background: '#D1FAE5', color: '#059669', border: '#D1FAE5' }"
                >
                    Đã phỏng vấn
                </span>
                <span
                    v-else
                    class="px-2 py-1 rounded-2 inline-block"
                    :style="{ background: '#F1F5F9', color: '#070A13', border: '#E2E8F0' }"
                >
                    Chưa phỏng vấn
                </span>
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '@/shared-fe/components/c-table/type'
import { FORMAT_TIME } from '@/shared-fe/utils/constant'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { RESIGN_COLLECTION } from '@/shared-fe/utils/table-name'
const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}
const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'deleteItem', 'updateSort'])

const itemsRender = computed(() => {
    let _items: any = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 100,
    },
    {
        text: 'Mã đơn',
        value: 'id',
        width: 100,
    },
    {
        text: 'Ngày tạo đơn',
        value: 'date_created',
    },
    {
        text: 'Chi nhánh',
        value: 'zone',
        width: 120,
    },
    {
        text: 'Khu vực',
        value: 'area',
        width: 120,
    },
    {
        text: 'Nhà phân phối',
        value: 'system_providers',
        width: 200,
    },
    {
        text: 'SS',
        value: 'ss',
        width: 200,
    },
    {
        text: 'Mã Route',
        value: 'route_code',
        width: 120,
    },
    {
        text: 'Mã DCR',
        value: 'code',
        width: 120,
    },
    {
        text: 'Họ và tên',
        value: 'full_name',
        width: 200,
    },
    {
        text: 'Ngày làm việc cuối cùng',
        value: 'last_working_day',
        width: 250,
    },
    {
        text: 'Trạng thái',
        value: 'status',
    },
    {
        text: 'Trạng thái phỏng vấn',
        value: 'status_pv',
    },
    {
        text: 'Ngày duyệt',
        value: 'date_approved',
    },
    {
        text: 'Người phụ trách',
        value: 'hr_name',
    },
])

const detailRowOnClick = (data: any) => {
    router.push({
        name: PATH_ROUTER.RESIGN_DETAIL.NAME,
        params: {
            id: data.item.id,
        },
    })
}

const convertKeySort = (sortKey, desc) => {
    let key = ''
    switch (sortKey) {
        case 'zone':
            key = 'staff_id.zone.name'
            break
        case 'area':
            key = 'staff_id.area.name'
            break
        case 'system_providers':
            key = 'staff_id.system_providers.provider_id.name'
            break
        case 'ss':
            key = 'staff_id.parent_id.full_name'
            break
        case 'full_name':
            key = 'staff_id.full_name'
            break
        case 'code':
            key = 'staff_id.code'
            break
        case 'route_code':
            key = 'staff_id.route_code.name'
            break
        case 'phone_number':
            key = 'staff_id.phone_number'
            break
        case 'hr_name':
            key = 'hr_id.full_name'
            break
        default:
            key = sortKey
            break
    }
    if (desc) key = `-${key}`
    return key
}

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    if (sort?.by != 'stt' && sort?.by != 'status_pv') {
        emit('updateSort', convertKeySort(sort?.by, sort?.desc))
    }
}

// const changeManualSort = (data) => {
//     // when update position (required field ID in the column)
//     // call api update position
// }
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
