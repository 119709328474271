import { useApi } from '@directus/extensions-sdk'
import { FILES_COLLECTION, LIVE_EDITOR_COLLECTION } from '../utils/table-name'
import { AxiosResponse } from 'axios'

export const useLiveEditor = () => {
    const api = useApi()

    const getAllLiveEditorByGroup = async (group: string): Promise<AxiosResponse<any> | undefined> => {
        try {
            return await api.get(`/items/${LIVE_EDITOR_COLLECTION}`, {
                params: {
                    fields: ['option_key', 'option_value'],
                    filter: {
                        group: {
                            _eq: group,
                        },
                    },
                },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getAllFilesByFolder = async (folder: string): Promise<AxiosResponse<any> | undefined> => {
        try {
            return await api.get(`/${FILES_COLLECTION}`, {
                params: {
                    filter: {
                        folder: {
                            _eq: folder,
                        },
                    },
                },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getDataLiveEditor = async (option_key: string): Promise<AxiosResponse<any> | undefined> => {
        try {
            return await api.get(`/items/${LIVE_EDITOR_COLLECTION}`, {
                params: {
                    fields: ['option_key', 'option_value'],
                    filter: {
                        option_key: {
                            _eq: option_key,
                        },
                    },
                },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    return {
        getAllLiveEditorByGroup,
        getAllFilesByFolder,
        getDataLiveEditor,
    }
}
