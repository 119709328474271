import {ref, computed} from 'vue'
import api from '@/api'
import {set} from 'lodash'

export const useSaleforce = () => {
    const _providers = ref([])
    const _areas = ref([])
    const _users = ref([])
    const _ss = ref([])
    const _dcr = ref([])
    const _allUsers = ref({})

    const userSearch = ref('')
    const selection = ref({
        zone: '',
        area: '',
        asm: '',
        ss: '',
        dcr: ''
    })

    const userSelected = computed(() => {

        if (selection.value.dcr) {
            return _dcr.value.find(u => u.id == selection.value.dcr)
        } else if (selection.value.ss) {
            return _ss.value.find(u => u.id == selection.value.ss)
        } else if (selection.value.asm) {
            return _users.value.find(u => u.id == selection.value.asm)
        }
        return {}
    })

    const setSelection = (key, value) => {

        if (key == 'zone') {
            selection.value.area = null
            selection.value.ss = null
            selection.value.dcr = null
            if (selection.value.zone == value) {
                selection.value.zone = ''
                return
            }
        }

        if (key == 'area') {
            selection.value.ss = null
            selection.value.dcr = null
            if (selection.value.area == value) {
                selection.value.area = ''
                return
            }
        }

        if (key == 'asm') {
            selection.value.ss = null
            selection.value.dcr = null
        }

        if (key == 'ss') {
            selection.value.dcr = null
            selection.value.asm = null;
            userSearch.value = ''

            const ssModel = _ss.value.find(u => u.id == selection.value.ss)
            if (ssModel) {
                selection.value.asm = ssModel.parent_id;
            }

            if (selection.value.ss == value) {
                selection.value.ss = ''
                return
            }
        }

        if (key == 'dcr') {
            const dcrCurrent = _dcr.value.find(u => u.id == value)
            if (dcrCurrent) {
                userSearch.value = ''
                selection.value.ss = dcrCurrent.parent_id
                selection.value.zone = dcrCurrent.zone
                selection.value.area = dcrCurrent.area
            }
        }

        set(selection.value, key, value)
    }


    const queryUser = (role) => {
        return {
            limit: -1,
            fields: ['*', 'sub_users.*', 'system_providers.*', 'role.*'],
            sort: ['full_name'],
            filter: {
                role: {
                    short_name: role
                },
                employment_status: 'on'
            },
            deep: {
                sub_users: {
                    _filter: {
                        employment_status: 'on'
                    }
                }
            }
        }
    }

    api.get('/items/providers', {
        params: {
            limit: -1
        }
    }).then(res => {
        _providers.value = res.data.data
    })

    api.get('/items/areas', {
        params: {
            limit: -1,
            sort: ['-name']
        }
    }).then(res => {
        _areas.value = res.data.data
    })

    api.get('/users', {
        params: queryUser('asm')
    }).then(res => {
        _users.value = res.data.data
        _users.value.map(u => _allUsers.value[u.id] = u)
    })

    api.get('/users', {
        params: queryUser('ss')
    }).then(res => {
        _ss.value = res.data.data
        _ss.value.map(u => _allUsers.value[u.id] = u)
    })

    api.get('/users', {
        params: queryUser('dcr')
    }).then(res => {
        _dcr.value = res.data.data
        _dcr.value.map(u => _allUsers.value[u.id] = u)
    })

    const currentSS = computed(() => {
        if (!selection.value.ss) return {}
        let u = _ss.value.find(u => u.id === selection.value.ss)

        if (u.system_providers)
            u.providers = u.system_providers.map(p => {
                return p?.provider_id
            })
        else
            u.providers = []
        return u
    })

    const providers = computed(() => {
        if (!selection.value.area) return []
        let items = _providers.value.filter(p => p.area == selection.value.area)

        if (selection.value.ss) {

            items = items.filter(p => {
                if (!currentSS.value.providers) {
                    return false
                }
                return currentSS.value.providers.indexOf(p.id) >= 0
            })
        }

        return items
    })

    const areas = computed(() => {
        if (!selection.value.zone)
            return []

        return _areas.value.filter(a => a.zone === selection.value.zone)
    })

    const asm = computed(() => {
        // if (!selection.value.zone) return []

        let us = _users.value.filter(u => u.role.short_name === 'asm')

        if (selection.value.zone) {
            us = us.filter(u => u.zone == selection.value.zone)
        }

        if (selection.value.area) {
            us = us.filter(u => u.area == selection.value.area)
        }

        return us
    })


    const dcr = computed(() => {
        let users = _dcr.value

        if (!selection.value.area && !userSearch.value && !selection.value.ss) return []

        if (selection.value.area)
            users = users.filter(u => u.area == selection.value.area)

        if (userSearch.value && userSearch.value.length > 1 && users.length > 0) {
            const k = userSearch.value.toString().toLowerCase()
            users = users.filter(u => {
                return (
                    (u.full_name && u.full_name.toLowerCase().includes(k)) ||
                    (u.email && u.email.toLowerCase().includes(k)) ||
                    (u.code && u.code.toLowerCase().includes(k)) ||
                    (u.route_code && u.route_code?.name && u.route_code?.name.toLowerCase().includes(k))
                )
            })

            return users
        }

        if (selection.value.ss)
            users = users.filter(u => u.parent_id == selection.value.ss)

        if (selection.value.npp)
            users = users.filter(u => {
                const ex = u.system_providers.find(p => p.provider_id == selection.value.npp)
                return !!ex
            })

        return users
    })

    const zones = computed(() => {
        if (!_areas.value) return []
        return _areas.value?.filter(a => a.zone === null) ?? []
    })

    const dcrError = computed(() => {
        return _dcr.value.filter(u => {
            if (!u || !u.system_providers) return false

            const [p] = u.system_providers ?? null
            return !p
                || !u.zone
                || !u.area
                || !u.route_code
                || !u.code
                || !u.start_date
        })
    })

    const ssError = computed(() => {
        return _ss.value.filter(u => {
            if (!u || !u.system_providers) return false
            const [p] = u.system_providers ?? null
            return !p
                || !u.zone
                || !u.area
        })
    })

    const ssFilterSearch = (ssUsers) => {
        if (!userSearch.value) return ssUsers

        const k = userSearch.value.toString().toLowerCase()
        return ssUsers.filter(u => {
            return (
                (u.full_name && u.full_name.toLowerCase().includes(k)) ||
                (u.email && u.email.toLowerCase().includes(k))
            )
        })
    }

    const refreshUser = async (id: any) => {
        await api.get(`/users/${id}`, {
            params: {
                ...queryUser(''),
                filter: null
            }
        }).then(res => {
            if (res.data.data.role.short_name == 'dcr') {
                _dcr.value.some((u: any, index: number) => {
                    if (u.id == id) {
                        _dcr.value[index] = res.data.data
                        return true
                    }

                    return false
                })
            }
            if (res.data.data.role.short_name == 'ss') {
                _ss.value.some((u, index) => {
                    if (u.id == id) {
                        _ss.value[index] = res.data.data
                        return true
                    }
                })
            }
            if (res.data.data.role.short_name == 'asm') {
                _users.value.some((u, index) => {
                    if (u.id == id) {
                        _users.value[index] = res.data.data
                        return true
                    }
                })
            }
        })
    }

    return {
        selection,
        userSearch,
        setSelection,
        userSelected,
        providers,
        areas,
        zones,
        asm,
        dcr,
        dcrError,
        ssError,
        ssFilterSearch,
        refreshUser,
        _allUsers
    }
}
