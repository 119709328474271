<template>
    <c-role>
        <private-view :title="infoTrainingTitle">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <template #title>
                <div class="type-title leading-[28px] mb-4">
                    <div>Thứ 7 học hay</div>
                    <div class="text-[#475569] text-xs font-normal mt-1">
                        Chương trình đào tạo dành cho đội ngũ {{ labelUserType }} tại nhà phân phối
                    </div>
                </div>
            </template>
            <!-- navigation end  -->
            <!-- header start -->
            <div class="w-full">
                <div
                    class="tab-header w-full min-h-[380px] h-full flex xl:flex-row flex-col justify-center items-start xl:px-28 xl:py-16 px-10 px-5 mt-[-24px]"
                    :style="{ 'background-image': `url(${imageData.saturday_learning_image[0]})` }"
                >
                    <div class="xl:w-1/2 w-full flex justify-center items-center xl:mt-0 mt-5 mr-22"></div>
                    <div
                        class="xl:w-1/2 md:w-2/3 w-full flex flex-col justify-center xl:items-start items-center mx-auto text-[#1E293B]"
                    >
                        <h5 class="font-bold xl:text-[32px] text-2xl xl:mt-0 mt-3 leading-10 mb-5">
                            {{ course?.title }}
                        </h5>
                        <p class="font-normal text-base leading-6 text-[#475569]">
                            {{ course?.description }}
                        </p>
                        <span class="font-normal icon-size my-8 leading-5 text-[#1939AE]">
                            <v-icon name="play_circle" />
                            {{ course?.total_topic }} bài học
                        </span>
                    </div>
                </div>
                <div class="bg-[#374151] flex w-full flex-row justify-center items-center gap-8">
                    <button
                        class="success text-[18px] leading-6 text-center pt-4 pb-5 px-4"
                        @click="openTab(1)"
                        :class="
                            keyNumber === 1
                                ? 'text-white border-b-4 border-b-[#67C2C3] border-b-solid'
                                : 'text-[#E2E8F0]'
                        "
                    >
                        Tổng quan
                    </button>
                    <button
                        @click="openTab(2)"
                        class="success text-[18px] leading-6 text-center pt-4 pb-5 px-4"
                        :class="
                            keyNumber === 2
                                ? 'text-white border-b-4 border-b-[#67C2C3] border-b-solid'
                                : 'text-[#E2E8F0]'
                        "
                    >
                        Khung chương trình
                    </button>
                </div>
                <div v-if="keyNumber === 1">
                    <dcr-exam-results
                        :user-id="String(route.params?.user_id)"
                        :topic-id="Number(route.params?.module_id)"
                        :sessions-train-id="String(route.params?.session_train)"
                        @see-result="(item) => handleSeeResult(item)"
                    />
                </div>
                <div v-if="keyNumber === 2">
                    <module-list :lstModule="modules" @changeChannel="changeChannel" :channelId="channelId"/>
                </div>
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" :title="infoTrainingTitle" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useConstantsImage } from '../../../../../shared-fe/stores/constants-image'
import DcrExamResults from '../components/dcr-exam-results.vue'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { useTrainingQuery } from '../../../../../shared-fe/services/training-service'
import { useStores } from '@directus/composables'
import ModuleList from '../components/module-list.vue'
import { useUserStore } from '@/stores/user'
import { TYPE_OF_TRAIN } from '@/shared-fe/utils/constant'

const imageStore = useConstantsImage()
const route = useRoute()
const keyNumber = ref(1)
const modules = ref([])
const isName = '2'
const router = useRouter()
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false

const imageData = computed(() => {
    return { ...imageStore.imageConstants }
})
const openTab = (key) => {
    keyNumber.value = key
}
const { getSessionTrains, getModulesByChannelId } = useTrainingQuery()
const channelId = ref('');
const channels = ref(userStore.currentUser[TYPE_OF_TRAIN.T7HH]);
const userStore = useUserStore();
const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})
const infoTrainingTitle = computed(() => {
    return `Thông tin đào tạo - ${labelUserType.value} Onboarding`
})
const course = ref(<any>{})
onMounted(() => {
    if (route.params?.session_train) {
        getSessionTrains(route.params?.session_train).then((res) => {
            course.value = res.data.result?.course
            if (res.data.result?.dcr_lists?.length > 0 && res.data.result?.dcr_lists[0]?.list_modules?.length > 0) {
                course.value['total_topic'] = res.data.result?.dcr_lists[0]?.list_modules?.length
            }
        })
    }
})

const getModules = (id: any) => {
    course.value = {}
    modules.value = []
    channelId.value = id
    getModulesByChannelId(id).then((_modules) => {
        course.value = _modules?.data?.result.courses
        if (course.value?.topics?.length > 0) {
            modules.value = course.value?.topics
            course.value['total_topic'] = modules.value?.length
        }
    });
}

if(channels.value.length > 0){
    getModules(channels.value[0].id)
}


const changeChannel = (id) => {
    course.value = {}
    modules.value = []
    channelId.value = id
    getModulesByChannelId(id).then((_modules) => {
        course.value = _modules?.data?.result.courses
        if (course.value?.topics?.length > 0) {
            modules.value = course.value?.topics
            course.value['total_topic'] = modules.value?.length
        }
    });
}

const handleSeeResult = (item) => {
    router.push({
        name: PATH_ROUTER.RESULT_DETAIL_EXAM_MODULE.NAME,
        params: {
            session_train: String(route.params?.session_train),
            user_id: String(route.params?.user_id),
            exam_result_id: item.id,
        },
    })
}
</script>

<style lang="scss" scoped>
.tab-header {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bg-process {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.icon-size {
    --v-icon-size: 16px;
}
</style>
