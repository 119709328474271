<template>
    <v-dialog v-model="isShow" @esc="isShow = false" placement="center custom-width">
        <v-card>
            <div class="flex flex-col items-center justify-center px-28px">
                <div class="mt-10 mb-6">
                    <div
                        class="p-6 bg-[#DEF4F5] w-100px h-100px rounded-full box-icon-success flex items-center justify-center"
                    >
                        <v-icon name="lock_reset"></v-icon>
                    </div>
                </div>
                <div class="type-labell">
                    <div class="text-5 text-[#1E293B] font-bold mb-1">Hệ thống đã ghi nhận module đã tạo bài thi</div>
                </div>
                <v-card-actions>
                    <v-button secondary @click="closeDialog">Đóng</v-button>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
const props = defineProps({
    isShowDialog: {
        type: Boolean,
        default: '',
    },
})
const emit = defineEmits(['cancelAction'])
const isShow = ref()
const closeDialog = () => {
    isShow.value = false
    emit('cancelAction')
}
watch(
    () => props.isShowDialog,
    () => {
        isShow.value = props.isShowDialog
    },
    {
        immediate: true,
    }
)
</script>
<style lang="scss">
.box-icon-success {
    position: relative;
    .v-icon i {
        color: var(--primary);
        font-size: 30px;
        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
