<template>
    <c-role>
        <private-view title="Chi tiết nghỉ việc">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.RESIGN_LIST.VALUE,
                            to: PATH_ROUTER.RESIGN_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <c-access :roles="[]">
                    <v-dialog v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-save">
                        <v-button v-tooltip.bottom="SAVE" icon rounded @click="save">
                            <v-icon name="check" />
                        </v-button>
                    </div>
                </c-access>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->

            <resign-detail v-if="objResignDetail && dataForm" :itemDisplay="objResignDetail" :dataForm="dataForm" />
            <resign-history v-if="objResignDetail" :idResign="objResignDetail?.idResign"></resign-history>
            <!--  content end -->

            <template #sidebar>
                <!-- <sidebar-detail icon="info" title="Chi tiết nghỉ việc" /> -->
                <resign-sidebar
                    close
                    :status="objResignDetail?.status"
                    :idResign="objResignDetail?.idResign"
                    @handleSubmit="getDetail"
                ></resign-sidebar>
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { FORMAT_TIME, MODE_EDIT, SUBMIT_EVENT, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { CANCEL, CONTENT_DELETE, LABEL_DELETE, SAVE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { RESIGN_COLLECTION } from '../../../../../shared-fe/utils/table-name'

import { useStores } from '@directus/extensions-sdk'
import dayjs from 'dayjs'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useResignQuery } from '../../../../../shared-fe/services/resign-service'
import { RESIGN_STATUS } from '../../../../../shared-fe/utils/constant'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import ResignDetail from '../components/resign-detail.vue'
import ResignHistory from '../components/resign-history.vue'
import ResignSidebar from '../components/resign-sidebar.vue'
const commonStore = useStoreCommon()
const route = useRoute()
const objResignDetail = ref(<any>null)
const dataForm = ref(<any>null)
const { getItemResign } = useResignQuery()
onBeforeUnmount(() => {})

const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = true

// get data detail (start)
onMounted(async () => {
    if (route?.params?.id) {
        await getDetail()
    }
})

const getDetail = async () => {
    let res = await getItemResign(route?.params?.id.toString())
    let data = JSON.parse(JSON.stringify(res?.data?.data))

    objResignDetail.value = {
        idResign: route?.params?.id.toString(),
        idUser: data?.staff_id.id,
        date_of_birth: data?.staff_id.date_of_birth,
        phone_number: data?.staff_id.phone_number,
        zone: data?.staff_id.zone?.name,
        area: data?.staff_id.area?.name,
        system_providers:
            data?.staff_id.system_providers.length > 0 ? data?.staff_id.system_providers[0].provider_id.name : '',
        ss: data.staff_id.parent_id
            ? displayParentName(data.staff_id.parent_id?.role?.short_name, data.staff_id.parent_id?.full_name)
            : '',
        code: data?.staff_id.code,
        route_code: data?.staff_id.route_code?.name,
        first_working_day: data?.first_working_day ? dayjs(data?.first_working_day).format(FORMAT_TIME.DDMMYYYY) : '',
        status: data?.status,
        hr_name: data?.hr_id?.full_name,
        status_name: data.status ? RESIGN_STATUS[data.status].text : '',
        date_created: data?.date_created,
        full_name: data?.staff_id?.full_name,
    }
    dataForm.value = {
        idResign: route?.params?.id.toString(),
        idUser: data?.staff_id?.id,
        last_working_day: data?.last_working_day,
        reason: data?.reason?.id,
        interview: data?.interview?.id,
        interview_note: data?.interview_note,
        attachments: data?.attachments,
        note: data?.note,
    }
}

// get data detail (end)

// handle logic header (start)
const router = useRouter()
const save = () => {
    commonStore.submitEvent({
        name: SUBMIT_EVENT.RESIGN_DETAIL_SUBMIT,
        payload: MODE_EDIT,
    })
}

const navigateBack = () => {
    router.push({
        name: PATH_ROUTER.RESIGN_LIST.NAME,
    })
}

const linkedYCTD = () => {}

const batchDeleteFun = async () => {
    await batchDelete('status', objResignDetail.value.idResign)
    router.push({
        name: PATH_ROUTER.RESIGN_LIST.NAME,
    })
}

const { confirmDelete, deleting, batchDelete, error: deleteError, modelSelected } = useBatch(RESIGN_COLLECTION)
// handle logic header (end)
</script>

<style lang="scss"></style>
