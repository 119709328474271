<template>
    <div class="table-hand-made mt-3rem">
        <div v-if="items && items.length > 0" class="mx-9 mb-12 box-table">
            <div class="grid grid-cols-2 items-center mb-4">
                <div class="col-span-1 info-request font-bold text-[var(--foreground-normal-alt)] text-6">
                    Lịch sử yêu cầu tuyển dụng
                </div>
            </div>

            <div class="grid bg-[#1E4BA1] rounded-t-lg text-white grid-cols-4">
                <div class="col-span-1 border p-4">Thời gian</div>
                <div class="col-span-1 border p-4">Nội dung</div>
                <div class="col-span-1 border p-4">Người xác nhận</div>
                <div class="col-span-1 p-4">Vai trò</div>
            </div>
            <div v-for="item in items" :style="{ borderBottom: '1px solid #E2E8F0' }" class="grid bg-white grid-cols-1">
                <div class="grid grid-cols-4">
                    <div class="col-span-1 p-4">{{ dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) }}</div>
                    <div class="col-span-1 p-4" v-html="item?.note"></div>
                    <div class="col-span-1 p-4">{{ item.user_created?.full_name }}</div>
                    <div class="col-span-1 p-4">{{ item.user_created?.role?.name }}</div>
                </div>
            </div>
            <div class="footer">
                <div class="c-pagi pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="7"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import { useRecruitmentRequestHistoryQuery } from '@/shared-fe/services/recruitment-request-history'

const { getRecruitmentRequestHistory } = useRecruitmentRequestHistoryQuery()
const props = defineProps({
    idRequestRecruitment: {
        type: Number,
        default: '',
    },
})
const page = ref(1)
const ITEM_PER_PAGE = 5
const totalPages = ref(1)
const items = ref(<any>[])

const toPage = (_page) => {
    page.value = _page
}

const getData = async () => {
    const res = await getRecruitmentRequestHistory(ITEM_PER_PAGE, props?.idRequestRecruitment, page.value)
    items.value = []
    if (res.data.data) {
        items.value = res.data.data
    }
    if (res.data.meta) {
        totalPages.value = Math.ceil(res.data.meta.filter_count / ITEM_PER_PAGE)
    }
}
watch(
    () => page.value,
    async () => {
        await getData()
    },
    { immediate: true }
)
</script>

<style lang="scss" scoped></style>
