<template>
    <div class="menu-list relative flex flex-col justify-between">
        <div class="">
            <c-navigation-apps :module="module"></c-navigation-apps>

            <v-list nav>
                <v-list-item
                    v-for="navItem in navItems"
                    :key="navItem.to"
                    :class="`link-${navItem.name}`"
                    :to="navItem.to"
                >
                    <v-list-item-content>
                        <div v-if="navItem.name === 'space'">
                            <div class=""></div>
                        </div>
                        <div v-else class="flex items-center">
                            <v-icon
                                v-if="navItem.icon"
                                :style="{ color: navItem.colorIcon }"
                                class="mr-2"
                                :name="navItem.icon"
                            ></v-icon>
                            <v-text-overflow :text="navItem.value" />
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PATH_ROUTER } from '../../utils/path'
import { routerModel } from './types'
import CNavigationApps from './c-navigation-apps.vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'

const { t } = useI18n()
const userStore = useUserStore()
const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})


const props = defineProps({
    name: { type: String, default: () => null, required: false },
    module: {},
})

const module = computed(() => {
    if (props.module) return props.module
    switch (props.name) {
        case '2':
            return 'ld'
        case '3':
            return 'agency'
        default:
            return 'hr'
    }
})

const MENUS: Array<routerModel> = [
    {
        name: PATH_ROUTER.RECRUITMENT_LIST.NAME,
        value: PATH_ROUTER.RECRUITMENT_LIST.VALUE,
        to: PATH_ROUTER.RECRUITMENT_LIST.PATH,
        permission: PATH_ROUTER.RECRUITMENT_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.NAME,
        value: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.VALUE,
        to: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.PATH,
        permission: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.RESIGN_LIST.NAME,
        value: PATH_ROUTER.RESIGN_LIST.VALUE,
        to: PATH_ROUTER.RESIGN_LIST.PATH,
        permission: PATH_ROUTER.RESIGN_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
        value: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.VALUE,
        to: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.PATH,
        permission: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.NAME,
        value: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.VALUE,
        to: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.PATH,
        permission: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.CANDIDATE_LIST.NAME,
        value: PATH_ROUTER.CANDIDATE_LIST.VALUE,
        to: PATH_ROUTER.CANDIDATE_LIST.PATH,
        permission: PATH_ROUTER.CANDIDATE_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.DCR_LIST.NAME,
        value: PATH_ROUTER.DCR_LIST.VALUE,
        to: PATH_ROUTER.DCR_LIST.PATH,
        permission: PATH_ROUTER.DCR_LIST.PERMISSION,
    },
    {
        name: 'space',
        to: 'space',
        value: '',
        permission: [],
    },
    {
        name: PATH_ROUTER.RECRUITMENT_SETTINGS.NAME,
        value: PATH_ROUTER.RECRUITMENT_SETTINGS.VALUE,
        to: PATH_ROUTER.RECRUITMENT_SETTINGS.PATH,
        permission: PATH_ROUTER.RECRUITMENT_SETTINGS.PERMISSION,
    },
    {
        name: PATH_ROUTER.ADMIN_HR.NAME,
        value: PATH_ROUTER.ADMIN_HR.VALUE,
        to: PATH_ROUTER.ADMIN_HR.PATH,
        permission: PATH_ROUTER.ADMIN_HR.PERMISSION,
    },
    {
        name: PATH_ROUTER.RECRUITMENT_REPORT.NAME,
        value: PATH_ROUTER.RECRUITMENT_REPORT.VALUE,
        to: PATH_ROUTER.RECRUITMENT_REPORT.PATH,
        permission: PATH_ROUTER.RECRUITMENT_REPORT.PERMISSION,
    },
]
const MENUS_SS: Array<routerModel> = [
    {
        name: PATH_ROUTER.RESIGN_LIST.NAME,
        value: PATH_ROUTER.RESIGN_LIST.VALUE,
        to: PATH_ROUTER.RESIGN_LIST.PATH,
        permission: PATH_ROUTER.RESIGN_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.NAME,
        value: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.VALUE,
        to: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.PATH,
        permission: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.CANDIDATE_LIST.NAME,
        value: PATH_ROUTER.CANDIDATE_LIST.VALUE,
        to: PATH_ROUTER.CANDIDATE_LIST.PATH,
        permission: PATH_ROUTER.CANDIDATE_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
        value: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.VALUE,
        to: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.PATH,
        permission: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.DCR_LIST.NAME,
        value: PATH_ROUTER.DCR_LIST.VALUE,
        to: PATH_ROUTER.DCR_LIST.PATH,
        permission: PATH_ROUTER.DCR_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.NAME,
        value: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.VALUE,
        to: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.PATH,
        permission: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.PERMISSION,
    },
    {
        name: PATH_ROUTER.RECRUITMENT_REPORT.NAME,
        value: PATH_ROUTER.RECRUITMENT_REPORT.VALUE,
        to: PATH_ROUTER.RECRUITMENT_REPORT.PATH,
        permission: PATH_ROUTER.RECRUITMENT_REPORT.PERMISSION,
    },
    // {
    //     name: PATH_ROUTER.RECRUITMENT_LIST.NAME,
    //     value: PATH_ROUTER.RECRUITMENT_LIST.VALUE,
    //     to: PATH_ROUTER.RECRUITMENT_LIST.PATH,
    //     permission: PATH_ROUTER.RECRUITMENT_LIST.PERMISSION,
    // },

    // {
    //     name: PATH_ROUTER.CANDIDATE_LIST.NAME,
    //     value: PATH_ROUTER.CANDIDATE_LIST.VALUE,
    //     to: PATH_ROUTER.CANDIDATE_LIST.PATH,
    //     permission: PATH_ROUTER.CANDIDATE_LIST.PERMISSION,
    // },
]
const ldMenu: Array<routerModel> = [
    {
        name: PATH_ROUTER.LD_LEARNING_DETAIL.NAME,
        value: PATH_ROUTER.LD_LEARNING_DETAIL.VALUE.replace("DCR", labelUserType.value),
        to: PATH_ROUTER.LD_LEARNING_DETAIL.PATH,
        permission: PATH_ROUTER.LD_LEARNING_DETAIL.PERMISSION,
        icon: 'dashboard',
        colorIcon: '#399E9F',
    },
    {
        name: PATH_ROUTER.SATURDAY_LEARNING.NAME,
        value: PATH_ROUTER.SATURDAY_LEARNING.VALUE,
        to: PATH_ROUTER.SATURDAY_LEARNING.PATH,
        permission: PATH_ROUTER.SATURDAY_LEARNING.PERMISSION,
        icon: 'dashboard',
        colorIcon: '#F59E0B',
    },
    {
        name: PATH_ROUTER.LEARNING_REPORT.NAME,
        value: PATH_ROUTER.LEARNING_REPORT.VALUE,
        to: PATH_ROUTER.LEARNING_REPORT.PATH,
        permission: PATH_ROUTER.LEARNING_REPORT.PERMISSION,
        icon: 'insert_chart',
        colorIcon: '#399E9F',
    },
    {
        name: PATH_ROUTER.LEARNING_SETTINGS.NAME,
        value: PATH_ROUTER.LEARNING_SETTINGS.VALUE,
        to: PATH_ROUTER.LEARNING_SETTINGS.PATH,
        permission: PATH_ROUTER.LEARNING_SETTINGS.PERMISSION,
        icon: 'settings_suggest',
        colorIcon: '#b6007a',
    },
    {
        name: PATH_ROUTER.TARGET_MANAGEMENT.NAME,
        value: PATH_ROUTER.TARGET_MANAGEMENT.VALUE,
        to: PATH_ROUTER.TARGET_MANAGEMENT.PATH,
        permission: PATH_ROUTER.TARGET_MANAGEMENT.PERMISSION,
        icon: 'assessment',
        colorIcon: '#F59E0B',
    },
    {
        name: PATH_ROUTER.OB_RESULT_ADJUSTMENT.NAME,
        value: PATH_ROUTER.OB_RESULT_ADJUSTMENT.VALUE,
        to: PATH_ROUTER.OB_RESULT_ADJUSTMENT.PATH,
        permission: PATH_ROUTER.OB_RESULT_ADJUSTMENT.PERMISSION,
        icon: 'photo_filter',
        colorIcon: '#399E9F',
    },
]

const MENUS_AGENCY: Array<routerModel> = [
    {
        name: PATH_ROUTER.WSR_LIST.NAME,
        value: PATH_ROUTER.WSR_LIST.VALUE,
        to: PATH_ROUTER.WSR_LIST.PATH,
        permission: PATH_ROUTER.WSR_LIST.PERMISSION,
     },
]

const menuContent = [
    {
        name: 'content-news',
        value: 'Tin tức',
        to: '/spvb/content/news',
    },
    {
        name: 'content-feedbacks',
        value: 'Cảm nhận, đánh giá',
        to: '/spvb/content/feedbacks',
    },
    {
        name: 'content-files',
        value: 'Quản lý file',
        to: '/spvb/content/file',
    },
    {
        name: 'content-settings',
        value: 'Cài đặt HR',
        to: '/spvb/content/setting-hr',
    },
    {
        name: 'content-settings',
        value: 'Cài đặt LD',
        to: '/spvb/content/setting-ld',
    },
]

const navItems = computed(() => {
    let _menu: Array<routerModel> = []
    let listMenu = MENUS

    if (props.name === '2') {
        listMenu = ldMenu
    } else if (props.name === '3') {
        listMenu = MENUS_AGENCY
    } else {
        if (userStore.currentUser?.role.short_name === 'ss' || userStore.currentUser?.role.short_name === 'asm') {
            listMenu = MENUS_SS
        }
    }

    if (props.module == 'content') {
        return menuContent
    }

    if (userStore.currentUser) {
        listMenu.forEach((item: any) => {
            if (
                userStore.currentUser.role.admin_access ||
                item.permission.includes(userStore.currentUser.role.short_name)
            ) {
                _menu.push({
                    name: item.name,
                    to: item.to,
                    value: item.value,
                    permission: item.permission,
                    icon: item?.icon,
                    colorIcon: item?.colorIcon,
                })
            }
        })
    }
    return _menu
})
</script>
<style lang="scss">
.menu-list {
    .v-list.nav {
        .v-list-item.router-link-active,
        .v-list-item:hover {
            background-color: #def4f5 !important;
            color: #399e9f !important;
            font-weight: 700 !important;
        }
    }
}

.menu-list {
    height: calc(100% - 5px);
}

.menu-list .link-space .v-list-item-content {
    height: 1px;
    padding: 0;
    background-color: #e0e0e0;
}

.menu-list .link-space {
    min-height: 0 !important;
}
</style>
