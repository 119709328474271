<template>
    <c-role :can-access-page="canAccessPage">
        <private-view>
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title>
                <h1 class="type-title" v-html="titleHead"></h1>
            </template>
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #actions>
                <c-access :roles="[]">
                    <v-dialog v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <v-icon name="delete" outline />
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" icon="delete" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-save">
                        <v-button
                            v-tooltip.bottom="SAVE"
                            icon
                            rounded
                            @click="saveForm"
                            :loading="commonStore.loading"
                            :disabled="commonStore.loading"
                        >
                            <v-icon name="check" />
                        </v-button>
                    </div>
                </c-access>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->
            <template v-if="objRecruitmentRequestDetail.id">
                <template v-if="canManager">
                    <div class="flex justify-center mb-8">
                        <div class="list-btn-tab-detail">
                            <v-button
                                class="first"
                                :class="activeTabFormDetail ? 'active' : ''"
                                secondary
                                @click="activeTabFormDetail = !activeTabFormDetail"
                            >
                                <v-icon class="mr-2" name="person" />
                                Chi tiết yêu cầu
                            </v-button>
                            <v-button
                                class="last"
                                secondary
                                :class="!activeTabFormDetail ? 'active' : ''"
                                @click="activeTabFormDetail = !activeTabFormDetail"
                            >
                                <v-icon class="mr-2" name="people_alt" />
                                Danh sách ứng viên
                            </v-button>
                        </div>
                    </div>
                    <DetailForm
                        ref="formElement"
                        v-if="activeTabFormDetail && objRecruitmentRequestDetail?.id"
                        :id="route?.params?.id"
                        class="mb-10"
                    ></DetailForm>
                    <!-- <recruitment-request-view
                        v-if="activeTabFormDetail && objRecruitmentRequestDetail?.id"
                        :key="objRecruitmentRequestDetail.id"
                        ref="formDetail"
                        :item="objRecruitmentRequestDetail"
                    /> -->
                    <recruitment-request-history
                        v-if="activeTabFormDetail && objRecruitmentRequestDetail?.id"
                        :idRequestRecruitment="objRecruitmentRequestDetail?.id"
                    />
                    <recruitment-request-candidate
                        v-if="!activeTabFormDetail && objRecruitmentRequestDetail?.id"
                        :role="userStore?.currentUser?.role?.short_name || ''"
                        :requestId="objRecruitmentRequestDetail?.id"
                    />
                </template>
                <template v-else>
                    <div class="text-center text-red">Yêu cầu này không thuộc quản lý của bạn</div>
                </template>
            </template>

            <!--  content end -->
            <template #sidebar>
                <sidebar-detail icon="info" title="Chi tiết yêu cầu" close />

                <recruitment-request-sidebar
                    v-if="objRecruitmentRequestDetail && displayQuckActionStatus"
                    :idRecruitmentRequest="objRecruitmentRequestDetail?.id"
                    :statusRecruitmentRequest="objRecruitmentRequestDetail?.status"
                    @handle-submit="navigateBack"
                    :detail="objRecruitmentRequestDetail"
                ></recruitment-request-sidebar>
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useBatch } from '@/shared-fe/composables/use-batch'
import { useRecruitmentRequestQuery } from '@/shared-fe/services/recruitment-request-service'
import { useStoreCommon } from '@/shared-fe/stores/common'
import { FORMAT_TIME, RECRUITMENT_REQUEST_STATUS, USER_ROLE } from '@/shared-fe/utils/constant'
import { CANCEL, CONTENT_DELETE, LABEL_DELETE, SAVE } from '@/shared-fe/utils/message'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { RECRUITMENT_REQUEST_COLLECTION } from '@/shared-fe/utils/table-name'
import { useUserStore } from '@/stores/user'
import { useStores } from '@directus/extensions-sdk'
import dayjs from 'dayjs'
import { computed, onMounted, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import recruitmentRequestCandidate from '../components/recruitment-request-candidate.vue'
import RecruitmentRequestSidebar from '../components/recruitment-request-sidebar.vue'
import RecruitmentRequestHistory from '../components/recruitment-request-history.vue'

import DetailForm from './../components/detail/form.vue'

const canAccessPage = ref(true)
const { getItemRecruitmentRequest } = useRecruitmentRequestQuery()
const formElement = ref(null)
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
const activeTabFormDetail = ref(true)

sidebarOpen.value = true
const route = useRoute()
const router = useRouter()
const commonStore = useStoreCommon()
const formDetail = ref(null)

const objRecruitmentRequestDetail = ref(<any>{})

const titleHead = computed(() => {
    return `${PATH_ROUTER.RECRUITMENT_REQUEST_DETAIL.VALUE}<span style='color: #62b1eb'>(${route?.params?.id})</span>`
})
// get data detail (start)
onMounted(async () => {
    if (route?.params?.id) {
        await getDetail()
    }
})

const displayQuckActionStatus = computed(() => {
    return (
        objRecruitmentRequestDetail.value.status !== RECRUITMENT_REQUEST_STATUS.completed.value &&
        objRecruitmentRequestDetail.value.status !== RECRUITMENT_REQUEST_STATUS.rejected.value
    )
})

const getDetail = async () => {
    await getItemRecruitmentRequest(route?.params?.id.toString())
        .then((res) => {
            let item = res?.data?.data
            objRecruitmentRequestDetail.value = {
                id: item.id,
                hiring_reason: item.hiring_reason,
                providers: item.providers,
                employment_type: item.employment_type,
                province: item.province?.id,
                districts: item.districts,
                start_date: item.start_date,
                start_display: item.start_display,
                quantity: item.quantity,
                content: item.content,
                staff_id: item.staff_id?.id,
                user_created: item.user_created,
                status: item.status,
                completed_date: item.completed_date ? dayjs(item.completed_date).format(FORMAT_TIME.DDMMYYYY) : '',
                date_created: item.date_created,
                ss_id: item.user_created?.id,
                position: item.position,
                rotation_date: item.rotation_date,
                reason_rotation: item.reason_rotation,
                resign_id: item.resign_id,
            }
        })
        .catch((e) => {
            console.log(e)
            //canAccessPage.value = false
        })
}

// get data detail (end)

// handle logic header (start)
const saveForm = () => {
    formElement.value.save()
}

const navigateBack = () => {
    router.push({
        name: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.NAME,
    })
}

const batchDeleteFun = async () => {
    await batchDelete('status', objRecruitmentRequestDetail.value.id)
    router.push({
        name: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.NAME,
    })
}

const {
    deleting,
    error: deleteError,
    modelSelected,
    batchDelete,
    confirmDelete,
} = useBatch(RECRUITMENT_REQUEST_COLLECTION)
// handle logic header (end)

const userStore = useUserStore()
const canManager = computed(() => {
    if (userStore.currentUser.role.short_name == USER_ROLE.HR) {
        const ares_management = userStore.currentUser?.ares_management?.map((a) => a.id)
        return ares_management.indexOf(objRecruitmentRequestDetail.value.user_created.area) >= 0
    }
    return true
})
</script>

<style lang="scss" scoped></style>
