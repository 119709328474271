<template>
    <div>
        <div v-if="itemsRender.length >= 0">
            <v-table
                class="c-table table-dcr mt-8"
                :headers="canSs || canSAdmin ? headers : headersRole"
                :items="itemsRender"
                show-resize
                v-model:modelValue="modelValue"
                :disabled="disabled"
                manualSortKey="tel"
                :sort="internalSort"
                @update:sort="updateSort($event)"
                showSelect="multiple"
                :loading="props.loading"
            >
                <template #[`item.user`]="{ item }">
                    <c-expand-list
                        :maxItemDisplay="3"
                        :showListNumbers="true"
                        :data="item.user.map((x) => x.directus_users_id.full_name)"
                        :showMoreText="true"
                    ></c-expand-list>
                </template>
                <template #[`item.status`]="{ item }">
                    <c-status :collectionName="SESSION_TRAINS_COLLECTION" :id="item.status"></c-status>
                </template>
                <template #[`item.date_created`]="{ item }">
                    {{ item.date_created ? dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) : '' }}
                </template>
                <template #[`item.dismiss`]="{ item }">
                    <span
                        v-if="item.status === SESSIONS_TRAIN_STATUS.in_progress.value"
                        class="text-[#62B1EB]"
                        @click="goToDetail(item)"
                    >
                        {{ CONTINUE }}
                        <v-icon class="ml-2" name="arrow_forward" />
                    </span>
                    <span v-if="item.status === SESSIONS_TRAIN_STATUS.completed.value" class="text-[#62B1EB]">
                        {{ REPORT }}
                        <v-icon class="ml-2" name="arrow_forward" />
                    </span>
                </template>
                <template v-if="canSs || canSAdmin" #[`item.reset`]="{ item }">
                    <span class="custom-button-export-excel">
                        <v-button rounded icon @click="resetChannel(item)">
                            <v-icon secondary name="refresh" />
                        </v-button>
                    </span>
                </template>
            </v-table>
            <div class="footer">
                <div class="c-pagi pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="10"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
        <div v-else class="mx-auto max-w-[540px] border border-solid border-[#E2E8F0] rounded-xs bg-white">
            <div class="flex justify-center flex-col items-center px-10 pt-10 pb-12">
                <div class="p-[30px] rounded-full bg-[#F3F4F6] mb-6">
                    <v-icon x-large name="no_accounts" />
                </div>
                <div class="text-[#1E293B] text-xl font-bold text-center mb-8">{{ NO_DCR_TO_TRAIN }}</div>
                <div class="text-[#64748B] text-center mb-8">{{ NO_DCR_TO_TRAIN_DETAIL }}</div>
                <span class="custom-button-btn text-center">
                    <v-button v-for="item in channels"  @click="() => goToModuleList(item.id)" class="learning-list mr-5 mb-3">
                        <v-icon class="" name="library_books" />
                        <span class="ml-2">{{  item.name  }}</span>
                    </v-button>
                </span>
            </div>
        </div>
    </div>
    <!-- -->
    <modal-choose-channel
        :is-show="resetChannelModal"
        @submit="(channel: string) => 
            handleResetChannel(channel)
        "
        @close="resetChannelModal = false"
        mode="edit"
        :channel="itemReset?.course?.channel?.id"
    ></modal-choose-channel>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { NO_DCR_TO_TRAIN, NO_DCR_TO_TRAIN_DETAIL, CONTINUE, REPORT } from '../../../../../shared-fe/utils/message'
import {
    CONFIRM_MODAL_TEXT,
    FORMAT_TIME,
    SESSIONS_TRAIN_STATUS,
TYPE_OF_TRAIN,
} from '../../../../../shared-fe/utils/constant'
import { SESSION_TRAINS_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import CExpandList from '../../../../../shared-fe/components/c-table/c-expand-list/c-expand-list.vue'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import ModalChooseChannel from './modal-choose-channel.vue'
import { useTrainingQuery } from '@/shared-fe/services/training-service'
import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import { useUserStore } from '@/stores/user'
const userStore = useUserStore();

const channels = ref(userStore.currentUser[TYPE_OF_TRAIN.OB]);
 const goToModuleList = (id: string) => {
    router.push({
        name: PATH_ROUTER.MODULE_LIST.NAME,
        params: {
            channel: id,
        },
    })
}
const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}
const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'updateSort', 'refresh'])

const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const resetChannelModal = ref(false)
const itemReset = ref(<any>null)
const { updateSessionTrainChannel } = useTrainingQuery()
const { canSs, canSAdmin } = useAppPermissions()
const confirmModal = useConfirmModal()
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})

const itemsRender = computed(() => {
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return []
})

const headers = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 70,
    },
    {
        text: 'Nhân viên',
        value: 'user',
        width: 300,
    },
    {
        text: 'Ngày bắt đầu',
        value: 'date_created',
    },
    {
        text: 'SS',
        value: 'user_created.full_name',
    },
    {
        text: 'Trạng thái',
        value: 'status',
    },
    {
        text: 'Kênh bán hàng',
        value: 'course.channel.name',
    },
    {
        text: 'Thao tác',
        value: 'dismiss',
    },
    {
        text: 'Reset',
        value: 'reset',
    },
])

const headersRole = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 70,
    },
    {
        text: 'Nhân viên',
        value: 'user',
        width: 300,
    },
    {
        text: 'Ngày bắt đầu',
        value: 'date_created',
    },
    {
        text: 'SS',
        value: 'user_created.full_name',
    },
    {
        text: 'Trạng thái',
        value: 'status',
    },
    {
        text: 'Thao tác',
        value: 'dismiss',
    },
])

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    let sortKey = sort?.by
    if (sort?.desc) sortKey = `-${sortKey}`
    emit('updateSort', sortKey)
}

const goToDetail = (item: any) => {
    router.push({
        name: PATH_ROUTER.DCR_ONBOARDING_DETAIL.NAME,
        params: {
            session_train: item.id,
        },
    })
}

const resetChannel = (item: any) => {
    resetChannelModal.value = true
    itemReset.value = item
}

const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
    resetChannelModal.value = false
}

const handleResetChannel = (channelData: string) => {
    if (!channelData) {
        confirmModal.handleOpenWarningModal('Bạn chưa chọn lại kênh đào tạo, vui lòng chọn và xác nhận')
        resetChannelModal.value = true
    } else {
        handleConfirm(CONFIRM_MODAL_TEXT.CHANGE_CHANNEL.CONFIRM, async () => await handleReset(channelData))
    }
}

const handleReset = async (channelId: any) => {
    const params = {
        channel: channelId,
    }
    await updateSessionTrainChannel(itemReset.value?.id, params)
    itemReset.value = null
    emit('refresh')
}

</script>

<style lang="scss">
.btn-continue-or-report {
    .outlined {
        font-weight: 500;
        font-size: 14px;
        border: none !important;
    }
}
</style>
