<template>
    <div>
        <v-table
            class="c-table"
            :headers="fullAccess ? headers : headersForAsmAndSS"
            :items="itemsRender"
            show-resize
            v-model:modelValue="modelValue"
            :disabled="disabled"
            manualSortKey="tel"
            :loading="loading"
            :sort="internalSort"
            @update:sort="updateSort($event)"
            showSelect="multiple"
            @click:row="detailRowOnClick"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->
            <template #[`item.id`]="{ item }">
                <span style="color: #62b1eb">{{ item.id }}</span>
            </template>
            <template #[`item.status`]="{ item }">
                <c-status :collectionName="RECRUITMENT_REQUEST_COLLECTION" :id="item.status"></c-status>
            </template>
            <template #[`item.total_request_date`]="{ item }">
                <span>{{ `${item.total_request_date}` }}</span>
            </template>
            <template #[`item.action`]="{ item }">
                <div class="flex gap-2 actions">
                    <span
                        v-for="action in actions"
                        class="action w-9 h-9 text-center rounded-full block flex justify-center items-center"
                        :style="{ background: action.bg }"
                    >
                        <div
                            :class="action.icon_name"
                            :style="{ color: action.color, fontSize: '14px' }"
                            @click="() => action.onClick(item)"
                        ></div>
                    </span>
                </div>
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { ActionTableModel } from '../../../../../shared-fe/types/table-type'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { RECRUITMENT_REQUEST_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { USER_ROLE } from '../../../../../shared-fe/utils/constant'

const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}

const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const { canAccess } = usePermission()
const fullAccess = computed(() => {
    return canAccess([USER_ROLE.HR])
})

const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'updateSort'])

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 90,
    },
    {
        text: 'Mã YCTD',
        value: 'id',
        width: 100,
    },
    {
        text: 'Ngày yêu cầu',
        value: 'date_created',
    },
    {
        text: 'Người yêu cầu',
        value: 'recruitment_request_person',
    },
    {
        text: 'Trạng thái',
        value: 'status',
    },
    {
        text: 'Lý do tuyển',
        value: 'hiring_reason',
    },
    {
        text: 'Chi nhánh',
        value: 'zone',
    },
    {
        text: 'Khu vực',
        value: 'area',
    },
    {
        text: 'Nhà phân phối',
        value: 'provider_name',
    },
    {
        text: 'Mã Route',
        value: 'staff_route_code',
    },
    {
        text: 'Họ và tên',
        value: 'staff_full_name',
    },
    {
        text: 'Ứng viên',
        value: 'total_candidate_linked',
    },
    {
        text: 'Đạt PV1',
        value: 'total_pass_interview_1',
    },
    {
        text: 'Đạt PV2',
        value: 'total_pass_interview_2',
    },
    {
        text: 'Đạt Route Ride',
        value: 'total_pass_route_ride',
    },
    {
        text: 'Đạt PV3',
        value: 'total_pass_interview_3',
    },
    {
        text: 'Đã nhận việc',
        value: 'total_pass_pass_job',
    },
    {
        text: 'Số ngày tuyển dụng',
        value: 'total_request_date',
        width: 200,
    },
    {
        text: 'Người phụ trách',
        value: 'hr_name',
    },
])

const headersForAsmAndSS = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 150,
    },
    {
        text: 'Mã YCTD',
        value: 'id',
        width: 150,
    },
    {
        text: 'Ngày yêu cầu',
        value: 'date_created',
    },
    {
        text: 'Người yêu cầu',
        value: 'recruitment_request_person',
    },
    {
        text: 'Trạng thái',
        value: 'status',
    },
    {
        text: 'Lý do tuyển',
        value: 'hiring_reason',
    },
    {
        text: 'Nhà phân phối',
        value: 'provider_name',
    },
    {
        text: 'Mã Route',
        value: 'staff_route_code',
    },
    {
        text: 'Họ và tên',
        value: 'staff_full_name',
    },
    {
        text: 'Ứng viên',
        value: 'total_candidate_linked',
    },
    {
        text: 'Đạt PV1',
        value: 'total_pass_interview_1',
    },
    {
        text: 'Đạt PV2',
        value: 'total_pass_interview_2',
    },
    {
        text: 'Đạt Route Ride',
        value: 'total_pass_route_ride',
    },
    {
        text: 'Đạt PV3',
        value: 'total_pass_interview_3',
    },
    {
        text: 'Đã nhận việc',
        value: 'total_pass_pass_job',
    },
    {
        text: 'Người phụ trách',
        value: 'hr_name',
    },
])

const detailRowOnClick = (data: any) => {
    router.push({
        name: PATH_ROUTER.RECRUITMENT_REQUEST_DETAIL.NAME,
        params: {
            id: data.item.id,
        },
    })
}

const actions: ActionTableModel[] = [
    {
        icon_name: 'i-mdi-pencil-outline',
        onClick: detailRowOnClick,
        bg: '#F1F5F9',
        color: '#64748B',
    },
]
const convertKeySort = (sortKey, desc) => {
    let key = ''
    switch (sortKey) {
        case 'recruitment_request_person':
            key = 'user_created.full_name'
            break
        case 'zone':
            key = 'providers.zone.name'
            break
        case 'area':
            key = 'providers.area.name'
            break
        case 'provider_name':
            key = 'providers.name'
            break
        case 'province_name':
            key = 'province.name'
            break
        case 'staff_route_code':
            key = 'staff_id.route_code.name'
            break
        case 'staff_code':
            key = 'staff_id.code'
            break
        case 'staff_full_name':
            key = 'staff_id.full_name'
            break
        case 'hr_name':
            key = 'hr_id.full_name'
            break
        default:
            key = sortKey
            break
    }
    if (desc) key = `-${key}`
    return key
}
const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    if (
        sort?.by !== 'total_candidate_linked' &&
        sort?.by !== 'stt' &&
        sort?.by !== 'total_pass_interview_1' &&
        sort?.by !== 'total_pass_interview_2' &&
        sort?.by !== 'total_pass_interview_3' &&
        sort?.by !== 'total_pass_route_ride' &&
        sort?.by !== 'total_pass_pass_job' &&
        sort?.by !== 'total_request_date'
    )
        emit('updateSort', convertKeySort(sort?.by, sort?.desc))
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
