<template>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9">
        <div class="info-request font-bold text-[var(--foreground-normal-alt)] text-6 mb-8">Đánh giá của SS</div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">Ngày công</div>
                <div class="flex items-center h-[var(--input-height)]">
                    <c-toggle
                        :disabled="assessmented"
                        v-model="formData.work_day_status"
                        @handleChange="(e) => handleChange(e, 'work_day_status')"
                    ></c-toggle>
                    <div class="ml-3">{{ workDayStatus }}</div>
                </div>
            </div>
            <div class="col-span-1 lg:col-span-2">
                <div class="type-label">Số ngày công trong tháng</div>
                <v-input
                    type="text"
                    :disabled="assessmented"
                    placeholder="Nhập nội dung..."
                    v-model="formData.work_day"
                    @update:model-value="(e) => handleChange(e, 'work_day')"
                ></v-input>
                <span class="message-error" v-if="errors?.work_day">Vui lòng nhập nội dung</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">Tuân thủ kỷ luật</div>
                <div class="flex items-center h-[var(--input-height)]">
                    <c-toggle
                        :disabled="assessmented"
                        v-model="formData.compliance_status"
                        @handleChange="(e) => handleChange(e, 'compliance_status')"
                    ></c-toggle>
                    <div class="ml-3">{{ complianceStatus }}</div>
                </div>
            </div>
            <div class="col-span-1 lg:col-span-2">
                <div class="type-label">Tỷ lệ ghé thăm điểm bán</div>
                <v-input
                    type="text"
                    :disabled="assessmented"
                    placeholder="Nhập nội dung..."
                    v-model="formData.compliance"
                    @update:model-value="(e) => handleChange(e, 'compliance')"
                ></v-input>
                <span class="message-error" v-if="errors?.compliance">Vui lòng nhập nội dung</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">Thực thi tại điểm bán</div>
                <div class="flex items-center h-[var(--input-height)]">
                    <c-toggle
                        :disabled="assessmented"
                        v-model="formData.execution_point_status"
                        @handleChange="(e) => handleChange(e, 'execution_point_status')"
                    ></c-toggle>
                    <div class="ml-3">{{ executionPointStatus }}</div>
                </div>
            </div>
            <div class="col-span-1 lg:col-span-2">
                <div class="type-label">Kết quả thực thi các KPI</div>
                <v-input
                    type="text"
                    :disabled="assessmented"
                    placeholder="Nhập nội dung..."
                    v-model="formData.execution_point"
                    @update:model-value="(e) => handleChange(e, 'execution_point')"
                ></v-input>
                <span class="message-error" v-if="errors?.execution_point">Vui lòng nhập nội dung</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">Sản lượng</div>
                <div class="flex items-center h-[var(--input-height)]">
                    <c-toggle
                        :disabled="assessmented"
                        v-model="formData.sales_volume_status"
                        @handleChange="(e) => handleChange(e, 'sales_volume_status')"
                    ></c-toggle>
                    <div class="ml-3">{{ salesVolumeStatus }}</div>
                </div>
            </div>
            <div class="col-span-1 lg:col-span-2">
                <div class="type-label">Sản lượng bán ra so với chỉ tiêu</div>
                <v-input
                    type="text"
                    :disabled="assessmented"
                    placeholder="Nhập nội dung..."
                    v-model="formData.sales_volume"
                    @update:model-value="(e) => handleChange(e, 'sales_volume')"
                ></v-input>
                <span class="message-error" v-if="errors?.sales_volume">Vui lòng nhập nội dung</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">Thái độ làm việc</div>
                <div class="flex items-center h-[var(--input-height)]">
                    <c-toggle
                        :disabled="assessmented"
                        v-model="formData.work_attitude_status"
                        @handleChange="(e) => handleChange(e, 'work_attitude_status')"
                    ></c-toggle>
                    <div class="ml-3">{{ workAttitudeStatus }}</div>
                </div>
            </div>
            <div class="col-span-1 lg:col-span-2">
                <div class="type-label">Thái độ và tinh thần làm việc</div>
                <v-input
                    type="text"
                    :disabled="assessmented"
                    placeholder="Nhập nội dung..."
                    v-model="formData.work_attitude"
                    @update:model-value="(e) => handleChange(e, 'work_attitude')"
                ></v-input>
                <span class="message-error" v-if="errors?.work_attitude">Vui lòng nhập nội dung</span>
            </div>

            <div class="col-span-1 lg:col-span-3">
                <div class="type-label">Đánh giá chi tiết</div>
                <v-textarea
                    :disabled="assessmented"
                    :model-value="formData.ss_note"
                    @update:model-value="(e) => handleChange(e, 'ss_note')"
                />
                <span class="message-error" v-if="errors?.ss_note">Vui lòng nhập nội dung</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">
                    Kết quả thử việc
                    <span class="required">*</span>
                </div>
                <v-select
                    :model-value="formData.ss_status"
                    :items="dropdownStatus"
                    :disabled="assessmented"
                    @update:model-value="(e) => handleChange(e, 'ss_status')"
                ></v-select>
                <span class="message-error" v-if="errors?.ss_status">Vui lòng chọn trạng thái</span>
            </div>
            <!-- <div class="col-span-1 items-start">
                <div class="type-label">&#160</div>
                <div class="h-[var(--input-height)]">
                    <v-button :disabled="assessmented" secondary @click="updateStatus" class="custom-style-button">Xác nhận đánh giá</v-button>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script setup lang="ts">
import Joi from 'joi'
import _ from 'lodash'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import CToggle from '../../../../../shared-fe/components/c-toggle/c-toggle.vue'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import {
    CONFIRM_MODAL_TEXT,
    FORMAT_TIME,
    MODE_EDIT,
    SUBMIT_EVENT,
    USER_ROLE,
    USER_STATUS,
} from '../../../../../shared-fe/utils/constant'
import { useProbationaryAssessmentQuery } from '../../../../../shared-fe/services/probationary-assessment-service'
import { useRouter } from 'vue-router'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import dayjs from 'dayjs'
import { useUserStore } from '@/stores/user'

const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
    dropdownStatus: {
        type: Array<Object>,
        default: null,
    },
    startDate: {
        type: String,
        default: '',
    },
    endDate: {
        type: String,
        default: '',
    },
})
const commonStore = useStoreCommon()
const { patchItemProbationaryAssessment } = useProbationaryAssessmentQuery()
const errors: any = ref(null)
const confirmModal = useConfirmModal()
const router = useRouter()
const emit = defineEmits(['update:handleChange'])
const dataDefault = {
    id: null,
    work_day_status: null,
    work_day: null,
    work_attitude: null,
    work_attitude_status: null,
    compliance_status: null,
    compliance: null,
    execution_point: null,
    execution_point_status: null,
    sales_volume: null,
    sales_volume_status: null,
    // percentage_total: null,
    ss_note: null,
    ss_status: null,
}
const userStore = useUserStore()

const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
}

const assessmented = computed(() => {
    const parts = props.endDate.split('/')
    const formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`
    let validEndDate = dayjs(new Date(formattedDateString)).add(60, 'day').format(FORMAT_TIME.YYYMMDD)
    let validDay = dayjs(new Date(formattedDateString)).add(-10, 'day').format(FORMAT_TIME.YYYMMDD)
    const currentDate = dayjs().format(FORMAT_TIME.YYYMMDD)
    if (dayjs(validEndDate).isBefore(currentDate)) {
        return true
    }
    // Trong vòng 10 ngày trước ngày kết thúc thử việc được phép đánh giá
    if (dayjs(currentDate).isBefore(dayjs(validDay))) {
        return true
    }
    if (props.item?.ss_status !== null) {
        return true
    }
    if (![USER_ROLE.SS].includes(userStore.currentUser?.role.short_name)) {
        return true
    }
    return false
})

const workDayStatus = computed(() => {
    if (formData.value.work_day_status === null) {
        return 'Chưa đánh giá'
    }
    return formData.value.work_day_status ? 'Đạt' : 'Không đạt'
})
const workAttitudeStatus = computed(() => {
    if (formData.value.work_attitude_status === null) {
        return 'Chưa đánh giá'
    }
    return formData.value.work_attitude_status ? 'Đạt' : 'Không đạt'
})
const complianceStatus = computed(() => {
    if (formData.value.compliance_status === null) {
        return 'Chưa đánh giá'
    }
    return formData.value.compliance_status ? 'Đạt' : 'Không đạt'
})
const executionPointStatus = computed(() => {
    if (formData.value.execution_point_status === null) {
        return 'Chưa đánh giá'
    }
    return formData.value.execution_point_status ? 'Đạt' : 'Không đạt'
})
const salesVolumeStatus = computed(() => {
    if (formData.value.sales_volume_status === null) {
        return 'Chưa đánh giá'
    }
    return formData.value.sales_volume_status ? 'Đạt' : 'Không đạt'
})
const ssStatus = computed(() => {
    if (formData.value.ss_status === null) {
        return 'Chưa đánh giá'
    }
    return formData.value.ss_status ? 'Đạt' : 'Không đạt'
})

const defaultDataEdit = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const formData = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const handleChange = (e: any, variable: any) => {
    switch (variable) {
        default:
            formData.value[variable] = e
            break
    }
    emitData()
}

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(formData.value)) {
        formData.value[key] = props.item[key]
    }

    defaultDataEdit.value = JSON.parse(JSON.stringify(formData.value))
}

watch(
    () => props.item,
    async () => {
        if (props.item) {
            initData()
        }
    },
    { immediate: true }
)

const emitData = () => {
    emit('update:handleChange', _.isEqual(defaultDataEdit.value, formData.value))
}

const validateForm = () => {
    errors.value = null
    if (!formData.value.work_day) {
        errors.value = {
            ...errors.value,
            work_day: 'work_day bắt buộc nhập',
        }
    }

    if (!formData.value.work_attitude) {
        errors.value = {
            ...errors.value,
            work_attitude: 'work_attitude bắt buộc nhập',
        }
    }
    if (!formData.value.compliance) {
        errors.value = {
            ...errors.value,
            compliance: 'compliance bắt buộc nhập',
        }
    }
    if (!formData.value.execution_point) {
        errors.value = {
            ...errors.value,
            execution_point: 'execution_point bắt buộc nhập',
        }
    }
    if (!formData.value.sales_volume) {
        errors.value = {
            ...errors.value,
            sales_volume: 'sales_volume bắt buộc nhập',
        }
    }
}

// const updateStatus = async () => {
//     validateForm();
//     if (formData.value.ss_status === null) {
//         errors.value = {
//             ...errors.value,
//             ss_status: 'ss_status bắt buộc nhập',
//         }
//     }

//     if (errors.value !== null && Object.keys(errors.value).length !== 0) {
//             return
//     }

//     let params = JSON.parse(JSON.stringify(formData.value))
//     delete params.id

//     await patchItemProbationaryAssessment(formData.value.id, {
//         ...params,
//     })

//     await router.push({
//         name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
//     })
// }
const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.PROBATIONARY_ASSESSMENTS_SUBMIT)
    if (payloadSubmitForm) {
        validateForm()
        if (formData.value.ss_status === null) {
            errors.value = {
                ...errors.value,
                ss_status: 'ss_status bắt buộc nhập',
            }
        }
        if (errors.value !== null && Object.keys(errors.value).length !== 0) {
            return
        }
        let params = JSON.parse(JSON.stringify(formData.value))
        delete params.id
        // delete params.ss_status

        if (payloadSubmitForm.payload === MODE_EDIT) {
            handleConfirm(
                params.ss_status
                    ? CONFIRM_MODAL_TEXT.PROBATIONARY_ASSESSMENTS.OK
                    : CONFIRM_MODAL_TEXT.PROBATIONARY_ASSESSMENTS.REJECT,
                async () => await handleSubmit(params)
            )
        }
    }
}

const handleSubmit = async (params: any) => {
    await patchItemProbationaryAssessment(formData.value.id, params)
    commonStore.showDialogSucessUpdate = true
    router.push({
        name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
    })
}
const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})
</script>
