<template>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9">
        <div class="grid grid-cols-1 gap-8">
            <div class="col-span-1 lg:w-1/2">
                <div class="type-label">
                    {{ POST_AVATAR }}
                    <span class="required">*</span>
                </div>
                <interface-file-image
                    :collection="RECRUITMENT_COLLECTION"
                    :crop="true"
                    :disabled="false"
                    field="image"
                    v-model:value="formData.image"
                    @input="
                        (data) => {
                            formData.image = data
                        }
                    "
                    width="full"
                />
                <span class="message-error" v-if="errors?.image">{{ REQUIRED_UPLOAD_IMAGE }}</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">
                    {{ TITLE }}
                    <span class="required">*</span>
                </div>
                <v-input v-model="formData.title" @update:model-value="(e) => handleChange(e, 'title')"></v-input>
                <span class="message-error" v-if="errors?.title">{{ REQUIRED_TITLE }}</span>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
                <div class="col-span-1">
                    <div class="type-label">
                        Trạng thái
                        <span class="required">*</span>
                    </div>
                    <v-select
                        v-if="arrayStatus?.length > 0"
                        :model-value="formData?.status"
                        :items="arrayStatus"
                        showDeselect
                        @update:model-value="(e) => handleChange(e, 'status')"
                    ></v-select>
                    <span class="message-error" v-if="errors?.hiring_reason">Vui lòng nhập trạng thái</span>
                </div>

                <div class="col-span-1">
                    <div class="type-label">&#160</div>
                    <v-checkbox
                        :label="HOT_JOB"
                        :checked="formData?.is_hot"
                        @update:model-value="(e) => handleChange(e, 'is_hot')"
                    />
                </div>
            </div>

            <div class="col-span-1">
                <div class="type-label">
                    {{ WORK_STATION }}
                    <span class="required">*</span>
                </div>
                <c-select-multiple-m2o
                    :items="arrProvince"
                    :idTableLink="item?.id"
                    :dataSeleted="formData?.provinces"
                    :dataInit="provincesInit"
                    @update:handleChange="handleChangeDropdownProvince"
                    keyTableLink="recruitment_id"
                    keyTableMain="province_id"
                    tableMain="provinces"
                ></c-select-multiple-m2o>
                <div class="note-input">{{ CHOOSE_FIVE_PLACES }}</div>
                <span class="message-error" v-if="errors?.provinces">{{ REQUIRED_AREA_WORK }}</span>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div class="col-span-1">
                    <div class="type-label">
                        {{ MIN_SALARY }}
                    </div>
                    <v-input
                        type="number"
                        v-model="formData.salary_min"
                        @update:model-value="(e) => handleChange(e, 'salary_min')"
                    ></v-input>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        {{ MAX_SALARY }}
                    </div>
                    <v-input
                        type="number"
                        v-model="formData.salary_max"
                        @update:model-value="(e) => handleChange(e, 'salary_max')"
                    ></v-input>
                </div>
            </div>

            <div class="col-span-1">
                <v-checkbox
                    :label="NEGOTIATE"
                    :checked="formData?.salary_negotiate"
                    @update:model-value="(e) => handleChange(e, 'salary_negotiate')"
                />
            </div>

            <div class="col-span-1">
                <div class="type-label">
                    {{ JOB_DESCRIPTION }}
                    <span class="required">*</span>
                </div>
                <interface-input-rich-text-html
                    :value="formData.content"
                    :disabled="false"
                    @input="(e) => handleChange(e, 'content')"
                ></interface-input-rich-text-html>
                <div class="note-input">{{ MAX_CHAR_NUMBER }}</div>
                <span class="message-error" v-if="errors?.content">{{ REQUIRED_CONTENT_WORK }}</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">
                    {{ JOB_REQUIREMENT }}
                    <span class="required">*</span>
                </div>
                <interface-input-rich-text-html
                    @input="(e) => handleChange(e, 'requirement')"
                    :value="formData.requirement"
                    :disabled="false"
                ></interface-input-rich-text-html>
                <div class="note-input">{{ MAX_CHAR_NUMBER }}</div>
                <span class="message-error" v-if="errors?.requirement">{{ REQUIRED_REQUIREMENT }}</span>
            </div>

            <div class="col-span-1">
                <div class="type-label">
                    {{ BENEFIT }}
                    <span class="required">*</span>
                </div>
                <interface-input-rich-text-html
                    @input="(e) => handleChange(e, 'benefit')"
                    :value="formData.benefit"
                    :disabled="false"
                ></interface-input-rich-text-html>
                <div class="note-input">{{ MAX_CHAR_NUMBER }}</div>
                <span class="message-error" v-if="errors?.benefit">{{ REQUIRED_BENEFIT }}</span>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div class="col-span-1">
                    <div class="type-label">
                        {{ CONTACT_EMAIL }}
                        <span class="required">*</span>
                    </div>
                    <v-input
                        v-model="formData.contact_email"
                        @update:model-value="(e) => handleChange(e, 'contact_email')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.contact_email">{{ REQUIRED_EMAIL_CONTRACT }}</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        {{ CONTACT_NAME }}
                        <span class="required">*</span>
                    </div>
                    <v-input
                        v-model="formData.contact_name"
                        @update:model-value="(e) => handleChange(e, 'contact_name')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.contact_name">{{ REQUIRED_NAME_CONTRACT }}</span>
                </div>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div class="col-span-1">
                    <div class="type-label">
                        {{ CONTACT_PHONE_NUMBER }}
                        <span class="required">*</span>
                    </div>
                    <v-input
                        v-model="formData.contact_phone"
                        @update:model-value="(e) => handleChange(e, 'contact_phone')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.contact_phone">{{ REQUIRED_PHONE_CONTRACT }}</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        {{ SUBMIT_FORM_DEADLINE }}
                        <span class="required">*</span>
                    </div>
                    <v-menu ref="dateTimeMenu" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="formData.expired_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.expired ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.expired_display,
                                            'today-icon': !formData.expired_display,
                                        }"
                                        v-on="{ click: formData.expired ? unsetValue : null }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <c-date-picker
                            type="date"
                            :include-seconds="true"
                            :use-24="true"
                            :model-value="formData.expired"
                            @update:model-value="(e) => handleChange(e, 'expired')"
                            @close="dateTimeMenu?.deactivate"
                        />
                    </v-menu>
                    <span class="message-error" v-if="errors?.expired">{{ REQUIRED_EXPIRED_TIME }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Joi from 'joi'
import _ from 'lodash'
import dayjs from 'dayjs'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import CSelectMultipleM2o from '../../../../../shared-fe/components/c-select-multiple-m2o/c-select-multiple-m2o.vue'
import { useRecruitmentQuery } from '../../../../../shared-fe/services/recruitment-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { FORMAT_TIME, MODE_EDIT, RECRUITMENT_STATUS, SUBMIT_EVENT } from '../../../../../shared-fe/utils/constant'
import {
    BENEFIT,
    CHOOSE_FIVE_PLACES,
    CONTACT_EMAIL,
    CONTACT_NAME,
    CONTACT_PHONE_NUMBER,
    HOT_JOB,
    JOB_DESCRIPTION,
    JOB_REQUIREMENT,
    MAX_CHAR_NUMBER,
    MAX_SALARY,
    MIN_SALARY,
    NEGOTIATE,
    POST_AVATAR,
    RECRUITMENT_STAFF,
    REQUIRED_AREA_WORK,
    REQUIRED_BENEFIT,
    REQUIRED_CONTENT_WORK,
    REQUIRED_EMAIL_CONTRACT,
    REQUIRED_EXPIRED_TIME,
    REQUIRED_NAME_CONTRACT,
    REQUIRED_PHONE_CONTRACT,
    REQUIRED_REQUIREMENT,
    REQUIRED_TITLE,
    REQUIRED_UPLOAD_IMAGE,
    SUBMIT_FORM_DEADLINE,
    TITLE,
    WORK_STATION,
} from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { RECRUITMENT_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { RecruitmentDetailModel } from '../types'
import CDatePicker from '@/shared-fe/components/c-datepicker/c-datepicker.vue'
import { useProvinceQuery } from '../../../../../shared-fe/services/province-service'
import { useField } from '../../../../../shared-fe/composables/use-field'
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})
const { getArrayStatus } = useField()
const arrayStatus = computed(() => {
    return getArrayStatus(RECRUITMENT_COLLECTION)
})
const commonStore = useStoreCommon()
const _useRecruitmentQuery = useRecruitmentQuery()
const _useProvinceQuery = useProvinceQuery()
const router = useRouter()
const dateTimeMenu = ref()
const provincesIdFK = ref(<any>{})
const provincesInit = ref(<any>[])
const dataDefault = {
    id: 0,
    title: null,
    is_hot: false,
    expired: null,
    salary_min: null,
    salary_max: null,
    provinces: [],
    salary_negotiate: false,
    contact_name: null,
    contact_email: null,
    contact_phone: null,
    image: null,
    content: null,
    requirement: null,
    benefit: null,
    expired_display: null,
    status: null
}
const defaultDataEdit = ref(<RecruitmentDetailModel>JSON.parse(JSON.stringify(dataDefault)))
const formData = ref(<RecruitmentDetailModel>JSON.parse(JSON.stringify(dataDefault)))

const schema = Joi.object({
    title: Joi.string().required(),
    expired: Joi.string().required(),
    provinces: Joi.array().items(Joi.object()).min(1).required(),
    contact_name: Joi.string().required(),
    contact_email: Joi.string().required(),
    contact_phone: Joi.string().required(),
    image: Joi.string().required(),
    content: Joi.string().required(),
    requirement: Joi.string().required(),
    benefit: Joi.string().required(),
    expired_display: Joi.string().required(),
    status: Joi.string().required(),
}).unknown(false)

const initData = () => {
    for (const [key, value] of Object.entries(formData.value)) {
        formData.value[key] = props.item[key]
    }
    formData.value.salary_negotiate = !props.item?.salary_min && !props.item?.salary_min ? true : false
    formData.value.expired = dayjs(formData.value.expired).format(FORMAT_TIME.YYYMMDD)
    formData.value.expired_display = dayjs(formData.value.expired).format(FORMAT_TIME.DDMMYYYY)
    provincesInit.value = []
    if (formData.value.provinces?.length > 0) {
        formData.value.provinces.forEach((x: any) => {
            provincesInit.value.push(x)
        })
    }
    defaultDataEdit.value = JSON.parse(JSON.stringify(formData.value))
}

const arrProvince = ref(<any>[])
const getProvincesFunc = async () => {
    let resProvince: any = await _useProvinceQuery.getAllProvinceItem()
    let res = resProvince.data.data?.filter((x: any) => !x.parent_id)
    res?.map((x: any) => {
        arrProvince.value.push({ text: x.name, value: x.id })
    })
}

watch(
    () => props.item,
    async () => {
        await getProvincesFunc()
        if (props.item) {
            // edit
            initData()
        } else {
            defaultDataEdit.value.salary_negotiate = true
            formData.value.salary_negotiate = true
            formData.value.expired = dayjs(new Date()).format(FORMAT_TIME.YYYMMDD)
            formData.value.expired_display = dayjs(new Date()).format(FORMAT_TIME.DDMMYYYY)
        }
    },
    { immediate: true }
)

const handleChange = (e: any, variable: any) => {
    switch (variable) {
        case 'is_hot':
        case 'salary_negotiate':
            formData.value[variable] = !formData.value[variable]
            formData.value.salary_min = ''
            formData.value.salary_max = ''
            break

        case 'salary_min':
        case 'salary_max':
            formData.value[variable] = e
            formData.value.salary_negotiate = !formData.value.salary_min && !formData.value.salary_max ? true : false
            break

        case 'expired':
            formData.value.expired_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break

        default:
            formData.value[variable] = e
            break
    }
}

const unsetValue = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    formData.value.expired = ''
}


const handleChangeDropdownProvince = (data) => {
    provincesIdFK.value = data.dataUpdateApi
    formData.value.provinces = data.dataModel
}

// handle submit form (start)
const errors: any = ref(null)
const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.RECRUITMENT_DETAIL_SUBMIT)
    if (payloadSubmitForm) {
        const { id, salary_min, salary_max, is_hot, salary_negotiate, ...res } = formData.value
        const { error } = schema.validate(res, { abortEarly: false })

        if (error) {
            errors.value = error.details.reduce((acc, curr) => {
                acc[curr.path[0]] = curr.message
                return acc
            }, {})
            return
        } else {
            errors.value = {}
            let params = JSON.parse(JSON.stringify(formData.value))
            !provincesIdFK.value?.change
                ? delete params.provinces
                : (params.provinces = provincesIdFK.value.data.provinces)

            if(params?.salary_negotiate){
                delete params?.salary_max
                delete params?.salary_min
            }

            if (payloadSubmitForm.payload === MODE_EDIT) {
                await _useRecruitmentQuery.patchItemRecruitment(params.id, params)
                commonStore.showDialogSucessUpdate = true
            } else {
                delete params.id
                commonStore.loading = true
                let res = await _useRecruitmentQuery.postItemRecruitment(params)
                setTimeout(() => {
                    commonStore.loading = false
                    router.push({
                        name: PATH_ROUTER.RECRUITMENT_DETAIL.NAME,
                        params: {
                            id: res?.data?.data?.id,
                        },
                    })
                }, 2000)
                
            }
           
        }
    }
}

const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})
// handle submit form (end)
</script>

