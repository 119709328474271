<template>
    <c-role>
        <private-view :title="infoTrainingTitle">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <template #title>
                <h1 class="type-title leading-[28px]">
                    Thông tin đào tạo - {{ labelUserType }} Onboarding
                    <br />
                    <span class="text-[#475569] text-[12px] font-normal">
                        Giới thiệu tổng quan về chương trình đào tạo {{ labelUserType }} ONboarding
                    </span>
                </h1>
            </template>
            <!-- navigation end  -->
            <!-- header start -->
            <div class="w-full">
                <div
                    class="tab-header w-full h-full flex xl:flex-row flex-col justify-center items-start xl:px-28 xl:py-16 px-10 px-5 mt-[-24px]"
                    :style="{ 'background-image': `url(${imageData?.constants_image[7]})` }"
                >
                    <div class="xl:w-1/2 w-full flex justify-center items-center xl:mt-0 mt-5 mr-22">
                        <!-- <img :src="imageData.constants_image[9]" /> -->
                        <div v-if="course?.video" class="w-full h-full">
                            <iframe
                                :src="course?.video"
                                class="w-full aspect-video object-contain"
                                frameborder="0"
                            ></iframe>
                        </div>
                    </div>
                    <div
                        class="xl:w-1/2 md:w-2/3 w-full flex flex-col justify-center xl:items-start items-center mx-auto"
                    >
                        <h5 class="font-bold xl:text-[32px] text-2xl text-white xl:mt-0 mt-3 leading-10 mb-5">
                            {{ course?.title }}
                        </h5>
                        <p class="font-normal text-base text-white leading-6">
                            {{ course?.description }}
                        </p>
                        <span class="font-normal icon-size my-8 text-white leading-5">
                            <v-icon name="play_circle" />
                            {{ course?.total_topic }} bài học
                        </span>
                    </div>
                </div>
                <div
                    class="bg-process flex w-full flex-row justify-center items-center gap-8"
                    :style="{ 'background-image': `url(${imageData?.constants_image[5]})` }"
                >
                    <button
                        class="success text-[18px] leading-6 text-center pt-4 pb-5 px-4 text-white border-b-4 border-b-[#253A84] border-b-solid"
                    >
                        Khung chương trình
                    </button>
                </div>
                <div>
                    <list-module :lstModule="modules" :channel="String(route.params?.channel)" />
                </div>
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" :title="infoTrainingTitle" close />
            </template>
        </private-view>
    </c-role>
</template>
<script setup lang="ts">
import { ref, computed, toRefs } from 'vue'
import { useTrainingQuery } from '../../../../shared-fe/services/training-service'
import { useConstantsImage } from '../../../../shared-fe/stores/constants-image'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import ListModule from '../session-train-detail/components/list-module.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useStores } from '@directus/composables'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user';

const modules = ref([])
const course = ref(<any>{})
const isName = '2'
const route = useRoute()
const { getModulesByChannelId } = useTrainingQuery()
const imageStore = useConstantsImage()
const imageData = computed(() => {
    return { ...imageStore.imageConstants }
})
const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})

const infoTrainingTitle = computed(() => {
    return `Thông tin đào tạo - ${labelUserType.value} Onboarding`
})

const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false

getModulesByChannelId(route.params?.channel).then((_modules) => {
    course.value = _modules?.data?.result.courses
    if (course.value?.topics?.length > 0) {
        modules.value = course.value?.topics
        course.value['total_topic'] = modules.value?.length
    }
})
 
</script>
