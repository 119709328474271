<template>
    <form method="get" @submit.prevent="handleSubmit" class="searchbar-container p-[1rem] rounded-lg">
        <div class="searchbar flex gap-x-7 items-center">
            <div class="flex-grow flex basic-slot">
                <slot name="basic"></slot>
            </div>

            <div class="flex gap-2">
                <div class="self-center cursor-pointer" @click="handleRefresh">
                    <v-icon name="refresh" />
                </div>

                <div class="search-button">
                    <v-button type="submit">{{ SEARCH_BTN }}</v-button>
                </div>
            </div>
        </div>

        <c-access :roles="[USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2, USER_ROLE.AGENCY]">
            <div>
                <template v-if="showMore">
                    <div class="border-top mt-4 pt-6 grid grid-cols-3 mb-4 gap-7">
                        <slot name="advanced"></slot>
                    </div>
                </template>
            </div>
        </c-access>
    </form>

    <c-access :roles="[USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2, , USER_ROLE.AGENCY]">
        <div class="flex flex-col justify-center pt-7 cursor-pointer" @click="showMore = !showMore">
            <div class="self-center cursor-pointer text-primary-base text-center">
                <v-icon :name="showMore ? 'expand_less' : 'expand_more'" />
                <div>{{ showMore ? LESS_EXPAND : SEARCHBAR_EXPAND }}</div>
            </div>
        </div>
    </c-access>
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue'
import { USER_ROLE } from '../../utils/constant'
import CAccess from '../c-access/c-access.vue'

const SEARCHBAR_EXPAND = 'Mở rộng tìm kiếm'
const LESS_EXPAND = 'Thu gọn'
const SEARCH_BTN = 'Tìm kiếm'

const showMore = ref(false)
const emit = defineEmits(['reset', 'submit'])

const handleRefresh = () => {
    emit('reset')
}

const handleSubmit = () => {
    emit('submit')
}
</script>

<style lang="scss" scoped>
.searchbar-container:deep {
    border: 1px solid var(--primary);

    .searchbar {
        .v-input {
            .input {
                border: none;
            }
        }
    }

    .border-right {
        border-right: 2px solid var(--border-subdued);

        &:last-child {
            background-color: red;
            border-right: none;
        }
    }

    .border-top {
        border-top: 1px solid var(--border-subdued);
    }

    .search-button {
        .v-button {
            --v-button-background-color: #334155;
        }
    }
    .v-input {
        height: 40px;
    }
}
</style>
