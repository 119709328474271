<template>
    <div class="px-13 pb-10" v-if="idSettings">
        <v-form primary-key='1' v-model="formData" :fields="fields"></v-form>

        <div class="pt-8" v-if="!formLoading">
            <div class="inline-block">
                <v-button @click="save" class="button button-primary">Lưu thay đổi</v-button>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="text-primary text-2xl text-center">
            Đang khởi tạo dữ liệu ...
        </div>
    </div>
</template>

<script lang="ts" setup>
import {ref, computed} from 'vue'
import {useFieldsCom} from "./field-composables";

const {
    fieldGroup,
    fieldInput,
    fieldSmallFile,
    save,
    formLoading,
    idSettings,
    formData,
} = useFieldsCom('ld_settings', {
    group: {
        headerColor: "#f59e0b"
    }
});

const groups = {
    ynghiachuongtrinh: "ynghiachuongtrinh",
    rules: "rules",
    steps: "steps",
    step1: "step1",
    step2: "step2",
    step3: "step3",
    footer: "footer",
}
const fields = [
    fieldGroup(groups.ynghiachuongtrinh, "Ý nghĩa chương trình"),

    fieldSmallFile('s1_image', "Hình ảnh", {
        group: groups.ynghiachuongtrinh,
        width: "col-span-2"
    }),
    fieldInput('s1_text_1', "Ý nghĩa 1", {
        group: groups.ynghiachuongtrinh,
    }),
    fieldInput('s1_text_2', "Ý nghĩa 2", {
        group: groups.ynghiachuongtrinh,
    }),
    fieldInput('s1_text_3', "Ý nghĩa 3", {
        group: groups.ynghiachuongtrinh,
        width: "col-span-2"
    }),


    fieldGroup(groups.rules, "Quy định chương trình"),

    fieldInput('s2_time', "Thời gian", {
        group: groups.rules,
        width: "half"
    }),
    fieldInput('s2_durations', "Thời lượng", {
        group: groups.rules,
        width: "half"
    }),
    fieldSmallFile('s2_bg', "Hình Nền", {
        group: groups.rules,
        width: "col-span-2"
    }),
    fieldInput('s2_text', "Phương pháp (text)", {
        group: groups.rules,
        width: "half"
    }),
    fieldSmallFile('s2_file', "Phương pháp (file)", {
        group: groups.rules,
        width: "half"
    }),

    fieldSmallFile('s2_img', "Hình ảnh", {
        group: groups.rules,
        width: "half"
    }),
    fieldSmallFile('s2_file_target', "Chỉ tiêu năm (id file)", {
        group: groups.rules,
    }),

    fieldGroup(groups.steps, "Các bước huấn luyện"),
    fieldGroup(groups.step1, "Buổi 1", {
        group: groups.steps
    }),
    fieldInput('step_1_title', "Tiêu đề", {
        group: groups.step1,
    }),
    fieldInput('step_1', "Các bước", {
        group: groups.step1,
        interface: "input-code",
        note: "Enter xuống dòng sau mỗi bước"
    }),

    fieldGroup(groups.step2, "Buổi 2", {
        group: groups.steps
    }),
    fieldInput('step_2_title', "Tiêu đề", {
        group: groups.step2,
    }),
    fieldInput('step_2', "Các bước", {
        group: groups.step2,
        interface: "input-code",
        note: "Enter xuống dòng sau mỗi bước"
    }),

    fieldGroup(groups.step3, "Buổi 3", {
        group: groups.steps
    }),
    fieldInput('step_3_title', "Tiêu đề", {
        group: groups.step3,
    }),
    fieldInput('step_3', "Các bước", {
        group: groups.step3,
        interface: "input-code",
        note: "Enter xuống dòng sau mỗi bước"
    }),


    fieldGroup(groups.footer, "Chân trang"),
    fieldSmallFile('logo', "Logo", {
        group: groups.footer,
        width: "half",
        note: "Sử dụng logo trong suốt"
    }),
    fieldSmallFile('footer_file', "File hướng dẫn", {
        group: groups.footer,
    }),
    fieldSmallFile('bg', "Hình nền", {
        group: groups.footer,
        width: "col-span-2",
        note: "Nên sử dụng nền tối"
    }),

    fieldInput('footer_title', "Tiêu đề", {
        group: groups.footer,
    }),

    fieldInput('footer_hotline', "Số hotline", {
        group: groups.footer,
    }),

    fieldInput('footer_intro', "Mô tả", {
        group: groups.footer,
        width: "col-span-2",
    }),

];

defineExpose({
    save
})
</script>
