import { useCollection } from '@directus/extensions-sdk'
import { sortDataSelection } from '../utils/convert-data'

export const useField = () => {
    const getArrayStatus = (collectionName: string) => {
        const { fields } = useCollection(collectionName)
        let res: any = []
        if (fields.value && fields.value.length > 0) {
            res = fields.value.find((x) => x.field === 'status')
            return sortDataSelection(res?.meta?.options?.choices)
        }
        return res
    }

    const getArrayHiringReason = (collectionName: string) => {
        const { fields } = useCollection(collectionName)
        let res: any = []
        if (fields.value && fields.value.length > 0) {
            res = fields.value.find((x) => x.field === 'hiring_reason')
            return sortDataSelection(res?.meta?.options?.choices)
        }
        return res
    }

    const getEmploymentType = (collectionName: string) => {
        const { fields } = useCollection(collectionName)
        let res: any = []
        if (fields.value && fields.value.length > 0) {
            res = fields.value.find((x) => x.field === 'employment_type')
            return sortDataSelection(res?.meta?.options?.choices)
        }
        return res
    }

    const getDcrStatus = (collectionName: string) => {
        const { fields } = useCollection(collectionName)
        let res: any = []
        if (fields.value && fields.value.length > 0) {
            res = fields.value.find((x) => x.field === 'employment_status')
            return sortDataSelection(res?.meta?.options?.choices)
        }
        return res
    }

    const getResignStatus = (collectionName: string) => {
        const { fields } = useCollection(collectionName)
        let res: any = []
        if (fields.value && fields.value.length > 0) {
            res = fields.value.find((x) => x.field === 'status')
            return sortDataSelection(res?.meta?.options?.choices)
        }
        return res
    }

    const getDocumentCandidateStatus = (collectionName: string) => {
        const { fields } = useCollection(collectionName)
        let res: any = []
        if (fields.value && fields.value.length > 0) {
            res = fields.value.find((x) => x.field === 'filter_profiles')
            return sortDataSelection(res?.meta?.options?.choices)
        }
        return res
    }

    const getReasonRejectRR = (collectionName: string) => {
        const { fields } = useCollection(collectionName)
        let res: any = []
        if (fields.value && fields.value.length > 0) {
            res = fields.value.find((x) => x.field === 'reason_rejected')
            return res?.meta?.options?.choices
        }
        return res
    }

    return {
        getArrayStatus,
        getArrayHiringReason,
        getEmploymentType,
        getDcrStatus,
        getResignStatus,
        getReasonRejectRR,
        getDocumentCandidateStatus,
    }
}
