<template>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9">
        <div v-if="infoDcr.idCandidate !== -1" class="text-[#1E4BA1] mb-8" @click="goToCandidate">
            Xem thông tin ứng viên
            <v-icon name="open_in_new" small />
        </div>
        <div class="grid bg-[#EFFAFA] px-[1.4rem] py-[1.7rem] gap-8 p- grid-cols-1 lg:grid-cols-3">
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Chi nhánh</div>
                <div>{{ infoDcr.zone_name }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Khu vực</div>
                <div>{{ infoDcr.area_name }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Nhà phân phối</div>
                <div>{{ infoDcr.system_providers_name }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">SS</div>
                <div>{{ infoDcr.ss_name }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Mã route</div>
                <div>{{ infoDcr.route_code }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Mã DCR</div>
                <div>{{ infoDcr.code }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Ngày bắt đầu làm việc</div>
                <div>{{ infoDcr.start_date }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Trạng thái</div>
                <div>{{ infoDcr.employment_status_name }}</div>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Ngày làm việc cuối cùng</div>
                <div>{{ infoDcr.last_working_date }}</div>
            </div>
        </div>
        <div v-if="infoDcr && infoDcr?.resigns?.length > 0">
            <div class="border-bottom my-8"></div>
            <div class="info-request font-bold text-[var(--foreground-normal-alt)] text-6 my-8">Đơn nghỉ việc</div>
            <template v-for="(item, index) in infoDcr?.resigns">
                <div class="grid bg-[#F8FAFC] px-[1.4rem] py-[1.7rem] mb-[1.7rem] gap-8 p- grid-cols-1 lg:grid-cols-3">
                    <div class="col-span-1 lg:col-span-3 text-[#1E4BA1]">Đợt {{ index + 1 }}</div>
                    <div class="col-span-1 flex">
                        <div class="mr-3">
                            <div class="i-mdi-calendar-blank-outline mt-4px text-22px text-[#1E4BA1]"></div>
                        </div>
                        <div>
                            <div class="text-12px text-[#64748B]">Ngày nộp đơn</div>
                            <div>{{ dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) }}</div>
                        </div>
                    </div>
                    <div class="col-span-1 flex">
                        <div class="mr-3">
                            <div class="i-mdi-file-document-outline mt-4px text-22px text-[#1E4BA1]"></div>
                        </div>
                        <div>
                            <div class="text-12px text-[#64748B]">Lý do nghỉ việc - SS</div>
                            <div>{{ item.reason?.opt_value }}</div>
                        </div>
                    </div>
                    <div class="col-span-1 flex">
                        <div class="mr-3">
                            <div class="i-mdi-file-document-outline mt-4px text-22px text-[#1E4BA1]"></div>
                        </div>
                        <div>
                            <div class="text-12px text-[#64748B]">Lý do nghi việc P&C</div>
                            <div>{{ item.interview?.opt_value }}</div>
                        </div>
                    </div>
                    <div class="col-span-1 flex">
                        <div class="mr-3">
                            <div class="i-mdi-timer-sand-empty mt-4px text-22px text-[#1E4BA1]"></div>
                        </div>
                        <div>
                            <div class="text-12px text-[#64748B]">Trạng thái</div>
                            <div>{{ item.status ? RESIGN_STATUS[item.status].text : '' }}</div>
                        </div>
                    </div>
                    <div class="col-span-1 flex">
                        <div class="mr-3">
                            <div class="i-mdi-timer-sand-empty mt-4px text-22px text-[#1E4BA1]"></div>
                        </div>
                        <div>
                            <div class="text-12px text-[#64748B]">Lý do hủy yêu cầu</div>
                            <div>{{ displayReasonRejectResign(item.resigns_history) }}</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { FORMAT_TIME, RESIGN_STATUS, RESIGN_HISTORY_REASON } from '../../../../../shared-fe/utils/constant'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
const goToCandidate = () => {
    router.push({
        name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
        params: {
            id: infoDcr.value.idCandidate,
        },
    })
}

const displayReasonRejectResign = (resigns_history) => {
    if (resigns_history && resigns_history.length > 0) {
        return RESIGN_HISTORY_REASON.find((x) => x.value === resigns_history[resigns_history.length - 1].reason)?.text
    }
    return ''
}
const dataDefault = {
    id: null,
    note: null,
    area_name: null,
    zone_name: null,
    system_providers_name: null,
    ss_name: null,
    route_code: null,
    code: null,
    start_date: null,
    last_working_date: null,
    employment_status_name: null,
    resigns: null,
    idCandidate: null,
    resigns_history: null,
}
const router = useRouter()
const infoDcr = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(infoDcr.value)) {
        infoDcr.value[key] = props.item[key]
    }
    infoDcr.value.start_date = infoDcr.value?.start_date
        ? dayjs(infoDcr.value.start_date).format(FORMAT_TIME.DDMMYYYY)
        : ''
    infoDcr.value.last_working_date = infoDcr.value?.last_working_date
        ? dayjs(infoDcr.value.last_working_date).format(FORMAT_TIME.DDMMYYYY)
        : ''
}

watch(
    () => props.item,
    () => {
        if (props.item) {
            initData()
        }
    },
    { immediate: true }
)
</script>
