<template>
    <c-role>
        <private-view :title='TITLE_LIST_RECRUITMENT'>
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <!-- navigation end  -->
            <!-- header start -->
            <!--  dùng cho detail -->
            <!-- <template #title-outer:prepend>
                    <v-button class="header-icon" rounded icon secondary exact
                        @click="navigateBack">
                        <v-icon name="arrow_back" />
                    </v-button>
                </template>

                <template #headline>
                    <v-breadcrumb :items="[{ name: PATH_ROUTER.RECRUITMENT_REQUEST.VALUE, to: PATH_ROUTER.RECRUITMENT_REQUEST.PATH }]" />
                </template> -->
            <template #actions>
                <c-access :roles='[USER_ROLE.HR]'>
                    <span class='custom-button-export-excel'>
                        <v-button secondary @click='exportData' :loading='_export.loadingExcel.value'>
                            <v-icon name='file_upload' outline />
                            <span class='inline-block ml-2'>
                                {{ EXPORT_EXCEL }}
                            </span>
                        </v-button>
                    </span>
                </c-access>
                <c-access :roles='[]'>
                    <v-dialog v-if='modelSelected?.length > 0' v-model='confirmDelete' @esc='confirmDelete = false'>
                        <template #activator='{ on }'>
                            <v-button
                                v-tooltip.bottom='LABEL_DELETE'
                                rounded
                                icon
                                class='action-delete danger-action'
                                secondary
                                @click='on'
                            >
                                <div class='delete_outline'></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click='confirmDelete = false'>
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind='danger' :loading='deleting' @click='batchDeleteFun'>
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <!-- <c-access :roles="[USER_ROLE.HR]">
                    <v-button
                        v-if="modelSelected?.length > 0"
                        v-tooltip.bottom="EDIT"
                        rounded
                        icon
                        secondary
                        @click="batchEditActive = true"
                    >
                        <div class="i-mdi-pencil-outline"></div>
                    </v-button>
                </c-access> -->

                <v-button
                    v-tooltip.bottom='CREATE_ITEM'
                    class='custom-create-button'
                    :to='PATH_ROUTER.RECRUITMENT_ADD.PATH'
                >
                    Tạo tin tuyển dụng
                </v-button>
                <drawer-batch
                    v-model:active='batchEditActive'
                    :primary-keys='primaryKeys'
                    :collection='RECRUITMENT_COLLECTION'
                    @refresh='batchRefresh'
                />
            </template>

            <!-- header end -->
            <div class='mx-9'>
                <!-- searchbar start -->
                <recruitment-searchbar
                    :arrayValue='objSearchStore?.dataSeletedForm'
                    :arrayStatus='arrayStatus'
                    @update:handleSearch='handleSearch'
                />
                <!-- searchbar end -->

                <!-- content start -->
                <recruitment-table
                    :items='itemsRender'
                    :modelSelected='modelSelected'
                    @update:modelSelected='modelSelectedFun'
                    :totalPages='totalPages'
                    :page='page'
                    :loading='loading'
                    @update:toPage='handleToPage'
                    @updateSort='handleUpdateSort'
                />
                <!--  content end -->
            </div>
            <template #sidebar>
                <sidebar-detail icon='info' :title='TITLE_LIST_RECRUITMENT' close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang='ts' setup>
import { useApi, useItems } from '@directus/composables'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import CAccess from '../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../shared-fe/composables/use-batch'
import { useField } from '../../../../shared-fe/composables/use-field'
import { useStoreSearch } from '../../../../shared-fe/stores/search'
import {
    DELETE_VALUE,
    FORMAT_TIME,
    RECORD_PER_PAGE,
    RECRUITMENT_STATUS,
    USER_ROLE
} from '../../../../shared-fe/utils/constant'
import { formatDateObjectToQuery, convertMoney } from '../../../../shared-fe/utils/convert-data'
import {
    CANCEL,
    CONTENT_DELETE,
    CREATE_ITEM,
    EDIT,
    EXPORT_EXCEL,
    LABEL_DELETE,
    TITLE_LIST_RECRUITMENT
} from '../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import { RECRUITMENT_COLLECTION } from '../../../../shared-fe/utils/table-name'
import RecruitmentSearchbar from './components/recruitment-searchbar.vue'
import RecruitmentTable from './components/recruitment-table.vue'
import { useExport } from '../../../../shared-fe/composables/use-export'
import dayjs from 'dayjs'
import { custom } from 'joi'
import { useStores } from '@directus/extensions-sdk'
import { useFieldsStore } from '@/stores/fields'

const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
const { getArrayStatus } = useField()
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-id'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)

const primaryKeys = computed(() => {
    return modelSelected.value?.map((x) => x.id) || []
})


const customFilter = computed(() => {
    let _filter = {
        status: {
            _neq: DELETE_VALUE
        }
    }
    if (filter.value && Object.entries(filter.value).length > 0) {
        _filter = {
            ..._filter,
            ...filter.value
        }
    }

    return _filter
})
const { items, totalPages, loading, getItems, getTotalCount, getItemCount } = useItems(ref(RECRUITMENT_COLLECTION), {
    fields: ref(['*', 'provinces.province_id.*']),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search: ref(null),
    filter: customFilter,
    page: page
})

const itemsRender = computed(() => {
    let _itemsRender: any = []
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    items.value.forEach((x) => {
        index++
        _itemsRender.push({
            ...x,
            stt: index
        })
    })
    return _itemsRender
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const arrayStatus = computed(() => {
    return getArrayStatus(RECRUITMENT_COLLECTION)
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {
        provinces: data.province_id
            ? {
                province_id: {
                    id: {
                        _eq: data.province_id
                    }
                }
            }
            : null,
        status: data.status || null
    }

    if (data.date_created && data.date_created.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.date_created[0], data.date_created[1])
        _filter.date_created = {
            _between: dateQuery
        }
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value) {
            delete _filter[key]
        }
    }

    if (data.title) {
        _filter._and = [
            {
                _or: [
                    {
                        title: {
                            _contains: data.title
                        }
                    }
                ]
            }
        ]
    }
    filter.value = _filter

    updateSearchStore(
        {
            search: data.title,
            filter: _filter
        },
        data
    )
}

const api = useApi()
const modelSelectedFun = (data) => {
    modelSelected.value = data
}

// table end

// actions start

const batchRefresh = async () => {
    modelSelected.value = []
    await refresh()
}

const batchDeleteFun = async () => {
    await batchDelete('status')
    await refresh()
}
const {
    confirmDelete,
    deleting,
    batchDelete,
    error: deleteError,
    batchEditActive,
    modelSelected
} = useBatch(RECRUITMENT_COLLECTION)

const _export = useExport(RECRUITMENT_COLLECTION, {
    stt: 'STT',
    title: 'Tiêu đề',
    'provinces.province_id.name': 'Khu vực',
    status: 'Trạng thái',
    salary: 'Mức lương',
    date_created: 'Ngày đăng',
    expired: 'Ngày hết hạn',
    view_count: 'Số lượt xem',
    candidates: 'Số ứng viên',
    'user_created.full_name': 'Người đăng'
})
_export.filename.value = 'Recruitment.xlsx'
_export.filter.value = {
    status: {
        _neq: DELETE_VALUE
    }
}
_export.beforeGenerate.value = (items) => {
    return items.map((item, index) => {
        return {
            stt: index + 1,
            title: item?.title,
            provinces_province_id_name: item?.provinces?.map((x) => x.province_id.name).toString(),
            status: RECRUITMENT_STATUS[item?.status]?.text,
            salary: convertMoney(item),
            date_created: item?.date_created ? new Date(item?.date_created) : '',
            expired: item?.expired ? new Date(item?.expired) : '',
            view_count: item?.view_count,
            candidates: item?.candidates?.length,
            user_created_full_name: item?.user_created?.full_name
        }
    })
}

const exportData = () => {
    _export.filter.value = customFilter.value
    _export.sort.value = sort.value
    _export.run()
}
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang='scss' scoped></style>
