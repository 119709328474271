import { useOptionQuery } from '../services/option-service'

export const useOption = () => {
    const { getOptionByGroup } = useOptionQuery()

    const getOptionByGroupData = async (optGroup: string) => {
        let res = await getOptionByGroup(optGroup)

        let array: Array<any> = []
        if (res.data.data.length > 0) {
            res.data.data.forEach((val: any) => {
                array.push({
                    value: String(val.id),
                    text: String(val.opt_value),
                })
            })
        }
        return array
    }
    return { getOptionByGroupData }
}
