import { defineModule } from '@directus/extensions-sdk'
import CandidateAddComponent from './routes/candidate/add/add.vue'
import CandidateComponent from './routes/candidate/candidate.vue'
import CandidateDetailComponent from './routes/candidate/detail/detail.vue'
import IntroductCandidateComponent from './routes/introduct_candidate/introduct-candidate.vue'
import RecruitmentAddComponent from './routes/recruitment/add/add.vue'
import RecruitmentDetailComponent from './routes/recruitment/detail/detail.vue'
import RecruitmentComponent from './routes/recruitment/recruitment.vue'
import RecruitmentRequestAddComponent from './routes/recruitment_request/add/add.vue'
import RecruitmentRequestDetailComponent from './routes/recruitment_request/detail/detail.vue'
import RecruitmentRequestComponent from './routes/recruitment_request/recruitment_request.vue'

// Không dùng nữa, vì sẽ dùng chung với component tạo ứng viên
// import IntroductCandidateAddComponent from './routes/introduct_candidate/add/add.vue'

import AdminHr from './routes/adminhr/index.vue'
import DcrComponent from './routes/dcr/dcr.vue'
import DcrDetailComponent from './routes/dcr/detail/detail.vue'
import ProbationaryAssessmentsDetailComponent from './routes/probationary_assessments/detail/detail.vue'
import ProbationaryAssessmentsComponent from './routes/probationary_assessments/probationary-assessments.vue'
import ResignAddComponent from './routes/resign/add/add.vue'
import ResignDetailComponent from './routes/resign/detail/detail.vue'
import ResignComponent from './routes/resign/resign.vue'
import HrSetting from './routes/settings/hr-setting.vue'
import Report from './routes/report/report.vue'

import { PATH_ROUTER } from '@/shared-fe/utils/path'
import '@/style.scss'
import 'uno.css'
import { USER_ROLE, USER_TYPE } from '@/shared-fe/utils/constant'

export default defineModule({
    id: 'pepsi-hr',
    name: 'HR Module',
    icon: 'person_search',
    routes: [
        {
            name: 'hr-index',
            path: '',
            redirect: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.PATH,
            children: [
                {
                    name: PATH_ROUTER.ADMIN_HR.NAME,
                    path: PATH_ROUTER.ADMIN_HR.PATH,
                    component: AdminHr,
                    meta: {
                        permission: PATH_ROUTER.ADMIN_HR.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_LIST.NAME,
                    path: PATH_ROUTER.RECRUITMENT_LIST.PATH,
                    component: RecruitmentComponent,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_LIST.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_DETAIL.NAME,
                    path: PATH_ROUTER.RECRUITMENT_DETAIL.PATH,
                    component: RecruitmentDetailComponent,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_DETAIL.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_ADD.NAME,
                    path: PATH_ROUTER.RECRUITMENT_ADD.PATH,
                    component: RecruitmentAddComponent,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_ADD.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.NAME,
                    path: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.PATH,
                    component: RecruitmentRequestComponent,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_REQUEST_DETAIL.NAME,
                    path: PATH_ROUTER.RECRUITMENT_REQUEST_DETAIL.PATH,
                    component: RecruitmentRequestDetailComponent,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_REQUEST_DETAIL.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_REQUEST_ADD.NAME,
                    path: PATH_ROUTER.RECRUITMENT_REQUEST_ADD.PATH,
                    component: RecruitmentRequestAddComponent,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_REQUEST_ADD.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.CANDIDATE_LIST.NAME,
                    path: PATH_ROUTER.CANDIDATE_LIST.PATH,
                    component: CandidateComponent,
                    meta: {
                        permission: PATH_ROUTER.CANDIDATE_LIST.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.CANDIDATE_ADD.NAME,
                    path: PATH_ROUTER.CANDIDATE_ADD.PATH,
                    component: CandidateAddComponent,
                    meta: {
                        permission: PATH_ROUTER.CANDIDATE_ADD.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
                    path: PATH_ROUTER.CANDIDATE_DETAIL.PATH,
                    component: CandidateDetailComponent,
                    meta: {
                        permission: PATH_ROUTER.CANDIDATE_DETAIL.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.NAME,
                    path: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.PATH,
                    component: IntroductCandidateComponent,
                    meta: {
                        permission: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.INTRODUCT_CANDIDATE_ADD.NAME,
                    path: PATH_ROUTER.INTRODUCT_CANDIDATE_ADD.PATH,
                    component: CandidateAddComponent,
                    meta: {
                        permission: PATH_ROUTER.INTRODUCT_CANDIDATE_ADD.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                    props: {
                        ss_refer: true
                    }
                },
                {
                    name: PATH_ROUTER.DCR_LIST.NAME,
                    path: PATH_ROUTER.DCR_LIST.PATH,
                    component: DcrComponent,
                    meta: {
                        permission: PATH_ROUTER.DCR_LIST.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.DCR_DETAIL.NAME,
                    path: PATH_ROUTER.DCR_DETAIL.PATH,
                    component: DcrDetailComponent,
                    meta: {
                        permission: PATH_ROUTER.DCR_DETAIL.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RESIGN_LIST.NAME,
                    path: PATH_ROUTER.RESIGN_LIST.PATH,
                    component: ResignComponent,
                    meta: {
                        permission: PATH_ROUTER.RESIGN_LIST.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RESIGN_DETAIL.NAME,
                    path: PATH_ROUTER.RESIGN_DETAIL.PATH,
                    component: ResignDetailComponent,
                    meta: {
                        permission: PATH_ROUTER.RESIGN_DETAIL.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RESIGN_ADD.NAME,
                    path: PATH_ROUTER.RESIGN_ADD.PATH,
                    component: ResignAddComponent,
                    meta: {
                        permission: PATH_ROUTER.RESIGN_ADD.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
                    path: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.PATH,
                    component: ProbationaryAssessmentsComponent,
                    meta: {
                        permission: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_DETAIL.NAME,
                    path: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_DETAIL.PATH,
                    component: ProbationaryAssessmentsDetailComponent,
                    meta: {
                        permission: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_DETAIL.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_SETTINGS.NAME,
                    path: PATH_ROUTER.RECRUITMENT_SETTINGS.PATH,
                    component: HrSetting,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_SETTINGS.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
                {
                    name: PATH_ROUTER.RECRUITMENT_REPORT.NAME,
                    path: PATH_ROUTER.RECRUITMENT_REPORT.PATH,
                    component: Report,
                    meta: {
                        permission: PATH_ROUTER.RECRUITMENT_REPORT.PERMISSION,
                        user_type: USER_TYPE.RETAIL
                    },
                },
            ],
        },
        {
            name: PATH_ROUTER.ACCESS_DENIED.NAME,
            path: PATH_ROUTER.ACCESS_DENIED.PATH,
            redirect: PATH_ROUTER.ACCESS_DENIED.PATH,
        },
    ],
})
