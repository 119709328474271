import { PROVIDER_COLLECTION, ROLES_COLLECTION, USER_COLLECTION } from '../utils/table-name'
import { DELETE_VALUE, RECRUITMENT_REQUEST_STATUS, RESIGN_STATUS, USER_ROLE, USER_STATUS, USER_TYPE } from '../utils/constant'
import { useApi } from '@directus/composables'
import { useUserStore } from '@/stores/user'
export const useUserQuery = () => {
    const api = useApi()
    const userStore = useUserStore()
    const getProvider = async () => {
        return await api.get(`/${USER_COLLECTION}/me`, {
            params: {
                fields: ['system_providers.provider_id.*'],
            },
        })
    }

    const getProviderByProviderName = async (name: string) => {
        return await api.get(`items/${PROVIDER_COLLECTION}`, {
            params: {
                fields: ['*'],
                filter: {
                    name: name,
                },
            },
        })
    }

    const getCurrentUser = async () => {
        return await api.get(`/${USER_COLLECTION}/me`, {
            params: {
                limit: -1,
                fields: [
                    '*',
                    'role.*',
                    'system_providers.id',
                    'system_providers.provider_id.name',
                    'system_providers.provider_id.id',
                ],
            },
        })
    }

    const getAllDcr = async () => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                filter: {
                    role: {
                        short_name: USER_ROLE.DCR,
                    },
                    // employment_status: USER_STATUS.on.value,
                },
                limit: -1,
                fields: [
                    'id',
                    'code',
                    '*'
                ],
            },
        })
    }

    const getAllEmailUserWithLimit = async (filter: any) => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                filter: filter,
                fields: [
                    'personal_email',
                    'code'
                ],
            },
        })
    }

    const getInfoDcrWithLimit = async (filter: any) => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                filter: {
                    _and: [
                        {
                            role: {
                                short_name: USER_ROLE.DCR,
                            },
                        },
                        {
                            status: {
                                _neq: DELETE_VALUE,
                            },
                        },
                        { _or: filter }
                    ]
                },
                fields: [
                    'id',
                    'id_card_number',
                    'code',
                    'phone_number',
                    'personal_email'
                ],
            },
        })
    }

    const getUsersForRR = async (ss_id: any) => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                fields: [
                    '*',
                    'role.*',
                    'resigns.*',
                    'resigns.reason.opt_value',
                    'resigns.note',
                    'resigns.interview.opt_value',
                    'route_code.name',
                    'system_providers.provider_id',
                ],
                filter: {
                    _and: [
                        {
                            _or: [
                                {
                                    role: { short_name: USER_ROLE.DCR },
                                },
                                {
                                    role: { short_name: USER_ROLE.SS },
                                },
                            ],
                        },
                        {
                            parent_id: ss_id,
                        },
                    ],
                },
            },
        })
    }

    const getUsers = async () => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                fields: ['*', 'role.*', 'resigns.*', 'resigns.interview.opt_value', 'route_code.name'],
            },
        })
    }

    const getDcrResignExisted = async () => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                fields: [
                    'id',
                    'full_name',
                    'resigns.*',
                    'system_providers.provider_id.id',
                    'system_providers.provider_id.name',
                    'resigns.interview.opt_value',
                    'route_code.name',
                ],
                filter: {
                    role: {
                        short_name: 'dcr',
                    },
                    resigns: {
                        _nnull: true,
                    },
                    _or: [
                        {
                            requests: {
                                _null: true,
                            },
                        },
                        {
                            requests: {
                                status: {
                                    _nin: [RECRUITMENT_REQUEST_STATUS.completed.value, RECRUITMENT_REQUEST_STATUS.pending.value, RECRUITMENT_REQUEST_STATUS.processing.value],
                                },
                            },
                        },
                    ]
                },
            },
        })
    }

    const getAllUsersBySS = async (parent_id: string) => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                filter: {
                    _and: [
                        {
                            role: { short_name: USER_ROLE.DCR },
                        },
                        {
                            parent_id: parent_id,
                        },
                    ],
                },
                fields: ['*'],
            },
        })
    }

    const getUsersWithFilterByResign = async () => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                fields: ['full_name', 'id', 'resigns.id'],
                filter: {
                    role: { short_name: USER_ROLE.DCR },
                    employment_status: USER_STATUS.on.value,
                },
                deep: {
                    resigns: {
                        _filter: {
                            status: RESIGN_STATUS.pending.value,
                        },
                    },
                },
            },
        })
    }

    const getUserByCode = async (_code: string) => {
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                fields: ['full_name', 'id'],
                filter: {
                    code: _code,
                },
            },
        })
    }

    const getListSS = async (_code: string, params: any = null) => {
        let _filter = {
            role: {
                short_name: USER_ROLE.SS,
            },
            employment_status: 'on',
            status: 'active',
        }

        if (params) {
            _filter = {
                ..._filter,
                ...params,
            }
        }

        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                fields: [
                    'full_name',
                    'id',
                    'route_code.name',
                    'route_code.name',
                    'system_providers.id',
                    'system_providers.provider_id.name',
                    'system_providers.provider_id.id',
                    'sub_users.id',
                    'sub_users.code',
                    'sub_users.employment_status',
                    'sub_users.route_code.name',
                    'sub_users.full_name',
                    'sub_users.system_providers.provider_id.name',
                    'sub_users.system_providers.provider_id.id',
                ],
                filter: _filter,
            },
        })
    }


    const getListAgency = async () => {
        let _filter = {
            role: {
                short_name: USER_ROLE.AGENCY,
            },
            employment_status: 'on',
            status: 'active',
        }

        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                limit: -1,
                fields: [
                    'full_name',
                    'id',
                    'route_code.name',
                    'route_code.name',
                    'system_providers.id',
                    'system_providers.provider_id.name',
                    'system_providers.provider_id.id',
                    'sub_users.id',
                    'sub_users.code',
                    'sub_users.employment_status',
                    'sub_users.route_code.name',
                    'sub_users.full_name',
                    'sub_users.system_providers.provider_id.name',
                    'sub_users.system_providers.provider_id.id',
                ],
                filter: _filter,
            },
        })
    }

    const getListDCRBySystemProvider = async (parent_id: string, provider_id: string = "" ,user_type: string) => {
        let _filter = {
            _and: [
                {
                    role: {
                        short_name: USER_ROLE.DCR,
                    },
                },
                {
                    parent_id: parent_id,
                },
                {
                    employment_status: 'on',
                },
                {
                    user_type: user_type
                },
                ...(user_type === USER_TYPE.RETAIL ? [
                    {
                        system_providers: {
                            provider_id: provider_id,
                        }
                    }
                ] : [])
            ],
        }
        return await api.get(`/${USER_COLLECTION}`, {
            params: {
                fields: ['full_name', 'id'],
                filter: _filter,
                limit: -1,
            },
        })
    }

    const getUserByUserId = async (userId: string) => {
        return await api.get(`/${USER_COLLECTION}/${userId}`, {
            params: {
                fields: [
                    'date_of_birth',
                    'phone_number',
                    'zone.name',
                    'area.name',
                    'system_providers.provider_id.name',
                    'system_providers.provider_id.id',
                    'parent_id.role.short_name',
                    'parent_id.full_name',
                    'code',
                    'id',
                    'full_name',
                    'gender',
                    'phone_number_zalo',
                    'email',
                    'education_level',
                    'married',
                    'route_code.name',
                    'start_date',
                    'province',
                    'district',
                    'address',
                    'id_card_date',
                    'id_card_number',
                    'id_card_place',
                    'attachments',
                    'note',
                    'bank_account_number',
                    'bank_branch',
                    'bank_name',
                    'zone.*',
                    'area.*',
                    'system_providers.*',
                    'route_code',
                    'code',
                    'parent_id.*',
                    'start_date',
                    'last_working_date',
                    'employment_status',
                    'resigns.*',
                    'resigns.resigns_history.*',
                    'resigns.resigns_history.reason.*',
                    'resigns.interview.*',
                    'resigns.date_created',
                    'resigns.status',
                    'resigns.reason.*',
                    'candidates.*',
                    'note',
                    'personal_email',
                ],
            },
        })
    }

    const patchItemUser = async (id: number, params: any) => {
        return await api.patch(`/${USER_COLLECTION}/${id}`, params)
    }

    const postItemUser = async (params: any) => {
        return await api.post(`/${USER_COLLECTION}`, params)
    }

    const getUserByRoleSpecify = async (userId: string, roleName: string = 'hr'): Promise<any> => {
        try {
            const userData = await api.get(`/${USER_COLLECTION}/${userId}`, {
                params: {
                    fields: ['id', 'full_name', 'role.short_name', 'parent_id'],
                },
            })
            if (!userData?.data?.data) return ''
            if (!userData?.data?.data?.role?.short_name) return ''
            if (userData?.data?.data?.role.short_name === roleName) {
                return userData
            } else {
                return getUserByRoleSpecify(userData?.data?.data?.parent_id, roleName)
            }
        } catch (err: any) {
            console.log('err: ', err)
            return ''
        }
    }

    const updatePassword = async (id: string, password: string) => {
        return await api.patch(`/api-auth/dcr/reset-password`, {
            id: id,
            password: password,
        })
    }

    const getAsms = async (_code: string, isWsr: boolean = false) => {
        let params: any = {
            limit: -1,
            fields: ['full_name', 'id', 'role.short_name', 'parent_id'],
            filter: null,
        }
        if (userStore.currentUser?.role?.short_name === USER_ROLE.HR) {
            params.filter = {
                _and: [
                    {
                        _or: [
                            {
                                role: {
                                    short_name: USER_ROLE.ASM,
                                },
                            },
                            {
                                role: {
                                    short_name: USER_ROLE.SS,
                                },
                            },
                        ],
                    },
                    {
                        area: {
                            id: {
                                _in: userStore.currentUser?.ares_management?.map((a) => a.id),
                            },
                        },
                    },
                    ...(isWsr ? [{ user_type: USER_TYPE.WSR }] : [])
                ],
            }
        } else if (userStore.currentUser?.role?.short_name === USER_ROLE.ASM) {
            params.filter = {
                parent_id: {
                    id: userStore.currentUser?.id,
                },
            }
        }

        if (!params.filter) delete params.filter

        return await api.get(`/${USER_COLLECTION}`, {
            params: params,
        })
    }

    const getAsmForReport = async () => {
        let params: any = {
            limit: -1,
            fields: ['full_name', 'id', 'role.short_name', 'parent_id'],
            filter: {
                role: {
                    short_name: USER_ROLE.ASM,
                },
            },
        }
        return await api.get(`/${USER_COLLECTION}`, {
            params: params,
        })
    }

    const getAllRole = async () => {
        return await api.get(`/${ROLES_COLLECTION}`, {
            params: {
                fields: ['*']
            },
        })
    }

    const getAllAsmAndSsWsr = async () => {
        let params: any = {
            limit: -1,
            fields: ['full_name', 'id', 'code', 'email'],
            filter: {
                _and: [
                    {
                        _or: [
                            {
                                role: {
                                    short_name: USER_ROLE.ASM,
                                },
                            },
                            {
                                role: {
                                    short_name: USER_ROLE.SS,
                                },
                            },
                        ],
                    },
                    {
                        user_type: USER_TYPE.WSR
                    }
                ],
            },
        }
        return await api.get(`/${USER_COLLECTION}`, {
            params: params,
        })
    }

    const getDcrWsr = async () => {
        let params: any = {
            limit: -1,
            fields: ['full_name', 'id', 'code'],
            filter: {
                status: {
                    _neq: DELETE_VALUE,
                },
                role: {
                    short_name: USER_ROLE.DCR,
                },
                user_type: USER_TYPE.WSR
            },
        }
        return await api.get(`/${USER_COLLECTION}`, {
            params: params,
        })
    }

    return {
        getProvider,
        getUsers,
        getCurrentUser,
        getUserByCode,
        getUserByUserId,
        patchItemUser,
        getListSS,
        getUserByRoleSpecify,
        getUsersWithFilterByResign,
        getAllUsersBySS,
        getListDCRBySystemProvider,
        getUsersForRR,
        updatePassword,
        getAsms,
        getDcrResignExisted,
        getAsmForReport,
        getAllDcr,
        getListAgency,
        postItemUser,
        getAllRole,
        getAllAsmAndSsWsr,
        getDcrWsr,
        getAllEmailUserWithLimit,
        getInfoDcrWithLimit,
        getProviderByProviderName,
    }
}
