import { useApi } from '@directus/extensions-sdk'
import { INTERVIEW_ROUND_COLLECTION } from '../utils/table-name'

export const useInterviewRoundQuery = () => {
    const api = useApi()

    const getInterviewRound = async (idCandidate: any) => {
        return await api.get(`/items/${INTERVIEW_ROUND_COLLECTION}`, {
            params: {
                limit: -1,
                fields: ['*', 'recruitment_request.*', 'recruitment_request.resign_id.last_working_day'],
                filter: {
                    status: 'open',
                    candidate: idCandidate,
                },
            },
        })
    }
    const patchItemInterviewRound = async (id: any, params: any) => {
        return await api.patch(`/items/${INTERVIEW_ROUND_COLLECTION}/${id}`, params)
    }
    return {
        getInterviewRound,
        patchItemInterviewRound,
    }
}
