import { useOption } from "@/shared-fe/composables/use-option";
import { useAreaQuery } from "@/shared-fe/services/area-service";
import { useUserQuery } from "@/shared-fe/services/user-service"
import { useStoreCommon } from "@/shared-fe/stores/common";
import { useStoreLoading } from "@/shared-fe/stores/loading";
import { FORMAT_TIME, MODIFY_ONBOARD_STATUS, RAW_MODIFY_ONBOARD_STATUS, RECORD_IMPORT_NUMBER, USER_ROLE, USER_TYPE } from "@/shared-fe/utils/constant";
import { parseToValidDate, toLowerCaseNonAccentVietnamese } from "@/shared-fe/utils/convert-data";
import { MODIFY_ONBOARDING_COLLECTION, USER_COLLECTION } from "@/shared-fe/utils/table-name";
import { useApi } from "@directus/composables"
import dayjs from "dayjs";
import { Ref, ref } from "vue"
import { useImportWsrValidate } from "./useImportWsrValidate";
import _ from "lodash";

const LIMIT_GETDATA = 20

export const useImportWsr = () => {
    const { getAllAsmAndSsWsr, getAllEmailUserWithLimit, getAllRole, getInfoDcrWithLimit, getProviderByProviderName } = useUserQuery();
    const { getAllAreaAndZoneItem } = useAreaQuery()
    const { getOptionByGroupData } = useOption()
    const storeCommon = useStoreCommon()
    const { messageLabels } = useImportWsrValidate()
    const allAsmAndSs: Ref<any[]> = ref([])
    const allZone: Ref<any[]> = ref([])
    const educationLevel: Ref<any[]> = ref([])
    const arrayGender: Ref<any[]> = ref([])
    const loading = ref(false)
    const roleDcr: Ref<any> = ref(null)
    const listEmail: Ref<string[]> = ref([])
    const apiPath = `/${USER_COLLECTION}`
    const api = useApi();
    const progressing = useStoreLoading();

    const getListEmail = async (dcrCode: string[]) => {
        if (dcrCode.length > 0) {
            const filter = {
                email: {
                    _in: dcrCode.map(x => `${x}@spvb.vn`)
                }
            }
            const resDcr = await getAllEmailUserWithLimit(filter)

            const dcrs: any[] = resDcr.data.data
            listEmail.value = listEmail.value.concat(dcrs.map(x => x.code))
        }
    }

    (async () => {
        loading.value = true
        const res = await getAllAsmAndSsWsr()
        const users: any[] = res.data.data
        if (users.length > 0) {
            allAsmAndSs.value = [...users]
        }
        getAllAreaAndZoneItem().then((res) => {
            if (res.data.data) {
                res.data.data?.forEach((x: any) => {
                    if (!x?.zone) {
                        allZone.value.push({
                            text: x?.name,
                            value: x?.id,
                        })
                    }
                })
            }
        })
        getAllRole().then((res) => {
            if (res?.data?.data) {
                let idDcr = res.data?.data?.find((x: any) => x.short_name === USER_ROLE.DCR)
                roleDcr.value = idDcr?.id
            }
        })
        const resEdu = await getOptionByGroupData('education_level')
        educationLevel.value = resEdu
        const resGender = await getOptionByGroupData('gender')
        arrayGender.value = resGender
        loading.value = false
    })()

    const arrayMarriedStatus = [
        {
            text: 'Độc thân',
            value: false,
        },
        {
            text: 'Kết hôn',
            value: true,
        },
    ]

    const arrayUserStatus = [
        {
            text: 'on',
            value: 'on',
        },
        {
            text: 'off',
            value: 'off',
        },
        {
            text: 'process resign',
            value: 'process resign',
        },
    ]

    const parseDataResultToValue = (result: string, arrayData: any[]) => {
        if (!result) {
            return null
        }
        let data = toLowerCaseNonAccentVietnamese(result.trim())
        let dataReturn: any = null;
        arrayData.map((item: any) => {
            const text = item.text ? toLowerCaseNonAccentVietnamese(item.text.trim()) : ''
            if (text && text.includes(data)) {
                dataReturn = item.value
            }
        })

        return dataReturn
    }

    const prepareRequestData = async (data: any[]) => {
        const emailReq = []
        for (let i = 0; i < data.length; i += LIMIT_GETDATA) {
            let dataImport = data.slice(i, i + LIMIT_GETDATA)
            console.log('dataImport: ', dataImport);
            emailReq.push(await getListEmail(dataImport.map(x => x.dcr_code)))
        }
        await Promise.all([emailReq])
    }

    const prepareProviderIdData = async () => {
        const res = await getProviderByProviderName("WSR PROVIDER")
        if (res?.data?.data?.length > 0) {
            return res?.data?.data[0]?.id
        }
        return ''
    }

    const importWsrData = async (data: any[], rawData: any[]) => {
        const providerId = await prepareProviderIdData()
        let errors: any[] = []
        let postParams: any[] = []
        let updateParams: any[] = []
        if (data && data.length > 0) {
            await prepareRequestData(data).then(() => {
                data.map((item, index) => {
                    const indexOfDcrCode = data.findIndex(x => x.dcr_code === item.dcr_code);
                    if (indexOfDcrCode !== index) {
                        const errorRow = rawData.findIndex(x => x.dcr_code === item.dcr_code && item.dcr_code !== '')
                        errors.push(`${item.dcr_code}||${messageLabels["dcr_code"]} bị trùng với ${messageLabels["dcr_code"]} Dòng ${errorRow + 1}`)
                    }
                })
                data.forEach(async (item: any) => {
                    const asm = allAsmAndSs.value.find((x: any) => x.email.toLowerCase() === item.asm_email.toLowerCase())?.id
                    if (!asm) {
                        errors.push(`${item.dcr_code}||${messageLabels["asm_email"]} không tìm thấy trong hệ thống`)
                    }
                    const ss = allAsmAndSs.value.find((x: any) => x.email.toLowerCase() === item.ss_email.toLowerCase())?.id
                    if (!ss) {
                        errors.push(`${item.dcr_code}||${messageLabels["ss_email"]} không tìm thấy trong hệ thống`)
                    }
                    const edu = parseDataResultToValue(item.education_level, educationLevel.value)
                    if (!edu && item.education_level) {
                        errors.push(`${item.dcr_code}||${messageLabels["education_level"]} không tìm thấy trong hệ thống`)
                    }
                    const gender = parseDataResultToValue(item.gender, arrayGender.value)
                    if (!gender) {
                        errors.push(`${item.dcr_code}||${messageLabels["gender"]} không tìm thấy trong hệ thống`)
                    }
                    const zone = parseDataResultToValue(item.zone, allZone.value)
                    if (!zone) {
                        errors.push(`${item.dcr_code}||${messageLabels["zone"]} lỗi định dạng`)
                    }
                    const married = parseDataResultToValue(item.married, arrayMarriedStatus)
                    if (married === undefined) {
                        errors.push(`${item.dcr_code}||${messageLabels["married"]} lỗi định dạng`)
                    }

                    const province: any = storeCommon.provinceList.find((x: any) => toLowerCaseNonAccentVietnamese(x?.name) === toLowerCaseNonAccentVietnamese(item.province.trim()))
                    if (!province) {
                        errors.push(`${item.dcr_code}||${messageLabels["province"]} không tìm thấy trong hệ thống`)
                    }
                    let district = null
                    const districtArray = storeCommon.getDistrictByProvinceId(province?.id || 0)
                    if (districtArray.length === 0) {
                        errors.push(`${item.dcr_code}||${messageLabels["district"]} không hợp lệ`)
                    } else {
                        const districtData: any = districtArray?.find((x: any) => toLowerCaseNonAccentVietnamese(item.district.trim()).includes(toLowerCaseNonAccentVietnamese(x?.name)))
                        if (!districtData) {
                            errors.push(`${item.dcr_code}||${messageLabels["district"]} không hợp lệ`)
                        } else {
                            district = districtData?.id
                        }
                    }
                    const employment_status = parseDataResultToValue(item.employment_status, arrayUserStatus)
                    if (!employment_status) {
                        errors.push(`${item.dcr_code}||${messageLabels["employment_status"]} lỗi định dạng`)
                    }
                    if (listEmail.value.indexOf(item.dcr_code.trim()) !== -1) {
                        errors.push(`${item.dcr_code}||${messageLabels["dcr_code"]} đã tồn tại trong hệ thống`)
                    }

                    if (errors.length === 0) {
                        const params = {
                            zone: zone,
                            parent_id: ss,
                            route_code: {
                                name: item.route_code
                            },
                            email: `${item.dcr_code}@spvb.vn`,
                            code: item.dcr_code,
                            full_name: item.full_name.trim(),
                            date_of_birth: parseToValidDate(item.date_of_birth).add(1, 'day').format(FORMAT_TIME.YYYMMDD),
                            gender: gender,
                            phone_number: item.phone_number.trim(),
                            phone_number_zalo: item.phone_number_zalo ? item.phone_number_zalo.trim() : '',
                            personal_email: item.email ? item.email.trim() : '',
                            education_level: item.education_level ? edu : undefined,
                            married,
                            id_card_number: item.id_card_number.trim(),
                            id_card_date: parseToValidDate(item.id_card_date).add(1, 'day').format(FORMAT_TIME.YYYMMDD),
                            id_card_place: item.id_card_place.trim(),
                            address: item.address.trim(),
                            district: district,
                            province: province?.id,
                            start_date: parseToValidDate(item.start_date).add(1, 'day').format(FORMAT_TIME.YYYMMDD),
                            last_working_date: item.last_working_date ? parseToValidDate(item.last_working_date).add(1, 'day').format(FORMAT_TIME.YYYMMDD) : null,
                            employment_status: employment_status,
                            note: item.note.trim(),
                            role: roleDcr.value,
                            user_type: USER_TYPE.WSR,
                            system_providers: {
                                "create": [
                                    {
                                        "user_id": "+",
                                        "provider_id": {
                                            "id": providerId
                                        }
                                    }
                                ],
                                "update": [],
                                "delete": []
                            }
                        }
                        postParams.push({ ...params })
                    }
                })
            })
        }

        if (data.length === postParams.length) {
            for (let i = 0; i < postParams.length; i += RECORD_IMPORT_NUMBER) {
                let dataImport = postParams.slice(i, i + RECORD_IMPORT_NUMBER)
                await api.post(apiPath, dataImport).catch()
                progressing.handleProgressing(i + dataImport.length)
            }

            for (let i = 0; i < updateParams.length; i += RECORD_IMPORT_NUMBER) {
                let dataImport = updateParams.slice(i, i + RECORD_IMPORT_NUMBER)
                await api.patch(apiPath, dataImport).catch()
                progressing.handleProgressing(i + dataImport.length + postParams.length)
            }
        }

        listEmail.value = []
        console.log('errors: ', errors);
        return { errors, success: postParams.length + updateParams.length }

    }
    return {
        importWsrData,
        parseDataResultToValue,
        loading
    }
}
