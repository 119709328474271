<template>
    <v-overlay v-if='data' :active='isShow' @esc='closeDialogFunc'>
        <div class='modal-target-detail px-15 py-8 bg-white min-w-[90vw] max-h-[90vh] overflow-y-scroll'>
            <div v-if='user' class='flex w-full items-end gap-x-6'>
                <v-form class='grid grid-cols-3 w-3/4' primaryKey='+' v-model='formSs' :initial-values='formSsInit' :fields='fieldsSs'></v-form>
                <v-button @click='saveSsData' class='w-1/4' icon medium>
                    <v-icon name='check'></v-icon>
                </v-button>
            </div>

            <div v-if='user' class='border border-[#CBD5E1] w-full border-solid h-[1px] my-6'></div>
            <div class='flex gap-x-2 items-center'>
                <div
                    class='rounded-md border border-solid border-[#E2E8F0] px-4 py-2 cursor-pointer'
                    :class="currentYear?.id === item?.id ? 'bg-[#67C2C3] text-white' : ''"
                    v-for='item in listYear'
                    :key='item'
                    @click='handleChooseYear(item)'
                >
                    {{ item?.year }}
                </div>
            </div>
            <div class='text-2xl font-bold mt-8'>Chỉ tiêu từng tháng</div>
            <div class='mt-4 w-1/4' v-if="type === 'add'">
                <div class='type-label'>Chọn năm</div>
                <v-select
                    showDeselect
                    :model-value="targetYear"
                    :items="getMonthToAdd"
                    @update:model-value="(e) => (targetYear = e)"
                ></v-select>
            </div>
            <div v-if="type === 'add'" class='border border-[#CBD5E1] w-full border-solid h-[1px] my-6'></div>
            <div class='my-7'>
                <div v-if="!user && type === 'edit'" class='text-[#3B82F6] text-sm italic mb-4'>
                    Những chỉ tiêu có giá trị bằng “0" sẽ được tính là miễn trừ.
                </div>

                <div v-if="(listYear && listYear.length > 0) || type === 'add'">
                    <template v-if='loading'>
                        <div class='grid gap-4'>
                            <v-skeleton-loader v-for='i in 6' class='c-loading' type='block-list-item-dense' />
                        </div>
                    </template>
                    <v-form v-else class='grid grid-cols-4' primaryKey='+' v-model='formData' :fields='fields'></v-form>
                </div>
            </div>
            <div
                v-if='Object.keys(errors).length > 0'
                class='v-form grid border border-solid border-red-400 rounded-md mb-7'
            >
                <ul class='p-6'>
                    <li v-for='error in errors'>
                        {{ error }}
                    </li>
                </ul>
            </div>
            <div class='flex justify-end gap-x-4'>
                <v-button secondary @click='closeDialogFunc'>Hủy</v-button>
                <v-button :loading='loading' :disabled='loading || !fullAccess' @click='handleSubmit'>
                    <span class='mr-2'>Lưu chỉ tiêu</span>
                    <v-icon name='check'></v-icon>
                </v-button>
            </div>
        </div>
    </v-overlay>
    <modal-success :is-show='isShowSuccessDialog' @close='onCloseModal' />
</template>
<script setup lang='ts'>
import dayjs from 'dayjs'
import { ref, toRefs, watch } from 'vue'
import { useTargets } from '../composables/use-targets'
import ModalSuccess from './modal-success.vue'
import { useFieldTemplate } from '@/shared-fe/composables/use-field-template'
import { computed } from 'vue'
import { notify } from '@/utils/notify'

const dataDefault = {
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
    note: ''
}

const props = defineProps({
    isShow: {
        type: Boolean,
        default: false
    },
    data: {
        type: Object,
        default: {}
    },
    user: {
        type: String,
        default: ''
    },
    mode: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'edit'
    }
})
const { isShow, data, user, mode, type } = toRefs(props)
const listYear = ref([])
const formData = ref({ ...dataDefault })
const formSs = ref({})
const formSsInit = ref({})
const targetYear = ref('')
const errors = ref({})
const isShowSuccessDialog = ref(false)
const idTarget = ref('')
const currentYear = ref(<any>null)
const loading = ref(<boolean>false)
const emit = defineEmits(['close', 'refresh'])
const { fieldInput } = useFieldTemplate({})

const {
    getAvailableYear,
    getDetailTargetMonth,
    fieldsSs,
    getSsInformation,
    saveSs,
    save,
    validate,
    fullAccess,
    getMonthToAdd
} = useTargets(mode.value, type.value)

const handleGetSsInformation = async () => {
    const res = await getSsInformation(user.value ? user.value : '')
    if (res) {
        formSsInit.value = {
            full_name: res.full_name,
            start_month: res.start_month ? String(res.start_month) : '',
            start_date: res.start_date ? dayjs(res.start_date).format("YYYY-MM-DD") : null
        }
    }
}

const closeDialogFunc = () => {
    emit('close')
    formData.value = { ...dataDefault }
    errors.value = {}
}

const handleChooseYear = async (year: any) => {
    currentYear.value = { ...year }
    loading.value = true
    const res = await getDetailTargetMonth(year.id)
    loading.value = false
    if (res) {
        idTarget.value = res?.id
        targetYear.value = res.year
        formData.value = {
            january: res.january,
            february: res.february,
            march: res.march,
            april: res.april,
            may: res.may,
            june: res.june,
            july: res.july,
            august: res.august,
            september: res.september,
            october: res.october,
            november: res.november,
            december: res.december,
            note: res?.note
        }
    }
}
const handleSubmit = async () => {
    Object.keys(formData.value).map((x) => Number(formData.value[x]))
    const val = validate(formData.value)

    errors.value = {}
    if (!targetYear.value && type.value === 'add') {
        errors.value = {
            year: 'Năm: là bắt buộc'
        }
    }

    if (val.error || Object.keys(errors.value).length > 0) {
        errors.value = { ...errors.value, ...val.data }
        return
    }
    const payload = {
        ...formData.value,
        type_module: "saturday_learn"
    }
    loading.value = true
    const res = await save(payload, idTarget.value, type.value, !fullAccess.value ? 'user' : 'global', targetYear.value)
    loading.value = false
    if (res) {
        emit('refresh')
        isShowSuccessDialog.value = true
    }
}

const saveSsData = async () => {
    let params: any = { ...formSs.value }
    for (let key in params) {
        if (!params[key]) delete params[key]
    }

    await saveSs(params, user.value)

    notify({
        title: "Cập nhật thông tin SS thành công",
        text: "Ngày gia nhập của SS đã được lưu",
        dialog: true
    })
}

const handleGetDetailTargetMonth = async () => {
    loading.value = true
    const res = await getDetailTargetMonth(currentYear.value?.id)
    loading.value = false
    if (res) {
        idTarget.value = res?.id
        targetYear.value = res.year
        formData.value = {
            january: res.january,
            february: res.february,
            march: res.march,
            april: res.april,
            may: res.may,
            june: res.june,
            july: res.july,
            august: res.august,
            september: res.september,
            october: res.october,
            november: res.november,
            december: res.december,
            note: res.note
        }
    }
}

const isDisableByMonth = (month: any, year: any) => {
    const date = new Date()

    //if (!fullAccess.value) return true

    if (+date.getFullYear() > +year)
        return true

    return (+date.getFullYear() == +year) && (month < date.getMonth() + 1)
}

const months = [
    { name: 'january', value: 1 },
    { name: 'february', value: 2 },
    { name: 'march', value: 3 },
    { name: 'april', value: 4 },
    { name: 'may', value: 5 },
    { name: 'june', value: 6 },
    { name: 'july', value: 7 },
    { name: 'august', value: 8 },
    { name: 'september', value: 9 },
    { name: 'october', value: 10 },
    { name: 'november', value: 11 },
    { name: 'december', value: 12 }
]

const fields = computed(() => {
    const __fs = months.map((i) => {
        return fieldInput(i.name, `Tháng ${i.value}`, {
            type: 'number',
            width: 'col-span-1',
            readonly: isDisableByMonth(i.value, targetYear.value)
        })
    })

    if (mode.value === 'user') {
        __fs.unshift(
            fieldInput('note', 'Lý do thay đổi chỉ tiêu', {
                width: 'col-span-4',
                note: 'Lí do thay đổi chỉ tiêu: nghỉ thai sản, nghỉ tai nạn, ốm đau...). Đây là trường bắt buộc khi thực hiện thay đổi chỉ tiêu (*)',
                options: {
                    placeholder: 'Nhập lý do...'
                }
            })
        )
    }
    return __fs
})

watch(
    () => data.value,
    async () => {
        if (mode.value === 'global') {
            getAvailableYear(
                !fullAccess.value ? 'user' : 'global',
                !fullAccess.value && user.value ? user.value : ''
            ).then((res) => {
                listYear.value = res
            })

            currentYear.value = { id: data.value?.id, year: data.value?.year }
            if (currentYear.value?.id && currentYear.value?.year) {
                handleGetDetailTargetMonth()
            }
        }
    }
)

watch(
    () => user.value,
    async () => {
        if (user.value && mode.value === 'user') {
            handleGetSsInformation()
            getAvailableYear(mode.value, user.value).then((res) => {
                listYear.value = res

                if (res && res.length > 0) {
                    currentYear.value = { ...res[0] }
                    handleGetDetailTargetMonth()
                }
            })
        }
    }
)

const onCloseModal = () => {
    isShowSuccessDialog.value = false;
    emit("close")
}
</script>

<style lang='scss' scoped>
.v-overlay {
    z-index: 99 !important;
}
</style>
