<template>
    <c-role>
        <private-view title="Danh sách ứng viên">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <!-- navigation end  -->
            <!-- header start -->
            <template #actions>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-yctd" v-if="modelSelected?.length > 0">
                        <v-button v-tooltip.bottom="LINKED_RECRUITMENT_REQUEST" @click="showDialogCandidateLinkYCTD">
                            <div class="i-mdi-arrow-top-right-thin-circle-outline" />
                            <span class="ml-1">{{ LINKED_RECRUITMENT_REQUEST }}</span>
                        </v-button>
                    </div>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <span class="custom-button-export-excel">
                        <v-button secondary @click="exportData" :loading="_export.loadingExcel.value">
                            <v-icon name="file_upload" outline />
                            <span class="inline-block ml-2">
                                {{ EXPORT_EXCEL }}
                            </span>
                        </v-button>
                    </span>
                </c-access>
                <!-- <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-hsuv" v-if="modelSelected?.length > 0">
                        <v-button v-tooltip.bottom="SAVE_FILE" @click="saveFiles">
                            <div class="i-mdi-archive-arrow-down-outline" />
                            <span class="ml-1">{{ SAVE_FILE }}</span>
                        </v-button>
                    </div>
                </c-access> -->
                <!-- <v-button v-tooltip.bottom="REFRESH" class="btn-refresh" rounded icon secondary @click="refresh">
                    <div class="i-mdi-cached"></div>
                </v-button> -->
                <c-access :roles="[USER_ROLE.HR]">
                    <v-button
                        v-tooltip.bottom="CREATE_ITEM"
                        class="custom-create-button"
                        :to="PATH_ROUTER.CANDIDATE_ADD.PATH"
                    >
                        Tạo ứng viên
                    </v-button>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <v-dialog v-if="modelSelected?.length > 0" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <!-- <v-button
                    v-if="modelSelected?.length > 0"
                    v-tooltip.bottom="EDIT"
                    rounded
                    icon
                    secondary
                    @click="batchEditActive = true"
                >
                    <div class="i-mdi-pencil-outline"></div>
                </v-button> -->
                <recruitment-request-modal
                    :overlay="activeRecruitmentRequestDialog"
                    @input="(e) => handleUpdateRecruitmentRequest(e)"
                    @close="activeRecruitmentRequestDialog = false"
                />
                <candidate-link-recruitment-request-modal
                    :items="candidateLinkYCTDlst"
                    :overlay="activeCandidateLinkYCTDDialog"
                    @submit="
                        () => {
                            activeCandidateLinkYCTDDialog = false
                            activeRecruitmentRequestDialog = true
                        }
                    "
                    @close="activeCandidateLinkYCTDDialog = false"
                />

                <drawer-batch
                    v-model:active="batchEditActive"
                    :primary-keys="primaryKeys"
                    :collection="CANDIDATE_COLLECTION"
                    @refresh="batchRefresh"
                />
            </template>
            <!-- header end -->
            <div class="mx-9">
                <!-- searchbar start -->
                <candidate-searchbar
                    :arrayValue="objSearchStore?.dataSeletedForm"
                    :arrayStatus="arrayStatus"
                    @update:handleSearch="handleSearch"
                />
                <!-- searchbar end -->

                <!-- content start -->
                <candidate-table
                    :items="itemsRender"
                    :modelSelected="modelSelected"
                    @update:modelSelected="modelSelectedFun"
                    :totalPages="totalPages"
                    :page="page"
                    :loading="loading"
                    @update:toPage="handleToPage"
                    @updateSort="handleUpdateSort"
                />
                <!--  content end -->
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" title="Danh sách ứng viên" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useItems } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import CAccess from '../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../shared-fe/composables/use-batch'
import { useExport } from '../../../../shared-fe/composables/use-export'
import { useField } from '../../../../shared-fe/composables/use-field'
import { useCommonQuery } from '../../../../shared-fe/services/common-service'
import { useStoreSearch } from '../../../../shared-fe/stores/search'
import {
    CANDIDATES_FILTER_PROFILE,
    CANDIDATE_STATUS,
    DELETE_VALUE,
    RECORD_PER_PAGE,
    USER_ROLE,
} from '../../../../shared-fe/utils/constant'
import { formatDateObjectToQuery } from '../../../../shared-fe/utils/convert-data'
import {
    CANCEL,
    CONTENT_DELETE,
    CREATE_ITEM,
    EXPORT_EXCEL,
    LABEL_DELETE,
    LINKED_RECRUITMENT_REQUEST,
    SAVE_FILE,
} from '../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import { CANDIDATE_COLLECTION } from '../../../../shared-fe/utils/table-name'
import CandidateLinkRecruitmentRequestModal from './components/candidate-link-recruitment-request-modal.vue'
import CandidateSearchbar from './components/candidate-searchbar.vue'
import CandidateTable from './components/candidate-table.vue'
import RecruitmentRequestModal from './components/recruitment-request-modal.vue'
import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'

const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
const { canAsm, canSs } = useAppPermissions()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
const { getArrayStatus } = useField()
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-date_created'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)
const activeRecruitmentRequestDialog = ref(false)
const _useCommonQuery = useCommonQuery()
const primaryKeys = computed(() => {
    return modelSelected.value?.map((x) => x.id) || []
})
const activeCandidateLinkYCTDDialog = ref(false)
const candidateLinkYCTDlst = ref(<any>[])
const customFilter = computed(() => {
    let _filter: any = {
        _and: [
            {
                status_display: {
                    _neq: DELETE_VALUE,
                },
            },
        ],
    }
    let filterData: any = {}
    
    if (canSs.value) {
        filterData = {
            status: {
                opt_key: {
                    _nin: ['applied', 'interview_1', 'not_passed_interview_1'],
                },
            },
        }
    } else if (canAsm.value) {
        filterData = {
            status: {
                opt_key: {
                    _in: [
                        'passed_rr',
                        'interview_3',
                        'passed_interview_3',
                        'not_passed_interview_3',
                        'waiting_for_job',
                        'hired',
                        'not_hired',
                    ],
                },
            },
        }
    }

    if (canSs.value || canAsm.value) {
        _filter._and.push(filterData)
    }
    if (filter.value && Object.entries(filter.value).length > 0) {
        _filter = {
            ..._filter,
            ...filter.value,
        }
    }

    return _filter
})

const { items, totalPages, getItems, getTotalCount, getItemCount, loading } = useItems(ref(CANDIDATE_COLLECTION), {
    fields: ref([
        '*',
        'province.name',
        'district.name',
        'province_recruitment.name',
        'district_recruitment.name',
        'gender.opt_value',
        'status.*',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search: ref(null),
    filter: customFilter,
    page: page,
})
const itemsRender = computed(() => {
    let _itemsRender: any = []
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    items.value.forEach((x) => {
        index++
        _itemsRender.push({
            stt: index,
            ...x,
        })
    })

    return _itemsRender
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const arrayStatus = computed(() => {
    return getArrayStatus(CANDIDATE_COLLECTION)
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {
        province_recruitment: data.province_id
            ? {
                  id: {
                      _eq: data.province_id,
                  },
              }
            : null,
        district_recruitment: data.district_id
            ? {
                  id: {
                      _eq: data.district_id,
                  },
              }
            : null,
        status: data.status || null,
    }

    if (data.education_level) {
        _filter.education_level = {
            id: data.education_level,
        }
    }

    if (data.source) {
        _filter.source = {
            id: data.source,
        }
    }

    if (data.date_created && data.date_created.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.date_created[0], data.date_created[1])
        _filter.date_created = {
            _between: dateQuery,
        }
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value) {
            delete _filter[key]
        }
    }

    if (data.title) {
        _filter._and = [
            {
                _or: [
                    {
                        full_name: {
                            _contains: data.title,
                        },
                    },
                    {
                        phone_number: {
                            _contains: data.title,
                        },
                    },
                    {
                        email: {
                            _contains: data.title,
                        },
                    },
                ],
            },
        ]
    }

    if (data.filter_profiles) {
        _filter.filter_profiles = data.filter_profiles
    }

    filter.value = _filter
    updateSearchStore(
        {
            search: data.title,
            filter: _filter,
        },
        data
    )
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

// table end

// actions start
const showDialogCandidateLinkYCTD = () => {
    let _candidateLinkYCTDlst: any = []
    if (modelSelected.value.length > 0) {
        modelSelected.value.forEach((x) => {
            _candidateLinkYCTDlst.push({
                full_name: x?.full_name,
                request_id: x?.request_id,
                id: x?.id,
            })
        })
    }
    candidateLinkYCTDlst.value = _candidateLinkYCTDlst
    if (candidateLinkYCTDlst.value?.filter((x) => x.request_id).length > 0) {
        activeCandidateLinkYCTDDialog.value = true
    } else {
        activeRecruitmentRequestDialog.value = true
    }
}
const batchRefresh = async () => {
    modelSelected.value = []
    await refresh()
}

// const saveFiles = async () => {
//     const batchPrimaryKeys = modelSelected.value.map((x: any) => x?.id)
//     await _useCommonQuery.patchArchiveItem(batchPrimaryKeys, true, 'is_archived', CANDIDATE_COLLECTION)
//     modelSelected.value = []
//     await refresh()
// }

const handleUpdateRecruitmentRequest = async (e) => {
    if (e?.length > 0) {
        const batchPrimaryKeys = candidateLinkYCTDlst.value.filter((x) => !x.request_id)?.map((x: any) => x?.id)
        if (batchPrimaryKeys?.length > 0) {
            await _useCommonQuery.patchArchiveItem(batchPrimaryKeys, e[0]?.id, 'request_id', CANDIDATE_COLLECTION)
        }
        modelSelected.value = []
        await refresh()
    }
}
const batchDeleteFun = async () => {
    await batchDelete('status_display')
    await refresh()
}

const {
    confirmDelete,
    deleting,
    batchDelete,
    error: deleteError,
    batchEditActive,
    modelSelected,
} = useBatch(CANDIDATE_COLLECTION)

const _export = useExport(CANDIDATE_COLLECTION, {
    stt: 'STT',
    id: 'Mã ứng viên',
    'province_recruitment.name': 'Tỉnh / Thành phố ứng tuyển',
    'district_recruitment.name': 'Quận / Huyện ứng tuyển',
    full_name: 'Họ và tên',
    date_of_birth: 'Ngày sinh',
    'gender.opt_value': 'Giới tính',
    phone_number: 'Số điện thoại',
    phone_number_zalo: 'Số điện thoại sử dụng zalo',
    email: 'Email',
    'education_level.opt_value': 'Trình độ học vấn',
    address: 'Địa chỉ hiện tại',
    'district.name': 'Quận / Huyện',
    'province.name': 'Tỉnh / Thành phố',
    experience_detail: 'Chi tiết kinh nghiệm',
    'source.opt_value': 'Nguồn ứng viên',
    date_created: 'Ngày ứng tuyển',
    date_updated: 'Ngày cập nhật',
    'status.opt_key': 'Trạng thái',
    filter_profiles: 'Thông tin lọc hồ sơ',
    'request_id.id': 'Mã YCTD',
    'request_id.hr_id.full_name': 'Người phụ trách',
    'interview_rounds.interview_details.*': 'Ngày PV1',
    'interview_rounds.interview_details.status': 'Kết quản PV1',
    'interview_rounds.interview_details.content': 'Đánh giá PV1',
    'interview_rounds.interview_details.user_created.full_name': 'Người PV1',
    'interview_rounds.interview_details.id': 'Ngày PV2',
    'interview_rounds.interview_details.candidate': 'Kết quản PV2',
    'interview_rounds.ride_step': 'Đánh giá PV2',
    'interview_rounds.user_created': 'Người PV2',
    'interview_rounds.date_route_ride': 'Ngày bắt đầu RR',
    'interview_rounds.status': 'Ngày training RR',
    'interview_rounds.staff': 'Kết quả RR',
    'interview_rounds.pv2': 'Đánh giá RR',
    'interview_rounds.pv1': 'Ngưởi phụ trách RR',
    'interview_rounds.pv3': 'Ngày PV3',
    'interview_rounds.start_date': 'Kết quản PV3',
    'interview_rounds.date_finished': 'Đánh giá PV3',
    'interview_rounds.date_send_email': 'Người PV3',
    'interview_rounds.date_completed_ride': 'Ngày gửi offer',
    'interview_rounds.date_updated': 'Ngày nhận việc',
    'interview_rounds.user_updated': 'Tình trạng nhận việc',
    'interview_rounds.id': 'Lý do không nhận việc',
})
_export.filename.value = 'ExportCandidate.xlsx'
_export.filter.value = {
    status_display: {
        _neq: DELETE_VALUE,
    },
}
_export.beforeGenerate.value = (items) => {
    let res: any = []
    let candidate
    items.forEach((item, index) => {
        candidate = {
            stt: index + 1,
            id: item?.id,
            province_recruitment_name: item?.province_recruitment?.name,
            district_recruitment_name: item?.district_recruitment?.name,
            full_name: item?.full_name,
            date_of_birth: item?.date_of_birth ? new Date(item?.date_of_birth) : '',
            gender_opt_value: item?.gender?.opt_value,
            phone_number: item?.phone_number,
            phone_number_zalo: item?.phone_number_zalo,
            email: item?.email,
            education_level_name: item?.education_level?.opt_value,
            address: item?.address,
            district_name: item?.district?.name,
            province_name: item?.province?.name,
            experience_detail: item?.experience_detail,
            source_name: item?.source?.opt_value,
            date_created: item?.date_created ? new Date(item?.date_created) : '',
            date_updated: item?.date_updated ? new Date(item?.date_updated) : '',
            status: CANDIDATE_STATUS[item?.status?.opt_key]?.text,
            filter_profiles: CANDIDATES_FILTER_PROFILE[item?.filter_profiles]?.text,
            request_id: item?.request_id?.id,
            request_id_hr_id_full_name: item?.request_id?.hr_id?.full_name,
        }
        if (item?.interview_rounds?.length > 0) {
            item?.interview_rounds.forEach((round_step, stepIndex) => {
                let objPv1 = round_step?.interview_details.find((x) => x?.candidate_status === 'interview_1')
                let objPv2 = round_step?.interview_details.find((x) => x?.candidate_status === 'interview_2')
                let objRR = round_step?.interview_details.find((x) => x?.candidate_status === 'route_ride_result')
                let objPv3 = round_step?.interview_details.find((x) => x?.candidate_status === 'interview_3')
                let objWaitingForJob = round_step?.interview_details.find(
                    (x) => x?.candidate_status === 'waiting_for_job'
                )
                let interview_round = {
                    date_pv1: objPv1?.date_created ? new Date(objPv1?.date_created) : '',
                    result_pv1: !objPv1 ? '' : objPv1?.status === true ? 'Đạt' : 'Không đạt',
                    content_pv1: objPv1?.content,
                    person_pv1: objPv1?.user_created?.full_name,

                    date_pv2: objPv2?.date_created ? new Date(objPv2?.date_created) : '',
                    result_pv2: !objPv2 ? '' : objPv2?.status === true ? 'Đạt' : 'Không đạt',
                    content_pv2: objPv2?.content,
                    person_pv2: objPv2?.user_created?.full_name,

                    date_route_ride: round_step?.date_route_ride ? new Date(round_step?.date_route_ride) : '',
                    date_tranning_route_ride: '',
                    result_route_ride: !objRR ? '' : objRR?.status === true ? 'Đạt' : 'Không đạt',
                    content_result_route_ride: objRR?.content,
                    person_route_ride: objRR?.user_created?.full_name,

                    date_pv3: objPv3?.date_created ? new Date(objPv3?.date_created) : '',
                    result_pv3: !objPv3 ? '' : objPv3?.status === true ? 'Đạt' : 'Không đạt',
                    content_pv3: objPv3?.content,
                    person_pv3: objPv3?.user_created?.full_name,

                    date_send_email: round_step?.date_send_email ? new Date(round_step?.date_send_email) : '',
                    date_hired_job:
                        objWaitingForJob?.status === true
                            ? objWaitingForJob?.date_created
                                ? new Date(objWaitingForJob?.date_created)
                                : ''
                            : '',
                    waiting_for_job_status: !objPv3
                        ? ''
                        : objWaitingForJob?.status === true
                        ? 'Nhận việc'
                        : 'Không nhận việc',
                    waiting_for_job_content: objWaitingForJob?.content,
                }
                if (stepIndex === 0) {
                    candidate = {
                        ...candidate,
                        ...interview_round,
                    }
                } else {
                    candidate = {
                        stt: '',
                        id: '',
                        province_recruitment_name: '',
                        district_recruitment_name: '',
                        full_name: '',
                        date_of_birth: '',
                        gender_opt_value: '',
                        phone_number: '',
                        phone_number_zalo: '',
                        email: '',
                        education_level_name: '',
                        address: '',
                        district_name: '',
                        province_name: '',
                        experience_detail: '',
                        source_name: '',
                        date_created: '',
                        date_updated: '',
                        status: '',
                        filter_profiles: '',
                        request_id: '',
                        request_id_hr_id_full_name: '',
                        ...interview_round,
                    }
                }

                res.push(candidate)
            })
        } else {
            res.push(candidate)
        }
    })
    return res
}
const exportData = () => {
    _export.filter.value = customFilter.value
    if (primaryKeys.value && primaryKeys.value.length > 0) {
        _export.filter.value = {
            id: {
                _in: primaryKeys.value,
            },
        }
    }
    _export.sort.value = sort.value
    _export.run()
}
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang="scss" scoped></style>
