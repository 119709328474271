<template>
    <c-role>
        <private-view title="Đánh giá thử việc">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.VALUE,
                            to: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <div class="box-save">
                    <v-button :disabled="isDisabledSave" v-tooltip.bottom="SAVE" icon rounded @click="save">
                        <v-icon name="check" />
                    </v-button>
                </div>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->
            <div class="detail-probationary-assessment">
                <info-dcr v-if="objInfoDcr" :item="objInfoDcr" />

                <ss-assessment
                    :dropdownStatus="dropdownStatus"
                    :item="objSsAssessment"
                    :start-date="startDate"
                    :end-date="objInfoDcr?.end_date"
                />

                <c-access :roles="[USER_ROLE.ASM, USER_ROLE.HR]">
                    <asm-assessment
                        :dropdownStatus="dropdownStatus"
                        :item="objAsmAssessment"
                        :end-date="objInfoDcr?.end_date"
                    />
                </c-access>
            </div>
            <!--  content end -->

            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin ứng viên" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { FORMAT_TIME, MODE_EDIT, SUBMIT_EVENT, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { SAVE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import dayjs from 'dayjs'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useProbationaryAssessmentQuery } from '../../../../../shared-fe/services/probationary-assessment-service'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import AsmAssessment from '../components/asm-assessment.vue'
import InfoDcr from '../components/info-dcr.vue'
import SsAssessment from '../components/ss-assessment.vue'
const commonStore = useStoreCommon()
const route = useRoute()
const objInfoDcr = ref(<any>null)
const objAsmAssessment = ref(<any>null)
const objSsAssessment = ref(<any>null)
const startDate = ref(<any>null)
const { getItemProbationaryAssessment } = useProbationaryAssessmentQuery()
onBeforeUnmount(() => {})

const dropdownStatus = [
    {
        text: 'Chưa đánh giá',
        value: null,
    },
    {
        text: 'Đạt',
        value: true,
    },
    {
        text: 'Không đạt',
        value: false,
    },
]
// get data detail (start)
onMounted(async () => {
    if (route?.params?.id) {
        await getDetail()
    }
})

const isDisabledSave = computed(() => {
    const parts = objInfoDcr.value.end_date.split('/')

    const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00Z`
    let validEndDate = new Date(formattedDateString)
    validEndDate.setUTCHours(0, 0, 0, 0)

    let currentDate = new Date()
    currentDate.setUTCHours(0, 0, 0, 0)

    return dayjs(validEndDate).isBefore(currentDate)
})

const getDetail = async () => {
    let res = await getItemProbationaryAssessment(route?.params?.id.toString())
    let data = JSON.parse(JSON.stringify(res?.data?.data))
    objAsmAssessment.value = {
        id: data.id,
        asm_status: data?.asm_status,
        asm_note: data?.asm_note,
    }
    objSsAssessment.value = {
        id: data.id,
        work_day_status: data?.work_day_status,
        work_day: data?.work_day,
        work_attitude: data?.work_attitude,
        work_attitude_status: data?.work_attitude_status,
        compliance_status: data?.compliance_status,
        compliance: data?.compliance,
        execution_point: data?.execution_point,
        execution_point_status: data?.execution_point_status,
        sales_volume: data?.sales_volume,
        sales_volume_status: data?.sales_volume_status,
        percentage_total: data?.percentage_total,
        ss_note: data?.ss_note,
        ss_status: data?.ss_status,
    }
    objInfoDcr.value = {
        zone_name: data.dcr.zone?.name,
        area_name: data.dcr.area?.name,
        system_provider_name:
            data.dcr.system_providers?.length > 0 ? data.dcr.system_providers[0].provider_id?.name : '',
        ss_full_name: data.dcr.parent_id
            ? displayParentName(data.dcr.parent_id?.role?.short_name, data.dcr.parent_id?.full_name)
            : '',
        code: data.dcr.code,
        route_code: data.dcr?.route_code?.name,
        dcr_full_name: data.dcr.full_name,
        start_date: dayjs(data.start_date).format(FORMAT_TIME.DDMMYYYY),
        end_date: dayjs(data.start_date).add(59, 'day').format(FORMAT_TIME.DDMMYYYY),
    }

    startDate.value = data.start_date
}

// get data detail (end)

// handle logic header (start)
const router = useRouter()
const save = () => {
    commonStore.submitEvent({
        name: SUBMIT_EVENT.PROBATIONARY_ASSESSMENTS_SUBMIT,
        payload: MODE_EDIT,
    })
}

const navigateBack = () => {
    router.push({
        name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
    })
}

// handle logic header (end)
</script>

<style lang="scss">
.detail-probationary-assessment {
    .custom-style-button {
        .button {
            padding: 1rem 2rem;
            height: var(--input-height);
            background-color: var(--primary);
            color: white;
            border-color: var(--primary);
            &:hover,
            &:focus {
                background-color: var(--primary);
                color: white;
                border-color: var(--primary);
            }
            &:disabled {
                color: var(--v-button-color-disabled);
                background-color: var(--v-button-background-color-disabled);
                border: var(--border-width) solid var(--v-button-background-color-disabled);
                cursor: not-allowed;
            }
        }
    }
}
</style>
