<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            :loading="loading"
            v-model:modelValue="modelValue"
            :disabled="disabled"
            manualSortKey="tel"
            :sort="internalSort"
            @update:sort="updateSort($event)"
            showSelect="multiple"
            @click:row="detailRowOnClick"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->

            <template #[`item.status`]="{ item }">
                <c-status :collectionName="CANDIDATE_COLLECTION" :id="item.status?.opt_key"></c-status>
            </template>

            <template #[`item.date_of_birth`]="{ item }">
                {{ item.date_of_birth ? dayjs(item.date_of_birth).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.gender`]="{ item }">
                {{ item.gender?.opt_value }}
            </template>
            <template #[`item.date_created`]="{ item }">
                {{ item.date_created ? dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.province_recruitment`]="{ item }">
                {{ item.province_recruitment?.name }}
            </template>
            <template #[`item.district_recruitment`]="{ item }">
                {{ item.district_recruitment?.name }}
            </template>
            <template #[`item.source`]="{ item }">
                {{ item.source?.opt_value }}
            </template>
            <template #[`item.refer_code`]="{ item }">
                {{ item.refer?.code }}
            </template>
            <template #[`item.refer_full_name`]="{ item }">
                {{ item.refer?.full_name }}
            </template>
            <template #[`item.system_providers`]="{ item }">
                {{ item.refer?.system_providers?.length > 0 ? item.refer.system_providers[0]?.provider_id?.name : '' }}
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import { CANDIDATE_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}

const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'deleteItem', 'updateSort'])

const itemsRender = computed(() => {
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return []
})

const headers = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 90,
    },
    {
        text: 'Nguồn ứng viên',
        value: 'source',
    },
    {
        text: 'Mã người giới thiệu',
        value: 'refer_code',
        width: 200,
    },
    {
        text: 'Tên người giới thiệu',
        value: 'refer_full_name',
        width: 250,
    },
    {
        text: 'Nhà phân phối',
        value: 'system_providers',
    },
    {
        text: 'Họ và tên',
        value: 'full_name',
        width: 250,
    },
    {
        text: 'Ngày sinh',
        value: 'date_of_birth',
    },
    {
        text: 'Số điện thoại',
        value: 'phone_number',
    },
    {
        text: 'Tỉnh / Thành phố',
        value: 'province_recruitment',
        width: 200,
    },
    {
        text: 'Quận / Huyện',
        value: 'district_recruitment',
    },
    {
        text: 'Trạng thái',
        value: 'status',
    },
    {
        text: 'Ngày giới thiệu',
        value: 'date_created',
    },
])

const detailRowOnClick = (data: any) => {
    router.push({
        name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
        params: {
            id: data.item.id,
        },
        query: {
            introduct_candidate: 1,
        },
    })
}

const convertKeySort = (sortKey, desc) => {
    let key = ''
    switch (sortKey) {
        case 'source':
            key = 'source.opt_value'
            break
        case 'refer_code':
            key = 'refer.code'
            break
        case 'refer_full_name':
            key = 'refer.full_name'
            break
        case 'system_providers':
            key = 'refer.system_providers.provider_id.name'
            break
        case 'phone_number':
            key = 'phone_number'
            break
        case 'full_name':
            key = 'full_name'
            break
        case 'date_of_birth':
            key = 'date_of_birth'
            break
        case 'province_recruitment':
            key = 'province_recruitment.name'
            break
        case 'district_recruitment':
            key = 'district_recruitment.name'
            break
        case 'status':
            key = 'status'
            break
        case 'date_created':
            key = 'date_created'
            break

        default:
            key = sortKey
            break
    }
    if (desc) key = `-${key}`
    return key
}
const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    if (sort?.by !== 'stt') {
        emit('updateSort', convertKeySort(sort?.by, sort?.desc))
    }
}

// const changeManualSort = (data) => {
//     // when update position (required field ID in the column)
//     // call api update position
// }
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
