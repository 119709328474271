import { useUserStore } from '@/stores/user'

export default function usePermission() {
    const userStore = useUserStore()

    const canAccess = (roles: Array<string>) => {
        if (userStore.currentUser?.role.admin_access) {
            return true
        }
        if (roles.includes(userStore.currentUser?.role.short_name)) {
            return true
        }
        return false
    }

    return {
        canAccess,
    }
}
