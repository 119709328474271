<template>
    <sidebar-detail icon="back_Hand" class="resign-sidebar" title="Thao tác nhanh">
        <v-skeleton-loader v-if="loading" class="c-loading" type="input-tall" />
        <div v-else>
            <div>
                <div class="text-12px text-[#94A3B8] mb-">Trạng thái</div>
                <div>{{ displayStatus }}</div>
            </div>
            <div v-if="enableQuickActions">
                <div class="my-6 pt-6 border-y-1px border-y-solid border-[#D3DAE4]">
                    <div v-if="!isASM" class="mb-6">
                        <div class="text-[#1E293B] mb-2">Lý do</div>
                        <v-select
                            showDeselect
                            :model-value="reason"
                            :items="RESIGN_HISTORY_REASON"
                            @update:model-value="(e) => (reason = e)"
                        ></v-select>
                        <span class="message-error" v-if="errors?.reason">Vui lòng nhập lý do</span>
                    </div>

                    <div class="text-[#1E293B] mb-2">Chi tiết lý do từ chối</div>
                    <div class="mb-6">
                        <v-textarea
                            placeholder="Nhập nội dung..."
                            :model-value="content"
                            @update:model-value="(e) => (content = e)"
                        />
                        <span class="message-error" v-if="errors?.content">Vui lòng nhập nội dung</span>
                    </div>
                </div>
                <div class="list-btn flex gap-3 w-full">
                    <c-access :roles="[USER_ROLE.SS, USER_ROLE.HR]" :deniedAccessAdmin="true">
                        <v-button
                            v-if="dispalyBtnCancelled"
                            class="w-1/2 ml-auto btn-fail"
                            @click="
                                () =>
                                    handleConfirm(
                                        CONFIRM_MODAL_TEXT.RESIGN.CANCEL,
                                        async () => await submit(RESIGN_STATUS.cancelled.value)
                                    )
                            "
                        >
                            <div class="">Huỷ đơn</div>
                        </v-button>
                    </c-access>
                    <c-access :roles="[USER_ROLE.ASM]">
                        <v-button
                            v-if="dispalyBtnRejected"
                            class="w-1/2 ml-auto btn-fail"
                            @click="
                                () =>
                                    handleConfirm(
                                        CONFIRM_MODAL_TEXT.RESIGN.REJECT,
                                        async () => await submit(RESIGN_STATUS.rejected.value)
                                    )
                            "
                        >
                            <div class="">Từ chối</div>
                        </v-button>
                    </c-access>
                    <c-access
                        v-if="dispalyBtnApproved"
                        class="w-1/2 ml-auto"
                        :roles="[USER_ROLE.ASM]"
                        :deniedAccessAdmin="true"
                    >
                        <v-button
                            class="w-full btn-pass"
                            @click="
                                () =>
                                    handleConfirm(
                                        CONFIRM_MODAL_TEXT.RESIGN.OK,
                                        async () => await submit(RESIGN_STATUS.approved.value)
                                    )
                            "
                        >
                            <div class="">Chấp nhận</div>
                        </v-button>
                    </c-access>
                </div>
                <div class="my-3 text-[#D97706] text-right">{{ messageStatus }}</div>
            </div>
        </div>
    </sidebar-detail>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useResignQuery } from '../../../../../shared-fe/services/resign-service'
import { useResignHistoryQuery } from '../../../../../shared-fe/services/resign-history-service'
import {
    RESIGN_STATUS,
    USER_ROLE,
    RESIGN_HISTORY_REASON,
    CONFIRM_MODAL_TEXT,
} from '../../../../../shared-fe/utils/constant'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useGroupable } from '@directus/composables'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import { useUserStore } from '@/stores/user'

const emit = defineEmits(['handleSubmit'])
const props = defineProps({
    idResign: {
        type: Number,
        default: null,
    },
    status: {
        type: String,
        default: null,
    },
})
const content = ref('')
const reason = ref('')
const errors = ref(<any>{})
const messageStatus = ref('')
const { active, toggle } = useGroupable({
    value: 'Thao tác nhanh',
    group: 'sidebar-detail',
    active: ref(true),
})
const enableQuickActions = computed(() => {
    return props?.status !== RESIGN_STATUS.rejected.value && props.status !== RESIGN_STATUS.cancelled.value
})
const dispalyBtnRejected = ref(true)
const dispalyBtnCancelled = ref(true)
const dispalyBtnApproved = ref(true)
const displayStatus = computed(() => {
    dispalyBtnRejected.value = true
    dispalyBtnApproved.value = true
    dispalyBtnCancelled.value = true
    if (props.status === RESIGN_STATUS.rejected.value || props.status === RESIGN_STATUS.cancelled.value)
        dispalyBtnRejected.value = false
    if (props.status === RESIGN_STATUS.cancelled.value) dispalyBtnCancelled.value = false
    if (props.status === RESIGN_STATUS.pending.value) dispalyBtnApproved.value = true
    return props.status ? RESIGN_STATUS[props.status].text : ''
})

const loading = ref(false)
const { patchItemResign } = useResignQuery()
const { postItemResignHistory } = useResignHistoryQuery()
const userStore = useUserStore()
const confirmModal = useConfirmModal()
const isASM = computed(() => {
    return userStore.currentUser.role.short_name === USER_ROLE.ASM
})

const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
}

const submit = async (status) => {
    errors.value = null
    if (status === RESIGN_STATUS.rejected.value || status === RESIGN_STATUS.cancelled.value) {
        if (isASM) {
            if (!content.value) {
                errors.value = {
                    content: 'content is required',
                }
            }
        } else {
            if (!reason.value) {
                errors.value = {
                    ...errors.value,
                    reason: 'reason is required',
                }
            }
            if (!content.value) {
                errors.value = {
                    ...errors.value,
                    content: 'content is required',
                }
            }
        }
    }
    if (errors.value !== null) {
        return
    }
    loading.value = true
    await postItemResignHistory({
        note: content.value,
        resign: props.idResign,
        reason: RESIGN_HISTORY_REASON.find((x) => x.value === reason.value)?.text,
        status: status,
    })
    await patchItemResign(props.idResign, {
        status: status,
    })
    setTimeout(() => {
        loading.value = false
        messageStatus.value = 'Cập nhật thành công !!'
        content.value = ''
        reason.value = ''
        errors.value = null
        setTimeout(() => {
            emit('handleSubmit')
        }, 2000)
    }, 2000)
}
</script>
<style lang="scss">
.resign-sidebar {
    .list-btn {
        .btn-fail {
            .button {
                background-color: #fee2e2;
                color: #ef4444;
                border: 1px solid #f87171;
                width: 100%;
                min-width: auto;
            }
        }
        .btn-pass {
            .button {
                width: 100%;
                background-color: #10b981;
                color: white;
                min-width: auto;
            }
        }
    }
}
</style>
