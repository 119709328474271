<template>
    <v-overlay v-if="items" :active="overlay">
        <div class="py-8 px-10 bg-white rounded-md max-w-[500px] max-h-[90vh] overflow-y-scroll">
            <div class="font-bold mb-6 text-lg">Đề xuất xin duyệt miễn trừ Onboarding đến Sales Training Executive</div>

            <div class="mb-6">
                <div class="label-input text-xs">
                    Họ và tên nhân viên miễn trừ
                    <span class="required">*</span>
                </div>
                <v-input type="text" disabled :model-value="items?.full_name"></v-input>
            </div>

            <div class="mb-6 flex gap-x-6">
                <div class="w-full">
                    <div class="label-input text-xs">
                        Thời gian nghỉ việc gđ trước
                        <span class="required">*</span>
                    </div>
                    <v-input
                        type="text"
                        :model-value="items?.resign_before"
                        @update:model-value="(e) => handleChange(e, 'resign_before')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.resign_before">
                        Vui lòng nhập thởi gian nghỉ việc gđ trước
                    </span>
                </div>

                <div class="w-full">
                    <div class="label-input text-xs">
                        Tổng thâm niên
                        <span class="required">*</span>
                    </div>
                    <v-input
                        type="text"
                        :model-value="items?.total_seniority"
                        @update:model-value="(e) => handleChange(e, 'total_seniority')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.total_seniority">Vui lòng nhập tổng thâm niên</span>
                </div>
            </div>

            <div class="mb-6 flex gap-x-6 w-full">
                <div class="w-full">
                    <div class="label-input text-xs">
                        Mã route gđ làm việc cũ
                        <span class="required">*</span>
                    </div>
                    <v-input
                        type="text"
                        :model-value="items?.route_old"
                        @update:model-value="(e) => handleChange(e, 'route_old')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.route_old">Vui lòng nhập mã route gđ làm việc cũ</span>
                </div>

                <div class="w-full">
                    <div class="label-input text-xs">
                        Mã route hiện tại
                        <span class="required">*</span>
                    </div>
                    <v-input type="text" disabled :model-value="items?.route_code"></v-input>
                </div>
            </div>

            <div class="mb-6">
                <div class="label-input text-xs">
                    Lý do xin miễn trừ
                    <span class="required">*</span>
                </div>
                <v-textarea
                    :placeholder="`Lý do xin miễn trừ của nhân viên ${items?.full_name}`"
                    :model-value="items?.reason"
                    @update:model-value="(e) => handleChange(e, 'reason')"
                ></v-textarea>
                <span class="message-error" v-if="errors?.reason">Vui lòng nhập lý do xin miễn trừ</span>
            </div>

            <div class="text-[#3B82F6] italic mb-6">
                *Hãy điền kết quả KPI 3 tháng gần nhất của {{ labelUserType }} giai đoạn trước khi nghỉ việc
            </div>

            <div class="mb-6 flex gap-x-6 w-full">
                <div class="w-full">
                    <div class="label-input text-xs">
                        Kết quả KPI tháng thứ 1
                        <span class="required">*</span>
                    </div>
                    <div class="w-full flex gap-x-6 mb-4">
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                :placeholder="canWSR ? '% Combo' : '% đạt Hero SKU'"
                                :model-value="items?.hero_sku_one"
                                @update:model-value="(e) => handleChange(e, 'hero_sku_one')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.hero_sku_one">Vui lòng nhập đủ thông tin</span>
                        </div>
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt EBD"
                                :model-value="items?.ebd_one"
                                @update:model-value="(e) => handleChange(e, 'ebd_one')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.ebd_one">Vui lòng nhập đủ thông tin</span>
                        </div>
                    </div>
                    <div class="w-full gap-x-6 flex mb-4">
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt ASO Coverage"
                                :model-value="items?.aso_one"
                                @update:model-value="(e) => handleChange(e, 'aso_one')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.aso_one">Vui lòng nhập đủ thông tin</span>
                        </div>
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt Trưng bày"
                                :model-value="items?.display_one"
                                @update:model-value="(e) => handleChange(e, 'display_one')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.display_one">Vui lòng nhập đủ thông tin</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-6 flex gap-x-6 w-full">
                <div class="w-full">
                    <div class="label-input text-xs">
                        Kết quả KPI tháng thứ 2
                        <span class="required">*</span>
                    </div>
                    <div class="w-full flex gap-x-6 mb-4">
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                :placeholder="canWSR ? '% Combo' : '% đạt Hero SKU'"
                                :model-value="items?.hero_sku_two"
                                @update:model-value="(e) => handleChange(e, 'hero_sku_two')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.hero_sku_two">Vui lòng nhập đủ thông tin</span>
                        </div>
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt EBD"
                                :model-value="items?.ebd_two"
                                @update:model-value="(e) => handleChange(e, 'ebd_two')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.ebd_two">Vui lòng nhập đủ thông tin</span>
                        </div>
                    </div>
                    <div class="w-full gap-x-6 flex mb-4">
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt ASO Coverage"
                                :model-value="items?.aso_two"
                                @update:model-value="(e) => handleChange(e, 'aso_two')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.aso_two">Vui lòng nhập đủ thông tin</span>
                        </div>
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt Trưng bày"
                                :model-value="items?.display_two"
                                @update:model-value="(e) => handleChange(e, 'display_two')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.display_two">Vui lòng nhập đủ thông tin</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-6 flex gap-x-6 w-full">
                <div class="w-full">
                    <div class="label-input text-xs">
                        Kết quả KPI tháng thứ 3
                        <span class="required">*</span>
                    </div>
                    <div class="w-full flex gap-x-6 mb-4">
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                :placeholder="canWSR ? '% Combo' : '% đạt Hero SKU'"
                                :model-value="items?.hero_sku_three"
                                @update:model-value="(e) => handleChange(e, 'hero_sku_three')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.hero_sku_three">Vui lòng nhập đủ thông tin</span>
                        </div>
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt EBD"
                                :model-value="items?.ebd_three"
                                @update:model-value="(e) => handleChange(e, 'ebd_three')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.ebd_three">Vui lòng nhập đủ thông tin</span>
                        </div>
                    </div>
                    <div class="w-full gap-x-6 flex mb-4">
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt ASO Coverage"
                                :model-value="items?.aso_three"
                                @update:model-value="(e) => handleChange(e, 'aso_three')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.aso_three">Vui lòng nhập đủ thông tin</span>
                        </div>
                        <div class="w-full">
                            <v-input
                                class="w-full"
                                type="text"
                                placeholder="% đạt Trưng bày"
                                :model-value="items?.display_three"
                                @update:model-value="(e) => handleChange(e, 'display_three')"
                            ></v-input>
                            <span class="message-error" v-if="errors?.display_three">Vui lòng nhập đủ thông tin</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-x-6 w-full">
                <v-button class="btn-modal w-full" secondary @click="emit('close')">Hủy bỏ</v-button>
                <v-button class="btn-modal w-full" @click="handleSubmit">Xác nhận gửi</v-button>
            </div>
        </div>
    </v-overlay>
</template>

<script setup lang="ts">
import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'
import Joi from 'joi'
import { ref, computed } from 'vue'
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})


const props = defineProps({
    items: {
        type: Object,
        default: {},
    },
    overlay: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['change', 'submit', 'close'])
const { canWSR } = useAppPermissions()

const schema = Joi.object({
    reason: Joi.string().required(),
    resign_before: Joi.string().required(),
    total_seniority: Joi.string().required(),
    route_old: Joi.string().required(),
    hero_sku_one: Joi.string().required(),
    ebd_one: Joi.string().required(),
    aso_one: Joi.string().required(),
    display_one: Joi.string().required(),
    hero_sku_two: Joi.string().required(),
    ebd_two: Joi.string().required(),
    aso_two: Joi.string().required(),
    display_two: Joi.string().required(),
    hero_sku_three: Joi.string().required(),
    ebd_three: Joi.string().required(),
    aso_three: Joi.string().required(),
    display_three: Joi.string().required(),
}).unknown(false)
const errors: any = ref(null)

const handleChange = (e, variable) => {
    emit('change', e, variable)
}

const handleSubmit = async () => {
    errors.value = null
    const { full_name, route_code, id, ...res } = props.items
    const { error } = schema.validate(res, { abortEarly: false })
    if (error) {
        errors.value = error.details.reduce((acc, curr) => {
            acc[curr.path[0]] = curr.message
            return acc
        }, {})
    }
    errors.value = {
        ...errors.value,
    }
    if (Object.keys(errors.value).length !== 0) {
        return
    }
    errors.value = {}
    let params = JSON.parse(JSON.stringify(props.items))
    delete params.full_name
    delete params.route_code
    emit('submit', params)
}
</script>

<style lang="scss">
.send-icon {
    .v-icon {
        color: #399e9f !important;
    }
    i {
        color: #399e9f !important;
        font-size: 55px !important;
    }
}
.label-input {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 6px;
}
.btn-modal {
    button {
        width: 100% !important;
    }
}
</style>
