import { useApi, useCollection } from '@directus/composables'
import { Ref, computed, ref } from 'vue'

export const useGeneralSetting = (name: string) => {
    const { fields } = useCollection('directus_settings')
    const api = useApi()
    const formData: Ref<any> = ref({})
    const originValue: Ref<any> = ref({})
    const loading: Ref<boolean> = ref(false)
    const isReady = ref(false)

    const fieldsRender = computed(() => {
        let data = [...fields.value]
        let array = []
        data.map((field) => {
            if (field.meta?.group === name) {
                array.push(field)
                let setting = data.filter((element) => element.meta?.group === field.field)
                if (setting.length > 0) {
                    array = [...array, ...setting]
                }
            }
        })

        return array.map((field) => {
            if (field.meta?.group === name) {
                let fieldForm = {
                    ...field,
                    meta: {
                        ...field.meta,
                        width: 'col-span-2',
                        group: null,
                    },
                }
                return fieldForm
            }
            return field
        })
    })

    const refreshData = async () => {
        isReady.value = false

        api.get('settings', {
            params: {
                fields: ['*'],
            },
        }).then(async (res) => {
            isReady.value = true
            let model = res.data?.data
            formData.value = {}

            Object.keys(model).map((key) => {
                if (fieldsRender.value.find((field) => field.field === key)) {
                    formData.value[key] = model[key]
                    originValue.value[key] = model[key]
                }
            })
        })
    }

    refreshData().catch()

    const save = async () => {
        loading.value = true
        let hasChanged = false
        let params = {}
        for (let key of Object.keys(formData.value)) {
            if (originValue.value[key] != formData.value[key]) {
                hasChanged = true
                params = { ...params, [key]: formData.value[key] }
            }
        }

        if (hasChanged) {
            api.patch('settings', params)
            setTimeout(refreshData, 500)
        }
        loading.value = false
    }

    return {
        isReady,
        formData,
        fieldsRender,
        loading,
        save,
    }
}
