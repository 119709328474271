<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            showResize
            :model-value="modelSelected"
            @item-selected="(e) => emit('update:modelSelected', e?.item)"
            manualSortKey="tel"
            :loading="loading"
            :sort="internalSort"
            showSelect="one"
            @update:sort="updateSort($event)"
            @click:row="detailRowOnClick"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->
            <template #[`item.id`]="{ item }">
                <span style="color: #62b1eb">{{ item.id }}</span>
            </template>
            F
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Sort } from '../../../../../shared-fe/components/c-table/type'

const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}

const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const internalSort: Ref<Sort> = ref({ by: null, desc: false })

const emit = defineEmits(['update:modelSelected', 'update:toPage', 'updateSort'])

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'Mã YCTD',
        value: 'id',
        width: 150,
    },
    {
        text: 'Chi nhánh',
        value: 'zone',
    },
    {
        text: 'Nhà phân phối',
        value: 'provider_name',
    },
    {
        text: 'Người yêu cầu',
        value: 'recruitment_request_person',
    },
    {
        text: 'Mã Route',
        value: 'staff_route_code',
    },
])

const convertKeySort = (sortKey, desc) => {
    let key = ''
    switch (sortKey) {
        case 'recruitment_request_person':
            key = 'user_created.full_name'
            break
        case 'zone':
            key = 'providers.zone.name'
            break
        case 'provider_name':
            key = 'providers.name'
            break
        case 'staff_route_code':
            key = 'staff_id.route_code.name'
            break
        default:
            key = sortKey
            break
    }
    if (desc) key = `-${key}`
    return key
}

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    emit('updateSort', convertKeySort(sort?.by, sort?.desc))
}

const detailRowOnClick = (data: any) => {
    emit('update:modelSelected', data.item)
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
