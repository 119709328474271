import { useApi } from '@directus/extensions-sdk'

export const useInterviewDetailQuery = () => {
    const api = useApi()
    const postItemInterviewDetail = async (params: any) => {
        return await api.post(`/api-hr/sidebar/form`, params)
    }

    const sendService = async (params: any) => {
        return await api.post(`/api-hr/sidebar/notification`, params)
    }

    return {
        postItemInterviewDetail,
        sendService,
    }
}
