<template>
    <div class="px-10 py-8">
        <v-breadcrumb :items="breadcrumbCustom" />
        <div class="text-[#1E4BA1] font-bold text-lg mt-8 mb-2">{{ user?.full_name }}</div>
        <div class="mb-6">
            <strong>Mã route :</strong>
            <span>&nbsp;{{ user?.route_code?.name }} ;</span>
            <strong>&nbsp;Mã {{ labelUserType }}:</strong>
            <span>&nbsp;{{ user?.code }}</span>
        </div>
        <div>
            <v-table class="c-table" :headers="headers" :items="itemsRender" show-resize :loading="loading">
                <template #[`item.date_created`]="{ item }">
                    {{ item.date_created ? dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYYhhmmssA) : '' }}
                </template>
                <template #[`item.is_pass`]="{ item }">
                    <result-status :is-pass="item?.is_pass"></result-status>
                </template>
                <template #[`item.dismiss`]="{ item }">
                    <div class="btn-result font-bold text-[#62B1EB] cursor-pointer" @click="handleSeeResult(item)">
                        Chi tiết đáp án
                        <v-icon class="ml-2" name="arrow_forward" />
                    </div>
                </template>
            </v-table>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ref, toRefs, watch, onMounted, Ref } from 'vue'
import dayjs from 'dayjs'
import { useItems } from '@directus/composables'
import { EXAM_RESULTS_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { FORMAT_TIME, EXAM_STATUS } from '../../../../../shared-fe/utils/constant'
import ResultStatus from './result-status.vue'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})

const props = defineProps({
    sessionsTrainId: {
        type: String,
        default: 0,
    },
    topicId: {
        type: Number,
        default: 0,
    },
    userId: {
        type: String,
        default: '',
    },
})

const emit = defineEmits(['seeResult'])
const user: Ref<any> = ref(null)
const { getUserByUserId } = useUserQuery()

const { items, loading } = useItems(ref(EXAM_RESULTS_COLLECTION), {
    fields: ref([
        '*',
        'exam_room.topic.title',
        'exam_room.topic.id',
        'quiz.id',
        'quiz.questions.question',
        'exam_room.topic.random_number'
    ]),
    limit: ref(-1),
    sort: ref(null),
    search: ref(null),
    filter: ref({
        _and: [
            {
                user: {
                    id: {
                        _eq: props.userId,
                    },
                },
                exam_room: {
                    _and: [
                        {
                            topic: {
                                id: props.topicId,
                            },
                        },
                        {
                            session_train: {
                                id: props.sessionsTrainId,
                            },
                        },
                    ],
                },
            },
        ],
    }),
    page: ref(1),
})

onMounted(async () => {
    const res = await getUserByUserId(props.userId)

    if (res?.data?.data) {
        user.value = res?.data?.data
    }
})

const itemsRender = computed(() => {
    return items.value.map((x, index) => {
        let totalQuestions = 0
        if (x?.exam_room?.topic?.random_number) {
            if (x?.exam_room?.topic?.random_number <= x.quiz?.questions.length) {
                totalQuestions = Number(x?.exam_room?.topic?.random_number)
            } else {
                totalQuestions = x.quiz?.questions.length
            }
        } else {
            totalQuestions = x.quiz?.questions.length
        }
        return {
            ...x,
            index: `Đợt ${index + 1}`,
            is_pass: x.status === EXAM_STATUS.PASS ? true : false,
            total_incorrect_score: totalQuestions - x?.total_score,
        }
    })
})

const breadcrumbCustom = computed(() => {
    let items = [
        { icon: 'local_library', to: '/ld' },
        { name: 'Thứ 7 Học Hay', to: '/ld/saturday-learning' },
        { name: 'Kết quả các đợt thi', disabled: true },
    ]

    return items
})

const headers = ref([
    {
        text: 'Thời gian',
        value: 'date_created',
        width: 300,
    },
    {
        text: 'Đợt thi',
        value: 'index',
    },
    {
        text: 'Kết quả',
        value: 'is_pass',
    },
    {
        text: 'Câu đúng',
        value: 'total_score',
    },
    {
        text: 'Câu sai',
        value: 'total_incorrect_score',
    },
    {
        text: 'Thao tác',
        value: 'dismiss',
        width: 300,
    },
])

const handleSeeResult = (item: any) => {
    emit('seeResult', item)
}
</script>
<style lang="scss"></style>
