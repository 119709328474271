<template>
    <c-role>
        <private-view title="">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->
            <template #title>
                <h1 class="type-title" v-html="titleHead"></h1>
            </template>
            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.CANDIDATE_LIST.VALUE,
                            to: PATH_ROUTER.CANDIDATE_LIST.PATH,
                        },
                    ]"
                />
            </template>
            <template #actions>
                <div class="" v-if="displayRouteRide && activeTabFormDetail === 2">
                    <v-button icon rounded secondary @click="handleFullScreen">
                        <v-icon name="fullscreen"></v-icon>
                    </v-button>
                </div>
                <div class="" v-if="enableToTestRR">
                    <v-button icon rounded secondary @click="startEvent()">
                        <v-icon name="manage_history"></v-icon>
                    </v-button>
                </div>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-yctd" v-if="displayBtnYCTD">
                        <v-button
                            v-tooltip.bottom="LINKED_RECRUITMENT_REQUEST"
                            @click="
                                () => {
                                    activeRecruitmentRequestDialog = true
                                }
                            "
                        >
                            <div class="i-mdi-arrow-top-right-thin-circle-outline" />
                            <span class="ml-1">{{ LINKED_RECRUITMENT_REQUEST }}</span>
                        </v-button>
                    </div>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-yctd" v-if="displayBtnCancelYCTD">
                        <v-button
                            class="danger-action"
                            v-tooltip.bottom="CANCEL_LINKED_RECRUITMENT_REQUEST"
                            @click="updateStatusCandidate"
                        >
                            <div class="i-mdi-close-outline" />
                            <span class="ml-1">{{ CANCEL_LINKED_RECRUITMENT_REQUEST }}</span>
                        </v-button>
                    </div>
                </c-access>
                <!-- <c-access :roles="[USER_ROLE.HR]">
                    <div
                        class="box-hsuv"
                        v-if="
                            objCandidateDetail && activeTabFormDetail === 0 && objCandidateDetail?.is_archived !== true
                        "
                    >
                        <v-button v-tooltip.bottom="SAVE_FILE" @click="saveFile">
                            <div class="i-mdi-archive-arrow-down-outline" />
                            <span class="ml-1">{{ SAVE_FILE }}</span>
                        </v-button>
                    </div>
                </c-access> -->
                <c-access :roles="[]">
                    <v-dialog v-if="objCandidateDetail" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <v-icon name="delete" outline />
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-save">
                        <v-button
                            v-if="objCandidateDetail && activeTabFormDetail === 0"
                            v-tooltip.bottom="SAVE"
                            icon
                            rounded
                            @click="save"
                        >
                            <v-icon name="check" />
                        </v-button>
                    </div>
                </c-access>
            </template>
            <!-- header end -->

            <!-- content start -->
            <div class="flex justify-center mb-8 px-9">
                <div class="list-btn-tab-detail flex">
                    <v-button
                        class="first"
                        :class="activeTabFormDetail === 0 ? 'active' : ''"
                        secondary
                        @click="openTab(0)"
                    >
                        <div class="i-mdi-account mr-2"></div>
                        Thông tin cá nhân
                    </v-button>
                    <v-button
                        class="last"
                        secondary
                        :class="activeTabFormDetail === 1 ? 'active' : ''"
                        @click="openTab(1)"
                    >
                        <div class="i-mdi-account-multiple mr-2"></div>
                        Lịch sử phỏng vấn
                    </v-button>

                    <v-button
                        v-if="displayRouteRide"
                        class="last"
                        secondary
                        :class="activeTabFormDetail === 2 ? 'active' : ''"
                        @click="openTab(2)"
                    >
                        <div class="i-mdi-account-multiple mr-2"></div>
                        Đào tạo Route Ride
                    </v-button>
                </div>
            </div>

            <div class="px-9">
                <div class="mb-2" v-if="objCandidateDetail?.request_id?.[0]">
                    <span class="text-gray-400">Yêu cầu tuyển dụng</span>
                    : #{{ objCandidateDetail?.request_id[0] }}
                </div>

                <form-candidate-detail
                    v-if="activeTabFormDetail === 0 && objCandidateDetail"
                    :item="objCandidateDetail"
                />
                <interview-detail
                    v-if="activeTabFormDetail === 1 && objCandidateDetail"
                    :idCandidate="objCandidateDetail?.id"
                />
                <route-ride
                    v-if="activeTabFormDetail === 2 && displayRouteRide"
                    ref="route_ride"
                    :request_recruitment_id="objCandidateDetail?.request_id"
                    :interview="interviewItem"
                    @change="getDetail"
                ></route-ride>
            </div>

            <!--  content end -->

            <recruitment-request-modal
                :selection="objCandidateDetail?.request_id"
                :overlay="activeRecruitmentRequestDialog"
                @input="(e) => updateRequestId(e)"
                @close="activeRecruitmentRequestDialog = false"
            />

            <!-- <div> -->
            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin ứng viên" close>Thông tin ứng viên</sidebar-detail>
                <div v-if="interviewItem">
                    <interview-steps
                        @handleSubmit="getDetail"
                        @goToRouteRide="
                            () => {
                                activeTabFormDetail = 2
                            }
                        "
                        :interviewItem="interviewItem"
                        :candidate-status="objCandidateDetail?.status"
                    ></interview-steps>
                </div>
                <c-access :roles="[USER_ROLE.HR]">
                    <sidebar-detail icon="back_Hand" class="" title="Gửi mail user pool">
                        <div class="flex justify-center">
                            <v-button :loading="loadingSendMail" @click="submitMail" class="btn btn-primary">
                                Gửi Email
                            </v-button>
                        </div>
                        <p class="mt-3 text-[#D97706]">{{ messageSendMail }}</p>
                    </sidebar-detail>
                </c-access>
            </template>
            <!-- </div> -->
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { useCandidateQuery } from '../../../../../shared-fe/services/candidate-service'
import { useInterviewRoundQuery } from '../../../../../shared-fe/services/interview-round-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import {
    CANDIDATE_STATUS,
    MODE_EDIT,
    SUBMIT_EVENT,
    USER_ROLE,
    ENABLE_TO_TEST,
} from '../../../../../shared-fe/utils/constant'
import {
    CANCEL,
    CANCEL_LINKED_RECRUITMENT_REQUEST,
    CONTENT_DELETE,
    LABEL_DELETE,
    LINKED_RECRUITMENT_REQUEST,
    SAVE,
    SAVE_FILE,
} from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { CANDIDATE_COLLECTION } from '../../../../../shared-fe/utils/table-name'

import { useStores } from '@directus/extensions-sdk'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useOptionQuery } from '../../../../../shared-fe/services/option-service'
import FormCandidateDetail from '../components/form-candidate-detail.vue'
import InterviewDetail from '../components/interview-detail.vue'
import InterviewSteps from '../components/interview-steps.vue'
import RecruitmentRequestModal from '../components/recruitment-request-modal.vue'
import RouteRide from '../components/route-ride.vue'
import { useUserStore } from '@/stores/user'
import { isFullScreen, toggleFullScreen } from '@/shared-fe/utils/convert-data'

const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = true
const activeRecruitmentRequestDialog = ref(false)
const commonStore = useStoreCommon()
const userStore: any = useUserStore()
const route = useRoute()
const objCandidateDetail = ref(null)
const activeTabFormDetail = ref(0)
const interviewItem = ref(null)
const route_ride = ref(<any>null)
const { getItemCandidate, patchItemCandidate, postSendMailUserPool } = useCandidateQuery()
const { getInterviewRound, patchItemInterviewRound } = useInterviewRoundQuery()
onBeforeUnmount(() => {})

const titleHead = computed(() => {
    return `Thông tin ứng viên <span style='color: #62b1eb'>(${route?.params?.id})</span>`
})

const arrStatus = ref()

// get data detail (start)
onMounted(async () => {
    if (route?.params?.id) {
        await getDetail()
    }
    getOptionsData()
})

const getOptionsData = () => {
    const options = userStore.currentUser.options
    let _arrStatus: any = []
    if (options.length > 0) {
        options.forEach((x) => {
            if (x.opt_group === 'candidate_status') {
                _arrStatus.push({
                    opt_key: x.opt_key,
                    id: x.id,
                })
            }
        })
    }
    arrStatus.value = _arrStatus
}

const handleFullScreen = () => {
    toggleFullScreen()
    route_ride.value?.handleForcePlayVideoIfSafariBrowser()
}

const openTab = (tab: number) => {
    if (isFullScreen()) {
        toggleFullScreen()
    }
    activeTabFormDetail.value = tab
}

const getDetail = async () => {
    let res = await getItemCandidate(route?.params?.id.toString())
    objCandidateDetail.value = {
        ...res?.data?.data,
        code: res?.data?.data?.refer?.code,
        refer_name: res?.data?.data?.refer?.full_name,
        request_id: res?.data?.data?.request_id ? [res?.data?.data?.request_id] : [],
    }
    getInterview(res?.data?.data?.id).catch()
}

// get data detail (end)

// logic interview round (start)
const displayRouteRide = computed(() => {
    if (!interviewItem.value) return false
    if (interviewItem.value?.pv2 === true && interviewItem.value?.date_route_ride) {
        return true
    }

    return false
})
const getInterview = async (id: any) => {
    let res = await getInterviewRound(id)
    if (res?.data.data) {
        interviewItem.value = res?.data.data[0]
    }
}
// logic interview round (end)

// handle logic header (start)
const router = useRouter()
const save = () => {
    commonStore.submitEvent({
        name: SUBMIT_EVENT.CANDIDATE_DETAIL_SUBMIT,
        payload: MODE_EDIT,
    })
}

const displayBtnYCTD = computed(() => {
    if (objCandidateDetail.value?.status?.opt_key === CANDIDATE_STATUS.hired.value) return false
    if (objCandidateDetail.value && objCandidateDetail.value?.request_id?.length <= 0) {
        return true
    }
    return false
})

const displayBtnCancelYCTD = computed(() => {
    if (objCandidateDetail.value?.status?.opt_key === CANDIDATE_STATUS.hired.value) return false
    if (
        objCandidateDetail.value &&
        objCandidateDetail.value?.request_id?.length > 0 &&
        objCandidateDetail.value?.status?.opt_key !== CANDIDATE_STATUS.hired.value
    ) {
        return true
    }
    return false
})

const enableToTestRR = computed(() => {
    return (
        ENABLE_TO_TEST.includes(userStore?.currentUser?.source) &&
        activeTabFormDetail.value === 2 &&
        displayRouteRide.value &&
        interviewItem?.value?.ride_step !== 'completed'
    )
})

const navigateBack = () => {
    if (route.query?.introduct_candidate) {
        router.push({
            name: PATH_ROUTER.INTRODUCT_CANDIDATE_LIST.NAME,
        })
        return
    }

    router.push({
        name: PATH_ROUTER.CANDIDATE_LIST.NAME,
    })
}

const batchDeleteFun = async () => {
    await batchDelete('status_display', objCandidateDetail.value.id)
    router.push({
        name: PATH_ROUTER.CANDIDATE_LIST.NAME,
    })
}

const startEvent = () => {
    document.dispatchEvent(new Event('reduce-timer'))
}

const { confirmDelete, deleting, batchDelete, error: deleteError, modelSelected } = useBatch(CANDIDATE_COLLECTION)

const updateRequestId = async (e) => {
    if (e?.length > 0) {
        await patchItemCandidate(objCandidateDetail.value?.id, {
            request_id: e[0]?.id,
        })
    }
    getDetail()
}
// const saveFile = async () => {
//     await patchItemCandidate(objCandidateDetail.value?.id, {
//         is_archived: true,
//     })
//     navigateBack()
// }
// handle logic header (end)

const updateStatusCandidate = async () => {
    if (isFullScreen()) {
        toggleFullScreen()
    }
    if (interviewItem.value) {
        await patchItemInterviewRound(interviewItem.value?.id, {
            status: 'cancelled',
        })
    }
    await patchItemCandidate(objCandidateDetail.value.id, {
        request_id: null,
        status: arrStatus.value?.find((x: any) => x.opt_key === CANDIDATE_STATUS.applied.value)?.id,
    })
    router.push({
        name: PATH_ROUTER.CANDIDATE_LIST.NAME,
    })
}

onBeforeUnmount(() => {
    if (isFullScreen()) {
        toggleFullScreen()
    }
})

const loadingSendMail = ref(false)
const messageSendMail = ref('')
const submitMail = async () => {
    loadingSendMail.value = true
    await postSendMailUserPool(route?.params?.id, 'candidate.sendmail.pool')
    setTimeout(() => {
        loadingSendMail.value = false
        messageSendMail.value = 'Gửi mail thành công!!'
        setTimeout(() => {
            messageSendMail.value = ''
        }, 2000)
    }, 1000)
}
</script>
