<template>
    <div class="w-full custom-h" :id="'player'"></div>
    <!-- <iframe class="w-full custom-h" id="player" ref="iframe" :src="url" frameborder="0"></iframe> -->
</template>

<script lang="ts">
export default {
    name: 'cYoutube',
}
</script>

<script lang="ts" setup>
import { computed, onMounted, ref, toRefs, watch } from 'vue'
const props = defineProps({
    url: {
        type: String,
        default: '',
    },
    start: {
        type: Number,
        default: 0,
    },
})
const { url, start } = toRefs(props)
const iframe = ref(null)
const videoState = ref(null)

const rundCommand = (func: string) => {
    switch (func) {
        case 'playVideo':
            player.value?.playVideo()
            break
        case 'pauseVideo':
            console.log("rundCommand")
            if (videoState.value === YT.PlayerState.PLAYING) {
                player.value?.pauseVideo()
            }
            break
        case 'stopVideo':
            player.value?.stopVideo()
            break
    }
}

const getCurrentTime = () => {
    if (player.value) {
        return player.value?.getCurrentTime()
    }
    return 0
}

const emit = defineEmits(['ended'])
const player = ref(null)

onMounted(() => {
    player.value = new YT.Player('player', {
        videoId: getIdYoutubeVideo(url.value),
        playerVars: {
            frameborder: 0,
            rel: 0,
            controls: 0,
            disablekb: 1,
            loop: 0,
            hl: 'vi',
            playlist: getIdYoutubeVideo(url.value),
        },
        events: {
            onReady: (event: any) => {
                event.target.seekTo(start.value, true)
                event.target.playVideo()
            },
            onStateChange: (playerStatus: any) => {
                videoState.value = playerStatus.data
                if (playerStatus.data == YT.PlayerState.ENDED) {
                    emit('ended')
                    player.value?.playVideo()
                }
            },
        },
    })
})

const getIdYoutubeVideo = (link: string) => {
    let url = link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

defineExpose({ rundCommand, getCurrentTime, videoState })
</script>
