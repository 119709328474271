import { useApi } from '@directus/composables'
import { CANDIDATE_COLLECTION } from '../utils/table-name'

export const useCandidate = () => {
    const api = useApi()

    const checkCandidateExists = async (
        phone_number: string,
        phone_number_zalo: string,
        email: string
    ): Promise<any> => {
        return await api.post(`/api-hr/candidate/isDuplicate`, {
            phone_number,
            phone_number_zalo,
            email
        })
    }

    const checkCandidateExistsHR = async (
        phone_number: string,
        phone_number_zalo: string,
        email: string
    ): Promise<string | null> => {
        const candidate = await api.get(`/items/${CANDIDATE_COLLECTION}`, {
            params: {
                fields: ['id'],
                filter: {
                    _and: [
                        {
                            _or: [
                                {
                                    phone_number,
                                },
                                {
                                    phone_number_zalo,
                                },
                                {
                                    email,
                                },
                            ],
                        },
                        {
                            date_created: {
                                _gt: '$NOW(-30 days)',
                            },
                        },
                    ],
                },
            },
        })
        if (candidate.data.data && candidate.data.data.length > 0) {
            return candidate?.data?.data[0]?.id
        }
        return null
    }

    const getDuplicateCandidate = async (
        phone_number: string,
        phone_number_zalo: string,
        email: string,
        limit: number,
        currentPage: number
    ) => {
        let customFilter: any[] = [
            {
                phone_number,
            },
        ]
        if (email) {
            customFilter = [
                ...customFilter,
                {
                    email,
                },
            ]
        }
        if (phone_number_zalo) {
            customFilter = [
                ...customFilter,
                {
                    phone_number_zalo,
                },
            ]
        }
        return await api.get(`/items/${CANDIDATE_COLLECTION}`, {
            params: {
                limit: limit,
                meta: ['filter_count'],
                page: currentPage,
                fields: ['*', 'source.*', 'status.*'],
                filter: {
                    _and: [
                        {
                            _or: [
                                ...customFilter
                            ],
                        }
                    ],
                },
            },
        })
    }

    return { checkCandidateExists, checkCandidateExistsHR, getDuplicateCandidate }
}
