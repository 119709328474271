import { defineStore } from 'pinia'
import { reactive } from 'vue'

export const useStoreLoading = defineStore('useStoreLoading', () => {
    const getLoading = reactive({ loading: 0 })
    const postLoading = reactive({ loading: 0 })
    const progressing = reactive({ progressing: 0 })
    const uploading = reactive({ uploading: false })

    const setGetLoading = () => {
        getLoading.loading = getLoading.loading + 1
    }

    const setPostLoading = () => {
        postLoading.loading = postLoading.loading + 1
    }

    const hideGetLoading = () => {
        getLoading.loading = getLoading.loading - 1
    }

    const hidePostLoading = () => {
        postLoading.loading = postLoading.loading - 1
    }

    const stopLoading = () => {
        getLoading.loading = 0
        postLoading.loading = 0
    }

    const handleUpload = (upload: boolean) => {
        uploading.uploading = upload
    }

    const handleProgressing = (progress: number) => {
        progressing.progressing = progress
    }

    return {
        getLoading,
        postLoading,
        progressing,
        uploading,
        setGetLoading,
        setPostLoading,
        hideGetLoading,
        hidePostLoading,
        stopLoading,
        handleUpload,
        handleProgressing
    }
})
