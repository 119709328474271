<template>
    <c-role>
        <private-view title="Báo cáo thông tin tuyển dụng">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <div class="w-full py-10">
                <report-tabs></report-tabs>
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" title="Báo cáo thông tin tuyển dụng" close />
            </template>
        </private-view>
    </c-role>
</template>
<script setup lang="ts">
import { useStores } from '@directus/composables'
import { toRefs } from 'vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import ReportTabs from './report-tabs/report-tabs.vue'

const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>
