<template>
    <div class="c-roles">
        <slot class="c-roles" v-if="canAccess" />
        <c-access-denied v-else />

        <v-dialog
            v-model="commonStore.showDialogSucessUpdate"
            @esc="commonStore.showDialogSucessUpdate = false"
            placement="center custom-width"
        >
            <v-card>
                <div class="flex flex-col items-center justify-center px-28px">
                    <div class="mt-10 mb-6">
                        <div
                            class="p-6 bg-[#DEF4F5] w-100px h-100px rounded-full box-icon-success flex items-center justify-center"
                        >
                            <v-icon name="lock_reset"></v-icon>
                        </div>
                    </div>
                    <div class="text-14px text-[#64748B]">
                        {{ commonStore.titleDialog ? commonStore.titleDialog : 'Cập nhật thành công' }}
                    </div>
                    <v-card-actions>
                        <v-button secondary @click="commonStore.showDialogSucessUpdate = false">Đóng</v-button>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, watch } from 'vue'
import CAccessDenied from '../c-access-denied/c-access-denied.vue'
import { useStoreCommon } from '../../stores/common'
import { USER_ROLE, USER_TYPE } from '@/shared-fe/utils/constant'
import { useUserStore } from '@/stores/user'
const userStore = useUserStore()

// document.body.style.zoom = "80%";

const commonStore = useStoreCommon()
watch(
    () => commonStore.showDialogSucessUpdate,
    () => {
        if (commonStore.showDialogSucessUpdate === true) {
            setTimeout(() => {
                commonStore.showDialogSucessUpdate = false
                commonStore.titleDialog = ''
            }, 2000)
        }
    }
)
const props = defineProps({
    canAccessPage: {
        type: Boolean,
        default: true,
    },
})

const route = useRoute()
const canAccess = computed(() => {
    if (!props.canAccessPage) {
        return false
    }
    if (userStore.currentUser?.role.admin_access) {
        return true
    }

    // ASM và SS retail k dc vào module Agency
    if (route.meta?.user_type === USER_TYPE.WSR) {
        if (userStore.currentUser.user_type !== USER_TYPE.WSR) {
            if (
                userStore.currentUser?.role.short_name === USER_ROLE.ASM ||
                userStore.currentUser?.role.short_name === USER_ROLE.SS
            ) {
                return false
            }
        }
    }

    // user có type là WSR thì không được vào module HR,
    if (route.meta?.user_type === USER_TYPE.RETAIL && userStore.currentUser.user_type === USER_TYPE.WSR) {
        return false
    }

    if (route.meta?.permission && route.meta?.permission.includes(userStore.currentUser?.role.short_name)) {
        return true
    }

    return false
})
</script>
