<template>
    <label class="switch" :class="valueUndefined">
        <input type="checkbox" :disabled="disabled" v-model="modelValue" />
        <span class="slider round"></span>
    </label>
</template>
<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits(['handleChange'])
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
})

const valueUndefined = computed(() => {
   return props.modelValue === true ? 'has': props.modelValue === false ? 'none' : 'und'
});

const modelValue = computed({
    get() {
        return props.modelValue !== undefined ? props.modelValue : undefined
    },
    set(newActive: any) {
        emit('handleChange', newActive)
    },
})
</script>
<style lang="scss">
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch.und {
    opacity: 0.5;
    .slider:before {
        left: 30% !important;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 5px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--primary);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--primary);
}

input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
