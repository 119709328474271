<template>
    <c-search-bar :data="BASIC_SEARCH" @changeItem="changeItem" @update:model-value="handleSearch" />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CSearchBar from '../../../../../shared-fe/components/c-search-bar/c-search-bar.vue'
import { useField } from '../../../../../shared-fe/composables/use-field'
import { ComponentPropsModel } from '../../../../../shared-fe/types/table-type'
import { EXEMPTION_CERTIFICATE_COLLECTION, SESSION_TRAINS_COLLECTION } from '../../../../../shared-fe/utils/table-name'

const emit = defineEmits(['update:handleSearch'])
const props = defineProps({
    arrayValue: {
        type: Object,
        default: {},
    },
    keyNumber: {
        type: Number,
        default: 3,
    },
})
const { getArrayStatus } = useField()
const dataModel = ref(<any>props.arrayValue)

const BASIC_SEARCH = computed(() => {
    let fields = [
        {
            key: 'title',
            value: props.arrayValue?.title,
            label: 'Nhập mã nhân viên, mã route, tên, số điện thoại,...',
            placeholder: 'Nhập mã nhân viên, mã route, tên, số điện thoại,...',
            type: 'v-input',
            icon: 'search',
        },
        
    ];
    if(props.keyNumber === 3){
        fields.push({
            key: 'status',
            value: dataModel.value?.status,
            label: 'Tất cả trạng thái',
            placeholder: 'Tất cả trạng thái',
            type: 'v-select',
            data: getArrayStatus(SESSION_TRAINS_COLLECTION)
        })
    }
    return fields;
})

const changeItem = (data) => {
    dataModel.value[data.key] = data.value
}

const handleSearch = (data) => {
    emit('update:handleSearch', data)
}
</script>

<style lang="scss" scoped></style>
