import { useApi } from '@directus/extensions-sdk'
import { SESSION_TRAINS_COLLECTION } from '../utils/table-name'
import { useStoreLoading } from '../stores/loading'
export const useOnboardingDcr = () => {
    const api = useApi()
    const loading = useStoreLoading()

    const postItemSessionsTrains = async (params: any) => {
        loading.setPostLoading()
        try {
            let res = await api.post(`/api-learnings/${SESSION_TRAINS_COLLECTION}/create`, params)
            loading.hidePostLoading()
            return res

        } catch (error) {
            loading.hidePostLoading()
            return error?.response
        }
    }

    return {
        postItemSessionsTrains,
    }
}
