import { computed } from 'vue'
import { useUserStore } from '@/stores/user'
import { USER_ROLE, USER_TYPE } from '@/shared-fe/utils/constant'

export const useAppPermissions = () => {
    const userStore: any = useUserStore()
    const canHR = computed(() => {
        return userStore.currentUser.role.short_name == USER_ROLE.HR || userStore.isAdmin
    })
    const canSAdmin = computed(() => {
        return userStore.currentUser.source == 'admin'
    })

    const canL1AndL2 = computed(() => {
        return userStore.currentUser.role.short_name == USER_ROLE.ADMIN_L1 ||
            userStore.currentUser.role.short_name == USER_ROLE.ADMIN_L2 ||
            userStore.isAdmin
    })

    const canL1AndL2only = computed(() => {
        return userStore.currentUser.role.short_name == USER_ROLE.ADMIN_L1 ||
            userStore.currentUser.role.short_name == USER_ROLE.ADMIN_L2
    })

    const canSs = computed(() => {
        return userStore.currentUser.role.short_name == USER_ROLE.SS
    })

    const canAsm = computed(() => {
        return userStore.currentUser.role.short_name == USER_ROLE.ASM
    })

    const canContentManagement = computed(() => {
        return canHR.value || canSAdmin.value || canL1AndL2.value
    })

    const canWSR = computed(() => {
        return userStore.currentUser.user_type == USER_TYPE.WSR
    })

    return {
        canContentManagement,
        canHR,
        canSAdmin,
        canL1AndL2,
        canL1AndL2only,
        canSs,
        canAsm,
        canWSR
    }
}
