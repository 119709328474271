<template>
    <div class="table-hand-made">
        <div v-if="items && items.length > 0" class="mx-9 mb-12 table-dcr-history box-table">
            <div class="grid grid-cols-2 items-center">
                <div class="col-span-1 info-request font-bold text-[var(--foreground-normal-alt)] text-6">
                    Lịch sử nhân viên
                </div>
                <div class="col-span-1 flex justify-end items-center">
                    <div class="mr-2 text-[#64748B]">Lọc theo thứ tự</div>
                    <div class="w-40 dropdown-sort">
                        <v-select
                            :model-value="sort"
                            @update:model-value="(e) => (sort = e)"
                            :items="arrSort"
                        ></v-select>
                    </div>
                </div>
            </div>

            <div class="grid bg-[#1E4BA1] rounded-t-lg text-white grid-cols-4">
                <div class="col-span-1 border p-4">Thời gian</div>
                <div class="col-span-1 border p-4">Mô tả</div>
                <div class="col-span-1 border p-4">Người xác nhận</div>
                <div class="col-span-1 p-4">Vai trò</div>
            </div>
            <div v-for="item in items" :style="{ borderBottom: '1px solid #E2E8F0' }" class="grid bg-white grid-cols-1">
                <div class="grid grid-cols-4">
                    <div class="col-span-1 p-4">{{ dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) }}</div>
                    <div class="col-span-1 p-4" v-html="item.content"></div>
                    <div class="col-span-1 p-4">{{ item.user_created?.full_name }}</div>
                    <div class="col-span-1 p-4">{{ item.user_created?.role?.name }}</div>
                </div>
            </div>
            <div class="footer">
                <div class="c-pagi pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="7"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import { useUserActivitiesQuery } from '../../../../../shared-fe/services/user-activities-service'

const { getUserActivites } = useUserActivitiesQuery()
const props = defineProps({
    idUser: {
        type: Number,
        default: '',
    },
})
const page = ref(1)
const ITEM_PER_PAGE = 5
const totalPages = ref(1)
const items = ref(<any>[])
const sort = ref('-date_created')

const arrSort = [
    {
        text: 'Gần nhất',
        value: '-date_created',
    },
    {
        text: 'Cũ nhất',
        value: 'date_created',
    },
]
const toPage = (_page) => {
    page.value = _page
}

const getData = async () => {
    const res = await getUserActivites(ITEM_PER_PAGE, props?.idUser, page.value, sort.value)
    items.value = []
    if (res.data.data) {
        items.value = res.data.data
    }
    if (res.data.meta) {
        totalPages.value = Math.ceil(res.data.meta.filter_count / ITEM_PER_PAGE)
    }
}

watch(
    () => sort.value,
    () => {
        getData()
    },
    { immediate: true }
)

watch(
    () => page.value,
    async () => {
        await getData()
    },
    { immediate: true }
)
</script>

<style lang="scss">
.table-dcr-history {
    .dropdown-sort {
        .v-input .input {
            border: none !important;
            &.active {
                box-shadow: none;
            }
        }
    }
}
</style>
