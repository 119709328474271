import { defineInterface } from '@directus/utils';
import appUpload from './app-upload.vue';
import PreviewSVG from './preview.svg?raw';

export default defineInterface({
	id: 'app-upload',
	name: 'App Upload',
	description: '',
	icon: 'insert_photo',
	component: appUpload,
	types: ['uuid'],
	localTypes: ['file'],
	group: 'standard',
	options: ({ field }) => {},
	recommendedDisplays: ['image'],
	preview: PreviewSVG,
});
