<template>
    <c-role>
        <private-view title="Nhân viên DCR">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <!-- navigation end  -->
            <!-- header start -->
            <template #actions>
                <span class="custom-button-export-excel">
                    <v-button secondary @click="exportData" :loading="_export.loadingExcel.value">
                        <v-icon name="file_upload" outline />
                        <span class="inline-block ml-2">
                            {{ EXPORT_EXCEL }}
                        </span>
                    </v-button>
                </span>

                <c-access :roles="[]">
                    <v-dialog v-if="modelSelected?.length > 0" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <!-- <v-button v-tooltip.bottom="REFRESH" class="btn-refresh" rounded icon secondary @click="refresh">
                    <div class="i-mdi-cached"></div>
                </v-button> -->
            </template>
            <!-- header end -->
            <div class="mx-9">
                <!-- searchbar start -->
                <dcr-searchbar
                    :arrayValue="objSearchStore?.dataSeletedForm"
                    :arrayStatus="arrayStatus"
                    @update:handleSearch="handleSearch"
                />
                <!-- searchbar end -->

                <!-- content start -->
                <dcr-table
                    :items="itemsRender"
                    :modelSelected="modelSelected"
                    @update:modelSelected="modelSelectedFun"
                    :totalPages="totalPages"
                    :page="page"
                    :loading="loading"
                    @update:toPage="handleToPage"
                    @updateSort="handleUpdateSort"
                />
                <!--  content end -->
            </div>

            <template #sidebar>
                <sidebar-detail icon="info" title="Nhân viên DCR" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user'
import { useItems } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import CAccess from '../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '@/shared-fe/composables/use-batch'
import { useExport } from '@/shared-fe/composables/use-export'
import { useField } from '@/shared-fe/composables/use-field'
import { useStoreSearch } from '@/shared-fe/stores/search'
import {
    DELETE_VALUE,
    RECORD_PER_PAGE,
    USER_ROLE,
    USER_STATUS,
    USER_TYPE,
    USER_TYPE_DATA,
} from '@/shared-fe/utils/constant'
import { formatDateObjectToQuery } from '@/shared-fe/utils/convert-data'
import { CANCEL, CONTENT_DELETE, EXPORT_EXCEL, LABEL_DELETE } from '@/shared-fe/utils/message'
import { USER_COLLECTION } from '@/shared-fe/utils/table-name'
import DcrSearchbar from './components/dcr-searchbar.vue'
import DcrTable from './components/dcr-table.vue'

const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
const { getArrayStatus } = useField()
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-start_date'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)
const userStore = useUserStore()
const customFilter = computed(() => {
    let _filter: any = {
        role: {
            short_name: USER_ROLE.DCR,
        },
    }
    if (
        userStore?.currentUser?.role?.short_name == USER_ROLE.ASM ||
        userStore?.currentUser?.role?.short_name == USER_ROLE.SS
    ) {
        _filter = {
            ..._filter,
            employment_status: {
                _eq: USER_STATUS.on.value,
            },
        }
    }
    if (userStore?.currentUser?.role?.short_name == USER_ROLE.HR) {
        _filter = {
            ..._filter,
            area: {
                id: {
                    _in: userStore.currentUser?.ares_management?.map((a) => a.id),
                },
            },
        }
        
    } else {
        _filter = {
            ..._filter,
            user_type: USER_TYPE.RETAIL,
        }
    }
    if (filter.value && Object.entries(filter.value).length > 0) {
        _filter = {
            ..._filter,
            ...filter.value,
        }

        // trường hợp user là HR thì và search WSR thì sẽ loại bỏ area vì WSR k có area
        if (userStore?.currentUser?.role?.short_name == USER_ROLE.HR && 
        _filter?.user_type === USER_TYPE.WSR) {
            delete _filter?.area
        }
    }
    if (!_filter?.employment_status) {
        delete _filter?.employment_status
    }
   console.log(_filter);
   
    return _filter
})
const { items, totalPages, getItems, getTotalCount, getItemCount, loading } = useItems(ref('directus_users'), {
    fields: ref([
        '*',
        'zone.*',
        'gender.opt_value',
        'area.name',
        'employment_status.*',
        'route_code.name',
        'parent_id.full_name',
        'parent_id.role.short_name',
        'system_providers.provider_id.*',
    ]),
    limit: ref(RECORD_PER_PAGE),
    filter: customFilter,
    sort,
    search: ref(null),
    page: page,
})

const itemsRender = computed(() => {
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    return items.value.map((x) => {
        index++
        return {
            ...x,
            stt: index,
            system_providers: x.system_providers?.length > 0 ? x.system_providers[0].provider_id.name : '',
        }
    })
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const arrayStatus = computed(() => {
    return getArrayStatus(USER_COLLECTION)
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {
        employment_status: data.employment_status || null,
        area: data.area || null,
        zone: data.zone || null,
        system_providers: data.system_providers || null,
    }

    if (data.system_providers) {
        _filter.system_providers = {
            provider_id: data.system_providers,
        }
    }

    if (data.start_date && data.start_date.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.start_date[0], data.start_date[1])
        _filter.start_date = {
            _between: dateQuery,
        }
    }

    if (data.ss) {
        _filter.parent_id = data.ss
    } else if (data.asm) {
        _filter._or = [
            { parent_id: data.asm },
            {
                parent_id: {
                    parent_id: data.asm,
                },
            },
        ]
        _filter.asm = data.asm
    } else if (data.user_type !== 'all') {
        _filter.user_type = data.user_type
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value && key !== 'employment_status') {
            delete _filter[key]
        }
    }

    if (data.title) {
        _filter._and = [
            {
                _or: [
                    {
                        full_name: {
                            _contains: data.title,
                        },
                    },
                    {
                        phone_number: {
                            _contains: data.title,
                        },
                    },
                    {
                        personal_email: {
                            _contains: data.title,
                        },
                    },
                    {
                        code: {
                            _contains: data.title,
                        },
                    },
                    {
                        route_code: {
                            name: {
                                _contains: data.title,
                            },
                        },
                    },
                    {
                        id_card_number: {
                            _contains: data.title,
                        },
                    },
                ],
            },
        ]
    }
    filter.value = _filter
    delete filter.value?.asm

    updateSearchStore(
        {
            search: data.title,
            filter: {
                ..._filter,
            },
        },
        data
    )
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

// table end

// actions start

const batchDeleteFun = async () => {
    await batchDelete('status')
    await refresh()
}

const { confirmDelete, deleting, batchDelete, error: deleteError, modelSelected } = useBatch(USER_COLLECTION)

const _export = useExport(USER_COLLECTION, {
    stt: 'STT',
    user_type: 'Vị trí',
    'zone.name': 'Chi nhánh',
    'area.name': 'Khu vực',
    'system_providers.provider_id.code': 'Mã NPP',
    'system_providers.provider_id.name': 'Nhà phân phối',
    'parent_id.parent_id.full_name': 'ASM',
    'parent_id.parent_id.code': 'Mã ASM',
    'parent_id.parent_id.email': 'Email ASM',
    'parent_id.full_name': 'SS',
    'parent_id.code': 'Mã SS',
    'parent_id.email': 'Email SS',
    'route_code.name': 'Mã Route',
    code: 'Mã DCR',
    full_name: 'Họ và tên',
    date_of_birth: 'Ngày sinh',
    'gender.opt_value': 'Giới tính',
    phone_number: 'Số điện thoại',
    phone_number_zalo: 'Số điện thoại sử dụng Zalo',
    personal_email: 'Email',
    'education_level.opt_value': 'Trình độ học vấn',
    married: 'Tình trạng hôn nhân',
    id_card_number: 'CCCD',
    id_card_date: 'Ngày cấp',
    id_card_place: 'Nơi cấp',
    address: 'Địa chỉ hiện tại',
    'district.name': 'Quận / Huyện',
    'province.name': 'Tỉnh / Thành phố',
    bank_account_number: 'Số tài khoản',
    'bank_name.name': 'Tên ngân hàng',
    bank_branch: 'Chi nhánh ngân hàng',
    start_date: 'Ngày bắt đầu',
    last_working_date: 'Ngày lv cuối cùng',
    employment_status: 'Trạng thái',
    note: 'Ghi chú',
})
_export.filter.value = {
    role: {
        short_name: USER_ROLE.DCR,
    },
    employment_status: {
        _neq: DELETE_VALUE,
    },
}
_export.filename.value = 'ExportDcr.xlsx'
_export.beforeGenerate.value = (items) => {
    return items.map((item, index) => {
        return {
            stt: index + 1,
            user_type: USER_TYPE_DATA[item?.user_type]?.label,
            zone_name: item?.zone?.name,
            area_name: item?.area?.name,
            system_providers_provider_id_id:
                item?.system_providers?.length > 0 ? item?.system_providers[0]?.provider_id?.code : '',
            system_providers_provider_id_name:
                item?.system_providers?.length > 0 ? item?.system_providers[0]?.provider_id?.name : '',
            parent_id_parent_id_full_name: item?.parent_id?.parent_id?.full_name,
            parent_id_parent_id_code: item?.parent_id?.parent_id?.code,
            parent_id_parent_id_email: item?.parent_id?.parent_id?.email,
            parent_id_full_name: item?.parent_id?.full_name,
            parent_id_code: item?.parent_id?.code,
            parent_id_email: item?.parent_id?.email,
            'route_code.name': item?.route_code?.name,
            code: item?.code,
            full_name: item?.full_name,
            date_of_birth: item?.date_of_birth ? new Date(item?.date_of_birth) : '',
            gender_name: item?.gender?.opt_value,
            phone_number: item?.phone_number,
            phone_number_zalo: item?.phone_number_zalo,
            email: item?.personal_email,
            education_level: item?.education_level?.opt_value,
            married: item?.married === true ? 'Đã kết hôn' : 'Độc thân',
            id_card_number: item?.id_card_number,
            id_card_date: item?.id_card_date ? new Date(item?.id_card_date) : '',
            id_card_place: item?.id_card_place,
            address: item?.address,
            district_name: item?.district?.name,
            province_name: item?.province?.name,
            bank_account_number: item?.bank_account_number,
            'bank_name.name': item?.bank_name?.name,
            bank_branch: item?.bank_branch,
            start_date: item?.start_date ? new Date(item?.start_date) : '',
            last_working_date: item?.last_working_date ? new Date(item?.last_working_date) : '',
            employment_status: USER_STATUS[item?.employment_status]?.text,
            note: item?.note,
        }
    })
}
const exportData = () => {
    _export.filter.value = customFilter.value

    if (modelSelected.value) {
        const primaryKeys = modelSelected.value.map((u) => u.id)
        if (primaryKeys && primaryKeys.length > 0) {
            _export.filter.value = {
                id: {
                    _in: primaryKeys,
                },
            }
        }
    }

    _export.sort.value = sort.value
    _export.run()
}
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang="scss" scoped></style>
