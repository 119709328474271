<template>
    <div class="ml-7">
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            :disabled="disabled"
            :sort="internalSort"
            :loading="loading"
            @update:sort="updateSort($event)"
        >
            <template #[`item.edit`]="{ item }">
                <v-button rounded secondary icon small @click="detailRowOnClick(item)">
                    <v-icon name="edit"></v-icon>
                </v-button>
            </template>
        </v-table>
    </div>
    <modal-target-detail
        :is-show="isShowDialogDetail"
        :data="dataModal"
        @close="isShowDialogDetail = false"
        mode="global"
        @refresh="emit('refresh')"
    ></modal-target-detail>
</template>

<script setup lang="ts">
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import modalTargetDetail from './modal-target-detail.vue'

const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const disabled = ref(false)
const isShowDialogDetail = ref(false)
const dataModal = ref({})
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const emit = defineEmits(['updateSort', 'success', 'refresh'])

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'Năm',
        value: 'year',
        width: 100,
    },
    {
        text: 'Tổng chi tiêu',
        value: 'total_targets',
        width: 150,
    },
    {
        text: 'T1',
        value: 'january',
        width: 100,
    },
    {
        text: 'T2',
        value: 'february',
        width: 100,
    },
    {
        text: 'T3',
        value: 'march',
        width: 100,
    },
    {
        text: 'T4',
        value: 'april',
        width: 100,
    },
    {
        text: 'T5',
        value: 'may',
        width: 100,
    },
    {
        text: 'T6',
        value: 'june',
        width: 100,
    },
    {
        text: 'T7',
        value: 'july',
        width: 100,
    },
    {
        text: 'T8',
        value: 'august',
        width: 100,
    },
    {
        text: 'T9',
        value: 'september',
        width: 100,
    },
    {
        text: 'T10',
        value: 'october',
        width: 100,
    },
    {
        text: 'T11',
        value: 'november',
        width: 100,
    },
    {
        text: 'T12',
        value: 'december',
        width: 100,
    },
    {
        text: 'Chỉnh sửa',
        value: 'edit',
        width: 150,
    },
])

const detailRowOnClick = (data: any) => {
    isShowDialogDetail.value = true
    dataModal.value = data
}

const convertKeySort = (sortKey, desc) => {
    let key = ''
    switch (sortKey) {
        default:
            key = sortKey
            break
    }
    if (desc) key = `-${key}`
    return key
}
const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    let sortKey = sort?.by
    if (sortKey !== 'stt') {
        emit('updateSort', convertKeySort(sortKey, sort?.desc))
    }
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
