import {Ref, ref} from 'vue'
import {useApi} from '@directus/composables'
import {useFieldTemplate} from "@/shared-fe/composables/use-field-template";

export const useFieldsCom = (keyName, groupOptions = {}) => {
    const formData = ref({})
    const idSettings: Ref<any[]> = ref({})
    const formLoading = ref(false)
    const isReady = ref(false);


    const {
        fieldInput,
        fieldGroup,
        fieldSmallFile,
    } = useFieldTemplate({})

    const fieldSmallFolder = (fieldName, label = null, metas = null) => {

        return fieldInput(fieldName, label, {
            ...metas,
            note: 'Có thể chọn nhiều hình, Kéo thả từng hình để sắp xếp',
            interface: 'app-uploads',
        })
    }

    const api = useApi()
    const apiPath = '/items/live_editor'

    const originValue = ref({});

    const refreshData = async () => {
        isReady.value = false;

        api.get(apiPath, {
            params: {
                filter: {
                    group: keyName,
                },
                sort: ['-group'],
                limit: -1
            },
        }).then(async (res) => {
            let model = res.data?.data
            if (!model) {
                model = await api.post(apiPath, {
                    group: keyName,
                }).then((__res) => {
                    return __res.data?.data
                })
            }

            idSettings.value = {}
            formData.value = {}

            for (let item of model) {
                idSettings.value[item.option_key] = item.id
                formData.value[item.option_key] = item.option_value
                originValue.value[item.option_key] = item.option_value
            }

        }).then(() => {
            isReady.value = true
        })
    }

    refreshData().catch();

    const save = async () => {
        formLoading.value = true
        let hasChanged = false;
        for (let key of Object.keys(formData.value)) {

            if (idSettings.value[key] > 0 && originValue.value[key] != formData.value[key]) {
                hasChanged = true;
                await api.patch(`${apiPath}/${idSettings.value[key]}`, {
                    option_value: formData.value[key],
                    option_key: key,
                    group: keyName,
                })
            }
        }

        if (hasChanged) setTimeout(refreshData, 500)
        formLoading.value = false
    }

    return {
        isReady,
        idSettings,
        formLoading,
        formData,
        save,
        fieldGroup,
        fieldInput,
        fieldSmallFile,
        fieldSmallFolder,
    }
}
