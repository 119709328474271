<template>
    <div class="asm-assessment form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9">
        <div class="info-request font-bold text-[var(--foreground-normal-alt)] text-6 mb-8">Đánh giá của ASM</div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1 lg:col-span-3">
                <div class="type-label">Đánh giá chi tiết</div>
                <v-input
                    type="text"
                    :disabled="assessmented"
                    placeholder="Nhập nội dung..."
                    v-model="formData.asm_note"
                    @update:model-value="(e) => handleChange(e, 'asm_note')"
                ></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Kết quả thử việc
                    <span class="required">*</span>
                </div>
                <v-select
                    :model-value="formData.asm_status"
                    :items="dropdownStatus"
                    :disabled="assessmented"
                    @update:model-value="(e) => handleChange(e, 'asm_status')"
                ></v-select>
                <span class="message-error" v-if="errors?.asm_status">Vui lòng chọn trạng thái</span>
            </div>
            <!-- <div class="col-span-1 items-start">
                <div class="type-label">&#160</div>
                <div class="h-[var(--input-height)]">
                    <v-button :disabled="assessmented" secondary @click="updateStatus" class="custom-style-button">Xác nhận đánh giá</v-button>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script setup lang="ts">
import _ from 'lodash'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { useProbationaryAssessmentQuery } from '../../../../../shared-fe/services/probationary-assessment-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { CONFIRM_MODAL_TEXT, FORMAT_TIME, MODE_EDIT, SUBMIT_EVENT, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import dayjs from 'dayjs'
import { useUserStore } from '@/stores/user'
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
    dropdownStatus: {
        type: Array<Object>,
        default: null,
    },
    endDate: {
        type: String,
        default: ''
    }
})

const commonStore = useStoreCommon()
const { patchItemProbationaryAssessment } = useProbationaryAssessmentQuery()
const errors: any = ref(null)
const router = useRouter()
const confirmModal = useConfirmModal()
const dataDefault = {
    id: 0,
    asm_status: null,
    asm_note: null,
}
const userStore = useUserStore()
const assessmented = computed(() => {
    const parts = props.endDate.split('/')
    const formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`
    let validEndDate = dayjs(new Date(formattedDateString)).add(60, 'day').format(FORMAT_TIME.YYYMMDD)
    if (dayjs(validEndDate).isBefore(dayjs(new Date()).format(FORMAT_TIME.YYYMMDD))) {
        return true
    }
    if (props.item?.asm_status !== null) {
        return true
    }
    if (![USER_ROLE.ASM].includes(userStore.currentUser?.role.short_name)) {
        return true
    }
    return false
})

const defaultDataEdit = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const formData = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
}
const handleChange = (e: any, variable: any) => {
    switch (variable) {
        default:
            formData.value[variable] = e
            break
    }
}

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(formData.value)) {
        formData.value[key] = props.item[key]
    }

    defaultDataEdit.value = JSON.parse(JSON.stringify(formData.value))
}

watch(
    () => props.item,
    async () => {
        if (props.item) {
            initData()
        }
    },
    { immediate: true }
)

const updateStatus = async () => {
    if (formData.value.asm_status === null) {
        errors.value = {
            asm_status: 'asm_status bắt buộc nhập',
        }
        return
    }

    await patchItemProbationaryAssessment(formData.value.id, {
        ...formData.value,
        asm_status: formData.value.asm_status,
    })

    router.push({
        name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
    })
}

const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.PROBATIONARY_ASSESSMENTS_SUBMIT)
    if (payloadSubmitForm) {
        if (formData.value.asm_status === null) {
            errors.value = {
                asm_status: 'asm_status bắt buộc nhập',
            }
            return
        }
        let params = JSON.parse(JSON.stringify(formData.value))
        // delete params.asm_status
        if (payloadSubmitForm.payload === MODE_EDIT) {
            handleConfirm(
                params.asm_status
                    ? CONFIRM_MODAL_TEXT.PROBATIONARY_ASSESSMENTS.OK
                    : CONFIRM_MODAL_TEXT.PROBATIONARY_ASSESSMENTS.REJECT,
                async () => await handleSubmit(params)
            )
        }
    }
}

const handleSubmit = async (params: any) => {
    await patchItemProbationaryAssessment(params.id, params)
    commonStore.showDialogSucessUpdate = true
    router.push({
        name: PATH_ROUTER.PROBATIONARY_ASSESSMENTS_LIST.NAME,
    })
}
const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})
</script>
