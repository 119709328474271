<template>
    <div v-if="items && items.length > 0" class="table-hand-made">
        <div class="mb-12 box-table">
            <div class="grid grid-cols-2 items-center mb-4">
                <div class="col-span-1 info-request font-bold text-[var(--foreground-normal-alt)] text-6">
                    Lịch sử ứng tuyển
                </div>
            </div>

            <div class="grid bg-[#1E4BA1] rounded-t-lg text-white grid-cols-5">
                <div class="col-span-1 border p-4">Ngày ứng tuyển</div>
                <div class="col-span-1 border p-4">Mã ứng viên</div>
                <div class="col-span-1 border p-4">Họ và tên</div>
                <div class="col-span-1 border p-4">Trạng thái</div>
                <div class="col-span-1 p-4">Nguồn ứng viên</div>
            </div>
            <div v-for="item in items" :style="{ borderBottom: '1px solid #E2E8F0' }" class="grid bg-white grid-cols-1">
                <div class="grid grid-cols-5">
                    <div class="col-span-1 p-4">{{ dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) }}</div>
                    <a class="col-span-1 p-4 font-bold hover:underline text-[#1E4BA1]" :href="'/admin/spvn/candidates/' + item?.id" target="_blank">
                        {{ item?.id }}
                    </a>
                    <div class="col-span-1 p-4">{{ item?.full_name }}</div>
                    <div class="col-span-1 p-4">{{ item?.status?.opt_value }}</div>
                    <div class="col-span-1 p-4">{{ item?.source?.opt_value }}</div>
                </div>
            </div>
            <div class="footer">
                <div class="c-pagi pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="7"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import { useCandidate } from '../../../../../shared-fe/composables/use-candidate'

const props = defineProps({
    idCandidate: {
        type: Number,
        default: '',
    },
    phoneNumber: {
        type: String,
        default: '',
    },
    phoneNumberZalo: {
        type: String,
        default: '',
    },
    email: {
        type: String,
        default: '',
    },
})
const page = ref(1)
const ITEM_PER_PAGE = 5
const totalPages = ref(1)
const items = ref(<any>[])
const { getDuplicateCandidate } = useCandidate()

const toPage = (_page) => {
    page.value = _page
}

const getData = async () => {
    const res = await getDuplicateCandidate(
        props.phoneNumber,
        props.phoneNumberZalo,
        props.email,
        ITEM_PER_PAGE,
        page.value
    )
    items.value = []
    if (res.data.data) {
        let data = res.data.data
        let index = data.findIndex((x: any) => x.id === props.idCandidate)
        data.splice(index, 1)
        items.value = data
    }
    if (res.data.meta) {
        totalPages.value = Math.ceil(res.data.meta.filter_count / ITEM_PER_PAGE)
    }
}
watch(
    () => page.value,
    async () => {
        await getData()
    },
    { immediate: true }
)
</script>

<style lang="scss" scoped></style>
