import { defineModule } from '@directus/extensions-sdk'
import OnboardingDRC from './routes/learning/learning.vue'
import OnboardingDRCDetail from './routes/session-train-detail/session-train-detail.vue'
import ExamResultsList from './routes/session-train-detail/exam_results_list/exam_results_list.vue'
import ExamResultsDetail from './routes/session-train-detail/exam_results_detail/exam_results_detail.vue'
import ModuleList from './routes/module-list/module-list.vue'
import ExcludeDcrDetail from './routes/exclude-dcr/detail/detail.vue'
import SaturdayLearning from './routes/saturday-learning/saturday-learning.vue'
import SaturdayProcessTrain from './routes/saturday-process-train/saturday-process-train.vue'
import SaturdayExamResultList from './routes/saturday-learning/exam_results_list/exam_results_list.vue'
import SaturdayExamResultDetail from './routes/saturday-learning/exam_results_detail/exam_results_detail.vue'
import ProcessModuleTrain from './routes/process-module-train/process-module-train.vue'
import LdSettings from './routes/settings/ld-settings.vue'
import TargetManagement from './routes/target_management/target-management.vue'
import ObResultAdjustment from './routes/ob-result-adjustment/ob-result-adjustment.vue'
import Report from './routes/report/report.vue'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import '@/style.scss'
import 'uno.css'

export default defineModule({
    id: 'pepsi-ld',
    name: 'LD Module',
    icon: 'school',
    routes: [
        {
            name: 'ld-index',
            path: '',
            redirect: PATH_ROUTER.LD_LEARNING_DETAIL.PATH,
            children: [
                {
                    name: PATH_ROUTER.LD_LEARNING_DETAIL.NAME,
                    path: PATH_ROUTER.LD_LEARNING_DETAIL.PATH,
                    component: OnboardingDRC,
                    meta: {
                        permission: PATH_ROUTER.LD_LEARNING_DETAIL.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.DCR_ONBOARDING_DETAIL.NAME,
                    path: PATH_ROUTER.DCR_ONBOARDING_DETAIL.PATH,
                    component: OnboardingDRCDetail,
                    meta: {
                        permission: PATH_ROUTER.DCR_ONBOARDING_DETAIL.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.PROCESS_MODULE_TRAIN.NAME,
                    path: PATH_ROUTER.PROCESS_MODULE_TRAIN.PATH,
                    component: ProcessModuleTrain,
                    meta: {
                        permission: PATH_ROUTER.PROCESS_MODULE_TRAIN.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.PROCESS_MODULE_RETRY_TRAIN.NAME,
                    path: PATH_ROUTER.PROCESS_MODULE_RETRY_TRAIN.PATH,
                    component: ProcessModuleTrain,
                    meta: {
                        permission: PATH_ROUTER.PROCESS_MODULE_RETRY_TRAIN.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.RESULT_EXAM_MODULE.NAME,
                    path: PATH_ROUTER.RESULT_EXAM_MODULE.PATH,
                    component: ExamResultsList,
                    meta: {
                        permission: PATH_ROUTER.RESULT_EXAM_MODULE.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.RESULT_DETAIL_EXAM_MODULE.NAME,
                    path: PATH_ROUTER.RESULT_DETAIL_EXAM_MODULE.PATH,
                    component: ExamResultsDetail,
                    meta: {
                        permission: PATH_ROUTER.RESULT_DETAIL_EXAM_MODULE.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.MODULE_LIST.NAME,
                    path: PATH_ROUTER.MODULE_LIST.PATH,
                    component: ModuleList,
                    meta: {
                        permission: PATH_ROUTER.MODULE_LIST.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.EXCLUDE_DCR_DETAIL.NAME,
                    path: PATH_ROUTER.EXCLUDE_DCR_DETAIL.PATH,
                    component: ExcludeDcrDetail,
                    meta: {
                        permission: PATH_ROUTER.EXCLUDE_DCR_DETAIL.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.SATURDAY_LEARNING.NAME,
                    path: PATH_ROUTER.SATURDAY_LEARNING.PATH,
                    component: SaturdayLearning,
                    meta: {
                        permission: PATH_ROUTER.SATURDAY_LEARNING.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.SATURDAY_LEARNING_TRAINING.NAME,
                    path: PATH_ROUTER.SATURDAY_LEARNING_TRAINING.PATH,
                    component: SaturdayProcessTrain,
                    meta: {
                        permission: PATH_ROUTER.SATURDAY_LEARNING_TRAINING.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.SATURDAY_LEARNING_RESULT_LIST.NAME,
                    path: PATH_ROUTER.SATURDAY_LEARNING_RESULT_LIST.PATH,
                    component: SaturdayExamResultList,
                    meta: {
                        permission: PATH_ROUTER.SATURDAY_LEARNING_RESULT_LIST.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.SATURDAY_LEARNING_RESULT_DETAIL.NAME,
                    path: PATH_ROUTER.SATURDAY_LEARNING_RESULT_DETAIL.PATH,
                    component: SaturdayExamResultDetail,
                    meta: {
                        permission: PATH_ROUTER.SATURDAY_LEARNING_RESULT_DETAIL.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.LEARNING_REPORT.NAME,
                    path: PATH_ROUTER.LEARNING_REPORT.PATH,
                    component: Report,
                    meta: {
                        permission: PATH_ROUTER.LEARNING_REPORT.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.LEARNING_SETTINGS.NAME,
                    path: PATH_ROUTER.LEARNING_SETTINGS.PATH,
                    component: LdSettings,
                    meta: {
                        permission: PATH_ROUTER.LEARNING_SETTINGS.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.TARGET_MANAGEMENT.NAME,
                    path: PATH_ROUTER.TARGET_MANAGEMENT.PATH,
                    component: TargetManagement,
                    meta: {
                        permission: PATH_ROUTER.TARGET_MANAGEMENT.PERMISSION,
                    },
                },
                {
                    name: PATH_ROUTER.OB_RESULT_ADJUSTMENT.NAME,
                    path: PATH_ROUTER.OB_RESULT_ADJUSTMENT.PATH,
                    component: ObResultAdjustment,
                    meta: {
                        permission: PATH_ROUTER.OB_RESULT_ADJUSTMENT.PERMISSION,
                    },
                },
            ],
        },
    ],
})
