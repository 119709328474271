import {ref, computed} from 'vue'
import {aw} from "@fullcalendar/core/internal-common";
import {useApi} from "@directus/composables";

export const useProvinceDistrict = (defaultProvince, defaultDistrict) => {
    const api = useApi();
    const province = ref(defaultProvince)
    const district = ref(defaultDistrict);

    const provinces = ref([]);
    const districts = ref([]);

    (async () => {
        const ps = await api.get("/items/provinces", {
            params: {
                limit: 999,
                filter: {
                    parent_id: {
                        _null: true
                    }
                },
                fields: [
                    "id",
                    "name",
                    "districts.id",
                    "districts.name",
                ]
            }
        }).then(res => res.data.data)

        provinces.value = ps;
    })()

    const selectProvinces = computed(() => {
        if (provinces.value?.length <= 0) return [];

        return provinces.value.map(p => {
            return {
                text: p.name,
                value: p.id
            }
        })
    })

    const currentProvince = computed(() => {
        if (!province.value) return {}

        return provinces.value.find(p => p.id == province.value)
    })

    const selectDistrict = computed(() => {
        if (!province.value || !currentProvince.value?.districts) return [];

        return currentProvince.value.districts.map(d => {
            return {
                text: d.name,
                value: d.id
            }
        })
    })

    const setProvince = (v) => province.value = v;
    const setDistrict = (v) => district.value = v;

    return {
        province,
        district,
        selectDistrict,
        selectProvinces,
        provinces,
        setProvince,
        setDistrict,
        currentProvince,
        districts
    }
}
