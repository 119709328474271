<template>
    <div class="flex flex-col items-center justify-center px-28px py-10 min-w-[540px] bg-white rounded-md">
        <div class="mb-6">
            <div
                class="p-6 bg-[#DEF4F5] w-100px h-100px rounded-full box-icon-success flex items-center justify-center"
            >
                <v-icon name="check"></v-icon>
            </div>
        </div>
        <div class="type-label">
            <div class="text-5 text-[#1E293B] font-bold mb-1">Đã mở khoá thi thành công</div>
        </div>
        <div class="text-14px text-[#64748B]">Thời gian còn lại để bắt đầu khóa thi:</div>
        <div class="time-down flex flex-row justify-center mt-8">
            <div class="flex flex-col items-center text-[#1E4BA1]">
                <div class="font-bold text-36px">{{ hour }}</div>
                <div class="text-14px mt-2">Giờ</div>
            </div>
            <div class="flex flex-col items-center text-[#1E4BA1] mx-37px">
                <div class="font-bold text-36px">{{ minute }}</div>
                <div class="text-14px mt-2">Phút</div>
            </div>
            <div class="flex flex-col items-center text-[#1E4BA1]">
                <div class="font-bold text-36px">{{ second }}</div>
                <div class="text-14px mt-2">Giây</div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/customParseFormat'

dayjs.extend(duration)
const emit = defineEmits(['cancelAction', 'nextAction'])

const nextDay = dayjs().add(1, 'day').set('hour', 0).set('minute', 0).set('second', 0)
const timeLeft = ref(nextDay.diff(dayjs(), 'hour', true) * 3600)

const hour = ref('')
const minute = ref('')
const second = ref('')
const intervalId = ref()

watch(
    () => timeLeft.value,
    () => {
        if (timeLeft.value && timeLeft.value > 0) {
            const hoursLeft = Math.floor(timeLeft.value / 3600)
            const minutesLeft = Math.floor((timeLeft.value - hoursLeft * 3600) / 60)
            const secondsLeft = timeLeft.value - hoursLeft * 3600 - minutesLeft * 60
            hour.value = hoursLeft.toString().padStart(2, '0')
            minute.value = minutesLeft.toString().padStart(2, '0')
            second.value = secondsLeft.toString().padStart(2, '0')
        } else {
            hour.value = '0'
            minute.value = '0'
            second.value = '0'
        }
    }
)

onMounted(() => {
    handleCountDown()
})
onUnmounted(() => {
    clearInterval(intervalId.value)
})
const handleCountDown = () => {
    timeLeft.value = Math.round(timeLeft.value)

    intervalId.value = setInterval(() => {
        if (timeLeft.value > 0) {
            timeLeft.value = timeLeft.value - 1
        } else {
            clearInterval(intervalId.value)
            emit('nextAction')
            return
        }
    }, 1000)
}
</script>
<style lang="scss">
.box-icon-success {
    position: relative;
    .v-icon i {
        color: var(--primary);
        font-size: 30px;
        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
