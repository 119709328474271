<template>
    <v-dialog v-model="isShow" @esc="closeDialogFunc" placement="center custom-width">
        <v-card>
            <div class="flex flex-col items-center justify-center px-28px">
                <div class="mt-10 mb-6">
                    <div
                        class="p-6 bg-[#DEF4F5] w-100px h-100px rounded-full box-icon-success flex items-center justify-center"
                    >
                        <v-icon size="large" name="check"></v-icon>
                    </div>
                </div>
                <div class="type-labell">
                    <div class="text-5 text-[#1E293B] font-bold mb-1">Đã lưu chỉ tiêu</div>
                </div>
                <div class="text-14px text-[#64748B] text-center">
                    Những thay đổi này sẽ được áp dụng cho chỉ tiêu của từng SS từ thời điểm hiện tại đến tương lai.
                </div>
                <v-card-actions>
                    <v-button secondary @click="closeDialogFunc">Đóng</v-button>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps({
    isShow: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['close'])
const { isShow } = toRefs(props)

const closeDialogFunc = () => {
    emit('close');

}
</script>

<style>

</style>
