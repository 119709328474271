<template>
    <template v-if="!uploadState">
        <div class="text-center text-xl text-primary font-bold" v-if="loading">Đang tải dữ liệu, vui lòng chờ ...</div>
        <template v-if="!loading">
            <div class="w-[500px] bg-white mx-auto">
                <c-upload :fileNames="fileNames" @input="uploaded" @error="handleError"></c-upload>
            </div>
            <div class="w-[500px] mx-auto mt-[14px]">
                <span class="message-error text-xs" v-if="errors?.length > 0">
                    Tải lên không thành công. File tải không hợp lệ.
                </span>
                <div class="text-xs" v-for="(error, index) in errors" :key="index">
                    <span class="message-error text-xs" v-if="error?.row !== 0">DÒNG {{ error.row }}:&nbsp;</span>
                    <span v-if="error?.row !== 0">{{ error.message.join(' ; ') }}</span>
                </div>
            </div>
            <div v-if="!uploadState && dcrSuccess > 0" class="mt-8 w-full flex justify-center">
                <span class="custom-button-import">
                    <v-button class="" outlined @click="submit">
                        <span class="text-[#5BABAC]">Xác nhận tải lên</span>
                    </v-button>
                </span>
            </div>
            <!-- <div
                v-if="dcrSuccess > 0"
                class="mt-5 py-[18px] px-4 border-l-4 border-t-0 border-r-0 border-b-0 border-[#399E9F] border-solid bg-[#EAFAF6] w-full text-[#399E9F]"
            >
                <span class="mr-2 py-1 border border-solid rounded-full">
                    <v-icon class="" outlined name="check"></v-icon>
                </span>
                <span class="mr-4">Đã tải lên {{ totalDcrSuccess ? totalDcrSuccess : dcrSuccess }} WSR hợp lệ</span>
            </div> -->
        </template>
    </template>

    <div v-if="uploadState">
        <c-upload-progress title="File WSR " :total="validData?.length"></c-upload-progress>
    </div>

    <c-dialog-upload-success
        :is-show="isShowSuccess"
        @close="isShowSuccess = false"
        :total="totalDcrSuccess"
        file-name="WSR"
    ></c-dialog-upload-success>
</template>
<script setup lang="ts">
import CUpload from '@/shared-fe/components/c-upload/c-upload.vue'
import { Ref } from 'vue'
import { ref } from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import { toLowerCaseNonAccentVietnamese } from '@/shared-fe/utils/convert-data'
import dayjs from 'dayjs'
import { FORMAT_TIME } from '@/shared-fe/utils/constant'
import { toRefs } from 'vue'
import { useImportWsrValidate } from '../import/useImportWsrValidate'
import cDialogUploadSuccess from '@/shared-fe/components/c-dialog-upload-success/c-dialog-upload-success.vue'
import { useStoreLoading } from '@/shared-fe/stores/loading'
import { useImportWsr } from '../import/useImportWsr'
import cUploadProgress from '@/shared-fe/components/c-upload-progress/c-upload-progress.vue'

const { handleProgressing, uploading } = useStoreLoading()
const { importWsrData, loading } = useImportWsr()
const { getIndexOfColumnDependOnHeader, columnLabels, validate } = useImportWsrValidate()
const errors: Ref<any[]> = ref([])
const uploadState: Ref<boolean> = ref(false)
const dcrSuccess: Ref<number> = ref(0)
const totalDcrSuccess: Ref<number> = ref(0)
const rawData = ref(<any[]>[])
const isShowSuccess = ref(false)
const fileNames: Ref<string[]> = ref([])
const validData = ref(<any[]>[])

const emit = defineEmits(['import'])

const uploaded = (file: any, filesName: Array<string>) => {
    fileNames.value = filesName
    totalDcrSuccess.value = 0
    validData.value = []
    rawData.value = []
    if (file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
            errors.value = []
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, { type: 'array', cellDates: true })

            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            let parseData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: '',
            })

            let header = parseData[0].map((columnName: string) => toLowerCaseNonAccentVietnamese(columnName.trim()))

            parseData.shift()
            if (parseData.length === 0) {
                handleError()
                return
            }
            parseData.map((arrayData: any, index: number) => {
                const id = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.id)].toString() || ''
                const zone = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.zone)].toString() || ''
                const asm_name =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.asm_name)].toString() || ''
                const asm_code =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.asm_code)].toString() || ''
                const asm_email =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.asm_email)].toString() || ''
                const ss = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.ss)].toString() || ''
                const ss_code = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.ss_code)].toString() || ''
                const ss_email =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.ss_email)].toString() || ''
                const route_code =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.route_code)].toString() || ''
                const dcr_code =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.dcr_code)].toString() || ''
                const full_name =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.full_name)].toString() || ''
                const date_of_birth =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.date_of_birth)] || ''
                const gender = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.gender)].toString() || ''
                const phone_number =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.phone_number)].toString() || ''
                const phone_number_zalo =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.phone_number_zalo)].toString() || ''
                const email = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.email)].toString() || ''
                const education_level =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.education_level)].toString() || ''
                const married = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.married)].toString() || ''
                const id_card_number =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.id_card_number)].toString() || ''
                const id_card_date = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.id_card_date)] || ''
                const id_card_place =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.id_card_place)].toString() || ''
                const address = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.address)].toString() || ''
                const district =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.district)].toString() || ''
                const province =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.province)].toString() || ''
                const start_date = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.start_date)] || ''
                const last_working_date =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.last_working_date)] || ''
                const employment_status =
                    arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.employment_status)].toString() || ''
                const note = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.note)].toString() || ''
                const dataValidation = {
                    id,
                    zone,
                    asm_name,
                    asm_code,
                    asm_email,
                    ss,
                    ss_code,
                    ss_email,
                    route_code,
                    dcr_code,
                    full_name,
                    date_of_birth,
                    gender,
                    phone_number,
                    phone_number_zalo,
                    email,
                    education_level,
                    married,
                    id_card_number,
                    id_card_place,
                    id_card_date,
                    address,
                    district,
                    province,
                    start_date,
                    last_working_date,
                    employment_status,
                    note,
                }
                rawData.value.push(dataValidation)
                const isEmptyRows = arrayData.every((x: any) => x === '')
                if (isEmptyRows) {
                    return
                }
                let { error, data } = validate(dataValidation)
                if (error) {
                    errors.value.push({
                        row: index + 2,
                        message: data,
                    })
                } else {
                    validData.value.push(dataValidation)
                }
            })
            dcrSuccess.value = validData.value.length
        }

        reader.readAsArrayBuffer(file)
    }
}

const submit = async () => {
    if (validData.value.length > 0) {
        handleProgressing(0)
        uploadState.value = true
        const res = await importWsrData(validData.value, rawData.value)
        if (res) {
            totalDcrSuccess.value = res.success
            if (res.errors.length === 0) {
                dcrSuccess.value = 0
                isShowSuccess.value = true
                uploadState.value = false
                return
            }
            res.errors.forEach((error: any) => {
                let errorArr = error.split('||')
                if (errorArr.length > 0) {
                    let dcrCode = errorArr[0]
                    let index = rawData.value.findIndex((x: any) => x.dcr_code === dcrCode)
                    let message = errorArr[1]
                    handleShowError(message, index)
                }
            })
            uploadState.value = false
        }
    }
}

const handleShowError = (error: string, row: number) => {
    if (!error) {
        return
    }

    let findRow = errors.value.findIndex((x) => x.row === row + 1)
    if (findRow < 0) {
        errors.value.push({
            row: row + 1,
            message: [error],
        })
    } else {
        errors.value[findRow].message.push(error)
    }
}

const handleError = () => {
    errors.value = [
        {
            row: 0,
            message: ['error'],
        },
    ]
}

defineExpose({ handleShowError, rawData, validData })
</script>

<style lang="scss">
.custom-button-import {
    position: relative;
    .button {
        border-radius: 0.5rem !important;
        border-color: var(--primary) !important;
        background: transparent !important;
        color: var(--primary) !important;

        &:hover {
            border-color: var(--primary) !important;
            background: transparent;
            color: var(--primary);
        }
    }
}
</style>
