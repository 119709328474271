import { useApi } from '@directus/extensions-sdk'
import { CANDIDATES_HISTORY_COLLECTION } from '../utils/table-name'
export const useCandidateHistoryQuery = () => {
    const api = useApi()

    const getCandidateHistory = async (limit: number, idCandidate: number, currentPage: number) => {
        return await api.get(`/items/${CANDIDATES_HISTORY_COLLECTION}`, {
            params: {
                limit: limit,
                page: currentPage,
                fields: ['date_created', 'description', 'user_created.full_name', 'user_created.role.name'],
                meta: ['filter_count'],
                filter: {
                    candidate_id: idCandidate,
                },
            },
        })
    }

    return {
        getCandidateHistory,
    }
}
