<template>
    <c-role>
        <private-view :title="TITLE_LIST_RECRUITMENT_REQUEST">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <template #actions>
                <c-access :roles="[USER_ROLE.HR]">
                    <span class="custom-button-export-excel">
                        <v-button secondary @click="exportData" :loading="loadingExcel">
                            <v-icon name="file_upload" outline />
                            <span class="inline-block ml-2">
                                {{ EXPORT_EXCEL }}
                            </span>
                        </v-button>
                    </span>
                </c-access>

                <c-access :roles="[]">
                    <v-dialog v-if="modelSelected?.length > 0" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>

                <!-- <c-access :roles="[USER_ROLE.HR]">
                    <v-button
                        v-if="modelSelected?.length > 0"
                        v-tooltip.bottom="EDIT"
                        rounded
                        icon
                        secondary
                        @click="batchEditActive = true"
                    >
                        <div class="i-mdi-pencil-outline"></div>
                    </v-button>
                </c-access> -->
                <c-access :roles="[USER_ROLE.SS, USER_ROLE.ASM]" :deniedAccessAdmin="true">
                    <v-button
                        v-tooltip.bottom="CREATE_ITEM"
                        class="custom-create-button"
                        :to="PATH_ROUTER.RECRUITMENT_REQUEST_ADD.PATH"
                    >
                        Tạo YCTD
                    </v-button>
                </c-access>
                <drawer-batch
                    v-model:active="batchEditActive"
                    :primary-keys="primaryKeys"
                    :collection="RECRUITMENT_REQUEST_COLLECTION"
                    @refresh="batchRefresh"
                />
            </template>

            <!-- dialog delete (start) -->
            <v-dialog v-if="modelSelected?.length > 0" v-model="confirmDelete" @esc="confirmDelete = false">
                <v-card>
                    <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                    <v-card-actions>
                        <v-button secondary @click="confirmDelete = false">
                            {{ CANCEL }}
                        </v-button>
                        <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                            {{ LABEL_DELETE }}
                        </v-button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- dialog delete (end) -->

            <!-- header end -->
            <div class="mx-9">
                <!-- searchbar start -->
                <recruitment-request-searchbar
                    :arrayValue="objSearchStore?.dataSeletedForm"
                    @update:handleSearch="handleSearch"
                />
                <!-- searchbar end -->

                <!-- content start -->
                <recruitment-request-table
                    :items="itemsRender"
                    :modelSelected="modelSelected"
                    @update:modelSelected="modelSelectedFun"
                    :totalPages="totalPages"
                    :page="page"
                    :loading="loading"
                    @update:toPage="handleToPage"
                    @updateSort="handleUpdateSort"
                />
                <!--  content end -->
            </div>

            <template #sidebar>
                <sidebar-detail icon="info" :title="TITLE_LIST_RECRUITMENT_REQUEST" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useApi, useItems } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import dayjs from 'dayjs'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import * as XLSX from 'xlsx/xlsx.mjs'
import CAccess from '../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '@/shared-fe/composables/use-batch'
import { useField } from '@/shared-fe/composables/use-field'
import { useRecruitmentRequestQuery } from '@/shared-fe/services/recruitment-request-service'
import { useStoreSearch } from '@/shared-fe/stores/search'
import {
    DELETE_VALUE,
    FORMAT_TIME,
    RECORD_PER_PAGE,
    RECRUITMENT_REQUEST_STATUS,
    USER_ROLE,
} from '@/shared-fe/utils/constant'
import { displayRequestDate, formatDateObjectToQuery } from '@/shared-fe/utils/convert-data'
import {
    CANCEL,
    CONTENT_DELETE,
    CREATE_ITEM,
    EXPORT_EXCEL,
    LABEL_DELETE,
    TITLE_LIST_RECRUITMENT_REQUEST,
} from '@/shared-fe/utils/message'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { RECRUITMENT_REQUEST_COLLECTION } from '@/shared-fe/utils/table-name'
import RecruitmentRequestSearchbar from './components/recruitment-request-searchbar.vue'
import RecruitmentRequestTable from './components/recruitment-request-table.vue'
import { useUserStore } from '@/stores/user'
import { useSettingQuery } from '@/shared-fe/services/setting-service'

const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
const { getArrayHiringReason } = useField()
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-id'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)
const primaryKeys = computed(() => {
    return modelSelected.value?.map((x) => x.id) || []
})
const userStore = useUserStore()
const api = useApi()
const customFilter = computed(() => {
    let _filter: any = {
        status: {
            _neq: DELETE_VALUE,
        },
    }
    if (userStore?.currentUser?.role?.short_name == USER_ROLE.HR) {
        _filter = {
            ..._filter,
            user_created: {
                area: {
                    id: {
                        _in: userStore.currentUser?.ares_management?.map((a) => a.id),
                    },
                },
            },
        }
    }
    if (filter.value && Object.entries(filter.value).length > 0) {
        _filter = {
            ..._filter,
            ...filter.value,
        }
    }

    return _filter
})
const { items, totalPages, loading, getItems, getTotalCount, getItemCount } = useItems(
    ref(RECRUITMENT_REQUEST_COLLECTION),
    {
        fields: ref([
            'province.name',
            'hr_id.full_name',
            'status',
            'route',
            'providers.zone.name',
            'providers.name',
            'providers.area.name',
            'hiring_reason',
            'user_created.full_name',
            'user_created.zone.name',
            'user_created.area.name',
            'staff_id.route_code.name',
            'staff_id.code',
            'staff_id.full_name',
            'staff_id.system_providers.provider_id.name',
            'staff_id.system_providers.provider_id.zone.name',
            'staff_id.system_providers.provider_id.area.name',
            'staff_id.last_working_date',
            'interview_round.pv1',
            'interview_round.pv2',
            'interview_round.pv3',
            'interview_round.route_ride',
            'interview_round.date_send_email',
            'interview_round.date_work_est',
            'interview_round.staff',
            'completed_date',
            'date_created',
            'id',
            'approved_date',
            'rotation_date',
            'start_date',
            'candidates',
        ]),
        limit: ref(RECORD_PER_PAGE),
        sort,
        search: ref(null),
        filter: customFilter,
        page: page,
    }
)

const hiringReasonDisplayName = (value: string) => {
    if (arrayHiringReason.value?.length > 0) {
        return arrayHiringReason.value.find((x: any) => x.value === value)?.text
    }
    return ''
}

const { getProjectSetting } = useSettingQuery()
const holidays = ref(<string[]>[])
getProjectSetting().then((res) => {
    if (res?.data?.data) {
        holidays.value = res?.data?.data?.holidays
            ?.split(',')
            ?.map((x: any) => x.replace('\n', '').split('/').reverse().join('-'))
    }
})

const itemsRender = computed(() => {
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    return items.value.map((item) => {
        index++
        return {
            stt: index,
            id: item?.id,
            date_created: item?.date_created ? dayjs(item?.date_created).format(FORMAT_TIME.DDMMYYYY) : '',
            recruitment_request_person: item?.user_created?.full_name,
            status: item?.status,
            hiring_reason: hiringReasonDisplayName(item?.hiring_reason),
            zone: item?.user_created?.zone?.name,
            area: item?.user_created?.area?.name,
            provider_name: item?.providers?.name,
            province_name: item?.province?.name,
            staff_route_code: item?.route,
            staff_code: item?.staff_id?.code,
            staff_full_name: item?.staff_id?.full_name,
            total_candidate_linked: item?.interview_round?.length,
            total_pass_interview_1: item?.interview_round?.filter((x) => x?.pv1 === true)?.length,
            total_pass_interview_2: item?.interview_round?.filter((x) => x?.pv2 === true)?.length,
            total_pass_route_ride: item?.interview_round?.filter((x) => x?.route_ride === true)?.length,
            total_pass_interview_3: item?.interview_round?.filter((x) => x?.pv3 === true)?.length,
            total_pass_pass_job: item?.interview_round?.filter((x) => x?.staff === true)?.length,
            total_request_date: displayRequestDate(item, item?.staff_id?.last_working_date, holidays.value),
            hr_name: item?.hr_id?.full_name,
        }
    })
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const arrayHiringReason = computed(() => {
    return getArrayHiringReason(RECRUITMENT_REQUEST_COLLECTION)
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {
        province: data.province_id
            ? {
                  id: {
                      _eq: data.province_id,
                  },
              }
            : null,
        status: data.status || null,
    }
    _filter.providers = {}
    if (data.area) {
        _filter.providers = {
            ..._filter.providers,
            area: {
                id: data.area,
            },
        }
    }
    if (data.zone) {
        _filter.providers = {
            ..._filter.providers,
            zone: {
                id: data.zone,
            },
        }
    }
    if (data.provider) {
        _filter.providers = {
            ..._filter.providers,
            id: data.provider,
        }
    }

    if (data.date_created && data.date_created.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.date_created[0], data.date_created[1])
        _filter.date_created = {
            _between: dateQuery,
        }
    }

    if (data.user_created) {
        _filter.user_created = {
            full_name: {
                _contains: data.user_created,
            },
        }
    }

    if (data.hr_name) {
        _filter.hr_id = {
            full_name: {
                _contains: data.hr_name,
            },
        }
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value) {
            delete _filter[key]
        }
    }

    if (data.title) {
        _filter._and = [
            {
                _or: [
                    {
                        id: data.title,
                    },
                ],
            },
        ]
    }

    filter.value = _filter

    updateSearchStore(
        {
            search: data.title,
            filter: _filter,
        },
        data
    )
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

// table end

// actions start
const { changeStatusItemRecruitmentRequest } = useRecruitmentRequestQuery()
const batchRefresh = async () => {
    modelSelected.value = []
    await refresh()
}

const batchDeleteFun = async () => {
    await batchDelete('status')
    await refresh()
}

const {
    confirmDelete,
    deleting,
    error: deleteError,
    batchEditActive,
    modelSelected,
    batchDelete,
} = useBatch(RECRUITMENT_REQUEST_COLLECTION)

const itemsExportExcel = ref(<any>[])
const pageExcel = ref(1)
const run = async () => {
    const params = {
        fields: [
            'id',
            'date_created',
            'user_created.full_name',
            'status',
            'approved_date',
            'providers.name',
            'providers.code',
            'user_created.system_providers.provider_id.zone.name',
            'user_created.system_providers.provider_id.area.name',
            'user_created.system_providers.provider_id.code',
            'user_created.system_providers.provider_id.name',
            'user_created.full_name',
            'user_created.parent_id.full_name',
            'province.name',
            'districts.name',
            'hiring_reason',
            'staff_id.code',
            'staff_id.full_name',
            'staff_id.route_code.name',
            'staff_id.last_working_date',
            'quantity',
            'start_date',
            'rotation_date',
            'completed_date',
            'hr_id.full_name',
            'interview_round.id',
            'interview_round.candidate.*',
            'interview_round.candidate.user.code',
            'interview_round.candidate.source.opt_value',
            'interview_round.pv1',
            'interview_round.pv2',
            'interview_round.route_ride',
            'interview_round.pv3',
            'interview_round.staff',
            'interview_round.date_send_email',
            'interview_round.interview_details.*',
            'interview_round.ride_step',
            'interview_round.user_created',
            'interview_round.date_route_ride',
            'interview_round.status',
            'interview_round.staff',
            'interview_round.pv2',
            'interview_round.pv1',
            'interview_round.pv3',
            'interview_round.start_date',
            'interview_round.date_finished',
            'interview_round.date_send_email',
            'interview_round.date_completed_ride',
            'interview_round.date_work_est',
            'interview_round.date_updated',
            'interview_round.user_updated',
        ],
        sort: sort.value,
        page: pageExcel.value,
        limit: 100,
        filter: customFilter.value,
    }
    const output = await api
        .get(`items/${RECRUITMENT_REQUEST_COLLECTION}`, {
            params: params,
        })
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })

    itemsExportExcel.value = itemsExportExcel.value.concat(output.data)

    if (output.data.length > 0) {
        pageExcel.value++
        await run().catch()
    } else {
        pageExcel.value = 1
        generateExcel()
    }
}

const loadingExcel = ref(false)
const headerYCTD = [
    'STT',
    'Mã YCTD',
    'Ngày tạo yêu cầu',
    'Người yêu cầu',
    'Trạng thái',
    'Ngày duyệt yêu cầu',
    'Chi nhánh',
    'Khu vực',
    'ASM',
    'SS',
    'Mã NPP',
    'Nhà phân phối',
    'Tỉnh / Thành phố',
    'Lý do tuyển',
    'Mã nv nghỉ việc / luân chuyển',
    'Nhân viên nghỉ việc / luân chuyển',
    'Mã route',
    'Số lượng',
    'Ngày dự kiến bắt đầu làm việc',
    'Số ngày tuyển dụng',
    'Người phụ trách',
    'Ứng viên',
    'Đạt PV1',
    'Đạt PV2',
    'Đạt RR',
    'Đạt PV3',
    'Đã nhận việc',
    'Ngày offer',
    'Ngày nhận việc',
    'Mã NV mới',
    'Mã ứng viên',
    'Tên ứng viên',
    'Nguồn ứng viên',
]

const headerDetailYCTD = [
    'STT',
    'Mã YCTD',
    'Ngày tạo yêu cầu',
    'Người yêu cầu',
    'Trạng thái',
    'Ngày duyệt yêu cầu',
    'Chi nhánh',
    'Khu vực',
    'ASM',
    'SS',
    'Mã NPP',
    'Nhà phân phối',
    'Tỉnh / Thành phố',
    'Lý do tuyển',
    'Mã nv nghỉ việc / luân chuyển',
    'Nhân viên nghỉ việc / luân chuyển',
    'Mã route',
    'Số lượng',
    'Ngày dự kiến bắt đầu làm việc',
    'Số ngày tuyển dụng',
    'Người phụ trách',
    'Mã NV mới',
    'Mã ứng viên',
    'Tên ứng viên',
    'Nguồn ứng viên',
    'Ngày PV 1',
    'Kết quả PV 1',
    'Chi tiết đánh giá',
    'Ngày PV 2',
    'Kết quả PV2',
    'Chi tiết đánh giá',
    'Ngày bắt đầu RR',
    'Ngày training RR',
    'Kết quả RR',
    'Chi tiết đánh giá',
    'Ngày PV 3',
    'Kết quả PV 3',
    'Chi tiết đánh giá',
    'Ngày gửi offer',
    'Ngày nhận việc',
    'Tình trạng nhận việc',
    'Lý do không nhận việc',
]

const renderContentInterviewRound = (interviewRound: any) =>{
    let candidatePassInterview: string[] = []
    let interview_round: string[] = []
    let detailCandidateInfo: string[] = []

    let objPv1 = interviewRound?.interview_details.find((x) => x?.candidate_status === 'interview_1')
    let objPv2 = interviewRound?.interview_details.find((x) => x?.candidate_status === 'interview_2')
    let objRR = interviewRound?.interview_details.find((x) => x?.candidate_status === 'route_ride_result')
    let objPv3 = interviewRound?.interview_details.find((x) => x?.candidate_status === 'interview_3')
    let objWaitingForJob = interviewRound?.interview_details?.find(
        (x) => x.candidate_status === 'waiting_for_job' && x.status === true
    )
    candidatePassInterview = [
    interviewRound?.date_send_email ? new Date(interviewRound?.date_send_email) : '',
        objWaitingForJob?.date_created ? new Date(objWaitingForJob?.date_created) : '',
        interviewRound?.candidate?.user?.code,
        interviewRound?.candidate?.id,
        interviewRound?.candidate?.full_name,
        interviewRound?.candidate?.source?.opt_value,
    ]
    detailCandidateInfo = [
        interviewRound?.candidate?.user?.code,
        interviewRound?.candidate?.id,
        interviewRound?.candidate?.full_name,
        interviewRound?.candidate?.source?.opt_value,
    ]

    interview_round = [
        objPv1?.date_created ? new Date(objPv1?.date_created) : '',
        !objPv1 ? '' : objPv1?.status === true ? 'Đạt' : 'Không đạt',
        objPv1?.content,

        objPv2?.date_created ? new Date(objPv2?.date_created) : '',
        !objPv2 ? '' : objPv2?.status === true ? 'Đạt' : 'Không đạt',
        objPv2?.content,

        interviewRound?.date_route_ride ? new Date(interviewRound?.date_route_ride) : '',
        '',
        !objRR ? '' : objRR?.status === true ? 'Đạt' : 'Không đạt',
        objRR?.content,

        objPv3?.date_created ? new Date(objPv3?.date_created) : '',
        !objPv3 ? '' : objPv3?.status === true ? 'Đạt' : 'Không đạt',
        objPv3?.content,

        interviewRound?.date_send_email ? new Date(interviewRound?.date_send_email) : '',

        interviewRound?.date_work_est ? new Date(interviewRound?.date_work_est) : '',
        
        !objPv3 ? '' : objWaitingForJob?.status === true ? 'Nhận việc' : 'Không nhận việc',
        objWaitingForJob?.content,
    ]

    return {
        candidatePassInterview,
        detailCandidateInfo,
        interview_round
    }
}

const convertDataList = () => {
    let itemsListResult: any = []
    let itemsDetailResult: any = []
    itemsExportExcel.value.forEach((item, index) => {
        let requestRecruitment: any = [
            index + 1,
            item?.id,
            item?.date_created ? new Date(item.date_created) : '',
            item?.user_created?.full_name,
            RECRUITMENT_REQUEST_STATUS[item?.status]?.text,
            item?.approved_date ? new Date(item.approved_date) : '',
            item?.user_created?.system_providers?.length > 0
                ? item?.user_created?.system_providers[0].provider_id.zone?.name
                : '',
            item?.user_created?.system_providers?.length > 0
                ? item?.user_created?.system_providers[0].provider_id.area?.name
                : '',
            item?.user_created?.parent_id?.full_name,
            item?.user_created?.full_name,
            // item?.user_created?.system_providers?.length > 0
            //     ? item?.user_created?.system_providers[0].provider_id.code
            //     : '',
            // item?.user_created?.system_providers?.length > 0
            //     ? item?.user_created?.system_providers[0].provider_id?.name
            //     : '',
            item?.providers?.code,
            item?.providers?.name,
            item?.province?.name,
            hiringReasonDisplayName(item?.hiring_reason),
            item?.staff_id?.code,
            item?.staff_id?.full_name,
            item?.staff_id?.route_code?.name,
            item?.quantity,
            item?.start_date ? new Date(item.start_date) : '',
            Number(displayRequestDate(item, item?.staff_id?.last_working_date, holidays.value)),
            item?.hr_id?.full_name,
        ]

        let summaryInterview = [
            item?.interview_round?.length,
            item?.interview_round?.filter((x) => x?.pv1 === true)?.length,
            item?.interview_round?.filter((x) => x?.pv2 === true)?.length,
            item?.interview_round?.filter((x) => x?.route_ride === true)?.length,
            item?.interview_round?.filter((x) => x?.pv3 === true)?.length,
            item?.interview_round?.filter((x) => x?.staff === true)?.length,
        ]

        if (item.interview_round.length > 0) {
            let objInterviewSummary: string[] = []
            let objInterviewDetail: string[] = []
            
            objInterviewSummary = [...requestRecruitment, ...summaryInterview]

            // tại sheet tổng hợp, 1 yctd là 1 record
            // lấy interview round sau cùng và trường hợp ứng viên nhận việc thì mới thêm phần phỏng vấn
            const interviewRound  = item.interview_round?.find((x: any) => x.staff === true);
            if(interviewRound && interviewRound.staff === true){
                const interviewRoundRenderSummary =  renderContentInterviewRound(interviewRound);
                objInterviewSummary = [...objInterviewSummary, ...interviewRoundRenderSummary.candidatePassInterview]
            }
            itemsListResult.push(objInterviewSummary)

            // tại sheet detail, hiển thị tất cả interview round.
            item.interview_round.forEach(x => {
                const interviewRoundRenderDetail = renderContentInterviewRound(x);
                objInterviewDetail = [...requestRecruitment, ...interviewRoundRenderDetail.detailCandidateInfo, ...interviewRoundRenderDetail.interview_round]
                itemsDetailResult.push(objInterviewDetail)
            })            
            
        } else {
            itemsListResult.push([...requestRecruitment, ...summaryInterview, '', '', '', '', ''])
            itemsDetailResult.push([...requestRecruitment, '', '', '', '', '', '', '', ''])
        }
    })

    itemsDetailResult.map((x: any, index: number) => {
        x[0] = index + 1;
    })

    return { itemsListResult, itemsDetailResult }
}
const generateExcel = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet([headerYCTD])
    const ws2 = XLSX.utils.aoa_to_sheet([headerDetailYCTD])

    const { itemsListResult, itemsDetailResult } = convertDataList()

    XLSX.utils.sheet_add_json(ws, itemsListResult, {
        origin: -1,
        skipHeader: 1,
    })
    XLSX.utils.sheet_add_json(ws2, itemsDetailResult, {
        origin: -1,
        skipHeader: 1,
    })

    XLSX.utils.book_append_sheet(wb, ws, 'YCTD Tổng hợp')
    XLSX.utils.book_append_sheet(wb, ws2, 'YCTD Chi tiết')

    XLSX.writeFile(wb, 'RecruitmentRequest.xlsx')
}

const exportData = async () => {
    loadingExcel.value = true
    itemsExportExcel.value = []
    await run()
    loadingExcel.value = false
}
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang="scss"></style>
