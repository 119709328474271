import Joi from 'joi'

export const useModifyOnboardValidate = () => {
    const messageLabels = {
        dcr_code: 'Cột A',
        route_code: 'Cột B',
        full_name: 'Cột C',
        work_with: 'Cột D',
        elearning: 'Cột E',
        duplicate: 'Cột F',
        result: 'Cột G'
    }
    const customMessages = {
        'number.base': 'lỗi định dạng',
        'string.base': 'lỗi định dạng',
        'string.empty': 'đang trống dữ liệu',
        'any.required': 'đang trống dữ liệu',
    }

    const schema = Joi.object({
        dcr_code: Joi.string().required().messages(customMessages),
        route_code: Joi.string().allow('').optional().messages(customMessages),
        full_name: Joi.string().allow('').optional().messages(customMessages),
        work_with: Joi.number().required().messages(customMessages),
        elearning: Joi.number().required().messages(customMessages),
        duplicate: Joi.number().required().messages(customMessages),
        result: Joi.string().required().messages(customMessages),
    }).unknown(true)

    const validate = (formData: any) => {
        const { error } = schema.validate(formData, { abortEarly: false })
        let messages: string[] = []
        if (error?.details) {
            error.details.map((detail) => {
                const customLabel = messageLabels[detail.context.key]
                messages.push(`\"${customLabel} ${detail.message}\"`)
            })
        }

        return {
            error: !!error,
            data: messages,
        }
    }

    return {
        messageLabels,
        schema,
        validate,
    }
}
