export const useFieldTemplate = (groupOptions: any) => {
    const interfaces = {
        'select-dropdown-m2o': 'select-dropdown-m2o',
        file: 'file',
        'system-folder': 'system-folder',
        'file-image': 'file-image',
        input: 'input',
        'system-token': 'system-token',
        datetime: 'datetime',
        'select-color': 'select-color',
        'select-dropdown': 'select-dropdown',
        list: 'list',
        'input-code': 'input-code',
        'system-modules': 'system-modules',
        'system-language': 'system-language',
        'input-rich-text-html': 'input-rich-text-html',
        'select-icon': 'select-icon',
        'system-display-template': 'system-display-template',
        boolean: 'boolean',
        'system-field': 'system-field',
        'system-field-tree': 'system-field-tree',
        'input-multiline': 'input-multiline',
        'input-hash': 'input-hash',
        tags: 'tags',
        'system-mfa-setup': 'system-mfa-setup',
        'list-o2m': 'list-o2m',
        'group-detail': 'group-detail',
        'list-m2m': 'list-m2m',
        files: 'files',
        'presentation-divide': 'presentation-divider',
    }

    const fieldGroup = (fieldName: string, label = null, metas = {}) => {
        return {
            field: fieldName,
            type: 'alias',
            name: label || fieldName,
            meta: {
                field: fieldName,
                special: ['alias', 'no-data', 'group'],
                options: {
                    headerColor: '#0eb4b2',
                    ...(groupOptions.group ?? {}),
                },
                interface: 'group-detail',
                ...metas,
            },
        }
    }
    const fieldInput = (fieldName: string, label: string|any = null, metas: any = {}) => {
        let options = {}
        if (metas?.interface === 'input-rich-text-html') {
            const mini = ['bold', 'italic', 'underline', 'forecolor']

            if (metas.type == 'mini') {
                options = {
                    toolbar: mini,
                    tinymceOverrides: {
                        height: 100,
                    },
                }
            } else {
                options = {
                    toolbar: [
                        ...mini,
                        'h1',
                        'h2',
                        'h3',
                        'numlist',
                        'bullist',
                        'backcolor',
                        'removeformat',
                        'blockquote',
                        'customLink',
                        'customImage',
                        'customMedia',
                        'hr',
                        'code',
                        'fullscreen',
                    ],
                }
            }
        }

        delete metas.type

        return {
            field: fieldName,
            type: 'string',
            name: label || fieldName,
            meta: {
                field: fieldName,
                interface: 'input',
                options: {
                    ...options,
                },
                ...metas,
            },
        }
    }

    const fieldDate = (fieldName, label, metas: any = {}) => {
        return {
            field: fieldName,
            type: 'date',
            name: label || fieldName,
            meta: {
                disableSunday: true,
                disableSaturday: true,
                field: fieldName,
                interface: 'app-datetime',
                ...metas,
                options: {
                    disableSunday: true,
                    disableSaturday: true,
                    ...(metas.options ?? {}),
                },
            },
        }
    }

    const fieldDateDefault = (fieldName, label, metas: any = {}) => {
        return {
            field: fieldName,
            type: 'date',
            name: label || fieldName,
            meta: {
                field: fieldName,
                interface: 'app-datetime',
                ...metas,
                options: {
                    ...(metas.options ?? {}),
                },
            },
        }
    }

    const fieldFilesImage = (fieldName, label, metas: any = {}) => {
        return {
            field: fieldName,
            type: 'string',
            name: label || fieldName,
            meta: {
                field: fieldName,
                interface: 'file-image',
                ...metas,
                options: {
                    ...(metas.options ?? {}),
                },
            },
        }
    }

    const fieldTextArea = (fieldName, label, metas: any = {}) => {
        return {
            field: fieldName,
            type: 'string',
            name: label || fieldName,
            meta: {
                field: fieldName,
                interface: 'input-multiline',
                ...metas,
                options: {
                    ...(metas.options ?? {}),
                },
            },
        }
    }

    const fieldSmallFile = (fieldName, label = null, metas = null) => {
        return fieldInput(fieldName, label, {
            ...metas,
            note: 'Nhập ID file',
        })

        return {
            field: fieldName,
            type: 'string',
            name: label || fieldName,
            meta: {
                field: fieldName,
                interface: 'file',
                ...metas,
            },
        }
    }

    const fieldSelect = (fieldName, label, options = [], metas = {}) => {
        return {
            field: fieldName,
            type: 'string',
            name: label || fieldName,
            meta: {
                field: fieldName,
                interface: interfaces['select-dropdown'],
                ...metas,
                options: {
                    choices: Object.values(options ?? []) ?? [],
                },
            },
        }
    }

    return {
        fieldSelect,
        fieldDate,
        fieldInput,
        fieldGroup,
        fieldSmallFile,
        interfaces,
        fieldDateDefault,
        fieldFilesImage,
        fieldTextArea,
    }
}
