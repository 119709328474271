<template>
    <v-dialog v-model="isShow" @esc="closeDialogFunc" placement="center custom-width">
        <v-card>
            <v-card-title>
                <div class="flex flex-col items-center justify-center px-28px w-full">
                    <div class="mt-10 mb-6">
                        <div
                            class="p-6 bg-[#DEF4F5] w-100px h-100px rounded-full box-icon-success flex items-center justify-center"
                        >
                            <v-icon x-large name="check"></v-icon>
                        </div>
                    </div>
                    <div class="type-labell">
                        <div class="text-5 text-[#1E293B] font-bold mb-1">Tải lên hoàn tất</div>
                    </div>
                    <div class="text-14px text-[#64748B] text-center mb-12">
                        Đã tìm thấy {{ total }} Item đã tải lên từ file {{ props.fileName }}
                    </div>
                </div>
            </v-card-title>
            <v-card-actions class="!justify-center">
                <v-button secondary @click="closeDialogFunc">Đóng</v-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
import { onMounted, toRefs } from 'vue'

const props = defineProps({
    isShow: {
        type: Boolean,
        default: false,
    },
    total: {
        type: Number,
        default: 0,
    },
    fileName: {
        type: String,
        default: 'Work With',
    },
})
const emit = defineEmits(['close'])
const { isShow, total } = toRefs(props)

const closeDialogFunc = () => {
    emit('close')
}
</script>

<style></style>
