import Joi from "joi";
import {ref} from "vue";

export const useRecruitmentRequestValidate = () => {
    const messageLabels = {
        hiring_reason: "Lý do tuyển",
        providers: "Nhà phân phối",
        employment_type: "Hình thức làm việc",
        province: "Tỉnh thành",
        quantity: "Số lượng",
        position: "Vị trí tuyển dụng",
        ss_id: "Nhân viên SS",
        staff_id: "Nhân viên DCR",
        districts: "Quận / Huyện",
        reason_rotation: "Lý do luân chuyển",
        rotation_date: "Ngày luân chuyển",
        start_date: "Ngày làm việc dự kiến",
        route: "Mã route",
    }
    const customMessages = {
        'number.base': 'là bắt buộc',
        'string.base': 'không được để trống',
        'string.empty': 'không được để trống',
        'any.required': 'là bắt buộc',
        'array.base': 'là bắt buộc',
    };

    const schema = Joi.object({
        hiring_reason: Joi.string().required().messages(customMessages),
        providers: Joi.number().required().messages(customMessages),
        employment_type: Joi.string().required().messages(customMessages),
        province: Joi.string().required().messages(customMessages),
        quantity: Joi.number().required().messages(customMessages),
        position: Joi.string().required().messages(customMessages),
        ss_id: Joi.string().required().messages(customMessages),
        start_date: Joi.string().required().messages(customMessages),
        route: Joi.when('hiring_reason', {
            is: Joi.valid("extend"),
            then: Joi.string().required().messages(customMessages),
            otherwise: Joi.optional()
        }),
        staff_id: Joi.when('hiring_reason', {
            is: Joi.valid("replacement", "transfer"),
            then: Joi.string().required().messages(customMessages),
            otherwise: Joi.optional()
        }),
        districts: Joi.array().items(Joi.any()).min(1).required().messages(customMessages),
        reason_rotation: Joi.when('hiring_reason', {
            is: "transfer",
            then: Joi.string().required().messages(customMessages),
            otherwise: Joi.optional()
        }),
        rotation_date: Joi.when('hiring_reason', {
            is: "transfer",
            then: Joi.string().required().messages(customMessages),
            otherwise: Joi.optional()
        })
    })
        .unknown(true)


    const validate = (formData: any) => {
        const {error} = schema.validate(formData, {abortEarly: false})
        const format = {}
        if (error?.details) {
            error.details.map(detail => {
                const customLabel = messageLabels[detail.context.key];
                format[detail.context.key] = `${customLabel}: ${detail.message}`;
            });
        }

        return {
            error: !!error,
            data: format
        }
    }

    return {
        schema,
        validate
    }
}
