<template>
    <v-menu ref="dateTimeMenu" :close-on-content-click="false" attached full-height seamless>
        <template #activator="{ toggle, active }">
            <v-input
                :active="active"
                clickable
                readonly
                :placeholder="props.placeholder"
                @click="toggle"
                :model-value="displayValue"
            >
                <template #prepend>
                    <v-icon
                        :name="props.rangeDateValue ? 'clear' : 'calendar_month'"
                        :class="{
                            active,
                            'clear-icon': props.rangeDateValue,
                            'today-icon': !props.rangeDateValue,
                        }"
                        v-on="{ click: props.rangeDateValue ? unsetValue : null }"
                    />
                </template>
            </v-input>
        </template>

        <c-date-picker-range
            type="date"
            :model-value="props.rangeDateValue"
            @update:model-value="$emit('update:model-value', $event)"
            @close="dateTimeMenu?.deactivate"
        />
    </v-menu>
</template>

<script setup lang="ts">
import { isValid, parse } from 'date-fns'
import { computed, ref } from 'vue'
import CDatePickerRange from './c-datepicker-range.vue'
import { formatDateToDisplay } from '../../utils/convert-data'

const props = defineProps({
    rangeDateValue: {
        type: Array<String>,
        default: [],
    },
    placeholder: {
        type: String,
        default: '',
    },
})
const emit = defineEmits(['update:model-value'])
const dateTimeMenu = ref()
const displayValue = computed(() => {
    if (!props.rangeDateValue || !isValidValue) {
        return
    }

    return props.rangeDateValue
        .map((val: any) => {
            return formatDateToDisplay(val)
        })
        .join(' đến ')
})

const isValidValue = computed(() => {
    if (props.rangeDateValue && props.rangeDateValue.length > 1) {
        let isValidArray = props.rangeDateValue.map((item) => isValid(parse(item, 'yyyy-MM-dd', new Date())))
        let valid = isValidArray.find((x) => !x)
        if (valid !== undefined) {
            return false
        }
        return true
    }
    return false
})

/**
 * handle unset value when input date range has data
 * @param e
 */
function unsetValue() {
    emit('update:model-value', null)
}
</script>

<style lang="scss" scoped>
.v-icon {
    &.today-icon {
        &:hover,
        &.active {
            --v-icon-color: var(--primary);
        }
    }

    &.clear-icon {
        &:hover,
        &.active {
            --v-icon-color: var(--danger);
        }
    }
}
</style>
