<template>
    <div class="flex items-center">
        <v-icon
            small
            filled
            name="fiber_manual_record"
            :style="props.isPass ? { color: '#059669' } : { color: '#EF4444' }"
        />
        <div class="text-[#059669]" v-if="isPass">Thi đậu</div>
        <div class="text-[#EF4444]" v-else>Thi trượt</div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
const props = defineProps({
    isPass: {
        type: Boolean,
    },
})
const { isPass } = toRefs(props)
</script>

<style lang="scss"></style>
