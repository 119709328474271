<template>
    <div class="h-screen">
        <div class="flex-none header-layout">
            <slot name="header"></slot>
        </div>
        <div class="bodylayout" >
            <slot></slot>
        </div>
    </div>
</template>

<style>
.header-layout {
    height: 58px;
}

.bodylayout {
    height: calc(100% - 58px);
}

</style>
<script setup>
</script>
