import { useApi } from '@directus/extensions-sdk'

export const useOptionQuery = () => {
    const api = useApi()

    const getAllOption = async () => {
        return await api.get('/items/options', {
            params: {
                limit: -1,
                fields: ['*'],
            },
        })
    }

    const getOptionByGroup = async (optGroup: string) => {
        return await api.get('/items/options', {
            params: {
                fields: ['id', 'opt_value'],
                limit: -1,
                filter: {
                    opt_group: {
                        _eq: optGroup,
                    },
                },
            },
        })
    }

    return {
        getAllOption,
        getOptionByGroup,
    }
}
