<template>
    <c-role>
        <private-view title="Nhân viên WSR" name="3">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation name="3" />
            </template>

            <!-- navigation end  -->
            <!-- header start -->
            <template #actions>
                <span class="custom-button-export-excel mr-5 text-[#399E9F] border-[#399E9F]">
                    <v-button secondary @click="exportData" :loading="_export.loadingExcel.value">
                        <v-icon name="file_download" outline />
                        <span class="inline-block ml-2">
                            {{ EXPORT_EXCEL }}
                        </span>
                    </v-button>
                </span>
                <span class="border h-full w-[1px] border-solid border-[#E2E8F0] mx-5"></span>

                <c-access :roles="[USER_ROLE.HR, USER_ROLE.AGENCY]">
                    <span class="custom-button-export-excel ml-5 text-[#399E9F] border-[#399E9F]">
                        <v-button secondary @click="handleImportWsr">
                            <v-icon name="file_upload" outline />
                            <span class="inline-block ml-2">Nhập danh sách WSR</span>
                        </v-button>
                    </span>
                    <v-button
                        @click="handleAddNew"
                        rounded
                        icon
                        v-tooltip.bottom="CREATE_ITEM"
                        class="custom-create-button ml-5"
                    >
                        <v-icon name="add"></v-icon>
                    </v-button>
                </c-access>
                <c-access v-if="modelSelected?.length > 0" :roles="[USER_ROLE.AGENCY, USER_ROLE.HR]">
                    <v-dialog v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <v-icon name="delete"></v-icon>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
            </template>
            <!-- header end -->
            <div class="mx-9">
                <!-- searchbar start -->
                <wsr-searchbar
                    :arrayValue="objSearchStore?.dataSeletedForm"
                    :arrayStatus="arrayStatus"
                    @update:handleSearch="handleSearch"
                />

                <!-- searchbar end -->
                <!-- content start -->
                <wsr-table
                    :items="itemsRender"
                    :modelSelected="modelSelected"
                    @update:modelSelected="modelSelectedFun"
                    :totalPages="totalPages"
                    :page="page"
                    :loading="loading"
                    @update:toPage="handleToPage"
                    @updateSort="handleUpdateSort"
                />
                <!--  content end -->
            </div>

            <template #sidebar>
                <sidebar-detail icon="info" title="Nhân viên WSR" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useBatch } from '@/shared-fe/composables/use-batch'
import { useField } from '@/shared-fe/composables/use-field'
import { useStoreSearch } from '@/shared-fe/stores/search'
import { DELETE_VALUE, RECORD_PER_PAGE, USER_ROLE, USER_STATUS } from '@/shared-fe/utils/constant'
import { USER_COLLECTION } from '@/shared-fe/utils/table-name'
import { useUserStore } from '@/stores/user'
import { useItems } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import { computed, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { EXPORT_EXCEL, CREATE_ITEM, LABEL_DELETE, CONTENT_DELETE, CANCEL } from '../../../../shared-fe/utils/message'
import cAccess from '@/shared-fe/components/c-access/c-access.vue'
import CRole from '@/shared-fe/components/c-role/c-role.vue'
import WsrSearchbar from './components/wsr-searchbar.vue'
import WsrTable from './components/wsr-table.vue'
import cNavigation from '@/shared-fe/components/c-navigation/c-navigation.vue'
import { formatDateObjectToQuery } from '@/shared-fe/utils/convert-data'
import { useExport } from '@/shared-fe/composables/use-export'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { USER_TYPE } from '../../../../shared-fe/utils/constant'

const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
const { getArrayStatus } = useField()
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-date_created'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)
const router = useRouter()
const userStore = useUserStore()
const customFilter = computed(() => {
    let _filter: any = {
        status: {
            _neq: DELETE_VALUE,
        },
        role: {
            short_name: USER_ROLE.DCR,
        },
        user_type: USER_TYPE.WSR,
        zone: {
            id: {
                _in: getListZoneByHR(),
            },
        },
    }

    if (filter.value && Object.entries(filter.value).length > 0) {
        _filter = {
            ..._filter,
            ...filter.value,
        }
    }
    if (!_filter?.employment_status) {
        delete _filter?.employment_status
    }
    return _filter
})
const getListZoneByHR = () => {
    let _zones: any = []
    if (userStore.currentUser?.role?.short_name === USER_ROLE.HR) {
        if (userStore.currentUser?.ares_management?.length > 0) {
            userStore.currentUser?.ares_management.forEach((x) => {
                if (_zones?.length > 0) {
                    if (!_zones.find((k) => k === x?.zone?.id)) {
                        _zones.push(x?.zone?.id)
                    }
                } else {
                    _zones.push(x?.zone?.id)
                }
            })
        }
    }
    return _zones
}
const { items, totalPages, getItems, getTotalCount, getItemCount, loading } = useItems(ref('directus_users'), {
    fields: ref([
        '*',
        'zone.*',
        'gender.opt_value',
        'employment_status.*',
        'route_code.name',
        'parent_id.full_name',
        'parent_id.role.short_name',
    ]),
    limit: ref(RECORD_PER_PAGE),
    filter: customFilter,
    sort,
    search: ref(null),
    page: page,
})

const itemsRender = computed(() => {
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    return items.value.map((x) => {
        index++
        return {
            ...x,
            stt: index,
        }
    })
})

const handleAddNew = () => {
    router.push({
        name: PATH_ROUTER.WSR_ADD.NAME,
    })
}

const handleImportWsr = () => {
    router.push({
        name: PATH_ROUTER.IMPORT_WSR.NAME,
    })
}

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const arrayStatus = computed(() => {
    return getArrayStatus(USER_COLLECTION)
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {
        employment_status: data.employment_status || null,
        zone: data.zone || null,
    }

    if (data.start_date && data.start_date.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.start_date[0], data.start_date[1])
        _filter.start_date = {
            _between: dateQuery,
        }
    }

    if (data.ss) {
        _filter.parent_id = data.ss
    } else if (data.asm) {
        _filter._or = [
            { parent_id: data.asm },
            {
                parent_id: {
                    parent_id: data.asm,
                },
            },
        ]
        _filter.asm = data.asm
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value && key !== 'employment_status') {
            delete _filter[key]
        }
    }

    if (data.title) {
        _filter._and = [
            {
                _or: [
                    {
                        full_name: {
                            _contains: data.title,
                        },
                    },
                    {
                        phone_number: {
                            _contains: data.title,
                        },
                    },
                    {
                        personal_email: {
                            _contains: data.title,
                        },
                    },
                    {
                        code: {
                            _contains: data.title,
                        },
                    },
                    {
                        route_code: {
                            name: {
                                _contains: data.title,
                            },
                        },
                    },
                    {
                        id_card_number: {
                            _contains: data.title,
                        },
                    },
                ],
            },
        ]
    }
    filter.value = _filter
    delete filter.value?.asm

    updateSearchStore(
        {
            search: data.title,
            filter: {
                ..._filter,
            },
        },
        data
    )
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}

const batchDeleteFun = async () => {
    await batchDelete('status')
    await refresh()
}

// table end

// actions start

const { confirmDelete, deleting, batchDelete, error: deleteError, modelSelected } = useBatch(USER_COLLECTION)

const _export = useExport(USER_COLLECTION, {
    stt: 'STT',
    'zone.name': 'Chi nhánh',
    'parent_id.parent_id.full_name': 'ASM',
    'parent_id.parent_id.code': 'Mã ASM',
    'parent_id.parent_id.email': 'Email ASM',
    'parent_id.full_name': 'SS',
    'parent_id.code': 'Mã SS',
    'parent_id.email': 'Email SS',
    'route_code.name': 'Mã Route',
    code: 'Mã DCR',
    full_name: 'Họ và tên',
    date_of_birth: 'Ngày sinh',
    'gender.opt_value': 'Giới tính',
    phone_number: 'Số điện thoại',
    phone_number_zalo: 'Số điện thoại sử dụng Zalo',
    personal_email: 'Email',
    'education_level.opt_value': 'Trình độ học vấn',
    married: 'Tình trạng hôn nhân',
    id_card_number: 'CCCD',
    id_card_date: 'Ngày cấp',
    id_card_place: 'Nơi cấp',
    address: 'Địa chỉ hiện tại',
    'district.name': 'Quận / Huyện',
    'province.name': 'Tỉnh / Thành phố',
    start_date: 'Ngày bắt đầu',
    last_working_date: 'Ngày lv cuối cùng',
    employment_status: 'Trạng thái',
    note: 'Ghi chú',
    'resigns_wsr.interview.type_of_resign': 'Loại nghỉ việc (SS)',
    'resigns_wsr.interview.opt_value': 'Lý do nghỉ việc (SS)',
    'resigns_wsr.interview_note': 'Chi tiết lý do nghỉ việc (SS)',
    'resigns_wsr.reason.type_of_resign': 'Loại nghỉ việc (Agency)',
    'resigns_wsr.reason.opt_value': 'Lý do nghỉ việc (Agency)',
    'resigns_wsr.reason_note': 'Chi tiết lý do nghỉ việc (Agency)',
})
_export.filter.value = {
    role: {
        short_name: USER_ROLE.DCR,
    },
    user_type: USER_TYPE.WSR,
    employment_status: {
        _neq: DELETE_VALUE,
    },
}
_export.filename.value = 'ExportWsr.xlsx'
_export.beforeGenerate.value = (items) => {
    return items.map((item, index) => {
        return {
            stt: index + 1,
            zone_name: item?.zone?.name,
            parent_id_parent_id_full_name: item?.parent_id?.parent_id?.full_name,
            parent_id_parent_id_code: item?.parent_id?.parent_id?.code,
            parent_id_parent_id_email: item?.parent_id?.parent_id?.email,
            parent_id_full_name: item?.parent_id?.full_name,
            parent_id_code: item?.parent_id?.code,
            parent_id_email: item?.parent_id?.email,
            'route_code.name': item?.route_code?.name,
            code: item?.code,
            full_name: item?.full_name,
            date_of_birth: item?.date_of_birth ? new Date(item?.date_of_birth) : '',
            gender_name: item?.gender?.opt_value,
            phone_number: item?.phone_number,
            phone_number_zalo: item?.phone_number_zalo,
            email: item?.personal_email,
            education_level: item?.education_level?.opt_value,
            married: item?.married === true ? 'Đã kết hôn' : 'Độc thân',
            id_card_number: item?.id_card_number,
            id_card_date: item?.id_card_date ? new Date(item?.id_card_date) : '',
            id_card_place: item?.id_card_place,
            address: item?.address,
            district_name: item?.district?.name,
            province_name: item?.province?.name,
            start_date: item?.start_date ? new Date(item?.start_date) : '',
            last_working_date: item?.last_working_date ? new Date(item?.last_working_date) : '',
            employment_status: USER_STATUS[item?.employment_status]?.text,
            note: item?.note,
            'resigns_wsr.interview.type_of_resign': item?.resigns_wsr?.[0]
                ? item?.resigns_wsr[0]?.interview?.type_of_resign
                : '',
            'resigns_wsr.interview.opt_value': item?.resigns_wsr?.[0] ? item?.resigns_wsr[0]?.interview?.opt_value : '',
            'resigns_wsr.interview_note': item?.resigns_wsr?.[0] ? item?.resigns_wsr[0]?.interview_note : '',
            'resigns_wsr.reason.type_of_resign': item?.resigns_wsr?.[0]
                ? item?.resigns_wsr[0]?.reason?.type_of_resign
                : '',
            'resigns_wsr.reason.opt_value': item?.resigns_wsr?.[0] ? item?.resigns_wsr[0]?.reason?.opt_value : '',
            'resigns_wsr.reason_note': item?.resigns_wsr?.[0] ? item?.resigns_wsr[0]?.reason_note : '',
        }
    })
}
const exportData = () => {
    _export.filter.value = customFilter.value

    if (modelSelected.value) {
        const primaryKeys = modelSelected.value.map((u) => u.id)
        if (primaryKeys && primaryKeys.length > 0) {
            _export.filter.value = {
                id: {
                    _in: primaryKeys,
                },
            }
        }
    }

    _export.sort.value = sort.value
    _export.run()
}
// actions end
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang="scss" scoped></style>
