<template>
    <c-role>
        <private-view :title="PATH_ROUTER.OB_RESULT_ADJUSTMENT.VALUE">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <template #title>
                <div class="type-title leading-[28px] mb-4">
                    <div>{{ PATH_ROUTER.OB_RESULT_ADJUSTMENT.VALUE }}</div>
                </div>
            </template>
            <template #actions>
                <span class="custom-button-download-template">
                    <c-download-button :file_id="templateFile">
                        <v-icon name="file_download" />
                        <span class="ml-2">Tải file mẫu</span>
                    </c-download-button>
                </span>
            </template>
            <!-- navigation end  -->

            <div class="w-full h-full pt-8">
                <div class="px-6" v-if="!uploadState">
                    <div class="flex gap-5 mb-8">
                        <span class="custom-button-download-template">
                            <v-button @click="handleImportFile">
                                <v-icon name="file_upload" />
                                <span class="ml-2">Nhập file kết quả</span>
                            </v-button>
                        </span>

                        <c-download-button :file_id="templateFile">
                            <v-icon name="file_download" />
                            <span class="ml-2">Tải template mẫu</span>
                        </c-download-button>
                    </div>
                </div>
                <ob-result-adjustment-table
                    v-if="!uploadState"
                    :items="itemsRender"
                    :page="page"
                    :loading="loading"
                    @import-file="handleImportFile"
                    @updateSort="handleUpdateSort"
                ></ob-result-adjustment-table>
                <div v-if="uploadState" class="flex justify-center">
                    <ob-result-adjustment-upload></ob-result-adjustment-upload>
                </div>
            </div>

            <template #sidebar>
                <sidebar-detail icon="info" title="Điều chỉnh kết quả Onboarding" close />
            </template>
        </private-view>
    </c-role>
</template>
<script setup lang="ts">
import { useItems, useStores } from '@directus/composables'
import { ref, toRefs } from 'vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import ObResultAdjustmentUpload from './components/ob-result-adjustment-upload.vue'
import ObResultAdjustmentTable from './components/ob-result-adjustment-table.vue'
import { computed } from 'vue'
import { MODIFY_ONBOARDING_COLLECTION } from '../../../../shared-fe/utils/table-name'
import { useSettingQuery } from '@/shared-fe/services/setting-service'
import useImage from '@/shared-fe/composables/use-image'
import { getAssetUrl } from '@/utils/get-asset-url'
import CDownloadButton from '@/shared-fe/components/c-download-button/c-download-button.vue'

const isName = '2'

const { useAppStore } = useStores()
const { getProjectSetting } = useSettingQuery()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
const uploadState = ref(false)
const templateFile = ref('')
const usedImage = useImage()
sidebarOpen.value = false

const sort = ref(['-date_created'])
const filter = ref(null)
const page = ref(1)

const customFilter = computed(() => {
    let _filter = {}

    return _filter
})

getProjectSetting().then((res) => {
    if (res?.data?.data) {
        templateFile.value = res?.data?.data?.file_template_ob
    }
})

const { items, loading, getItems, getItemCount, getTotalCount } = useItems(ref(MODIFY_ONBOARDING_COLLECTION), {
    fields: ref(['*, user.id, user.full_name, user.code']),
    limit: ref(-1),
    sort,
    search: ref(null),
    filter: customFilter.value,
    page: page,
})

const downloadFile = () => {
    window.open(usedImage.idToUrlDownload(templateFile.value))
}

const refresh = () => {
    getItems()
    getItemCount()
    getTotalCount()
}

const itemsRender = computed(() => {
    let _itemsRender: any = []
    items.value.forEach((x) => {
        _itemsRender.push({
            ...x,
        })
    })
    return _itemsRender
})

const handleImportFile = () => {
    uploadState.value = true
}

const handleUpdateSort = (sortKey: any) => {
    sort.value = sortKey
}
</script>

<style>
.type-note {
    max-width: none !important;
    color: #3b82f6 !important;
}
</style>
