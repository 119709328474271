<template>
    <c-search-bar :data="BASIC_SEARCH" @changeItem="changeItem" @update:model-value="handleSearch" />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CSearchBar from '../../../../../shared-fe/components/c-search-bar/c-search-bar.vue'

const emit = defineEmits(['update:handleSearch'])
const props = defineProps({
    arrayValue: {
        type: Object,
        default: {},
    },
})
const dataModel = ref(<any>props.arrayValue)

const BASIC_SEARCH = computed(() => [
    {
        key: 'title',
        value: props.arrayValue?.title,
        label: 'Tìm email của SS...',
        placeholder: 'Tìm email của SS...',
        type: 'v-input',
        icon: 'search',
    },
])

const changeItem = (data) => {
    dataModel.value[data.key] = data.value
}

const handleSearch = (data) => {
    emit('update:handleSearch', data)
}
</script>

<style lang="scss" scoped></style>
