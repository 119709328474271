<template>
    <div class="px-13 pb-10" v-if="idSettings">
        <v-form v-model="formData" :fields="fields"></v-form>

        <div class="pt-8" v-if="!formLoading">
            <div class="inline-block">
                <v-button @click="save" class="button button-primary">Lưu thay đổi</v-button>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="text-primary text-2xl text-center">
            Đang khởi tạo dữ liệu ...
        </div>
    </div>
</template>

<script lang="ts" setup>
import {useFieldsCom} from "./field-composables";

const {
    fieldGroup,
    fieldInput,
    fieldSmallFile,
    save,
    formLoading,
    idSettings,
    formData,
} = useFieldsCom('on_boarding');

const groups = {
    target: "target",
    faq: "faq",
    policy: "policy",
    trick: "trick",
    footer: "footer",
}
const fields = [
    fieldGroup(groups.target, "Mục tiêu"),
    fieldInput('target_1', "Mục tiêu 1", {
        group: groups.target,
        interface: "input-rich-text-html",
        width: "half"
    }),
    fieldInput('target_2', "Mục tiêu 2", {
        group: groups.target,
        interface: "input-rich-text-html",
        width: "half"
    }),
    fieldInput('target_3', "Mục tiêu 3", {
        group: groups.target,
        interface: "input-rich-text-html",
        width: "half"
    }),
    fieldInput('target_4', "Mục tiêu 4", {
        group: groups.target,
        interface: "input-rich-text-html",
        width: "half"
    }),

    fieldSmallFile('target_bg', "Hình nền", {
        group: groups.target,
    }),
    fieldSmallFile('target_character', "Hình nền text", {
        group: groups.target,
        width: "half"
    }),
    fieldSmallFile('target_character_bg', "Hình nhân vật", {
        group: groups.target,
        width: "half"
    }),


    fieldGroup(groups.faq, "Câu hỏi thường gặp"),
    fieldInput('faq_title', "Tiêu đề", {
        group: groups.faq,
    }),
    fieldSmallFile('faq_img', "Hình ảnh", {
        group: groups.faq,
        width: "half",
    }),
    fieldSmallFile('faq_file', "File tải về", {
        group: groups.faq,
        width: "half",
    }),

    fieldGroup(groups.policy, "Quy định"),
    fieldInput('policy_title', "Tiêu đề", {
        group: groups.policy,
    }),
    fieldSmallFile('policy_img', "Hình ảnh", {
        group: groups.policy,
        width: "half",
    }),
    fieldSmallFile('policy_file', "File tải về", {
        group: groups.policy,
        width: "half",
    }),


    fieldGroup(groups.trick, "Mẹo thực hiện"),

    fieldInput('trick_title', "Tiêu đề", {
        group: groups.trick,
    }),
    fieldSmallFile('trick_img', "Hình ảnh", {
        group: groups.trick,
        width: "half",
    }),
    fieldSmallFile('trick_file', "File tải về", {
        group: groups.trick,
        width: "half",
    }),


    fieldGroup(groups.footer, "Chân trang"),
    fieldSmallFile('footer_logo', "Logo", {
        group: groups.footer,
        width: "half",
        note: "Sử dụng logo trong suốt"
    }),
    fieldSmallFile('footer_bg', "Hình nền", {
        group: groups.footer,
        width: "half",
        note: "Nên sử dụng nền tối"
    }),

    fieldInput('footer_title', "Tiêu đề", {
        group: groups.footer,
    }),

    fieldInput('footer_intro', "Mô tả", {
        group: groups.footer,
    }),
    fieldInput('footer_hotline', "Số hotline", {
        group: groups.footer,
        width: "half",
    }),
    fieldSmallFile('footer_file', "File tải về", {
        group: groups.footer,
        width: "half",
        note: "Có thể dùng file PDF"
    }),
]

defineExpose({
    save
})
</script>
