<template>
    <interview-table
        :items="items"
        :loading="loading"
        :totalPages="totalPages"
        :page="page"
        @update:toPage="handleToPage"
    />
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import { useInterviewDetailQuery } from '../../../../../shared-fe/services/interview-detail-service'
import { useItems } from '@directus/extensions-sdk'
import { INTERVIEW_DETAIL_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { RECORD_PER_PAGE } from '../../../../../shared-fe/utils/constant'
import InterviewTable from './interview-table.vue'
const props = defineProps({
    idCandidate: {
        type: Number,
        default: -1,
    },
})
const page = ref(1)
const { items, totalPages, loading } = useItems(ref(INTERVIEW_DETAIL_COLLECTION), {
    fields: ref([
        'content',
        'date_created',
        'user_created.full_name',
        'user_created.role.name',
        'status',
        'candidate_status',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort: ref(['date_created']),
    filter: ref({
        candidate: props.idCandidate,
    }),
    page: page,
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
}
</script>
