<template>
    <v-dialog v-model="isShow" @esc="closeDialogFunc" placement="center custom-width" :persistent="true">
        <v-card>
            <v-card-title>
                <div class="w-full h-full">
                    <div class="text-5 text-[#1E293B] font-bold mb-4">Chọn {{ labelUserType }} để thi:</div>
                </div>
            </v-card-title>
            <div class="px-28px">
                <div class="flex justify-between items-center w-full bg-[#F1F5F9] py-2 px-2">
                    <div class="check-all">
                        <v-checkbox label="Tất cả" :checked="isCheckedAll" @update:model-value="checkAllClick" />
                    </div>
                    <div class="text-14px text-[#94A3B8]">Đã chọn {{ totalDcr }}</div>
                </div>
                <div class="font-italic mt-3 mb-4 text-normal text-[#475569]">Vui lòng “bỏ chọn” nếu {{labelUserType}} không thi</div>
                <div class="max-h-217px overflow-y-scroll">
                    <div class="flex flex-row mb-3 items-center h-32px" v-for="(item, index) in arrDcrDisplay">
                        <div class="w-100px">
                            <div
                                class="bg-[#F1F5F9] text-[#64748B] hover:bg-[#FEE2E2] hover:text-[#EF4444] py-1 px-4 rounded-5px mr-3 text-center"
                                v-show="item.checked"
                                @click="
                                    () => {
                                        arrDcrDisplay[index].checked = !arrDcrDisplay[index].checked
                                    }
                                "
                            >
                                Bỏ chọn
                            </div>
                        </div>
                        <div class="">
                            <v-checkbox
                                :label="item.full_name"
                                :checked="arrDcrDisplay[index].checked"
                                @update:model-value="
                                    (e) => (arrDcrDisplay[index].checked = !arrDcrDisplay[index].checked)
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <v-card-actions>
                <v-button secondary @click="closeDialogFunc">huỷ bỏ</v-button>
                <v-button :disabled="totalDcr <= 0" :loading="loadingCreateExam" class="custom-btn" @click="nextAction">
                    Mở bài thi
                </v-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTrainingQuery } from '../../../../../shared-fe/services/training-service'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();
const labelUserType = computed(() => {
    return userStore?.currentUser?.label_user_type
})
const emit = defineEmits(['nextAction', 'cancelAction'])

const { getSessionTrainById } = useTrainingQuery()
const props = defineProps({
    isShowDialog: {
        type: Boolean,
        default: false,
    },
    loadingCreateExam: {
        type: Boolean,
        default: false,
    }
})
const isShow = ref(false)
watch(
    () => props.isShowDialog,
    () => {
        isShow.value = props.isShowDialog
    },
    {
        immediate: true,
    }
)
const route = useRoute()

const isCheckedAll = ref(false)
const nextAction = () => {
    isShow.value = false
    emit('nextAction', {
        _totalDcr: arrDcrDisplay.value,
        _dcrStartExam: arrDcrDisplay.value?.filter((x) => x.checked),
    })
}

const closeDialogFunc = () => {
    isShow.value = false
    emit('cancelAction')
}
const arrDcrDisplay = ref()

// lấy ds dcr trong sstrain và loại bỏ những thằng dcr đã có phòng thi.
getSessionTrainById(route.params?.session_train).then(
    (res: any) => {
        arrDcrDisplay.value = []
        let sstrain = res?.data?.data;
        if (sstrain?.length > 0) {
            if(sstrain[0].user?.length > 0){
                sstrain[0].user.forEach(x => {
                    if(!sstrain[0].exam_rooms.find(k => k.user.id === x.directus_users_id?.id)){
                        arrDcrDisplay.value.push({
                            ...x,
                            full_name: x.directus_users_id?.full_name,
                            id: x.directus_users_id?.id,
                            checked: false
                        })
                    }
                })
            }
        }
    }
)
const totalDcr = computed(() => {
    return arrDcrDisplay.value?.filter((x) => x.checked)?.length
})
watch(
    () => arrDcrDisplay.value,
    () => {
        isCheckedAll.value = arrDcrDisplay.value.filter((x) => x.checked)?.length === arrDcrDisplay.value.length
    },
    {
        deep: true,
    }
)
const checkAllClick = () => {
    isCheckedAll.value = !isCheckedAll.value
    if (isCheckedAll.value) {
        arrDcrDisplay.value?.map((x) => (x.checked = true))
    } else {
        arrDcrDisplay.value?.map((x) => (x.checked = false))
    }
}
</script>
<style lang="scss">
.check-all {
    .label {
        font-weight: bold;
    }
}
</style>
