import { LIVE_EDITOR } from './../types/table-type'
const SALARYS_LIST = [
    {
        text: '< 7 triệu',
        value: '1',
        callback: () => {
            return { price: { _lt: 7000000 } }
        },
    },
    {
        text: '7 - 10 triệu',
        value: '2',
        callback: () => {
            return { price: { _between: [7000000, 1000000] } }
        },
    },
    {
        text: '11 - 15 triệu',
        value: '3',
        callback: () => {
            return { price: { _between: [11000000, 15000000] } }
        },
    },
]

const FORMAT_TIME = {
    YYYMMDD: 'YYYY-MM-DD',
    DDMMYYYY: 'DD/MM/YYYY',
    MMYYYY: 'MM/YYYY',
    DDMMYYYYhhmmssA: 'DD/MM/YYYY - hh:mm:ss a',
    DDMMYYYYhmm: 'DD/MM/YYYY h:mm',
    DDMMYYYYhhmmss: 'DD-MM-YYYY hh:mm:ss',
    MMDDYYYY: 'MM-DD-YYYY',
}

const RESPONSE_STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
}

const RECORD_PER_PAGE = 10

const SUBMIT_EVENT = {
    RECRUITMENT_DETAIL_SUBMIT: 'recruitment-detail-submit',
    RECRUITMENT_REQUEST_DETAIL_SUBMIT: 'recruitment-request-detail-submit',
    CANDIDATE_DETAIL_SUBMIT: 'candidate-detail-submit',
    RESIGN_DETAIL_SUBMIT: 'resign-detail-submit',
    PROBATIONARY_ASSESSMENTS_SUBMIT: 'probationary-assessments-submit',
    DCR_DETAIL_SUBMIT: 'dcr-detail-submit',
    INTRODUCE_CANDIDATE_SUBMIT: 'introduce-candidate-submit',
    WSR_DETAIL_SUBMIT: 'wsr-detail-submit',
}

const MODE_ADD = 'add'
const MODE_EDIT = 'edit'
const RECRUITMENT_STATUS = {
    active: {
        text: 'Đang đăng tuyển',
        value: 'active',
        color: '#1D4ED8',
        background: '#DBEAFE',
    },
    inactive: {
        text: 'Hết hạn',
        value: 'inactive',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    archived: {
        text: 'Tạm ẩn',
        value: 'archived',
        color: '#070A13',
        background: '#F1F5F9',
    },
}

const NOT_FOUND_STATUS = {
    not_found: {
        text: 'Không tìm thấy trạng thái',
        value: 'not_found',
        background: '#efe6dd',
        color: '#654e34',
    },
}

const RECRUITMENT_REQUEST_STATUS = {
    pending: {
        text: 'Chờ duyệt',
        value: 'pending',
        color: '#1D4ED8',
        background: '#DBEAFE',
    },
    processing: {
        text: 'Đang tuyển',
        value: 'processing',
        color: '#D97706',
        background: '#FEF3C7',
    },
    completed: {
        text: 'Hoàn thành',
        value: 'completed',
        color: '#059669',
        background: '#D1FAE5',
    },
    rejected: {
        text: 'Đã từ chối',
        value: 'rejected',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    cancelled: {
        text: 'Đã huỷ',
        value: 'cancelled',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
}

const CANDIDATE_STATUS = {
    pendnot_passed_trial_perioding: {
        text: 'Không đạt thử việc',
        value: 'pendnot_passed_trial_perioding',
        color: '#B91C1C',
        background: '#fff',
    },
    passed_trial_period: {
        text: 'Đạt thử việc',
        value: 'passed_trial_period',
        color: '#059669',
        background: '#D1FAE5',
    },
    not_hired: {
        text: 'Không nhận việc',
        value: 'not_hired',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    hired: {
        text: 'Đã nhận việc',
        value: 'hired',
        color: '#1D4ED8',
        background: '#DBEAFE',
    },
    waiting_for_job: {
        text: 'Chờ nhận việc',
        value: 'waiting_for_job',
        color: '#D97706',
        background: '#FEF3C7',
    },
    not_passed_interview_3: {
        text: 'Không đạt PV 3',
        value: 'not_passed_interview_3',
        color: '#B91C1C',
        background: '#fff',
    },
    passed_interview_3: {
        text: 'Đạt PV 3',
        value: 'passed_interview_3',
        color: '#654E34',
        background: '#EEE6DD',
    },
    interview_3: {
        text: 'Phỏng vấn 3',
        value: 'interview_3',
        color: '#866846',
        background: '#fff',
    },
    not_passed_rr: {
        text: 'Không đạt RR',
        value: 'not_passed_rr',
        color: '#B91C1C',
        background: '#fff',
    },
    passed_rr: {
        text: 'Đạt RR',
        value: 'passed_rr',
        color: '#5B21B6',
        background: '#EDE9FE',
    },
    route_ride: {
        text: 'Route Ride',
        value: 'route_ride',
        color: '#6D28D9',
        background: '#fff',
    },
    not_passed_interview_2: {
        text: 'Không đạt PV 2',
        value: 'not_passed_interview_2',
        color: '#B91C1C',
        background: '#fff',
    },
    passed_interview_2: {
        text: 'Đạt PV 2',
        value: 'passed_interview_2',
        color: '#654E34',
        background: '#EEE6DD',
    },
    interview_2: {
        text: 'Phỏng vấn 2',
        value: 'interview_2',
        color: '#866846',
        background: '#fff',
    },
    not_passed_interview_1: {
        text: 'Không đạt PV 1',
        value: 'not_passed_interview_1',
        color: '#B91C1C',
        background: '#fff',
    },
    passed_interview_1: {
        text: 'Đạt PV 1',
        value: 'passed_interview_1',
        color: '#654E34',
        background: '#EEE6DD',
    },
    interview_1: {
        text: 'Phỏng vấn 1',
        value: 'interview_1',
        color: '#866846',
        background: '#fff',
    },
    applied: {
        text: 'Ứng tuyển',
        value: 'applied',
        color: '#207A7A',
        background: '#D7F5F5',
    },
    route_ride_result: {
        text: 'Kết quả Route Ride',
        value: 'route_ride_result',
        oclor: '#D97706',
        background: '#FEF3C7',
    },
}

const CANDIDATES_FILTER_PROFILE = {
    completed: {
        text: 'Đạt',
        value: 'completed',
        color: '#059669',
        background: '#D1FAE5',
    },
    incomplete: {
        text: 'Không đạt',
        value: 'incomplete',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    save_records: {
        text: 'Lưu hồ sơ',
        value: 'save_records',
        color: '#1D4ED8',
        background: '#D7F5F5',
    },
    not_suitable: {
        text: 'Không phù hợp',
        value: 'not_suitable',
        color: '#5B21B6',
        background: '#FEF3C7',
    },
}

const MODIFY_ONBOARD_STATUS = {
    completed_prescribed: {
        text: 'Hoàn thành theo quy định',
        value: 'completed_prescribed',
        color: '#059669',
        background: '#D1FAE5',
    },
    complete_progress: {
        text: 'Hoàn thành tiến độ',
        value: 'complete_progress',
        color: '#059669',
        background: '#D1FAE5',
    },
    do_one_part: {
        text: 'Thực hiện 1 phần',
        value: 'do_one_part',
        color: '#D97706',
        background: '#FEF3C7',
    },
    not_done: {
        text: 'Không thực hiện OB',
        value: 'not_done',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    progress_not_completed: {
        text: 'Không hoàn thành tiến độ',
        value: 'progress_not_completed',
        background: '#efe6dd',
        color: '#654e34',
    },
}

const RECRUITMENT_REQUEST_STEPS = [
    {
        stepNumber: 1,
        textDisplay: 'Tạo YCTD',
    },
    {
        stepNumber: 2,
        textDisplay: 'Phê duyệt',
    },
    {
        stepNumber: 3,
        textDisplay: 'Ứng tuyển',
    },
    {
        stepNumber: 4,
        textDisplay: 'Phỏng vấn 1',
    },
    {
        stepNumber: 5,
        textDisplay: 'Phỏng vấn 2',
    },
    {
        stepNumber: 6,
        textDisplay: 'Route ride',
    },
    {
        stepNumber: 7,
        textDisplay: 'Phỏng vấn 3',
    },
    {
        stepNumber: 8,
        textDisplay: 'Đóng YCTD',
    },
]

const USER_STATUS = {
    on: {
        text: 'On',
        value: 'on',
        color: '#059669',
        background: '#D1FAE5',
    },
    off: {
        text: 'Off',
        value: 'off',
        color: '#070A13',
        background: '#F1F5F9',
    },
    process_resign: {
        text: 'Process Resign',
        value: 'process_resign',
        color: '#D97706',
        background: '#FEF3C7',
    },
}

const PROBATIONARY_ASSESSMENTS_STATUS = {
    true: {
        text: 'Đạt',
        value: true,
        color: '#059669',
        background: '#D1FAE5',
    },
    false: {
        text: 'Không đạt',
        value: false,
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    null: {
        text: 'Chưa đánh giá',
        value: null,
        color: '#070A13',
        background: '#F1F5F9',
    },
}

const SESSIONS_TRAIN_STATUS = {
    in_progress: {
        text: 'Đang diễn ra',
        value: 'in_progress',
        color: '#D97706',
        background: '#FEF3C7',
    },
    completed: {
        text: 'Hoàn thành',
        value: 'completed',
        color: '#059669',
        background: '#D1FAE5',
    },
}

const STATUS_STEPS = {
    COMPLETED: 'completed',
    PROCESSING: 'processing',
}

const EXEMPTION_CASE = {
    e_learning: {
        text: 'E-learning',
        value: 'e_learning',
    },
    work_with: {
        text: 'Work with',
        value: 'work_with',
    },
}

const USER_ROLE = {
    SS: 'ss',
    ASM: 'asm',
    HR: 'hr',
    DCR: 'dcr',
    ADMIN_L1: 'adminl1',
    ADMIN_L2: 'adminl2',
    AGENCY: 'agency',
}

const USER_TYPE = {
    RETAIL: "retail",
    WSR: 'wsr'
}

const USER_TYPE_DATA = {
    [USER_TYPE.RETAIL]: { value: USER_TYPE.RETAIL, label: "Retail" },
    [USER_TYPE.WSR]: { value: USER_TYPE.WSR, label: "Wholesales Rep" },
}

const RESIGN_STATUS = {
    pending: {
        text: 'Chờ duyệt',
        value: 'pending',
        color: '#92400E',
        background: '#fff',
    },
    completed: {
        text: 'Đã xử lý',
        value: 'completed',
        color: '#059669',
        background: '#D1FAE5',
    },
    approved: {
        text: 'Đã duyệt',
        value: 'approved',
        color: '#1D4ED8',
        background: '#fff',
    },
    rejected: {
        text: 'Đã từ chối',
        value: 'rejected',
        color: '#B91C1C',
        background: '#fff',
    },
    cancelled: {
        text: 'Đã huỷ',
        value: 'cancelled',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    archived: {
        text: 'Đã xoá',
        value: 'archived',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
}

const LIST_TITLE_TAB = [
    {
        key: 1,
        title: 'Quy định chương trình',
    },
    {
        key: 2,
        title: 'Danh sách DCR cần huấn luyện',
    },
    {
        key: 3,
        title: 'Danh sách DCR đã và đang được huấn luyện',
    },
    {
        key: 4,
        title: 'Danh sách miễn trừ',
    },
    // {
    //     key: 5,
    //     title: 'DS yêu cầu mở thi',
    // },
]

const LIST_REPORT_TAB = [
    {
        key: 1,
        title: 'Thứ 7 Học Hay',
    },
    {
        key: 2,
        title: 'DCR Onboarding',
    },
    // {
    //     key: 3,
    //     title: 'Thư viện điện tử',
    // },
]

const LIST_TARGET_MANAGEMENT_TAB = [
    {
        key: 1,
        title: 'Quản lý chỉ tiêu theo năm',
        icon: 'person',
    },
    {
        key: 2,
        title: 'Quản lý chỉ tiêu theo SS',
        icon: 'people',
    },
]

const LIVE_EDITOR = {
    LD: 'ld',
    CONSTANTS_IMAGE: 'constants_image',
    SATURDAY_LEARNING: 'saturday_learning',
    PDF: 'pdf',
}

const INTERVIEW_DETAIL_STATUS = {
    interview_1: {
        text: 'Phỏng vấn 1',
        value: 'interview_1',
        color: '#654E34',
        background: '#EEE6DD',
    },
    interview_2: {
        text: 'Phỏng vấn 2',
        value: 'interview_2',
        color: '#654E34',
        background: '#EEE6DD',
    },
    interview_3: {
        text: 'Phỏng vấn 3',
        value: 'interview_3',
        color: '#654E34',
        background: '#EEE6DD',
    },
    waiting_for_job: {
        text: 'Chờ nhận việc',
        value: 'waiting_for_job',
        oclor: '#D97706',
        background: '#FEF3C7',
    },
    route_ride: {
        text: 'Route Ride',
        value: 'route_ride',
        color: '#5B21B6',
        background: '#EDE9FE',
    },
    route_ride_result: {
        text: 'Kết quả Route Ride',
        value: 'route_ride_result',
        oclor: '#D97706',
        background: '#FEF3C7',
    },
    true: {
        text: 'Đạt',
        value: true,
        color: '#059669',
        background: '#D1FAE5',
    },
    false: {
        text: 'Không đạt',
        value: false,
        color: '#B91C1C',
        background: '#FEE2E2',
    },
}
const RESIGN_HISTORY_STATUS = {
    created: {
        text: 'Tạo đơn',
        value: 'created',
        color: '#92400E',
        background: '#fff',
    },
    completed: {
        text: 'Đã phỏng vấn',
        value: 'completed',
        color: '#059669',
        background: '#D1FAE5',
    },
    approved: {
        text: 'Đã duyệt',
        value: 'approved',
        color: '#1D4ED8',
        background: '#fff',
    },
    rejected: {
        text: 'Đã từ chối',
        value: 'rejected',
        color: '#B91C1C',
        background: '#fff',
    },
    cancelled: {
        text: 'Đã huỷ',
        value: 'cancelled',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
}

const EXEMPTION_CERTIFICATE_STATUS = {
    waiting: {
        text: 'Chờ duyệt',
        value: 'waiting',
        color: '#D97706',
        background: '#FEF3C7',
    },
    l1_approved: {
        text: 'Đã duyệt Admin L1',
        value: 'l1_approved',
        color: '#1D4ED8',
        background: '#DBEAFE',
    },
    completed: {
        text: 'Hoàn thành',
        value: 'completed',
        color: '#059669',
        background: '#D1FAE5',
    },
    reject: {
        text: 'Từ chối',
        value: 'reject',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
}

const EXAM_RESULT_STATUS = {
    completed: {
        text: 'Đạt',
    },
    incomplete: {
        text: 'K.Đạt',
    },
}

const EXAM_RESULT_REPORT_STATUS = {
    completed: {
        text_1: 'Hoàn thành',
        text_2: 'Hoàn thành',
    },
    incomplete: {
        text_1: 'Chưa hoàn thành',
        text_2: 'Không hoàn thành',
    },
    passed: {
        text: 'Đạt',
    },
    not_passed: {
        text: 'Không Đạt',
    },
}

const EXAM_RESULT_VALID = {
    valid: 'Hợp lệ',
    invalid: 'Không hợp lệ',
}

const MODULE_VALIDATE = {
    VALID: {
        text: 'Hợp lệ',
    },
    INVALID: {
        text: 'Không hợp lệ',
    },
}

const RESIGN_HISTORY_REASON = [
    {
        text: 'Nhân viên rút đơn nghỉ việc',
        value: 'employee_withdraws_resignation',
    },
    {
        text: 'Đơn nghỉ việc sai thông tin',
        value: 'incorrect_information',
    },
    {
        text: 'Lý do khác',
        value: 'other_reason',
    },
]

const EXAM_STATUS = {
    PASS: 'completed',
    FAIL: 'incomplete',
}

const DELETE_VALUE = 'archived'

const PROCESS_TRAIN_STEP_STATUS = {
    passed: {
        text: 'Thi đạt',
        value: 'passed',
        color: '#059669',
        icon: 'check_circle_outline',
    },
    failed: {
        text: 'Thi rớt',
        value: 'failed',
        color: '#B91C1C',
        icon: 'do_disturb_alt',
    },
    taking_exams: {
        text: 'Đang thi',
        value: 'taking_exams',
        color: '#1D4ED8',
        icon: 'run_circle',
    },
    untrained: {
        text: 'Chưa đào tạo',
        value: 'untrained',
        color: '#070A13',
        icon: 'pending',
    },
}
const TOTAL_PROCESS_TRAIN = {
    completed: {
        text: 'Đã hoàn thành',
        value: 'completed',
        color: '#207A7A',
        background: '#D7F5F5',
    },
    incomplete: {
        text: 'Chưa hoàn thành',
        value: 'incomplete',
        color: '#B91C1C',
        background: '#FEE2E2',
    },
    not_making_progress: {
        text: 'Không đạt tiến độ',
        value: 'not_making_progress',
        color: '#92400E',
        background: '#FFE7CC',
    },
}
const EXAM_ROOM_STATUS = {
    OPENED: 'opened',
    CLOSED: 'closed',
}

const LOCAL_STOREGE = {
    ONBOARDING: 'onboarding',
    SATURDAY_LEARN: 'saturday_learn',
}

const EMPLOYMENT_STATUS = {
    ON: 'on',
    OFF: 'off',
    PROCESS_RESIGN: 'process_resign',
}

const SPECIFIC_DAYS = ['08-12', '08-14']
const MAXIMUN_DATE = '9999-12-01'

const MONTH_SELECT = [
    {
        text: 'Tháng 1',
        value: '1',
    },
    {
        text: 'Tháng 2',
        value: '2',
    },
    {
        text: 'Tháng 3',
        value: '3',
    },
    {
        text: 'Tháng 4',
        value: '4',
    },
    {
        text: 'Tháng 5',
        value: '5',
    },
    {
        text: 'Tháng 6',
        value: '6',
    },
    {
        text: 'Tháng 7',
        value: '7',
    },
    {
        text: 'Tháng 8',
        value: '8',
    },
    {
        text: 'Tháng 9',
        value: '9',
    },
    {
        text: 'Tháng 10',
        value: '10',
    },
    {
        text: 'Tháng 11',
        value: '11',
    },
    {
        text: 'Tháng 12',
        value: '12',
    },
]

const CONFIRM_MODAL_TEXT = {
    RECRUITMENT_REQUEST: {
        CANCEL: 'Bạn chắc chắn <span style="font-weight: bold;">Huỷ</span> yêu cầu tuyển dụng này?',
        REJECT: 'Bạn chắc chắn <span style="font-weight: bold;">Từ chối</span> yêu cầu tuyển dụng này?',
        OK: 'Bạn chắc chắn <span style="font-weight: bold;">Đồng ý</span> yêu cầu tuyển dụng này?',
        CREATE_ROUTE_NAME: 'Mã route chưa tổn tại trong hệ thống. Bạn có chắc muốn tạo mới?'
    },
    RESIGN: {
        CANCEL: 'Bạn chắc chắn <span style="font-weight: bold;">Huỷ</span> đơn nghỉ việc này?',
        REJECT: 'Bạn chắc chắn <span style="font-weight: bold;">Từ chối</span> đơn nghỉ việc này?',
        OK: 'Bạn chắc chắn <span style="font-weight: bold;">Đồng ý</span> đơn nghỉ việc này?',
    },
    INTERVIEW_1: {
        REJECT: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Không đạt</span> phỏng vấn 1?',
        OK: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Đạt</span> phỏng vấn 1?',
    },
    INTERVIEW_2: {
        REJECT: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Không đạt</span> phỏng vấn 2?',
        OK: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Đạt</span> phỏng vấn 2?',
    },
    ROUTE_RIDE: {
        REJECT: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Không đạt</span> Route Ride?',
        OK: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Đạt</span> Route Ride?',
        COMPLETE: 'Đã hoàn thành bài học Route Ride\n Vui lòng ấn Xác nhận để kết thúc',
        CONFIRM: 'Đã hoàn thành bài học Route Ride',
        CONTINUE_WATCH_VIDEO: '<span style="font-weight: bold;">Thời gian đếm ngược sẽ bị ngừng</span> \nBạn vui lòng quay lại để tiếp tục bài giảng'
    },
    INTERVIEW_3: {
        REJECT: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Không đạt</span> phỏng vấn 3?',
        OK: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Đạt</span> phỏng vấn 3?',
    },
    GET_THE_JOB: {
        REJECT: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Không nhận việc</span>?',
        OK: 'Bạn chắc chắn ứng viên <span style="font-weight: bold;">Đã nhận việc</span>?',
    },
    PROBATIONARY_ASSESSMENTS: {
        REJECT: 'Bạn chắc chắn DCR <span style="font-weight: bold;">Không đạt</span> thử việc?',
        OK: 'Bạn chắc chắn DCR <span style="font-weight: bold;">Đạt</span> thử việc?',
    },
    CHANGE_CHANNEL: {
        CONFIRM: 'Bạn chắc chắn muốn chuyển kênh đào tạo. Nếu xác nhận toàn bộ dữ liệu sẽ bị xoá'
    }
}

const RECORD_IMPORT_NUMBER = 10
const CHANNEL = {
    ON: {
        value: 'on',
        text: 'online'
    },
    OFF: {
        value: 'off',
        text: 'offline'
    }
}

const LESSON_CHANNEL = {
    ON: 'on',
    OFF: 'off',
}

const ENABLE_TO_TEST = ['admin', 'cmc', 'test']

const RAW_MODIFY_ONBOARD_STATUS = {
    completed_prescribed: {
        text: ['hoan thanh theo quy dinh'],
        value: 'completed_prescribed',
    },
    complete_progress: {
        text: ['hoan thanh tien do'],
        value: 'complete_progress',
    },
    do_one_part: {
        text: ['thuc hien 1 phan', 'thuc hien mot phan'],
        value: 'do_one_part',
    },
    not_done: {
        text: ['khong thuc hien ob', 'khong thuc hien'],
        value: 'not_done',
    },
    progress_not_completed: {
        text: ['khong hoan thanh tien do'],
        value: 'progress_not_completed',
    },
}

const RESULT_TRAINING_WW_AND_OB = {
    completed_prescribed: {
        interface: 'Hoàn thành theo quy định',
        value: 'completed_prescribed',
    },
    complete_progress: {
        interface: 'Hoàn thành tiến độ',
        value: 'complete_progress',
    },
    do_one_part: {
        interface: 'Thực hiện 1 phần',
        value: 'do_one_part',
    },
    progress_not_completed: {
        interface: 'Không hoàn thành tiến độ',
        value: 'progress_not_completed',
    },
    not_done: {
        interface: 'Không thực hiện',
        value: 'not_done',
    },

}
const TYPE_OF_TRAIN = {
    OB: 'ob',
    T7HH: 't7hh'
}

const COURSE_BY_USER_TYPE = {
    OB: 'course_ob',
    T7HH: 'course_t7hh'
}




export {
    MODULE_VALIDATE,
    EXAM_RESULT_REPORT_STATUS,
    EXAM_RESULT_STATUS,
    SALARYS_LIST,
    RECORD_PER_PAGE,
    FORMAT_TIME,
    SUBMIT_EVENT,
    MODE_ADD,
    MODE_EDIT,
    RECRUITMENT_STATUS,
    RECRUITMENT_REQUEST_STATUS,
    NOT_FOUND_STATUS,
    RECRUITMENT_REQUEST_STEPS,
    STATUS_STEPS,
    CANDIDATE_STATUS,
    USER_STATUS,
    USER_ROLE,
    RESIGN_STATUS,
    PROBATIONARY_ASSESSMENTS_STATUS,
    LIST_TITLE_TAB,
    RESPONSE_STATUS,
    SESSIONS_TRAIN_STATUS,
    LIVE_EDITOR,
    INTERVIEW_DETAIL_STATUS,
    RESIGN_HISTORY_REASON,
    RESIGN_HISTORY_STATUS,
    DELETE_VALUE,
    PROCESS_TRAIN_STEP_STATUS,
    EXAM_STATUS,
    EXAM_ROOM_STATUS,
    TOTAL_PROCESS_TRAIN,
    EXEMPTION_CERTIFICATE_STATUS,
    EXEMPTION_CASE,
    LIST_REPORT_TAB,
    LOCAL_STOREGE,
    SPECIFIC_DAYS,
    MAXIMUN_DATE,
    EMPLOYMENT_STATUS,
    CANDIDATES_FILTER_PROFILE,
    EXAM_RESULT_VALID,
    LIST_TARGET_MANAGEMENT_TAB,
    MONTH_SELECT,
    CONFIRM_MODAL_TEXT,
    RECORD_IMPORT_NUMBER,
    LESSON_CHANNEL,
    ENABLE_TO_TEST,
    CHANNEL,
    MODIFY_ONBOARD_STATUS,
    RAW_MODIFY_ONBOARD_STATUS,
    RESULT_TRAINING_WW_AND_OB,
    TYPE_OF_TRAIN,
    COURSE_BY_USER_TYPE,
    USER_TYPE,
    USER_TYPE_DATA
}
