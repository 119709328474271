<template>
    <c-role>
        <private-view title="Thông tin đào tạo - Thứ 7 học hay">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <template #title>
                <div class="type-title leading-[28px] mb-4">
                    <div>{{ PATH_ROUTER.LEARNING_REPORT.VALUE }}</div>
                    <div class="text-[#475569] text-xs font-normal mt-1">
                        Chương trình đào tạo dành cho đội ngũ {{ labelUserType }} tại nhà phân phối
                    </div>
                </div>
            </template>
            <template v-if="keyNumber === 2" #actions>
                <span class="custom-button-download-template">
                    <c-download-button :file_id="templateFile">
                        <v-icon name="file_download" />
                        <span class="ml-2">Tải file mẫu</span>
                    </c-download-button>
                </span>
            </template>
            <!-- navigation end  -->

            <div class="w-full py-10">
                <div class="min-w-[700px] mb-10">
                    <div v-if="!uploading.uploading" class="w-full flex flex-wrap justify-center">
                        <div v-for="(item, index) in menuList" class="w-[234px]">
                            <button
                                :key="index"
                                @click="openTab(item.key)"
                                :class="
                                    keyNumber === item.key
                                        ? `bg-[#1E4BA1] text-white tab-button-${item.key}`
                                        : `tab-button-${item.key} bg-white text-[#64748B]`
                                "
                                class="border h-full border-solid py-3 font-medium border-[#CBD5E1] px-4 w-full text-sm"
                            >
                                {{ item.title }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="w-full" v-if="keyNumber === 1">
                    <saturday-learning-report></saturday-learning-report>
                </div>
                <div class="w-full" v-if="keyNumber === 2">
                    <onboarding-learning-report></onboarding-learning-report>
                </div>
                <div class="w-full" v-if="keyNumber === 3"></div>
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin đào tạo - Thứ 7 Học Hay" close />
            </template>
        </private-view>
    </c-role>
</template>
<script setup lang="ts">
import { ref, toRefs, computed } from 'vue'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import { LIST_REPORT_TAB } from '../../../../shared-fe/utils/constant'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import SaturdayLearningReport from './saturday-learning-report/saturday-learning-report.vue'
import OnboardingLearningReport from './onboarding-learning-report/onboarding-learning-report.vue'
import { useStores } from '@directus/composables'
import { useStoreLoading } from '@/shared-fe/stores/loading'
import CDownloadButton from '@/shared-fe/components/c-download-button/c-download-button.vue'
import { useSettingQuery } from '@/shared-fe/services/setting-service'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})

const menuList = computed(() => {
    return LIST_REPORT_TAB.map(x => {
        return {
            ...x, 
            title: x.title.replace("DCR", labelUserType.value)
        }
    })
})

const isName = '2'
const keyNumber = ref(1)
const { useAppStore } = useStores()
const { uploading } = useStoreLoading()
const { getProjectSetting } = useSettingQuery()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
const templateFile = ref('')
sidebarOpen.value = false

getProjectSetting().then((res) => {
    if (res?.data?.data) {
        templateFile.value = res?.data?.data?.file_template_ww
    }
})

const openTab = (key) => {
    keyNumber.value = key
}
</script>
