<template>
    <c-role>
        <private-view title="Thông tin đào tạo - Thứ 7 học hay">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <template #title>
                <div class="type-title leading-[28px] mb-4">
                    <div>Thứ 7 học hay</div>
                    <div class="text-[#475569] text-xs font-normal mt-1">
                        Chương trình đào tạo dành cho đội ngũ {{ labelUserType }} tại nhà phân phối
                    </div>
                </div>
            </template>
            <!-- navigation end  -->
            <!-- header start -->
            <div class="w-full">
                <div
                    class="tab-header w-full min-h-[380px] h-full flex xl:flex-row flex-col justify-center items-start xl:px-28 xl:py-16 px-10 px-5 mt-[-24px]"
                    :style="{ 'background-image': `url(${imageData?.saturday_learning_image?.[0]})` }"
                >
                    <div class="xl:w-1/2 w-full flex justify-center items-center xl:mt-0 mt-5 mr-22"></div>
                    <div
                        class="xl:w-1/2 md:w-2/3 w-full flex flex-col justify-center xl:items-start items-center mx-auto text-[#1E293B]"
                    >
                        <h5 class="font-bold xl:text-[32px] text-2xl xl:mt-0 mt-3 leading-10 mb-5">
                            {{ course?.title }}
                        </h5>
                        <p class="font-normal text-base leading-6 text-[#475569]">
                            {{ course?.description }}
                        </p>
                        <span class="font-normal icon-size my-8 leading-5 text-[#1939AE]">
                            <v-icon name="play_circle" />
                            {{ course?.total_topic }} bài học
                        </span>
                    </div>
                </div>
                <div class="bg-[#374151] flex w-full flex-row justify-center items-center gap-8">
                    <button
                        class="success text-[18px] leading-6 text-center pt-4 pb-5 px-4"
                        @click="openTab(1)"
                        :class="
                            keyNumber === 1
                                ? 'text-white border-b-4 border-b-[#67C2C3] border-b-solid'
                                : 'text-[#E2E8F0]'
                        "
                    >
                        Tổng quan
                    </button>
                    <button
                        @click="openTab(2)"
                        class="success text-[18px] leading-6 text-center pt-4 pb-5 px-4"
                        :class="
                            keyNumber === 2
                                ? 'text-white border-b-4 border-b-[#67C2C3] border-b-solid'
                                : 'text-[#E2E8F0]'
                        "
                    >
                        Khung chương trình
                    </button>
                </div>
                <div v-if="keyNumber === 1">
                    <home-overview></home-overview>
                </div>
                <div v-if="keyNumber === 2">
                    <module-list :lstModule="modules" @changeChannel="changeChannel" :channelId="channelId" />
                </div>
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin đào tạo - Thứ 7 Học Hay" close />
            </template>
        </private-view>
    </c-role>
</template>
<script setup lang="ts">
import { ref, computed, toRefs } from 'vue'
import { useTrainingQuery } from '../../../../shared-fe/services/training-service'
import { useConstantsImage } from '../../../../shared-fe/stores/constants-image'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import ModuleList from './components/module-list.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import HomeOverview from './components/home-overview.vue'
import { useStores } from '@directus/composables'
import { useUserStore } from '@/stores/user'
import { TYPE_OF_TRAIN } from '@/shared-fe/utils/constant'

const modules = ref([])
const course = ref(<any>{})
const isName = '2'
const keyNumber = ref(1)
const { getModulesByChannelId } = useTrainingQuery()
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
const userStore = useUserStore();
const channelId = ref('');

const imageStore = useConstantsImage()
const channels = ref(userStore?.currentUser[TYPE_OF_TRAIN.T7HH]);
const imageData = computed(() => {
    return { ...imageStore.saturdayLearningImage }
})
const labelUserType = computed(() => {
    return userStore?.currentUser.label_user_type
})

const changeChannel = (id) => {
    course.value = {}
    modules.value = []
    channelId.value = id
    getModulesByChannelId(id).then((_modules) => {
        course.value = _modules?.data?.result.courses
        if (course.value?.topics?.length > 0) {
            modules.value = course.value?.topics
            course.value['total_topic'] = modules.value?.length
        }
    });
}

const openTab = (key) => {
    keyNumber.value = key
}

const getModules = (id) => {
    course.value = {}
    modules.value = []
    channelId.value = id
    getModulesByChannelId(id).then((_modules) => {
        course.value = _modules?.data?.result.courses
        if (course.value?.topics?.length > 0) {
            modules.value = course.value?.topics
            course.value['total_topic'] = modules.value?.length
        }
    });
}

if(channels.value.length > 0){
    getModules(channels.value[0].id)
}


</script>
