<template>
    <div class="px-13 pb-10" v-if="idSettings">
        <v-form primary-key="1" v-model="formData" :fields="fields"></v-form>

        <div class="pt-8" v-if="!formLoading">
            <div class="inline-block">
                <v-button @click="save" class="button button-primary">Lưu thay đổi</v-button>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="text-primary text-2xl text-center">Đang khởi tạo dữ liệu ...</div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { useFieldsCom } from './field-composables'
import { useApi } from '@directus/composables'
import { Ref } from 'vue'

const {
    fieldGroup,
    fieldInput,
    fieldSmallFile,
    fieldInputNumber,
    save,
    fieldRepeater,
    formLoading,
    idSettings,
    formData,
} = useFieldsCom('online_library', {
    group: {
        headerColor: '#f59e0b',
    },
})
const categories: Ref<any[]> = ref([])
const api = useApi()
api.get('/items/post_categories', {
    params: {
        filter: {
            parent_id: {
                _null: true,
            },
        },
    },
}).then(async (res) => {
    const model = res.data?.data
    categories.value = model.map((x) => {
        return {
            text: x?.name,
            value: `${x?.id}-${x?.name}`,
        }
    })
})

const categoriesChoices = computed(() => {
    if (formData.value?.category_reorder && formData.value?.category_reorder?.length > 0) {
        const categoriesData = formData.value.category_reorder
        let categoriesDoesNotExistInList = categories.value.filter(
            (item: any) => !categoriesData.find((x) => item.value === x.category)
        )
        return categoriesDoesNotExistInList
    }
    return categories.value
})

const groups = {
    supportOnlineLibrary: 'supportOnlineLibrary',
    bannerLib: 'bannerLib',
    area: 'area',
}
const fields = computed(() => {
    return [
        fieldGroup(groups.supportOnlineLibrary, 'Hỗ trợ'),

        fieldInput('title', 'Tiêu đề', {
            group: groups.supportOnlineLibrary,
            width: 'col-span-2',
        }),
        fieldInput('detail', 'Chi tiết', {
            group: groups.supportOnlineLibrary,
            width: 'col-span-2',
        }),

        fieldInput('detail_first', 'Cách 1', {
            group: groups.supportOnlineLibrary,
            interface: 'input-rich-text-html',
        }),
        fieldInput('detail_second', 'Cách 2', {
            group: groups.supportOnlineLibrary,
            interface: 'input-rich-text-html',
        }),
        fieldInput('detail_third', 'Cách 3', {
            group: groups.supportOnlineLibrary,
            interface: 'input-rich-text-html',
        }),
        fieldInput('detail_forth', 'Cách 4', {
            group: groups.supportOnlineLibrary,
            interface: 'input-rich-text-html',
        }),
        fieldInputNumber('number_of_video_records', 'Số lượng video trang thư viện điện tử', {
            group: groups.supportOnlineLibrary,
        }),
        fieldInputNumber('number_of_featured_records', 'Số lượng nội dung nổi bật', {
            group: groups.supportOnlineLibrary,
        }),
        fieldInput('user_manual_link', 'Đường link tới trang HDSD', {
            group: groups.supportOnlineLibrary,
            note: 'Nếu là đường link tới trang HDSD thì sẽ nhập vào đây. Nếu không có đường dẫn mà là file thì thêm ở dưới',
            width: 'col-span-2',
        }),
        fieldSmallFile('user_manual', 'File HDSD', {
            group: groups.supportOnlineLibrary,
            note: 'Nếu là file HDSD thì sẽ nhập vào đây',
            width: 'col-span-2',
        }),
        fieldGroup(groups.area, 'Bộ phận đào tạo'),

        fieldRepeater('area_reorder', 'Danh sách khu vực', {
            group: groups.area,
            width: 'col-span-2',
            options: {
                template: '{{ area_name }} : {{ phone_number_area }}',
                fields: [
                    {
                        field: 'area_name',
                        name: 'area_name',
                        type: 'string',
                        meta: {
                            field: 'area_name',
                            width: 'half',
                            type: 'string',
                            interface: 'input',
                        },
                    },
                    {
                        field: 'phone_number_area',
                        name: 'phone_number_area',
                        type: 'string',
                        meta: {
                            field: 'phone_number_area',
                            width: 'half',
                            type: 'string',
                            interface: 'input',
                        },
                    },
                ],
            },
        }),

        fieldGroup(groups.bannerLib, 'Hình ảnh banner'),
        fieldSmallFile('banner_online_library', 'Banner thư viện điện tử', {
            group: groups.bannerLib,
            width: 'col-span-2',
            note: '',
        }),
        fieldSmallFile('banner_home', 'Banner trang Home', {
            group: groups.bannerLib,
            width: 'col-span-2',
            note: '',
        }),
        fieldRepeater('category_reorder', 'Thứ tự danh mục', {
            group: groups.supportOnlineLibrary,
            width: 'col-span-2',
            options: {
                fields: [
                    {
                        field: 'category',
                        name: 'Danh mục',
                        meta: {
                            field: 'category',
                            width: 'full',
                            type: 'string',
                            interface: 'select-dropdown',
                            options: {
                                choices: [...categoriesChoices.value],
                            },
                        },
                    },
                ],
            },
        }),
    ]
})

defineExpose({
    save,
})
</script>
