<template>
    <c-role>
        <private-view title="Cài đặt module tuyển dụng">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <template #title>
                <div class="type-title flex items-justify">
                    <div>Cài đặt module tuyển dụng</div>
                </div>
            </template>

            <template #actions>
                <v-button :loading="saving" v-tooltip.bottom="'Lưu thay đổi'" icon rounded @click="handleSave">
                    <v-icon name="check" />
                </v-button>
                <!--  -->
            </template>

            <div class="px-13 mb-4 flex gap-4 headTabs pb-8">
                <a
                    @click="module = 'career-site'"
                    :class="module === 'career-site' ? 'active' : ''"
                    class="border rounded-lg px-4 py-2 inline-block bg-gray-100 font-bold"
                    href="javascript:"
                >
                    Website tuyển dụng
                </a>
                <a
                    @click="module = 'hr-setting'"
                    :class="module === 'hr-setting' ? 'active' : ''"
                    class="border rounded-lg px-4 py-2 inline-block bg-gray-100 font-bold"
                    href="javascript:"
                >
                    Cài đặt chung
                </a>
            </div>

            <career-site-form
                ref="settingForm"
                @startLoad="saving = true"
                @endLoad="saving = false"
                v-if="module === 'career-site'"
            ></career-site-form>
            <general-setting
                ref="generalSetting"
                @startLoad="saving = true"
                @endLoad="saving = false"
                v-if="module === 'hr-setting'"
            ></general-setting>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import careerSiteForm from './components/career-site-form.vue'
import GeneralSetting from './components/general-setting.vue'

const settingForm = ref(null)
const generalSetting = ref(null)
const module = ref('career-site')

const saving = ref(false)

const handleSave = () => {
    switch (module.value) {
        case 'career-site':
            settingForm.value?.save()
            break
        case 'hr-setting':
            generalSetting.value?.save()
            break
        default:
            break
    }
}
</script>

<style>
.headTabs a.active {
    background: var(--primary);
    color: white;
}
</style>
