<template>
    <div v-if="!uploadState">
        <div class="w-[500px] bg-white h-[185px]">
            <c-upload @input="uploaded" @error="handleError"></c-upload>
        </div>
        <span class="message-error text-xs" v-if="errors.length > 0">
            Tải lên không thành công. File tải không hợp lệ.
        </span>
        <div class="text-xs" v-for="error in errors">
            <span class="message-error text-xs" v-if="error?.row !== 0">DÒNG {{ error.row }}:&nbsp;</span>
            <span v-if="error?.row !== 0">{{ error.message.join(' ; ') }}</span>
        </div>
        <div
            v-if="totalDcrSuccess > 0"
            class="mt-5 py-[18px] px-4 border-l-4 border-t-0 border-r-0 border-b-0 border-[#399E9F] border-solid bg-[#EAFAF6] w-full text-[#399E9F]"
        >
            <span class="mr-2 py-1 border border-solid rounded-full">
                <v-icon class="" outlined name="check"></v-icon>
            </span>
            <span class="mr-4">Đã tải lên {{ totalDcrSuccess }} DCR hợp lệ</span>
        </div>
        <div v-if="totalDcrSuccess > 0" class="mt-8 w-full flex justify-center">
            <span class="custom-button-import">
                <v-button class="" outlined @click="submit">
                    <span class="text-[#5BABAC]">Xác nhận tải lên</span>
                </v-button>
            </span>
        </div>
    </div>
    <div v-if="uploadState" class="w-[500px] bg-white h-[185px]">
        <c-upload-progress :total="totalDcrSuccess" title="Nhập file kết quả"></c-upload-progress>
    </div>
    <c-dialog-upload-success
        :is-show="isShowDialogSuccess"
        @close="isShowDialogSuccess = false"
        :total="totalDcrSuccess"
    ></c-dialog-upload-success>
</template>
<script setup lang="ts">
import CUpload from '@/shared-fe/components/c-upload/c-upload.vue'
import CUploadProgress from '@/shared-fe/components/c-upload-progress/c-upload-progress.vue'
import { Ref } from 'vue'
import { ref } from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import CDialogUploadSuccess from '@/shared-fe/components/c-dialog-upload-success/c-dialog-upload-success.vue'
import { useModifyOnboardValidate } from '../composables/useModifyOnboardValidate'
import { useModifyOnboard } from '../composables/useModifyOnboard'
import { useStoreLoading } from '@/shared-fe/stores/loading'
import { watch } from 'vue'

const errors: Ref<any[]> = ref([])
const uploadState: Ref<boolean> = ref(false)
const isShowDialogSuccess = ref(false)
const rawData = ref(<any[]>[])
const validData = ref(<any[]>[])
const totalDcrSuccess = ref(0)
const { messageLabels, validate } = useModifyOnboardValidate()
const { importModifyOnboardData, parseDataResultToKey } = useModifyOnboard()
const progressing = useStoreLoading()

const uploaded = (file: any) => {
    if (file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
            errors.value = []
            totalDcrSuccess.value = 0
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, { type: 'array', cellDates: true })

            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            let parseData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: '',
            })

            parseData.shift()
            if (parseData.length === 0) {
                handleError()
                return
            }
            parseData.map((arrayData: any, index: number) => {
                let obj = {
                    dcr_code: arrayData[0].trim(),
                    full_name: arrayData[1],
                    route_code: arrayData[2],
                    work_with: arrayData[3],
                    elearning: arrayData[4],
                    duplicate: arrayData[5],
                    result: parseDataResultToKey(arrayData[6]),
                }
                rawData.value.push(obj)
                let { error, data } = validate(obj)
                if (error) {
                    errors.value.push({
                        row: index + 2,
                        message: data,
                    })
                } else {
                    validData.value.push(obj)
                }
            })
            totalDcrSuccess.value = validData.value.length
        }

        reader.readAsArrayBuffer(file)
    }
}

const handleShowError = (error: string, row: number) => {
    if (!error) {
        return
    }

    errors.value.push({
        row: row + 1,
        message: [error],
    })
}

const handleError = () => {
    errors.value = [
        {
            row: 0,
            message: ['error'],
        },
    ]
}

const submit = async () => {
    if (validData.value.length > 0) {
        uploadState.value = true
        progressing.handleProgressing(0)
        const res = await importModifyOnboardData(validData.value)
        if (res) {
            res.errors.map((error) => {
                let errorArr = error.split('||')
                if (errorArr.length > 1) {
                    let dcrCode = errorArr[0]
                    let index = rawData.value.findIndex((x: any) => x.dcr_code === dcrCode)
                    let message = errorArr[1]
                    Object.keys(messageLabels).map((key: string) => {
                        if (message && message.includes(key)) {
                            message = message.replace(key, messageLabels[key] || '')
                        }
                    })
                    let findRow = errors.value.find((x) => x.row === index + 1)
                    if (!findRow) {
                        handleShowError(message, index)
                    }
                }
            })
            totalDcrSuccess.value = res.success
        }
        isShowDialogSuccess.value = true
        uploadState.value = false
    }
}

watch(
    () => isShowDialogSuccess.value,
    () => {
        if (isShowDialogSuccess.value) {
            setTimeout(() => {
                isShowDialogSuccess.value = false
            }, 3000)
        }
    }
)
</script>

<style lang="scss">
.custom-button-import {
    position: relative;
    .button {
        border-radius: 0.5rem !important;
        border-color: var(--primary) !important;
        background: transparent !important;
        color: var(--primary) !important;

        &:hover {
            border-color: var(--primary) !important;
            background: transparent;
            color: var(--primary);
        }
    }
}
</style>
