<template>
    <c-role>
        <private-view title="Đánh giá thử việc">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <!-- navigation end  -->
            <!-- header start -->
            <template #actions>
                <c-access :roles="[USER_ROLE.HR]">
                    <span class="custom-button-export-excel">
                        <v-button secondary @click="exportData" :loading="_export.loadingExcel.value">
                            <v-icon name="file_upload" outline />
                            <span class="inline-block ml-2">
                                {{ EXPORT_EXCEL }}
                            </span>
                        </v-button>
                    </span>
                </c-access>
                <!-- <c-access :roles="[]">
                    <v-dialog v-if="modelSelected.length > 0" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access> -->
            </template>
            <!-- header end -->
            <div class="mx-9">
                <!-- searchbar start -->
                <probationary-assessments-searchbar
                    :arrayValue="objSearchStore?.dataSeletedForm"
                    @update:handleSearch="handleSearch"
                />
                <!-- searchbar end -->

                <!-- content start -->
                <probationary-assessments-table
                    :items="itemsRender"
                    :modelSelected="modelSelected"
                    @update:modelSelected="modelSelectedFun"
                    :totalPages="totalPages"
                    :page="page"
                    :loading="loading"
                    @update:toPage="handleToPage"
                    @updateSort="handleUpdateSort"
                />
                <!--  content end -->
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" title="Đánh giá thử việc" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user'
import { useItems } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import dayjs from 'dayjs'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import CAccess from '../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../shared-fe/composables/use-batch'
import { useExport } from '../../../../shared-fe/composables/use-export'
import { useStoreSearch } from '../../../../shared-fe/stores/search'
import {
    FORMAT_TIME,
    PROBATIONARY_ASSESSMENTS_STATUS,
    RECORD_PER_PAGE,
    USER_ROLE,
} from '../../../../shared-fe/utils/constant'
import { displayParentName, formatDateObjectToQuery } from '../../../../shared-fe/utils/convert-data'
import { EXPORT_EXCEL } from '../../../../shared-fe/utils/message'
import { PROBATIONARY_ASSESSMENTS_COLLECTION } from '../../../../shared-fe/utils/table-name'
import ProbationaryAssessmentsSearchbar from './components/probationary-assessments-searchbar.vue'
import ProbationaryAssessmentsTable from './components/probationary-assessments-table.vue'

const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-start_date'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)
const userStore = useUserStore()
const customFilter = computed(() => {
    let _filter: any = filter.value
    let and: any = []
    // const currentDate = new Date()
    // const targetDate = new Date('2023-08-15')
    // if (currentDate >= targetDate) {
    //     and.push({
    //         start_date: {
    //             _lt: dayjs(new Date()).add(-50, 'day').format(FORMAT_TIME.YYYMMDD),
    //         },
    //     })
    // }

    if (_filter?.start_date) {
        and.push({
            start_date: _filter.start_date,
        })
        delete _filter.start_date
    }
    if (objSearchStore.value?.dataInitSearch?.search) {
        and.push({
            _or: [
                {
                    dcr: {
                        full_name: {
                            _contains: objSearchStore.value?.dataInitSearch?.search,
                        },
                    },
                },
                {
                    dcr: {
                        route_code: {
                            name: {
                                _contains: objSearchStore.value?.dataInitSearch?.search,
                            },
                        },
                    },
                },
                {
                    dcr: {
                        code: {
                            _contains: objSearchStore.value?.dataInitSearch?.search,
                        },
                    },
                },
                {
                    dcr: {
                        phone_number: {
                            _contains: objSearchStore.value?.dataInitSearch?.search,
                        },
                    },
                },
                {
                    dcr: {
                        id_card_number: {
                            _contains: objSearchStore.value?.dataInitSearch?.search,
                        },
                    },
                },
            ],
        })
    }

    if (userStore?.currentUser?.role?.short_name == USER_ROLE.HR) {
        and.push({
            dcr: {
                area: {
                    id: {
                        _in: userStore.currentUser?.ares_management?.map((a) => a.id),
                    },
                },
            },
        })
    }

    _filter = {
        ..._filter,
        _and: and,
    }

    return _filter
})

const { items, totalPages, loading, getItems, getTotalCount, getItemCount } = useItems(
    ref(PROBATIONARY_ASSESSMENTS_COLLECTION),
    {
        fields: ref([
            'id',
            'dcr.zone.name',
            'dcr.area.name',
            'dcr.system_providers.provider_id.name',
            'dcr.code',
            'dcr.route_code.name',
            'dcr.full_name',
            'dcr.parent_id.full_name',
            'dcr.parent_id.role.short_name',
            'dcr',
            'status',
            'start_date',
            'end_date',
            'ss_status',
            'asm_status',
        ]),
        limit: ref(RECORD_PER_PAGE),
        sort,
        search: ref(null),
        filter: customFilter,
        page: page,
    }
)

const itemsRender = computed(() => {
    let _itemsRender: any = []
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    items.value.forEach((x) => {
        index++
        _itemsRender.push({
            stt: index,
            id: x.id,
            zone: x.dcr?.zone?.name,
            area: x.dcr?.area?.name,
            system_providers: x.dcr?.system_providers?.length > 0 ? x.dcr.system_providers[0].provider_id?.name : '',
            ss: x.dcr?.parent_id
                ? displayParentName(x.dcr.parent_id?.role?.short_name, x.dcr.parent_id?.full_name)
                : '',
            full_name: x.dcr?.full_name,
            code: x.dcr?.code,
            route_code: x.dcr?.route_code?.name,
            start_date: x.start_date,
            end_date: x.end_date,
            status: x.status,
            ss_status: x.ss_status,
            asm_status: x.asm_status,
        })
    })

    return _itemsRender
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {}

    _filter.dcr = {}
    if (data.area) {
        _filter.dcr = {
            ..._filter.dcr,
            area: {
                id: data.area,
            },
        }
    }
    if (data.zone) {
        _filter.dcr = {
            ..._filter.dcr,
            zone: {
                id: data.zone,
            },
        }
    }
    if (data.system_providers) {
        _filter.dcr = {
            ..._filter.dcr,
            system_providers: {
                provider_id: data.system_providers,
            },
        }
    }

    if (data.end_date && data.end_date.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.end_date[0], data.end_date[1])
        _filter.end_date = {
            _between: dateQuery,
        }
    }
    if (data.status !== null) {
        _filter.status = data.status
    }

    if (data.ss) {
        _filter.dcr = {
            ..._filter.dcr,
            parent_id: data.ss,
        }
    } else if (data.asm) {
        _filter._or = [
            { dcr: { parent_id: data.asm } },
            {
                dcr: {
                    parent_id: {
                        parent_id: data.asm,
                    },
                },
            },
        ]
        _filter.asm = data.asm
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value) {
            delete _filter[key]
        }
    }

    filter.value = _filter
    delete filter.value?.asm

    updateSearchStore(
        {
            search: data.title,
            filter: {
                ..._filter,
            },
        },
        data
    )
}

// table end

// actions start

const {
    deleting,
    error: deleteError,
    modelSelected,
    confirmDelete,
    batchDelete,
} = useBatch(PROBATIONARY_ASSESSMENTS_COLLECTION)

const _export = useExport(PROBATIONARY_ASSESSMENTS_COLLECTION, {
    stt: 'STT',
    id: 'ID',
    'dcr.zone.name': 'Chi nhánh',
    'dcr.area.name': 'Khu vực',
    'dcr.system_providers.provider_id.name': 'Nhà phân phối',
    'dcr.parent_id.parent_id.full_name': 'ASM',
    'dcr.parent_id.full_name': 'SS',
    'dcr.route_code.name': 'Mã Route',
    'dcr.code': 'Mã DCR',
    'dcr.full_name': 'Họ và tên',
    start_date: 'Bắt đầu thử việc',
    end_date: 'Kết thúc thử việc',
    ss_date_interview: 'Ngày SS đánh giá',
    ss_status: 'SS đánh giá',
    ss_note: 'Chi tiết đánh giá - SS',
    asm_date_interview: 'Ngày ASM đánh giá',
    asm_status: 'ASM đánh giá',
    asm_note: 'Chi tiết đánh giá - ASM',
    status: 'Kết quả thử việc',
})
_export.filename.value = 'ProbationaryAssessments.xlsx'
_export.filter.value = {
    start_date: {
        _lt: dayjs(new Date()).add(-50, 'day').format(FORMAT_TIME.YYYMMDD),
    },
}
_export.beforeGenerate.value = (items) => {
    return items.map((item, index) => {
        return {
            stt: index + 1,
            id: item?.id,
            dcr_zone_name: item?.dcr?.zone?.name,
            dcr_area_name: item?.dcr?.area?.name,
            dcr_system_providers_provider_id_name:
                item?.dcr?.system_providers?.length > 0 ? item?.dcr?.system_providers[0]?.provider_id?.name : '',
            dcr_parent_id_parent_id_full_name: item?.dcr?.parent_id?.parent_id?.full_name,
            dcr_parent_id_full_name: item?.dcr?.parent_id?.full_name,
            dcr_route_code_name: item?.dcr?.route_code?.name,
            dcr_code: item?.dcr?.code,
            dcr_full_name: item?.dcr?.full_name,
            start_date: item?.start_date ? new Date(item?.start_date) : '',
            end_date: item?.end_date ? new Date(item?.end_date) : '',
            ss_date_interview: item?.ss_date_interview ? new Date(item?.ss_date_interview) : '',
            ss_status: PROBATIONARY_ASSESSMENTS_STATUS[item?.ss_status]?.text,
            ss_note: item?.ss_note,
            asm_date_interview: item?.asm_date_interview ? new Date(item?.asm_date_interview) : '',
            asm_status: PROBATIONARY_ASSESSMENTS_STATUS[item?.asm_status]?.text,
            asm_note: item?.asm_note,
            status: PROBATIONARY_ASSESSMENTS_STATUS[item?.status]?.text,
        }
    })
}
const exportData = () => {
    _export.filter.value = customFilter.value
    _export.sort.value = sort.value
    _export.run()
}
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang="scss" scoped></style>
