<template>
    <c-upload @input="uploaded" @error="handleError"></c-upload>
    <span class="message-error text-xs" v-if="errors.length > 0">Tải lên không thành công. File tải không hợp lệ.</span>
    <div class="text-xs" v-for="error in errors">
        <span class="message-error text-xs" v-if="error?.row !== 0">DÒNG {{ error.row }}:&nbsp;</span>
        <span v-if="error?.row !== 0">{{ error.message.join(' ; ') }}</span>
    </div>
    <div
        v-if="dcrSuccess > 0"
        class="mt-5 py-[18px] px-4 border-l-4 border-t-0 border-r-0 border-b-0 border-[#399E9F] border-solid bg-[#EAFAF6] w-full text-[#399E9F]"
    >
        <span class="mr-2 py-1 border border-solid rounded-full"><v-icon class="" outlined name="check"></v-icon></span>
        <span class="mr-4">Đã tải lên {{ totalSuccess ? totalSuccess : dcrSuccess }} DCR hợp lệ</span>
    </div>
    <div v-if="dcrSuccess > 0" class="mt-8 w-full flex justify-center">
        <span class="custom-button-import">
            <v-button class="" outlined @click="submit">
                <span class="text-[#5BABAC]">Xác nhận tải lên</span>
            </v-button>
        </span>
    </div>
</template>
<script setup lang="ts">
import CUpload from '@/shared-fe/components/c-upload/c-upload.vue'
import { Ref } from 'vue'
import { ref } from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useWorkWithValidate } from '../../composables/useWorkWithValidate'
import { toLowerCaseNonAccentVietnamese } from '@/shared-fe/utils/convert-data'
import dayjs from 'dayjs'
import { FORMAT_TIME } from '@/shared-fe/utils/constant'
import { toRefs } from 'vue'

const props = defineProps({
    totalSuccess: {
        type: Number,
        default: 0,
    },
})
const { totalSuccess } = toRefs(props)
const errors: Ref<any[]> = ref([])
const dcrSuccess: Ref<number> = ref(0)
const requestValid = useWorkWithValidate()
const rawData = ref(<any[]>[])
const validData = ref(<any[]>[])

const emit = defineEmits(['import'])

const uploaded = (file: any) => {
    if (file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
            errors.value = []
            rawData.value = []
            validData.value = []
            dcrSuccess.value = 0
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, { type: 'array', cellDates: true })

            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            let parseData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: '',
            })

            parseData.shift()
            if (parseData.length === 0) {
                handleError()
                return
            }
            parseData.map((arrayData: any, index: number) => {
                let obj = {
                    ww_date: dayjs(arrayData[0]).add(1, 'day').format(FORMAT_TIME.DDMMYYYY),
                    zone: arrayData[1],
                    area: arrayData[2],
                    sd_name: arrayData[3],
                    asm_name: arrayData[4],
                    ss_code: arrayData[5],
                    ss_name: arrayData[6],
                    email: arrayData[7],
                    work_item: arrayData[8],
                    objective: arrayData[9],
                    route_code: arrayData[10],
                    dcr_name: arrayData[11],
                    route_type: arrayData[12],
                    call: arrayData[13],
                    visited: arrayData[14],
                    result: toLowerCaseNonAccentVietnamese(arrayData[15].trim()),
                    status: toLowerCaseNonAccentVietnamese(arrayData[16].trim()),
                    source_id: String(arrayData[18]),
                    dcr_code: arrayData[19],
                }
                rawData.value.push(obj)
                let { error, data } = requestValid.validate(obj)
                if (error) {
                    errors.value.push({
                        row: index + 2,
                        message: data,
                    })
                } else {
                    validData.value.push(obj)
                }
            })

            dcrSuccess.value = validData.value.length
        }

        reader.readAsArrayBuffer(file)
    }
}

const submit = () => {
    emit('import', validData.value, rawData.value)
}

const handleShowError = (error: string, row: number) => {
    if (!error) {
        return
    }

    let findRow = errors.value.find((x) => x.row === row + 1)
    if (!findRow) {
        errors.value.push({
            row: row + 1,
            message: [error],
        })
    }
}

const handleError = () => {
    errors.value = [
        {
            row: 0,
            message: ['error'],
        },
    ]
}

defineExpose({ handleShowError, rawData, validData })
</script>

<style lang="scss">
.custom-button-import {
    position: relative;
    .button {
        border-radius: 0.5rem !important;
        border-color: var(--primary) !important;
        background: transparent !important;
        color: var(--primary) !important;

        &:hover {
            border-color: var(--primary) !important;
            background: transparent;
            color: var(--primary);
        }
    }
}
</style>
