import api from '@/api'
import { useLatencyStore } from '@/stores/latency'
import { userName } from '@/utils/user-name'
import { User } from '@directus/types'
import { merge } from 'lodash'
import { defineStore } from 'pinia'
import type { RouteLocationNormalized } from 'vue-router'
import * as Sentry from "@sentry/vue";
import { COURSE_BY_USER_TYPE, TYPE_OF_TRAIN, USER_TYPE } from '@/shared-fe/utils/constant'

type ShareUser = {
    share: string
    role: {
        id: string
        admin_access: false
        app_access: false
    }
}

export const useUserStore = defineStore({
    id: 'userStore',
    state: () => ({
        currentUser: null as User | ShareUser | null,
        loading: false,
        error: null,
    }),
    getters: {
        fullName(): string | null {
            if (this.currentUser === null || 'share' in this.currentUser) return null
            return userName(this.currentUser)
        },
        isAdmin(): boolean {
            return this.currentUser?.role.admin_access === true || false
        },
    },
    actions: {
        async hydrate() {
            this.loading = true

            try {
                const fields = [
                    '*',
                    'id',
                    'language',
                    'first_name',
                    'last_name',
                    'email',
                    'last_page',
                    'tfa_secret',
                    'source',
                    'avatar.id',
                    'role.admin_access',
                    'role.app_access',
                    'role.id',
                    'role.enforce_tfa',
                    'role.short_name',
                    'zone.*',
                    'area.*',
                    'system_providers.id',
                    'ares_management.*',
                    'ares_management.zone.*',
                    'user_type',
                    'role.*',
                    'system_providers.id',
                    'system_providers.provider_id.name',
                    'system_providers.provider_id.id',
                ]
                const { data } = await api.get(`/users/me`, { params: { fields } })
                data.data.last_page = null
                this.currentUser = data.data
                this.currentUser = {
                    ...this.currentUser,
                    ...this.getLabelUserType(),
                    ...await this.getChannels(),
                    ...await this.getAllOptions(),
                }
                Sentry.setUser({
                    ...this.currentUser,
                });
            } catch (error: any) {
                this.error = error
            } finally {
                this.loading = false
            }
        },
        getLabelUserType(){
            let labelUserType = "DCR"
            switch(this.currentUser.user_type){
                case USER_TYPE.WSR:
                    labelUserType = "WSR"
                    break
                case USER_TYPE.RETAIL:
                    labelUserType = "DCR"
                    break
                default:
                    labelUserType = "DCR"
                    break
            }
            return { label_user_type: labelUserType}
        },
        async getChannels() {
            const fields = [
                "courses",
                "name",
                "type_of_train",
                "type_of_user",
                "id"
            ];
            const res = {
                [TYPE_OF_TRAIN.OB]: [],
                [TYPE_OF_TRAIN.T7HH]: [],
                [COURSE_BY_USER_TYPE.OB]: [],
                [COURSE_BY_USER_TYPE.T7HH]: [],
            }

            const { data } = await api.get(`/items/channels`, { params: { fields }, limit: -1 })

            if (data?.data?.length > 0) {
                let channelOb = data?.data?.filter(x => x.type_of_train === TYPE_OF_TRAIN.OB);
                let channelT7hh = data?.data?.filter(x => x.type_of_train === TYPE_OF_TRAIN.T7HH);
                if (!this.currentUser.role.admin_access) {
                    channelOb = data?.data?.filter(x => x.type_of_user.includes(this.currentUser.user_type) && x.type_of_train === TYPE_OF_TRAIN.OB);
                    channelT7hh = data?.data?.filter(x => x.type_of_user.includes(this.currentUser.user_type) && x.type_of_train === TYPE_OF_TRAIN.T7HH)
                }
                res[TYPE_OF_TRAIN.OB] = channelOb;
                res[TYPE_OF_TRAIN.T7HH] = channelT7hh;
                res[COURSE_BY_USER_TYPE.OB] = channelOb.map(item => item.courses).flat();
                res[COURSE_BY_USER_TYPE.T7HH] = channelT7hh.map(item => item.courses).flat();
            }

            return res;
            
        },
        async getAllOptions() {
            const res = await api.get('/items/options', {
                params: {
                    limit: -1,
                    fields: ['*'],
                },
            })

            if (res.data.data && res.data.data.length > 0) {
                return { options: res.data.data }
            }

            return { options: [] }
        },
        async dehydrate() {
            this.$reset()
        },
        async hydrateAdditionalFields(fields: string[]) {
            try {
                const { data } = await api.get(`/users/me`, { params: { fields } })
                data.data.last_page = null
                this.currentUser = merge({}, this.currentUser, data.data)
            } catch (error: any) {
                // Do nothing
            }
        },
        async trackPage(to: RouteLocationNormalized) {
            /**
             * We don't want to track the full screen preview from live previews as part of the user's
             * last page, as that'll cause a fresh login to navigate to the full screen preview where
             * you can't navigate away from #19160
             */
            if (to.path.endsWith('/preview')) {
                return
            }

            const latencyStore = useLatencyStore()

            const start = performance.now()

            await api.patch(`/users/me/track/page`, {
                last_page: to.fullPath,
            })

            const end = performance.now()

            latencyStore.save({
                timestamp: new Date(),
                latency: end - start,
            })

            if (this.currentUser && !('share' in this.currentUser)) {
                // this.currentUser.last_page = to.fullPath;
            }
        },
    },
})
