import { ref } from 'vue'
import { useApi } from '@directus/composables'
import * as XLSX from 'xlsx/xlsx.mjs'
import { get, set } from 'lodash'
import { USER_COLLECTION } from '../utils/table-name'

export const useExport = (collection, fields) => {
    const api = useApi()
    const page = ref(1)
    const sort = ref(['-id'])
    const limit = ref(100)
    const items = ref([])
    const filter = ref({})
    const filename = ref('')
    const headers = ref(Object.values(fields))
    const _fields = Object.keys(fields)
    const loadingExcel = ref(false)
    const beforeGenerate = ref(<any>false)

    const run = async () => {
        loadingExcel.value = true
        const params = {
            fields: [..._fields, '*'],
            filter: filter.value,
            sort: sort.value,
            page: page.value,
            limit: limit.value,
        }
        const table = collection === USER_COLLECTION ? '/users' : `/items/${collection}`
        const output = await api
            .get(`${table}`, {
                params: params,
            })
            .then((res: any) => {
                return res.data
            })
            .catch((_e: any) => {
                console.log(_e)
                return {
                    data: [],
                }
            })

        items.value = items.value.concat(output.data)

        if (output.data.length > 0) {
            page.value++
            await run().catch()
        } else {
            page.value = 1
            generateExcel()
        }
    }

    const generateExcel = () => {
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet([headers.value])

        let formatted = []
        if (typeof beforeGenerate.value == 'function') {
            formatted = beforeGenerate.value(items.value)
        } else {
            formatted = items.value.map((i) => {
                const item = {}
                for (const k of _fields) {
                    // set(item, k.replace(/\./g, '_'), get(i, k, ''))
                    set(item, k, get(i, k, ''))
                }
                return item
            })
        }

        XLSX.utils.sheet_add_json(ws, formatted, {
            origin: -1,
            skipHeader: 1,
        })

        XLSX.utils.book_append_sheet(wb, ws, '')

        XLSX.writeFile(wb, filename.value)

        loadingExcel.value = false
        items.value = []
    }

    return {
        page,
        sort,
        limit,
        items,
        filter,
        filename,
        headers,
        run,
        beforeGenerate,
        loadingExcel,
    }
}
