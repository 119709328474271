<template>
    <div v-for="(item, index) in answers" :key="index">
        <div class="flex flex-cols mb-4 w-full" v-if="item.isChosen">
            <div
                class="p-4 border border-solid rounded-md w-full"
                v-bind:class="item.is_correct ? 'bg-[#DEF4F5] border-[#BBE9E9]' : 'bg-[#FEF2F2] border-[#FCA5A5]'"
            >
                {{ choice(String(Number(index) + 1)) }}{{ item.answer }}
            </div>
        </div>
    </div>

    <div class="mb-4 bg-[#FEF2F2] border-[#FCA5A5] p-4 border border-solid rounded-md w-full" v-if="noAnswer">
        Chưa chọn đáp án
    </div>

    <div v-if="isCorrectAnswer" class="px-2 py-1 bg-[#D1FAE5] text-[#059669] font-medium max-w-[51px] rounded-md">
        Đúng
    </div>
    <div v-else>
        <div>
            <span class="text-[#059669] font-bold">Đáp án đúng:&nbsp;</span>
            <template v-for="(item, index) in answers" :key="index">
                <span v-if="item.is_correct">{{ choice(String(Number(index) + 1)) }}{{ item.answer }}{{ '. ' }}</span>
            </template>
        </div>
        <div class="font-bold mt-6 mb-2">Giải thích:</div>
        <div>{{ explain }}</div>
    </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { choice } from '../../../../../shared-fe/utils/convert-data'
const props = defineProps({
    answers: {
        type: Object,
        default: () => {},
    },
    explain: {
        type: String,
        default: '',
    },
    noAnswer: {
        type: Boolean,
        default: false,
    },
})
const { answers, explain } = toRefs(props)

const isCorrectAnswer = computed(() => {
    let isCorrect = true
    answers.value.map((item: any) => {
        if ((item.isChosen && !item?.is_correct) || (item.is_correct && !item.isChosen)) {
            isCorrect = false
        }
    })

    return isCorrect
})
</script>
