<template>
    <div class="flex gap-16 py-12 px-[70px] h-full w-full">
        <div class="min-w-[329px]">
            <div
                class="px-4 py-3 text-[#374151] cursor-pointer"
                v-for="item in LIST_TAB_SATURDAY_REPORT"
                :key="item.key"
                :class="keyNumber === item.key ? 'bg-[#F1F5F9]' : ''"
                @click="openTab(item.key)"
            >
                {{ item.title }}
            </div>
        </div>
        <div class="vertical-dash"></div>
        <div class="w-full">
            <div class="flex gap-8 w-full mb-12">
                <div class="w-full">
                    <div class="type-label">Từ ngày</div>
                    <v-menu ref="dateTimeMenuStart" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="formData.start_date_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.start_date ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.start_date_display,
                                            'today-icon': !formData.start_date_display,
                                        }"
                                        v-on="{
                                            click:
                                                formData.start_date && !loadingExcel
                                                    ? unsetValue(e, 'start_date')
                                                    : null,
                                        }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <v-date-picker
                            type="date"
                            :disabled="loadingExcel"
                            :include-seconds="true"
                            :use-24="true"
                            :model-value="formData.start_date"
                            @update:model-value="(e) => handleChange(e, 'start_date')"
                            @close="dateTimeMenuStart?.deactivate"
                        />
                    </v-menu>
                    <div>
                        <span class="message-error" v-if="errors">Vui lòng nhập ngày bắt đầu và ngày kết thúc</span>
                    </div>
                </div>
                <div class="w-full">
                    <div class="type-label">Đến ngày</div>
                    <v-menu ref="dateTimeMenuEnd" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="formData.end_date_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.end_date ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.end_date_display,
                                            'today-icon': !formData.end_date_display,
                                        }"
                                        v-on="{
                                            click:
                                                formData.end_date && !loadingExcel ? unsetValue(e, 'end_date') : null,
                                        }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <v-date-picker
                            type="date"
                            :include-seconds="true"
                            :use-24="true"
                            :disabled="loadingExcel"
                            :model-value="formData.end_date"
                            @update:model-value="(e) => handleChange(e, 'end_date')"
                            @close="dateTimeMenuEnd?.deactivate"
                        />
                    </v-menu>
                    <div>
                        <span class="message-error" v-if="errors">Vui lòng nhập ngày bắt đầu và ngày kết thúc</span>
                    </div>
                </div>
            </div>
            <div class="flex gap-5 justify-end">
                <v-button @click="handleExportExcel" :loading="loadingExcel" :disabled="loadingExcel">
                    <v-icon class="mr-2" name="file_download" />
                    Xuất Excel
                </v-button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useApi } from '@directus/composables'
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import {
    EMPLOYMENT_STATUS,
    EXAM_RESULT_REPORT_STATUS, EXAM_RESULT_VALID,
    FORMAT_TIME,
    USER_ROLE
} from '../../../../../shared-fe/utils/constant'
import { formatDateObjectToQuery, sortData } from '../../../../../shared-fe/utils/convert-data'
import { USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import _ from 'lodash';

const dataSurvey = ref(<any>[])
const dataExam = ref(<any>[])
const listSS = ref(<any>[])
const pageExcel = ref(<any>1)
const dataSheetSS = ref(<any>[])
const dataSheetASM = ref(<any>[])
const dataSesionTrain = ref(<any>[])
const dataSheetOpenedExamRoom = ref(<any>[])
const api = useApi()
const dataDefault = {
    start_date: null,
    start_date_display: null,
    end_date: null,
    end_date_display: null,
}

const keyNumber = ref(1)
const dateTimeMenuStart = ref()
const dateTimeMenuEnd = ref()
const errors = ref('')
const loadingExcel = ref(false)

const openTab = (key: any) => {
    keyNumber.value = key
}

const formData = ref(JSON.parse(JSON.stringify(dataDefault)))

const LIST_TAB_SATURDAY_REPORT = computed(() => [
    {
        key: 1,
        title: 'Báo cáo tổng hợp hoàn thành khóa học',
    },
    {
        key: 2,
        title: 'Trung bình đánh giá theo khóa học',
    },
    {
        key: 3,
        title: 'Chi tiết đánh giá khóa học',
    },
    {
        key: 4,
        title: 'Chi tiết bài thi',
    },
    {
        key: 5,
        title: 'Thống kê số lượt trả lời câu hỏi thi',
    },
])

const handleChange = (e: any, variable: any) => {
    if (!loadingExcel.value) {
        switch (variable) {
            case 'start_date':
                formData.value.start_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
                formData.value[variable] = e || ''
                break
            case 'end_date':
                formData.value.end_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
                formData.value[variable] = e || ''
                break
            default:
                break
        }
    }
}

const unsetValue = (e: any, variable: string) => (e) => {
    e.preventDefault()
    e.stopPropagation()

    switch (variable) {
        case 'start_date':
            formData.value.start_date = ''
            formData.value.start_date_display = ''
            break
        case 'end_date':
            formData.value.end_date = ''
            formData.value.end_date_display = ''
            break
        default:
            break
    }
}
// Report
const handleExportExcel = async () => {
    listSS.value = []
    dataSesionTrain.value = []
    errors.value = ''
    dataSurvey.value = []
    dataSheetOpenedExamRoom.value = []
    // if (areDates30DaysApart(new Date(formData.value.end_date), new Date(formData.value.start_date))) {
    //     errors.value = 'error'
    //     return
    // }
    if (!formData.value.end_date || !formData.value.start_date) {
        errors.value = 'error'
        return
    }
    loadingExcel.value = true
    await getSSLists()
    // Báo cáo tổng hợp hoàn thành khóa học
    if (keyNumber.value === 1) {
        await getRawDataReport();
        convertDataSheetOpenedExamRoom()
        convertDataSheetSs()
        convertDataSheetAsm()
        await generateExcelKey1()
    }
    // Trung bình đánh giá theo khóa học
    else if (keyNumber.value === 2) {
    }
    // Chi tiết đánh giá khóa học
    else if (keyNumber.value === 3) {
        await getSesionTrain()
        convertDetailSurvey()
        await generateExcelKey3()
    }
    //Chi tiết bài thi
    else if (keyNumber.value === 4) {
        await getSesionTrain()
        convertDetailExam()
        await generateExcelKey4()
    }
    // Thống kê số lượt trả lời câu hỏi thi
    else if (keyNumber.value === 5) {
    }
    loadingExcel.value = false
}

const getSSLists = async () => {
    const params = {
        limit: 100,
        page: pageExcel.value,
        fields: [
            'id',
            'code',
            'full_name',
            'email',
            'employment_status',
            'start_month',
            'parent_id.id',
            'parent_id.email',
            'parent_id.code',
            'parent_id.full_name',
            'zone.name',
            'targets.*',
            'sub_users.*',
            'employment_status',
            'sub_users.system_providers.provider_id.id'
        ],
        filter: {
            _and: [
                {
                    role: {
                        short_name: {
                            _eq: USER_ROLE.SS,
                        },
                    },
                },
            ],
        },
    }
    const res = await api
        .get(`${USER_COLLECTION}`, {
            params: params,
        })
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })
    listSS.value = listSS.value.concat(res.data)
    if (res.data.length > 0) {
        pageExcel.value += 1
        await getSSLists().catch()
    } else {
        pageExcel.value = 1
    }
}

const getSesionTrain = async () => {
    // query param
    const params = {
        limit: 100,
        page: pageExcel.value,
        sort: ['user_created', 'date_created'],
        fields: [
            'user_created.full_name',
            'user_created.id',
            'user_created.code',
            'user_created.email',
            'user_created.system_providers.provider_id.id',
            'exam_rooms.detail',
            'exam_rooms.user.id',
            'exam_rooms.user.full_name',
            'exam_rooms.user.code',
            'exam_rooms.user.route_code.name',
            'exam_rooms.topic.title',
            'exam_rooms.topic.id',
            'exam_rooms.results.status',
            'exam_rooms.results.total_score',
            'exam_rooms.results.date_created',
            'exam_rooms.results.detail',
            'exam_rooms.image.id',
            'exam_rooms.provider.name',
            'exam_rooms.provider.id',
            'exam_rooms.date_created',
            'exam_rooms.survey_submit',
            'exam_rooms.survey_submit.detail',
            'asm.full_name',
            'asm.id',
            'asm.code',
            'meta_data',
            'id',
            'zone.name',
            'area.name',
        ],
        filter: {
            _and: [
                {
                    date_created: {
                        _between: formatDateObjectToQuery(formData.value.start_date, formData.value.end_date),
                    },
                },
                {
                    course: {
                        channel: {
                            type_of_train: {
                                _eq: TYPE_OF_TRAIN.OB
                            }
                        }
                    }
                }
            ],
        },
    }

    // call api
    const res = await api
        .post(`/api-learnings/session_train/report`, params)
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })
    dataSesionTrain.value = dataSesionTrain.value.concat(res.result)
    if (res?.result?.length > 0) {
        pageExcel.value++
        await getSesionTrain().catch()
    } else {
        pageExcel.value = 1
    }
}

const getRawDataReport = async () => {
    // query param
    const params = {
        limit: 100,
        page: pageExcel.value,
        sort: ['user_created', 'date_created'],
        fields: [
            "sd_id",
            "sd_name",
            "asm_id",
            "asm_name",
            "ss_id",
            "ss_name",
            "email",
            "module_title",
            "open_date",
            "provider",
            "dcr_total_on",
            "dcr_exam_result",
            "number_assessment",
            "dcr_percent_exam",
            "dcr_percent_assessment",
            "dcr_join_exam",
            "actual_results",
            "average_score",
            "average_rating",
            "number_dcr_passed",
            "percent_dcr_passed",
            "valid",
            "status",
            "image_text",
        ],
        filter: {
            _and: [
                {
                    date_created: {
                        _between: formatDateObjectToQuery(formData.value.start_date, formData.value.end_date),
                    },
                },
            ],
        },
    }

    // call api
    const res = await api
        .post(`/api-learnings/session_train/report-saturday-learning`, params)
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })
    dataSheetOpenedExamRoom.value = dataSheetOpenedExamRoom.value.concat(res.result)
    if (res?.result?.length > 0) {
        pageExcel.value++
        await getRawDataReport().catch()
    } else {
        pageExcel.value = 1
    }
}

////////////////////////////////////////////////////// tab 1
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

const convertDataSheetOpenedExamRoom = () => {
    dataSheetOpenedExamRoom.value = dataSheetOpenedExamRoom.value.map((x:any, index: number) => {
        return {
            ...x,
            no: index + 1,
            image_text: '',
            open_date: new Date(x?.open_date),
            _hyperlink: {
                Target: x?.image_text ? displayLink(x?.image_text) : '',
                Tooltip: 'Click me!',
                Display: 'View image',
            },
        }
    })
}


const currentTarget = (targets: any, year: any) => {
    return targets.find((x: any) => x?.year === year)
}
const targetByMonth = (target: any, month: any) => {
    let targetRes = 0;
    if(target) {
        switch (month){
            case "01":
                targetRes = target.january;
                break
            case "02":
                targetRes = target.february;
                break
            case "03":
                targetRes = target.march;
                break
            case "04":
                targetRes = target.april;
                break
            case "05":
                targetRes = target.may;
                break
            case "06":
                targetRes = target.june;
                break
            case "07":
                targetRes = target.july;
                break
            case "08":
                targetRes = target.august;
                break
            case "09":
                targetRes = target.september;
                break
            case "10":
                targetRes = target.october;
                break
            case "11":
                targetRes = target.november;
                break
            case "12":
                targetRes = target.december;
                break
            default:
                targetRes = target.december;
                break;
        }
    }
    return targetRes;
}

const countMonthExculde = (target: any, startMonth: any, endMonth: any) => {
    let _countMonthExclude = 0
    let _targetNeedExcuteYear = 0;

    if(endMonth - startMonth < 0) {
        return {
            count_month_exclude: 0,
            target_need_excute_year: 0
        };
    }

    for(let i = startMonth; i <= endMonth; i++){
        let _targetByMonth = targetByMonth(target, i.toString().padStart(2, "0"))
        if( _targetByMonth === 0){
            _countMonthExclude += 1;
        }
        _targetNeedExcuteYear += _targetByMonth
    }

    return {
        count_month_exclude: _countMonthExclude,
        target_need_excute_year: _targetNeedExcuteYear
    };
}

const targetNeedExcuteMonth = (target: any, targetNeedExcuteYear: any, startMonth: any, endMonth: any, startDay: any, endDay: any) => {
    let _targetNeedExcuteMonth = targetNeedExcuteYear
    if(startDay > 15){
        _targetNeedExcuteMonth = _targetNeedExcuteMonth - (targetByMonth(target, (startMonth).toString().padStart(2, "0"))/2)
    }

    if(endDay < 15) {
        _targetNeedExcuteMonth = _targetNeedExcuteMonth - (targetByMonth(target, (endMonth).toString().padStart(2, "0"))/2)
    }
    return Math.round(_targetNeedExcuteMonth);
}

const countMonthNotTrain = (sessionTrainBySS: any, startMonth: any, endMonth: any, startYear: any) => {
    let _countMonthNotTrain = 0
    if(endMonth - startMonth < 0) {
        return 0
    }

    for(let i = startMonth; i <= endMonth; i++){
        let monthYear = i.toString().padStart(2, "0") + '/' + startYear
        let res = sessionTrainBySS?.find((x:any) =>  (((x.open_date.getMonth()+1).toString().padStart(2, "0")) + '/' + x.open_date.getFullYear() === monthYear) && x.status === EXAM_RESULT_REPORT_STATUS.completed.text_1)
        if(res?.length === 0){
            _countMonthNotTrain++;
        }
    }

    return _countMonthNotTrain
}

const excludeByMonth = (target: any,sessionTrainBySS: any, startYear: any, month: any) => {
    let _targetByMonth = targetByMonth(target, month);

    if(_targetByMonth === 0){
        return 'Miễn trừ'
    }
    let monthYear = month + '/' + startYear
    let res = sessionTrainBySS?.filter((x:any) =>  (((x.open_date.getMonth()+1).toString().padStart(2, "0")) + '/' + x.open_date.getFullYear() === monthYear) && x.status === EXAM_RESULT_REPORT_STATUS.completed.text_1)

    if(res?.length >= _targetByMonth){
        return EXAM_RESULT_REPORT_STATUS.completed.text_1
    }

    return EXAM_RESULT_REPORT_STATUS.incomplete.text_1
}

const averageRatingSS = (sessionTrainBySS: any) => {
    let totalAvgRating = 0
    sessionTrainBySS.forEach((x: any) => { totalAvgRating += x.average_rating })
    return (totalAvgRating === 0 || sessionTrainBySS.length === 0 ) ? 0 : Math.round(totalAvgRating / sessionTrainBySS.length);
}

const totalDcrHaveExamRoomBySS = (sessionTrainBySS: any) => {
    let _totalDcrHaveExamRoomBySS = 0
    sessionTrainBySS.forEach((x: any) => { _totalDcrHaveExamRoomBySS += x.dcr_exam_result })
    return _totalDcrHaveExamRoomBySS
}

const totalDcrPassExamRoomBySS = (sessionTrainBySS: any) => {
    let _totalDcrPassExamRoomBySS = 0
    sessionTrainBySS.forEach((x: any) => { _totalDcrPassExamRoomBySS += x.number_dcr_passed })
    return _totalDcrPassExamRoomBySS
}
const convertDataSheetSs = () => {
    dataSheetSS.value = []
    if(listSS.value.length > 0){
        let _currentTargetSS;
        let _targetObj;
        let startDate = new Date(formData.value.start_date)
        let endDate = new Date(formData.value.end_date)
        let startYear =  startDate.getFullYear();
        let startDay = startDate.getDate()
        let endDay = endDate.getDate()
        let startMonth = startDate.getMonth() + 1;
        let endMonth = endDate.getMonth() + 1;
        let _targetNeedExcuteMonth;
        let _sessionTrainBySS;
        let _sessionTrainValid;
        let _sessionTrainStatus;
        const _totalMonthExportReport = (endMonth - startMonth) + 1
        let _countTrainAvg;
        let _targetNeedExcuteTrain;
        let _countMonthNotTrain;
        let _dcrHaveExamRoom;
        let _dcrPassedExamRoom;

        listSS.value.forEach((x: any, index: number) => {
            _currentTargetSS = currentTarget(x?.targets, startYear)
            _targetObj = countMonthExculde(_currentTargetSS, startMonth, endMonth)
            _targetNeedExcuteMonth = targetNeedExcuteMonth(_currentTargetSS, _targetObj.target_need_excute_year, startMonth, endMonth, startDay, endDay)
            _sessionTrainBySS = dataSheetOpenedExamRoom.value.filter((k: any) => k.email === x?.email);
            _sessionTrainValid = _sessionTrainBySS?.filter((k: any) => k.valid === EXAM_RESULT_VALID.valid)
            _sessionTrainStatus = _sessionTrainBySS?.filter((k: any) => k.status === EXAM_RESULT_REPORT_STATUS.completed.text_1)
            _countTrainAvg = (_sessionTrainStatus?.length === 0 || _totalMonthExportReport === 0) ? 0 : Math.round(_sessionTrainStatus?.length / _totalMonthExportReport);
            _targetNeedExcuteTrain = (_targetNeedExcuteMonth === 0 || (_totalMonthExportReport - _targetObj.count_month_exclude) === 0) ? 0 : Math.round(_targetNeedExcuteMonth / (_totalMonthExportReport - _targetObj.count_month_exclude))
            _countMonthNotTrain =  _sessionTrainValid?.length === 0 ? (_totalMonthExportReport - _targetObj.count_month_exclude) : countMonthNotTrain(_sessionTrainBySS, startMonth, endMonth, startYear);
            _dcrHaveExamRoom = totalDcrHaveExamRoomBySS(_sessionTrainBySS)
            _dcrPassedExamRoom = totalDcrPassExamRoomBySS(_sessionTrainBySS)
            dataSheetSS.value.push({
                stt: index + 1, //'#'
                sd_id: '', //'SD ID'
                sd_name: x?.zone?.name, //'SD'
                asm_id: x?.parent_id?.code, // 'ASM ID'
                asm_email: x?.parent_id?.email, // 'ASM ID'
                asm_name: x?.parent_id?.full_name, //'ASM'
                ss_id: x?.code, // 'SS ID',
                ss_name: x?.full_name, // 'SS'
                ss_email: x?.email, // 'Email',
                ss_start_month: x?.start_month ? dayjs(x?.start_month).format(FORMAT_TIME.MMYYYY) : '', // 'Tháng gia nhập'
                ss_status: x?.employment_status === EMPLOYMENT_STATUS.ON ? 'Active' : 'Terminate', //'Trạng thái làm việc (Active/Terminate)'
                count_month_exclude: _targetObj.count_month_exclude, // 'Số tháng miễn trừ',
                count_month_include: _totalMonthExportReport - _targetObj.count_month_exclude,// 'Số tháng cần thực hiện',
                target_need_excute_year: _targetObj.target_need_excute_year, // 'Chỉ tiêu số buổi cần thực hiện trong năm',
                target_need_excute_month:  _targetNeedExcuteMonth, // 'Chỉ tiêu số buổi cần thực hiện theo thời gian báo cáo',
                target_need_excute_train: _targetNeedExcuteTrain, // 'Số buổi huấn luyện trung bình cần đạt',
                session_train_valid:  _sessionTrainValid?.length, // 'Số buổi huấn luyện có tổ chức',
                session_train_status:  _sessionTrainStatus?.length,// 'Số buổi huấn luyện hoàn thành',
                count_train_avg: _countTrainAvg,// 'Số buổi huấn luyện trung bình',
                percent_passed_with_target: (_sessionTrainStatus?.length === 0 || _targetNeedExcuteMonth === 0) ? '0%' :  Math.round((_sessionTrainStatus?.length / _targetNeedExcuteMonth) % 100 ) + "%", // '% Đạt so với target',
                process_train:  _countTrainAvg >= _targetNeedExcuteTrain ? EXAM_RESULT_REPORT_STATUS.passed.text : EXAM_RESULT_REPORT_STATUS.not_passed.text, // 'Tiến độ huấn luyện',
                total_need_train:  _targetObj.target_need_excute_year - _sessionTrainStatus?.length, // 'Số buổi còn phải thực hiện',
                count_month_not_train: _countMonthNotTrain,// 'Số tháng không thực hiện huấn luyện',
                result_train_1: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '01'),// 'Kết quả huấn luyện tháng 1'
                result_train_2: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '02'),// 'Kết quả huấn luyện tháng 2',
                result_train_3: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '03'),// 'Kết quả huấn luyện tháng 3',
                result_train_4: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '04'),// 'Kết quả huấn luyện tháng 4',
                result_train_5: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '05'),// 'Kết quả huấn luyện tháng 5',
                result_train_6: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '06'),// 'Kết quả huấn luyện tháng 6',
                result_train_7: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '07'),// 'Kết quả huấn luyện tháng 7',
                result_train_8: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '08'),// 'Kết quả huấn luyện tháng 8',
                result_train_9: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '09'),// 'Kết quả huấn luyện tháng 9',
                result_train_10: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '10'),// 'Kết quả huấn luyện tháng 10',
                result_train_11: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '11'),// 'Kết quả huấn luyện tháng 11',
                result_train_12: excludeByMonth(_currentTargetSS, _sessionTrainBySS, startYear, '12'),// 'Kết quả huấn luyện tháng 12',
                dcr_have_exam_room: _dcrHaveExamRoom, // 'Số lượt DCR tham gia',
                dcr_passed_exam_room: _dcrPassedExamRoom,// 'Số lượt DCR thi đạt',
                percent_dcr_join_train: (_dcrPassedExamRoom === 0 || _dcrHaveExamRoom === 0 ) ? '0%' : String(Math.round((_dcrPassedExamRoom / _dcrHaveExamRoom) * 100))+ "%", // 'Tỉ lệ đạt của DCR tham gia huấn luyện',
                average_rating: averageRatingSS(_sessionTrainBySS) // 'Điểm trung bình đánh giá'
            })
        })
    }
}

const convertDataSheetAsm = () => {
    dataSheetASM.value = [];
    if(dataSheetSS.value?.length > 0){
        const dataByAsm = _.groupBy(dataSheetSS.value, 'asm_email')
        let dataTmp: any = []
        for (const [key, value] of Object.entries(dataByAsm)) {
            let _total_session_train_status = 0;
            let _total_count_train_avg = 0;
            let _total_passed_process_train = 0;
            let _total_not_passed_process_train = 0;
            let _total_ss_not_train = 0;
            let _count_ss_not_train = 0;
            if(key !== 'undefined'){
                if(value.length > 0){
                    value.forEach((x: any) => {
                        _count_ss_not_train = 0;
                        _total_session_train_status += x?.session_train_status
                        _total_count_train_avg += x?.count_train_avg
                        _total_passed_process_train +=  x?.process_train === EXAM_RESULT_REPORT_STATUS.passed.text ? 1 : 0
                        _total_not_passed_process_train +=  x?.process_train === EXAM_RESULT_REPORT_STATUS.not_passed.text ? 1 : 0
                        _count_ss_not_train += x?.result_train_1 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_2 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_3 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_4 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_5 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_6 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_7 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_8 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_9 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_10 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_11 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0
                        _count_ss_not_train += x?.result_train_12 === EXAM_RESULT_REPORT_STATUS.incomplete.text_1 ? 1 : 0

                        if(_count_ss_not_train >= 3) {
                            _total_ss_not_train += 1
                        }
                    })
                    dataTmp.push({
                        area: value[0]?.sd_name, //'Khu vực',
                        asm_name: value[0].asm_name, // 'ASM',
                        asm_email: key,// 'ASM Email',
                        count_ss: value?.filter((j: any) => j?.ss_status === 'Active')?.length, // 'Số SS hiện hữu',
                        total_session_train_status: _total_session_train_status, // 'Số buổi T7HH được thực hiện YTD',
                        total_count_train_avg: _total_count_train_avg != 0 ? Math.round((_total_count_train_avg/value?.length)) : 0, // 'Số buổi trung bình thực hiện',
                        total_passed_process_train: _total_passed_process_train,// 'Số SS đạt tiến độ',
                        percent_ss_pass_train: _total_passed_process_train != 0 ? Math.round((_total_passed_process_train/value.length)*100) + "%" : "0%",// '% SS đạt tiến độ ',
                        total_not_passed_process_train: _total_not_passed_process_train,// 'Số SS không đạt tiến độ',
                        percent_ss_not_pass_train: _total_not_passed_process_train != 0 ? Math.round((_total_not_passed_process_train/value.length)*100)  + "%" : "0%",// '% SS không đạt tiến độ',
                        total_ss_not_train: _total_ss_not_train// 'Số SS 3 tháng không HL'
                    })
                }

            }
        }

        const dataAsmGroup = _.groupBy(dataTmp, 'area')
        let _count_ss = 0;
        let _total_session_train_status = 0;
        let _total_count_train_avg = 0;
        let _total_passed_process_train = 0;
        let _percent_ss_pass_train = 0;
        let _total_not_passed_process_train = 0;
        let _percent_ss_not_pass_train = 0;
        let _total_ss_not_train = 0;
        for (const [key, value] of Object.entries(dataAsmGroup)) {
            _count_ss = 0;
            _total_session_train_status = 0;
            _total_count_train_avg = 0;
            _total_passed_process_train = 0;
            _percent_ss_pass_train = 0;
            _total_not_passed_process_train = 0;
            _percent_ss_not_pass_train = 0;
            _total_ss_not_train = 0;

            value?.forEach((x: any) => {
                dataSheetASM.value.push(x);
                _count_ss += x?.count_ss
                _total_session_train_status += x?.total_session_train_status
                _total_count_train_avg += x?.total_count_train_avg
                _total_passed_process_train += x?.total_passed_process_train
                _total_not_passed_process_train += x?.total_not_passed_process_train
                _total_ss_not_train += x?.total_ss_not_train
            })
            dataSheetASM.value.push({
                area: key, //'Khu vực',
                asm_name: 'Total ' +  key, // 'ASM',
                asm_email: '',// 'ASM Email',
                count_ss: _count_ss, // 'Số SS hiện hữu',
                total_session_train_status: _total_session_train_status, // 'Số buổi T7HH được thực hiện YTD',
                total_count_train_avg: _total_count_train_avg != 0 ? Math.round((_total_count_train_avg/_count_ss)) : 0, // 'Số buổi trung bình thực hiện',
                total_passed_process_train: _total_passed_process_train,// 'Số SS đạt tiến độ',
                percent_ss_pass_train: _total_passed_process_train != 0 ? Math.round((_total_passed_process_train/_count_ss)*100) + "%" : "0%",// '% SS đạt tiến độ ',
                total_not_passed_process_train: _total_not_passed_process_train,// 'Số SS không đạt tiến độ',
                percent_ss_not_pass_train: _total_not_passed_process_train != 0 ? Math.round((_total_not_passed_process_train/_count_ss)*100)  + "%" : "0%",// '% SS không đạt tiến độ',
                total_ss_not_train: _total_ss_not_train// 'Số SS 3 tháng không HL'
            })
        }
    }
}
const displayLink = (idFile: string) => {
    return `${window.location.origin}/assets/${idFile}`
}

const header = [
    'Stt',
    'SD ID',
    'SD',
    'ASM ID',
    'ASM',
    'SS ID',
    'SS',
    'Email',
    'Tên khoá học',
    'Ngày mở',
    'NPP',
    'Số DCR',
    'Số DCR đã thi',
    'Số DCR đã đánh giá',
    '% DCR Tham gia thi',
    '% DCR tham gia đánh giá',
    'Thoả điều kiện DCR Tham gia thi',
    'Kết quả thực tế',
    'Trung bình điểm thi',
    'TB điểm đánh giá',
    'Số lượng DCR đạt bài kiểm tra',
    '% DCR đạt',
    'Hợp lệ',
    'Trạng thái',
    'Hình ảnh',
    '',
]
const headerSheetSS = [
    'Stt',
    'SD ID',
    'SD',
    'ASM ID',
    'ASM Email',
    'ASM',
    'SS ID',
    'SS',
    'Email',
    'Tháng gia nhập',
    'Trạng thái làm việc (Active/Terminate)',
    'Số tháng miễn trừ',
    'Số tháng cần thực hiện',
    'Chỉ tiêu số buổi cần thực hiện trong năm',
    'Chỉ tiêu số buổi cần thực hiện theo thời gian báo cáo',
    'Số buổi huấn luyện trung bình cần đạt',
    'Số buổi huấn luyện có tổ chức',
    'Số buổi huấn luyện hoàn thành',
    'Số buổi huấn luyện trung bình',
    '% Đạt so với target',
    'Tiến độ huấn luyện',
    'Số buổi còn phải thực hiện',
    'Số tháng không thực hiện huấn luyện',
    'Kết quả huấn luyện tháng 1',
    'Kết quả huấn luyện tháng 2',
    'Kết quả huấn luyện tháng 3',
    'Kết quả huấn luyện tháng 4',
    'Kết quả huấn luyện tháng 5',
    'Kết quả huấn luyện tháng 6',
    'Kết quả huấn luyện tháng 7',
    'Kết quả huấn luyện tháng 8',
    'Kết quả huấn luyện tháng 9',
    'Kết quả huấn luyện tháng 10',
    'Kết quả huấn luyện tháng 11',
    'Kết quả huấn luyện tháng 12',
    'Số lượt DCR tham gia',
    'Số lượt DCR thi đạt',
    'Tỉ lệ đạt của DCR tham gia huấn luyện',
    'Điểm trung bình đánh giá'
]

const headerSheetASM = [
    'Khu vực',
    'ASM',
    'ASM Email',
    'Số SS hiện hữu',
    'Số buổi T7HH được thực hiện YTD',
    'Số buổi trung bình thực hiện',
    'Số SS đạt tiến độ',
    '% SS đạt tiến độ ',
    'Số SS không đạt tiến độ',
    '% SS không đạt tiến độ',
    'Số SS 3 tháng không HL'
]

const generateExcelKey1 = async () => {
    const { utils, writeFile } = XLSX
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet([header])
    const wsSS = utils.aoa_to_sheet([headerSheetSS])
    const wsASM = utils.aoa_to_sheet([headerSheetASM])

    const transformedItems = dataSheetOpenedExamRoom.value.map((item: any) => {
        if (item._hyperlink) {
            const { Target, Tooltip } = item._hyperlink
            const imageLinkCell = [
                item.no,
                item.sd_id,
                item.sd_name,
                item.asm_id,
                item.asm_name,
                item.ss_id,
                item.ss_name,
                item.email,
                item.module_title,
                item.open_date,
                item.provider,
                item.dcr_total_on,
                item.dcr_exam_result,
                item.number_assessment,
                item.dcr_percent_exam,
                item.dcr_percent_assessment,
                item.dcr_join_exam,
                item.actual_results,
                item.average_score,
                item.average_rating,
                item.number_dcr_passed,
                item.percent_dcr_passed,
                item.valid,
                item.status,
                item.image_text,
                {
                    t: 's',
                    l: { Target, Tooltip },
                    v: item._hyperlink.Display,
                },
            ]

            return imageLinkCell
        }

        return null
    })

    utils.sheet_add_aoa(ws, transformedItems, { origin: -1 })
    utils.sheet_add_aoa(wsSS, dataSheetSS.value.map((x: any) => Object.values(x)), { origin: -1 })
    utils.sheet_add_aoa(wsASM, dataSheetASM.value.map((x: any) => Object.values(x)), { origin: -1 })
    utils.book_append_sheet(wb, ws, 'Chi tiết khoá học đã mở')
    utils.book_append_sheet(wb, wsSS, 'Theo SS')
    utils.book_append_sheet(wb, wsASM, 'Theo ASM')

    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_Details_of_the_opened_course_saturday.xlsx'
    )
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// end tab 1

/////////////////////////////////////////////////////// tab 3
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

const headerDetailSurvey = [
    'STT',
    'Zone',
    'SD',
    'Area',
    'ASM Name',
    'SS ID',
    'SS Name',
    'Mã Route',
    'Mã DCR',
    'DCR Name',
    'Tên khoá học',
    'Ngày mở',
    'Câu hỏi đánh giá',
    'Điểm',
    'Nội dung',
]
const convertDetailSurvey = () => {
    let dataOuput: any = []
    if (dataSesionTrain.value.length > 0) {
        let detailSurvey: any
        dataSesionTrain.value.forEach((x: any) => {
            if (x.exam_rooms?.length > 0) {
                x.exam_rooms.forEach((k: any) => {
                    if (k?.survey_submit?.length > 0 && k?.survey_submit[0].detail) {
                        detailSurvey = JSON.parse(k?.survey_submit[0].detail)
                        detailSurvey.forEach((j: any) => {
                            if (j?.type === 'rate') {
                            }
                            dataOuput.push({
                                stt: '', // STT
                                zone: x?.zone?.name, // Zone
                                sd: '', // SD
                                area: x?.area?.name,
                                asm_full_name: x?.asm?.full_name, // ASM Name
                                ss_id: x?.user_created?.code, // SS ID
                                ss_full_name: x?.user_created?.full_name, // SS full name
                                dcr_route: k?.user?.route_code?.name, // Mã DCR
                                dcr_code: k?.user?.code, // Mã DCR
                                dcr_full_name: k?.user?.full_name,
                                course_name: k?.topic?.title, // Tên khoá học
                                open_date: k?.date_created ? new Date(k?.date_created) : '', // Ngày mowr
                                survey_question: j?.title, // Câu hỏi đánh giá
                                point: j?.type === 'rate' ? j?.answer : '', // Điểm
                                content_answer: j?.type !== 'rate' ? j?.answer : '', // Nội dung
                            })
                        })
                    }
                })
            }
        })
    }
    dataSurvey.value = []
    if (dataOuput.length > 0) {
        dataOuput = sortData(dataOuput, 'dcr_full_name')
        dataOuput = dataOuput.map((x: any, index: number) => {
            return {
                ...x,
                stt: index + 1,
            }
        })
        dataSurvey.value = dataOuput
    }
}
const generateExcelKey3 = async () => {
    const { utils, writeFile } = XLSX
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet([headerDetailSurvey])

    utils.sheet_add_aoa(
        ws,
        dataSurvey.value.map((x) => Object.values(x)),
        { origin: -1 }
    )
    utils.book_append_sheet(wb, ws, 'Chi tiết')
    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_Details_Survey_Of_saturday_learning.xlsx'
    )
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// end tab 3

/////////////////////////////////////////////////////// tab 4
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

const headerDetailExam = [
    'STT',
    'Zone',
    'SD',
    'Area',
    'ASM Name',
    'SS ID',
    'SS Name',
    'email',
    'Mã Route',
    'Mã DCR',
    'DCR Name',
    'Tên khoá học',
    'Ngày mở',
    'Lần thi',
    'Câu hỏi',
    // 'DCR trả lời',
    // 'Câu trả lời đúng',
    'Kết quả'
]
const convertDetailExam = () => {
    let dataOuput: any = []
    if (dataSesionTrain.value.length > 0) {
        dataSesionTrain.value.forEach((x: any) => {
            if (x.exam_rooms?.length > 0) {
                x.exam_rooms.forEach((k: any) => {
                    if (k?.results && k?.results?.length > 0) {
                        k?.results.forEach((examResult: any, index: number) => {
                            let detailExam: any
                            detailExam = JSON.parse(examResult.detail)
                            if (detailExam && detailExam.length > 0) {
                                detailExam.forEach((j: any) => {
                                    dataOuput.push({
                                        stt: '', // STT
                                        zone: x?.zone?.name, // Zone
                                        sd: '', // SD
                                        area: x?.area?.name,
                                        asm_full_name: x?.asm?.full_name, // ASM Name
                                        ss_id: x?.user_created?.code, // SS ID
                                        ss_full_name: x?.user_created?.full_name, // SS full name
                                        ss_email: x?.user_created?.email, // SS email
                                        dcr_route: k?.user?.route_code?.name, // Mã route
                                        dcr_code: k?.user?.code, // Mã DCR
                                        dcr_full_name: k?.user?.full_name,
                                        course_name: k?.topic?.title, // Tên khoá học
                                        open_date: k?.date_created ? new Date(k?.date_created) : '', // Ngày mowr
                                        number_of_exam: index + 1, // Lần thi
                                        title_question: j?.title_question, // Câu hỏi
                                        // answer_dcr: j?.answers.map((answer: any) => answer.answer).join(', '), // DCR trả lời
                                        // answer_correct: j?.answer_correct?.map((answer: any) => answer).join(', ') || '', // Câu trả lời đúng
                                        // Tồn tại 1 đáp án sai hoặc số câu DCR trả lời không bằng số câu tl đúng => sai
                                        result: !j?.is_correct ? 'Sai' : 'Đúng' //// kết quả
                                    })
                                })
                            }
                        })
                    }
                })
            }
        })
    }
    dataExam.value = []
    if (dataOuput.length > 0) {
        dataOuput = sortData(dataOuput, 'dcr_full_name')
        dataOuput = dataOuput.map((x: any, index: number) => {
            return {
                ...x,
                stt: index + 1,
            }
        })
        dataExam.value = dataOuput
    }
}
const generateExcelKey4 = async () => {
    const { utils, writeFile } = XLSX
    const wb = utils.book_new()
    const ws = utils.aoa_to_sheet([headerDetailExam])

    utils.sheet_add_aoa(
        ws,
        dataExam.value.map((x: any) => Object.values(x)),
        { origin: -1 }
    )
    utils.book_append_sheet(wb, ws, 'Chi tiết bài thi')
    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_Details_Exam_Of_saturday_learning.xlsx'
    )
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// end tab 4
</script>

<style lang="scss" scoped>
.vertical-dash {
    width: 1px;
    background-color: #e2e8f0;
}
</style>
