<template>
    <v-breadcrumb class="px-10 py-8" :items="breadcrumbCustom" />
    <div class="max-w-[720px] py-10 mx-auto">
        <div class="font-bold text-[22px] mb-8">{{ data?.title }}</div>
        <div
            class="w-full flex bg-[#F1F5F9] rounded-tl-lg rounded-tr-lg border border-b-0 border-solid border-[#E2E8F0]"
        >
            <div class="w-1/3 px-3 py-4 border-y-0 border-l-0 border-r border-solid border-[#E2E8F0]">Lần thi</div>
            <div class="w-2/3 text-center py-4">{{ data?.examinations }}</div>
        </div>
        <div class="w-full flex border-[#E2E8F0] border-x border-y-0 border-solid">
            <div class="w-1/3 px-3 py-4 border-y-0 border-l-0 border-r border-solid border-[#E2E8F0]">Ngày</div>
            <div class="w-2/3 text-center py-4">{{ dayjs(data?.date_created).format(FORMAT_TIME.DDMMYYYYhmm) }}</div>
        </div>
        <div class="w-full flex bg-[#F1F5F9] border-[#E2E8F0] border-x border-y-0 border-solid">
            <div class="w-1/3 px-3 py-4 border-y-0 border-l-0 border-r border-solid border-[#E2E8F0]">Số câu đúng</div>
            <div class="w-2/3 text-center py-4">{{ data?.total_score }}</div>
        </div>
        <div class="w-full flex border-[#E2E8F0] border-x border-y-0 border-solid">
            <div class="w-1/3 px-3 py-4 border-y-0 border-l-0 border-r border-solid border-[#E2E8F0]">
                Phần trăm đúng
            </div>
            <div class="w-2/3 text-center py-4">{{ data?.percent_total_score }}%</div>
        </div>
        <div
            class="w-full flex bg-[#F1F5F9] rounded-bl-lg rounded-br-lg border-[#E2E8F0] border border-t-0 border-solid"
        >
            <div class="w-1/3 px-3 py-4 border-y-0 border-l-0 border-r border-solid border-[#E2E8F0]">Kết quả</div>
            <div class="w-2/3 py-4 flex justify-center">
                <result-status :is-pass="data?.is_pass"></result-status>
            </div>
        </div>
        <div class="border-bottom w-full my-8"></div>
        <div v-for="(question, index) in data?.questions" :key="question.id">
            <div class="font-bold mb-6">
                <span class="text-[#F98620]">Câu hỏi&nbsp;</span>
                <span class="text-[#F98620]">{{ index + 1 }}</span>
                <span class="text-[#F98620]">{{ ': ' }}</span>
                <span>{{ question?.question }}</span>
            </div>
            <answer-sentence
                :answers="question.answers"
                :explain="question.explain"
                :no-answer="question.noAnswer"
            ></answer-sentence>
            <div class="border-bottom w-full my-8"></div>
        </div>

        <v-button class="flex justify-center w-full" secondary @click="handleBack">
            <v-icon class="text-[#334155] mr-2" name="arrow_back"></v-icon>
            Danh sách bài thi
        </v-button>
    </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { Ref, ref, watch, onMounted, computed } from 'vue'
import useExamResult from '../../../../../shared-fe/composables/use-exam-result'
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import AnswerSentence from './answer-sentence.vue'
import ResultStatus from './result-status.vue'
import { useRoute, useRouter } from 'vue-router'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const router = useRouter()
const route = useRoute()
const data: Ref<any> = ref(null)
const { getExamResultDetail } = useExamResult()

const handleBack = () => {
    router.push({
        name: PATH_ROUTER.RESULT_EXAM_MODULE.NAME,
        params: {
            session_train: route.params?.session_train,
            user_id: route.params?.user_id,
            module_id: data.value?.exam_room?.topic?.id,
        },
    })
}
const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})
watch(
    () => [route?.params?.user_id, route?.params?.exam_result_id],
    async () => {
        if (route?.params?.user_id && route?.params?.exam_result_id) {
            const res = await getExamResultDetail(Number(route?.params?.exam_result_id), String(route?.params?.user_id))
            data.value = res
        }
    },
    { immediate: true }
)

const breadcrumbCustom = computed(() => {
    let items = [
        { icon: 'local_library', to: '/ld' },
        { name: `${labelUserType.value} Onboarding `, to: '/ld' },
        { name: 'Tiến độ đào tạo', to: `/ld/session-train-detail/${route.params?.session_train}` },
        {
            name: 'Kết quả các đợt thi',
            to: `/ld/result-exam-module/${route.params?.session_train}/${route.params?.user_id}/${data.value?.exam_room?.topic?.id}`,
        },
        {
            name: 'Chi tiết đáp án',
            disabled: true,
        },
    ]

    return items
})
</script>
<style lang="scss">
.v-breadcrumb .section.disabled .section-link {
    font-size: 14px;
    color: #1939ae !important;
}
</style>
