<template>
    <c-access :roles="[USER_ROLE.SS, USER_ROLE.HR]">
        <sidebar-detail icon="pending" class="interview-steps" title="Chờ nhận việc">
            <v-skeleton-loader v-if="loadingInterview" class="c-loading" type="input-tall" />
            <div v-else>
                <div class="mb-2 text-[#1E293B]">Ghi chú</div>
                <div class="mb-6">
                    <v-textarea
                        placeholder="Nhập nội dung"
                        :model-value="content"
                        @update:model-value="(e) => (content = e)"
                    />
                    <span class="message-error" v-if="errContent != null">{{ errContent }}</span>
                </div>
                <div class="list-btn w-full mt-6 pt-6 border-t-1px border-t-solid border-[#D3DAE4]">
                    <v-button
                        class="w-full btn-fail mb-3"
                        @click="
                            () =>
                                handleConfirm(
                                    CONFIRM_MODAL_TEXT.GET_THE_JOB.REJECT,
                                    async () => await submit(CANDIDATE_STATUS.not_hired.value, false)
                                )
                        "
                    >
                        <div class="">Không nhận việc</div>
                    </v-button>
                    <v-button
                        class="w-full btn-pass"
                        @click="
                            () =>
                                handleConfirm(
                                    CONFIRM_MODAL_TEXT.GET_THE_JOB.OK,
                                    async () => await submit(CANDIDATE_STATUS.hired.value, true)
                                )
                        "
                    >
                        <div class="">Nhận việc</div>
                    </v-button>
                </div>
                <div class="mt-3 text-[#D97706]">{{ messageInterview }}</div>
            </div>
        </sidebar-detail>
    </c-access>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import CAccess from '../../../../../../shared-fe/components/c-access/c-access.vue'
import { useInterviewDetailQuery } from '../../../../../../shared-fe/services/interview-detail-service'
import { useOptionQuery } from '../../../../../../shared-fe/services/option-service'
import { CANDIDATE_STATUS, CONFIRM_MODAL_TEXT, USER_ROLE } from '../../../../../../shared-fe/utils/constant'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import { useUserStore } from '@/stores/user'
import { onMounted } from 'vue'
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})
const content = ref('')
const errContent = ref(<any>null)
const loadingInterview = ref(false)
const messageInterview = ref('')
const arrStatus = ref([])
const { postItemInterviewDetail } = useInterviewDetailQuery()
const userStore: any = useUserStore()
const confirmModal = useConfirmModal()
const emit = defineEmits(['handleSubmit'])

const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
}

onMounted(async () => {
    const options = userStore.currentUser.options
    let _arrStatus: any = []
    if (options.length > 0) {
        options.forEach((x) => {
            if (x.opt_group === 'candidate_status') {
                _arrStatus.push({
                    opt_key: x.opt_key,
                    id: x.id,
                })
            }
        })
    }
    arrStatus.value = _arrStatus
})
const submit = async (val, _status) => {
    errContent.value = ''
    if (!content.value && val === CANDIDATE_STATUS.not_hired.value) {
        errContent.value = 'Vui lòng nhập nội dung'
    } else {
        errContent.value = ''
    }

    if (errContent.value) {
        return
    }
    loadingInterview.value = true
    let findIdStatus = arrStatus.value?.find((x: any) => x.opt_key === val)
    let params: any = {
        status: _status,
        content: content.value,
        candidate_status: CANDIDATE_STATUS.waiting_for_job.value,
        candidate_status_id: findIdStatus?.id,
        id: props.item.candidate,
        interview_round: props.item?.id,
    }

    await postItemInterviewDetail({
        ...params,
    })
    setTimeout(() => {
        messageInterview.value = 'Đã hoàn thành đánh giá'
        loadingInterview.value = false
        content.value = ''
        setTimeout(() => {
            emit('handleSubmit')
        }, 2000)
    }, 2000)
}
</script>
