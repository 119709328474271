<template>
	<svg
		viewBox="0 0 24 24"
		width="24"
		height="24"
		fill="none"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path
			d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4a2 2 0 00-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
		/>
	</svg>
</template>

<script lang="ts">
export default {};
</script>
