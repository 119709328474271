import { ref } from "vue";
import { useApi } from "@directus/composables";

export const useFieldsCom = (keyName, groupOptions = {}) => {
    const formData = ref(<any>{});
    const idSettings = ref(null);
    const formLoading = ref(false);

    const fieldGroup = (fieldName, label = null, metas = {}) => {
        return {
            "field": fieldName,
            "type": "alias",
            name: label || fieldName,
            "meta": {
                "field": fieldName,
                "special": [
                    "alias",
                    "no-data",
                    "group"
                ],
                "options": {
                    headerColor: "#0eb4b2",
                    ...groupOptions.group ?? {}
                },
                "interface": "group-detail",
                ...metas
            }
        }
    }
    const fieldInput = (fieldName, label = null, metas = null) => {

        let options = {}
        if (metas.interface === "input-rich-text-html") {
            options = {
                "toolbar": [
                    "bold",
                    "italic",
                    "underline",
                    "h1",
                    "h2",
                    "h3",
                    "numlist",
                    "bullist",
                    "forecolor",
                    "backcolor",
                    "removeformat",
                    "blockquote",
                    "customLink",
                    "customImage",
                    "customMedia",
                    "hr",
                    "code",
                    "fullscreen"
                ]
            }
        }

        return {
            "field": fieldName,
            "type": "string",
            name: label || fieldName,
            "meta": {
                "field": fieldName,
                "interface": "input",
                options,
                ...metas
            }
        }
    }

    const fieldInputNumber = (fieldName, label = null, metas = null) => {
        return {
            "field": fieldName,
            "type": "integer",
            name: label || fieldName,
            "meta": {
                "field": fieldName,
                "interface": "input",
                ...metas
            }
        }
    }
    const fieldSmallFile = (fieldName, label = null, metas = null) => {
        return fieldInput(fieldName, label, {
            ...metas,
            "note": "",
            "interface": "app-upload",
        });

        return {
            "field": fieldName,
            "type": "string",
            name: label || fieldName,
            "meta": {
                "field": fieldName,
                "interface": "file",
                ...metas
            }
        }
    }

    const fieldRepeater = (fieldName, label = null, metas = null) => {

        return {
            "field": fieldName,
            name: label || fieldName,
            "meta": {
                "field": fieldName,
                "interface": "list",
                ...metas
            }
        }
    }

    const api = useApi();
    const apiPath = "/items/live_editor"
    api.get(apiPath, {
        params: {
            filter: {
                group: keyName,
            },
            sort: ["-group"]
        }
    }).then(async (res) => {
        const [model] = res.data?.data;
        if (!model) {
            await api.post(apiPath, {
                group: keyName
            }).then(__res => {
                idSettings.value = __res.data?.data?.id
            })
        } else {
            idSettings.value = model.id;
            try {
                const settings = JSON.parse(model.option_value);
                formData.value = settings ?? {}
            } catch (e) {
            }
        }
    });

    const save = async () => {
        formLoading.value = true;
        await api.patch(`${apiPath}/${idSettings.value}`, {
            option_value: JSON.stringify(formData.value),
            option_key: "main_settings"
        })
        formLoading.value = false;
    }

    return {
        idSettings,
        formLoading,
        formData,
        save,
        fieldGroup,
        fieldInput,
        fieldSmallFile,
        fieldInputNumber,
        fieldRepeater
    }
}
