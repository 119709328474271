import {defineInterface} from '@directus/utils';
import interFaceSelectM20 from './select-m2o.vue';

export default defineInterface({
    icon: "",
    options: undefined,
    id: 'app-select-m20',
    name: 'Lựa chọn m20',
    component: interFaceSelectM20,
    types: ['uuid', 'string', 'text', 'integer', 'bigInteger'],
    relational: true,
    localTypes: ['m2o'],
    group: 'relational'
})
