<template>
    <global-target-management-table
        :items="itemsRender"
        :page="page"
        :loading="loading"
        @updateSort="handleUpdateSort"
        @refresh="refresh()"
    ></global-target-management-table>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useItems } from '@directus/composables'
import { TARGETS_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import globalTargetManagementTable from './global-target-management-table.vue'
import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'
import { watch } from 'vue'

const props = defineProps({
    refresh: {
        type: Boolean,
        default: false,
    },
})

const sort = ref(['-date_created'])
const filter = ref(null)
const page = ref(1)
const { canL1AndL2 } = useAppPermissions()

const customFilter = computed(() => {
    let _filter = {}

    if (canL1AndL2.value) {
        _filter = {
            type: 'global',
        }
    } else {
        _filter = {
            _and: [
                {
                    type: 'user',
                },
                {
                    user: '$CURRENT_USER',
                },
            ],
        }
    }

    return _filter
})

const { items, loading, getItems, getItemCount, getTotalCount } = useItems(ref(TARGETS_COLLECTION), {
    fields: ref(['*']),
    limit: ref(-1),
    sort,
    search: ref(null),
    filter: customFilter.value,
    page: page,
})

const refresh = () => {
    getItems()
    getItemCount()
    getTotalCount()
}

const MONTH = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
]

const itemsRender = computed(() => {
    let _itemsRender: any = []
    items.value.forEach((x) => {
        let totalTargets = 0
        Object.keys(x).forEach((key) => {
            if (MONTH.includes(key)) {
                totalTargets += x[key] || 0
            }
        })
        
        _itemsRender.push({
            ...x,
            january: x.january !== undefined ? String(x.january) : x.january,
            february: x.february !== undefined ? String(x.february) : x.february,
            march: x.march !== undefined ? String(x.march) : x.march,
            april: x.april !== undefined ? String(x.april) : x.april,
            may: x.may !== undefined ? String(x.may) : x.may,
            june: x.june !== undefined ? String(x.june) : x.june,
            july: x.july !== undefined ? String(x.july) : x.july,
            august: x.august !== undefined ? String(x.august) : x.august,
            september: x.september !== undefined ? String(x.september) : x.september,
            october: x.october !== undefined ? String(x.october) : x.october,
            november: x.november !== undefined ? String(x.november) : x.november,
            december: x.december !== undefined ? String(x.december) : x.december,
            total_targets: totalTargets,
        })
    })
    return _itemsRender
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}

watch(
    () => props.refresh,
    () => {
        refresh()
    }
)
</script>

<style></style>
