<template>
    <v-dialog v-model="isShow" @esc="handleCloseModal" placement="center custom-width">
        <v-card>
            <v-card-title>
                <div class="w-full h-full">
                    <div class="text-5 text-[#1E293B] mb-8 whitespace-pre-line text-center mx-10">
                        {{
                            mode !== 'edit'
                                ? `Bạn đang Onboarding cho ${labelUserType} thuộc kênh bán hàng nào?`
                                : `Vui lòng chọn lại kênh bán hàng để Onboarding cho ${labelUserType}`
                        }}
                    </div>
                    <div class="justify-between items-center gap-4">
                        <div v-for="item in channels"
                            class="on-off-btn mb-3"
                            :class="item?.status ? 'text-white bg-[#67C2C3]' : ''"
                            @click="updateStatus(item.id)"
                        >
                            {{ item.name  }}
                        </div>
                    </div>
                    <div
                        v-if="mode === 'edit'"
                        class="p-4 border-l-4 border-solid border-[#D14F65] bg-[#FCEEF0] rounded-lg flex gap-3 mb-3 items-center my-8"
                    >
                        <div class="text-red-500">
                            <v-icon style="--v-icon-size: 44px" name="info"></v-icon>
                        </div>
                        <div class="text-red-500">
                            Sau khi chọn lại, toàn bộ dữ liệu đào tạo và khóa thi trước đó của {{ labelUserType }} sẽ bị xóa
                        </div>
                    </div>
                </div>
            </v-card-title>

            <v-card-actions>
                <div class="flex w-full gap-6 justify-center">
                    <v-button v-if="mode === 'edit'" class="modal-on-off-button" secondary @click="handleCloseModal">
                        Hủy bỏ
                    </v-button>
                    <v-button class="modal-on-off-button" @click="handleSubmit">Xác nhận</v-button>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import { TYPE_OF_TRAIN } from '@/shared-fe/utils/constant'
import { ref, computed } from 'vue'
import { watch } from 'vue'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const channels = ref(userStore.currentUser[TYPE_OF_TRAIN.OB]);
if(channels.value.length > 0){
    channels.value.map(x => x['status'] = false );
    channels.value[0].status = true;
}

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})


const idChannel = ref();
const props = defineProps({
    isShow: {
        type: Boolean,
        default: false,
    },
    channel: {
        type: String,
        default: '',
    },
    mode: {
        type: String,
        default: 'add',
    },
})

const { isShow } = toRefs(props)
const emit = defineEmits(['submit', 'close'])

const handleCloseModal = () => {
    emit('close')
}

const handleSubmit = () => {
    emit('submit', idChannel.value)
}

const updateStatus = (id) =>{
    channels.value.map(x => x.status = false)
    let index = channels.value.findIndex(x => x.id === id);
    if(index >= 0){
        channels.value[index].status = true;
        idChannel.value = channels.value[index].id
    }
}

watch(
    () => props.isShow,
    () => {
        idChannel.value = channels.value[0].id
        if (props.channel) {
            idChannel.value = props.channel
        }
        updateStatus(idChannel.value);
    }
)
</script>

<style lang="scss" scoped></style>
