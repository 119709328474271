const RESIGN_REPORTS = [
    'Tháng',
    'Chi nhánh',
    'Khu vực',
    'Mã NPP',
    'NPP',
    'Mã Route (DMS)',
    'Mã DCR',
    'Họ và tên DCR',
    'Ngày bắt đầu làm việc',
    'Ngày làm việc cuối cùng',
    'Tháng nghỉ việc',
    'Miễn trừ (Nếu có)',
    'Loại nghỉ việc',
    'Lý do nghỉ việc (final)',
    'Lý do nghỉ việc (P&C)',
    'Chi tiết lý do (P&C)',
    'Lý do nghỉ việc (SS)',
    'Chi tiết lý do (SS)',
    'Điều chỉnh loại nghỉ việc (nếu có)',
    'Location Detail',
    'ASM',
    'SS',
    'Số tháng làm việc',
    'Thâm niên',
    'Kết quả Onboarding (DCR ≤ 2 tháng)',
    'Route Type'
]

const DCR_ON_REPORTS = [
    'Month',
    'Tháng',
    'Khu vực',
    'Code NPP (new)',
    'Tên NPP',
    'Route ID(new)',
    'Mã NV',
    'Tên NV',
    'Ngày gia nhập SPVB',
    'Location',
    'Location Detail',
    'Area',
    'Route Type',
]

export { RESIGN_REPORTS, DCR_ON_REPORTS }