import { useFormatDate } from '@/shared-fe/composables/use-utils'

import { computed, ref, watch } from 'vue'
import { useFieldTemplate } from '@/shared-fe/composables/use-field-template'
import { notify } from '@/utils/notify'
import api from '@/api'
import { RESIGN_COLLECTION } from '@/shared-fe/utils/table-name'
import { RECRUITMENT_REQUEST_STATUS, RESIGN_STATUS } from '@/shared-fe/utils/constant'

const { fieldInput } = useFieldTemplate({})

export const useReplacementForm = ({ fieldReOrder, currentDCR }, model, initValue) => {
    const resignModel = ref({})
    const isReplacement = computed(() => {
        if (model.value.hiring_reason == 'replacement') return true
        return !model.value.hiring_reason && initValue.value.hiring_reason == 'replacement'
    })

    const fetchResign = async (id) => {
        resignModel.value = {}
        if (!id) {
            return
        }
        api.get(`/items/${RESIGN_COLLECTION}/${id}`, {
            params: {
                fields: [
                    '*',
                    'reason.*'
                ]
            }
        }).then((res) => {
            if (res.data.data) {
                const item = res.data.data
                model.value.ss_id = item.ss_id
                model.value.hiring_reason = 'replacement'

                resignModel.value = {
                    last_working_day: item.last_working_day ?? '',
                    reason: item.reason?.opt_value ?? '',
                    reason_detail: item.note ?? ''
                }

                setTimeout(() => {
                    model.value.staff_id = item.staff_id
                    model.value.resign_id = item.id
                }, 50)
            }
        })
    }

    const resignId = computed(() => {
        if (model.value.resign_id === undefined) {
            return initValue.value.resign_id
        }
        return model.value.resign_id
    })
    watch(
        () => resignId.value,
        () => {
            fetchResign(resignId.value).catch()
        }
    )
    watch(
        () => model.value.hiring_reason,
        () => {
            model.value.resign_id = ''
        }
    )

    const infoDCRfields = computed(() => {
        return [
            fieldInput('__dcr_code', 'Mã DCR', {
                readonly: true,
                options: {
                    placeholder: computed(() => currentDCR.value.code)
                }
            }),
            fieldInput('__dcr_route', 'Mã Route', {
                readonly: true,
                options: {
                    placeholder: computed(() => currentDCR.value.route)
                }
            })
        ]
    })

    const formReplacement = computed(() => {
        //if (!displayBoxRegin.value) return []
        let ctxFields = fieldReOrder.value.staff_id ? [fieldReOrder.value.staff_id] : []
        fieldReOrder.value.staff_id.meta.interface = 'app-select-m20'
        fieldReOrder.value.staff_id.meta.options = {
            label_field: 'full_name',
            limit: -1,
            callback_item: (item: any) =>{
                return item.filter((item: any) => {
                    
                    // Chỉ lấy các DCR có YCTD vs status không thuộc ['Hoàn thành', 'Chờ duyệt', 'Đang tuyển']
                    let resCheckRrStatus= !(item.requests.find((req: any) => {
                        return [
                            RECRUITMENT_REQUEST_STATUS.completed.value,
                            RECRUITMENT_REQUEST_STATUS.pending.value,
                            RECRUITMENT_REQUEST_STATUS.processing.value

                        ].indexOf(req.status)  >= 0
                    }))
                    // Chỉ lấy các DCR có Resign vs status không thuộc ['Từ chối', 'Đã huỷ', 'Đã xoá']
                    let resCheckResignStatus = true;
                    if(item.resigns.length > 0){
                        const resign = item.resigns.sort((a: any, b: any) => (new Date(b.date_created)).getTime() - (new Date(a.date_created)).getTime())[0]
                        if(resign.status === RESIGN_STATUS.rejected.value || 
                            resign.status === RESIGN_STATUS.cancelled.value || 
                            resign.status === RESIGN_STATUS.archived.value){
                                resCheckResignStatus = false;
                        }
                    }else{
                        resCheckResignStatus = false;
                    }
           
                    // chỉ trả về dcr khi thoả mãn 2 điều kiện trên
                    return (resCheckRrStatus && resCheckResignStatus);
                })
            },
            fields: [
                "id", "full_name", "requests.id", "requests.status", "resigns.status", "resigns.date_created"
            ],
            filter: computed(() => {
                const _or = []
                if (initValue.value.id > 0) {
                    _or.push({
                        requests: initValue.value.id
                    })
                }

                return {
                    _and: [
                        {
                            parent_id: model.value.user_created ?? initValue.value.user_created
                        },
                        {
                            system_providers: {
                                provider_id: { _in: currentDCR.value.system_providers }
                            }
                        },
                        {
                            role: {
                                short_name: 'dcr'
                            }
                        },
                        {
                            resigns: {
                                _nnull: true
                            }
                        },
                        {
                            _or
                        }
                    ]
                }
            })
        }

        ctxFields[0].name = 'Chọn DCR nghỉ việc'

        if (!ctxFields) return []

        ctxFields = ctxFields.concat(infoDCRfields.value)

        ctxFields.push(
            fieldInput('__resign_last_day', 'Ngày làm việc cuối', {
                readonly: true,
                options: {
                    placeholder: computed(() => {
                        if (!model.value.resign_id) return ''
                        return useFormatDate(resignModel.value.last_working_day)
                    })
                },
                type: 'date'
            })
        )
        ctxFields.push(
            fieldInput('__resign_reason', 'Lý do nghỉ việc SS', {
                readonly: true,
                options: {
                    placeholder: computed(() => resignModel.value.reason)
                }
            })
        )
        ctxFields.push(
            fieldInput('__resign_reason_detail', 'Chi tiết lý do SS', {
                readonly: true,
                options: {
                    placeholder: computed(() => resignModel.value.reason_detail)
                }
            })
        )

        let loop = 1
        for (let f of ctxFields) {
            loop++
            f.meta.sort = loop
            f.meta.group = null
        }

        console.log('ctxFields: ', ctxFields)
        return ctxFields
    })

    return {
        formReplacement,
        currentDCR,
        isReplacement,
        resignModel
    }
}
