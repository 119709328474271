<template>
    <div v-if="dataRender && dataRender.length > 0">
        <div v-for="(item, index) in dataRender" class="pb-1">
            <span v-if="showListNumbers">{{ index + 1 }}.{{ ' ' }}</span>
            <span>{{ item }}</span>
        </div>
        <div v-if="showMore" class="mt-6 show-more" @click="handelShowAllData">
            {{ showMoreText ? 'Xem thêm' : '+' + countShow + ' Khu vực' }}
        </div>
        <div v-if="collapse" class="mt-6 collapse" @click="handelShowData">Thu gọn</div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'

interface Props {
    data: Array<any>
    maxItemDisplay: number
    showListNumbers: boolean
    showMoreText: {
        type: Boolean
        default: false
    }
}
const props = withDefaults(defineProps<Props>(), {
    maxItemDisplay: 3,
    data: undefined,
    showListNumbers: undefined,
    showMoreText: undefined,
})

const countShow = ref(0)

const dataRender = ref(<Array<any>>[])

onMounted(() => {
    handelShowData()
})

const showMore = ref(<boolean>false)

const handelShowMore = () => {
    if (props.data && props.data.length - props.maxItemDisplay > 0) {
        countShow.value = props.data.length - props.maxItemDisplay
        showMore.value = true
    } else {
        showMore.value = false
    }
}

const collapse = computed(() => {
    if (
        !showMore.value &&
        props.data?.length === dataRender.value.length &&
        props.data?.length > props.maxItemDisplay
    ) {
        return true
    }
    return false
})

const handelShowData = () => {
    let itemRender: Array<any> = []
    if (props.data && props.data.length > 0) {
        for (let [i, v] of props.data.entries()) {
            if (props.data[i] && i < props.maxItemDisplay) {
                itemRender.push(props.data[i])
            }
        }
    }
    dataRender.value = itemRender
    handelShowMore()
}

const handelShowAllData = () => {
    let itemRender: Array<any> = []
    if (props.data && props.data.length > 0) {
        for (let v of props.data) {
            itemRender.push(v)
        }
    }
    dataRender.value = itemRender
    showMore.value = false
}
</script>

<style lang="scss" scoped>
.show-more,
.collapse {
    color: #62b1eb;
}
</style>
