<template>
    <v-input
        type="date"
        @click="handleClickDateInput"
        :model-value="props.value"
        @update:model-value="handleChange"
        :disabled="disabled"
        placeholder="DD/MM/YYYY"
    ></v-input>
    <span class="message-error" v-if="error">Ngày tháng năm không hợp lệ</span>
</template>

<script setup lang="ts">
import { isValid } from 'date-fns'
import { ref } from 'vue'

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
})
const error = ref('')

const emit = defineEmits<{
    (e: 'input', value: string | null): void
}>()

const handleChange = (value: string) => {
    error.value = ''
    if (isValid(new Date(value))) {
        emit('input', value)
        return
    }
    error.value = 'error'
}

const handleClickDateInput = (e: any) => {
    e.preventDefault()
}
</script>

<style lang="scss" scoped></style>
