<template>
    <v-select
        :model-value="dataModel"
        :items="items"
        multiple
        showDeselect
        :multiplePreviewThreshold="6"
        @update:model-value="handleChange"
    ></v-select>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { converObjectSelect } from '../../utils/convert-data'

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    dataSeleted: {
        type: Array<Object>,
        default: [],
    },
    idTableLink: {
        type: Number,
        default: null,
    },
    dataInit: {
        type: Array<Object>,
        default: [],
    },
    keyTableLink: {
        type: String,
        default: '',
    },
    keyTableMain: {
        type: String,
        default: '',
    },
    tableMain: {
        type: String,
        default: '',
    },
})

const emit = defineEmits(['update:handleChange'])
const dataModel = computed(() => {
    let _dataModel: Array<string> = []
    if (props.dataSeleted.length > 0) {
        props.dataSeleted.forEach((x: any) => {
            _dataModel.push(String(x[props.keyTableMain]))
        })
    }
    console.log('_dataModel: ', props.dataSeleted)
    return _dataModel
})

const handleChange = (e) => {
    let dataModel: any = []
    if (e) {
        let findRes: any = {}
        e.forEach((x, index) => {
            dataModel[index] = {
                id: null,
                [props.keyTableMain]: x,
                [props.keyTableLink]: props.idTableLink,
            }
            findRes = props.dataInit.find((pr: any) => String(pr[props.keyTableMain]) === x)
            if (findRes) {
                dataModel[index].id = findRes.id
            }
        })
    }
    let dataUpdateApi: any = converObjectSelect(
        props.dataInit,
        e || [],
        String(props.idTableLink),
        props.keyTableLink,
        props.keyTableMain,
        props.tableMain
    )
    emit('update:handleChange', { dataModel, dataUpdateApi })
}
</script>
