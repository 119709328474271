import { defineInterface } from '@directus/utils';
import appUploads from './app-uploads.vue';
import PreviewSVG from './preview.svg?raw';

export default defineInterface({
	id: 'app-uploads',
	name: 'App Uploads',
	description: '',
	icon: 'insert_photo',
	component: appUploads,
	types: ['uuid'],
	localTypes: ['file'],
	group: 'standard',
	options: ({ field }) => {},
	recommendedDisplays: ['image'],
	preview: PreviewSVG,
});
