<template>
    <div class="pt-[70px] pb-28 px-8 mx-auto bg-[#F8FAFC]">
        <div class="w-full">
            <div class="text-[#1939AE] text-[26px] font-bold text-center mb-12">Ý nghĩa chương trình T7HH</div>
            <div class="flex justify-center relative w-[863px] min-h-[514px] mx-auto mb-20">
                <div
                    class="absolute top-0 left-[50px] font-medium text-[#1E293B] py-[38px] px-[30px] bg-[#DEF4F5] border border-solid border-[#67C2C3] w-[275px] rounded-md"
                >
                    <div
                        class="absolute top-[-33px] left-[178px] bg-white border border-solid border-[#67C2C3] w-[64px] h-[64px] rounded-full"
                    >
                        <v-icon
                            style="
                                color: #399e9f;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                            "
                            name="business_center"
                            large
                        ></v-icon>
                    </div>
                    {{ ldSettings.s1_text_1 }}
                </div>
                <img class="absolute mt-1" :src="usedImage.idToUrl(ldSettings.s1_image)"/>
                <div
                    class="absolute top-[121px] right-0 font-medium text-[#1E293B] pt-10 pb-[60px] px-[30px] bg-[#E0E7FF] border border-solid border-[#818CF8] w-[324px] rounded-md"
                >
                    <div
                        class="absolute top-[113px] left-[218px] bg-white border border-solid border-[#818CF8] w-[64px] h-[64px] rounded-full"
                    >
                        <v-icon
                            style="
                                color: #6366f1;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                            "
                            name="cast_for_education"
                            large
                        ></v-icon>
                    </div>
                    {{ ldSettings.s1_text_2 }}
                </div>
                <div
                    class="absolute top-[355px] left-[108px] font-medium text-[#1E293B] rounded-md py-10 px-[30px] bg-[#FFFBEB] border border-solid border-[#FCD34D] w-[287px]"
                >
                    <div
                        class="absolute top-[-33px] left-[178px] bg-white border border-solid border-[#FCD34D] w-[64px] h-[64px] rounded-full"
                    >
                        <v-icon
                            style="
                                color: #fbbf24;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                            "
                            name="how_to_reg"
                            large
                        ></v-icon>
                    </div>
                    {{ ldSettings.s1_text_3 }}
                </div>
            </div>

            <div
                class="flex-col lg:flex-row flex-wrap justify-between flex mx-[30px] border border-solid border-[#62B1EB] px-20 py-[70px] rounded-md mb-[70px]"
                :style="{ 'background-image': `url(${usedImage.idToUrl(ldSettings.s2_bg)})` }"
            >
                <div class="w-full lg:w-4/9">
                    <div class="text-center lg:text-left">
                        <div class="text-[#1939AE] font-bold text-[26px] mb-9">Quy định chương trình</div>
                    </div>

                    <div class="mt-3 text-[#1E293B] mx-auto lg:mx-0">
                        <div class="flex items-center mb-6 h-full">
                            <div class="h-full self-start">
                                <v-icon class="mr-4" name="ssid_chart" style="color: #1e4ba1"></v-icon>
                            </div>
                            <div @click="handleDownload(ldSettings.s2_file_target)" class="cursor-pointer">
                                <p class="mb-2">
                                    <span class="font-bold">Số buổi huấn luyện</span>
                                </p>

                                <p>
                                    <span class="cursor-pointer" style="color: #399e9f">
                                        {{ numberOfYear }}
                                    </span>
                                    <span>&#160số buổi quy định của năm</span>
                                </p>

                                <p>
                                    <span class="cursor-pointer" style="color: #399e9f">
                                        {{ numberOfMonth }}
                                    </span>
                                    <span>&#160số buổi quy định của tháng này</span>
                                </p>
                            </div>
                        </div>

                        <div class="flex flex-wrap items-center mb-6">
                            <div class="h-full self-start">
                                <v-icon class="mr-4" name="calendar_month" style="color: #1e4ba1"></v-icon>
                            </div>
                            <p>
                                <b class="font-bold">Thời gian:</b>
                                {{ ldSettings.s2_time }}
                            </p>
                        </div>

                        <div class="flex flex-wrap items-center mb-6">
                            <div class="h-full self-start">
                                <v-icon class="mr-4" name="hourglass_top" style="color: #1e4ba1"></v-icon>
                            </div>
                            <p>
                                <b class="font-bold">Thời lượng:</b>
                                {{ ldSettings.s2_durations }}
                            </p>
                        </div>

                        <div class="flex items-center mb-6">
                            <div class="h-full self-start flex-none">
                                <v-icon class="mr-4" name="settings" style="color: #1e4ba1"></v-icon>
                            </div>

                            <div class="flex-grow">
                                <p class="mb-2"><span class="font-bold">Phương pháp:</span></p>
                                <p class="cursor-pointer" @click="handleDownload(ldSettings.s2_file)">
                                    <v-icon style="color: #399e9f" small name="file_download"></v-icon>
                                    <span class="text-[#399e9f]">Tham khảo tài liệu đính kèm</span>
                                    {{ ldSettings.s2_text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full flex justify-center lg:w-1/2">
                    <img class="max-w-[700px]" :src="usedImage.idToUrl(ldSettings.s2_img)"/>
                </div>
            </div>
            <div class="mx-[30px] bg-white py-16 px-[50px]">
                <div class="text-[#1939AE] text-center font-bold text-[26px] mb-10">Các bước huấn luyện</div>

                <div class="grid grid-cols-1 lg:grid-cols-2 w-full mb-50">
                    <div v-for="(item, index) in LIST_TRAINING_STEP" class="col-span-1">
                        <button
                            :key="index"
                            @click="openTab(item.key)"
                            :class="
                                keyNumber === item.key
                                    ? `bg-[#1E4BA1] text-white tab-button-${item.key}`
                                    : `tab-button-${item.key} bg-white text-[#64748B]`
                            "
                            class="border border-solid py-3 font-medium border-[#CBD5E1] px-4 w-full"
                        >
                            {{ item.title }}
                        </button>
                    </div>
                </div>

                <div class="w-full">
                    <div v-if="keyNumber === 1" class="w-full flex justify-between relative">
                        <div v-for="item in LIST_TAB_1_STEP" :key="item.key" class="col-3 relative min-w-[120px]">
                            <div class="text-center flex flex-col justify-center items-center relative">
                                <div class="process-step text-center">
                                    <span>{{ item.key }}</span>
                                </div>
                                <div class="process-label max-w-36 mb-4 hidden lg:block">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div
                                class="hidden absolute w-full h-19 flex justify-center items-end css-animation-cv-jump -top-28 lg:flex"
                            >
                                <img
                                    :src="
                                        usedImage.idToUrl(
                                            ldSettings.step_icon ?? 'e7297ccb-ce09-4278-8ac5-f718305b859a'
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div
                            class="line left-0 right-0 mx-auto px-[40px] absolute top-5 h-2 w-full border-b-3 border-x-0 border-t-0 border-dotted border-[#94A3B8]"
                            :style="{ width: 'calc(100% - 80px)' }"
                        ></div>
                    </div>

                    <div v-if="keyNumber === 2" class="w-full flex justify-between relative">
                        <div v-for="item in LIST_TAB_2_STEP" :key="item.key" class="col-5 relative min-w-[120px]">
                            <div class="text-center flex flex-col justify-center items-center relative">
                                <div class="process-step text-center">
                                    <span>{{ item.key }}</span>
                                </div>
                                <div class="process-label max-w-36 mb-4 hidden lg:block">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div
                                class="hidden absolute w-full h-19 flex justify-center items-end css-animation-cv-jump -top-28 lg:flex"
                            >
                                <img
                                    :src="
                                        usedImage.idToUrl(
                                            ldSettings.step_icon ?? 'e7297ccb-ce09-4278-8ac5-f718305b859a'
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div
                            class="line left-0 right-0 mx-auto px-[40px] absolute top-5 h-2 w-full border-b-3 border-x-0 border-t-0 border-dotted border-[#94A3B8]"
                            :style="{ width: 'calc(100% - 80px)' }"
                        ></div>
                    </div>
                </div>
            </div>
            <div
                class="banner-footer-saturday relative flex justify-center items-center flex-col mt-[138px] mx-[30px] rounded-md"
            >
                <div
                    class="banner-background w-full h-[356px]"
                    :style="{
                        'background-image': `url(${usedImage.idToUrl(
                            ldSettings.bg ?? '8d47a4d0-aaa5-45e1-831a-2297cd033cab'
                        )})`,
                    }"
                ></div>
                <div class="list-item flex justify-center items-center flex-col absolute bottom-28">
                    <img :src="usedImage.idToUrl(ldSettings.footer_logo ?? 'a7872619-e9dd-4bec-8116-50814a7bc22b')"/>
                    <h5 class="font-bold md:text-[26px] text-white mt-10">
                        {{ ldSettings.footer_title }}
                    </h5>
                    <p class="font-normal text-[#e2e8f0] mt-4 mb-10 text-center">
                        {{ ldSettings.footer_intro }}
                    </p>
                    <div class="flex justify-center gap-4">
                        <v-button class="call-hotline" @click="handleCallHotline">
                            {{ ldSettings.footer_hotline }}
                        </v-button>
                        <v-button class="download-file" @click="handleDownload(ldSettings.footer_file)">
                            Tải hướng dẫn <v-icon name='download'></v-icon>
                        </v-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onMounted, ref, toRefs} from 'vue'
import {useStoreWebSetting} from '../../../../../shared-fe/stores/web-setting'
import useImage from '../../../../../shared-fe/composables/use-image'
import {useApi} from '@directus/composables'
import {useStores} from '@directus/extensions-sdk'

const imageWorkingEnv = useStoreWebSetting()
const usedImage = useImage()
const keyNumber = ref(1)
const modalSessions = ref(false)

const openTab = (key: number) => {
    keyNumber.value = key
}

const LIST_TRAINING_STEP = computed(() => {
    return [
        {
            key: 1,
            title: ldSettings.value.step_1_title,
        },
        {
            key: 2,
            title: ldSettings.value.step_2_title,
        },
    ]
})

const LIST_TAB_1_STEP = computed(() => {
    const stps = ldSettings.value.step_1?.split('\n') ?? []

    return stps.map((v, i) => {
        return {
            key: i + 1,
            title: v,
        }
    })
})

const LIST_TAB_2_STEP = computed(() => {
    const stps = ldSettings.value.step_2?.split('\n') ?? []

    return stps.map((v, i) => {
        return {
            key: i + 1,
            title: v,
        }
    })
})

const handleDownload = (id) => {
    if (!id) return
    window.open(usedImage.idToUrlDownload(id))
}

const handleOpenModal = () => {
    modalSessions.value = true
}

const handleCallHotline = () => {
    window.open('tel:' + ldSettings.value.footer_hotline)
}

const api = useApi()
const ldSettings = ref({})

onMounted(async () => {
    api.get('/items/live_editor', {
        params: {
            filter: {
                group: 'ld_settings',
            },
            sort: ['-group'],
        },
    }).then(async (res) => {
        const [m] = res.data.data
        ldSettings.value = JSON.parse(m.option_value)
    })
})

const {useSettingsStore} = useStores()
const appStore = useSettingsStore()
const {settings} = toRefs(appStore)

const numberOfYear = computed(() => {
    let total = 0
    settings.value.number_of_month.map((i) => {
        total += +i.times
    })

    return total
})
const numberOfMonth = computed(() => {
    let total = 0
    let currentMon = new Date().getMonth() + 1
    const setOf = settings.value.number_of_month.find((i) => i.month == currentMon)

    return +setOf.times
})
</script>
<style lang="scss">
.banner-footer-saturday {
    .banner-background {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .v-button {
        background-color: #fdce26;
        border-radius: 6px;
        &.download-file {
            button {
                background-color: var(--primary);
                color: white;
                border-color: var(--primary);

                &:hover {
                    background-color: var(--primary-50) !important;
                    border-color: var(--primary-50) !important;
                }
            }
        }
        button {
            background-color: #fdce26;
            border-radius: 6px;
            color: #000;
            border: 2px solid #fdce26;
            font-weight: 500;

            &:hover {
                background-color: #fcdb71 !important;
                color: #000;
                border-color: #f6df8c;
            }
        }

        &:hover {
            background-color: #fdce26 !important;
            color: #000;
            border-color: #fdce26;
        }
    }
}

.process-step {
    border-radius: 100%;
    line-height: 0;
    border: 1px dashed #fdce26;
    background-color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-self: center;
    display: flex;
    color: #000;
    width: 48px;
    height: 48px;
    font-weight: 600;
    margin-bottom: 16px;
    font-size: 24px;
    z-index: 4;
    cursor: pointer;
    box-shadow: #fff 0px 1px 5px, #fff 0px 0px 0px 5px;
}

.process-label {
    color: #1e293b;
    font-weight: 500;
    width: 100%;
    text-align: center;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
</style>
