import { useApi } from '@directus/extensions-sdk'
import { RECRUITMENT_REQUEST_COLLECTION, ROUTES_COLLECTION } from '../utils/table-name'
import { RECRUITMENT_REQUEST_STATUS } from '../utils/constant'
export const useRecruitmentRequestQuery = () => {
    const api = useApi()
    const patchItemRecruitmentRequest = async (id: number, params: any) => {
        return await api.patch(`/items/${RECRUITMENT_REQUEST_COLLECTION}/${id}`, params)
    }
    const postItemRecruitmentRequest = async (params: any) => {
        return await api.post(`/items/${RECRUITMENT_REQUEST_COLLECTION}`, params)
    }

    const changeStatusItemRecruitmentRequest = async (params: any) => {
        return await api.patch(`/items/${RECRUITMENT_REQUEST_COLLECTION}`, params)
    }

    const getItemRecruitmentRequest = async (id: any) => {
        return await api.get(`/items/${RECRUITMENT_REQUEST_COLLECTION}/${id}`, {
            params: {
                fields: [
                    [
                        '*',
                        'province.*',
                        'hr_id.full_name',
                        'user_created.full_name',
                        'user_created.id',
                        'user_created.area',
                        'staff_id.*',
                        'staff_id.resigns.*',
                        'completed_date',
                        'districts.*',
                        'candidates',
                    ],
                ],
            },
        })
    }

    const getItemRecruitmentRequestByUserCreated = async (idUserCreated: any) => {
        return await api.get(`/items/${RECRUITMENT_REQUEST_COLLECTION}`, {
            params: {
                fields: [['staff_id.id']],
                filter: {
                    _and: [
                        {
                            _and: [
                                {
                                    status: {
                                        _neq: RECRUITMENT_REQUEST_STATUS.completed.value,
                                    },
                                },
                                {
                                    status: {
                                        _neq: RECRUITMENT_REQUEST_STATUS.processing.value,
                                    },
                                },
                            ],
                        },
                        {
                            user_created: idUserCreated,
                        },
                    ],
                },
            },
        })
    }

    /**
     * Check route exist with processing status
     * true => exist, false => not exist
     * @param route 
     */
    const checkRouteCodeExistInTheSameProcessingStatus = async (route: any) => {
        const res = await api.post(`api-hr/recruitment_request/isExist`, {
            route: route,
        })

        if (res.data.result.is_exist) {
            return true
        }
        return false
    }

    /**
     * check route exist in routes table
     * @param route 
     */
    const checkRouteExists = async (route: any) => {
        const res = await api.get(`/items/${ROUTES_COLLECTION}`, {
            params: {
                fields: [['name']],
                filter: {
                    name: route.toUpperCase(),
                },
            }
        })

        if (res.data.data && res.data.data.length > 0) {
            return true
        }
        return false
    }

    return {
        patchItemRecruitmentRequest,
        postItemRecruitmentRequest,
        changeStatusItemRecruitmentRequest,
        getItemRecruitmentRequest,
        getItemRecruitmentRequestByUserCreated,
        checkRouteCodeExistInTheSameProcessingStatus,
        checkRouteExists,
    }
}
