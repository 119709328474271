import { computed, ref, watch } from 'vue'
import { notify } from '@/utils/notify'
import { USER_ROLE } from '@/shared-fe/utils/constant'
import { useProvinceDistrict } from '@/shared-fe/composables/use-province-district'
import { useUserStore } from '@/stores/user'

export const useGeneral = ({ fieldReOrder, currentSS, currentDCR }, model, initValue) => {
    const userStore = useUserStore()

    const { selectDistrict, selectProvinces, setProvince } = useProvinceDistrict(
        model.value.province,
        model.value.districts
    )

    watch(
        () => currentDCR.value,
        (nvl) => {
            if (nvl.route)
                model.value.route = nvl.route
        }
    )
    watch(
        () => model.value.province,
        (nvl) => {
            setProvince(nvl)
            model.value.districts = null
        }
    )
    watch(
        () => initValue.value.province,
        (nvl) => {
            setProvince(nvl)
            model.province = nvl
        }
    )
    watch(
        () => initValue.value.districts,
        (nvl) => {
            if (nvl[0] && typeof nvl[0] == 'object') {
                initValue.value.districts = nvl.map((i) => i.district_id)
            }
        }
    )

    const fieldsGeneral = computed(() => {
        fieldReOrder.value.province.meta.interface = 'select-dropdown'
        fieldReOrder.value.province.meta.options = {
            choices: computed(() => {
                return selectProvinces.value
            }),
        }

        fieldReOrder.value.districts.meta.interface = 'select-multiple-dropdown'
        fieldReOrder.value.districts.meta.options = {
            choices: computed(() => {
                return selectDistrict.value
            }),
            previewThreshold: 6
        }

        fieldReOrder.value.position.meta.interface = 'select-dropdown'
        fieldReOrder.value.position.meta.options = {
            choices: [
                {
                    text: 'DCR',
                    value: 'DCR',
                },
            ],
        }

        fieldReOrder.value.providers.meta.interface = 'app-select-m20'
        if (!fieldReOrder.value.providers.meta.options) {
            fieldReOrder.value.providers.meta.options = {}
        }
        fieldReOrder.value.providers.meta.options.filter = computed(() => {
            return {
                area: userStore.currentUser.area,
            }
        })

        fieldReOrder.value.user_created.name = 'SaleSup'
        fieldReOrder.value.user_created.meta.interface = 'app-select-m20'

        let queryParam = {}
        if (userStore.currentUser.role.short_name === USER_ROLE.ASM) {
            queryParam = {
                id: userStore.currentUser.id,
            }
        } else if (userStore.currentUser.role.short_name === USER_ROLE.SS) {
            queryParam = {
                id: userStore.currentUser.id,
            }
        }
        fieldReOrder.value.user_created.meta.options = {
            filter: computed(() => {
                return {
                    ...queryParam,
                    role: {
                        short_name: 'ss',
                    },
                    employment_status: 'on',
                }
            }),
            label_field: 'full_name',
            limit: -1,
        }

        fieldReOrder.value.quantity.meta.readonly = true
        fieldReOrder.value.start_date.meta.interface = 'app-datetime'
        fieldReOrder.value.providers.meta.interface = 'app-select-m20'
        fieldReOrder.value.providers.meta.options = {
            filter: computed(() => {
                return {
                    id: {
                        _in: currentSS.value.system_providers,
                    },
                    area: userStore.currentUser.area,
                }
            }),
            limit: -1,
        }

        fieldReOrder.value.hr_id.meta.readonly = true
        fieldReOrder.value.start_date.type = "dateTime"
        fieldReOrder.value.start_date.meta.options = {
            ...fieldReOrder.value.start_date.meta.options,
        }

        const __fields = [
            fieldReOrder.value.hiring_reason,
            fieldReOrder.value.user_created,
            fieldReOrder.value.providers,
            fieldReOrder.value.route,
            fieldReOrder.value.position,
            fieldReOrder.value.quantity,
            fieldReOrder.value.start_date,
            fieldReOrder.value.employment_type,
            fieldReOrder.value.province,
            fieldReOrder.value.districts,
            fieldReOrder.value.hr_id,
            fieldReOrder.value.content,
        ]

        const translateField = {
            hiring_reason: 'Lý do tuyển',
            providers: 'Nhà phân phối',
            route: 'Mã Route',
            position: 'Vị trí',
            quantity: 'Số lượng',
            start_date: 'Ngày dự kiến bắt đầu làm việc',
            employment_type: 'Hình thức làm việc',
            province: 'Tỉnh/Thành phố',
            districts: 'Quận/Huyện',
            hr_id: 'Người phụ trách',
            content: 'Yêu cầu về ứng tuyển',
            user_created: 'Sale Sup',
        }

        let loop = 1

        for (let f of __fields) {
            loop++
            f.meta.width = 'col-span-1'
            f.meta.sort = loop
            f.name = translateField[f.field]
            if (f.field == 'content') {
                f.meta.width = 'col-span-3'
            }

            if (
                userStore.currentUser.role.short_name === USER_ROLE.SS ||
                userStore.currentUser.role.short_name === USER_ROLE.ASM
            ) {
                if (!f.meta.readonly)
                    f.meta.readonly = model.value.id && model.value.id != "+"
            }

            if (f.field === "route" && (model.value.hiring_reason === 'replacement' || model.value.hiring_reason === 'transfer')) {
                f.meta.readonly = true
            }else{
                f.meta.readonly = false
            }

            if (userStore.currentUser.role.short_name === USER_ROLE.SS && f.field == 'user_created') {
                fieldReOrder.value.user_created.meta.hidden = true;
            }
        }
        return __fields
    })

    return {
        fieldsGeneral,
    }
}
