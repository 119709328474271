import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'
import { useFieldTemplate } from '@/shared-fe/composables/use-field-template'
import { MONTH_SELECT } from '@/shared-fe/utils/constant'
import { useApi } from '@directus/composables'
import { Joi } from '@directus/utils'
import { computed, ref } from 'vue'

export const useTargets = (mode: string, type: string) => {
    const api = useApi()
    const apiPath = '/items/targets'
    const userApiPath = '/users/'
    const { fieldInput, fieldSelect, fieldDateDefault } = useFieldTemplate({})
    const { canL1AndL2 } = useAppPermissions()

    const getAvailableYear = async (type: string, userId: string) => {
        let _and: any = [
            {
                type: type,
            },
        ]
        if (userId) {
            _and.push({
                user: userId,
            })
        }
        const res = await api.get(apiPath, {
            params: {
                limit: -1,
                field: ['year', 'id'],
                filter: {
                    _and,
                },
            },
        })

        if (res?.data?.data && res?.data?.data.length > 0) {
            return res.data?.data
        }
    }

    const getDetailTargetMonth = async (id: string) => {
        const res = await api.get(apiPath + '/' + id, {
            params: {
                field: ['*'],
            },
        })
        if (res?.data?.data) {
            return res.data?.data
        }
    }

    const getAvailableTargetYear = async (type: string, year: string, userId: string) => {
        let _and: any = [
            {
                type: type,
            },
            {
                year: year,
            },
        ]
        if (userId) {
            _and.push({
                user: userId,
            })
        }
        const res = await api.get(apiPath, {
            params: {
                field: ['*'],
                filter: {
                    _and,
                },
            },
        })
        if (res?.data?.data && res?.data?.data.length > 0) {
            return res.data?.data[0]
        }
        return null
    }

    const getSsInformation = async (user: string) => {
        const res = await api.get(userApiPath + user, {
            params: {
                field: ['full_name', 'start_date', 'start_month'],
            },
        })
        if (res?.data?.data) {
            return res.data?.data
        }
    }

    function onlyUnique(value: any, index: any, array: any) {
        return array.indexOf(value) === index
    }

    const fullAccess = computed(() => {
        return canL1AndL2.value
    })

    const MONTH = {
        january: 1,
        february: 2,
        march: 3,
        april: 4,
        may: 5,
        june: 6,
        july: 7,
        august: 8,
        september: 9,
        october: 10,
        november: 11,
        december: 12,
    }

    const MONTH_LABEL = {
        january: 'Tháng 1',
        february: 'Tháng 2',
        march: 'Tháng 3',
        april: 'Tháng 4',
        may: 'Tháng 5',
        june: 'Tháng 6',
        july: 'Tháng 7',
        august: 'Tháng 8',
        september: 'Tháng 9',
        october: 'Tháng 10',
        november: 'Tháng 11',
        december: 'Tháng 12',
    }

    const monthGlobalExisted = ref([])
    api.get(apiPath, {
        params: {
            fields: ["year"],
            filter: {
                type: "global"
            }
        }
    }).then(res => {
        monthGlobalExisted.value = res.data.data.map((i) => i.year)
    })
    const getMonthToAdd = computed(() => {
        let currentYear = new Date().getFullYear()
        let years = [
            {
                text: currentYear,
                value: currentYear,
            },
        ]
        for (let i = 1; i <= 5; i++) {
            years.push({
                text: currentYear + i,
                value: currentYear + i,
            })
        }

        return years.filter((year) => monthGlobalExisted.value.indexOf(year.value)  === -1)
    })

    const fieldsSs = computed(() => {
        const __fs = [
            fieldInput('full_name', 'Tên SS', {
                width: 'col-span-1',
                readonly: true,
            }),
            fieldInput('start_month', 'Tháng gia nhập', {
                width: 'col-span-1',
                readonly: true,
            }),
            fieldDateDefault('start_date', 'Ngày gia nhập', {
                width: 'col-span-1',
            }),
        ]

        return __fs
    })

    const saveSs = async (params: any, user: string) => {
        const res = await api.patch(userApiPath + user, { ...params })
        if (res?.data?.data?.id) {
            return true
        }
        return false
    }

    const save = async (params: any, id: string, type: string, mode: string, year: string) => {
        let res: any
        if (type === 'edit') {
            res = await api.patch(apiPath + '/' + id, { ...params })
        } else {
            const dataAvailable = await getAvailableTargetYear(mode, year, '')
            if (dataAvailable?.id) {
                res = await api.patch(apiPath + '/' + dataAvailable?.id, { ...params })
            } else {
                res = await api.post(apiPath, { ...params, year, type: mode })
            }
        }
        if (res?.data?.data?.id) {
            return true
        }
        return false
    }

    const customMessages = {
        'number.base': 'là bắt buộc',
        'string.base': 'không được để trống',
        'string.empty': 'không được để trống',
        'any.required': 'là bắt buộc',
        'array.base': 'là bắt buộc',
        'array.optional': 'là bắt buộc',
        'array.min': 'là bắt buộc',
        'any.invalid': 'là bắt buộc',
        'string.optional': 'là bắt buộc',
    }

    const schema = Joi.object({
        january: Joi.number().required().messages(customMessages),
        february: Joi.number().required().messages(customMessages),
        march: Joi.number().required().messages(customMessages),
        april: Joi.number().required().messages(customMessages),
        may: Joi.number().required().messages(customMessages),
        june: Joi.number().required().messages(customMessages),
        july: Joi.number().required().messages(customMessages),
        august: Joi.number().required().messages(customMessages),
        september: Joi.number().required().messages(customMessages),
        october: Joi.number().required().messages(customMessages),
        november: Joi.number().required().messages(customMessages),
        december: Joi.number().required().messages(customMessages),
    }).unknown(true)

    const validate = (formData: any) => {
        const { error } = schema.validate(formData, { abortEarly: false })
        const format = {}
        if (error?.details) {
            error.details.map((detail) => {
                const customLabel = MONTH_LABEL[detail.context.key]
                format[detail.context.key] = `${customLabel}: ${detail.message}`
            })
        }

        return {
            error: !!error,
            data: format,
        }
    }

    return {
        getAvailableYear,
        getDetailTargetMonth,
        getAvailableTargetYear,
        getSsInformation,
        fieldsSs,
        saveSs,
        save,
        getMonthToAdd,
        validate,
        fullAccess,
        MONTH,
    }
}
