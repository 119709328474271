<template>
    <div class="w-full mt-10">
        <div v-if="itemsRender.length >= 0">
            <p><span class="italic" v-html="ONBOARDING_NOTE"></span></p>
            <v-table
                class="c-table table-dcr mt-8"
                :headers="headers"
                :items="itemsRender"
                show-resize
                v-model:modelValue="modelValue"
                :disabled="disabled"
                manualSortKey="tel"
                :sort="internalSort"
                @update:sort="updateSort($event)"
                showSelect="multiple"
                :loading="props.loading"
            >
                <template #[`item.parent_id`]="{ item }">
                    {{
                        item.parent_id
                            ? displayParentName(item.parent_id?.role?.short_name, item.parent_id?.full_name)
                            : ''
                    }}
                </template>
                <template #[`item.start_date`]="{ item }">
                    {{ item.start_date ? dayjs(item.start_date).format(FORMAT_TIME.DDMMYYYY) : '' }}
                </template>
                <!-- <template #[`item.employment_status`]="{ item }">
                    <c-status :collectionName="USER_COLLECTION" :id="item.employment_status"></c-status>
                </template> -->
                <template #[`item.last_working_date`]="{ item }">
                    {{ item.last_working_date ? dayjs(item.last_working_date).format(FORMAT_TIME.DDMMYYYY) : '' }}
                </template>
                <template #[`item.dismiss`]="{ item }">
                    <v-button class="btn-action-reduce" outlined @click="handleOpenOverlay(item)">
                        <v-icon class="mr-2 btn-icon-action" name="person_remove" />
                        Xin duyệt miễn trừ
                    </v-button>
                </template>
            </v-table>
            <div class="footer">
                <div class="c-pagi pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="7"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
        <div v-else class="mx-auto max-w-[540px] border border-solid border-[#E2E8F0] rounded-xs bg-white">
            <div class="flex justify-center flex-col items-center px-10 pt-10 pb-12">
                <div class="p-[30px] rounded-full bg-[#F3F4F6] mb-6">
                    <v-icon x-large name="no_accounts" />
                </div>
                <div class="text-[#1E293B] text-xl font-bold text-center mb-8">{{ NO_DCR_TO_TRAIN }}</div>
                <div class="text-[#64748B] text-center mb-8">{{ NO_DCR_TO_TRAIN_DETAIL }}</div>
                <span class="custom-button-btn text-center">
                    <v-button v-for="item in channels"  @click="() => goToModuleList(item.id)" class="learning-list mr-5 mb-3">
                        <v-icon class="" name="library_books" />
                        <span class="ml-2">{{  item.name  }}</span>
                    </v-button>
                </span>
            </div>
        </div>
        <modal-exemption
            :items="currentDcr"
            @close="overlay = false"
            :overlay="overlay"
            @change="(e, variable) => handleChange(e, variable)"
            @submit="(params) => handleSubmit(params)"
        ></modal-exemption>
        <v-overlay v-if="currentDcr" :active="modalSuccess">
            <div class="pt-10 pb-12 px-10 bg-white rounded-md max-w-[540px] flex items-center flex-col justify-center">
                <div class="px-[26px] pb-[26px] bg-[#DEF4F5] mb-6 rounded-full w-[100px] h-[100px] flex items-center">
                    <v-icon class="send-icon" name="send"></v-icon>
                </div>
                <div class="text-xl font-bold mb-8">Đã gửi yêu cầu</div>
                <div class="mb-4 text-[#64748B]">{{ titleRequestExcludeTrain }}</div>
                <v-button secondary @click="handleClose">Đóng</v-button>
            </div>
        </v-overlay>
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { useExemptionCertificateQuery } from '../../../../../shared-fe/services/exemption-certificate-service'
import { FORMAT_TIME, TYPE_OF_TRAIN } from '../../../../../shared-fe/utils/constant'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import {
    NO_DCR_TO_TRAIN,
    NO_DCR_TO_TRAIN_DETAIL,
    ONBOARDING_NOTE,
} from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import ModalExemption from './modal-exemption.vue'
import { useUserStore } from '@/stores/user'
const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})

const titleRequestExcludeTrain = computed(() => {
    return `Yêu cầu xin duyệt miễn trừ cho ${userStore.currentUser.label_user_type} đã được gửi đến admin.`
})

const channels = ref(userStore.currentUser[TYPE_OF_TRAIN.OB]);
 const goToModuleList = (id: string) => {
    router.push({
        name: PATH_ROUTER.MODULE_LIST.NAME,
        params: {
            channel: id,
        },
    })
}
const dataDefault = {
    id: '',
    full_name: '',
    route_code: '',
    reason: '',
    resign_before: '',
    total_seniority: '',
    route_old: null,
    hero_sku_one: '',
    ebd_one: '',
    aso_one: '',
    display_one: '',
    hero_sku_two: '',
    ebd_two: '',
    aso_two: '',
    display_two: '',
    hero_sku_three: '',
    ebd_three: '',
    aso_three: '',
    display_three: '',
}

const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}
const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'updateSort'])

const disabled = ref(false)
const overlay = ref(false)
const modalSuccess = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const currentDcr: Ref<any> = ref(dataDefault)
const { postItemExemptionCertificate } = useExemptionCertificateQuery()
const router = useRouter()
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'Chi nhánh',
        value: 'zone',
    },
    {
        text: 'Khu vực',
        value: 'area_name',
    },
    {
        text: 'Nhà phân phối',
        value: 'system_providers',
    },
    {
        text: 'SS',
        value: 'parent_id',
    },
    {
        text: 'Mã Route',
        value: 'route_name',
    },
    {
        text: `Mã ${labelUserType.value}`,
        value: 'code',
    },
    {
        text: 'Họ và tên',
        value: 'full_name',
        width: 250,
    },
    {
        text: 'Ngày nhận việc chính thức',
        value: 'start_date',
        width: 230,
    },
    // {
    //     text: 'Trạng thái',
    //     value: 'employment_status',
    // },
    {
        text: 'Ngày làm việc cuối cùng',
        value: 'last_working_date',
        width: 230,
    },
    {
        text: 'Thao tác',
        value: 'dismiss',
        width: 300,
    },
])

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    let sortKey = sort?.by
    if (sort?.desc) sortKey = `-${sortKey}`
    emit('updateSort', sortKey)
}

const handleOpenOverlay = (item) => {
    currentDcr.value = { ...dataDefault, full_name: item?.full_name, route_code: item?.route_code?.name, id: item?.id }
    overlay.value = true
}

const handleChange = (e, variable) => {
    currentDcr.value[variable] = e
}

const handleSubmit = async (params) => {
    let data = { ...params, user: currentDcr.value?.id }
    delete data.id
    await postItemExemptionCertificate({ ...data })
    overlay.value = false
    modalSuccess.value = true
}

const handleClose = () => {
    modalSuccess.value = false
    currentDcr.value = null
}

</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
.table-dcr {
    th {
        .name {
            color: #1e4ba1;
            font-weight: bold;
        }
    }
}
.btn-icon-action {
    .v-icon {
        color: #1e4ba1;
    }
}
.send-icon {
    .v-icon {
        color: #399e9f !important;
    }
    i {
        color: #399e9f !important;
        font-size: 55px !important;
    }
}
.btn-action-reduce {
    .outlined {
        border-color: #1e4ba1 !important;
        color: #1e4ba1 !important;
    }
}
.label-input {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 6px;
}
.btn-modal {
    button {
        width: 100% !important;
    }
}
</style>
