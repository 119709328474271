<template>
    <div class="w-full bg-[#F8FAFC] px-7 py-10">
        <div v-if="props.listProcessTRaining.length >= 0">
            <p><span class="font-bold text-[#1939AE] text-2xl my-5 text-xl">Tiến độ đào tạo của {{ labelUserType }}</span></p>
            <v-table
                class="c-table table-dcr mt-8 table-height"
                :headers="headers"
                :items="props.listProcessTRaining"
                v-model:modelValue="modelValue"
                :disabled="disabled"
                :fixed-header="true"
                :loading="props.loading"
                :show-manual-sort="false"
            >
                <template #[`item.full_name`]="{ item }">
                    <div class="flex flex-col w-full">
                        <div class="w-full text-ellipsis overflow-hidden my-3">
                            <div><i>Họ và tên</i></div>
                            <span class="fw-bold">{{ item.full_name }}</span>
                        </div>
                        <div class="w-full text-ellipsis overflow-hidden my-3">
                            <div><i>Mã Route</i></div>
                            <span class="fw-bold">{{ item.route_name }}</span>
                        </div>
                        <div class="w-full text-ellipsis overflow-hidden my-3">
                            <div><i>Mã Nhân viên</i></div>
                            <span class="fw-bold">{{ item.code }}</span>
                        </div>
                        <div class="w-full text-ellipsis overflow-hidden my-3">
                            <div class="mb-1"><i>Trạng thái</i></div>
                            <span
                                v-if="item.status === TOTAL_PROCESS_TRAIN[item.status]?.value"
                                class="p-1 rounded-5px"
                                :style="{
                                    background: TOTAL_PROCESS_TRAIN[item.status]?.background,
                                    color: TOTAL_PROCESS_TRAIN[item.status]?.color,
                                }"
                            >
                                {{ TOTAL_PROCESS_TRAIN[item.status]?.text }}
                            </span>
                        </div>
                    </div>
                </template>
                <template #[`item.topic`]="{ item }">
                    <div class="flex flex-col w-full">
                        <div class="w-full text-ellipsis overflow-hidden h-33px my-4" v-for="x in item?.list_modules">
                            {{ x?.title }}
                        </div>
                    </div>
                </template>
                <template #[`item.status_child`]="{ item }">
                    <div class="flex flex-col">
                        <div
                            class="px-2 py-1 my-4 icon-size rounded-2 inline-block h-33px"
                            :style="{
                                color: `${PROCESS_TRAIN_STEP_STATUS[x.status_child]?.color}`,
                                border: `1px solid ${PROCESS_TRAIN_STEP_STATUS[x.status_child]?.color}`,
                            }"
                            v-for="(x, index) in item?.list_modules"
                            :key="index"
                        >
                            <v-icon class="ml-2" :name="PROCESS_TRAIN_STEP_STATUS[x.status_child]?.icon" />
                            {{ PROCESS_TRAIN_STEP_STATUS[x.status_child]?.text }}
                        </div>
                    </div>
                </template>
                <template #[`item.renderStatus`]="{ item }">
                    <div class="flex flex-col">
                        <div
                            class="text-[#62B1EB] icon-size my-4 font-bold py-1 h-33px"
                            v-for="(x, index) in item?.list_modules"
                            :key="index"
                        >
                            <span
                                @click="goToResultExam(item, x)"
                                v-if="x.status_child === PROCESS_TRAIN_STEP_STATUS.passed.value"
                            >
                                Kết quả thi
                                <v-icon class="ml-2" name="arrow_forward" />
                            </span>
                            <span v-else-if="x.status_child === PROCESS_TRAIN_STEP_STATUS.failed.value">
                                <span
                                    v-if="x?.status_room === EXAM_ROOM_STATUS.CLOSED"
                                    @click="goToModule(x?.id, true)"
                                >
                                    Đào tạo lại module
                                    <v-icon class="ml-2" name="arrow_forward" />
                                </span>
                                <span v-if="x?.status_room === EXAM_ROOM_STATUS.OPENED">
                                    <span v-if="x?.total_failed < 3">Yêu cầu {{ labelUserType }} thi lại</span>
                                    <span v-else @click="goToModule(x?.id, true)">
                                        Đào tạo lại module
                                        <v-icon class="ml-2" name="arrow_forward" />
                                    </span>
                                </span>
                            </span>
                            <span
                                @click="goToModule(x?.id)"
                                v-else-if="x.status_child === PROCESS_TRAIN_STEP_STATUS.untrained.value"
                            >
                                Bắt đầu đào tạo
                                <v-icon class="ml-2" name="arrow_forward" />
                            </span>
                            <span v-else>&nbsp;</span>
                        </div>
                    </div>
                </template>
                <!-- <template #[`item.status`]="{ item }">
                    <span
                        v-if="item.status === TOTAL_PROCESS_TRAIN[item.status]?.value"
                        class="p-1 rounded-5px"
                        :style="{
                            background: TOTAL_PROCESS_TRAIN[item.status]?.background,
                            color: TOTAL_PROCESS_TRAIN[item.status]?.color,
                        }"
                    >
                        {{ TOTAL_PROCESS_TRAIN[item.status]?.text }}
                    </span>
                </template> -->
            </v-table>
            <div class="footer">
                <div class="c-pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="7"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
        <div v-else class="mx-auto max-w-[540px] border border-solid border-[#E2E8F0] rounded-xs bg-white">
            <div class="flex justify-center flex-col items-center px-10 pt-10 pb-12">
                <div class="p-[30px] rounded-full bg-[#F3F4F6] mb-6">
                    <v-icon x-large name="no_accounts" />
                </div>
                <div class="text-[#1E293B] text-xl font-bold text-center mb-8">Không có chương trình đào tạo</div>
                <div class="text-[#64748B] text-center mb-8">
                    Hiện tại, bạn không có nhân viên nào cần được Onboard. Vui lòng chọn nhân viên để đào tạo từ danh
                    sách.
                </div>
                <v-button class=" ">Danh sách {{ labelUserType }} Onboarding</v-button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { HeaderRaw } from '@/components/v-table/types'
import { Ref, computed, ref } from 'vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import {
    TOTAL_PROCESS_TRAIN,
    PROCESS_TRAIN_STEP_STATUS,
    EXAM_ROOM_STATUS,
} from '../../../../../shared-fe/utils/constant'
import { useRouter, useRoute } from 'vue-router'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})

const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}
const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    listProcessTRaining: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
})

const emit = defineEmits(['update:modelSelected', 'update:toPage', 'updateSort'])
const router = useRouter()
const route = useRoute()
const disabled = ref(false)
// const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})

const headers = ref<HeaderRaw>([
    {
        text: 'Thông tin nhân viên',
        value: 'full_name',
        width: 170,
    },
    // {
    //     text: 'Mã Route',
    //     value: 'route_name',
    //     width: 120,
    // },
    // {
    //     text: 'Mã DCR',
    //     value: 'code',
    //     width: 120,
    // },
    {
        text: 'Nội dung',
        value: 'topic',
        width: 270,
    },
    {
        text: 'Tình trạng khoá học',
        value: 'status_child',
        width: 190,
    },
    {
        text: 'Thao tác',
        value: 'renderStatus',
        width: 200,
    },
    // {
    //     text: 'Trạng thái',
    //     value: 'status',
    //     width: 200,
    // },
])

// const updateSort = (sort: Sort | null) => {
//     internalSort.value = sort ?? { by: null, desc: false }
//     let sortKey = sort?.by
//     if (sort?.desc) sortKey = `-${sortKey}`
//     emit('updateSort', sortKey)
// }

const goToResultExam = (item: any, x) => {
    console.log(item)
    console.log(x)

    router.push({
        name: PATH_ROUTER.RESULT_EXAM_MODULE.NAME,
        params: {
            session_train: route.params?.session_train,
            user_id: item.id,
            module_id: x.id,
        },
    })
}

const goToModule = (id, retry = false) => {
    let name = retry === true ? PATH_ROUTER.PROCESS_MODULE_RETRY_TRAIN.NAME : PATH_ROUTER.PROCESS_MODULE_TRAIN.NAME
    router.push({
        name: name,
        params: {
            session_train: route.params?.session_train,
            module_id: id,
        },
    })
}
</script>
<style scoped lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
.table-dcr:deep {
    overflow: visible !important;
    // th {
    //     .content {
    //         padding: 20px;
    //     }
    //     .name {
    //         color: #1e4ba1;
    //         font-weight: bold;
    //     }
    // }
    .table-header {
        tr {
            position: sticky !important;
            top: 59px !important;
            .v-icon.small.action-icon {
                display: none;
            }
        }
    }
}

.icon-size {
    .v-icon {
        --v-icon-size: 18px;
    }
}
</style>
