import {getVueComponentName} from '@/utils/get-vue-component-name';
import {createPinia} from 'pinia';
import {createHead} from '@unhead/vue';
import {createApp} from 'vue';
import App from './app.vue';
import {registerComponents} from './components/register';
import {registerDirectives} from './directives/register';
import {loadExtensions, registerExtensions} from './extensions';
import {i18n} from './lang/';
import {router} from './router';
import './styles/main.scss';
import {registerViews} from './views/register';
import * as Sentry from "@sentry/vue";

init();

async function init() {
    const version = __DIRECTUS_VERSION__;

    if (import.meta.env.DEV) {
        console.info(`%c🐰 Starting Directus v${version}...`, 'color:Green');
    } else {
        console.info(`%c🐰 Starting Directus...`, 'color:Green');
    }
    console.time('🕓 Application Loaded');

    const app = createApp(App);

    app.use(router);
    app.use(i18n);
    app.use(createPinia());
    app.use(createHead());

    app.config.errorHandler = (err, vm, info) => {
        const source = getVueComponentName(vm);
        console.warn(`[app-${source}-error] ${info}`);
        console.warn(err);
        return false;
    };

    registerDirectives(app);
    registerComponents(app);
    registerViews(app);

    await loadExtensions();

    registerExtensions(app);

    console.log(`🚀 ${import.meta.env.VITE_APP_ADMIN_SENTRY}`);
    if (import.meta.env.VITE_APP_ADMIN_SENTRY) {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_APP_ADMIN_SENTRY,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            tracePropagationTargets: ["localhost", /^https:\/\/dcrhub\.suntorypepsico\.vn/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }

    app.mount('#app');
    console.timeEnd('🕓 Application Loaded');
    console.group(`%c✨ Project Information`, 'color:DodgerBlue'); // groupCollapsed

    if (import.meta.env.DEV) {
        console.info(`%cVersion: v${version}`, 'color:DodgerBlue');
    }

    console.info(`%cEnvironment: ${import.meta.env.MODE}`, 'color:DodgerBlue');
    console.groupEnd();

    // Prevent the browser from opening files that are dragged on the window
    window.addEventListener('dragover', (e) => e.preventDefault(), false);
    window.addEventListener('drop', (e) => e.preventDefault(), false);
}
