<template>
    <candidate-table
        :items="items"
        :totalPages="totalPages"
        :page="page"
        :loading="loading"
        @update:toPage="handleToPage"
        @updateSort="handleUpdateSort"
        v-if="items?.length > 0"
    />

    <div v-else>
        <v-info title="Không tin" icon="people_outline">Chưa có ứng viên được gán</v-info>
    </div>
</template>
<script setup lang="ts">
import { useItems } from '@directus/extensions-sdk'
import { computed, ref } from 'vue'
import { DELETE_VALUE, RECORD_PER_PAGE, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { CANDIDATE_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import candidateTable from './candidate-table.vue'
const props = defineProps({
    requestId: {
        type: Number,
        default: 0,
    },
    role: {
        type: String,
        default: '',
    },
})
const sort = ref(['-id'])
const filter = ref(<any>null)
const page = ref(1)

const customFilter = computed(() => {
    let _filter: any = {
        status_display: {
            _neq: DELETE_VALUE,
        },
        request_id: {
            _eq: props.requestId,
        },
    }

    if (props.role === USER_ROLE.SS) {
        _filter = {
            ..._filter,
            status: {
                opt_key: {
                    _nin: ['applied', 'interview_1', 'not_passed_interview_1'],
                },
            },
        }
    } else if (props.role === USER_ROLE.ASM) {
        _filter = {
            ..._filter,
            status: {
                opt_key: {
                    _in: [
                        'passed_rr',
                        'interview_3',
                        'passed_interview_3',
                        'not_passed_interview_3',
                        'waiting_for_job',
                        'hired',
                        'not_hired',
                    ],
                },
            },
        }
    }

    return _filter
})

const { items, totalPages, loading } = useItems(ref(CANDIDATE_COLLECTION), {
    fields: ref(['*', 'province.name', 'district.name', 'gender.opt_value', 'status.*']),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search: ref(null),
    filter: customFilter.value,
    page: page,
})

const handleUpdateSort = (sortKey: any) => {
    sort.value = sortKey
}

const handleToPage = (_toPage: number) => {
    page.value = _toPage
}
</script>
