import { useUserQuery } from "@/shared-fe/services/user-service"
import { useStoreLoading } from "@/shared-fe/stores/loading";
import { MODIFY_ONBOARD_STATUS, RAW_MODIFY_ONBOARD_STATUS, RECORD_IMPORT_NUMBER } from "@/shared-fe/utils/constant";
import { toLowerCaseNonAccentVietnamese } from "@/shared-fe/utils/convert-data";
import { MODIFY_ONBOARDING_COLLECTION } from "@/shared-fe/utils/table-name";
import { useApi } from "@directus/composables"
import { Ref, ref } from "vue"


export const useModifyOnboard = () => {
    const { getAllDcr } = useUserQuery();
    const allDcr: Ref<any[]> = ref([]);
    const modifyOnboardData: Ref<any[]> = ref([]);
    const apiPath = `/items/${MODIFY_ONBOARDING_COLLECTION}`
    const api = useApi();
    const progressing = useStoreLoading();

    (async () => {
        const res = await getAllDcr()
        const users = res.data.data
        if (users.length > 0) {
            allDcr.value = [...users]
        }

        api.get(apiPath, {
            params: {
                limit: -1,
                fields: ['id', 'user', 'user.code']
            },
        }).then(async (res) => {
            let model = res.data?.data
            if (model && model.length > 0) {
                modifyOnboardData.value = [...model]
            }
        })
    })()

    const parseDataResultToKey = (result: string) => {
        if (!result) {
            return ''
        }
        let data = toLowerCaseNonAccentVietnamese(result.trim())
        let string = ''
        Object.keys(RAW_MODIFY_ONBOARD_STATUS).map((key: any) => {
            if (RAW_MODIFY_ONBOARD_STATUS[key].text.includes(data)) {
                string = `${key}`
            }
        })

        return string
    }

    const importModifyOnboardData = async (data: any[]) => {
        let errors: any[] = []
        let postParams: any[] = []
        let updateParams: any[] = []
        if (data && data.length > 0) {
            data.forEach(async (item: any) => {
                let user = allDcr.value.find((x: any) => x.code === item.dcr_code)
                let temp = modifyOnboardData.value.find((x: any) => x?.user.code === item.dcr_code)
                if (!user) {
                    errors.push(`${item.dcr_code}||dcr_code = ${item.dcr_code} không tìm thấy trong hệ thống`)
                } else {
                    if (!temp) {
                        postParams.push({ user: user.id, work_with: item.work_with, elearning: item.elearning, duplicate: item.duplicate, result: item.result })
                    } else {
                        updateParams.push({ id: temp?.id, user: user.id, work_with: item.work_with, elearning: item.elearning, duplicate: item.duplicate, result: item.result })
                    }
                }
            })
        }

        for (let i = 0; i < postParams.length; i += RECORD_IMPORT_NUMBER) {
            let dataImport = postParams.slice(i, i + RECORD_IMPORT_NUMBER)
            await api.post(apiPath, dataImport).catch()
            progressing.handleProgressing(i + dataImport.length)
        }

        for (let i = 0; i < updateParams.length; i += RECORD_IMPORT_NUMBER) {
            let dataImport = updateParams.slice(i, i + RECORD_IMPORT_NUMBER)
            await api.patch(apiPath, dataImport).catch()
            progressing.handleProgressing(i + dataImport.length + postParams.length)
        }

        api.get(apiPath, {
            params: {
                limit: -1,
                fields: ['id', 'user', 'user.code']
            },
        }).then(async (res) => {
            let model = res.data?.data
            if (model && model.length > 0) {
                modifyOnboardData.value = [...model]
            }
        })

        return { errors, success: postParams.length + updateParams.length }

    }
    return {
        importModifyOnboardData,
        parseDataResultToKey
    }
}
