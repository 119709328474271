import { ref } from 'vue'
import { RecruitmentModel } from '../../modules/pepsi-hr/routes/recruitment/types'
import { useApi, useCollection } from '@directus/extensions-sdk'
import { useCommonQuery } from '../services/common-service'
import { DELETE_VALUE } from '../utils/constant'

export const useBatch = (collectionName: string) => {
    const confirmDelete = ref(false)
    const deleting = ref(false)

    const batchEditActive = ref(false)

    const confirmArchive = ref(false)
    const archiving = ref(false)
    const modelSelected = ref()
    const { info: currentCollection } = useCollection(collectionName)
    const error = ref<any>(null)
    const _useCommonQuery = useCommonQuery()

    const batchDelete = async (archiveField: string, id: any = null) => {
        deleting.value = true
        const batchPrimaryKeys = id ? [id] : modelSelected.value.map((x: RecruitmentModel) => x?.id)

        try {
            await _useCommonQuery.patchArchiveItem(batchPrimaryKeys, DELETE_VALUE, archiveField, collectionName)
            modelSelected.value = []
            confirmDelete.value = false
        } catch (err: any) {
            error.value = err
        } finally {
            deleting.value = false
        }
    }

    return {
        batchEditActive,
        confirmDelete,
        deleting,
        batchDelete,
        confirmArchive,
        archiving,
        error,
        modelSelected,
        currentCollection,
    }
}
