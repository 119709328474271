import { useApi } from '@directus/extensions-sdk'
import { EXAM_RESULTS_COLLECTION } from '../utils/table-name'

export const useExamResultQuery = () => {
    const api = useApi()

    const getItemExamResultList = async (examRoom: number, userId: string) => {
        return await api.get(`/items/${EXAM_RESULTS_COLLECTION}`, {
            params: {
                limit: -1,
                fields: ['*', 'quiz.id', 'quiz.questions.question'],
                filter: {
                    _and: [
                        {
                            user: {
                                id: {
                                    _eq: userId,
                                },
                            },
                            exam_room: {
                                id: examRoom,
                            },
                        },
                    ],
                },
            },
        })
    }
    
    const getItemExamResultsDetail = async (id: number) => {
        return await api.get(`/items/${EXAM_RESULTS_COLLECTION}/${id}`, {
            params: {
                fields: [
                    '*',
                    'exam_room.id',
                    'exam_room.topic.id',
                    'exam_room.topic.title',
                    'exam_room.topic.random_number',
                    'quiz.id',
                    'quiz.questions.*',
                    'user.id',
                    'user.full_name',
                ],
            },
        })
    }

    return {
        getItemExamResultList,
        getItemExamResultsDetail,
    }
}
