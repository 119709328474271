<template>
    <c-role>
        <private-view title="Giới thiệu ứng viên">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <!-- navigation end  -->
            <!-- header start -->
            <template #actions>
                <span class="custom-button-export-excel">
                    <v-button secondary @click="exportData" :loading="_export.loadingExcel.value">
                        <v-icon name="file_upload" outline />
                        <span class="inline-block ml-2">
                            {{ EXPORT_EXCEL }}
                        </span>
                    </v-button>
                </span>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-hsuv" v-if="modelSelected?.length > 0">
                        <v-button v-tooltip.bottom="SAVE_FILE" @click="saveFiles">
                            <div class="i-mdi-archive-arrow-down-outline" />
                            <span class="ml-1">{{ SAVE_FILE }}</span>
                        </v-button>
                    </div>
                </c-access>
                <!-- <v-button v-tooltip.bottom="REFRESH" class="btn-refresh" rounded icon secondary @click="refresh">
                    <div class="i-mdi-cached"></div>
                </v-button> -->
                <c-access :roles="[]">
                    <v-dialog v-if="modelSelected?.length > 0" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>

                <c-access :roles="[USER_ROLE.SS]">
                    <v-button
                        v-tooltip.bottom="CREATE_ITEM"
                        class="custom-create-button"
                        :to="PATH_ROUTER.INTRODUCT_CANDIDATE_ADD.PATH"
                    >
                        Giới thiệu ứng viên
                    </v-button>
                </c-access>
                <!-- <c-access :roles="[USER_ROLE.HR]">
                    <v-button
                        v-if="modelSelected?.length > 0"
                        v-tooltip.bottom="EDIT"
                        rounded
                        icon
                        secondary
                        @click="batchEditActive = true"
                    >
                        <div class="i-mdi-pencil-outline"></div>
                    </v-button>
                </c-access> -->

                <drawer-batch
                    v-model:active="batchEditActive"
                    :primary-keys="primaryKeys"
                    :collection="CANDIDATE_COLLECTION"
                    @refresh="batchRefresh"
                />
            </template>
            <!-- header end -->
            <div class="mx-9">
                <!-- searchbar start -->
                <introduct-candidate-searchbar
                    :arrayValue="objSearchStore?.dataSeletedForm"
                    :arrayStatus="arrayStatus"
                    @update:handleSearch="handleSearch"
                />
                <!-- searchbar end -->

                <!-- content start -->
                <introduct-candidate-table
                    :items="itemsRender"
                    :modelSelected="modelSelected"
                    @update:modelSelected="modelSelectedFun"
                    :totalPages="totalPages"
                    :page="page"
                    :loading="loading"
                    @update:toPage="handleToPage"
                    @updateSort="handleUpdateSort"
                />
                <!--  content end -->
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" title="Danh sách ứng viên" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useItems } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import CAccess from '../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../shared-fe/composables/use-batch'
import { useExport } from '../../../../shared-fe/composables/use-export'
import { useField } from '../../../../shared-fe/composables/use-field'
import { useCommonQuery } from '../../../../shared-fe/services/common-service'
import { useStoreSearch } from '../../../../shared-fe/stores/search'
import { CANDIDATE_STATUS, DELETE_VALUE, RECORD_PER_PAGE, USER_ROLE } from '../../../../shared-fe/utils/constant'
import { formatDateObjectToQuery } from '../../../../shared-fe/utils/convert-data'
import {
    CANCEL,
    CONTENT_DELETE,
    CREATE_ITEM,
    EXPORT_EXCEL,
    LABEL_DELETE,
    SAVE_FILE,
} from '../../../../shared-fe/utils/message'
import { CANDIDATE_COLLECTION } from '../../../../shared-fe/utils/table-name'
import IntroductCandidateSearchbar from './components/introduct-candidate-searchbar.vue'
import IntroductCandidateTable from './components/introduct-candidate-table.vue'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
const { getArrayStatus } = useField()
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-id'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)
const _useCommonQuery = useCommonQuery()
const customFilter = computed(() => {
    let _filter = {
        status_display: {
            _neq: DELETE_VALUE,
        },
        refer: {
            _nnull: true,
        },
    }
    if (filter.value && Object.entries(filter.value).length > 0) {
        _filter = {
            ..._filter,
            ...filter.value,
        }
    }

    return _filter
})

const primaryKeys = computed(() => {
    return modelSelected.value?.map((x) => x.id) || []
})
const { items, totalPages, getItems, getTotalCount, getItemCount, loading } = useItems(ref(CANDIDATE_COLLECTION), {
    fields: ref([
        '*',
        'province.name',
        'district.name',
        'province_recruitment.name',
        'district_recruitment.name',
        'status.*',
        'source.*',
        'refer.code',
        'refer.full_name',
        'refer.system_providers.provider_id.name',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search: ref(null),
    filter: customFilter,
    page: page,
})

const itemsRender = computed(() => {
    let _itemsRender: any = []
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    items.value.forEach((x) => {
        index++
        _itemsRender.push({
            stt: index,
            ...x,
        })
    })

    return _itemsRender
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const arrayStatus = computed(() => {
    return getArrayStatus(CANDIDATE_COLLECTION)
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {
        province_recruitment: data.province_id
            ? {
                  id: {
                      _eq: data.province_id,
                  },
              }
            : null,
        status: data.status || null,
    }

    if (data.user_created) {
        _filter.user_created.full_name = { _contains: data.user_created }
    }

    if (data.refer_name) {
        _filter._or = [
            {
                refer: {
                    code: data.refer_name,
                },
            },
            {
                refer: {
                    full_name: {
                        _contains: data.refer_name,
                    },
                },
            },
        ]
    }

    if (data.date_created && data.date_created.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.date_created[0], data.date_created[1])
        _filter.date_created = {
            _between: dateQuery,
        }
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value) {
            delete _filter[key]
        }
    }

    if (data.title) {
        _filter._and = [
            {
                _or: [
                    {
                        full_name: {
                            _contains: data.title,
                        },
                    },
                    {
                        phone_number: {
                            _contains: data.title,
                        },
                    },
                    {
                        email: {
                            _contains: data.title,
                        },
                    },
                ],
            },
        ]
    }
    filter.value = _filter

    updateSearchStore(
        {
            search: data.title,
            filter: _filter,
        },
        data
    )
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

// table end

// actions start

const batchRefresh = async () => {
    modelSelected.value = []
    await refresh()
}

const batchDeleteFun = async () => {
    await batchDelete('status_display')
    await refresh()
}

const saveFiles = async () => {
    const batchPrimaryKeys = modelSelected.value.map((x: any) => x?.id)
    await _useCommonQuery.patchArchiveItem(batchPrimaryKeys, true, 'is_archived', CANDIDATE_COLLECTION)
    modelSelected.value = []
    await refresh()
}

const {
    confirmDelete,
    deleting,
    batchDelete,
    error: deleteError,
    batchEditActive,
    modelSelected,
} = useBatch(CANDIDATE_COLLECTION)

const _export = useExport(CANDIDATE_COLLECTION, {
    stt: 'STT',
    'refer.code': 'Mã người giới thiệu',
    'refer.full_name': 'Tên người giới thiệu',
    'refer.system_providers.provider_id.name': 'Nhà phân phối',
    date_created: 'Ngày giới thiệu',
    id: 'Mã ứng viên',
    'province_recruitment.name': 'Tỉnh / Thành phố ứng tuyển',
    'district_recruitment.name': 'Quận / Huyện ứng tuyển',
    full_name: 'Họ và tên',
    date_of_birth: 'Ngày sinh',
    'gender.opt_value': 'Giới tính',
    phone_number: 'Số điện thoại',
    email: 'Email',
    'education_level.opt_value': 'Trình độ học vấn',
    address: 'Địa chỉ hiển tại',
    'district.name': 'Quận / Huyện',
    'province.name': 'Tỉnh / Thành phố',
    experience_detail: 'Chi tiết kinh nghiệm',
    'request_id.id': 'Mã YCTD',
    date_updated: 'Ngày cập nhật',
    'status.opt_key': 'Trạng thái',
    'request_id.hr_id.full_name': 'Người phụ trách',
})
_export.filename.value = 'IntroductCandidate.xlsx'
_export.filter.value = {
    status_display: {
        _neq: DELETE_VALUE,
    },
}
_export.beforeGenerate.value = (items) => {
    return items.map((item, index) => {
        return {
            stt: index + 1,
            refer_code: item?.refer?.code,
            refer_full_name: item?.refer?.full_name,
            refer_system_providers_provider_id_name:
                item?.refer?.system_providers?.length > 0 ? item?.refer?.system_providers[0]?.provider_id?.name : '',
            date_created: item?.date_created ? new Date(item?.date_created) : '',
            id: item?.id,
            province_recruitment_name: item?.province_recruitment?.name,
            district_recruitment_name: item?.district_recruitment?.name,
            full_name: item?.full_name,
            date_of_birth: item?.date_of_birth ? new Date(item?.date_of_birth) : '',
            gender: item?.gender?.opt_value,
            phone_number: item?.phone_number,
            email: item?.email,
            education_level: item?.education_level?.opt_value,
            address: item?.address,
            district_name: item?.district?.name,
            province_name: item?.province?.name,
            experience_detail: item?.experience_detail,
            request_id: item?.request_id?.id,
            date_updated: item?.date_updated ? new Date(item?.date_updated) : '',
            status_opt_key: CANDIDATE_STATUS[item?.status?.opt_key]?.text,
            hr_name: item?.request_id?.hr_id?.full_name,
        }
    })
}
const exportData = () => {
    _export.filter.value = customFilter.value
    _export.sort.value = sort.value
    _export.run()
}
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang="scss" scoped></style>
