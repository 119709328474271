<template>
    <c-wrapper-search @reset="handleRefresh" @submit="handleSubmit">
        <template #basic>
            <template v-for="(item, index) in basic" :key="index">
                <component
                    v-if="item.type !== 'c-range-datetime'"
                    :is="item.type"
                    :model-value="item.value"
                    :placeholder="item.placeholder"
                    :items="item.data ? item.data : null"
                    :showDeselect="true"
                    @update:model-value="(event) => handleChange(item.type, event, index, 'basic')"
                >
                    <template v-if="item.icon" #prepend><v-icon :name="item.icon"></v-icon></template>
                </component>
                <c-range-datetime
                    v-else
                    :placeholder="RANGE_DATE_PLACEHOLDER"
                    :rangeDateValue="item.data"
                    @update:model-value="(event) => handleChange(item.type, event, index, 'basic')"
                />
                <div class="border-right"></div>
            </template>
        </template>

        <template #advanced>
            <template v-for="(item, index) in expand" :key="index">
                <template v-if="item?.role?.length > 0">
                    <c-access :roles="item?.role">
                        <div class="w-full">
                            <div class="type-label">{{ item.label }}</div>
                            <component
                                v-if="item.type !== 'c-range-datetime'"
                                :is="item.type"
                                :model-value="item.value"
                                :placeholder="item.placeholder"
                                :items="item.data ? item.data : null"
                                :showDeselect="true"
                                @update:model-value="(event) => handleChange(item.type, event, index, 'expand')"
                            >
                                <template v-if="item.icon" #prepend><v-icon :name="item.icon"></v-icon></template>
                            </component>
                            <c-range-datetime
                                v-else
                                :placeholder="RANGE_DATE_PLACEHOLDER"
                                :rangeDateValue="item.data"
                                @update:model-value="(event) => handleChange(item.type, event, index, 'expand')"
                            />
                        </div>
                    </c-access>
                </template>
                <template v-else>
                    <div class="w-full">
                        <div class="type-label">{{ item.label }}</div>
                        <component
                            v-if="item.type !== 'c-range-datetime'"
                            :is="item.type"
                            :model-value="item.value"
                            :placeholder="item.placeholder"
                            :items="item.data ? item.data : null"
                            :showDeselect="true"
                            @update:model-value="(event) => handleChange(item.type, event, index, 'expand')"
                        >
                            <template v-if="item.icon" #prepend><v-icon :name="item.icon"></v-icon></template>
                        </component>
                        <c-range-datetime
                            v-else
                            :placeholder="RANGE_DATE_PLACEHOLDER"
                            :rangeDateValue="item.data"
                            @update:model-value="(event) => handleChange(item.type, event, index, 'expand')"
                        />
                    </div>
                </template>
            </template>
        </template>
    </c-wrapper-search>
</template>

<script setup lang="ts">
import { ref, Ref, watch } from 'vue'
import { ComponentPropsModel } from '../../types/table-type'
import { RANGE_DATE_PLACEHOLDER } from '../../utils/message'
import CRangeDatetime from '../c-range-datetime/c-range-datetime.vue'
import CWrapperSearch from './c-wrapper-search.vue'
import CAccess from '../c-access/c-access.vue'
const props = defineProps({
    dataBasic: {
        type: Array<ComponentPropsModel>,
        default: [],
    },
    dataExpand: {
        type: Array<ComponentPropsModel>,
        default: [],
    },
})

const basic: Ref<ComponentPropsModel[]> = ref(props.dataBasic)
const expand: Ref<ComponentPropsModel[]> = ref(props.dataExpand)

watch(
    () => props.dataBasic,
    (newData) => {
        basic.value = newData
    },
    { immediate: true }
)
watch(
    () => props.dataExpand,
    (newData) => {
        expand.value = newData
    },
    { immediate: true }
)
const emit = defineEmits(['update:model-value', 'changeItem'])

/**
 * handle change data
 */
const handleChange = (type, event, index, variable) => {
    let key = ''
    switch (variable) {
        case 'basic':
            if (type === 'c-range-datetime') {
                basic.value[index].data = event
            } else {
                basic.value[index].value = event
            }
            key = basic.value[index].key
            break
        case 'expand':
            if (type === 'c-range-datetime') {
                expand.value[index].data = event
            } else {
                expand.value[index].value = event
            }
            key = expand.value[index].key
            break

        default:
            break
    }
    emit('changeItem', { value: event, key })
}

const handleRefresh = () => {
    let dataBasic = basic.value.map((item) => {
        return item.type === 'c-range-datetime' ? { ...item, data: [] } : { ...item, value: '' }
    })
    basic.value = [...dataBasic]

    let dataExpand = expand.value.map((item) => {
        return item.type === 'c-range-datetime' ? { ...item, data: [] } : { ...item, value: '' }
    })
    expand.value = [...dataExpand]
    
    handleSubmit();
}

const handleSubmit = () => {
    let data = {}
    basic.value.forEach((x) => {
        data[x.key] = x.type === 'c-range-datetime' ? x.data : x.value
    })

    expand.value.forEach((x) => {
        data[x.key] = x.type === 'c-range-datetime' ? x.data : x.value
    })
    emit('update:model-value', data)
}
</script>
