import { useApi } from '@directus/extensions-sdk'
import { RECRUITMENT_COLLECTION } from '../utils/table-name'
export const useRecruitmentQuery = () => {
    const api = useApi()
    const patchItemRecruitment = async (id: number, params: any) => {
        return await api.patch(`/items/${RECRUITMENT_COLLECTION}/${id}`, params)
    }
    const postItemRecruitment = async (params: any) => {
        return await api.post(`/items/${RECRUITMENT_COLLECTION}`, params)
    }

    const getItemRecruitment = async (id: any) => {
        return await api.get(`/items/${RECRUITMENT_COLLECTION}/${id}`, {
            params: {
                fields: ['*', 'provinces.*'],
            },
        })
    }

    return {
        patchItemRecruitment,
        postItemRecruitment,
        getItemRecruitment,
    }
}
