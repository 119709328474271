<template>
    <div class='relative'>
        <draggable
            v-model="images"
            :force-fallback="true"
            @update:model-value="sortItems($event)"
            class='grid grid-cols-4 gap-4'
            @start="drag=true"
            item-key="id"
            @end="drag=false"
        >
            <template #header>
                <div class='aspect-1'>
                    <v-upload
                        multiple
                        from-library
                        @input='updateData'
                    ></v-upload>
                </div>
            </template>
            <template #item="{element}">
                <div class='aspect-1'>
                    <div class='border border-solid border-gray-200 rounded h-full relative overflow-hidden'>
                        <div class='rounded-full top-1 relative left-1 bg-white shadow aspect-1 w-10 h-10 flex items-center justify-center'>
                            <button class='relative z-99 left-0' @click='deleteImg(element.id)'>
                                <v-icon name='delete'></v-icon>
                            </button>
                        </div>
                        <img :alt='element.id' class='absolute top-0 right-0 w-full h-full object-contain' :src='element.src' alt=''>
                    </div>
                </div>
            </template>
        </draggable>
    </div>
</template>

<script setup lang='ts'>
import { computed, ref, withDefaults, defineProps } from 'vue'
import { useApi } from '@directus/composables'
import { useI18n } from 'vue-i18n'
import Draggable from 'vuedraggable';

const { t, n, te } = useI18n()
const drag = ref(false)

const props = withDefaults(
    defineProps<{
        value: string | number | null | any;
        type?: string;
        clear?: boolean;
        disabled?: boolean;
        placeholder?: string;
        masked?: boolean;
        iconLeft?: string;
        iconRight?: string;
        trim?: boolean;
        font?: 'sans-serif' | 'serif' | 'monospace';
        length?: number;
        softLength?: number;
        dbSafe?: boolean;
        autofocus?: boolean;
        slug?: boolean;
        min?: number;
        max?: number;
        step?: number;
        direction?: string;
    }>(),
    {
        font: 'sans-serif',
        step: 1
    }
)
const emit = defineEmits(['input'])

const api = useApi()

const updateData = (value) => {
    console.log(value)
    if (value.length > 0) {
        const imgs = images.value

        for (let i of value) {
            imgs.push({
                id: i.id
            })
        }

        emit('input', imgs)
    }

}

const deleteImg = (id) => {
    const imgs = images.value
    const index = imgs.findIndex(i => i.id == id)
    if (index >= 0) {
        imgs.splice(index, 1)
    }
    emit('input', imgs)
}

const migrateFolderToImages = async (idFolder) => {
    api.get(`/files`, {
        params: {
            limit: 99,
            filter: {
                folder: idFolder
            },
            fields: ["id"]
        }
    })
        .then(res => {
            oldFiles.value = res.data.data.map(i => {
                return {
                    id: i.id
                }
            })

            emit('input', res.data.data.map(i => {
                return {
                    id: i.id
                }
            }))
        })
        .catch(e => {
            console.log(e)
        })
}

const oldFiles = ref([]);

const images = computed(() => {
    let output = [
        ... oldFiles.value
    ]

    if (typeof props.value == 'string' && props.value) {
        try {
            const imgs = JSON.parse(props.value);
            output = imgs
        } catch (e) {
            migrateFolderToImages(props.value);
            return [];
        }

    } else if (typeof props.value == 'object') {
        output = props.value
    }

    output.map(img => img.src = `/assets/${img.id}`)
    return output
})


const sortItems = (event) => {
    emit('input', event.map(i => {
        return {
            id: i.id
        }
    }))
}

</script>
