import { useLiveEditor } from '../services/live-editor-service'
import useImage from './use-image'

export const useWebSetting = () => {
    const { getAllLiveEditorByGroup, getAllFilesByFolder } = useLiveEditor()
    const { idToUrl } = useImage()

    const getWebSettingData = async (group: string) => {
        let response = await getAllLiveEditorByGroup(group)
        if (response && response.status) {
            const res = response?.data?.data
            let result: any = {}
            if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                    let val = res[i]
                    if (val.option_key.indexOf('_image') !== -1) {
                        let files = await getAllFilesByFolder(val.option_value)
                        result[val.option_key] = files?.data?.data.map((temp: any) => {
                            return temp.id ? idToUrl(temp.id) : null
                        })
                    } else {
                        result[val.option_key] = val.option_value
                    }
                }
            }
            return result
        }
    }
    return {
        getWebSettingData,
    }
}
