import { useApi } from '@directus/extensions-sdk'
import { USER_ROLE } from '../utils/constant'
import { useUserStore } from '@/stores/user'

export const useProviderQuery = () => {
    const api = useApi()
    const userStore = useUserStore()

    const getAllProviderItem = async () => {
        let params: any = {
            limit: -1,
            fields: ['id', 'name', 'area', 'zone'],
            filter: null,
        }
        if (userStore.currentUser?.role?.short_name === USER_ROLE.HR) {
            params.filter = {
                area: {
                    id: {
                        _in: userStore.currentUser?.ares_management?.map((a) => a.id),
                    },
                },
            }
        } else if (userStore.currentUser?.role?.short_name === USER_ROLE.ASM) {
            params.filter = {
                area: {
                    id: userStore.currentUser?.area?.id,
                },
            }
        }

        if (!params.filter) delete params.filter

        return await api.get('/items/providers', {
            params: params,
        })
    }
    return {
        getAllProviderItem,
    }
}
