<template>
    <div class="px-13 pb-10 flex flex-col gap-y-12" v-if="isReady">
        <v-form class="" primaryKey="+" v-model="formData" :fields="fieldsRender"></v-form>
    </div>
    <div v-else>
        <div class="text-primary text-2xl text-center">Đang khởi tạo dữ liệu ...</div>
    </div>
</template>

<script lang="ts" setup>
import { defineEmits, watch, computed } from 'vue'
import { useGeneralSetting } from '../../../../../shared-fe/composables/use-general-setting'

const { fieldsRender, save, formData, isReady } = useGeneralSetting('HR_Settings')

const emit = defineEmits(['startLoad', 'endLoad'])

defineExpose({ save })
</script>
