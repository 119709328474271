import { useApi } from '@directus/extensions-sdk'
import { RESIGN_HISTORY_COLLECTION } from '../utils/table-name'
import { useStoreCommon } from '../stores/common'
export const useResignHistoryQuery = () => {
    const api = useApi()
    const commonStore = useStoreCommon()

    const postItemResignHistory = async (params: any) => {
        try {
            return await api.post(`/items/${RESIGN_HISTORY_COLLECTION}`, params)
        } catch (error) {
            commonStore.loading = false
        }
    }

    const getItemResignHistory = async (idResign: Number, limit: Number, currentPage: Number) => {
        return await api.get(`/items/${RESIGN_HISTORY_COLLECTION}`, {
            params: {
                limit: limit,
                page: currentPage,
                fields: [
                    'reason',
                    'status',
                    'note',
                    'date_created',
                    'user_created.full_name',
                    'user_created.role.name',
                ],
                meta: ['filter_count'],
                filter: { resign: idResign },
                sort: ['-date_created'],
            },
        })
    }

    return {
        postItemResignHistory,
        getItemResignHistory,
    }
}
