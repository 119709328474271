<template>
    <private-view title="Cài đặt module tuyển dụng">
        <!-- navigation start  -->
        <template #navigation>
            <c-navigation/>
        </template>

        <template #title>
            <div class="type-title flex items-justify">
                <div>Quản lý SaleForce</div>
            </div>
        </template>

        <template #actions>
            <v-button
                :disabled="disableSave"
                v-tooltip.bottom="'Lưu thay đổi'"
                icon
                rounded
                @click="save()"
                :loading="saving"
            >
                <v-icon name="check"/>
            </v-button>
            <!--  -->
        </template>

        <div class="px-8">
            <div class="flex gap-4">
                <button
                    class="px-4 py-2 rounded"
                    :class="saleForce.selection.value.zone === z.id ? 'bg-primary' : 'bg-gray-100'"
                    @click="saleForce.setSelection('zone', z.id)"
                    v-for="z in saleForce.zones.value"
                >
                    {{ z.name }}
                </button>
            </div>

            <div class="flex gap-2 mt-3">
                <button
                    class="px-3 py-1 rounded border border-gray-100 border-solid"
                    :class="saleForce.selection.value.area === a.id ? 'bg-primary-alt' : 'bg-gray-50'"
                    @click="saleForce.setSelection('area', a.id)"
                    v-for="a in saleForce.areas.value"
                >
                    {{ a.name }}
                </button>
            </div>
            <div class="mt-2">
                <v-input v-model="saleForce.userSearch.value"></v-input>
            </div>

            <div class="mt-6">
                <div class="grid grid-cols-4 gap-6">
                    <div class="box-border">
                        <div class="mb-2 text-xl">ASM - SS</div>
                        <div v-for="u in saleForce.asm.value">
                            <div class="">
                                <a
                                    class="block font-bold"
                                    href="javascript:"
                                    :class="saleForce.selection.value.asm === u.id ? 'text-primary' : ''"
                                    @click="saleForce.setSelection('asm', u.id)"
                                >
                                    {{ u.full_name }}
                                </a>
                            </div>

                            <div v-for="uss in saleForce.ssFilterSearch(u.sub_users)" class="">
                                <a
                                    class="block px-2 py-1 rounded"
                                    :class="saleForce.selection.value.ss === uss.id ? 'bg-primary-alt' : ''"
                                    href="javascript:"
                                    @click="saleForce.setSelection('ss', uss.id)"
                                >
                                    {{ uss.full_name }}
                                </a>
                            </div>
                        </div>

                        <div class="-mx-6 mt-4 px-2 py-1 text-red-600 bg-red-100">
                            <input type="checkbox" v-model="show.ss"/>
                            SS cần kiểm tra ({{ saleForce.ssError.value.length }})
                        </div>
                        <div v-if="show.ss">
                            <div class="px-2 -mx-6 py-1 bg-gray-100 mb-2 font-italic">
                                SS không có NPP, Chưa có DCR, Vùng, Chi nhánh
                            </div>
                            <div v-for="u in saleForce.ssError.value">
                                <a
                                    class="hover:bg-gray-50 block px-2 py-1"
                                    :class="saleForce.selection.value.provider === u.id ? 'bg-gray-200' : ''"
                                    href="javascript:"
                                    @click="saleForce.setSelection('ss', u.id)"
                                >
                                    {{ u.full_name }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="box-border">
                        <div class="mb-2 text-xl">DCR</div>
                        <div v-for="u in saleForce.dcr.value">
                            <a
                                class="hover:bg-gray-50 block px-2 py-1"
                                :class="saleForce.selection.value.dcr === u.id ? '!bg-gray-200' : ''"
                                href="javascript:"
                                @click="saleForce.setSelection('dcr', u.id)"
                            >
                                {{ u.full_name }}
                            </a>
                        </div>

                        <div class="-mx-6 mt-4 px-2 py-1 text-red-600 bg-red-100">
                            <input type="checkbox" v-model="show.dcr"/>
                            DCR cần kiểm tra ({{ saleForce.dcrError.value.length }})
                        </div>
                        <div v-if="show.dcr">
                            <div class="px-2 -mx-6 py-1 bg-gray-100 mb-2 font-italic">
                                DCR không có NPP, Chưa có DCR, Vùng, Chi nhánh, Ngày làm việc, mã Route
                            </div>
                            <div v-for="u in saleForce.dcrError.value">
                                <a
                                    class="hover:bg-gray-50 block px-2 py-1"
                                    :class="saleForce.selection.value.provider === u.id ? 'bg-gray-200' : ''"
                                    href="javascript:"
                                    @click="saleForce.setSelection('dcr', u.id)"
                                >
                                    {{ u.full_name }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="box-border col-span-2">
                        <div v-if="saleForce.userSelected.value.id">
                            <v-form
                                :primary-key="saleForce.userSelected.value.id"
                                class="mini-spacing"
                                v-model="userEdit"
                                :fields="fields"
                                :initialValues="saleForce.userSelected.value"
                            ></v-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </private-view>
</template>

<script lang="ts" setup>
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import {computed, ref, watch} from 'vue'
import {useSaleforce} from '@/modules/pepsi-hr/routes/adminhr/useSaleforce'
import {useFieldsStore} from '@/stores/fields'
import api from '@/api'
import {notify} from '@/utils/notify'
import {i18n} from '@/lang'

const {getFieldsForCollection} = useFieldsStore()

const show = ref({
    ss: false,
    dcr: false,
})

const saleForce = useSaleforce()
const userEdit = ref({})
const saving = ref(false)

const addMeta = (field, meta) => {
    return {
        ...field,
        meta: {
            ...field.meta,
            ...meta,
        },
    }
}

const fields = computed(() => {
    const fs = getFieldsForCollection('directus_users')

    const fsOrder: any = {}
    for (let _f of fs) {
        _f.meta.width = 'full'
        fsOrder[_f.field] = _f
    }

    fsOrder.route_code.meta.display = 'related-values'
    fsOrder.route_code.meta.display_options = {
        template: '{{ name }}',
    }
    fsOrder.route_code.meta.options = {
        template: '{{ name }}',
    }

    let f: any = {
        role: {
            ...fsOrder.role,
            name: 'Bộ phận',
        },
        full_name: {
            ...fsOrder.full_name,
            name: 'Tên đầy đủ',
        },
        email: {
            ...fsOrder.email,
            name: 'Email',
        },
        personal_email: {
            ...fsOrder.personal_email,
            name: 'Email Cá nhân',
        },

        date_of_birth: {
            ...fsOrder.date_of_birth,
            name: 'Ngày sinh',
        },

        phone_number: {
            ...fsOrder.phone_number,
            name: 'Số điện thoại',
        },

        id_card_number: {
            ...fsOrder.id_card_number,
            name: 'CCCD',
        },

        code: {
            ...addMeta(fsOrder.code, {width: 'half'}),
            name: 'Code',
        },
        route_code: {
            ...addMeta(fsOrder.route_code, {width: 'half'}),
            name: 'Mã Route',
        },
        start_date: {
            ...fsOrder.start_date,
            name: 'Ngày bắt đầu làm việc',
        },
        last_working_date: {
            ...fsOrder.last_working_date,
            name: 'Ngày làm việc cuối cùng',
        },
        zone: {
            ...addMeta(fsOrder.zone, {}),
            name: 'Chi nhánh',
        },
        area: {
            ...addMeta(fsOrder.area, {}),
            name: 'Khu vực',
        },
        parent_id: {
            ...addMeta(fsOrder.parent_id, {
                options: {
                    filter: {
                        role: {
                            short_name: saleForce.userSelected.value.role?.short_name == 'dcr' ? 'ss' : 'asm',
                        },
                    },
                    template: '{{ role.name }} -> {{ full_name }}',
                },
            }),
            name: 'Cấp trên quản lý',
        },
        system_providers: {
            ...fsOrder.system_providers,
            name: 'Nhà phân phối',
        },
    }

    f.role.meta.readonly = true

    if (
        saleForce.userSelected.value.role?.short_name == 'ss' ||
        saleForce.userSelected.value.role?.short_name == 'asm'
    ) {
        delete f.code
        delete f.route_code
        delete f.start_date
        delete f.personal_email
    }

    if (saleForce.userSelected.value.role?.short_name == 'dcr') {
        delete f.email
    }

    if (saleForce.userSelected.value.role?.short_name == 'asm') {
        delete f.parent_id
    }

    f = Object.values(f)
    f = f.map((value, index) => {
        value.meta.sort = index + 1
        return value
    })

    return f
})

const disableSave = computed(() => {
    return Object.values(userEdit.value).length === 0
})

watch(
    () => userEdit.value.parent_id,
    (nvl) => {
        const userModel = saleForce._allUsers.value[nvl]
        if (!userModel) {
            return
        }
        if (userModel.zone) userEdit.value.zone = userModel.zone
        if (userModel.area) userEdit.value.area = userModel.area
    }
)

const save = async () => {
    console.log(userEdit.value)
    saving.value = true
    await api.patch(`/users/${saleForce.userSelected.value.id}`, userEdit.value)
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await saleForce.refreshUser(saleForce.userSelected.value.id)
    notify({
        title: i18n.global.t('item_update_success', 1),
    })

    userEdit.value = {}

    saving.value = false
}
</script>
