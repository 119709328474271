<template>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">
                    Họ và tên
                    <span class="required">*</span>
                </div>
                <v-input
                    :disabled="!fullAccess"
                    type="text"
                    v-model="formData.full_name"
                    @update:model-value="(e) => handleChange(e, 'full_name')"
                ></v-input>
                <span class="message-error" v-if="errors?.full_name">Vui lòng nhập họ tên</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Ngày sinh
                    <span class="required">*</span>
                </div>
                <c-native-date
                    :disabled="!fullAccess"
                    :value="formData.date_of_birth"
                    @input="(e) => handleChange(e, 'date_of_birth')"
                ></c-native-date>
                <span class="message-error" v-if="errors?.date_of_birth_invalid">Chưa đủ 18 tuổi để ứng tuyển</span>
                <span class="message-error" v-if="errors?.date_of_birth">Vui lòng nhập ngày sinh</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Giới tính"
                    collection="options"
                    v-model:modelValue="formData.gender"
                    field_text="opt_value"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'gender')"
                    :query-filter="{ filter: { opt_group: 'gender' } }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.gender">Vui lòng nhập giới tính</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Điện thoại
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.phone_number"
                    @update:model-value="(e) => handleChange(e, 'phone_number')"
                ></v-input>
                <span class="message-error" v-if="errors?.phone_number">
                    SĐT phải là 10 chữ số và không được để trống
                </span>
            </div>
            <div class="col-span-1">
                <div class="type-label">Số điện thoại sử dụng zalo</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.phone_number_zalo"
                    @update:model-value="(e) => handleChange(e, 'phone_number_zalo')"
                ></v-input>
                <span class="message-error" v-if="errors?.phone_number_zalo">SĐT Zalo phải là 10 chữ số</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">Email</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.email"
                    @update:model-value="(e) => handleChange(e, 'email')"
                ></v-input>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Trình độ học vấn"
                    collection="options"
                    v-model:modelValue="formData.education_level"
                    field_text="opt_value"
                    field_value="id"
                    placeholder=""
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'education_level')"
                    :query-filter="{ filter: { opt_group: 'education_level' } }"
                ></c-select-m2o>
            </div>
            <div class="col-span-1">
                <div class="type-label">Tình trạng hôn nhân</div>
                <v-select
                    :model-value="formData.married"
                    :items="arrayMarriedStatus"
                    showDeselect
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'married')"
                ></v-select>
            </div>
        </div>
        <div class="border-bottom my-8"></div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">
                    CCCD
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.id_card_number"
                    @update:model-value="(e) => handleChange(e, 'id_card_number')"
                ></v-input>
                <span class="message-error" v-if="errors?.id_card_number">Vui lòng nhập số CCCD</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Ngày cấp
                    <span class="required">*</span>
                </div>
                <c-native-date
                    :disabled="!fullAccess"
                    :value="formData.id_card_date"
                    @input="(e) => handleChange(e, 'id_card_date')"
                ></c-native-date>
                <span class="message-error" v-if="errors?.id_card_date">Vui lòng nhập ngày cấp</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Nơi cấp
                    <span class="required">*</span>
                </div>
                <v-input
                    :disabled="!fullAccess"
                    :model-value="formData.id_card_place"
                    @update:model-value="(e) => handleChange(e, 'id_card_place')"
                ></v-input>
                <span class="message-error" v-if="errors?.id_card_place">Vui lòng nhập nơi cấp</span>
            </div>
        </div>
        <div class="border-bottom my-8"></div>

        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
            <div class="col-span-1">
                <div class="type-label">
                    Địa chỉ hiện tại
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.address"
                    @update:model-value="(e) => handleChange(e, 'address')"
                ></v-input>
                <span class="message-error" v-if="errors?.address">Vui lòng nhập địa chỉ</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    collection="provinces"
                    v-model:model-value="formData.district"
                    field_text="name"
                    field_value="id"
                    label="Quận huyện"
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'district')"
                    :query-filter="queryFilterDistrict"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.district">Vui lòng nhập quận huyện</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Tỉnh thành"
                    collection="provinces"
                    v-model:modelValue="formData.province"
                    field_text="name"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'province')"
                    :query-filter="{ filter: { parent_id: { _null: true } } }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.province">Vui lòng nhập tỉnh thành</span>
            </div>
        </div>
        <template v-if="props.mode === 'add'">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div class="col-span-1">
                    <div class="type-label">
                        Chi nhánh
                        <span class="required">*</span>
                    </div>
                    <v-select
                        :model-value="formData.zone_name"
                        :items="arrZone"
                        showDeselect
                        :disabled="!fullAccess"
                        @update:model-value="(e) => handleChange(e, 'zone_name')"
                    ></v-select>
                    <span class="message-error" v-if="errors?.zone_name">Vui lòng chọn chi nhánh</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        SS
                        <span class="required">*</span>
                    </div>
                    <v-select
                        :model-value="formData.ss_name"
                        :items="arrSS"
                        showDeselect
                        :disabled="!fullAccess"
                        @update:model-value="(e) => handleChange(e, 'ss_name')"
                    ></v-select>
                    <span class="message-error" v-if="errors?.ss_name">Vui lòng chọn SS</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        Mã route
                        <span class="required">*</span>
                    </div>
                    <v-input
                        type="text"
                        :disabled="!fullAccess"
                        v-model="formData.route_code"
                        @update:model-value="(e) => handleChange(e, 'route_code')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.route_code">Vui lòng nhập Mã route</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        Mã nhân viên
                        <span class="required">*</span>
                    </div>
                    <v-input
                        type="text"
                        :disabled="!fullAccess"
                        v-model="formData.code"
                        @update:model-value="(e) => handleChange(e, 'code')"
                    ></v-input>
                    <span class="message-error" v-if="errors?.code">Vui lòng nhập mã nhân viên</span>
                    <span class="message-error" v-if="errors?.code_duplicate">{{ errors?.code_duplicate }}</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        Ngày bắt đầu làm việc
                        <span class="required">*</span>
                    </div>
                    <v-menu ref="dateTimeStartDate" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                :disabled="!fullAccess"
                                clickable
                                readonly
                                :model-value="formData.start_date_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.start_date ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.start_date_display,
                                            'today-icon': !formData.start_date_display,
                                        }"
                                        v-on="{ click: formData.start_date ? unsetValueStartDate : null }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <c-date-picker
                            type="date"
                            :include-seconds="true"
                            :use-24="true"
                            :disabled="!fullAccess"
                            :model-value="formData.start_date"
                            @update:model-value="(e) => handleChange(e, 'start_date')"
                            @close="dateTimeStartDate?.deactivate"
                        />
                    </v-menu>
                    <span class="message-error" v-if="errors?.start_date">Vui lòng chọn ngày bắt đầu làm việc</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">
                        Trạng thái
                        <span class="required">*</span>
                    </div>
                    <v-select
                        :model-value="formData.employment_status_name"
                        :items="arrayUserStatus"
                        :disabled="!fullAccess"
                        @update:model-value="(e) => handleChange(e, 'employment_status_name')"
                    ></v-select>
                    <span class="message-error" v-if="errors?.employment_status_name">Vui lòng chọn trạng thái</span>
                </div>
            </div>
        </template>
        <div class="border-bottom my-8"></div>
        <div class="grid grid-cols-1 gap-8">
            <div class="col-span-1">
                <div class="type-label">Danh sách file</div>
                <interface-files
                    collection="directus_users"
                    :enableCreate="true"
                    :enableSelect="false"
                    :disabled="!fullAccess"
                    field="attachments"
                    :folder="undefined"
                    :limit="15"
                    :primaryKey="formData.id"
                    :template="null"
                    v-model:value="formData.attachments"
                    @input="handleChangeUploadFile"
                ></interface-files>
            </div>
            <c-access :roles="[USER_ROLE.HR]">
                <div class="col-span-1">
                    <div class="type-label">Ghi chú</div>
                    <v-textarea
                        :disabled="!fullAccess"
                        :model-value="formData.note"
                        @update:model-value="(e) => handleChange(e, 'note')"
                    />
                </div>
            </c-access>
        </div>
    </div>
    <!-- <candidate-history :idCandidate="item.id"></candidate-history> -->
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import _ from 'lodash'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import CSelectM2o from '../../../../../shared-fe/components/c-select-m2o/c-select-m2o.vue'
import CNativeDate from '../../../../../shared-fe/components/c-native-date/c-native-date.vue'
import CDatePicker from '../../../../../shared-fe/components/c-datepicker/c-datepicker.vue'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import {
    FORMAT_TIME,
    MODE_ADD,
    MODE_EDIT,
    SUBMIT_EVENT,
    USER_ROLE,
    USER_STATUS,
    USER_TYPE,
} from '../../../../../shared-fe/utils/constant'
import Joi from 'joi'
import { ageValidation, sortDataSelection } from '@/shared-fe/utils/convert-data'
import { useRouter } from 'vue-router'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { useAreaQuery } from '@/shared-fe/services/area-service'
import { useUserStore } from '@/stores/user'

const commonStore = useStoreCommon()
const { patchItemUser, postItemUser, getAllRole, getListSS, getAllEmailUserWithLimit, getProviderByProviderName } =
    useUserQuery()
const { getAllAreaAndZoneItem } = useAreaQuery()
const arrAreaAndZone = ref(<any>[])
const arrZoneDefault = ref(<any>[])
const arrSS = ref(<any>[])
const providerId = ref(0)
const dateTimeStartDate = ref()
const userStore = useUserStore()
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
    mode: {
        type: String,
        default: 'detail',
    },
})
const router = useRouter()
const { canAccess } = usePermission()
const emit = defineEmits(['refetch'])
const fullAccess = computed(() => {
    if (userStore.currentUser.user_type === USER_TYPE.WSR) {
        if (
            userStore.currentUser?.role.short_name === USER_ROLE.ASM ||
            userStore.currentUser?.role.short_name === USER_ROLE.SS
        ) {
            return false
        }
    }
    return canAccess([USER_ROLE.HR, USER_ROLE.AGENCY])
})

const arrayUserStatus = computed(() => {
    const arr: any = []
    Object.keys(USER_STATUS).map((key) => {
        arr.push({
            text: USER_STATUS[key].text,
            value: USER_STATUS[key].value,
        })
    })

    return arr
})

const arrZone = computed(() => {
    debugger
    let _zones: any = []
    if (userStore.currentUser?.role?.short_name === USER_ROLE.HR) {
        if (userStore.currentUser?.ares_management?.length > 0) {
            userStore.currentUser?.ares_management.forEach((x) => {
                if (_zones?.length > 0) {
                    if (!_zones.find((k) => k.value === x?.zone?.id)) {
                        _zones.push({
                            text: x?.zone?.name,
                            value: x?.zone?.id,
                        })
                    }
                } else {
                    _zones.push({
                        text: x?.zone?.name,
                        value: x?.zone?.id,
                    })
                }
            })
        }
        return sortDataSelection(_zones)
    } else {
        return arrZoneDefault.value
    }
})

getAllAreaAndZoneItem().then((res) => {
    if (res.data.data) {
        res.data.data?.forEach((x) => {
            if (!x?.zone) {
                arrZoneDefault.value.push({
                    text: x?.name,
                    value: x?.id,
                })
            }
            arrAreaAndZone.value.push(x)
        })
        arrZoneDefault.value = sortDataSelection(arrZoneDefault.value)
    }
})

getListSS('', { user_type: USER_TYPE.WSR }).then((res) => {
    if (res?.data?.data && res?.data?.data.length > 0) {
        arrSS.value = res?.data?.data.map((x) => {
            return {
                text: x?.full_name,
                value: x?.id,
            }
        })
    }
})

getProviderByProviderName('WSR PROVIDER').then((res) => {
    if (res?.data?.data && res?.data?.data.length > 0) {
        providerId.value = res?.data?.data[0]?.id
    }
})
const dataDefault = {
    id: 0,
    full_name: null,
    date_of_birth: null,
    gender: null,
    phone_number: null,
    phone_number_zalo: null,
    email: null,
    education_level: null,
    married: null,
    province: null,
    district: null,
    address: null,
    id_card_number: null,
    id_card_date: null,
    id_card_place: null,
    id_card_date_display: null,
    zone_name: null,
    ss_name: null,
    route_code: null,
    code: null,
    start_date: null,
    start_date_display: null,
    employment_status_name: null,
    note: null,
    attachments: null,
}

const defaultDataEdit = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const formData = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const errors: any = ref(null)
const roleDcr = ref(null)

getAllRole().then((res) => {
    if (res?.data?.data) {
        let idDcr = res.data?.data?.find((x) => x.short_name === USER_ROLE.DCR)
        roleDcr.value = idDcr?.id
    }
})

const queryFilterDistrict = computed(() => {
    return { filter: { parent_id: formData.value.province } }
})

const schemaAdd = Joi.object({
    full_name: Joi.string().required(),
    date_of_birth: Joi.string().required(),
    gender: Joi.number().required(),
    phone_number: Joi.string()
        .required()
        .regex(/^\d{10}$/),
    province: Joi.string().required(),
    district: Joi.string().required(),
    address: Joi.string().required(),
    id_card_number: Joi.string().required(),
    id_card_date: Joi.string().required(),
    id_card_place: Joi.string().required(),
    zone_name: Joi.number().required(),
    ss_name: Joi.string().required(),
    route_code: Joi.string().required(),
    code: Joi.string().required(),
    start_date: Joi.string().required(),
    employment_status_name: Joi.string().required(),
}).unknown(false)

const schema = Joi.object({
    full_name: Joi.string().required(),
    date_of_birth: Joi.string().required(),
    gender: Joi.number().required(),
    phone_number: Joi.string()
        .required()
        .regex(/^\d{10}$/),
    province: Joi.string().required(),
    district: Joi.string().required(),
    address: Joi.string().required(),
    id_card_number: Joi.string().required(),
    id_card_date: Joi.string().required(),
    id_card_place: Joi.string().required(),
}).unknown(false)

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(formData.value)) {
        formData.value[key] = props.item[key]
    }

    if (formData.value.date_of_birth) {
        formData.value.date_of_birth = dayjs(formData.value.date_of_birth).format(FORMAT_TIME.YYYMMDD)
        formData.value.date_of_birth_display = dayjs(formData.value.date_of_birth).format(FORMAT_TIME.DDMMYYYY)
    }
    if (formData.value.id_card_date) {
        formData.value.id_card_date = dayjs(formData.value.id_card_date).format(FORMAT_TIME.YYYMMDD)
        formData.value.id_card_date_display = dayjs(formData.value.id_card_date).format(FORMAT_TIME.DDMMYYYY)
    }

    defaultDataEdit.value = JSON.parse(JSON.stringify(formData.value))
}

watch(
    () => props.item,
    async () => {
        if (props.item) {
            initData()
        }
    },
    { immediate: true }
)

const arrayMarriedStatus = [
    {
        text: 'Độc thân',
        value: false,
    },
    {
        text: 'kết hôn',
        value: true,
    },
]
const handleChange = (e: any, variable: any) => {
    switch (variable) {
        case 'date_of_birth':
            formData.value.date_of_birth_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'id_card_date':
            formData.value.id_card_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'start_date':
            formData.value.start_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'province':
            if (e !== props?.item?.province) {
                formData.value[variable] = e
                formData.value.district = ''
            } else {
                formData.value.district = props.item.district
            }
            break
        default:
            formData.value[variable] = e
            break
    }
}

const validateDateOfBirth = () => {
    if (formData.value.date_of_birth) {
        if (!ageValidation(new Date(formData.value.date_of_birth))) {
            return { date_of_birth_invalid: 'Chưa đủ 18 tuổi để ứng tuyển' }
        }
    }
    return {}
}

const handleChangeUploadFile = (data) => {
    formData.value.attachments = data
}

const validatePhoneZalo = () => {
    if (formData.value.phone_number_zalo) {
        let pattern = /^\d{10}$/
        if (!pattern.test(formData.value.phone_number_zalo)) {
            return { phone_number_zalo: 'phone_number_zalo is invalid ' }
        }
    }
    return {}
}

const unsetValueStartDate = (e) => {
    formData.value.start_date = ''
    formData.value.start_date_display = ''
    unsetValue(e)
}

const unsetValue = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
}

const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.WSR_DETAIL_SUBMIT)
    if (payloadSubmitForm) {
        errors.value = null
        const {
            id,
            email,
            education_level,
            married,
            id_card_date_display,
            note,
            refer_name,
            attachments,
            refer,
            status,
            request_id,
            phone_number_zalo,
            experience_detail,
            is_archived,
            date_of_birth_display,
            start_date_display,
            ...res
        } = formData.value

        const { zone_name, code, employment_status_name, ss_name, route_code, start_date, ...resEdit } = res

        const { error: errorEdit } = schema.validate(resEdit, { abortEarly: false })
        const { error: errorAdd } = schemaAdd.validate(res, { abortEarly: false })
        let error: any

        if (payloadSubmitForm.payload === MODE_EDIT) {
            error = errorEdit
        } else {
            error = errorAdd
        }
        if (error) {
            errors.value = error.details.reduce((acc, curr) => {
                acc[curr.path[0]] = curr.message
                return acc
            }, {})
        }

        errors.value = {
            ...errors.value,
            ...validatePhoneZalo(),
            ...validateDateOfBirth(),
        }

        console.log('errors.value: ', errors.value)
        if (Object.keys(errors.value).length !== 0) {
            return
        }

        let params = JSON.parse(JSON.stringify(formData.value))
        delete params?.date_of_birth_display
        params = JSON.parse(JSON.stringify(params))
        if (!params.attachments?.create) {
            delete params.attachments
        }

        params['personal_email'] = params.email
        delete params.email
        delete params.start_date_display
        delete params.id_card_date_display
        delete params.employment_status_name
        delete params.ss_email
        delete params.ss_name
        delete params.zone_name

        if (payloadSubmitForm.payload === MODE_EDIT) {
            delete params.id
            try {
                await patchItemUser(formData.value.id, params)
            } catch (error) {
                console.log(error)
                commonStore.titleDialog = error?.errors[0]?.message || ''
            }
            emit('refetch')
            commonStore.showDialogSucessUpdate = true
        } else if (payloadSubmitForm.payload === MODE_ADD) {
            delete params.id
            params.user_type = USER_TYPE.WSR
            params.role = roleDcr.value
            params.zone = params.zone_name
            params.route_code = { name: formData.value.route_code }
            params.start_date = formData.value.start_date
            params.parent_id = formData.value.ss_name
            params.employment_status = formData.value.employment_status_name
            params.email = `${params.code}@spvb.vn`
            params = {
                ...params,
                system_providers: {
                    create: [
                        {
                            user_id: '+',
                            provider_id: {
                                id: providerId.value,
                            },
                        },
                    ],
                    update: [],
                    delete: [],
                },
            }

            const filter = {
                email: {
                    _eq: params.email,
                },
            }
            const resEmail = await getAllEmailUserWithLimit(filter)
            if (resEmail.data.data.length > 0) {
                errors.value = {
                    code_duplicate: 'Thông tin mã DCR WSR đã tồn tại',
                }
                return
            }

            // try {
            const res = await postItemUser(params)
            commonStore.titleDialog = 'Tạo mới thành công'
            commonStore.showDialogSucessUpdate = true
            setTimeout(() => {
                router.push({
                    name: PATH_ROUTER.WSR_DETAIL.NAME,
                    params: {
                        id: res?.data?.data?.id,
                    },
                })
            }, 2000)
            // } catch (error) {
            //     console.log(error)
            //     commonStore.titleDialog = error?.errors[0]?.message || ''
            //     commonStore.showDialogSucessUpdate = true
            // }
        }
    }
}

const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})
</script>
