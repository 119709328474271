import { useApi } from '@directus/extensions-sdk'
import { EXAM_ROOM, SESSION_TRAINS_COLLECTION } from '../utils/table-name'
import { TYPE_OF_TRAIN } from '../utils/constant'

export const useTrainingQuery = () => {
    const api = useApi()

    const getModules = async (sessionTrainId: string) => {
        return await api.get(`/items/session_trains/${sessionTrainId}`, {
            params: {
                fields: [
                    'course.topics.title',
                    'course.topics.id',
                    'course.topics.time_left',
                    'course.topics.file_by_zones.zone',
                    'course.topics.file_by_zones.url_embed',
                    'course.topics.file_by_zones.files.directus_files_id.filename_disk',
                    'course.topics.file_by_zones.files.directus_files_id.id',
                    'course.topics.file_by_zones.files.directus_files_id.video_embed',
                    'course.topics.file_by_zones.files.directus_files_id.type',
                ],
            },
        })
    }

    const getListDcrStartExam = async (sessionTrainId: string, moduleId: string, retry: number) => {
        return await api.get(`/api-learnings/exam_user/list/${sessionTrainId}/${moduleId}/${retry}`)
    }

    const openTest = async (param: any) => {
        return await api.post('/items/exam_rooms', param)
    }

    const getOpenTest = async () => {
        return await api.get('/items/exam_rooms', {
            params: {
                limit: 2,
                fields: ['id', 'date_create', 'lessons.title', 'lessons.id'],
                // sort: 'ASC',
            },
        })
    }

    const getModulesByCourseId = async (courseId: any) => {
        return await api.get(`/items/courses/${courseId}`, {
            params: {
                fields: ['*', 'topics.*', 'topics.quiz.*'],
            },
        })
    }

    const getSessionTrains = async (sessionTrainId: any) => {
        return await api.get(`/api-learnings/session_trains/details/${sessionTrainId}`)
    }

    const postSessionsTrainEndpoint = async (params: any) => {
        return await api.post(`/api-learnings/session_trains/saturday/create`, params)
    }

    const getExamRoom = async (sessionTrainId: any, moduleId: any) => {
        return await api.get('items/exam_rooms', {
            params: {
                limit: -1,
                fields: ['id'],
                filter: {
                    session_train: sessionTrainId,
                    topic: moduleId,
                },
            },
        })
    }

    const getExamRoomSaturday = async (sessionTrainId: any, moduleId: any, provider_id: string) => {
        const start = new Date(new Date().setUTCHours(0, 0, 0, 0))
        const end = new Date(new Date().setUTCHours(23, 59, 59, 999))
        return await api.get('items/exam_rooms', {
            params: {
                limit: -1,
                fields: ['id'],
                filter: {
                    _and: [
                        {
                            _and: [
                                {
                                    date_created: {
                                        _gte: start,
                                    },
                                },
                                {
                                    date_created: {
                                        _lte: end,
                                    },
                                },
                            ],
                        },
                        {
                            session_train: sessionTrainId,
                        },
                        {
                            topic: moduleId,
                        },
                        {
                            user: {
                                system_providers: {
                                    provider_id: provider_id,
                                },
                            },
                        },
                    ],
                },
            },
        })
    }

    const getExamRoomNewToday = async (sessionTrainId: number) => {
        const start = new Date(new Date().setUTCHours(0, 0, 0, 0))
        const end = new Date(new Date().setUTCHours(23, 59, 59, 0))
        return await api.get(`/items/${EXAM_ROOM}`, {
            params: {
                fields: ['*', 'user.full_name', 'topic.*'],
                filter: {
                    _and: [
                        {
                            session_train: {
                                id: sessionTrainId,
                            },
                        },
                        {
                            retry: false,
                        },
                        {
                            _and: [
                                {
                                    date_created: {
                                        _gte: start,
                                    },
                                },
                                {
                                    date_created: {
                                        _lte: end,
                                    },
                                },
                            ],
                        },
                    ],
                },
            },
        })
    }

    const getExamResultBySessionTrains = async (sessionTrainId: number) => {
        return await api.get(`/items/${SESSION_TRAINS_COLLECTION}/${sessionTrainId}`, {
            params: {
                fields: ['*'],
            },
        })
    }

    const getSessionTrainById = async (sstrainId: number) => {
        return await api.get(`items/${SESSION_TRAINS_COLLECTION}`, {
            params: {
                limit: -1,
                fields: ['id', 'user.directus_users_id.full_name','user.directus_users_id.id', 'exam_rooms.*', 'exam_rooms.user.id'],
                filter: {
                    id: sstrainId,
                },
            },
        })
    }

    const getDataSessionTrainSaturday = async () => {
        const dateSubtract = 1
        const currentDate = new Date()

        const start = new Date(
            new Date(currentDate.setDate(currentDate.getDate() - dateSubtract)).setUTCHours(17, 0, 0, 0)
        )

        const end = (() => {
            const d = new Date()
            const m = (d.getMonth() + 1).toString().padStart(2, '0')
            const date = d.getDate().toString().padStart(2, '0')

            return `${d.getFullYear()}-${m}-${date}T23:00:00Z`
        })()
        return await api.get(`items/${SESSION_TRAINS_COLLECTION}`, {
            params: {
                limit: -1,
                fields: ['id', 'course.id'],
                filter: {
                    _and: [
                        {
                            date_created: {
                                _between: [start, end],
                            },
                        },
                        {
                            course: {
                                channel: {
                                    type_of_train: TYPE_OF_TRAIN.T7HH
                                }
                            },
                        },
                    ],
                },
            },
        })
    }

    const getProviderUserBySessionIdAndModuleId = async () => {
        return await api.get(`/api-learnings/provider_user/list`)
    }

    const isAllowToOpenExamRoom = async (sessionId: string, moduleId: string, providerId: string) => {
        return await api.get(`/api-learnings/provider_user/exist/${sessionId}/${moduleId}/${providerId}`)
    }

    const updateSessionTrainChannel = async (id: number, params: any) => {
        return await api.get(`/reset-sstrain/${id}?channel=${params.channel}`)
    }

    const getModulesByChannelId  = async (channel: string) => {
        return await api.get(`/api-learnings/modules/list?channel_id=${channel}`)
    }

    return {
        openTest,
        getOpenTest,
        getModules,
        getSessionTrains,
        getExamRoom,
        getExamResultBySessionTrains,
        getListDcrStartExam,
        getModulesByCourseId,
        postSessionsTrainEndpoint,
        getDataSessionTrainSaturday,
        getExamRoomNewToday,
        getProviderUserBySessionIdAndModuleId,
        getExamRoomSaturday,
        isAllowToOpenExamRoom,
        updateSessionTrainChannel,
        getModulesByChannelId,
        getSessionTrainById
    }
}
