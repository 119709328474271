<template>
    <c-role>
        <private-view :title="PATH_ROUTER.RECRUITMENT_REQUEST_ADD.VALUE">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #actions>
                <v-button
                    v-tooltip.bottom="SAVE"
                    icon
                    rounded
                    @click="save"
                    :loading="commonStore.loading"
                    :disabled="commonStore.loading"
                >
                    <v-icon name="check" />
                </v-button>
                <!--  -->
            </template>
            <!-- header end -->

            <template v-if="ready">
                <DetailForm ref="formDetail" class="mb-10" :objResignLinked="objResignLinked"></DetailForm>
            </template>

            <template #sidebar>
                <sidebar-detail icon="info" :title="PATH_ROUTER.RECRUITMENT_REQUEST_ADD.VALUE" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import CNavigation from '@/shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '@/shared-fe/components/c-role/c-role.vue'
import { useResignQuery } from '@/shared-fe/services/resign-service'
import { SAVE } from '@/shared-fe/utils/message'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import DetailForm from '@/modules/pepsi-hr/routes/recruitment_request/components/detail/form.vue'
import { useStoreCommon } from '@/shared-fe/stores/common'

const route = useRoute()
const { getItemResign } = useResignQuery()
const commonStore = useStoreCommon()
const objResignLinked = ref(<any>null)
const ready = ref(false)

const formDetail = ref(null)

if (route.query?.id_resign) {
    getItemResign(route.query?.id_resign.toString())
        .then((res) => {
            if (res?.data?.data) {
                objResignLinked.value = {
                    resign_id: route.query?.id_resign,
                    staff_id: res.data.data.staff_id?.id,
                    system_provider_id:
                        res?.data?.data?.staff_id.system_providers?.length > 0
                            ? res?.data?.data?.staff_id.system_providers[0].provider_id?.id
                            : '',
                    system_provider_name:
                        res?.data?.data?.staff_id.system_providers?.length > 0
                            ? res?.data?.data?.staff_id.system_providers[0].provider_id?.name
                            : '',
                }
            }
            ready.value = true
        })
        .catch(() => {
            ready.value = true
        })
} else {
    ready.value = true
}

// handle logic header (start)
const router = useRouter()
const save = () => {
    formDetail.value?.save()
}

const navigateBack = () => {
    router.push({
        name: PATH_ROUTER.RECRUITMENT_REQUEST_LIST.NAME,
    })
}
// handle logic header (end)
</script>

<style lang="scss" scoped></style>
