<template>
    <div>
        <div v-if="itemsRender.length >= 0">
            <v-table
                class="c-table table-dcr mt-8"
                :headers="headers"
                :items="itemsRender"
                show-resize
                v-model:modelValue="modelValue"
                :disabled="disabled"
                manualSortKey="tel"
                :sort="internalSort"
                @update:sort="updateSort($event)"
                showSelect="multiple"
                :loading="props.loading"
                @click:row="detailRowOnClick"
            >
                <template #[`item.date_created`]="{ item }">
                    {{ item.date_created ? dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) : '' }}
                </template>
                <template #[`item.status`]="{ item }">
                    <c-status :collectionName="EXEMPTION_CERTIFICATE_COLLECTION" :id="item.status"></c-status>
                </template>
                <template #[`item.area`]="{ item }">
                    {{ item.user.area?.name }}
                </template>
                <template #[`item.zone`]="{ item }">
                    {{ item.user.zone?.name }}
                </template>
                <template #[`item.parent_id`]="{ item }">
                    {{
                        item.user.parent_id
                            ? displayParentName(item.user.parent_id?.role?.short_name, item.user.parent_id?.full_name)
                            : ''
                    }}
                </template>
            </v-table>
            <div class="footer">
                <div class="c-pagi pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="10"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
        <div v-else class="mx-auto max-w-[540px] border border-solid border-[#E2E8F0] rounded-xs bg-white">
            <div class="flex justify-center flex-col items-center px-10 pt-10 pb-12">
                <div class="p-[30px] rounded-full bg-[#F3F4F6] mb-6">
                    <v-icon x-large name="no_accounts" />
                </div>
                <div class="text-[#1E293B] text-xl font-bold text-center mb-8">{{ NO_DCR_TO_TRAIN }}</div>
                <div class="text-[#64748B] text-center mb-8">{{ NO_DCR_TO_TRAIN_DETAIL }}</div>
                <span class="custom-button-btn text-center">
                    <v-button v-for="item in channels"  @click="() => goToModuleList(item.id)" class="learning-list mr-5 mb-3">
                        <v-icon class="" name="library_books" />
                        <span class="ml-2">{{  item.name  }}</span>
                    </v-button>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { FORMAT_TIME, TYPE_OF_TRAIN } from '../../../../../shared-fe/utils/constant'
import { NO_DCR_TO_TRAIN, NO_DCR_TO_TRAIN_DETAIL } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { EXEMPTION_CERTIFICATE_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import { useUserStore } from '@/stores/user'

const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}
const router = useRouter()
const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})
const channels = ref(userStore.currentUser[TYPE_OF_TRAIN.OB]);
 const goToModuleList = (id: string) => {
    router.push({
        name: PATH_ROUTER.MODULE_LIST.NAME,
        params: {
            channel: id,
        },
    })
}
const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'updateSort'])

const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})

const itemsRender = computed(() => {
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return []
})

const headers = ref([
    {
        text: 'Họ và tên',
        value: 'full_name',
        width: 300,
    },
    {
        text: 'Mã route',
        value: 'route_name',
    },
    {
        text: `Mã ${labelUserType.value}`,
        value: 'code',
    },
    {
        text: 'Chi nhánh',
        value: 'zone',
        width: 120,
    },
    {
        text: 'Khu vực',
        value: 'area',
        width: 120,
    },
    {
        text: 'Nhà phân phối',
        value: 'system_providers',
    },
    {
        text: 'SS',
        value: 'parent_id',
    },
    {
        text: 'Ngày duyệt',
        value: 'date_apply',
    },
    {
        text: 'Người duyệt',
        value: 'person_apply',
    },
    {
        text: 'Trạng thái',
        value: 'status',
    },
])

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    let sortKey = sort?.by
    if (sort?.desc) sortKey = `-${sortKey}`
    emit('updateSort', sortKey)
}

const detailRowOnClick = (data: any) => {
    router.push({
        name: PATH_ROUTER.EXCLUDE_DCR_DETAIL.NAME,
        params: {
            id: data.item.id,
        },
    })
}

 
</script>

<style lang="scss">
.btn-continue-or-report {
    .outlined {
        font-weight: 500;
        font-size: 14px;
        border: none !important;
    }
}
</style>
