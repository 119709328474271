<template>
    <div class="px-9">
        <div class="info-request font-bold text-[var(--foreground-normal-alt)] text-6 mb-3">
            Thông tin yêu cầu tuyển dụng
        </div>
        <div class="flex justify-between mb-1">
            <div>
                <span class="font-bold text-[var(--foreground-normal-alt)]">Người yêu cầu:</span>
                <span class="ml-2">{{ item?.user_created?.full_name }}</span>
            </div>
            <div v-if="model && model?.resign_id">
                <span class="">
                    <span class="font-bold text-[var(--foreground-normal-alt)] mr-2">Liên kết:</span>
                    Tạo từ Đăng kí nghỉ việc
                    <span class="text-primary-base" @click="gotoResigns(model?.resign_id)">
                        #{{ model?.resign_id }}
                    </span>
                </span>
            </div>
        </div>
        <div class="flex justify-between mb-1" v-if="item">
            <div>
                <span class="font-bold text-[var(--foreground-normal-alt)]">Ngày tạo :</span>
                <span class="ml-2">
                    {{ item.date_created ? dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) : '' }}
                </span>
            </div>
        </div>
        <div class="flex justify-between mb-6" v-if="item">
            <div v-if="item && displayRecruitmentRequestNumberOfDate !== ''">
                <span class="font-bold text-[var(--foreground-normal-alt)]">Số ngày tuyển dụng:</span>
                <span class="ml-2">
                    {{ ` ${displayRecruitmentRequestNumberOfDate}  Ngày` }}
                </span>
            </div>
        </div>
        <div v-if="errors && Object.keys(errors).length > 0" class="v-form grid has-border !border-red-400 mb-6">
            <ul class="pl-4">
                <li v-for="error in errors">
                    {{ error }}
                </li>
            </ul>
        </div>

        <template v-if="!ready">
            <div class="flex gap-8">
                <v-skeleton-loader type="input-tall"></v-skeleton-loader>
            </div>
        </template>
        <div v-else>
            <v-form
                :primaryKey="id"
                :loading="loading"
                :initialValues="itemInit"
                class="form-general-recruitment grid-cols-3 has-border mb-6"
                v-model="model"
                :fields="fieldsGeneral"
            ></v-form>

            <template v-if="isTransfer">
                <v-form
                    v-if="formTransfer.length > 0"
                    class="grid grid-cols-3 has-border"
                    :initialValues="itemInit"
                    :fields="formTransfer"
                    v-model="model"
                ></v-form>
            </template>

            <template v-if="isReplacement">
                <div v-if="formReplacement.length > 0" class="mt-6">
                    <template v-if="!model?.resign_id">
                        <div
                            class="p-4 border border-solid border-yellow-400 bg-yellow-50 rounded-lg flex gap-3 mb-3 items-center"
                        >
                            <div class="text-orange-500">
                                <v-icon style="--v-icon-size: 44px" name="info"></v-icon>
                            </div>
                            <div>
                                Trường hợp anh/chị không thấy tên DCR nghỉ việc bên dưới: Lý do chưa khai báo nhân viên
                                nghỉ việc. Vui lòng quay lại mục
                                <span class="text-orange-500 font-bold" @click="goToCreateResign">
                                    Danh sách nghỉ việc
                                </span>
                                để tạo đơn nghỉ việc sau đó mới tạo yêu cầu tuyển dụng thay thế
                            </div>
                        </div>
                    </template>
                    <v-form
                        v-if="formReplacement.length > 0"
                        class="grid grid-cols-3 has-border"
                        :initialValues="itemInit"
                        :fields="formReplacement"
                        v-model="model"
                    ></v-form>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import api from '@/api'
import { useItem } from '@/composables/use-item'
import { useGeneral } from '@/modules/pepsi-hr/routes/recruitment_request/components/detail/useGeneralForm'
import { useReplacementForm } from '@/modules/pepsi-hr/routes/recruitment_request/components/detail/useReplacementForm'
import { useTransferForm } from '@/modules/pepsi-hr/routes/recruitment_request/components/detail/useTransferForm'
import { useRecruitmentRequestQuery } from '@/shared-fe/services/recruitment-request-service'
import { CONFIRM_MODAL_TEXT, FORMAT_TIME, RECRUITMENT_REQUEST_STATUS } from '@/shared-fe/utils/constant'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { useFieldsStore } from '@/stores/fields'
import dayjs from 'dayjs'
import { computed, ref, toRefs, watch } from 'vue'
import { useStoreCommon } from '@/shared-fe/stores/common'
import { useRecruitmentRequestEditValidate } from '../../composables/useRecruitmentRequestEditValidate'
import { useRouter } from 'vue-router'
import { displayRequestDate } from '@/shared-fe/utils/convert-data'
import { useRecruitmentRequestValidate } from '../../composables/useRecruitmentRequestValidate'
import { useSettingQuery } from '@/shared-fe/services/setting-service'
import { useUserStore } from '@/stores/user'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'

const collection = ref('recruitment_request')
const fieldsStore = useFieldsStore()
const props = defineProps(['id', 'objResignLinked'])
const { id } = toRefs(props)

const userStore = useUserStore()
const model = ref<any>({
    quantity: 1,
    position: 'DCR',
})

if (!id?.value || id.value == '+') {
    model.value.user_created = userStore.currentUser?.id
    model.value.hr_id = userStore.currentUser?.area?.hr || ''
}

if (props.objResignLinked && props.objResignLinked.resign_id) {
    setTimeout(() => {
        model.value.resign_id = props.objResignLinked.resign_id
        model.value.staff_id = props.objResignLinked.staff_id
    }, 100)
}

const router = useRouter()
const query = ref({
    fields: ['*', 'districts.*', 'user_created.id', 'user_created.full_name', 'interview_round.*'],
})
const { item, loading } = useItem(collection, id, query)
const itemInit = computed(() => {
    if (item.value) {
        return {
            ...item.value,
            user_created: item.value.user_created.id,
        }
    } else {
        return {
            user_created: userStore.currentUser?.id,
        }
    }
})

const currentSS = ref({})
const fetchSS = async (id) => {
    api.get(`/users/${id}`, {
        params: {
            fields: [
                'id',
                'full_name',
                'code',
                'route_code.name',
                'resigns.*',
                'system_providers.provider_id',
                'sub_users',
            ],
            deep: {
                resigns: {
                    _sort: ['-date_created'],
                },
            },
        },
    }).then((r) => {
        if (r.data?.data) {
            currentSS.value = r.data?.data
            currentSS.value.route = currentSS.value.route_code?.name
            currentSS.value.system_providers = r.data?.data?.system_providers.map((p) => p.provider_id)
        } else {
            currentSS.value = {}
        }
    })
}

const ssId = computed(() => model.value.user_created ?? itemInit.value.user_created?.id)
watch(
    () => ssId.value,
    (nvl) => {
        if (!nvl) {
            currentSS.value = {}
            return
        }
        fetchSS(nvl).catch()
    },
    { immediate: true }
)
const currentDCR = ref({})
const dcrID = computed(() => model.value.staff_id ?? itemInit.value.staff_id)
watch(
    () => dcrID.value,
    async (nvl) => {
        await fetchDcr(nvl).catch()
    }
)

const { getProjectSetting } = useSettingQuery()
const holidays = ref(<string[]>[])
getProjectSetting().then((res) => {
    if (res?.data?.data) {
        holidays.value = res?.data?.data?.holidays
            ?.split(',')
            ?.map((x) => x.replace('\n', '').split('/').reverse().join('-'))
    }
})

const displayRecruitmentRequestNumberOfDate = computed(() => {
    return displayRequestDate(
        item?.value,
        resignModel.value?.last_working_day ? resignModel.value?.last_working_day : false,
        holidays.value
    )
})

const fetchDcr = async (id) => {
    if (!id) {
        currentDCR.value = {}
        return
    }
    api.get(`/users/${id}`, {
        params: {
            fields: [
                'id',
                'full_name',
                'code',
                'route_code.name',
                'resigns.*',
                'system_providers.provider_id',
                'sub_users',
            ],
            deep: {
                resigns: {
                    _sort: ['-date_created'],
                },
            },
        },
    }).then((r) => {
        currentDCR.value = {}
        model.value.resign_id = null
        if (r.data?.data) {
            currentDCR.value = r.data?.data
            currentDCR.value.route = currentDCR.value.route_code?.name
            currentDCR.value.system_providers = r.data?.data?.system_providers.map((p) => p.provider_id)

            if (model.value.hiring_reason === 'replacement') {
                const [resigns] = r.data.data?.resigns
                if (resigns) {
                    model.value.resign_id = resigns.id
                }
            }
        }
    })
}

const fieldReOrder = computed(() => {
    const fs = fieldsStore.getFieldsForCollection(collection.value)
    const output: any = {}
    for (let f of fs) {
        output[f.field] = f
    }
    return output
})
watch(
    () => model.value.user_created,
    () => (model.value.providers = null)
)
watch(
    () => model.value.providers,
    () => (model.value.staff_id = '')
)

watch(
    () => currentDCR.value?.id,
    () => {
        if (currentDCR.value?.route) {
            model.value.route = currentDCR.value?.route
        }
    }
)
watch(
    () => model.value.hiring_reason,
    () => {
        model.value.staff_id = ''
        model.value.route = ''
    }
)
const { fieldsGeneral } = useGeneral({ currentSS, fieldReOrder, currentDCR }, model, itemInit)

const { formReplacement, isReplacement, resignModel } = useReplacementForm(
    { currentSS, fieldReOrder, currentDCR },
    model,
    itemInit
)

const { formTransfer, isTransfer } = useTransferForm({ currentSS, fieldReOrder, currentDCR }, model, itemInit)
const gotoResigns = (id) => {
    router.push({
        name: PATH_ROUTER.RESIGN_DETAIL.NAME,
        params: {
            id,
        },
    })
}

const goToCreateResign = () => {
    router.push({
        name: PATH_ROUTER.RESIGN_ADD.NAME,
    })
}

const ready = ref(true)
const payloadFixed = computed(() => {
    return {
        ss_id: ssId.value,
        staff_id: model.value.staff_id,
    }
})
const errors = ref({})
const requestValidEdit = useRecruitmentRequestEditValidate()
const requestValid = useRecruitmentRequestValidate()
const {
    patchItemRecruitmentRequest,
    postItemRecruitmentRequest,
    checkRouteCodeExistInTheSameProcessingStatus,
    checkRouteExists,
} = useRecruitmentRequestQuery()
const confirmModal = useConfirmModal()
const commonStore = useStoreCommon()

const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
}

const save = async () => {
    let mode = itemInit.value?.id && +itemInit.value?.id > 0 ? 'edit' : 'add'
    ready.value = false
    const mainContent = document.getElementById('main-content')
    let formData = {
        ...itemInit.value,
        ...JSON.parse(JSON.stringify(model.value)),
        ...JSON.parse(JSON.stringify(payloadFixed.value)),
    }

    // if (mode == 'edit') {
    //     formData = {
    //         ...formData,
    //         ...JSON.parse(JSON.stringify(payloadFixed.value)),
    //     }
    // }
    let val: any
    if (mode == 'add') {
        val = requestValid.validate(formData)
    } else {
        val = requestValidEdit.validate(formData)
    }

    errors.value = {}
    if (val.error) {
        errors.value = val.data
        mainContent?.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        ready.value = true
        return
    }
    if (formData.resign_id === '') delete formData.resign_id
    if (formData.staff_id === '') delete formData.staff_id

    commonStore.loading = true
    if (mode == 'add') {
        const exist = await checkRouteCodeExistInTheSameProcessingStatus(model.value.route.toUpperCase())
        if (exist) {
            errors.value = {
                ...errors.value,
                route: `Yêu cầu tuyển dụng mã route ${model.value.route} với trạng thái đang tuyển đã tổn tại`,
            }
            commonStore.loading = false
            ready.value = true
            return
        }
    }

    const startDate = formData?.start_date
    // Ngày LVCC của DCR cũ không được lớn hơn ngày bắt đầu làm việc của DCR mới nếu là thay thế
    // if (
    //     formData.hiring_reason === 'replacement' &&
    //     (dayjs(resignModel.value?.last_working_day).isAfter(startDate, 'day') ||
    //         dayjs(resignModel.value?.last_working_day).isSame(startDate, 'day'))
    // ) {
    //     errors.value = {
    //         ...errors.value,
    //         start_date: `Trùng ngày làm việc với DCR cũ. Vui lòng chọn ngày khác`,
    //     }
    //     commonStore.loading = false
    //     ready.value = true
    //     return
    // }

    // Ngày LVCC của DCR cũ không được lớn hơn ngày bắt đầu làm việc của DCR mới nếu là luân chuyển
    if (
        formData.hiring_reason === 'transfer' &&
        (dayjs(formData?.rotation_date).isAfter(startDate, 'day') ||
            dayjs(formData?.rotation_date).isSame(startDate, 'day'))
    ) {
        errors.value = {
            ...errors.value,
            start_date: `Trùng ngày luân chuyển và ngày dự kiến nhận việc. Vui lòng chọn ngày khác`,
        }
        commonStore.loading = false
        ready.value = true
        return
    }

    if (mode === 'edit') {
        formData.districts = formData.districts.map((district: string) => {
            return {
                district_id: district,
            }
        })
        await patchItemRecruitmentRequest(itemInit.value?.id, formData)
        commonStore.showDialogSucessUpdate = true
    } else {
        const payload = {
            ...formData,
            districts: model.value.districts.map((d) => {
                return {
                    request_id: '+',
                    district_id: d,
                }
            }),
            route: model.value.route.toUpperCase(),
        }
        delete payload.status
        if (model.value.hiring_reason === 'extend') {
            const exist = await checkRouteExists(model.value.route)
            if (exist) {
                await createRecruitmentRequest(payload)
            } else {
                handleConfirm(
                    CONFIRM_MODAL_TEXT.RECRUITMENT_REQUEST.CREATE_ROUTE_NAME,
                    async () => await createRecruitmentRequest(payload)
                )
            }
        } else {
            await createRecruitmentRequest(payload)
        }
    }
    commonStore.loading = false
    ready.value = true
}

const createRecruitmentRequest = async (payload: any) => {
    commonStore.loading = true
    const res = await postItemRecruitmentRequest(payload)
    commonStore.loading = false
    router.push({
        name: PATH_ROUTER.RECRUITMENT_REQUEST_DETAIL.NAME,
        params: {
            id: res?.data?.data?.id,
        },
    })
}

defineExpose({ save })
</script>

<style lang="scss">
.form-general-recruitment {
    .v-select {
        input {
            text-overflow: ellipsis;
        }
    }
}
</style>
