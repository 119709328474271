<template>
    <slot v-if="access" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import usePermission from '../../composables/use-permission'
import { useUserStore } from '@/stores/user'

const props = defineProps({
    roles: {
        type: Array<string>,
        default: [],
    },
    deniedAccessAdmin: {
        type: Boolean,
        default: false,
    },
})
const userStore = useUserStore()
const { canAccess } = usePermission()

const access = computed(() => {
    if (props.deniedAccessAdmin && userStore.currentUser?.role.admin_access) {
        return false
    }

    return canAccess(props.roles)
})
</script>
