<template>
    <div style="display: flex; gap:15px">
        <v-select v-model="topic" :items="itemsTopics"></v-select>
        <v-button large kind="info" @click="checkBeforeRun" :disabled="!values?.id || loading"
                  :loading="loading">
            <v-icon class="mr-2" name="check"/>
            <span>Kiểm tra</span>
        </v-button>

        <template v-if="hasCheck">
            <v-button large @click="createExamRoom" :disabled="!values?.id || loading" :loading="loading">
                <v-icon class="mr-2" name="add"/>
                <span>Tạo thêm exam room</span>
            </v-button>
        </template>
    </div>
    <div class="mt-2" v-if="dcrNeedToCreate.length > 0">
        <v-notice type="danger">
            Có {{ dcrNeedToCreate.length }} DCR cần tạo exam room
        </v-notice>
    </div>
</template>

<script setup lang="ts">
import {useApi} from '@directus/composables'
import {notify} from '@/utils/notify'
import {ref, watch} from 'vue'
import {inject} from 'vue'
import VNotice from "@/components/v-notice.vue";

const api = useApi()
const loading = ref(false)
const values = inject('values', ref<Record<string, any>>({}))
const users = ref({});
const topic = ref(null);

const itemsTopics = ref([])
watch(
    () => values.value.id,
    async () => {
        if (!values.value.id) return

        const topics: any = await api.get(`/items/courses/${values.value.course}`, {
            params: {
                fields: ['topics.id', "topics.title"],
            }
        }).catch((err) => {
            console.log(err)
        })

        itemsTopics.value = topics.data.data.topics.map((t: any) => {
            return {
                text: t.title,
                value: t.id
            }
        })
    }, {
        immediate: true
    }
)

const hasCheck = ref(false)
const dcrNeedToCreate = ref([]);

const checkBeforeRun = async () => {
    const resRoom: any = await getExamRoomsThisSSTrain()
    await getSessionTrainsDirectusUser();

    const dcrStartExam = Object.keys(users.value)
    dcrStartExam.forEach(u => {
        const exist = resRoom.find(r => r.user === u)
        if (!exist) {
            dcrNeedToCreate.value.push(u)
        }
    });

    if (dcrNeedToCreate.value.length === 0) {
        notify({
            title: 'Tất cả DCR đã có exam room',
            type: 'success'
        })
    } else  {
        hasCheck.value = true
    }
}

const createExamRoom = async () => {
    if (!hasCheck.value) {
        notify({
            title: 'Vui lòng [KIỂM TRA] trước khi tạo exam room',
            type: 'error'
        })
        return;
    }
    loading.value = true

    const params = {
        status: 'opened',
        topic: topic.value,
        user: null,
        session_train: values.value.id,
    }

    for (let item of dcrNeedToCreate.value) {
        params.user = item
        await openTest(params)
    }

    loading.value = false;
    hasCheck.value = false;
    dcrNeedToCreate.value = []
}

const openTest = async (param: any) => {
    const exam = await api.post('/items/exam_rooms', param)
    const updateParams = {
        id: exam.data.data.id,
        user_created: values.value.user_created,
    }
    await updateExamRoom(updateParams);
    notify({title: `Tạo thành công ROOM thi cho ${users.value[param.user].full_name}`})
}

const updateExamRoom = async (param: any) => {
    return await api.patch(`/items/exam_rooms/${param.id}`, param)
}

const getExamRoomsThisSSTrain = async () => {
    let params = {
        fields: ['*'],
        filter: {
            session_train: values.value.id
        },
    }
    return api.get('/items/exam_rooms', {params}).then((res) => {
        return res.data.data
    }).catch((err) => {
        console.log(err)
        return []
    })
}

const getSessionTrainsDirectusUser = async () => {
    await api.get(`/items/session_trains/${values.value.id}`, {
        params: {
            fields: [
                'user.directus_users_id.id',
                "user.directus_users_id.full_name",
                "exam_rooms.topic",
            ],
            filter: {
                id: {
                    _in: values.value.user
                }
            }
        }
    }).then((res) => {
        users.value = {}
        res.data.data.user.forEach((u: any) => {
            const {directus_users_id: user} = u
            users.value[user.id] = user
        });

        const topicExist = res.data.data?.exam_rooms?.[0]?.topic;
        if (topicExist) {
            topic.value = topicExist
        }
    }).catch((err) => {
        console.log(err)
    })
}

</script>

<style lang="scss"></style>
