<template>
    <div v-if="module">
        <c-wrapp-learning>
            <template #header>
                <process-module-train-header
                    :module="module"
                    @goBack="goBack"
                    @play-video="handlePlayVideoWhenBrowserIsSafari"
                >
                    <template #boxControl>
                        <div class="flex flex-row justify-center items-center">
                            <div class="min-w-100px mr-20 cursor-pointer">
                                <div @click="prevItem" v-if="currentIndex < fileByZone?.length && currentIndex > 0">
                                    <div class="flex items-center">
                                        <v-icon name="arrow_back" />
                                        <div class="ml-2">File trước</div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-5 text-[#1E293B] text-ellipsis overflow-hidden whitespace-nowrap">
                                <div class="font-bold leading-[28px]">T7HH: {{ module.title }}</div>
                                <div class="text-xs text-center font-italic">
                                    Click file tiếp theo/trước để chuyển nội dung đào tạo
                                </div>
                            </div>

                            <div class="ml-20 min-w-100px cursor-pointer">
                                <div @click="nextItem" v-if="currentIndex < fileByZone?.length - 1">
                                    <div class="flex items-center">
                                        <div class="mr-2">File tiếp theo</div>
                                        <v-icon name="arrow_forward" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #btnExcuteExam>
                        <div>
                            <dialog-upload-file
                                @nextAction="createdExamRoom"
                                :prev-exam-module="prevExamModule"
                                :loading="loading"
                            ></dialog-upload-file>
                        </div>
                    </template>
                </process-module-train-header>
            </template>

            <c-learning-player
                @ended="ableToNextFile = true"
                @pause="updateCurrentTime"
                v-if="isShowTrainning"
                ref="learningPlayer"
                :key="currentItem.id"
                poster-type="t7hh"
                :currentItem="currentItem"
            ></c-learning-player>
            <div class="w-full flex justify-center items-center custom-h" v-if="isShowDialogSuccessCreateExamRoom">
                <dialog-success-create-exam-room @next-action="showDialogExpiredTime"></dialog-success-create-exam-room>
            </div>
        </c-wrapp-learning>

        <div class="w-full flex justify-center items-center custom-h" v-if="isShowDialogExpiredTime">
            <dialog-expired-time :time-start="startTime" :totalDcr="totalDcr"></dialog-expired-time>
        </div>

        <div>
            <dialog-warning :is-show-dialog="isShowWarning" @cancel-action="isShowWarning = false"></dialog-warning>
        </div>
        <notification-dialogs />

        <dialog-choose-dcr
            @nextAction="createdExamRoomForWsr"
            @cancel-action="isShowDialogChooseDcr = false"
            :is-show-dialog="isShowDialogChooseDcr"
            :loadingCreateExam="loadingCreateExam"
        ></dialog-choose-dcr>
    </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import { useTrainingQuery } from '../../../../shared-fe/services/training-service'
import DialogUploadFile from './components/dialog-upload-file.vue'
import DialogSuccessCreateExamRoom from './components/dialog-success-create-exam-room.vue'
import DialogExpiredTime from './components/dialog-expired-time.vue'
import ProcessModuleTrainHeader from './components/process-module-train-header.vue'
import { EXAM_ROOM_STATUS, FORMAT_TIME, LOCAL_STOREGE, USER_TYPE } from '../../../../shared-fe/utils/constant'
import dayjs from 'dayjs'
import { useUserQuery } from '../../../../shared-fe/services/user-service'
import DialogWarning from './components/dialog-warning.vue'
import { isFullScreen, toggleFullScreen } from '@/shared-fe/utils/convert-data'
import { onBeforeUnmount } from 'vue'
import { notify } from '@/utils/notify'
import NotificationDialogs from '@/views/private/components/notification-dialogs.vue'
import CLearningPlayer from '@/shared-fe/components/c-learning-player/c-learning-player.vue'
import CWrappLearning from '@/shared-fe/components/c-wrapp-learning/c-wrapp-learning.vue'
import DialogChooseDcr from './components/dialog-choose-dcr.vue'
import { useUserStore } from '@/stores/user'

const imageId = ref('')
const isShowDialogSuccessCreateExamRoom = ref(false)
const isShowTrainning = ref(true)
const isShowDialogExpiredTime = ref(false)
const isShowWarning = ref(false)
const route = useRoute()
const { getModules, openTest, getExamRoomSaturday, isAllowToOpenExamRoom } = useTrainingQuery()
const { getListDCRBySystemProvider } = useUserQuery()
const module = ref(<any>{})
const router = useRouter()
const modules = ref(<any>[])
const dcrStartExam = ref(0)
const totalDcr = ref(0)
const userStore = useUserStore()
const loading = ref(false)
const currentIndex = ref(0)
const ableToNextFile = ref(<boolean>true)
const file = ref(<any>null)
const learningPlayer = ref<any>(null)
const isShowDialogChooseDcr = ref(false)
const loadingCreateExam = ref(false)

const nextItem = () => {
    if (currentItem.value.extension_file !== 'video' && !currentItem.value.url_embed) {
        currentIndex.value += 1
    } else {
        if (ableToNextFile.value) {
            currentIndex.value += 1
        } else {
            notify({
                title: 'Thông báo',
                text: 'Bạn cần xem hết video hoặc file trước mới có thể chuyển sang file tiếp theo',
                dialog: true,
                type: 'warning',
            })
        }
    }
}

const prevItem = () => {
    if (currentItem.value?.url_embed) {
        file.value[currentIndex.value].time = Number(learningPlayer.value?.getCurrentTime())
    }
    if (currentItem.value?.extension_file === 'video') {
        file.value[currentIndex.value].time = Number(learningPlayer?.value?.currentTime || 0)
    }
    currentIndex.value -= 1
}

const updateCurrentTime = (time: number) => {
    file.value[currentIndex.value].time = time
}

/**
 * because of the safari browser, when trigger full screen event, it will pause the video
 * trigger play video when full screen
 */
const handlePlayVideoWhenBrowserIsSafari = () => {
    learningPlayer.value.play()
}

const prevExamModule = ref(false)

document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
        learningPlayer.value?.pause()
    }
})

window.addEventListener('blur', () => {
    learningPlayer.value?.pause()
})

onBeforeUnmount(() => {
    if (isFullScreen()) {
        toggleFullScreen()
    }
})

watch(
    () => [module.value],
    async () => {
        if (module.value && modules.value?.length > 0) {
            currentIndex.value = 0
            const index = modules.value?.findIndex((x) => x.id === parseInt(String(route.params?.module_id)))
            if (index >= 0) {
                const res = await getExamRoomSaturday(
                    route.params?.session_train,
                    modules.value[index - 1]?.id,
                    String(route.params?.provider_id)
                )
                if (res.data.data?.length <= 0) {
                    prevExamModule.value = true
                    return
                }
            }
        }
    },
    {
        immediate: true,
    }
)

getModules(String(route.params?.session_train)).then((res) => {
    modules.value = []
    module.value = {}
    if (res.data.data?.course?.topics?.length > 0) {
        modules.value = res.data.data?.course?.topics
        module.value = modules.value.find((x) => x.id === parseInt(String(route.params?.module_id)))
    }
})

getListDCRBySystemProvider(
    userStore?.currentUser?.id,
    String(route.params?.provider_id),
    userStore?.currentUser?.user_type
).then((res) => {
    if (res?.data?.data && res?.data?.data.length > 0) {
        dcrStartExam.value = res?.data?.data
        totalDcr.value = res?.data?.data.length
    }
})

const fileByZone = computed(() => {
    let filesTemp: any = []
    let filesArr: any[] = []

    if (!file.value) {
        filesTemp = module.value?.file_by_zones?.find((x) => x.zone === userStore.currentUser?.zone?.id)
        if (!filesTemp) {
            filesTemp = module.value.file_by_zones?.find((x) => !x.zone)
        }

        if (!filesTemp) return []
    } else {
        filesTemp = file.value
        return filesTemp
    }

    if (filesTemp.url_embed) {
        filesArr.push({
            id: 'url_embed',
            url_embed: filesTemp.url_embed,
            extension_file: 'url_embed',
            time: 0,
        })
    }

    filesTemp?.files?.forEach((file: any) => {
        const fileModel = file.directus_files_id
        const _itemFile = {
            id: fileModel.id,
            url_embed: null,
            time: 0,
            extension_file: checkExtensionFile(fileModel.type, fileModel.filename_disk),
        }

        if (fileModel.video_embed) {
            _itemFile.url_embed = fileModel.video_embed
            _itemFile.extension_file = 'url_embed'
        }

        filesArr.push(_itemFile)
    })

    file.value = filesArr
    return filesArr
})

const currentItem = computed(() => {
    return fileByZone.value[currentIndex.value] ?? []
})

watch(
    () => currentItem.value,
    () => {
        if (currentItem.value.url_embed || currentItem.value.extension_file === 'video') {
            ableToNextFile.value = false
        }
    }
)

watch(
    () => fileByZone.value,
    () => {
        if (fileByZone.value?.length > 0) {
            let idFile = localStorage.getItem(`${LOCAL_STOREGE.SATURDAY_LEARN}_${module.value?.id}`)
            currentIndex.value = 0
            if (idFile) {
                currentIndex.value = fileByZone.value?.findIndex((x) => x?.id === idFile)
            }
        }
    },
    {
        immediate: true,
    }
)

watch(
    () => [currentIndex.value],
    () => {
        localStorage.setItem(`${LOCAL_STOREGE.SATURDAY_LEARN}_${module.value?.id}`, currentItem.value?.id)
    }
)

const checkExtensionFile = (type: string, fileName: string) => {
    if (!fileName) return ''
    if (type.includes('video')) {
        return 'video'
    }
    let res: any = fileName.split('.')
    if (res.length > 0) {
        return res[res.length - 1].toString().toLowerCase()
    }
}

const startTime = ref('')

const showDialogExpiredTime = () => {
    isShowDialogSuccessCreateExamRoom.value = false
    isShowDialogExpiredTime.value = true
}

const createdExamRoomForWsr = async ({ _dcrStartExam, _totalDcr }) => {
    if (_dcrStartExam.length > 0) {
        let params = {}
        loadingCreateExam.value = true
        for (let item of _dcrStartExam) {
            params = {
                status: EXAM_ROOM_STATUS.OPENED,
                topic: module.value?.id,
                image: imageId.value,
                user: item?.id,
                session_train: route.params.session_train,
            }
            await openTest(params)
            let id =
                route.params?.session_train +
                '_' +
                String(route.params?.module_id) +
                '_' +
                String(route.params?.provider_id)
            localStorage.removeItem('SATURDAY_MODULE' + id)
        }

        loadingCreateExam.value = false
        isShowDialogChooseDcr.value = false
        totalDcr.value = _totalDcr.length

        handleShowLayoutAfterSuccessExamRoom()
    }
}

const handleShowLayoutAfterSuccessExamRoom = () => {
    isShowTrainning.value = false
    isShowDialogSuccessCreateExamRoom.value = true
    startTime.value = dayjs().format(FORMAT_TIME.DDMMYYYYhmm)
    prevExamModule.value = false
}

const createdExamRoom = async (data) => {
    imageId.value = data

    if (userStore.currentUser.user_type === USER_TYPE.WSR) {
        isShowDialogChooseDcr.value = true
        return
    }
    if (isFullScreen()) {
        toggleFullScreen()
    }
    loading.value = true
    const isAllow = await isAllowToOpenExamRoom(
        String(route.params.session_train),
        String(route.params.module_id),
        String(route.params.provider_id)
    )
    if (isAllow?.data?.code === 'success') {
        if (!isAllow?.data?.result) {
            if (totalDcr.value > 0) {
                let params = {}
                for (let item of dcrStartExam.value) {
                    params = {
                        status: EXAM_ROOM_STATUS.OPENED,
                        topic: module.value?.id,
                        image: imageId.value,
                        user: item?.id,
                        session_train: route.params.session_train,
                    }
                    await openTest(params)
                    let id =
                        route.params?.session_train +
                        '_' +
                        String(route.params?.module_id) +
                        '_' +
                        String(route.params?.provider_id)
                    localStorage.removeItem('SATURDAY_MODULE' + id)
                }
            }
        } else {
            isShowWarning.value = true
        }

        handleShowLayoutAfterSuccessExamRoom()
    }
    loading.value = false
}

const goBack = () => {
    router.push({
        name: PATH_ROUTER.SATURDAY_LEARNING.NAME,
    })
}
</script>
<style lang="scss">
.custom-h {
    height: calc(100vh - 72px - 40px);
}
</style>
