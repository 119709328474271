<template>
    <sidebar-detail
        v-if="enableQuickActions"
        icon="back_Hand"
        class="recruitment-request-sidebar"
        title="Thao tác nhanh"
    >
        <v-skeleton-loader v-if="loading" class="c-loading" type="input-tall" />
        <div v-else>
            <div class="mb-6">
                <div class="text-[#1E293B] mb-2">
                    Lý do
                    <span class="required">*</span>
                </div>
                <v-select
                    showDeselect
                    :model-value="reason_rejected"
                    :items="arrayStatusReasonRejectRR"
                    @update:model-value="(e) => (reason_rejected = e)"
                ></v-select>
                <span class="message-error" v-if="errors?.reason_rejected">Vui lòng nhập lý do</span>
            </div>

            <div class="mb-6 border-b-1px border-b-solid border-[#D3DAE4]">
                <div class="text-[#1E293B] mb-2">
                    Lý do huỷ yêu cầu
                    <span class="required">*</span>
                </div>
                <div class="mb-6">
                    <v-textarea
                        placeholder="Nhập nội dung..."
                        :model-value="content"
                        @update:model-value="(e) => (content = e)"
                    />
                    <span class="message-error" v-if="errors?.content">Vui lòng nhập nội dung</span>
                </div>
            </div>

            <div class="list-btn flex gap-3 w-full">
                <c-access :roles="[USER_ROLE.HR, USER_ROLE.SS]">
                    <v-button
                        class="w-1/2 ml-auto btn-fail"
                        @click="
                            () => handleConfirm(CONFIRM_MODAL_TEXT.RECRUITMENT_REQUEST.CANCEL, async () => await submit(RECRUITMENT_REQUEST_STATUS.cancelled.value))
                        "
                    >
                        <div class="">Huỷ YCTD</div>
                    </v-button>
                </c-access>

                <c-access :roles="[USER_ROLE.ASM]" :deniedAccessAdmin="true">
                    <v-button
                        class="w-1/2 ml-auto btn-fail"
                        @click="
                            () => handleConfirm(CONFIRM_MODAL_TEXT.RECRUITMENT_REQUEST.REJECT, async () => await submit(RECRUITMENT_REQUEST_STATUS.rejected.value))
                        "
                    >
                        <div class="">Từ chối</div>
                    </v-button>
                </c-access>

                <c-access :roles="[USER_ROLE.ASM]" v-if="detail.status !== RECRUITMENT_REQUEST_STATUS.processing.value">
                    <v-button
                        class="w-full btn-pass"
                        @click="
                            () => handleConfirm(CONFIRM_MODAL_TEXT.RECRUITMENT_REQUEST.OK, async () => await submit(RECRUITMENT_REQUEST_STATUS.processing.value))
                        "
                    >
                        <div class="">Chấp nhận</div>
                    </v-button>
                </c-access>
            </div>
            <div class="my-3 text-[#D97706] text-right">{{ messageStatus }}</div>
        </div>
    </sidebar-detail>
</template>
<script setup lang="ts">
import { useGroupable } from '@directus/composables'
import { computed, ref } from 'vue'
import { useRecruitmentRequestQuery } from '../../../../../shared-fe/services/recruitment-request-service'
import { RECRUITMENT_REQUEST_STATUS, USER_ROLE, CONFIRM_MODAL_TEXT } from '../../../../../shared-fe/utils/constant'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useField } from '@/shared-fe/composables/use-field'
import { RECRUITMENT_REQUEST_COLLECTION } from '@/shared-fe/utils/table-name'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'

const emit = defineEmits(['handleSubmit'])
const confirmModal = useConfirmModal()
const props = defineProps({
    idRecruitmentRequest: {
        type: Number,
        default: null,
    },
    statusRecruitmentRequest: {
        type: String,
        default: null,
    },
    detail: {
        type: Object,
    },
})
const content = ref('')
const reason_rejected = ref('')
const errors = ref(<any>{})
const messageStatus = ref('')
const { active, toggle } = useGroupable({
    value: 'Thao tác nhanh',
    group: 'sidebar-detail',
    active: ref(true),
})

const enableQuickActions = computed(() => {
    return (
        props.statusRecruitmentRequest !== RECRUITMENT_REQUEST_STATUS.rejected.value &&
        props.statusRecruitmentRequest !== RECRUITMENT_REQUEST_STATUS.cancelled.value
    )
})

const { getReasonRejectRR } = useField()
const arrayStatusReasonRejectRR = computed(() => {
    return getReasonRejectRR(RECRUITMENT_REQUEST_COLLECTION)
})
const loading = ref(false)
const { patchItemRecruitmentRequest } = useRecruitmentRequestQuery()
const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
}

const submit = async (status) => {
    errors.value = null
    if (status === RECRUITMENT_REQUEST_STATUS.rejected.value || status === RECRUITMENT_REQUEST_STATUS.cancelled.value) {
        if (!content.value) {
            errors.value = {
                content: 'reason is required',
            }
        }
        if (!reason_rejected.value) {
            errors.value = {
                ...errors.value,
                reason_rejected: 'reason_rejected is required',
            }
        }

        if (errors.value && Object.keys(errors.value).length !== 0) {
            return
        }
    }

    loading.value = true
    if (status === RECRUITMENT_REQUEST_STATUS.rejected.value || status === RECRUITMENT_REQUEST_STATUS.cancelled.value) {
        await patchItemRecruitmentRequest(props.idRecruitmentRequest, {
            status: status,
            note: content.value,
            reason_rejected: reason_rejected.value,
        })
    } else {
        // only update status when asm approved
        await patchItemRecruitmentRequest(props.idRecruitmentRequest, {
            status: status,
        })
    }

    setTimeout(() => {
        loading.value = false
        messageStatus.value =
            status === RECRUITMENT_REQUEST_STATUS.rejected.value
                ? 'Đã từ chối yêu cầu'
                : status === RECRUITMENT_REQUEST_STATUS.cancelled.value
                ? 'Đã huỷ YCTD'
                : 'Duyệt YCTD thành công'
        content.value = ''
        errors.value = null
        setTimeout(() => {
            emit('handleSubmit')
        }, 1500)
    }, 2000)
}
</script>
<style lang="scss">
.recruitment-request-sidebar {
    .list-btn {
        .btn-fail {
            .button {
                background-color: #fee2e2;
                color: #ef4444;
                border: 1px solid #f87171;
                width: 100%;
                min-width: auto;
            }
        }

        .btn-pass {
            .button {
                width: 100%;
                background-color: #10b981;
                color: white;
                min-width: auto;
            }
        }
    }
}
</style>
