import { defineStore } from 'pinia'
import { Ref, onMounted, ref } from 'vue'
import { useWebSetting } from '../composables/use-web-setting'
import { LIVE_EDITOR } from '../utils/constant'

export const useConstantsImage = defineStore('useConstantsImage', () => {
    const imageConstants: Ref<any> = ref({})
    const saturdayLearningImage: Ref<any> = ref({})
    const pdfLink: Ref<any> = ref({})
    const webSetting = useWebSetting()

    onMounted(async () => {
        if (Object.keys(imageConstants.value).length === 0) {
            imageConstants.value = await webSetting.getWebSettingData(LIVE_EDITOR.CONSTANTS_IMAGE)
        }
        if (Object.keys(saturdayLearningImage.value).length === 0) {
            saturdayLearningImage.value = await webSetting.getWebSettingData(LIVE_EDITOR.SATURDAY_LEARNING)
        }
        if (Object.keys(pdfLink.value).length === 0) {
            pdfLink.value = await webSetting.getWebSettingData(LIVE_EDITOR.PDF)
        }
    })

    return { imageConstants, saturdayLearningImage, pdfLink }
})
