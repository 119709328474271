<template>
    <div class="type-label">
        {{ label }}
        <span v-if="required" class="required">*</span>
    </div>
    <v-select
        showDeselect
        :disabled="disabled"
        :model-value="mValue"
        :placeholder="placeholder"
        :items="itemsRender"
        @update:model-value="handleChange"
    ></v-select>
</template>

<script lang="ts" setup>
import { useApi } from '@directus/extensions-sdk'
import { computed, ref, watch } from 'vue'
import { get, isEqual } from 'lodash'

const api = useApi()
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    collection: {
        type: String,
    },
    queryFilter: {
        type: Object,
        default() {
            return {}
        },
    },
    label: {
        type: String,
    },
    placeholder: { type: String },
    field_text: {
        type: String,
        default: 'name',
    },
    field_value: {
        type: String,
        default: 'id',
    },
    modelValue: {},
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const items = ref([])

const endpoint = props.collection == 'users' ? '/users' : `/items/${props.collection}`
let prevFilter = ref(null)
watch(
    () => props.queryFilter,
    () => {

        if(isEqual(prevFilter.value, props.queryFilter) && Object.keys(props.queryFilter).length > 0){
            return;
        }
     
        prevFilter.value = JSON.parse(JSON.stringify(props.queryFilter));
         
        api.get(endpoint, {
            params: {
                limit: -1,
                ...props.queryFilter,
            },
        }).then((res) => {
            items.value = res.data.data
        })
    },
    { immediate: true }
)

const itemsRender = computed(() => {
    if (items.value.length == 0) return []
    return (
        items.value.map((it) => {
            return {
                text: get(it, props.field_text, it.name || ''),
                value: get(it, props.field_value, it.id),
            }
        }) || []
    )
})

const mValue = computed({
    set(nvl) {
        emit('update:modelValue', nvl)
    },
    get() {
        return props.modelValue
    },
})

const handleChange = (d) => {
    mValue.value = d
}
</script>
