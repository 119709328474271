<template>
    <c-role>
        <private-view title="Nhập danh sách WSR">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation name="3" />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.WSR_LIST.VALUE,
                            to: PATH_ROUTER.WSR_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <c-access :roles="[USER_ROLE.HR, USER_ROLE.AGENCY]">
                    <span class="custom-button-download-template">
                        <c-download-button :file_id="templateFile">
                            <v-icon name="file_download" />
                            <span class="ml-2">Tải file mẫu</span>
                        </c-download-button>
                    </span>
                </c-access>
                <!--  -->
            </template>
            <!-- header end -->

            <c-access :roles="[USER_ROLE.HR, USER_ROLE.AGENCY]">
                <wsr-import-layout></wsr-import-layout>
            </c-access>

            <!-- content start -->

            <!--  content end -->

            <template #sidebar>
                <sidebar-detail icon="info" title="Nhập danh sách WSR" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useSettingQuery } from '@/shared-fe/services/setting-service'
import { useStoreLoading } from '@/shared-fe/stores/loading'
import { USER_ROLE } from '@/shared-fe/utils/constant'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import CNavigation from '@/shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '@/shared-fe/components/c-role/c-role.vue'
import cAccess from '@/shared-fe/components/c-access/c-access.vue'
import { useStores } from '@directus/composables'
import CDownloadButton from '@/shared-fe/components/c-download-button/c-download-button.vue'
import wsrImportLayout from '../components/wsr-import-layout.vue'
import { toRefs } from 'vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const templateFile = ref('')
const router = useRouter()
const { useAppStore } = useStores()
const appStore = useAppStore()
const { getProjectSetting } = useSettingQuery()
const { uploading } = useStoreLoading()
const { sidebarOpen } = toRefs(appStore)
const importRef = ref(<any>null)
sidebarOpen.value = false

const navigateBack = () => {
    router.go(-1)
}

getProjectSetting().then((res) => {
    if (res?.data?.data) {
        templateFile.value = res?.data?.data?.file_template_wsr
    }
})

// handle logic header (end)
</script>
