import _ from 'lodash'
import { FORMAT_TIME, RECRUITMENT_REQUEST_STATUS, USER_ROLE } from './constant'

import dayjs, { Dayjs } from 'dayjs'
import customFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import isBetween from 'dayjs/plugin/isBetween'

import { NEGOTIATE } from './message'

dayjs.extend(customFormat)
dayjs.extend(minMax)
dayjs.extend(isBetween)

export const formatDateToDisplay = (val: any) => {
    return dayjs(val).format(FORMAT_TIME.DDMMYYYY)
}

export const formatDateObjectToQuery = (val1: any, val2: any) => {
    return [dayjs(val1).toISOString(), dayjs(val2).add(24, 'hour').toISOString()]
}

const formatMoney = (money: number, displaySuffix: boolean = true) => {
    const suffixes = ['', 'nghìn', 'triệu', 'tỷ', 'nghìn tỷ', 'triệu tỷ']
    let index = 0
    while (money >= 1000 && index < suffixes.length - 1) {
        money /= 1000
        index++
    }

    const formattedNumber = Math.floor(money).toLocaleString('vi-VN')
    const suffix = suffixes[index]

    return formattedNumber + (displaySuffix ? ' ' + suffix : '')
}

const converObjectSelect = (
    arrOrigin: Array<any>,
    arrChange: Array<any>,
    _recruitment_id: string,
    keyTableLink: string,
    keyTableMain: string,
    tableMain: string
) => {
    let _arrOrigin: Array<string> = []
    arrOrigin.forEach((x) => _arrOrigin.push(x[keyTableMain]))

    if (_.isEqual(_arrOrigin, arrChange)) {
        return {
            change: false,
            data: {
                [tableMain]: {
                    create: [],
                    delete: [],
                    update: [],
                },
            },
        }
    } else {
        const _createTemp = arrChange.filter((x) => !arrOrigin.find((k) => k[keyTableMain] === x))
        let create: any = []
        if (_createTemp.length > 0) {
            _createTemp.forEach((x) => {
                create.push({
                    [keyTableMain]: {
                        id: x,
                    },
                    [keyTableLink]: _recruitment_id,
                })
            })
        }
        arrOrigin.filter((x) => !arrChange.includes(x[keyTableMain]))
        return {
            change: true,
            data: {
                [tableMain]: {
                    create: create,
                    delete: arrOrigin.filter((x) => !arrChange.includes(x[keyTableMain])).map((x) => x.id),
                    update: [],
                },
            },
        }
    }
}

const displayParentName = (short_name: string, full_name: string) => {
    return short_name !== USER_ROLE.SS ? `${short_name} : ${full_name}` : full_name
}

const countNumberOfHolidaysBetweenTwoDays = (holidays: string[], start: Date, end: Date) => {
    let overlappingDays = holidays.reduce(function (count, holiday) {
        return count + ~~(dayjs(holiday).isAfter(start) && dayjs(holiday).isBefore(end) || dayjs(holiday).isAfter(end) && dayjs(holiday).isBefore(start));
    }, 0);
    return overlappingDays;
}

const displayRequestDate = (item: any, last_working_date: any, holidays: string[]) => {
    // check ASM do not approved, status = cancel or pending, rejected
    if (!item?.approved_date) return '';

    if ([
        RECRUITMENT_REQUEST_STATUS.cancelled.value,
        RECRUITMENT_REQUEST_STATUS.pending.value,
        RECRUITMENT_REQUEST_STATUS.rejected.value
    ].indexOf(item?.status) >= 0) return '';


    let dateApprove: any = new Date(`${item.approved_date}Z`);
    let dateByReason: any;
    // check the hiring reason
    switch (item?.hiring_reason) {
        case 'replacement':
            dateByReason = last_working_date ? last_working_date : false
            break
        case 'extend':
            dateByReason = item?.start_date ? item?.start_date : false;
            break
        case 'transfer':
            dateByReason = item?.rotation_date ? item?.rotation_date : false;
            break
        default:
            dateByReason = false;
            break
    }
    if (!dateByReason) {
        dateByReason = new Date(dateApprove);
    } else {
        dateByReason = new Date(`${dateByReason}Z`);
        dateByReason.setDate(dateByReason.getDate() - 15);
    }

    dateApprove.setHours(17);
    dateByReason.setHours(17);

    dateApprove = dayjs(dateApprove);
    dateByReason = dayjs(dateByReason);
    const begin = dateApprove.isAfter(dateByReason) ? dateApprove : dateByReason;

    // Tìm End
    let end: any = new Date();
    end.setHours(17);
    end = dayjs(end)

    let dateWorkEst: any;
    // dateWorkEst = new Date(`${item?.start_date}Z`)
    // dateWorkEst.setUTCHours(8);
    // dateWorkEst = dayjs(dateWorkEst)

    const interviewRound = item?.interview_round.filter((inter: any) => {
        return (inter.date_send_email) && (inter.staff != false);
    });

    let dateInterview: any;

    interviewRound.map((inter: any) => {
        let _date: any = new Date(`${inter.date_send_email}Z`);
        _date.setHours(17);
        _date = dayjs(_date);

        if (!dateInterview || dateInterview.isBefore(_date))
            dateInterview = _date;

        let _dateEst: any = new Date(inter.date_work_est);
        _dateEst.setHours(17)
        _dateEst = dayjs(_dateEst);

        if (!dateWorkEst || dateWorkEst.isBefore(_dateEst))
            dateWorkEst = _dateEst
    });

    // Lấy ngày nhỏ nhất trong các ngày có thể tính
    end = dayjs.min([
        dateWorkEst,
        dateInterview,
        end
    ].filter(d => dayjs(d).isValid()))

    // Đếm số ngày nghỉ trong khoảng begin - end
    const countHolidays = holidays.filter((dateString) => {
        const holiday = dayjs(dateString);
        return holiday.isBetween(begin, end, null, '[]');
    }).length;

    console.log({
        end,
        begin,
        countHolidays,
        dateWorkEst,
        dateInterview,
        holidays
    });
    console.log({
        end,
        begin,
        diff: end.diff(begin, 'days', true)
    })

    return (Math.ceil(end.diff(begin, 'days', true)) - countHolidays);
}

const convertMoney = (item: any) => {
    let valueMoney = NEGOTIATE
    if (item.salary_min && !item.salary_max) {
        valueMoney = `Từ ${formatMoney(item.salary_min)}`
    } else if (!item.salary_min && item.salary_max) {
        valueMoney = `Lên đến ${formatMoney(item.salary_max)}`
    } else if (item.salary_min && item.salary_max) {
        valueMoney = `${formatMoney(item.salary_min, false)} - ${formatMoney(item.salary_max)}`
    }
    return valueMoney
}

const choice = (index: string): string => {
    switch (index) {
        case '1':
            return 'A. '
        case '2':
            return 'B. '
        case '3':
            return 'C. '
        case '4':
            return 'D. '
        case '5':
            return 'E. '
        case '6':
            return 'F. '
        case '7':
            return 'G. '
        case '8':
            return 'H. '
        case '9':
            return 'I. '
        case '10':
            return 'J. '
        case '11':
            return 'K. '
        case '12':
            return 'L. '
        case '13':
            return 'M. '
        case '14':
            return 'N. '
        case '15':
            return 'O. '
        default:
            return 'P. '
    }
}
const sortDataSelection = (data: any) => {
    return data?.sort((a: any, b: any) => {
        let fa = a.text?.toLowerCase(),
            fb = b.text?.toLowerCase()

        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
    })
}

const areDates30DaysApart = (date1: Date, date2: Date) => {
    const millisecondsInOneDay = 24 * 60 * 60 * 1000
    const differenceInMilliseconds = Math.abs(date2 - date1)
    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay

    return differenceInDays >= 31
}

const isSunday = (date) => {
    return date.getDay() === 0 // 0 tương ứng với Chủ Nhật
}

const hasSundayInRange = (a, b) => {
    // Khởi tạo ngày bắt đầu từ a
    let currentDate = new Date(a)
    let endDate = new Date(b)
    let countSunday = 0
    // Duyệt qua từng ngày trong khoảng thời gian
    while (currentDate <= endDate) {
        if (isSunday(currentDate)) {
            countSunday++ // Nếu có Chủ Nhật, trả về true
        }
        // Tăng ngày hiện tại lên 1
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return countSunday // Nếu không có Chủ Nhật nào, trả về false
}

const sortData = (data: any, keySort: string) => {
    return data?.sort((a: any, b: any) => {
        let fa = a[keySort].toLowerCase(),
            fb = b[keySort].toLowerCase()

        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
    })
}

const ageValidation = (dateOfBirth: Date) => {
    const currentDate = new Date()
    if (currentDate.getFullYear() - dateOfBirth.getFullYear() < 18) {
        return false
    }
    if (currentDate.getFullYear() - dateOfBirth.getFullYear() == 18) {
        if (currentDate.getMonth() < dateOfBirth.getMonth()) {
            return false
        }
        if (currentDate.getMonth() == dateOfBirth.getMonth()) {
            if (currentDate.getDate() < dateOfBirth.getDate()) {
                return false
            }
        }
    }
    return true
}

const toLowerCaseNonAccentVietnamese = (str: string) => {
    str = str.toLowerCase()
    //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
    //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
    //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
    //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
    //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
    //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
    //     str = str.replace(/\u0111/g, "d");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư
    return str
}

const isFullScreen = () => {
    if (document?.fullscreenElement || document?.mozFullScreenElement ||
        document?.webkitFullscreenElement || document?.msFullscreenElement) {  // current working methods
        return true
    } else {
        return false
    }
}

const toggleFullScreen = () => {
    if (!document.fullscreenElement &&    // alternative standard method
        !document?.mozFullScreenElement && !document?.webkitFullscreenElement && !document?.msFullscreenElement) {  // current working methods
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement?.msRequestFullscreen) {
            document.documentElement?.msRequestFullscreen();
        } else if (document.documentElement?.mozRequestFullScreen) {
            document.documentElement?.mozRequestFullScreen();
        } else if (document.documentElement?.webkitRequestFullscreen) {
            document.documentElement?.webkitRequestFullscreen();
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document?.msExitFullscreen) {
            document?.msExitFullscreen();
        } else if (document?.mozCancelFullScreen) {
            document?.mozCancelFullScreen();
        } else if (document?.webkitExitFullscreen) {
            document?.webkitExitFullscreen();
        }
    }
}

const isValidDate = (date: string | undefined | null | Date | Dayjs) => {
    if (!date) return false;
    // @ts-ignore
    if (new Date(date) === "Invalid Date" || isNaN(new Date(date))) {
        return dayjs(date, FORMAT_TIME.DDMMYYYY, true).isValid();
    }
    // @ts-ignore
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};

const parseToValidDate = (date: string) => {
    if (dayjs(date, FORMAT_TIME.DDMMYYYY, true).isValid()) {
        return dayjs(date, FORMAT_TIME.DDMMYYYY)
    }

    return dayjs(date)
}

export {
    formatMoney,
    converObjectSelect,
    displayParentName,
    displayRequestDate,
    convertMoney,
    choice,
    sortData,
    areDates30DaysApart,
    hasSundayInRange,
    sortDataSelection,
    ageValidation,
    toLowerCaseNonAccentVietnamese,
    toggleFullScreen,
    isFullScreen,
    countNumberOfHolidaysBetweenTwoDays,
    isValidDate,
    parseToValidDate
}
