<template>
    <list-exemption-table
        :items="itemsRender"
        :modelSelected="modelSelected"
        @update:modelSelected="modelSelectedFun"
        :totalPages="totalPages"
        :page="page"
        @update:toPage="handleToPage"
        @updateSort="handleUpdateSort"
        :loading="loading"
    />
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import { useItems } from '@directus/composables'
import { computed, ref, toRefs } from 'vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { FORMAT_TIME, RECORD_PER_PAGE } from '../../../../../shared-fe/utils/constant'
import { EXEMPTION_CERTIFICATE_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import listExemptionTable from './list-exemption-table.vue'
import { useUserStore } from '@/stores/user'
const userStore = useUserStore(); 
const props = defineProps({
    filter: {
        type: Object,
        default: {},
    },
    search: {
        type: String,
        default: '',
    },
})

const { filter, search } = toRefs(props)
const { modelSelected } = useBatch(EXEMPTION_CERTIFICATE_COLLECTION)
const sort = ref(['-id'])
const page = ref(1)

const customFilter = computed(() => {
    return filter.value
})
const { items, totalPages, loading } = useItems(ref(EXEMPTION_CERTIFICATE_COLLECTION), {
    fields: ref([
        '*',
        'user.full_name',
        'user.route_code.name',
        'user.code',
        'user.zone.*',
        'user.area.name',
        'user.system_providers.provider_id.name',
        'user.parent_id.full_name',
        'user.parent_id.role.short_name',
        'admin_two.id',
        'admin_two.full_name',
        'admin_one.id',
        'admin_one.full_name',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search,
    filter: customFilter,
    page: page,
})
const itemsRender = computed(() => {
    return items.value.map((x) => {
        return {
            ...x,
            full_name: x?.user?.full_name,
            route_name: x?.user?.route_code?.name,
            code: x?.user?.code,
            status: exemptionStatus(x?.status_one, x?.status_two),
            system_providers: x?.user?.system_providers?.length > 0 ? x.user.system_providers[0].provider_id.name : '',
            date_apply: x?.date_apply_two
                ? dayjs(x?.date_apply_two).format(FORMAT_TIME.DDMMYYYY)
                : x?.date_apply_one
                ? dayjs(x?.date_apply_one).format(FORMAT_TIME.DDMMYYYY)
                : '',
            person_apply: x?.admin_two?.full_name
                ? x?.admin_two?.full_name
                : x?.admin_one?.full_name
                ? x?.admin_one?.full_name
                : '',
        }
    })
})

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}

const handleToPage = (_toPage: number) => {
    page.value = _toPage
}

const exemptionStatus = (statusL1, statusL2) => {
    if (statusL1 === null && statusL2 === null) {
        return 'waiting'
    }
    if (statusL1 && statusL2 === null) {
        return 'l1_approved'
    }
    if (statusL1 && statusL2) {
        return 'completed'
    }
    if (!statusL1 && !statusL2) {
        return 'reject'
    }
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
.table-dcr {
    th {
        .name {
            color: #1e4ba1;
            font-weight: bold;
        }
    }
}
</style>
