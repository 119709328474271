<template>
    <v-select
        :model-value='value'
        :items='choices'
        :disabled='disabled'
        placeholder='Chọn 1'
        @update:model-value="$emit('input', $event)"
    >
    </v-select>
</template>


<script setup lang='ts'>

import { Filter } from '@directus/types'
import { computed, watch, ref } from 'vue'
import { notify } from '@/utils/notify'
import api from '@/api'
import { useFieldsStore } from '@/stores/fields'
import { useRelationsStore } from '@/stores/relations'

type Option = {
    text: string;
    value: string | number | boolean;
    children?: Option[];
};


const props = withDefaults(
    defineProps<{
        value?: number | string | Record<string, any> | null;
        limit?: number | null;
        collection: string;
        label_field?: string;
        field: string;
        fields?: any;
        template?: string | null;
        selectMode?: 'auto' | 'dropdown' | 'modal';
        disabled?: boolean;
        filter?: Filter | null;
        enableCreate?: boolean;
        enableSelect?: boolean;
        callback_item?: null
    }>(),
    {
        value: () => null,
        selectMode: 'auto',
        disabled: false,
        template: () => null,
        filter: () => {},
        fields: () => [],
        enableCreate: true,
        enableSelect: true,
        callback_item: null
    }
)

defineEmits(['input'])


const items = ref([])
const fieldsStore = useFieldsStore()


const getCollectionUrl = computed(() => {
    const fid: any = fieldsStore.getField(props.collection, props.field)
    let collection = fid.schema.foreign_key_table
    if (collection.startsWith('directus_')) {
        collection = collection.replace('directus_', '')
    } else {
        collection = `items/${collection}`
    }

    return collection
})


const firstValue = ref<any>({})
const __query_cached_item = ref<any>('')
watch(
    () => props.value,
    () => {
        if (__query_cached_item.value != props.value) {
            api.get(`/${getCollectionUrl.value}/${props.value}`).then(res => {
                firstValue.value = res.data.data
                __query_cached_item.value = props.value
            })
        }
    },{
        immediate: true
    }
)

const _query = ref<any>({})
let __query_cached = ref({})

watch(
    () => props.filter,
    () => {
        _query.value = {
            filter: props.filter,
            fields: [
                ...props.fields ?? ["*"]
            ]
        }

        if (props.limit) {
            _query.value.limit = props.limit
        }

    }, {
        immediate: true
    }
)


const fetchData = () => {
    if (JSON.stringify(__query_cached.value) != JSON.stringify(_query.value)) {
        const collection = getCollectionUrl.value

        api.get(`/${collection}`, {
            params: _query.value
        }).then(res => {
            items.value = res.data.data
            __query_cached.value = _query.value
        })
    }
}

watch(
    () => _query.value,
    fetchData, {
        immediate: true
    }
)
const choices = computed(() => {

    const _items = props.callback_item ? props.callback_item(items.value) : items.value
    let output = _items.map((i: any) => {
        return {
            value: i.id,
            text: props.label_field ? i[props.label_field] : i.name ?? i.title
        }
    })

    if (firstValue.value.id) {
        output = [
            {
                value: firstValue.value.id,
                text: props.label_field ? firstValue.value[props.label_field] : firstValue.value.name ?? firstValue.value.title
            },
            ...output
        ]
    }


    return output
})
</script>
