<template>
    <div class="flex flex-col items-center justify-center py-10 px-28px w-full min-w-[540px] bg-white rounded-md">
        <div class="mb-6">
            <div
                class="p-6 bg-[#DEF4F5] w-100px h-100px rounded-full box-icon-success flex items-center justify-center"
            >
                <v-icon name="lock_reset"></v-icon>
            </div>
        </div>
        <div class="type-label">
            <div class="text-5 text-[#1E293B] font-bold mb-1">Hệ thống đã đóng lại</div>
        </div>
        <div class="text-14px text-[#64748B]">Thời gian còn lại để bắt đầu khóa thi:</div>
        <div class="time-down flex flex-row justify-center mt-8">
            <div class="flex flex-col items-start text-[#1E4BA1] mr-18px">
                <div class="flex items-center">
                    <div class="i-mdi-calendar-blank-outline"></div>
                    <div class="ml-2">Ngày thi</div>
                </div>
                <div class="text-14px mt-2">{{ timeStart }}</div>
            </div>
            <div class="flex flex-col items-start text-[#1E4BA1] ml-18px">
                <div class="">Số lượng {{ labelUserType }} đã thi</div>
                <div class="text-14px mt-2">{{ `${totalDcr} ${labelUserType} ` }}</div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useUserStore } from '@/stores/user';
const labelUserType = computed(() => {
    return userStore?.currentUser?.label_user_type
})

const userStore = useUserStore();
const props = defineProps({
    timeStart: {
        type: String,
        default: '',
    },
    totalDcr: {
        type: Number,
        default: '',
    },
    dcrStartExam: {
        type: Number,
        default: '',
    },
})
</script>
<style lang="scss">
.box-icon-success {
    position: relative;
    .v-icon i {
        color: var(--primary);
        font-size: 30px;
        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
