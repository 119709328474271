<template>
    <c-role>
        <private-view title="Đăng kí nghỉ việc">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.RESIGN_LIST.VALUE,
                            to: PATH_ROUTER.RESIGN_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <div class="box-save">
                    <v-button
                        v-tooltip.bottom="SAVE"
                        :loading="commonStore.loading"
                        :disabled="commonStore.loading"
                        icon
                        rounded
                        @click="save"
                    >
                        <v-icon name="check" />
                    </v-button>
                </div>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->

            <resign-detail />
            <!--  content end -->

            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin ứng viên" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue'
import { useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { MODE_ADD, SUBMIT_EVENT } from '../../../../../shared-fe/utils/constant'
import { SAVE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'

import ResignDetail from '../components/resign-detail.vue'
const commonStore = useStoreCommon()
const dataForm = ref(<any>null)
onBeforeUnmount(() => {})
// handle logic header (start)
const router = useRouter()
const save = () => {
    commonStore.submitEvent({
        name: SUBMIT_EVENT.RESIGN_DETAIL_SUBMIT,
        payload: MODE_ADD,
    })
}

const navigateBack = () => {
    router.push({
        name: PATH_ROUTER.RESIGN_LIST.NAME,
    })
}

// handle logic header (end)
</script>

<style lang="scss"></style>
