import { USER_ROLE } from './constant'

const PATH_ROUTER = {
    ADMIN_HR: {
        PATH: '/spvn/admin',
        NAME: 'admin_hr',
        VALUE: 'SaleForce',
        PERMISSION: [USER_ROLE.HR],
    },
    RECRUITMENT_LIST: {
        PATH: '/spvn/recruitments',
        NAME: 'recruitments',
        VALUE: 'Tin tuyển dụng',
        PERMISSION: [USER_ROLE.HR],
    },
    RECRUITMENT_DETAIL: {
        PATH: '/spvn/recruitments/:id',
        NAME: 'recruitment-detail',
        VALUE: 'Thông tin chi tiết tuyển dụng',
        PERMISSION: [USER_ROLE.HR],
    },
    RECRUITMENT_ADD: {
        PATH: '/spvn/recruitments/+',
        NAME: 'recruitment-add',
        VALUE: 'Tạo tin tuyển dụng',
        PERMISSION: [USER_ROLE.HR],
    },
    RECRUITMENT_REQUEST_LIST: {
        PATH: '/spvn/recruitment-request',
        NAME: 'recruitment-request',
        VALUE: 'Yêu cầu tuyển dụng',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    RECRUITMENT_REQUEST_DETAIL: {
        PATH: '/spvn/recruitment-request/:id',
        NAME: 'recruitment-request-detail',
        VALUE: 'Chi tiết yêu cầu',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    RECRUITMENT_REQUEST_ADD: {
        PATH: '/spvn/recruitment-request/+',
        NAME: 'recruitment-request-add',
        VALUE: 'Tạo mới yêu cầu',
        PERMISSION: [USER_ROLE.ASM, USER_ROLE.SS],
    },
    CANDIDATE_LIST: {
        PATH: '/spvn/candidates',
        NAME: 'candidates',
        VALUE: 'Danh sách ứng viên',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    CANDIDATE_DETAIL: {
        PATH: '/spvn/candidates/:id',
        NAME: 'candidate detail',
        VALUE: 'Thông tin ứng viên',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    CANDIDATE_ADD: {
        PATH: '/spvn/candidates/+',
        NAME: 'candidate add',
        VALUE: 'Tạo mới ứng viên',
        PERMISSION: [USER_ROLE.HR],
    },
    INTRODUCT_CANDIDATE_LIST: {
        PATH: '/spvn/introduct-candidates',
        NAME: 'introduct-candidates',
        VALUE: 'Giới thiệu ứng viên',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.SS],
    },
    INTRODUCT_CANDIDATE_ADD: {
        PATH: '/spvn/introduct-candidates/+',
        NAME: 'introduct-candidates add',
        VALUE: 'Tạo mới giới thiệu ứng viên',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.SS],
    },
    DCR_LIST: {
        PATH: '/spvn/dcrs',
        NAME: 'dcrs',
        VALUE: 'Danh sách nhân viên',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    DCR_DETAIL: {
        PATH: '/spvn/dcrs/:id',
        NAME: 'dcr detail',
        VALUE: 'Thông tin DCR',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    RESIGN_LIST: {
        PATH: '/spvn/resigns',
        NAME: 'resigns',
        VALUE: 'Danh sách nghỉ việc',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    RESIGN_DETAIL: {
        PATH: '/spvn/resigns/:id',
        NAME: 'resign detail',
        VALUE: 'Chi tiết nghỉ việc',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    RESIGN_ADD: {
        PATH: '/spvn/resigns/+',
        NAME: 'resign add',
        VALUE: 'Đăng kí nghỉ việc',
        PERMISSION: [USER_ROLE.ASM, USER_ROLE.SS],
    },
    PROBATIONARY_ASSESSMENTS_LIST: {
        PATH: '/spvn/probationary-assessments',
        NAME: 'probationary assessments',
        VALUE: 'Đánh giá thử việc',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    PROBATIONARY_ASSESSMENTS_DETAIL: {
        PATH: '/spvn/probationary-assessments/:id',
        NAME: 'probationary assessments detail',
        VALUE: 'Đánh giá thử việc',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    ACCESS_DENIED: {
        PATH: '/spvn/access-denied',
        NAME: 'access denied',
        VALUE: '',
        PERMISSION: [''],
    },
    LD_LEARNING_DETAIL: {
        PATH: '/ld',
        NAME: 'ld-leaning-detail',
        VALUE: 'DCR Onboarding',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    DCR_ONBOARDING_DETAIL: {
        PATH: '/ld/session-train-detail/:session_train',
        NAME: 'session-train-detail',
        VALUE: 'E-leaning',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    PROCESS_MODULE_TRAIN: {
        PATH: '/ld/process-module-train/:session_train/:module_id',
        NAME: 'process-module-train',
        VALUE: 'E-leaning',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    PROCESS_MODULE_RETRY_TRAIN: {
        PATH: '/ld/process-module-train/retry/:session_train/:module_id',
        NAME: 'process-module-retry-train',
        VALUE: 'E-leaning',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    RESULT_EXAM_MODULE: {
        PATH: '/ld/result-exam-module/:session_train/:user_id/:module_id',
        NAME: 'result-exam-module',
        VALUE: 'E-leaning',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    RESULT_DETAIL_EXAM_MODULE: {
        PATH: '/ld/result-detail-exam-module/:session_train/:user_id/:exam_result_id',
        NAME: 'result-exam-detail-module',
        VALUE: 'E-leaning',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    MODULE_LIST: {
        PATH: '/ld/module-list/:channel',
        NAME: 'module-list',
        VALUE: 'E-leaning',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    EXCLUDE_DCR_DETAIL: {
        PATH: '/ld/exclude-dcr-detail/:id',
        NAME: 'exclude-dcr-detail',
        VALUE: 'Xin duyệt miễn trừ',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    SATURDAY_LEARNING: {
        PATH: '/ld/saturday-learning',
        NAME: 'saturday-learning-home',
        VALUE: 'Thứ 7 Học Hay',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    SATURDAY_LEARNING_TRAINING: {
        PATH: '/ld/saturday-learning/process-train/:session_train/:module_id/:provider_id',
        NAME: 'saturday-learning-process-train',
        VALUE: 'Học tập - Thứ 7 Học Hay',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    SATURDAY_LEARNING_RESULT_LIST: {
        PATH: '/ld/saturday-learning/result-exam-module/:session_train/:user_id/:module_id',
        NAME: 'saturday-learning-result-exam-module',
        VALUE: 'Học tập - Thứ 7 Học Hay',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    SATURDAY_LEARNING_RESULT_DETAIL: {
        PATH: '/ld/saturday-learning/result-detail-exam-module/:session_train/:user_id/:exam_result_id',
        NAME: 'saturday-learning-result-exam-detail',
        VALUE: 'Học tập - Thứ 7 Học Hay',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    LEARNING_REPORT: {
        PATH: '/ld/report',
        NAME: 'learning-report',
        VALUE: 'Báo cáo',
        PERMISSION: ['hr', USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    LEARNING_SETTINGS: {
        PATH: '/ld/settings',
        NAME: 'learning-settings',
        VALUE: 'Cài đặt',
        PERMISSION: [USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    RECRUITMENT_SETTINGS: {
        PATH: '/spvn/settings',
        NAME: 'spvn-settings',
        VALUE: 'Cài đặt',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.ASM, USER_ROLE.SS],
    },
    TARGET_MANAGEMENT: {
        PATH: '/ld/target-management',
        NAME: 'target-management',
        VALUE: 'Quản lý chỉ tiêu',
        PERMISSION: [USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2, USER_ROLE.SS],
    },
    OB_RESULT_ADJUSTMENT: {
        PATH: '/ld/ob-result-adjustment',
        NAME: 'ob-result-adjustment',
        VALUE: 'Điều chỉnh kết quả Onboarding',
        PERMISSION: [USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    RECRUITMENT_REPORT: {
        PATH: '/spvn/report',
        NAME: 'spvn-report',
        VALUE: 'Báo cáo',
        PERMISSION: [USER_ROLE.HR],
    },
    ADMIN_AGENCY: {
        PATH: '/spvn/agency',
        NAME: 'spvn-agency',
        VALUE: 'Agency',
        PERMISSION: [USER_ROLE.AGENCY],
    },
    WSR_LIST: {
        PATH: '/spvn/wsrs',
        NAME: 'wsrs',
        VALUE: 'Danh sách nhân viên',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.AGENCY, USER_ROLE.ASM, USER_ROLE.SS, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    WSR_DETAIL: {
        PATH: '/spvn/wsrs/:id',
        NAME: 'wsr detail',
        VALUE: 'Thông tin WSR',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.AGENCY, USER_ROLE.SS, USER_ROLE.ASM, USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2],
    },
    WSR_ADD: {
        PATH: '/spvn/wsrs/add',
        NAME: 'wsr add new',
        VALUE: 'Tạo mới WSR',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.AGENCY],
    },
    IMPORT_WSR: {
        PATH: '/spvn/wsrs/import',
        NAME: 'wsr import',
        VALUE: 'Import WSR',
        PERMISSION: [USER_ROLE.HR, USER_ROLE.AGENCY],
    },
}

export { PATH_ROUTER }
