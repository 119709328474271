
export const linkFilesToView = (idFile: string, proxy = true) => {
    if (!proxy)
        return `${window.location.origin}/assets/${idFile}`
    else
        return `${window.location.origin}/proxy-s3/${idFile}`
    //
    // if (localStorage.getItem('is_admin') === 'true') {
    //     return `${window.location.origin}/proxy-s3/${idFile}`
    // } else {
    //     return `${window.location.origin}/assets/${idFile}`
    // }
}
