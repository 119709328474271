<template>
    <div class="c-date-picker">
        <div v-if="!hiddenInputDate" class="p-2 flex gap-1 items-center fake-input">
            <div class="flex-grow relative">
                <span class="mark bg-white absolute right-1 h-8 top-1 w-8"></span>
                <input
                    v-model="dateInput"
                    type="date"
                    class="w-full bg-white py-1 px-2 rounded border-solid border-1 border-gray-300"
                />
            </div>
            <div class="">
                <button
                    @click="changeInput"
                    class="bg-blue-900 text-white px-2 py-1 rounded rounded border-solid border-1 border-blue-900"
                >
                    Chọn
                </button>
            </div>
        </div>
        <div ref="wrapper" class="v-date-picker">
            <input class="input" type="text" data-input />
        </div>
    </div>
</template>

<script setup lang="ts">
import { notify } from '@/utils/notify'
import { format, formatISO, isValid } from 'date-fns'
import Flatpickr from 'flatpickr'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { CURRENT_DAY } from '../../utils/message'

interface Props {
    type: 'date' | 'time' | 'dateTime' | 'timestamp'
    modelValue?: string
    disabled?: boolean
    includeSeconds?: boolean
    use24?: boolean
    specificdays?: Array<String>
    disableSunday?: boolean
    disableSaturday?: boolean
    enableDate?: Array<any>
    minDate?: string
    maxDate?: string
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    disabled: false,
    includeSeconds: false,
    use24: true,
    specificdays: [],
    disableSunday: false,
    disableSaturday: false,
    enableDate: [],
    minDate: '',
    maxDate: '',
})

const hiddenInputDate = computed(() => {
    return (
        props.specificdays?.length > 0 ||
        props.disableSunday === true ||
        props.disableSaturday === true ||
        props.enableDate?.length > 0 ||
        props.minDate !== '' ||
        props.maxDate !== ''
    )
})

const emit = defineEmits(['update:modelValue', 'close'])

const wrapper = ref<HTMLElement | null>(null)
let flatpickr: Flatpickr.Instance | null

const flatpickrLocale = {
    weekdays: {
        shorthand: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        longhand: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
    },

    months: {
        shorthand: ['Th1', 'Th2', 'Th3', 'Th4', 'Th5', 'Th6', 'Th7', 'Th8', 'Th9', 'Th10', 'Th11', 'Th12'],
        longhand: [
            'Tháng một',
            'Tháng hai',
            'Tháng ba',
            'Tháng tư',
            'Tháng năm',
            'Tháng sáu',
            'Tháng bảy',
            'Tháng tám',
            'Tháng chín',
            'Tháng mười',
            'Tháng mười một',
            'Tháng mười hai',
        ],
    },

    firstDayOfWeek: 1,
    rangeSeparator: ' đến ',
}

onMounted(async () => {
    if (wrapper.value) {
        flatpickr = Flatpickr(wrapper.value, {
            ...flatpickrOptions.value,
            locale: flatpickrLocale,
        } as any)
    }

    watch(
        () => props.modelValue,
        () => {
            if (props.modelValue) {
                flatpickr?.setDate(props.modelValue, false)
            } else {
                flatpickr?.clear()
            }
        },
        { immediate: true }
    )
})

onBeforeUnmount(() => {
    if (flatpickr) {
        flatpickr.close()
        flatpickr = null
    }
})

const defaultOptions = {
    static: true,
    inline: true,

    nextArrow:
        '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>',
    prevArrow:
        '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/></svg>',
    wrap: true,

    onChange(selectedDates: Date[], _dateStr: string, _instance: Flatpickr.Instance) {
        const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null
        emitValue(selectedDate)
    },
    onClose(selectedDates: Date[], _dateStr: string, _instance: Flatpickr.Instance) {
        const selectedDate = selectedDates.length > 0 ? selectedDates[0] : null
        selectedDate && emitValue(selectedDate)
    },
    onReady(_selectedDates: Date[], _dateStr: string, instance: Flatpickr.Instance) {
        const setToNowButton: HTMLElement = document.createElement('button')
        setToNowButton.innerHTML = CURRENT_DAY
        setToNowButton.classList.add('set-to-now-button')
        setToNowButton.tabIndex = -1
        setToNowButton.addEventListener('click', setToNow)
        instance.calendarContainer.appendChild(setToNowButton)

        if (!props.use24) {
            instance.amPM?.addEventListener('keyup', enterToClose)
        } else if (props.includeSeconds) {
            instance.secondElement?.addEventListener('keyup', enterToClose)
        } else {
            instance.minuteElement?.addEventListener('keyup', enterToClose)
        }
    },
}

const saturdays = (date) => {
    return date.getDay() === 6
}
const sundays = (date) => {
    return date.getDay() === 0
}
const flatpickrOptions = computed<Record<string, any>>(() => {
    let obj: any = {
        enableSeconds: props.includeSeconds,
        enableTime: ['dateTime', 'time', 'timestamp'].includes(props.type),
        noCalendar: props.type === 'time',
        time_24hr: props.use24,
    }

    let dates = []

    if (props.specificdays?.length > 0) {
        dates = [...props.specificdays]
    }

    if (props.disableSaturday) {
        dates = [...dates, saturdays]
    }
    if (props.disableSunday) {
        dates = [...dates, sundays]
    }

    obj = {
        ...obj,
        disable: dates,
    }

    if (props.enableDate?.length > 0) {
        obj = {
            ...obj,
            enable: props.enableDate,
        }
    }

    if (props.minDate) {
        obj = {
            ...obj,
            minDate: props.minDate,
        }
    }

    if (props.maxDate) {
        obj = {
            ...obj,
            maxDate: props.maxDate,
        }
    }

    return Object.assign({}, defaultOptions, obj)
})

function emitValue(value: Date | null) {
    if (!value) return emit('update:modelValue', null)
    switch (props.type) {
        case 'dateTime':
            emit('update:modelValue', format(value, "yyyy-MM-dd'T'HH:mm:ss"))
            break
        case 'date':
            emit('update:modelValue', format(value, 'yyyy-MM-dd'))
            break
        case 'time':
            emit('update:modelValue', format(value, 'HH:mm:ss'))
            break
        case 'timestamp':
            emit('update:modelValue', formatISO(value))
            break
    }

    // close the calendar on input change if it's only a date picker without time input
    if (props.type === 'date') {
        emit('close')
    }
}

let initDate: string = props.modelValue
if (initDate) {
    initDate = format(new Date(initDate), 'yyyy-MM-dd')
}

const dateInput = ref(initDate)

function changeInput() {
    const val = new Date(dateInput.value)
    if (isValid(val)) {
        flatpickr?.setDate(val, true)
    } else {
        notify({
            title: `Định dạng ngày [${dateInput.value}] chọn không hợp lệ`,
        })
    }
}

function setToNow() {
    flatpickr?.setDate(new Date(), true)
}

function enterToClose(e: any) {
    if (e.key !== 'Enter') return
    flatpickr?.close()
    emit('close')
}
</script>

<style lang="scss" scoped>
.c-date-picker:deep {
    .input {
        display: none;
    }

    // .rangeMode {
    //     .flatpickr-day.inRange {
    //         border-radius: 0;
    //         box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
    //         background: #e6e6e6;
    //         border-color: #e6e6e6;
    //     }
    // }
}

.fake-input {
    button {
        width: 100%;
        //transform: translateX(-50%);
    }
}
</style>
