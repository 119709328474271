<template>
    <v-button :disabled="!fileModel.id" :href="getAssetUrl(fileModel.id, true)" :download="fileModel.filename_download">
        <template v-if="fileModel.id">
            <slot></slot>
        </template>

        <span v-else>Không tìm thấy File</span>
    </v-button>
</template>

<script setup lang="ts">
import { getAssetUrl } from '@/utils/get-asset-url'
import { ref, watch } from 'vue'
import api from '@/api'

const props = defineProps(['file_id'])

const fileModel = ref({
    id: null,
    filename_download: null,
})

watch(
    () => props.file_id,
    () => {
        if (props.file_id) {
            api.get(`/files/${props.file_id}`).then(({ data }) => {
                console.log(data)
                if (data.data?.id) {
                    fileModel.value = data.data
                }
            })
        }
    },
    {
        immediate: true,
    }
)
</script>
