<template>
    <div class="search-bar-container p-[1.4rem] rounded-lg bg-white">
        <div class="searchbar flex gap-x-7 items-center">
            <div class="flex-grow flex basic-slot">
                <template v-for="(item, index) in data" :key="index">
                    <component
                        v-if="item.type !== 'c-range-datetime'"
                        :is="item.type"
                        class="w-[166%]"
                        :model-value="item.value"
                        :placeholder="item.placeholder"
                        :items="item.data ? item.data : null"
                        :showDeselect="true"
                        @update:model-value="(event) => handleChange(item.type, event, index)"
                    >
                        <template v-if="item.icon" #prepend><v-icon :name="item.icon"></v-icon></template>
                    </component>
                    <c-range-datetime
                        v-else
                        :placeholder="RANGE_DATE_PLACEHOLDER"
                        :rangeDateValue="item.data"
                        @update:model-value="(event) => handleChange(item.type, event, index)"
                    />
                    <div class="border-right"></div>
                </template>
            </div>

            <div class="flex gap-2">
                <v-button @click="handleSubmit">{{ SEARCH_BTN }}</v-button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, watch, Ref } from 'vue'
import { RANGE_DATE_PLACEHOLDER } from '../../utils/message'
import CRangeDatetime from '../c-range-datetime/c-range-datetime.vue'
import { ComponentPropsModel } from '../../types/table-type'

const props = defineProps({
    data: {
        type: Array<ComponentPropsModel>,
        default: [],
    },
})

const SEARCH_BTN = 'Tìm kiếm'

const data: Ref<ComponentPropsModel[]> = ref(props.data)

const emit = defineEmits(['update:model-value', 'changeItem'])

const handleSubmit = () => {
    let dataItem = {}
    data.value.forEach((x) => {
        dataItem[x.key] = x.type === 'c-range-datetime' ? x?.dataItem : x.value
    })

    emit('update:model-value', dataItem)
}

/**
 * handle change data
 */
const handleChange = (type, event, index) => {
    let key = ''
    if (type === 'c-range-datetime') {
        data.value[index].data = event
    } else {
        data.value[index].value = event
    }
    key = data.value[index].key
    emit('changeItem', { value: event, key })
}

watch(
    () => props.data,
    (newData) => {
        data.value = newData
    },
    { immediate: true }
)
</script>

<style lang="scss" scoped>
.search-bar-container:deep {
    border: 1px solid var(--primary);

    .searchbar {
        .v-input {
            .input {
                border: none;
            }
        }
    }

    .border-right {
        border-right: 2px solid var(--border-subdued);

        &:last-child {
            background-color: red;
            border-right: none;
        }
    }

    .border-top {
        border-top: 1px solid var(--border-subdued);
    }
}
</style>
