import Joi from 'joi'

export const useWorkWithValidate = () => {
    const messageLabels = {
        ww_date: 'Cột A',
        zone: 'Cột B',
        area: 'Cột C',
        sd_name: 'Cột D',
        asm_name: 'Cột E',
        ss_code: 'Cột F',
        ss_name: 'Cột G',
        email: 'Cột H',
        work_item: 'Cột I',
        objective: 'Cột J',
        route_code: 'Cột K',
        dcr_name: 'Cột L',
        route_type: 'Cột M',
        call: 'Cột N',
        visited: 'Cột O',
        result: 'Cột P',
        status: 'Cột Q',
        source_id: 'Cột S',
        dcr_code: 'Cột T',
    }
    const customMessages = {
        'number.base': 'lỗi định dạng',
        'string.base': 'lỗi định dạng',
        'string.empty': 'đang trống dữ liệu',
        'any.required': 'đang trống dữ liệu',
    }

    const schema = Joi.object({
        ww_date: Joi.string().required().messages(customMessages),
        zone: Joi.string().required().messages(customMessages),
        area: Joi.string().allow('').optional().messages(customMessages),
        sd_name: Joi.string().allow('').optional().messages(customMessages),
        asm_name: Joi.string().required().messages(customMessages),
        ss_code: Joi.string().messages(customMessages),
        ss_name: Joi.string().required().messages(customMessages),
        email: Joi.string().allow('').optional().messages(customMessages),
        work_item: Joi.string().required().messages(customMessages),
        objective: Joi.string().allow('').optional().messages(customMessages),
        route_code: Joi.string().required().messages(customMessages),
        dcr_name: Joi.string().required().messages(customMessages),
        route_type: Joi.string().allow('').optional().messages(customMessages),
        call: Joi.number().required().messages(customMessages),
        visited: Joi.number().required().messages(customMessages),
        result: Joi.string().required().messages(customMessages),
        status: Joi.string().allow('').optional().messages(customMessages),
        dcr_code: Joi.string().required().messages(customMessages),
        source_id: Joi.string().allow('').optional().messages(customMessages),
    }).unknown(true)

    const validate = (formData: any) => {
        const { error } = schema.validate(formData, { abortEarly: false })
        let messages: string[] = []
        if (error?.details) {
            error.details.map((detail) => {
                const customLabel = messageLabels[detail.context.key]
                messages.push(`\"${customLabel} ${detail.message}\"`)
            })
        }

        return {
            error: !!error,
            data: messages,
        }
    }

    return {
        messageLabels,
        schema,
        validate,
    }
}
