<template>
    <div>
        <list-sessions-train
            :items="itemsRender"
            :modelSelected="modelSelected"
            @update:modelSelected="modelSelectedFun"
            :totalPages="totalPages"
            :page="page"
            @update:toPage="handleToPage"
            @updateSort="handleUpdateSort"
            @refresh="refresh"
            :loading="loading"
        />
    </div>
</template>

<script lang="ts" setup>
import { useItems } from '@directus/composables'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { COURSE_BY_USER_TYPE, RECORD_PER_PAGE, TYPE_OF_TRAIN } from '../../../../../shared-fe/utils/constant'
import { SESSION_TRAINS_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import listSessionsTrain from './list-sessions-train.vue'
import { useUserStore } from '@/stores/user'
const userStore = useUserStore(); 
const props = defineProps({
    filter: {
        type: Object,
        default: {},
    },
    search: {
        type: String,
        default: '',
    },
})

const route = useRoute()
const { filter, search } = toRefs(props)
const { modelSelected } = useBatch(SESSION_TRAINS_COLLECTION)
const sort = ref(['-id'])
const page = ref(1)


const customFilter = computed(() => {
    let _filter = filter.value
    let _and = _filter?._and || []
    let _filterSSTrainByOB = {
        course: {
            channel:{
                type_of_train: {
                    _eq: TYPE_OF_TRAIN.OB
                }
            },
            id:{
                _in: userStore.currentUser[COURSE_BY_USER_TYPE.OB]
            }
            
        },
        
    }
    if (_and && _and.length > 0) {
        _filter = {
            _and: [
                ..._and,
                _filterSSTrainByOB,
            ],
        }
    } else {
        _filter = {
            _and: [_filterSSTrainByOB],
        }
    }

    return _filter
})

const { items, totalPages, getItems, getTotalCount, getItemCount, loading } = useItems(ref(SESSION_TRAINS_COLLECTION), {
    fields: ref([
        '*',
        'course.channel.name',
        'course.channel.id',
        'user.directus_users_id.full_name',
        'user_created.full_name',
        'user.directus_users_id',
        'user_created.id',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search,
    filter: customFilter,
    page: page,
})
const itemsRender = computed(() => {
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    return items.value.map((x) => {
        index++
        return {
            ...x,
            stt: index
        }
    })
})

const primaryKeys = computed(() => {
    return modelSelected.value?.map((x) => x.id) || []
})

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}

const handleToPage = (_toPage: number) => {
    page.value = _toPage
}

const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
.table-dcr {
    th {
        .name {
            color: #1e4ba1;
            font-weight: bold;
        }
    }
}
</style>
