const LABEL_DELETE = 'Xóa'
const CONTENT_DELETE = 'Bạn có chắc bạn muốn xóa mục này? Hành động này không thể được hoàn tác.'
const CANCEL = 'Hủy bỏ'
const EDIT = 'Sửa'
const CREATE_ITEM = 'Tạo mới'
const ARCHIVE = 'lưu trữ'
const CONTENT_ARCHIVE = 'Bạn có chắc chắn muốn lưu trữ mục này không?'
const EXPORT_EXCEL = 'Xuất excel'
const POSITION = 'Vị trí'
const PROVINCE_AREA = 'Khu vực'
const STATUS = 'Trạng thái'
const SALARY = 'Mức lương'
const CREATED = 'Ngày đăng'
const EXPIRED_TIME = 'Ngày hết hạn'
const ACTIONS = 'Công cụ'
const SEARCH_BOX = 'Tìm kiếm...'
const SEARCH_BTN = 'Tìm kiếm'
const RANGE_DATE_PLACEHOLDER = 'Từ ngày - Đến ngày'
const PROVINCE = 'Thành phố'
const JOB_ID = 'Mã công việc'
const DISTRICT = 'Khu vực'
const AREA = 'Area'
const PROVIDER = 'Nhà phân phối'
const CREATOR = 'Người tạo'
const RECRUITMENT_STAFF = 'NV tuyển dụng'
const SEARCHBAR_EXPAND = 'Mở rộng bộ tìm kiếm'
const LESS_EXPAND = 'Thu gọn'
const TITLE = 'Tiêu đề'
const POST_AVATAR = 'Ảnh đại diện bài đăng'
const HOT_JOB = 'Việc làm hot'
const WORK_STATION = 'Khu vực làm việc'
const CHOOSE_FIVE_PLACES = 'Chọn 5 địa điểm'
const MIN_SALARY = 'Mức lương thấp nhất'
const MAX_SALARY = 'Mức lương cao nhất'
const NEGOTIATE = 'Thương lượng'
const JOB_DESCRIPTION = 'Mô tả công việc'
const JOB_REQUIREMENT = 'Yêu cầu công việc'
const BENEFIT = 'Phúc lợi'
const MAX_CHAR_NUMBER = 'Tối đa 1500 kí tự'
const CONTACT_EMAIL = 'Email liên hệ'
const CONTACT_NAME = 'Họ và tên liên hệ'
const CONTACT_PHONE_NUMBER = 'Số điện thoại liên hệ'
const SUBMIT_FORM_DEADLINE = 'Hạn nộp hồ sơ'
const TITLE_RECRUITMENT = 'Tiêu đề tin đăng'
const TITLE_RANGE_SALARY_PLACHEHOLDER = '1 - 2 triệu'
const TITLE_ALL_PLACHEHOLDER = 'Tất cả'
const CURRENT_DAY = 'Ngày hiện tại'
const SAVE = 'Lưu'
const REQUIRED_UPLOAD_IMAGE = 'Vui lòng upload hình ảnh'
const REQUIRED_TITLE = 'Vui lòng tiêu đề'
const REQUIRED_AREA_WORK = 'Vui lòng nhập khu vực làm việc'
const REQUIRED_CONTENT_WORK = 'Vui lòng nhập nội dung công việc'
const REQUIRED_REQUIREMENT = 'Vui lòng nhập yêu cầu'
const REQUIRED_BENEFIT = 'Vui lòng nhập phúc lợi'
const REQUIRED_EMAIL_CONTRACT = 'Vui lòng nhập email'
const REQUIRED_PHONE_CONTRACT = 'Vui lòng nhập số điện thoại'
const REQUIRED_NAME_CONTRACT = 'Vui lòng nhập tên'
const REQUIRED_EXPIRED_TIME = 'Vui lòng nhập hạn nộp hồ sơ'
const UNSAVED_CHANGES = 'Thay đổi chưa được lưu'
const UNSAVED_CHANGES_CONTENT = 'Bạn có chắc chắn muốn rời khỏi trang này'
const DISCARD_CHANGES = 'Hủy thay đổi'
const KEEP_EDITING = 'Tiếp tục chỉnh sửa'
const TITLE_LIST_RECRUITMENT = 'Danh sách tin tuyển dụng'
const TITLE_LIST_RECRUITMENT_REQUEST = 'Danh sách yêu cầu tuyển dụng'
const CODE = 'code'
const RESPONSIBLE_PERSON = 'Người phụ trách'
const DISTRIBUTOR = 'Nhà phân phối'
const REASON = 'Lý do'
const REQUEST_DATE = 'Ngày yêu cầu'
const PETITIONER = 'Người yêu cầu'
const TITLE_AVT = 'Hình'
const CANCEL_REQUEST = 'Hủy yêu cầu'
const CONTENT_CANCEL_REQUEST = 'Bạn có chắc bạn muốn hủy yêu cầu mục này không?'
const INFO_REQUEST = 'Thông tin yêu cầu'
const HIRING_REASON = 'Lý do tuyển'
const EMPLOYMENT_TYPE = 'Hình thức làm việc'
const LABEL_PROVINCE = 'Tỉnh thành'
const LABEL_DISTRICT = 'Quận huyện'
const EXPECTED_START_DATE = 'Ngày làm việc dự kiến'
const COUNT_RECRUITMENT_REQUEST = 'Số lượng'
const OTHER_REQUIREMENT = 'Yêu cầu khác'
const REFRESH = 'Làm mới dữ liệu'
const LECTURE_LIST = 'Danh sách bài giảng'
const ONBOARDING_NOTE =
    'Vui lòng chọn nhân viên cần huấn luyện và ấn nút <span style="color:#399E9F">"Bắt đầu huấn luyện"</span>. Bạn có thể chọn nhiều DCR nếu các bạn gia nhập cùng thời điểm.'
const START_TO_TRAIN = 'Bắt đầu huấn luyện'
const NO_DCR_TO_TRAIN = 'Không có DCR cần huấn luyện'
const NO_DCR_TO_TRAIN_DETAIL =
    'Hiện tại, bạn không có nhân viên nào cần được huấn luyện. Bạn có thể xem Danh sách bài giảng.'
const CONTINUE = 'Tiếp tục'
const REPORT = 'Báo cáo'
const LINKED_RECRUITMENT_REQUEST = 'Gắn YCTD'
const CANCEL_LINKED_RECRUITMENT_REQUEST = 'Huỷ gắn YCTD'
const SAVE_FILE = 'Lưu hồ sơ'
export {
    LABEL_DELETE,
    CONTENT_DELETE,
    CANCEL,
    EDIT,
    CREATE_ITEM,
    ARCHIVE,
    CONTENT_ARCHIVE,
    EXPORT_EXCEL,
    POSITION,
    PROVINCE_AREA,
    STATUS,
    SALARY,
    CREATED,
    EXPIRED_TIME,
    ACTIONS,
    SEARCH_BOX,
    SEARCH_BTN,
    RANGE_DATE_PLACEHOLDER,
    PROVINCE,
    JOB_ID,
    DISTRICT,
    AREA,
    PROVIDER,
    CREATOR,
    RECRUITMENT_STAFF,
    SEARCHBAR_EXPAND,
    LESS_EXPAND,
    TITLE,
    HOT_JOB,
    WORK_STATION,
    CHOOSE_FIVE_PLACES,
    MIN_SALARY,
    MAX_SALARY,
    NEGOTIATE,
    JOB_DESCRIPTION,
    MAX_CHAR_NUMBER,
    JOB_REQUIREMENT,
    BENEFIT,
    CONTACT_EMAIL,
    CONTACT_NAME,
    CONTACT_PHONE_NUMBER,
    SUBMIT_FORM_DEADLINE,
    POST_AVATAR,
    TITLE_RECRUITMENT,
    TITLE_RANGE_SALARY_PLACHEHOLDER,
    TITLE_ALL_PLACHEHOLDER,
    CURRENT_DAY,
    SAVE,
    REQUIRED_UPLOAD_IMAGE,
    REQUIRED_TITLE,
    REQUIRED_AREA_WORK,
    REQUIRED_CONTENT_WORK,
    REQUIRED_REQUIREMENT,
    REQUIRED_BENEFIT,
    REQUIRED_EMAIL_CONTRACT,
    REQUIRED_PHONE_CONTRACT,
    REQUIRED_NAME_CONTRACT,
    REQUIRED_EXPIRED_TIME,
    UNSAVED_CHANGES,
    UNSAVED_CHANGES_CONTENT,
    DISCARD_CHANGES,
    KEEP_EDITING,
    TITLE_LIST_RECRUITMENT,
    TITLE_LIST_RECRUITMENT_REQUEST,
    CODE,
    RESPONSIBLE_PERSON,
    DISTRIBUTOR,
    REASON,
    REQUEST_DATE,
    PETITIONER,
    TITLE_AVT,
    CANCEL_REQUEST,
    CONTENT_CANCEL_REQUEST,
    INFO_REQUEST,
    HIRING_REASON,
    EMPLOYMENT_TYPE,
    LABEL_PROVINCE,
    LABEL_DISTRICT,
    EXPECTED_START_DATE,
    COUNT_RECRUITMENT_REQUEST,
    OTHER_REQUIREMENT,
    REFRESH,
    LECTURE_LIST,
    ONBOARDING_NOTE,
    START_TO_TRAIN,
    NO_DCR_TO_TRAIN,
    NO_DCR_TO_TRAIN_DETAIL,
    CONTINUE,
    REPORT,
    LINKED_RECRUITMENT_REQUEST,
    SAVE_FILE,
    CANCEL_LINKED_RECRUITMENT_REQUEST,
}
