<template>
    <c-role :can-access-page="canAccessPage">
        <private-view>
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="true" />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title>
                <h1 class="type-title" v-html="titleHead"></h1>
            </template>
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <!-- content start -->
            <exclude-dcr-detail :item="objExemptionDetail" />
            <kpi-content-table :item="objExemptionDetail" />
            <!--  content end -->

            <!-- dialog confirm unsave end -->
            <template #sidebar close>
                <exclude-dcr-sidebar
                    v-if="objExemptionDetail"
                    :id-exemption="objExemptionDetail?.id"
                    :status="objExemptionDetail?.status"
                    @handle-submit="navigateBack"
                />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useStores } from '@directus/extensions-sdk'
import { computed, onMounted, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useExemptionCertificateQuery } from '../../../../../shared-fe/services/exemption-certificate-service'
import {
    EXEMPTION_CASE,
    EXEMPTION_CERTIFICATE_STATUS
} from '../../../../../shared-fe/utils/constant'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import ExcludeDcrDetail from '../components/exclude-dcr-detail.vue'
import excludeDcrSidebar from '../components/exclude-dcr-sidebar.vue'
import kpiContentTable from '../components/kpi-content-table.vue'

const canAccessPage = ref(true)
const { getItemExemptionCertificate } = useExemptionCertificateQuery()
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
const route = useRoute()
const router = useRouter()
const objExemptionDetail = ref(<any>{})
const titleHead = computed(() => {
    return PATH_ROUTER.EXCLUDE_DCR_DETAIL.VALUE
})
// get data detail (start)
onMounted(async () => {
    if (route?.params?.id) {
        await getDetail()
    }
})

const getDetail = async () => {
    await getItemExemptionCertificate(route?.params?.id.toString())
        .then((res) => {
            let item = res?.data?.data
            objExemptionDetail.value = {
                id: item.id,
                full_name: item.user.full_name,
                exemption_case:
                    exemptionCase(item.is_elearning, EXEMPTION_CASE.e_learning.value) +
                    (item.is_elearning && item.is_work_with ? ' & ' : '') +
                    exemptionCase(item.is_work_with, EXEMPTION_CASE.work_with.value),
                is_elearning: item.is_elearning,
                is_work_with: item.is_work_with,
                resign_before: item.resign_before,
                total_seniority: item.total_seniority,
                status: exemptionStatus(item?.status_one, item?.status_two),
                status_one: item.status_one,
                status_two: item.status_two,
                route_old: item.route_old,
                route_name: item?.user?.route_code?.name || '',
                reason: item.reason,
                hero_sku_one: item.hero_sku_one,
                hero_sku_two: item.hero_sku_two,
                hero_sku_three: item.hero_sku_three,
                ebd_one: item.ebd_one,
                ebd_two: item.ebd_two,
                ebd_three: item.ebd_three,
                aso_one: item.aso_one,
                aso_two: item.aso_two,
                aso_three: item.aso_three,
                display_one: item.display_one,
                display_two: item.display_two,
                display_three: item.display_three,
            }
        })
        .catch((e) => {
            canAccessPage.value = false
        })
}

const exemptionStatus = (statusL1, statusL2) => {
    if (statusL1 === null && statusL2 === null) {
        return EXEMPTION_CERTIFICATE_STATUS.waiting.text
    }
    if (statusL1 && statusL2 === null) {
        return EXEMPTION_CERTIFICATE_STATUS.l1_approved.text
    }
    if (statusL1 && statusL2) {
        return EXEMPTION_CERTIFICATE_STATUS.completed.text
    }
    if (!statusL1 || !statusL2) {
        return EXEMPTION_CERTIFICATE_STATUS.reject.text
    }
}

const exemptionCase = (exemption_case, type) => {
    if (type === EXEMPTION_CASE.e_learning.value && exemption_case) {
        return EXEMPTION_CASE.e_learning.text
    }
    if (type === EXEMPTION_CASE.work_with.value && exemption_case) {
        return EXEMPTION_CASE.work_with.text
    }
    return ''
}

const navigateBack = () => {
    router.push({
        name: PATH_ROUTER.LD_LEARNING_DETAIL.NAME,
    })
}
</script>

<style lang="scss" scoped></style>
