<template>
    <v-dialog v-model="isShow" @esc="closeDialogFunc" placement="center custom-width">
        <template #activator="{ on }">
            <div class="text-center group">
                <v-button
                    class="custom-btn"
                    :disabled="isDisabledStartExam"
                    secondary
                    @click="
                        () => {
                            emit('check')
                            on()
                        }
                    "
                    :loading="loading"
                >
                    Mở bài thi
                    <v-icon name="check"></v-icon>
                </v-button>

                <div class="group-hover:block hidden">
                    <ul
                        class="text-left bg-white py-4 absolute top-full w-md right-0 border border-solid border-gray-300"
                    >
                        <li :class="processTrainStore.startExam ? 'text-green-700' : 'text-red-600'">
                            {{ processTrainStore.startExam ? 'Hết thời gian chờ' : 'Chờ đếm ngược' }}
                        </li>
                        <!-- <li :class="!prevExamModule ? 'text-red-700' : 'text-green-700'">
                            {{ !prevExamModule ? 'Đã có mở thi module khác' : 'Chưa có Thi trong ngày' }}
                        </li> -->
                        <li :class="loading ? 'text-red-700' : 'text-green-700'">
                            {{ loading ? 'Đang loading' : 'Loading sẵn sàng' }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <v-card>
            <v-card-title>
                <div v-if="isDisabledStartExam">Đang kiểm tra. Vui lòng chờ ...</div>
                <div class="whitespace-pre-wrap" v-else-if="!prevExamModule">{{ title }}</div>
                <div v-else class="w-full h-full">
                    <div class="text-5 text-[#1E293B] font-bold mb-8">Tải hình huấn luyện và mở khóa thi</div>
                    <interface-file-image
                        collection="topics"
                        :disabled="false"
                        :from-library="false"
                        :from-url="false"
                        field="image"
                        v-model:value="imageId"
                        @input="
                            (data) => {
                                imageId = data
                            }
                        "
                        width="full"
                    />
                </div>
            </v-card-title>

            <v-card-actions>
                <v-button v-if="isDisabledStartExam || !prevExamModule" class="custom-btn" @click="closeDialogFunc">
                    Đóng
                </v-button>
                <v-button v-else :disabled="!imageId" class="custom-btn" @click="nextAction">
                    Lưu & Mở khoá thi
                </v-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useProcessTrain } from '../../../../../shared-fe/stores/process-train'
const props = defineProps({
    prevExamModule: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: '',
    },
})
const isShow = ref(false)
const imageId = ref(<any>null)
const emit = defineEmits(['nextAction', 'check'])
const processTrainStore = useProcessTrain()

const isDisabledStartExam = computed(() => {
    // Đang xử lý data ajax sẽ disable
    if (props.loading) return true

    // Nếu chưa hết giờ (startExam = true) thì disable (return true tại đây)
    // Khi đếm ngược xong processTrainStore.startExam sẽ bằng true
    if (!processTrainStore.startExam) return true
})
const nextAction = () => {
    isShow.value = false
    setTimeout(() => {
        emit('nextAction', imageId.value)
        imageId.value = ''
    }, 500)
}

const closeDialogFunc = () => {
    isShow.value = false
    imageId.value = ''
}
</script>
