<template>
    <c-role>
        <private-view title="Tạo mới WSR">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation name="3" />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.WSR_LIST.VALUE,
                            to: PATH_ROUTER.WSR_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <c-access :roles="[USER_ROLE.HR, USER_ROLE.AGENCY]">
                    <div class="box-save">
                        <v-button v-tooltip.bottom="SAVE" icon rounded @click="save">
                            <v-icon name="check" />
                        </v-button>
                    </div>
                </c-access>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->

            <!-- <form-wsr-work v-if="!activeTabFormDetail" :item="objWsrWork" /> -->
            <form-wsr-detail mode="add" />
            <!--  content end -->

            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin WSR" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { MODE_ADD, MODE_EDIT, SUBMIT_EVENT, USER_ROLE, USER_STATUS } from '../../../../../shared-fe/utils/constant'
import { CANCEL, CONTENT_DELETE, LABEL_DELETE, SAVE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'

import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import WsrHistory from '../components/wsr-history.vue'
import FormWsrDetail from '../components/form-wsr-detail.vue'
import FormWsrWork from '../components/form-wsr-work.vue'

const commonStore = useStoreCommon()
const route = useRoute()
const objWsrDetail = ref(null)
const activeTabFormDetail = ref(true)

// get data detail (end)

// handle logic header (start)
const router = useRouter()
const save = () => {
    commonStore.submitEvent({
        name: SUBMIT_EVENT.WSR_DETAIL_SUBMIT,
        payload: MODE_ADD,
    })
}

const navigateBack = () => {
    router.go(-1)
}

// handle logic header (end)
</script>
