<template>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9 bg-[#EFFAFA]">
        <div v-if="infoDcr.idCandidate !== -1" class="text-[#1E4BA1] mb-8" @click="goToCandidate">
            Xem thông tin ứng viên
            <v-icon name="open_in_new" small />
        </div>
        <div class="grid px-[1.4rem] py-[1.7rem] gap-8 p- grid-cols-1 lg:grid-cols-3">
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Chi nhánh</div>
                <v-select
                    :model-value="infoDcr.zone_name"
                    :items="arrZone"
                    showDeselect
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'zone_name')"
                ></v-select>
                <span class="message-error" v-if="errors?.zone_name">Vui lòng chọn chi nhánh</span>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">SS</div>
                <v-select
                    :model-value="infoDcr.ss_name"
                    :items="arrSS"
                    showDeselect
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'ss_name')"
                ></v-select>
                <span class="message-error" v-if="errors?.ss_name">Vui lòng chọn SS</span>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Mã route</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="infoDcr.route_code"
                    @update:model-value="(e) => handleChange(e, 'route_code')"
                ></v-input>
                <span class="message-error" v-if="errors?.route_code">Vui lòng nhập Mã route</span>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Mã nhân viên</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="infoDcr.code"
                    @update:model-value="(e) => handleChange(e, 'code')"
                ></v-input>
                <span class="message-error" v-if="errors?.code">Vui lòng nhập mã nhân viên</span>
                <span class="message-error" v-if="errors?.code_duplicate">{{ errors?.code_duplicate }}</span>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Ngày bắt đầu làm việc</div>
                <v-menu ref="dateTimeStartDate" :close-on-content-click="false" attached full-height seamless>
                    <template #activator="{ toggle, active }">
                        <v-input
                            :active="active"
                            :disabled="!fullAccess"
                            clickable
                            readonly
                            :model-value="infoDcr.start_date_display"
                            placeholder=""
                            @click="toggle"
                        >
                            <template #append>
                                <v-icon
                                    :name="infoDcr.start_date ? 'clear' : 'today'"
                                    :class="{
                                        active,
                                        'clear-icon': infoDcr.start_date_display,
                                        'today-icon': !infoDcr.start_date_display,
                                    }"
                                    v-on="{ click: infoDcr.start_date ? unsetValueStartDate : null }"
                                />
                            </template>
                        </v-input>
                    </template>

                    <c-date-picker
                        type="date"
                        :include-seconds="true"
                        :use-24="true"
                        :disabled="!fullAccess"
                        :model-value="infoDcr.start_date"
                        @update:model-value="(e) => handleChange(e, 'start_date')"
                        @close="dateTimeStartDate?.deactivate"
                    />
                </v-menu>
                <span class="message-error" v-if="errors?.start_date">Vui lòng chọn ngày bắt đầu làm việc</span>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Ngày làm việc cuối cùng</div>
                <v-menu ref="dateTimeLastWorkingDate" :close-on-content-click="false" attached full-height seamless>
                    <template #activator="{ toggle, active }">
                        <v-input
                            :active="active"
                            :disabled="!fullAccess"
                            clickable
                            readonly
                            :model-value="infoDcr.last_working_date_display"
                            placeholder=""
                            @click="toggle"
                        >
                            <template #append>
                                <v-icon
                                    :name="infoDcr.last_working_date ? 'clear' : 'today'"
                                    :class="{
                                        active,
                                        'clear-icon': infoDcr.last_working_date_display,
                                        'today-icon': !infoDcr.last_working_date_display,
                                    }"
                                    v-on="{ click: infoDcr.last_working_date ? unsetValueLastWorkingDate : null }"
                                />
                            </template>
                        </v-input>
                    </template>

                    <c-date-picker
                        type="date"
                        :include-seconds="true"
                        :use-24="true"
                        :disabled="!fullAccess"
                        :model-value="infoDcr.last_working_date"
                        @update:model-value="(e) => handleChange(e, 'last_working_date')"
                        @close="dateTimeLastWorkingDate?.deactivate"
                    />
                </v-menu>
            </div>
            <div class="col-span-1">
                <div class="text-12px text-[#64748B]">Trạng thái</div>
                <v-select
                    :model-value="infoDcr.employment_status_name"
                    :items="arrayUserStatus"
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'employment_status_name')"
                ></v-select>
                <span class="message-error" v-if="errors?.employment_status_name">Vui lòng chọn trạng thái</span>
            </div>
        </div>

        <div class="border-bottom my-8"></div>
        <div class="grid px-[1.4rem] py-[1.7rem] gap-8 p- grid-cols-1 lg:grid-cols-3">
            <div class="col-span-1">
                <c-select-m2o
                    collection="options"
                    v-model:model-value="infoDcr.interview"
                    field_text="opt_value"
                    field_value="id"
                    label="Lý do nghỉ việc (SS)"
                    :query-filter="{ filter: queryResignReason, sort: ['opt_value'] }"
                    @update:model-value="(e) => handleChange(e, 'interview')"
                ></c-select-m2o>
            </div>
            <div class="col-span-2">
                <div class="type-label">Chi tiết lý do nghỉ việc (SS)</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="infoDcr.interview_note"
                    @update:model-value="(e) => handleChange(e, 'interview_note')"
                ></v-input>
                <span class="message-error" v-if="errors?.interview_note">
                    Vui lòng nhập Chi tiết lý do nghỉ việc (SS)
                </span>
                <span class="message-error" v-if="errors?.interview">Vui lòng nhập Lý do nghỉ việc (SS) trước</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    collection="options"
                    v-model:model-value="infoDcr.reason"
                    field_text="opt_value"
                    field_value="id"
                    label="Lý do nghỉ việc (Agency)"
                    :query-filter="{ filter: queryResignReason, sort: ['opt_value'] }"
                    @update:model-value="(e) => handleChange(e, 'reason')"
                ></c-select-m2o>
            </div>
            <div class="col-span-2">
                <div class="type-label">Chi tiết lý do nghỉ việc (Agency)</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="infoDcr.reason_note"
                    @update:model-value="(e) => handleChange(e, 'reason_note')"
                ></v-input>
                <span class="message-error" v-if="errors?.reason_note">
                    Vui lòng nhập Chi tiết lý do nghỉ việc (Agency)
                </span>
                <span class="message-error" v-if="errors?.reason">Vui lòng nhập Lý do nghỉ việc (Agency) trước</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import usePermission from '@/shared-fe/composables/use-permission'
import { useAreaQuery } from '@/shared-fe/services/area-service'
import { useStoreCommon } from '@/shared-fe/stores/common'
import { sortDataSelection } from '@/shared-fe/utils/convert-data'
import { useUserStore } from '@/stores/user'
import CSelectM2o from '@/shared-fe/components/c-select-m2o/c-select-m2o.vue'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import {
    FORMAT_TIME,
    MODE_EDIT,
    RESIGN_HISTORY_REASON,
    SUBMIT_EVENT,
    USER_ROLE,
    USER_STATUS,
    USER_TYPE,
} from '../../../../../shared-fe/utils/constant'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { useUserQuery } from '@/shared-fe/services/user-service'
import { onBeforeUnmount } from 'vue'
import CDatePicker from '@/shared-fe/components/c-datepicker/c-datepicker.vue'
import { useResignQuery } from '@/shared-fe/services/resign-service'
import Joi from 'joi'

const goToCandidate = () => {
    router.push({
        name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
        params: {
            id: infoDcr.value.idCandidate,
        },
    })
}

const emit = defineEmits(['refetch'])
const { canAccess } = usePermission()
const commonStore = useStoreCommon()
const dateTimeStartDate = ref()
const dateTimeLastWorkingDate = ref()
const { patchItemUser, getListSS, getAllEmailUserWithLimit } = useUserQuery()
const { postItemResignWsr, patchItemResignWsr } = useResignQuery()
const fullAccess = computed(() => {
    if (userStore.currentUser.user_type === USER_TYPE.WSR) {
        if (
            userStore.currentUser?.role.short_name === USER_ROLE.ASM ||
            userStore.currentUser?.role.short_name === USER_ROLE.SS
        ) {
            return false
        }
    }
    return canAccess([USER_ROLE.HR, USER_ROLE.AGENCY])
})
const userStore = useUserStore()
const { getAllAreaAndZoneItem } = useAreaQuery()
const arrAreaAndZone = ref(<any>[])
const arrZoneDefault = ref(<any>[])
const arrSS = ref(<any>[])
const errors: any = ref(null)

const arrayUserStatus = computed(() => {
    const arr: any = []
    Object.keys(USER_STATUS).map((key) => {
        arr.push({
            text: USER_STATUS[key].text,
            value: USER_STATUS[key].value,
        })
    })

    return arr
})

const queryResignReason = computed(() => {
    let filter = {}
    let _fixedConditions: any = []
    let _hasId: any = []

    _fixedConditions = [
        {
            opt_group: 'resignation_reason',
            is_shown: true,
        },
    ]

    if (infoDcr.value.reason) {
        _hasId = [
            {
                id: {
                    _in: [infoDcr.value.reason],
                },
            },
        ]
    }

    filter = {
        _or: [..._fixedConditions, ..._hasId],
    }

    return filter
})

getListSS('', { user_type: USER_TYPE.WSR }).then((res) => {
    if (res?.data?.data && res?.data?.data.length > 0) {
        arrSS.value = res?.data?.data.map((x) => {
            return {
                text: x?.full_name,
                value: x?.id,
            }
        })
    }
})

getAllAreaAndZoneItem().then((res) => {
    if (res.data.data) {
        res.data.data?.forEach((x) => {
            if (!x?.zone) {
                arrZoneDefault.value.push({
                    text: x?.name,
                    value: x?.id,
                })
            }
            arrAreaAndZone.value.push(x)
        })
        arrZoneDefault.value = sortDataSelection(arrZoneDefault.value)
    }
})

const arrZone = computed(() => {
    let _zones: any = []
    if (userStore.currentUser?.role?.short_name === USER_ROLE.HR) {
        if (userStore.currentUser?.ares_management?.length > 0) {
            userStore.currentUser?.ares_management.forEach((x) => {
                if (_zones?.length > 0) {
                    if (!_zones.find((k) => k.value === x?.zone?.id)) {
                        _zones.push({
                            text: x?.zone?.name,
                            value: x?.zone?.id,
                        })
                    }
                } else {
                    _zones.push({
                        text: x?.zone?.name,
                        value: x?.zone?.id,
                    })
                }
            })
        }
        return sortDataSelection(_zones)
    } else {
        return arrZoneDefault.value
    }
})

const dataDefault = {
    id: null,
    note: null,
    zone_name: null,
    ss_name: null,
    route_code: null,
    code: null,
    code_before: null,
    start_date: null,
    last_working_date: null,
    employment_status_name: null,
    resigns: null,
    idCandidate: null,
    resignId: null,
    interview: null,
    interview_note: null,
    reason: null,
    reason_note: null,
}
const router = useRouter()
const infoDcr = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})

const schema = Joi.object({
    zone_name: Joi.number().required(),
    ss_name: Joi.string().required(),
    route_code: Joi.string().required(),
    code: Joi.string().required(),
    start_date: Joi.string().required(),
    employment_status_name: Joi.string().required(),
}).unknown(false)

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(infoDcr.value)) {
        infoDcr.value[key] = props.item[key]
    }
    if (infoDcr.value.start_date) {
        infoDcr.value.start_date = infoDcr.value?.start_date
            ? dayjs(infoDcr.value.start_date).format(FORMAT_TIME.YYYMMDD)
            : ''
        infoDcr.value.start_date_display = infoDcr.value?.start_date
            ? dayjs(infoDcr.value.start_date).format(FORMAT_TIME.DDMMYYYY)
            : ''
    }
    if (infoDcr.value.last_working_date) {
        infoDcr.value.last_working_date = infoDcr.value?.last_working_date
            ? dayjs(infoDcr.value.last_working_date).format(FORMAT_TIME.YYYMMDD)
            : ''
        infoDcr.value.last_working_date_display = infoDcr.value?.last_working_date
            ? dayjs(infoDcr.value.last_working_date).format(FORMAT_TIME.DDMMYYYY)
            : ''
    }
}

const handleChange = (e: any, variable: any) => {
    switch (variable) {
        case 'start_date':
            infoDcr.value.start_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            infoDcr.value[variable] = e || ''
            break
        case 'last_working_date':
            infoDcr.value.last_working_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            infoDcr.value[variable] = e || ''
            break
        default:
            infoDcr.value[variable] = e
            break
    }
}

const unsetValueStartDate = (e) => {
    infoDcr.value.start_date = ''
    infoDcr.value.start_date_display = ''
    unsetValue(e)
}

const unsetValueLastWorkingDate = (e) => {
    infoDcr.value.last_working_date = ''
    infoDcr.value.last_working_date_display = ''
    unsetValue(e)
}

const unsetValue = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
}

watch(
    () => props.item,
    () => {
        if (props.item) {
            initData()
        }
    },
    { immediate: true }
)
const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.WSR_DETAIL_SUBMIT)
    if (payloadSubmitForm) {
        errors.value = {}
        const {
            start_date_display,
            last_working_date_display,
            id,
            note,
            last_working_date,
            resigns,
            idCandidate,
            resignId,
            interview,
            interview_note,
            reason,
            reason_note,
            code_before,
            ...res
        } = infoDcr.value
        const { error } = schema.validate(res, { abortEarly: false, allowUnknown: true })

        if (error) {
            errors.value = error.details.reduce((acc, curr) => {
                acc[curr.path[0]] = curr.message
                return acc
            }, {})
        }

        if (reason && !reason_note) {
            errors.value = {
                ...errors.value,
                reason_note: 'error',
            }
        }

        if (!reason && reason_note) {
            errors.value = {
                ...errors.value,
                reason: 'error',
            }
        }

        if (interview && !interview_note) {
            errors.value = {
                ...errors.value,
                interview_note: 'error',
            }
        }

        if (!interview && interview_note) {
            errors.value = {
                ...errors.value,
                interview: 'error',
            }
        }
        console.log(infoDcr.value)

        if (infoDcr.value.code !== infoDcr.value.code_before) {
            const email = `${infoDcr.value.code}@spvb.vn`

            const filter = {
                email: {
                    _eq: email,
                },
            }
            const resEmail = await getAllEmailUserWithLimit(filter)
            if (resEmail.data.data.length > 0) {
                errors.value = {
                    ...errors.value,
                    code_duplicate: 'Thông tin mã DCR WSR đã tồn tại',
                }
                return
            }
        }

        console.log(errors.value)

        if (Object.keys(errors.value).length !== 0) {
            return
        }

        let params: any = {
            zone: infoDcr.value.zone_name,
            code: infoDcr.value.code,
            email: `${infoDcr.value.code}@spvb.vn`,
            route_code: { name: infoDcr.value.route_code },
            employment_status: infoDcr.value.employment_status_name,
        }

        let paramsWsr = {
            interview: infoDcr.value.interview,
            interview_note: infoDcr.value.interview_note,
            reason: infoDcr.value.reason,
            reason_note: infoDcr.value.reason_note,
            user: infoDcr.value?.id,
        }

        if (infoDcr.value.start_date) {
            params.start_date = infoDcr.value.start_date
        }
        if (infoDcr.value.last_working_date) {
            params.last_working_date = infoDcr.value.last_working_date
        }
        if (infoDcr.value.ss_name) {
            params.parent_id = infoDcr.value.ss_name
        }

        if (payloadSubmitForm.payload === MODE_EDIT) {
            try {
                await patchItemUser(infoDcr.value.id, params)
                infoDcr.value.code_before = infoDcr.value.code
                if (paramsWsr?.interview || paramsWsr?.reason) {
                    if (infoDcr.value.resignId) {
                        await patchItemResignWsr(infoDcr.value.resignId, paramsWsr)
                    } else {
                        await postItemResignWsr(paramsWsr)
                    }
                }
                emit('refetch')
            } catch (error) {
                commonStore.titleDialog = 'Cập nhật thất bại'
            }
            commonStore.showDialogSucessUpdate = true
        }
    }
}

const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})
</script>
