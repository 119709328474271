<template>
    <c-role>
        <private-view :title="PATH_ROUTER.TARGET_MANAGEMENT.VALUE">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <template #title>
                <div class="type-title leading-[28px] mb-4">
                    <div>{{ PATH_ROUTER.TARGET_MANAGEMENT.VALUE }}</div>
                </div>
            </template>
            <template #actions>
                <c-access :roles="[USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2]">
                    <v-button v-if="keyNumber === 1" @click="handleAddTarget" rounded icon>
                        <v-icon name="add"></v-icon>
                    </v-button>
                </c-access>
            </template>
            <!-- navigation end  -->

            <div class="w-full py-10">
                <div v-if="canL1AndL2" class="min-w-[700px] mb-10">
                    <div class="w-full flex flex-wrap justify-center">
                        <div v-for="(item, index) in LIST_TARGET_MANAGEMENT_TAB" >
                            <button
                                :key="index"
                                @click="openTab(item.key)"
                                :class="
                                    keyNumber === item.key
                                        ? `bg-[#1E4BA1] text-white tab-button-${item.key}`
                                        : `tab-button-${item.key} bg-white text-[#64748B]`
                                "
                                class="border h-full border-solid py-3 font-medium border-[#CBD5E1] px-4 w-full"
                            >
                                <v-icon class="mr-2" :name="item.icon"></v-icon>
                                <span>{{ item.title }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="w-full" v-if="keyNumber === 1">
                    <global-target-management :refresh="refreshFlg"></global-target-management>
                </div>
                <div class="w-full" v-if="keyNumber === 2">
                    <list-ss-target-management></list-ss-target-management>
                </div>
            </div>

            <template #sidebar>
                <sidebar-detail icon="info" title="Quản lý chỉ tiêu" close />
            </template>
        </private-view>
        <modal-target-detail
            :is-show="isShowDialogDetail"
            @close="isShowDialogDetail = false"
            :mode="modeAdd"
            type="add"
            @refresh="refreshFlg = !refreshFlg"
        ></modal-target-detail>
    </c-role>
</template>
<script setup lang="ts">
import { computed, ref, toRefs } from 'vue'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import { LIST_TARGET_MANAGEMENT_TAB, USER_ROLE } from '../../../../shared-fe/utils/constant'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useStores } from '@directus/composables'
import GlobalTargetManagement from './components/global-target-management.vue'
import ListSsTargetManagement from './components/list-ss-target-management.vue'
import cAccess from '@/shared-fe/components/c-access/c-access.vue'
import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'
import modalTargetDetail from './components/modal-target-detail.vue'

const isName = '2'
const keyNumber = ref(1)

const { useAppStore } = useStores()
const appStore = useAppStore()
const isShowDialogDetail = ref(false)
const { sidebarOpen } = toRefs(appStore)
const { canL1AndL2 } = useAppPermissions()
const refreshFlg = ref(false)
sidebarOpen.value = false

const openTab = (key) => {
    keyNumber.value = key
}

const modeAdd = computed(() => {
    return canL1AndL2.value ? 'global' : 'user'
})

const handleAddTarget = () => {
    isShowDialogDetail.value = true
}
</script>

<style>
.type-note {
    max-width: none !important;
    color: #3b82f6 !important;
}
</style>
