<template>
	<div class="v-card-text"><slot /></div>
</template>

<style lang="scss" scoped>
.v-card-text {
	padding: var(--v-card-padding);
	padding-top: 0;
	padding-bottom: 12px;
}
</style>
