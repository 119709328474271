<template>
    <c-role>
        <private-view title="Thông tin DCR">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.DCR_LIST.VALUE,
                            to: PATH_ROUTER.DCR_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <c-access :roles="[USER_ROLE.HR]">
                    <v-dialog v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-save">
                        <v-button v-tooltip.bottom="SAVE" icon rounded @click="save">
                            <v-icon name="check" />
                        </v-button>
                    </div>
                </c-access>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->
            <div class="flex justify-center mb-8">
                <div class="list-btn-tab-detail">
                    <v-button
                        class="first"
                        :class="activeTabFormDetail ? 'active' : ''"
                        secondary
                        @click="activeTabFormDetail = !activeTabFormDetail"
                    >
                        <div class="i-mdi-account mr-2"></div>
                        Thông tin cá nhân
                    </v-button>
                    <v-button
                        class="last"
                        secondary
                        :class="!activeTabFormDetail ? 'active' : ''"
                        @click="activeTabFormDetail = !activeTabFormDetail"
                    >
                        <div class="i-mdi-account-multiple mr-2"></div>
                        Thông tin công tác
                    </v-button>
                </div>
            </div>
            <form-dcr-work v-if="!activeTabFormDetail && objDcrWork" :item="objDcrWork"></form-dcr-work>
            <form-dcr-detail v-if="activeTabFormDetail && objDcrDetail" :item="objDcrDetail" />
            <dcr-history v-if="objDcrDetail" :id-user="objDcrDetail?.id" />

            <!--  content end -->

            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin DCR" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { MODE_EDIT, SUBMIT_EVENT, USER_ROLE, USER_STATUS } from '../../../../../shared-fe/utils/constant'
import { CANCEL, CONTENT_DELETE, LABEL_DELETE, SAVE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'

import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import DcrHistory from '../components/dcr-history.vue'
import FormDcrDetail from '../components/form-dcr-detail.vue'
import FormDcrWork from '../components/form-dcr-work.vue'

const commonStore = useStoreCommon()
const route = useRoute()
const objDcrDetail = ref(null)
const activeTabFormDetail = ref(true)
const objDcrWork = ref(<any>null)
const { getUserByUserId } = useUserQuery()
onBeforeUnmount(() => {})

// get data detail (start)
onMounted(async () => {
    if (route?.params?.id) {
        await getDetail()
    }
})

const getDetail = async () => {
    let res = await getUserByUserId(route?.params?.id.toString())
    let data = res?.data?.data
    objDcrDetail.value = {
        ...data,
        email: data?.personal_email,
    }

    objDcrWork.value = {
        id: data.id,
        note: data.note,
        area_name: data.area?.name,
        zone_name: data.zone?.name,
        system_providers_name: data.system_providers?.length > 0 ? data.system_providers[0]?.provider_id?.name : '',
        ss_name: data.parent_id ? displayParentName(data.parent_id?.role?.short_name, data.parent_id?.full_name) : '',
        route_code: data.route_code?.name,
        code: data.code,
        resigns: data?.resigns,
        start_date: data.start_date,
        last_working_date: data.last_working_date,
        idCandidate: data.candidates?.length > 0 ? data.candidates[0].id : -1,
        employment_status_name: data.employment_status ? USER_STATUS[data.employment_status].text : '',
        resgins_history: data?.resigns_history,
    }
}

// get data detail (end)

// handle logic header (start)
const router = useRouter()
const save = () => {
    commonStore.submitEvent({
        name: SUBMIT_EVENT.DCR_DETAIL_SUBMIT,
        payload: MODE_EDIT,
    })
}

const navigateBack = () => {
    router.go(-1)
}

const batchDeleteFun = async () => {
    await batchDelete('status', objDcrDetail.value.id)
    router.push({
        name: PATH_ROUTER.DCR_LIST.NAME,
    })
}

const { confirmDelete, deleting, batchDelete, error: deleteError } = useBatch(USER_COLLECTION)
// handle logic header (end)
</script>
