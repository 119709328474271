import { useApi } from '@directus/extensions-sdk'
import { RESIGN_COLLECTION, RESIGN_WSR_COLLECTION } from '../utils/table-name'
import { useStoreCommon } from '../stores/common'

export const useResignQuery = () => {
    const api = useApi()
    const commonStore = useStoreCommon()
    const changeStatusItemResign = async (params: any) => {
        return await api.patch(`/items/${RESIGN_COLLECTION}`, params)
    }
    const patchItemResign = async (id: number, params: any) => {
        try {
            return await api.patch(`/items/${RESIGN_COLLECTION}/${id}`, params)
        } catch (error) {
            commonStore.loading = false
        }
    }
    const postItemResign = async (params: any) => {
        try {
            return await api.post(`/items/${RESIGN_COLLECTION}`, params)
        } catch (error) {
            commonStore.loading = false
        }
    }
    const getItemResign = async (id: any) => {
        return await api.get(`/items/${RESIGN_COLLECTION}/${id}`, {
            params: {
                fields: [
                    'id',
                    'reason.id',
                    'interview.id',
                    'status',
                    'interview_note',
                    'staff_id.date_of_birth',
                    'staff_id.phone_number',
                    'staff_id.zone.name',
                    'staff_id.area.name',
                    'staff_id.system_providers.provider_id.name',
                    'staff_id.system_providers.provider_id.id',
                    'staff_id.parent_id.role.short_name',
                    'staff_id.parent_id.full_name',
                    'staff_id.code',
                    'staff_id.id',
                    'staff_id.full_name',
                    'staff_id.route_code.name',
                    'first_working_day',
                    'last_working_day',
                    'attachments',
                    'hr_id.full_name',
                    'note',
                    'date_created',
                ],
            },
        })
    }
    const patchItemResignWsr = async (id: number, params: any) => {
        return await api.patch(`/items/${RESIGN_WSR_COLLECTION}/${id}`, params)
    }
    const postItemResignWsr = async (params: any) => {
        return await api.post(`/items/${RESIGN_WSR_COLLECTION}`, params)
    }

    const getItemResignWsr = async (id: any) => {
        return await api.get(`/items/${RESIGN_WSR_COLLECTION}`, {
            params: {
                fields: [
                    '*',
                    'id',
                    'reason',
                    'interview',
                    'interview_note',
                    'reason_note',
                    'user'
                ],
                filter: {
                    user: { id }
                }
            },
        })
    }

    return {
        changeStatusItemResign,
        getItemResign,
        postItemResign,
        patchItemResign,
        patchItemResignWsr,
        postItemResignWsr,
        getItemResignWsr,
    }
}
