<template>
    <sidebar-detail icon="back_Hand" class="recruitment-request-sidebar" title="Thao tác nhanh" close>
        <div v-if="enableQuickActions">
            <v-skeleton-loader v-if="loading" class="c-loading" type="input-tall" />
            <div v-else>
                <div class="mb-6 border-b-1px border-b-solid border-[#D3DAE4]">
                    <div class="text-[#94A3B8] mb-2 text-xs">Trạng thái</div>
                    <div class="mb-6">
                        {{ status }}
                    </div>
                </div>

                <div class="mb-6 border-b-1px border-b-solid border-[#D3DAE4]">
                    <v-textarea
                        class="mb-6"
                        placeholder="Nhập nội dung..."
                        :model-value="content"
                        @update:model-value="(e) => (content = e)"
                    />
                    <span class="message-error" v-if="errors?.content">Vui lòng nhập nội dung</span>
                </div>
                <div v-if="isAdminLv1" class="mb-6 border-b-1px border-b-solid border-[#D3DAE4]">
                    <div class="text-[#1E293B] mb-2 text-xs">Các trưởng hợp miễn trừ</div>
                    <div class="p-4 border border-solid border-[#399E9F] rounded-md mb-2">
                        <v-checkbox
                            :label="EXEMPTION_CASE.e_learning.text"
                            :checked="isElearning"
                            @update:model-value="(e) => handleChange(e, EXEMPTION_CASE.e_learning.value)"
                        />
                    </div>
                    <div class="mb-6 p-4 border border-solid border-[#399E9F] rounded-md">
                        <v-checkbox
                            :label="EXEMPTION_CASE.work_with.text"
                            :checked="isWorkWith"
                            @update:model-value="(e) => handleChange(e, EXEMPTION_CASE.work_with.value)"
                        />
                    </div>

                    <span class="message-error" v-if="errors?.status">Vui lòng chọn trường hợp miễn trừ</span>
                </div>
                <div class="my-3 text-[#D97706] text-right">{{ messageStatus }}</div>
                <div class="list-btn flex gap-3 w-full">
                    <v-button class="w-1/2 btn-fail" @click="() => submit(false)">
                        <div class="">Từ chối</div>
                    </v-button>

                    <v-button class="w-1/2 btn-pass" @click="() => submit(true)">
                        {{ isAdminLv2 ? 'Duyệt' : isAdminLv1 ? 'Chấp nhận' : '' }}
                    </v-button>
                </div>
            </div>
        </div>
    </sidebar-detail>
</template>
<script setup lang="ts">
import { useGroupable } from '@directus/composables'
import { Ref, computed, ref } from 'vue'
import { useExemptionCertificateQuery } from '../../../../../shared-fe/services/exemption-certificate-service'
import { EXEMPTION_CASE, EXEMPTION_CERTIFICATE_STATUS, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { useUserStore } from '@/stores/user';

const emit = defineEmits(['handleSubmit'])
const props = defineProps({
    idExemption: {
        type: Number,
        default: null,
    },
    status: {
        type: String,
        default: null,
    },
})
const content = ref('')
const isElearning: Ref<any> = ref(false)
const isWorkWith: Ref<any> = ref(false)
const errors = ref(<any>{})
const userStore = useUserStore()
const messageStatus = ref('')
const { active, toggle } = useGroupable({
    value: 'Thao tác nhanh',
    group: 'sidebar-detail',
    active: ref(false),
})
const enableQuickActions = computed(() => {
    if (
        props.status === EXEMPTION_CERTIFICATE_STATUS.l1_approved.text &&
        userStore.currentUser.role.short_name === USER_ROLE.ADMIN_L1
    ) {
        return false
    } else if (
        props.status === EXEMPTION_CERTIFICATE_STATUS.waiting.text &&
        userStore.currentUser.role.short_name === USER_ROLE.ADMIN_L2
    ) {
        return false
    }
    return (
        props.status !== EXEMPTION_CERTIFICATE_STATUS.completed.text &&
        props.status !== EXEMPTION_CERTIFICATE_STATUS.reject.text
    )
})

const isAdminLv1 = computed(() => {
    return userStore.currentUser.role.short_name === USER_ROLE.ADMIN_L1
})

const isAdminLv2 = computed(() => {
    return userStore.currentUser.role.short_name === USER_ROLE.ADMIN_L2
})

const loading = ref(false)
const { patchItemExemptionCertificate } = useExemptionCertificateQuery()
const submit = async (status) => {
    errors.value = null
    if (!content.value) {
        errors.value = {
            content: 'content is required',
        }
    }
    if (status && isAdminLv1.value && !isElearning.value && !isWorkWith.value) {
        errors.value = {
            ...errors.value,
            status: 'status is required',
        }
    }

    if (errors.value) {
        return
    }
    loading.value = true
    let params = {}
    if (isAdminLv1.value) {
        params = {
            status_one: status,
            note_one: content.value,
            admin_one: userStore.currentUser.id,
            is_elearning: isElearning.value,
            is_work_with: isWorkWith.value,
            date_apply_one: new Date(),
        }
    } else if (isAdminLv2.value) {
        params = {
            status_two: status,
            note_two: content.value,
            admin_two: userStore.currentUser.id,
            date_apply_two: new Date(),
        }
    }
    await patchItemExemptionCertificate(props.idExemption, {
        ...params,
    })
    setTimeout(() => {
        loading.value = false
        messageStatus.value = 'Đã cập nhật trạng thái'
        content.value = ''
        errors.value = null
        setTimeout(() => {
            messageStatus.value = ''
            emit('handleSubmit')
        }, 1500)
    }, 2000)
}

const handleChange = (e, exemption) => {
    switch (exemption) {
        case EXEMPTION_CASE.e_learning.value:
            isElearning.value = isElearning.value === null ? true : !isElearning.value
            break
        case EXEMPTION_CASE.work_with.value:
            isWorkWith.value = isWorkWith.value === null ? true : !isWorkWith.value
            break
        default:
            break
    }
}
</script>
<style lang="scss">
.recruitment-request-sidebar {
    .list-btn {
        .btn-fail {
            .button {
                background-color: #fee2e2;
                color: #ef4444;
                border: 1px solid #f87171;
                width: 100%;
                min-width: auto;
            }
        }
        .btn-pass {
            .button {
                width: 100%;
                background-color: #10b981;
                color: white;
                min-width: auto;
            }
        }
    }
}
</style>
