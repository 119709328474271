<template>
    <div class="mb-9">
        <c-expand-search
            :dataBasic="BASIC_SEARCH"
            :dataExpand="EXPAND_SEARCH"
            @update:model-value="handleSearch"
            @changeItem="changeItem"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import CExpandSearch from '../../../../../shared-fe/components/c-expand-search/c-expand-search.vue'
import { ComponentPropsModel } from '../../../../../shared-fe/types/table-type'
import { PROVINCE, RANGE_DATE_PLACEHOLDER } from '../../../../../shared-fe/utils/message'
import { useProvinceQuery } from '../../../../../shared-fe/services/province-service'
import { useOptionQuery } from '../../../../../shared-fe/services/option-service'
import { sortDataSelection } from '../../../../../shared-fe/utils/convert-data'
import { useUserStore } from '@/stores/user'
const props = defineProps({
    arrayStatus: {
        type: Array<Object>,
        default: [],
    },
    arrayValue: {
        type: Object,
        default: {},
    },
})
const emit = defineEmits(['update:handleSearch'])
const { getAllProvinceItem } = useProvinceQuery()
const userStore: any = useUserStore()
const arrProvince = ref(<any>[])
const arrCandidateStatus = ref(<any>[])
const arrProvinceAnDistricts = ref(<any>[])
const dataModel = ref(<any>props.arrayValue)

getAllProvinceItem().then((res) => {
    let _arrProvince: any = []
    let _arrProvinceAnDistricts: any = []
    if (res.data.data) {
        res.data.data.forEach((x) => {
            if (!x.parent_id) {
                _arrProvince.push({
                    text: x.name,
                    value: x.id,
                })
            }
            _arrProvinceAnDistricts.push(x)
        })
    }
    arrProvince.value = sortDataSelection(_arrProvince)
    arrProvinceAnDistricts.value = _arrProvinceAnDistricts
})

onMounted(async () => {
    const options = userStore.currentUser.options
    let _arrStatus: any = []
    if (options.length > 0) {
        options.forEach((x) => {
            if (x.opt_group === 'candidate_status') {
                _arrStatus.push({
                    opt_key: x.opt_key,
                    id: x.id,
                })
            }
        })
    }
    arrCandidateStatus.value = sortDataSelection(_arrStatus)
})

watch(
    () => props.arrayValue,
    () => {
        dataModel.value = props.arrayValue || {}
    }
)
const BASIC_SEARCH: ComponentPropsModel[] = [
    {
        key: 'title',
        value: dataModel.value.title,
        label: '',
        placeholder: 'Nhập Tên/ Điện thoại/ Email ...',
        type: 'v-input',
        icon: 'search',
    },
    {
        key: 'date_created',
        value: dataModel.value.date_created,
        label: '',
        placeholder: RANGE_DATE_PLACEHOLDER,
        type: 'c-range-datetime',
        data: dataModel.value.date_created || null,
    },
]

const EXPAND_SEARCH: any = computed(() => {
    return [
        {
            key: 'province_id',
            value: dataModel.value.province_id,
            label: PROVINCE,
            placeholder: PROVINCE,
            type: 'v-select',
            icon: 'search',
            data: arrProvince.value,
        },
        {
            key: 'user_created',
            value: dataModel.value.user_created,
            label: 'Người tạo',
            placeholder: 'Người tạo',
            type: 'v-input',
            icon: '',
        },
        {
            key: 'refer_name',
            value: dataModel.value.refer_name,
            label: 'Người giới thiệu',
            placeholder: 'Nhập mã DCR/tên DCR',
            type: 'v-input',
            icon: '',
        },
        {
            key: 'status',
            value: dataModel.value.status,
            label: 'Trạng thái',
            placeholder: 'Trạng thái',
            type: 'v-select',
            data: arrCandidateStatus.value,
        },
    ]
})
const changeItem = (data) => {
    dataModel.value[data.key] = data.value
}

const handleSearch = (data) => {
    emit('update:handleSearch', data)
}
</script>

<style lang="scss" scoped></style>
