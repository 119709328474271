<template>
    <div class="px-4 py-4 border border-bottom border-gray-200">
        <div class="grid grid-cols-2 gap-3 text-center">
            <template v-if="accessModuleHR">
                <router-link
                    :to="appPermission.canL1AndL2only.value ? '/spvn/dcrs' : '/spvn/recruitment-request'"
                    class="py-4 px-1 rounded-lg itemApp border-1 border-solid border-gray-200"
                    :class="module === 'hr' ? 'active' : ''"
                >
                    <v-icon name="person_search"></v-icon>
                    <span class="block text-sm mt-1">Tuyển Dụng</span>
                </router-link>
            </template>
            <template v-if="accessModuleLD">
                <router-link
                    to="/ld"
                    class="py-4 px-2 rounded-lg itemApp border-1 border-solid border-gray-200"
                    :class="module === 'ld' ? 'active' : ''"
                >
                    <v-icon name="school"></v-icon>
                    <span class="block mt-1 text-sm">Đào Tạo</span>
                </router-link>
            </template>
            <template v-if="accessModuleAgency">
                <router-link
                    to="/spvn/wsrs"
                    class="py-4 px-2 rounded-lg itemApp border-1 border-solid border-gray-200"
                    :class="module === 'agency' ? 'active' : ''"
                >
                    <v-icon name="school"></v-icon>
                    <span class="block mt-1 text-sm">Tuyển Dụng WSR</span>
                </router-link>
            </template>
        </div>

        <div v-if="appPermission.canContentManagement.value === true" class="mt-3 flex flex-col gap-3">
            <router-link
                to="/content"
                :class="module === 'content' ? 'active' : ''"
                class="p-2 block itemApp rounded-lg"
                hover="text-primary font-bold"
            >
                <v-icon name="article"></v-icon>
                Quản lý nội dung
            </router-link>

            <router-link
                to="/files"
                :class="module === 'files' ? 'active' : ''"
                class="p-2 block itemApp rounded-lg"
                hover="text-primary font-bold"
            >
                <v-icon name="folder_open"></v-icon>
                Quản lý File
            </router-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
const { t } = useI18n()

import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'
import { computed } from 'vue';
import { USER_ROLE, USER_TYPE } from '@/shared-fe/utils/constant';
const appPermission = useAppPermissions()
const userStore = useUserStore()

const accessModuleAgency = computed(() => {
    if(userStore.currentUser?.user_type !== USER_TYPE.WSR){
        if(userStore.currentUser?.role.short_name === USER_ROLE.ASM || 
            userStore.currentUser?.role.short_name === USER_ROLE.SS){
                return false
        }
    }
    return true;
})

const accessModuleHR = computed(() => {
    if(userStore.currentUser?.user_type !== USER_TYPE.WSR){
        return true
    }
    return false;
})

const accessModuleLD = computed(() => {
    if(userStore.currentUser?.role.short_name !== USER_ROLE.AGENCY){
        return true
    }
    return false;
})



const props = defineProps({
    name: { type: String, default: () => null, required: false },
    module: {},
})
</script>
<style lang="scss" scoped>
.itemApp {
    background-color: var(--v-list-item-background-color-active);
    transition: all 0.25s;
}

.itemApp .v-icon {
    --v-icon-color: black;
}

.itemApp.active {
    color: var(--primary-alt);
    background-color: var(--primary);
    font-weight: bold;
    border-color: var(--primary);
}

.itemApp.active .v-icon {
    --v-icon-color: var(--primary-alt);
}
</style>
