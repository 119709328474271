<template>
    <c-access :roles="[USER_ROLE.SS]">
        <sidebar-detail icon="pending" class="interview-steps" title="Kết quả Route Ride">
            <div class="message-error" v-if="displayRouteRideMessage">Vui lòng hoàn thành bài học Route Ride</div>
            <template v-else>
                <v-skeleton-loader v-if="loadingInterview" class="c-loading" type="input-tall" />
                <div v-else>
                    <div class="mb-2 text-[#1E293B]">Chọn ngày Route Ride</div>
                    <div class="mb-6">
                        <v-input
                            clickable
                            disabled
                            readonly
                            placeholder=""
                            :model-value="dayjs(item.date_route_ride).format(FORMAT_TIME.DDMMYYYY)"
                        />
                    </div>
                    <div class="mt-6 pt-6 border-t-1px border-t-solid border-[#D3DAE4]">
                        <div class="mb-2 text-[#1E293B]">
                            Đánh giá
                            <span class="required">*</span>
                        </div>
                        <v-textarea
                            placeholder="Nhập nội dung"
                            :model-value="content"
                            @update:model-value="(e) => (content = e)"
                        />
                        <span class="message-error" v-if="errContent != null">{{ errContent }}</span>
                    </div>

                    <div class="list-btn flex gap-3 w-full mt-6 pt-6 border-t-1px border-t-solid border-[#D3DAE4]">
                        <v-button
                            class="w-1/2 btn-fail"
                            @click="
                                () =>
                                    handleConfirm(
                                        CONFIRM_MODAL_TEXT.ROUTE_RIDE.REJECT,
                                        async () => await submit(CANDIDATE_STATUS.not_passed_rr.value, false)
                                    )
                            "
                        >
                            <div class="">Không đạt</div>
                        </v-button>
                        <v-button
                            class="w-1/2 btn-pass"
                            @click="
                                () =>
                                    handleConfirm(
                                        CONFIRM_MODAL_TEXT.ROUTE_RIDE.OK,
                                        async () => await submit(CANDIDATE_STATUS.passed_rr.value, true)
                                    )
                            "
                        >
                            <div class="">Đạt</div>
                        </v-button>
                    </div>
                    <div class="mt-3 text-[#D97706]">{{ messageInterview }}</div>
                </div>
            </template>
        </sidebar-detail>
    </c-access>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import CAccess from '../../../../../../shared-fe/components/c-access/c-access.vue'
import { useInterviewDetailQuery } from '../../../../../../shared-fe/services/interview-detail-service'
import { useOptionQuery } from '../../../../../../shared-fe/services/option-service'
import {
    CANDIDATE_STATUS,
    CONFIRM_MODAL_TEXT,
    FORMAT_TIME,
    USER_ROLE,
} from '../../../../../../shared-fe/utils/constant'
import dayjs from 'dayjs'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import { computed } from 'vue'
import { useUserStore } from '@/stores/user'
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})
const content = ref('')
const loadingInterview = ref(false)
const messageInterview = ref('')
const arrStatus = ref([])
const userStore: any = useUserStore()
const errContent = ref('')
const { postItemInterviewDetail, sendService } = useInterviewDetailQuery()
const confirmModal = useConfirmModal()
const emit = defineEmits(['handleSubmit'])

const handleConfirm = (title: string, action: () => void) => {
    confirmModal.handleOpenConfirmModal(title || '', action)
}

onMounted(async () => {
    const options = userStore.currentUser.options
    let _arrStatus: any = []
    if (options.length > 0) {
        options.forEach((x) => {
            if (x.opt_group === 'candidate_status') {
                _arrStatus.push({
                    opt_key: x.opt_key,
                    id: x.id,
                })
            }
        })
    }
    arrStatus.value = _arrStatus
})

const displayRouteRideMessage = computed(() => {
    return props.item.ride_step !== 'completed'
})

const submit = async (val, _status) => {
    errContent.value = ''
    if (!content.value && _status === false) {
        errContent.value = 'Vui lòng nhập nội dung'
        return
    }

    loadingInterview.value = true
    let findIdStatus = arrStatus.value?.find((x: any) => x.opt_key === val)
    let params: any = {
        status: _status,
        candidate_status_id: findIdStatus?.id,
        content: content.value,
        candidate_status: 'route_ride_result',
        id: props.item.candidate,
        interview_round: props.item?.id,
    }

    await postItemInterviewDetail({
        ...params,
    })
    setTimeout(() => {
        messageInterview.value = 'Đã hoàn thành đánh giá'
        loadingInterview.value = false
        content.value = ''
        setTimeout(() => {
            emit('handleSubmit')
        }, 2000)
    }, 2000)
}
</script>
