<template>
    <div class='relative'>
        <div v-if='displayImage.id' class='image-preview mb-2' :class="{ 'is-svg': displayImage.type && displayImage.type.includes('svg') }">
            <template v-if='!src && displayImage.title'>
                <div class='info flex gap-2 items-center'>
                    <v-icon-file :ext='ext' />
                    <div>
                        <div class='title font-bold'>{{ displayImage.title }}</div>
                        <div class='meta'>{{ meta }}</div>
                    </div>
                </div>
            </template>

            <div v-else-if='imageError || !src' class='image-error'>
                <div class='px-4 mb-2 py-2 border border-solid bg-yellow-50 border-yellow-500 rounded'>
                    <v-icon large :name="imageError === 'UNKNOWN' ? 'error' : 'info'" />
                    <span class='message'>
					{{ src ? 'Tập tin không tồn tại' : t('errors.UNSUPPORTED_MEDIA_TYPE') }}
				</span>
                </div>
            </div>

            <v-image
                v-else-if="displayImage.type.startsWith('image')"
                :src='src'
                :width='displayImage.width'
                :height='displayImage.height'
                alt=''
                role='presentation'
                @error='imageErrorHandler'
                class='w-full absolute h-full object-contain'
            />
        </div>

        <v-upload
            from-library
            @input='updateData'
        ></v-upload>
    </div>
</template>

<script setup lang='ts'>
import { computed, ref, withDefaults, defineProps } from 'vue'
import { useApi } from '@directus/composables'
import { addTokenToURL } from '@/api'
import { useI18n } from 'vue-i18n'

const { t, n, te } = useI18n()
import { formatFilesize } from '@/utils/format-filesize'
import { readableMimeType } from '@/utils/readable-mime-type'

const props = withDefaults(
    defineProps<{
        value: string | number | null;
        type?: string;
        clear?: boolean;
        disabled?: boolean;
        placeholder?: string;
        masked?: boolean;
        iconLeft?: string;
        iconRight?: string;
        trim?: boolean;
        font?: 'sans-serif' | 'serif' | 'monospace';
        length?: number;
        softLength?: number;
        dbSafe?: boolean;
        autofocus?: boolean;
        slug?: boolean;
        min?: number;
        max?: number;
        step?: number;
        direction?: string;
    }>(),
    {
        font: 'sans-serif',
        step: 1
    }
)
const emit = defineEmits(['input'])

const api = useApi()
const displayImage = ref({})
const imageError = ref<string | null>(null)

const fetchImage = async () => {
    displayImage.value = {}
    api.get(`/files/${props.value}`, {
        fields: ['id', 'title', 'width', 'height', 'filesize', 'type', 'filename_download', 'modified_on']
    })
        .then(img => {
            displayImage.value = img.data.data
        })
}
fetchImage()

const updateData = (value) => {
    emit('input', value.id)
    imageError.value = null
    displayImage.value = value
}

const src = computed(() => {
    if (!displayImage.value.id) return null
    if (displayImage.value.type.includes('svg')) {
        return '/assets/' + displayImage.value.id
    }

    if (displayImage.value.type.includes('image')) {
        const fit = props.crop ? 'cover' : 'contain'
        const url = `/assets/${displayImage.value.id}?key=system-large-${fit}&cache-buster=${displayImage.value.modified_on}`
        return addTokenToURL(url) ?? ''
    }
})


async function imageErrorHandler() {
    if (!src.value) return

    try {
        await api.get(src.value)
    } catch (err: any) {
        imageError.value = err.response?.data?.errors[0]?.extensions?.code

        if (!imageError.value || !te('errors.' + imageError.value)) {
            imageError.value = 'UNKNOWN'
        }
    }
}

const ext = computed(() => (displayImage.value ? readableMimeType(displayImage.value.type, true) : 'unknown'))
const meta = computed(() => {
    if (!displayImage.value) return null
    const { filesize, width, height, type } = displayImage.value

    if (width && height) {
        return `${n(width)}x${n(height)} • ${formatFilesize(filesize)} • ${type}`
    }

    return `${formatFilesize(filesize)} • ${type}`
})
</script>
