<template>
    <div class="mb-9">
        <c-expand-search
            :dataBasic="BASIC_SEARCH"
            :dataExpand="EXPAND_SEARCH"
            @changeItem="changeItem"
            @update:model-value="handleSearch"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import CExpandSearch from '../../../../../shared-fe/components/c-expand-search/c-expand-search.vue'
import { ComponentPropsModel } from '../../../../../shared-fe/types/table-type'
import { PROVINCE, RANGE_DATE_PLACEHOLDER } from '../../../../../shared-fe/utils/message'
import { useProviderQuery } from '../../../../../shared-fe/services/provider-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { RECRUITMENT_REQUEST_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { useField } from '../../../../../shared-fe/composables/use-field'
import { sortData, sortDataSelection } from '../../../../../shared-fe/utils/convert-data'
import { USER_ROLE } from '@/shared-fe/utils/constant'
import { useUserStore } from '@/stores/user'
import { useAreaQuery } from '@/shared-fe/services/area-service'
const emit = defineEmits(['update:handleSearch'])
const props = defineProps({
    arrayValue: {
        type: Object,
        default: {},
    },
})
const commonStore = useStoreCommon()
const arrProviderFullData = ref(<any>[])
const arrAreaAndZone = ref(<any>[])
const arrZoneDefault = ref(<any>[])
const { getAllAreaAndZoneItem } = useAreaQuery()
const { getAllProviderItem } = useProviderQuery()
const dataModel = ref(<any>props.arrayValue)
const { getArrayStatus } = useField()
const userStore = useUserStore()

getAllAreaAndZoneItem().then((res) => {
    if (res.data.data) {
        res.data.data?.forEach((x) => {
            if (!x?.zone) {
                arrZoneDefault.value.push({
                    text: x?.name,
                    value: x?.id,
                })
            }
            arrAreaAndZone.value.push(x)
        })
        arrZoneDefault.value = sortDataSelection(arrZoneDefault.value)
    }
})

const arrZone = computed(() => {
    let _zones: any = []
    if (userStore.currentUser?.role?.short_name !== 'Admin') {
        if (userStore.currentUser?.ares_management?.length > 0) {
            userStore.currentUser?.ares_management.forEach((x) => {
                if (_zones?.length > 0) {
                    if (!_zones.find((k) => k.value === x?.zone?.id)) {
                        _zones.push({
                            text: x?.zone?.name,
                            value: x?.zone?.id,
                        })
                    }
                } else {
                    _zones.push({
                        text: x?.zone?.name,
                        value: x?.zone?.id,
                    })
                }
            })
        }
        return sortDataSelection(_zones)
    } else {
        return arrZoneDefault.value
    }
})

const arrProvider = computed(() => {
    if (!dataModel.value?.zone && !dataModel.value?.area) {
        return sortDataSelection(
            arrProviderFullData.value.map((x) => {
                return {
                    text: x?.name,
                    value: x?.id,
                }
            })
        )
    }

    if (dataModel.value?.zone) {
        let _arrProvider = arrProviderFullData.value.filter((x) => x?.zone === dataModel.value?.zone)

        if (dataModel.value?.area) {
            _arrProvider = _arrProvider.filter((x) => x.area === dataModel.value?.area)
        }

        return sortDataSelection(
            _arrProvider.map((x) => {
                return {
                    text: x?.name,
                    value: x?.id,
                }
            })
        )
    }

    return []
})

const arrArea = computed(() => {
    let _arrArea: any = []
    if (userStore.currentUser?.role?.short_name !== 'Admin') {
        if (dataModel.value.zone) {
            userStore.currentUser?.ares_management?.forEach((x) => {
                if (x?.zone?.id === dataModel.value.zone) {
                    _arrArea.push({
                        text: x?.name,
                        value: x?.id,
                    })
                }
            })
        }
        return sortDataSelection(_arrArea)
    } else {
        if (dataModel.value.zone) {
            arrAreaAndZone.value.forEach((x) => {
                if (x?.zone === dataModel.value.zone) {
                    _arrArea.push({
                        text: x?.name,
                        value: x?.id,
                    })
                }
            })
        }
        return sortDataSelection(_arrArea)
    }
})

const arrProvince = computed(() => {
    let res: any = []
    res = commonStore.provinceList.map((x: any) => {
        return { text: x.name, value: x.id }
    })
    return res
})

watch(
    () => dataModel.value.zone,
    () => {
        dataModel.value.area = ''
        dataModel.value.provider = ''
    }
)

watch(
    () => dataModel.value.area,
    () => {
        dataModel.value.provider = ''
    }
)

watch(
    () => props.arrayValue,
    () => {
        dataModel.value = props.arrayValue || {}
    }
)

getAllProviderItem().then((res) => {
    if (res.data.data) {
        res.data.data?.forEach((x) => {
            arrProviderFullData.value.push(x)
        })
    }
})
const arrayStatus = computed(() => {
    return getArrayStatus(RECRUITMENT_REQUEST_COLLECTION)
})

const BASIC_SEARCH: ComponentPropsModel[] = [
    {
        key: 'title',
        value: props.arrayValue?.title,
        label: '',
        placeholder: 'Nhập mã yêu cầu tuyển dụng',
        type: 'v-input',
        icon: 'search',
    },
    {
        key: 'date_created',
        value: '',
        label: '',
        placeholder: RANGE_DATE_PLACEHOLDER,
        type: 'c-range-datetime',
        data: props.arrayValue?.date_created || null,
    },
]

const EXPAND_SEARCH: any = computed(() => {
    return [
        {
            key: 'zone',
            value: dataModel.value?.zone,
            label: 'Chi nhánh',
            placeholder: 'Chi nhánh',
            type: 'v-select',
            icon: 'search',
            data: arrZone.value,
            role: [USER_ROLE.HR],
        },
        {
            key: 'area',
            value: dataModel.value?.area,
            label: 'Khu vực',
            placeholder: 'Khu vực',
            type: 'v-select',
            icon: 'search',
            data: arrArea.value,
            role: [USER_ROLE.HR],
        },
        {
            key: 'provider',
            value: dataModel.value?.provider,
            label: 'Nhà phân phối',
            placeholder: 'Nhà phân phối',
            type: 'v-select',
            icon: 'search',
            data: arrProvider.value,
        },
        {
            key: 'province_id',
            value: dataModel.value?.province_id,
            label: PROVINCE,
            placeholder: PROVINCE,
            type: 'v-select',
            icon: 'search',
            data: arrProvince.value,
        },
        {
            key: 'user_created',
            value: dataModel.value?.user_created,
            label: 'Người tạo',
            placeholder: 'Người tạo',
            type: 'v-input',
        },
        {
            key: 'hr_name',
            value: dataModel.value?.hr_name,
            label: 'Người phụ trách',
            placeholder: 'Người phụ trách',
            type: 'v-input',
        },
        {
            key: 'status',
            value: dataModel.value?.status,
            label: 'Trạng thái',
            placeholder: 'Trạng thái',
            type: 'v-select',
            data: arrayStatus.value,
        },
    ]
})

const changeItem = (data) => {
    dataModel.value[data.key] = data.value
}

const handleSearch = (data) => {
    emit('update:handleSearch', data)
}
</script>

<style lang="scss" scoped></style>
