<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            v-model:modelValue="modelValue"
            :disabled="disabled"
            manualSortKey="tel"
            :sort="internalSort"
            :loading="loading"
            @update:sort="updateSort($event)"
            showSelect="multiple"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->

            <template #[`item.title`]="{ item }">
                <span class="custom-text" @click="() => detailRowOnClick(item)">
                    {{ item.title }}
                </span>
            </template>
            <template #[`item.provinces`]="{ item }">
                <c-expand-list
                    :maxItemDisplay="3"
                    :data="item.provinces.map((x) => x.province_id.name)"
                ></c-expand-list>
            </template>
            <template #[`item.status`]="{ item }">
                <c-status :collectionName="RECRUITMENT_COLLECTION" :id="item.status"></c-status>
            </template>
            <template #[`item.salary`]="{ item }">
                {{ convertMoney(item) }}
            </template>
            <template #[`item.date_created`]="{ item }">
                {{ dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) }}
            </template>
            <template #[`item.expired`]="{ item }">
                {{ dayjs(item.expired).format(FORMAT_TIME.DDMMYYYY) }}
            </template>
            <template #[`item.image`]="{ item }">
                <img
                    class="rounded-1"
                    :src="
                        idToUrl(item.image, {
                            width: 48,
                            height: 48,
                        })
                    "
                />
            </template>
            <template #[`item.action`]="{ item }">
                <div class="flex gap-2 actions">
                    <div
                        v-for="action in actions"
                        class="action w-9 h-9 text-center rounded-full block flex justify-center items-center"
                        :style="{ background: action.bg }"
                    >
                        <div
                            :class="action.icon_name"
                            :style="{ color: action.color, fontSize: '14px' }"
                            @click="() => action.onClick(item)"
                        ></div>
                    </div>
                </div>
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import CExpandList from '../../../../../shared-fe/components/c-table/c-expand-list/c-expand-list.vue'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import useImage from '../../../../../shared-fe/composables/use-image'
import { useRecruitmentQuery } from '../../../../../shared-fe/services/recruitment-service'
import { ActionTableModel } from '../../../../../shared-fe/types/table-type'
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import { convertMoney } from '../../../../../shared-fe/utils/convert-data'
import {
    ACTIONS,
    CREATED,
    EXPIRED_TIME,
    PROVINCE_AREA,
    SALARY,
    STATUS,
    TITLE,
    TITLE_AVT,
} from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { RECRUITMENT_COLLECTION } from '../../../../../shared-fe/utils/table-name'
const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}

const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const { idToUrl } = useImage()
const { postItemRecruitment } = useRecruitmentQuery()
const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'deleteItem', 'updateSort'])

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 100,
    },
    {
        text: TITLE_AVT,
        value: 'image',
        width: 100,
    },
    {
        text: TITLE,
        value: 'title',
    },
    {
        text: PROVINCE_AREA,
        value: 'provinces',
    },
    {
        text: STATUS,
        value: 'status',
    },
    {
        text: SALARY,
        value: 'salary',
    },
    {
        text: CREATED,
        value: 'date_created',
    },
    {
        text: EXPIRED_TIME,
        value: 'expired',
    },
    {
        text: ACTIONS,
        value: 'action',
    },
])

const detailRowOnClick = (item: any) => {
    router.push({
        name: PATH_ROUTER.RECRUITMENT_DETAIL.NAME,
        params: {
            id: item.id,
        },
    })
}

const copyRowOnClick = async (item: any) => {
    delete item.id
    let res = await postItemRecruitment(item)
    if (res.data.data) {
        router.push({
            name: PATH_ROUTER.RECRUITMENT_DETAIL.NAME,
            params: {
                id: res.data.data?.id,
            },
        })
    }
}

const actions: ActionTableModel[] = [
    {
        icon_name: 'i-mdi-pencil-outline',
        onClick: detailRowOnClick,
        bg: '#F1F5F9',
        color: '#64748B',
    },
    {
        icon_name: 'i-mdi-content-copy',
        onClick: copyRowOnClick,
        bg: '#DBEAFE',
        color: '#3B82F6',
    },
]

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    let sortKey = sort?.by
    if (sort?.desc) sortKey = `-${sortKey}`
    if (sort?.by !== 'salary' && sort?.by !== 'action' && sort?.by !== 'stt') {
        emit('updateSort', sortKey)
    }
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
