import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useStoreSearch = defineStore('useStoreSearch', () => {
    const searchScreens = ref(Array<Object>())

    const getSearch = (routerId: string) => {
        return searchScreens.value?.find((x: any) => x.routerId === routerId) ?? []
    }
    const updateSearch = (routerId: string, dataInitSearch: any, dataSeletedForm: any = null) => {
        let index = searchScreens.value?.findIndex((x: any) => x.routerId === routerId)
        if (index >= 0) {
            searchScreens.value[index]['dataInitSearch'] = dataInitSearch
            if (dataSeletedForm) {
                searchScreens.value[index]['dataSeletedForm'] = dataSeletedForm
            }
        } else {
            searchScreens.value.push({
                routerId,
                dataInitSearch,
                dataSeletedForm,
            })
        }
    }
    return {
        searchScreens,
        getSearch,
        updateSearch,
    }
})
