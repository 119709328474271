import { useLiveEditor } from '../services/live-editor-service'
import useImage from './use-image'
import {FORMAT_TIME} from "@/shared-fe/utils/constant";
import dayjs from "dayjs";

export const useUtils = () => {

}

export const useFormatDate = (value) => {
    const d = dayjs(value)
    return d.format(FORMAT_TIME.DDMMYYYY)
}
