<template>
	<svg
		viewBox="0 0 48 48"
		xmlns="http://www.w3.org/2000/svg"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
	>
		<path
			fill-opacity=".3"
			d="M24.02 42.98L47.28 14c-.9-.68-9.85-8-23.28-8S1.62 13.32.72 14l23.26 28.98.02.02.02-.02z"
		/>
		<path d="M0 0h48v48H0z" fill="none" />
		<path
			d="M7.07 21.91l16.92 21.07.01.02.02-.02 16.92-21.07C40.08 21.25 33.62 16 24 16c-9.63 0-16.08 5.25-16.93 5.91z"
		/>
	</svg>
</template>

<script lang="ts">
export default {};
</script>
