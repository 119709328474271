<template>
    <c-access :roles="[USER_ROLE.SS]">
        <sidebar-detail icon="pending" class="interview-steps" title="Đào tạo Route Ride">
            <div class="flex items-center flex-col">
                <v-button class="btn-pass" @click="() => goToRouteRide()">
                    <div class="">Đào tạo Route Ride</div>
                </v-button>
            </div>
        </sidebar-detail>
    </c-access>
</template>
<script setup lang="ts">
import CAccess from '../../../../../../shared-fe/components/c-access/c-access.vue'
import { USER_ROLE } from '../../../../../../shared-fe/utils/constant'
const emit = defineEmits(['handleSubmit'])

const goToRouteRide = () => {
    emit('handleSubmit')
}
</script>
