<template>
    <div class="flex items-center mb-6" v-if="objStatus">
        <div class="text-[#94A3B8] mr-2">Trạng thái:</div>
        <div>
            <span
                :style="{
                    background: objStatus?.background,
                    color: objStatus?.color,
                    border: objStatus?.background === '#fff' ? `1px solid ${objStatus?.color}` : '',
                }"
                class="px-2 py-1 rounded-2 inline-block"
            >
                {{ objStatus?.text }}
            </span>
        </div>
    </div>
    <div
        class="has-border border border-solid border-red-400 p-4 w-full text-center my-4 message-error font-bold rounded-lg"
        v-if="existCandidate"
    >
        Không thành công! Ứng viên đã tồn tại trên hệ thống
    </div>
    <div class="form-data px-6 py-[1.7rem] rounded-lg">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">
                    Họ và tên ứng viên
                    <span class="required">*</span>
                </div>
                <v-input
                    :disabled="!fullAccess"
                    type="text"
                    v-model="formData.full_name"
                    @update:model-value="(e) => handleChange(e, 'full_name')"
                ></v-input>
                <span class="message-error" v-if="errors?.full_name">Vui lòng nhập họ tên</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Ngày sinh
                    <span class="required">*</span>
                </div>
                <!-- <v-menu ref="dateTimeMenuDateOfBirth" :close-on-content-click="false" attached full-height seamless>
                    <template #activator="{ toggle, active }">
                        <v-input
                            :active="active"
                            clickable
                            :disabled="!fullAccess"
                            readonly
                            :model-value="formData.date_of_birth_display"
                            placeholder=""
                            @click="toggle"
                        >
                            <template #append>
                                <v-icon
                                    :name="formData.date_of_birth ? 'clear' : 'today'"
                                    :class="{
                                        active,
                                        'clear-icon': formData.date_of_birth_display,
                                        'today-icon': !formData.date_of_birth_display,
                                    }"
                                    v-on="{ click: formData.date_of_birth ? unsetValueDateOfBirth : null }"
                                />
                            </template>
                        </v-input>
                    </template>

                    <c-datepicker
                        type="date"
                        :include-seconds="true"
                        :use-24="true"
                        :model-value="formData.date_of_birth"
                        @update:model-value="(e) => handleChange(e, 'date_of_birth')"
                        @close="dateTimeMenuDateOfBirth?.deactivate"
                    />
                </v-menu> -->
                <c-native-date
                    :disabled="!fullAccess"
                    :value="formData.date_of_birth"
                    @input="(e) => handleChange(e, 'date_of_birth')"
                ></c-native-date>
                <span class="message-error" v-if="errors?.date_of_birth_invalid">Chưa đủ 18 tuổi để ứng tuyển</span>
                <span class="message-error" v-if="errors?.date_of_birth">Vui lòng nhập ngày sinh</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Giới tính"
                    collection="options"
                    v-model:modelValue="formData.gender"
                    field_text="opt_value"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'gender')"
                    :query-filter="{ filter: { opt_group: 'gender' } }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.gender">Vui lòng nhập giới tính</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Điện thoại
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.phone_number"
                    @update:model-value="(e) => handleChange(e, 'phone_number')"
                ></v-input>
                <span class="message-error" v-if="errors?.phone_number">
                    SĐT phải là 10 chữ số và không được để trống
                </span>
            </div>
            <div v-if="ss_refer !== true" class="col-span-1">
                <div class="type-label">Số điện thoại sử dụng zalo</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.phone_number_zalo"
                    @update:model-value="(e) => handleChange(e, 'phone_number_zalo')"
                ></v-input>
                <span class="message-error" v-if="errors?.phone_number_zalo">SĐT phải là 10 chữ số</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">Email</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.email"
                    @update:model-value="(e) => handleChange(e, 'email')"
                ></v-input>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Trình độ học vấn"
                    collection="options"
                    v-model:modelValue="formData.education_level"
                    field_text="opt_value"
                    field_value="id"
                    placeholder=""
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'education_level')"
                    :query-filter="{ filter: { opt_group: 'education_level' } }"
                ></c-select-m2o>
            </div>
            <div v-if="ss_refer !== true" class="col-span-1">
                <div class="type-label">Tình trạng hôn nhân</div>
                <v-select
                    :model-value="formData.married"
                    :items="arrayMarriedStatus"
                    showDeselect
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'married')"
                ></v-select>
            </div>
            <div v-if="formData?.id" class="col-span-1">
                <div class="type-label">Trạng thái hồ sơ</div>
                <v-select
                    showDeselect
                    :model-value="formData?.filter_profiles"
                    :items="arrayFilterProfiles"
                    :disabled="!fullAccess || formData?.request_id?.length > 0"
                    @update:model-value="(e) => handleChange(e, 'filter_profiles')"
                ></v-select>
            </div>
        </div>
        <div class="border-bottom my-8"></div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">
                    Địa chỉ hiện tại
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.address"
                    @update:model-value="(e) => handleChange(e, 'address')"
                ></v-input>
                <span class="message-error" v-if="errors?.address">Vui lòng nhập địa chỉ</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    collection="provinces"
                    v-model:model-value="formData.district"
                    field_text="name"
                    field_value="id"
                    label="Quận huyện"
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'district')"
                    :query-filter="queryFilterDistrict"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.district">Vui lòng nhập quận huyện</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Tỉnh thành"
                    collection="provinces"
                    v-model:modelValue="formData.province"
                    field_text="name"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'province')"
                    :query-filter="{ filter: { parent_id: { _null: true } } }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.province">Vui lòng nhập tỉnh thành</span>
            </div>
            <div v-if="ss_refer !== true" class="col-span-1">
                <div class="type-label">CCCD</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.id_card_number"
                    @update:model-value="(e) => handleChange(e, 'id_card_number')"
                ></v-input>
            </div>
            <div v-if="ss_refer !== true" class="col-span-1">
                <div class="type-label">Ngày cấp</div>
                <!-- <v-menu ref="dateTimeMenuCard" :close-on-content-click="false" attached full-height seamless>
                    <template #activator="{ toggle, active }">
                        <v-input
                            :active="active"
                            clickable
                            readonly
                            :disabled="!fullAccess"
                            :model-value="formData.id_card_date_display"
                            placeholder=""
                            @click="toggle"
                        >
                            <template #append>
                                <v-icon
                                    :name="formData.id_card_date ? 'clear' : 'today'"
                                    :class="{
                                        active,
                                        'clear-icon': formData.id_card_date_display,
                                        'today-icon': !formData.id_card_date_display,
                                    }"
                                    v-on="{ click: formData.id_card_date ? unsetValueCarDate : null }"
                                />
                            </template>
                        </v-input>
                    </template>

                    <c-datepicker
                        type="date"
                        :include-seconds="true"
                        :use-24="true"
                        :model-value="formData.id_card_date"
                        @update:model-value="(e) => handleChange(e, 'id_card_date')"
                        @close="dateTimeMenuCard?.deactivate"
                    />
                </v-menu> -->
                <c-native-date
                    :disabled="!fullAccess"
                    :value="formData.id_card_date"
                    @input="(e) => handleChange(e, 'id_card_date')"
                ></c-native-date>
            </div>
            <div v-if="ss_refer !== true" class="col-span-1">
                <div class="type-label">Nơi cấp</div>
                <v-input
                    :disabled="!fullAccess"
                    :model-value="formData.id_card_place"
                    @update:model-value="(e) => handleChange(e, 'id_card_place')"
                ></v-input>
            </div>
        </div>
        <div class="border-bottom my-8"></div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <c-select-m2o
                    label="Khu vực ứng tuyển"
                    collection="provinces"
                    v-model:modelValue="formData.province_recruitment"
                    field_text="name"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'province_recruitment')"
                    :query-filter="{ filter: { parent_id: { _null: true } } }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.province_recruitment">Vui lòng nhập tỉnh thành</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    collection="provinces"
                    v-model:model-value="formData.district_recruitment"
                    field_text="name"
                    field_value="id"
                    label="&nbsp"
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'district_recruitment')"
                    :query-filter="queryFilterDistrictRecruitment"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.district_recruitment">Vui lòng nhập quận huyện</span>
            </div>
        </div>
        <div class="border-bottom my-8"></div>

        <div class="grid grid-cols-1 gap-8">
            <div class="col-span-1" v-if="formData.cv">
                <div class="type-label">
                    CV
                    <div class="text-sm font-normal font-italic">Đây là file CV của Ứng Viên tự upload từ website</div>
                </div>
                <c-file class="" :id="formData.cv"></c-file>
            </div>

            <div class="col-span-1">
                <div class="type-label">Tài liệu đính kèm</div>
                <interface-files
                    :collection="CANDIDATE_COLLECTION"
                    :enableCreate="true"
                    :enableSelect="false"
                    :disabled="!fullAccess"
                    field="attachments"
                    :folder="undefined"
                    :limit="15"
                    :primaryKey="formData.id"
                    :template="null"
                    v-model:value="formData.attachments"
                    @input="handleChangeUploadFile"
                ></interface-files>
            </div>
            <div class="col-span-1">
                <div class="type-label">Kinh nghiệm</div>
                <v-textarea
                    :disabled="!fullAccess"
                    :model-value="formData.experience_detail"
                    @update:model-value="(e) => handleChange(e, 'experience_detail')"
                />
            </div>
            <c-access :roles="[USER_ROLE.HR]">
                <div class="col-span-1">
                    <div class="type-label">Ghi chú</div>
                    <v-textarea
                        :disabled="!fullAccess"
                        :model-value="formData.note"
                        @update:model-value="(e) => handleChange(e, 'note')"
                    />
                </div>
            </c-access>
        </div>
        <div class="border-bottom my-8"></div>

        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8" v-if="ss_refer !== true">
            <div class="col-span-1">
                <c-select-m2o
                    label="Nguồn ứng viên"
                    collection="options"
                    v-model:modelValue="formData.source"
                    field_text="opt_value"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'source')"
                    :query-filter="{ filter: filterCandidateSource }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.source">Vui lòng nhập nguồn ứng viên</span>
            </div>
            <template v-if="ss_refer !== true">
                <div class="col-span-1">
                    <div class="type-label">Mã người giới thiệu</div>
                    <v-input
                        type="text"
                        :disabled="!fullAccess"
                        v-model="code"
                        @change="(e) => handleChangeCode(e)"
                    ></v-input>
                    <span class="message-error" v-if="errorsReferCode">Mã người giới thiệu không hợp lệ</span>
                </div>
                <div class="col-span-1">
                    <div class="type-label">Tên người giới thiệu</div>
                    <v-input type="text" disabled :model-value="refer_name"></v-input>
                </div>
            </template>
        </div>
    </div>
    <v-checkbox
        v-if="ss_refer"
        class="pb-[1.7rem] whitespace-pre-wrap"
        :checked="privacy"
        @update:model-value="privacy = !privacy"
    >
        Bạn xác nhận rằng bạn đã đọc toàn bộ
        <a :href="announcementLink" target="_blank">
            <strong>Thông báo Xử lý dữ liệu cá nhân của Nhà phân phối</strong>
        </a>
        , và cho phép Nhà phân phối được xử lý dữ liệu cá nhân của bạn theo Thông báo này.
    </v-checkbox>
    <template v-if="item">
        <candidate-history :idCandidate="item.id"></candidate-history>
    </template>
    <template v-if="item">
        <duplicate-candidate-table
            :idCandidate="item.id"
            :phone-number="item.phone_number"
            :phone-number-zalo="item.phone_number_zalo"
            :email="item.email"
        ></duplicate-candidate-table>
    </template>
    <v-overlay :active="confirmOverlay">
        <div class="py-8 px-10 bg-white rounded-md max-w-[500px] max-h-[90vh]">
            <div class="mb-6 text-lg">
                Ứng viên đã tồn tại trong hệ thống, vui lòng xem thông tin chi tiết ứng viên tại
                <a class="font-bold" :href="'/admin/spvn/candidates/' + idCandidate" target="_blank">đây</a>
            </div>
            <div class="flex gap-x-6 w-full">
                <v-button class="btn-modal w-full" secondary @click="handleClose">Hủy bỏ</v-button>
                <v-button class="btn-modal w-full" @click="handleConfirm">Xác nhận</v-button>
            </div>
        </div>
    </v-overlay>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import Joi, {exist} from 'joi'
import _ from 'lodash'
import {computed, onBeforeUnmount, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import CSelectM2o from '../../../../../shared-fe/components/c-select-m2o/c-select-m2o.vue'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import {useCandidateQuery} from '../../../../../shared-fe/services/candidate-service'
import {useUserQuery} from '../../../../../shared-fe/services/user-service'
import {useStoreCommon} from '../../../../../shared-fe/stores/common'
import {
    CANDIDATE_STATUS,
    FORMAT_TIME,
    MODE_ADD,
    MODE_EDIT,
    SUBMIT_EVENT,
    USER_ROLE,
} from '../../../../../shared-fe/utils/constant'
import {PATH_ROUTER} from '../../../../../shared-fe/utils/path'
import {CANDIDATE_COLLECTION} from '../../../../../shared-fe/utils/table-name'
import CandidateHistory from './candidate-history.vue'
import duplicateCandidateTable from './duplicate-candidate-table.vue'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import CNativeDate from '../../../../../shared-fe/components/c-native-date/c-native-date.vue'
import {useUserStore} from '@/stores/user'
import CDatepicker from '@/shared-fe/components/c-datepicker/c-datepicker.vue'
import {useCandidate} from '@/shared-fe/composables/use-candidate'
import {useLiveEditor} from '@/shared-fe/services/live-editor-service'
import {useField} from '@/shared-fe/composables/use-field'
import {ageValidation} from '@/shared-fe/utils/convert-data'
import CFile from '@/shared-fe/components/c-file/c-file.vue'
import {notify} from '@/utils/notify'

const commonStore = useStoreCommon()
const {patchItemCandidate, postItemCandidate} = useCandidateQuery()
const {getDataLiveEditor} = useLiveEditor()
const {checkCandidateExists, checkCandidateExistsHR} = useCandidate()
const {getUserByCode} = useUserQuery()
const {getDocumentCandidateStatus} = useField()
const announcementLink = ref('')
const privacy = ref(false)
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
    ss_refer: {
        type: Boolean,
        default: false,
    },
})
getDataLiveEditor('announcement_link')
    .then((res) => {
        if (res?.data.data && res?.data.data.length > 0) {
            announcementLink.value = res?.data.data[0].option_value
        }
    })
    .catch()

const arrayFilterProfiles = computed(() => {
    let data = getDocumentCandidateStatus(CANDIDATE_COLLECTION)
    return [{text: '', value: null}, ...data]
})

const title = computed(() => (props.ss_refer ? 'Giới thiệu ứng viên mới' : 'Tạo mới ứng viên'))

const {canAccess} = usePermission()
const fullAccess = computed(() => {
    let access = [USER_ROLE.HR]
    if (props.ss_refer) {
        access.push(USER_ROLE.SS)
    }
    return canAccess(access)
})
const route = useRoute()
const code = ref(props?.item?.code)
const refer_name = ref(props?.item?.refer_name)
const errorsReferCode = ref(false)
const confirmOverlay = ref(false)
const idCandidate = ref('')
const paramsCandidate = ref(null)
const objStatus = computed(() => {
    return formData.value.status?.opt_key ? CANDIDATE_STATUS[formData.value.status?.opt_key] : null
})
const existCandidate = ref('')
const dataDefault = {
    id: 0,
    full_name: null,
    date_of_birth: null,
    date_of_birth_display: null,
    gender: null,
    phone_number: null,
    phone_number_zalo: null,
    email: null,
    education_level: null,
    married: null,
    province: null,
    district: null,
    address: null,
    id_card_number: null,
    id_card_date: null,
    id_card_date_display: null,
    id_card_place: null,
    experience_detail: null,
    note: null,
    source: null,
    attachments: null,
    refer: null,
    status: null,
    request_id: null,
    filter_profiles: '',
    province_recruitment: null,
    district_recruitment: null,
    cv: null,
}
const schema = Joi.object({
    full_name: Joi.string().required(),
    date_of_birth: Joi.string().required(),
    gender: Joi.number().required(),
    phone_number: Joi.string()
        .required()
        .regex(/^\d{10}$/),
    province: Joi.string().required(),
    province_recruitment: Joi.string().required(),
    district_recruitment: Joi.string().required(),
    district: Joi.string().required(),
    address: Joi.string().required(),
    source: Joi.number().required(),
}).unknown(false)

const schemaForSS = Joi.object({
    full_name: Joi.string().required(),
    date_of_birth: Joi.string().required(),
    gender: Joi.number().required(),
    phone_number: Joi.string()
        .required()
        .regex(/^\d{10}$/),
    province: Joi.string().required(),
    province_recruitment: Joi.string().required(),
    district_recruitment: Joi.string().required(),
    district: Joi.string().required(),
    address: Joi.string().required(),
}).unknown(false)

const router = useRouter()
const defaultDataEdit = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const formData = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const dateTimeMenuDateOfBirth = ref()
const dateTimeMenuCard = ref()
const errors: any = ref(null)

const queryFilterDistrict = computed(() => {
    return {filter: {parent_id: formData.value.province}}
})
const queryFilterDistrictRecruitment = computed(() => {
    return {filter: {parent_id: formData.value.province_recruitment}}
})

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(formData.value)) {
        formData.value[key] = props.item[key]
    }
    formData.value.date_of_birth = formData.value.date_of_birth
        ? dayjs(formData.value.date_of_birth).format(FORMAT_TIME.YYYMMDD)
        : null
    formData.value.date_of_birth_display = formData.value.date_of_birth
        ? dayjs(formData.value.date_of_birth).format(FORMAT_TIME.DDMMYYYY)
        : null

    formData.value.id_card_date = formData.value.id_card_date
        ? dayjs(formData.value.id_card_date).format(FORMAT_TIME.YYYMMDD)
        : null
    formData.value.id_card_date_display = formData.value.id_card_date
        ? dayjs(formData.value.id_card_date).format(FORMAT_TIME.DDMMYYYY)
        : null

    defaultDataEdit.value = JSON.parse(JSON.stringify(formData.value))
}

watch(
    () => props.item,
    async () => {
        if (props.item) {
            initData()
        }
    },
    {immediate: true}
)

const arrayMarriedStatus = [
    {
        text: 'Độc thân',
        value: false,
    },
    {
        text: 'Kết hôn',
        value: true,
    },
]
const handleChange = (e: any, variable: any) => {
    switch (variable) {
        case 'date_of_birth':
            formData.value.date_of_birth_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'id_card_date':
            formData.value.id_card_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'province':
            if (e !== props?.item?.province) {
                formData.value[variable] = e
                formData.value.district = ''
            } else {
                formData.value.district = props.item.district
            }
            break

        case 'province_recruitment':
            if (e !== props?.item?.province_recruitment) {
                formData.value[variable] = e
                formData.value.district_recruitment = ''
            } else {
                formData.value.district_recruitment = props.item.district_recruitment
            }
            break
        default:
            formData.value[variable] = e
            break
    }
}

const handleChangeCode = (e) => {
    refer_name.value = ''
    if (e.target.value) {
        refer_name.value = ''
        code.value = e.target.value
        getUserByCode(e.target.value)
            .then((res) => {
                errorsReferCode.value = false
                refer_name.value = res.data.data[0].full_name
                formData.value.refer = res.data.data[0].id
            })
            .catch((x) => {
                errorsReferCode.value = true
                formData.value.refer = null
            })
    } else {
        errorsReferCode.value = false
        formData.value.refer = null
    }
}

const handleChangeUploadFile = (data) => {
    formData.value.attachments = data
}
const unsetValueDateOfBirth = (e) => {
    formData.value.date_of_birth = ''
    formData.value.date_of_birth_display = ''
    unsetValue(e)
}

const unsetValueCarDate = (e) => {
    formData.value.id_card_date = ''
    formData.value.id_card_date_display = ''
    unsetValue(e)
}

const filterCandidateSource = computed(() => {
    let idCondition = [];
    if (formData.value.source) {
        idCondition = [
            {
                id: formData.value.source
            },
        ]
    }

    return {
        _or: [
            {
                opt_group: 'candidate_source',
                is_shown: true,
            },
            ...idCondition
        ]
    }
})

const unsetValue = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
}

const validatePhoneZalo = () => {
    if (formData.value.phone_number_zalo) {
        let pattern = /^\d{10}$/
        if (!pattern.test(formData.value.phone_number_zalo)) {
            return {phone_number_zalo: 'phone_number_zalo is invalid '}
        }
    }
    return {}
}

const validateDateOfBirth = () => {
    if (formData.value.date_of_birth) {
        if (!ageValidation(new Date(formData.value.date_of_birth))) {
            return {date_of_birth_invalid: 'Chưa đủ 18 tuổi để ứng tuyển'}
        }
    }
    return {}
}

const userStore = useUserStore()

const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.CANDIDATE_DETAIL_SUBMIT)
    if (payloadSubmitForm) {
        if ((props.ss_refer && privacy.value) || !props.ss_refer) {
            const mainContent = document.getElementById('main-content')
            errors.value = null
            existCandidate.value = ''
            const {
                id,
                date_of_birth_display,
                email,
                education_level,
                married,
                id_card_number,
                id_card_date,
                id_card_date_display,
                id_card_place,
                note,
                code,
                refer_name,
                attachments,
                refer,
                status,
                request_id,
                phone_number_zalo,
                experience_detail,
                filter_profiles,
                ...res
            } = formData.value

            if (props.ss_refer !== true) {
                const {error} = schema.validate(res, {abortEarly: false, allowUnknown: true})
                if (error) {
                    errors.value = error.details.reduce((acc, curr) => {
                        acc[curr.path[0]] = curr.message
                        return acc
                    }, {})
                }

                errors.value = {
                    ...errors.value,
                    ...validatePhoneZalo(),
                    ...validateDateOfBirth(),
                }
            } else {
                const {source, ...resSS} = res
                const {error} = schemaForSS.validate(resSS, {abortEarly: false})
                if (error) {
                    errors.value = error.details.reduce((acc, curr) => {
                        acc[curr.path[0]] = curr.message
                        return acc
                    }, {})
                }

                errors.value = {
                    ...errors.value,
                    ...validatePhoneZalo(),
                    ...validateDateOfBirth(),
                }
            }

            if (errorsReferCode.value) {
                errors.value = {
                    ...errors.value,
                    errorsReferCode: errorsReferCode.value,
                }
            }

            if (Object.keys(errors.value).length !== 0) {
                return
            }

            errors.value = {}
            let params = JSON.parse(JSON.stringify(formData.value))
            params.filter_profiles = params.filter_profiles ? params.filter_profiles : ''

            if (!params.attachments?.create) {
                delete params.attachments
            }

            if (params.request_id) {
                params.request_id = params.request_id[0]
            }

            if (params.refer && params.refer.id) {
                params.refer = params.refer.id
            }

            if (props.ss_refer) {
                params.refer = userStore.currentUser.id
                params.source = 63
            }
            commonStore.loading = true
            if (payloadSubmitForm.payload === MODE_EDIT) {
                delete params.date_of_birth_display
                delete params.id_card_date_display
                delete params.status
                await patchItemCandidate(params.id, params)
                commonStore.showDialogSucessUpdate = true
            } else if (payloadSubmitForm.payload === MODE_ADD) {
                delete params.id
                params.status = 3159
                params.apply_date = new Date()
                if (props.ss_refer) {
                    let checkExist = await checkCandidateExists(
                        params.phone_number,
                        params.phone_number_zalo,
                        params.email
                    )
                    if (!checkExist?.data?.result?.is_duplicate) {
                        let res = await postItemCandidate(params)
                        router.push({
                            name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
                            params: {
                                id: res?.data?.data?.id,
                            },
                        })
                    } else {
                        existCandidate.value = 'error'
                        mainContent?.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        })
                    }
                } else {
                    let checkExist = await checkCandidateExistsHR(
                        params.phone_number,
                        params.phone_number_zalo,
                        params.email
                    )
                    if (!checkExist) {
                        let res = await postItemCandidate(params)
                        router.push({
                            name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
                            params: {
                                id: res?.data?.data?.id,
                            },
                        })
                    } else {
                        confirmOverlay.value = true
                        idCandidate.value = checkExist
                        paramsCandidate.value = params
                    }
                }
            }
            commonStore.loading = false
        }
    }
}

const handleConfirm = async () => {
    confirmOverlay.value = false
    if (paramsCandidate.value) {
        let res = await postItemCandidate(paramsCandidate.value)
        router.push({
            name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
            params: {
                id: res?.data?.data?.id,
            },
        })
    }
}

const handleClose = () => {
    confirmOverlay.value = false
    paramsCandidate.value = null
    idCandidate.value = ''
}

const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})
</script>

<style>
.v-checkbox {
    .label {
        white-space: pre-wrap !important;
    }
}
</style>
