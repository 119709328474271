<template>
    <div class="mb-9">
        <c-expand-search
            :dataBasic="BASIC_SEARCH"
            :dataExpand="EXPAND_SEARCH"
            @update:model-value="handleSearch"
            @changeItem="changeItem"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import CExpandSearch from '../../../../../shared-fe/components/c-expand-search/c-expand-search.vue'
import { useField } from '../../../../../shared-fe/composables/use-field'
import { useProviderQuery } from '../../../../../shared-fe/services/provider-service'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { ComponentPropsModel } from '../../../../../shared-fe/types/table-type'
import { USER_ROLE, USER_TYPE } from '../../../../../shared-fe/utils/constant'
import { RANGE_DATE_PLACEHOLDER } from '../../../../../shared-fe/utils/message'
import { sortDataSelection } from '../../../../../shared-fe/utils/convert-data'
import { useUserStore } from '@/stores/user'
import { useAreaQuery } from '@/shared-fe/services/area-service'
const emit = defineEmits(['update:handleSearch'])

const props = defineProps({
    arrayStatus: {
        type: Array<Object>,
        default: [],
    },
    arrayValue: {
        type: Object,
        default: {},
    },
})

const { getAllProviderItem } = useProviderQuery()
const { getAsms } = useUserQuery()
const { getDcrStatus } = useField()
const arrAreaAndZone = ref(<any>[])
const arrZoneDefault = ref(<any>[])
const { getAllAreaAndZoneItem } = useAreaQuery()
const dataModel = ref(<any>props.arrayValue)
const arrASM = ref(<any>[])
const arrProviderFullData = ref(<any>[])
const users = ref(<any>[])

const userStore = useUserStore()

getAllAreaAndZoneItem().then((res) => {
    if (res.data.data) {
        res.data.data?.forEach((x) => {
            if (!x?.zone) {
                arrZoneDefault.value.push({
                    text: x?.name,
                    value: x?.id,
                })
            }
            arrAreaAndZone.value.push(x)
        })
        arrZoneDefault.value = sortDataSelection(arrZoneDefault.value)
    }
})

const arrZone = computed(() => {
    let _zones: any = []
    if (userStore.currentUser?.role?.short_name !== 'Admin') {
        if (userStore.currentUser?.ares_management?.length > 0) {
            userStore.currentUser?.ares_management.forEach((x) => {
                if (_zones?.length > 0) {
                    if (!_zones.find((k) => k.value === x?.zone?.id)) {
                        _zones.push({
                            text: x?.zone?.name,
                            value: x?.zone?.id,
                        })
                    }
                } else {
                    _zones.push({
                        text: x?.zone?.name,
                        value: x?.zone?.id,
                    })
                }
            })
        }
        return sortDataSelection(_zones)
    } else {
        return arrZoneDefault.value
    }
})
const arrProvider = computed(() => {
    if (!dataModel.value.zone && !dataModel.value.area) {
        return sortDataSelection(
            arrProviderFullData.value.map((x) => {
                return {
                    text: x?.name,
                    value: x?.id,
                }
            })
        )
    }

    if (dataModel.value.zone) {
        let _arrProvider = arrProviderFullData.value.filter((x) => x.zone === dataModel.value.zone)

        if (dataModel.value.area) {
            _arrProvider = _arrProvider.filter((x) => x.area === dataModel.value.area)
        }

        return sortDataSelection(
            _arrProvider.map((x) => {
                return {
                    text: x?.name,
                    value: x?.id,
                }
            })
        )
    }

    return []
})

getAsms('').then((res) => {
    if (res.data.data) {
        let _arrAsm: any = []
        let _users: any = []
        res.data.data?.forEach((x) => {
            if (x.role?.short_name === USER_ROLE.ASM) {
                _arrAsm.push({
                    text: x?.full_name,
                    value: x?.id,
                })
            }
            _users.push(x)
        })
        arrASM.value = sortDataSelection(_arrAsm)
        users.value = _users
    }
})

const arrSS = computed(() => {
    let _arrSS: any = []
    if (dataModel.value?.asm) {
        users.value.forEach((x) => {
            if (x.parent_id === dataModel.value.asm && x.role?.short_name === USER_ROLE.SS) {
                _arrSS.push({
                    text: x?.full_name,
                    value: x?.id,
                })
            }
        })
    } else {
        users.value.forEach((x) => {
            if (x.role?.short_name === USER_ROLE.SS) {
                _arrSS.push({
                    text: x?.full_name,
                    value: x?.id,
                })
            }
        })
    }

    return sortDataSelection(_arrSS)
})

getAllProviderItem().then((res) => {
    if (res.data.data) {
        res.data.data?.forEach((x) => {
            arrProviderFullData.value.push(x)
        })
    }
})

const arrArea = computed(() => {
    let _arrArea: any = []
    if (userStore.currentUser?.role?.short_name !== 'Admin') {
        if (dataModel.value.zone) {
            userStore.currentUser?.ares_management?.forEach((x) => {
                if (x.zone?.id === dataModel.value.zone) {
                    _arrArea.push({
                        text: x?.name,
                        value: x?.id,
                    })
                }
            })
        }

        return sortDataSelection(_arrArea)
    } else {
        if (dataModel.value.zone) {
            arrAreaAndZone.value.forEach((x) => {
                if (x?.zone === dataModel.value.zone) {
                    _arrArea.push({
                        text: x?.name,
                        value: x?.id,
                    })
                }
            })
        }
        return sortDataSelection(_arrArea)
    }
})

watch(
    () => dataModel.value.zone,
    () => {
        dataModel.value.area = ''
        dataModel.value.system_providers = ''
    }
)

watch(
    () => dataModel.value.area,
    () => {
        dataModel.value.system_providers = ''
    }
)

watch(
    () => props.arrayValue,
    () => {
        dataModel.value = props.arrayValue || {}
    }
)

watch(
    () => dataModel.value.asm,
    () => {
        dataModel.value.ss = ''
    }
)

const arrayDCRType = [
    {
        text: 'Tất cả',
        value: 'all',
    },
    {
        text: 'Nhân viên DCR',
        value: USER_TYPE.RETAIL,
    },
    {
        text: 'Nhân viên WSR',
        value: USER_TYPE.WSR,
    },
]

const BASIC_SEARCH: ComponentPropsModel[] = [
    {
        key: 'title',
        value: dataModel.value?.title,
        label: '',
        placeholder: 'Nhập Tên/ Mã DCR/ Mã Route/ Điện thoại/ CCCD ...',
        type: 'v-input',
        icon: 'search',
    },
    {
        key: 'start_date',
        value: '',
        label: '',
        placeholder: RANGE_DATE_PLACEHOLDER,
        type: 'c-range-datetime',
        data: dataModel.value?.start_Date || null,
    },
]

const EXPAND_SEARCH: any = computed(() => {
    return [
        {
            key: 'zone',
            value: dataModel.value?.zone,
            label: 'Chi nhánh',
            placeholder: 'Chi nhánh',
            type: 'v-select',
            data: arrZone.value,
            role: [USER_ROLE.HR],
        },
        {
            key: 'area',
            value: dataModel.value?.area,
            label: 'Khu vực',
            placeholder: 'Khu vực',
            type: 'v-select',
            data: arrArea.value,
            role: [USER_ROLE.HR],
        },
        {
            key: 'system_providers',
            value: dataModel.value?.system_providers,
            label: 'Nhà phân phối',
            placeholder: 'Nhà phân phối',
            type: 'v-select',
            data: arrProvider.value,
        },
        {
            key: 'asm',
            value: dataModel.value?.asm,
            label: 'ASM',
            placeholder: 'ASM',
            type: 'v-select',
            data: arrASM.value,
            role: [USER_ROLE.HR],
        },
        {
            key: 'ss',
            value: dataModel.value?.ss,
            label: 'SS',
            placeholder: 'SS',
            type: 'v-select',
            data: arrSS.value,
        },
        {
            key: 'employment_status',
            value: dataModel.value?.employment_status,
            label: 'Trạng thái',
            placeholder: 'Trạng thái',
            type: 'v-select',
            data: getDcrStatus('directus_users'),
        },
        {
            key: 'user_type',
            value: dataModel.value?.user_type,
            label: 'Vị trí',
            placeholder: 'Vị trí',
            type: 'v-select',
            data: arrayDCRType,
            role: [USER_ROLE.HR],
        },
    ]
})

const changeItem = (data) => {
    dataModel.value[data.key] = data.value
}

const handleSearch = (data) => {
    emit('update:handleSearch', data)
}
</script>

<style lang="scss" scoped></style>
