import { useFieldTemplate } from '@/shared-fe/composables/use-field-template'
import { computed, reactive } from 'vue'

const { fieldInput } = useFieldTemplate({})

export const useTransferForm = ({ fieldReOrder, currentSS, currentDCR }, model, initValue) => {
    const infoDCRfields = computed(() => {
        return [
            reactive(
                fieldInput('dcr_code', 'Mã DCR', {
                    readonly: true,
                    options: {
                        placeholder: currentDCR.value.code,
                    },
                    group: null,
                })
            ),
            reactive(
                fieldInput('dcr_route', 'Mã Route', {
                    readonly: true,
                    options: {
                        placeholder: currentDCR.value.route,
                    },
                    group: null,
                })
            ),
        ]
    })

    const isTransfer = computed(() => {
        if (model.value.hiring_reason == 'transfer') return true
        return !model.value.hiring_reason && initValue.value.hiring_reason == 'transfer'
    })

    const formTransfer = computed(() => {
        if (!fieldReOrder.value.staff_id) return []

        fieldReOrder.value.staff_id.name = 'Chọn DCR luân chuyển'
        fieldReOrder.value.staff_id.meta.interface = 'app-select-m20'
        fieldReOrder.value.staff_id.meta.options = {
            label_field: 'full_name',
            limit: -1,
            filter: computed(() => {
                return {
                    parent_id: model.value.user_created ?? initValue.value.user_created,
                    system_providers: {
                        provider_id: model.value.providers,
                    },
                    role: {
                        short_name: 'dcr',
                    },
                    employment_status: {
                        _in: ['on', 'process_resign'],
                    },
                }
            }),
        }

        let ctxFields = [fieldReOrder.value.staff_id]

        ctxFields = ctxFields.concat(infoDCRfields.value)

        fieldReOrder.value.rotation_date.meta.interface = 'app-datetime'

        fieldReOrder.value.rotation_date.name = 'Ngày luân chuyển'
        fieldReOrder.value.reason_rotation.name = 'Lý do luân chuyển'

        ctxFields.push(fieldReOrder.value.rotation_date)
        ctxFields.push(fieldReOrder.value.reason_rotation)

        let loop = 1
        for (let f of ctxFields) {
            loop++
            f.meta.sort = loop
        }

        return ctxFields
    })

    return {
        formTransfer,
        isTransfer,
    }
}
