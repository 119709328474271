const RECRUITMENT_COLLECTION = 'recruitments'
const RECRUITMENT_REQUEST_COLLECTION = 'recruitment_request'
const CANDIDATE_COLLECTION = 'candidates'
const USER_COLLECTION = 'users'
const CANDIDATES_HISTORY_COLLECTION = 'candidates_history'
const RESIGN_COLLECTION = 'resigns'
const RESIGN_WSR_COLLECTION = 'resigns_wsr'
const PROBATIONARY_ASSESSMENTS_COLLECTION = 'probationary_assessments'
const SESSION_TRAINS_COLLECTION = 'session_trains'
const LIVE_EDITOR_COLLECTION = 'live_editor'
const FILES_COLLECTION = 'files'
const USER_ACTIVITIES_COLLECTION = 'user_activities'
const INTERVIEW_ROUND_COLLECTION = 'interview_round'
const INTERVIEW_DETAIL_COLLECTION = 'interview_details'
const RESIGN_HISTORY_COLLECTION = 'resigns_history'
const PROCESS_TRAINING = 'process_train'
const EXEMPTION_CERTIFICATE_COLLECTION = 'exemption_certificate'
const EXAM_RESULTS_COLLECTION = 'exam_results'
const EXAM_ROOM = 'exam_rooms'
const DIRECTUS_SETTINGS_COLLECTION = 'directus_settings'
const RECRUITMENT_REQUEST_HISTORY_COLLECTION = 'request_historys'
const TARGETS_COLLECTION = 'targets'
const COURSES_COLLECTION = 'courses'
const MODIFY_ONBOARDING_COLLECTION = 'modify_onboarding'
const ROUTES_COLLECTION = 'routes'
const DCR_EXISTS_COLLECTION = 'dcr_exists'
const ROLES_COLLECTION = 'roles'
const PROVIDER_COLLECTION = 'providers'

export {
    DIRECTUS_SETTINGS_COLLECTION,
    RECRUITMENT_COLLECTION,
    RECRUITMENT_REQUEST_COLLECTION,
    CANDIDATE_COLLECTION,
    CANDIDATES_HISTORY_COLLECTION,
    USER_COLLECTION,
    RESIGN_COLLECTION,
    PROBATIONARY_ASSESSMENTS_COLLECTION,
    SESSION_TRAINS_COLLECTION,
    LIVE_EDITOR_COLLECTION,
    USER_ACTIVITIES_COLLECTION,
    FILES_COLLECTION,
    INTERVIEW_DETAIL_COLLECTION,
    RESIGN_HISTORY_COLLECTION,
    PROCESS_TRAINING,
    EXEMPTION_CERTIFICATE_COLLECTION,
    EXAM_RESULTS_COLLECTION,
    INTERVIEW_ROUND_COLLECTION,
    EXAM_ROOM,
    RECRUITMENT_REQUEST_HISTORY_COLLECTION,
    TARGETS_COLLECTION,
    COURSES_COLLECTION,
    MODIFY_ONBOARDING_COLLECTION,
    ROUTES_COLLECTION,
    DCR_EXISTS_COLLECTION,
    RESIGN_WSR_COLLECTION,
    ROLES_COLLECTION,
    PROVIDER_COLLECTION
}
