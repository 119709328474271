<template>
    <div class="px-13 pb-10 flex flex-col gap-y-12" v-if="isReady">
        <v-form class="" primaryKey="+" v-model="formData" :fields="allFields"></v-form>
    </div>
    <div v-else>
        <div class="text-primary text-2xl text-center">Đang khởi tạo dữ liệu ...</div>
    </div>
</template>

<script lang="ts" setup>
import { useFieldsCom } from './field-composables'
import { defineEmits, watch, computed } from 'vue'

const emit = defineEmits(['startLoad', 'endLoad'])

const { fieldGroup, fieldInput, fieldSmallFolder, save, formLoading, formData, isReady } = useFieldsCom('global', {
    group: {
        headerColor: '#f59e0b',
    },
})

watch(
    () => formLoading.value,
    (newVl) => {
        emit(newVl ? 'startLoad' : 'endLoad')
    }
)

const groups = {
    searchBoxInBanner: 'searchBoxInBanner',
    companyIntroduced: 'companyIntroduced',
    whyJoined: 'whyJoined',
    highLightMonth: 'highLightMonth',
    recruitmentList: 'recruitmentList',
    dynamicWorking: 'dynamicWorking',
    feedback: 'feedback',
    news: 'news',
    recruitmentDetailScreen: 'recruitmentDetailScreen',
    recruitmentListScreen: 'recruitmentListScreen',
    footer: 'footer',
    announcementLink: 'announcementLink',
}
const searchBoxInBanner = [
    fieldGroup('searchBoxInBanner', 'Thanh tìm kiếm trang chủ ảnh bìa'),
    fieldInput('be_a_member_text', 'Gia nhập ngay', {
        group: 'searchBoxInBanner',
        width: 'half',
    }),
    fieldInput('the_first_beverage_of_vn_text', 'Hệ thống Nhà phân phối Suntory PesiCo', {
        group: 'searchBoxInBanner',
        width: 'half',
    }),
    fieldSmallFolder('banner_of_homepage_image', 'Tập tin chứa hình ảnh homepage banner', {
        group: 'searchBoxInBanner',
        width: 'col-span-2',
    }),
    fieldInput('attractive_jobs_text', 'Nhiều công việc hấp dẫn đang chờ bạn', {
        group: 'searchBoxInBanner',
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
]
const companyIntroduced = [
    fieldGroup('companyIntroduced', 'Giới thiệu công ty'),
    fieldInput('company_introduced_text', 'Giới thiệu công ty', {
        group: 'companyIntroduced',
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
    fieldInput('provider_quantity_number', 'Số nhà phân phối', {
        group: 'companyIntroduced',
        width: 'col-span-1',
    }),
    fieldInput('provinces_present_number', 'Số tỉnh thành', {
        group: 'companyIntroduced',
    }),
    fieldInput('sales_staff_number', 'Số lượng sales', {
        group: 'companyIntroduced',
    }),
    fieldInput('provider_quantity_text', 'Tỉnh thành phố trên cả nước', {
        group: 'companyIntroduced',
        width: '1/3',
    }),
    fieldInput('provinces_present_text', 'Nhà phân phối chiến lược', {
        group: 'companyIntroduced',
    }),
    fieldInput('sales_staff_text', 'Nhân viên bán hàng', {
        group: 'companyIntroduced',
    }),
]

const whyJoined = [
    fieldGroup('global', 'Tại sao cần gia nhập'),

    fieldSmallFolder('reason_for_joining_image', 'Hình ảnh tại sao nên gia nhập', {
        group: 'global',
        width: 'col-span-2',
    }),
    fieldInput('why_join_text', 'Tiêu đề', {
        group: 'global',
        width: 'col-span-2',
    }),

    fieldInput('commit_company_text', 'Những dẫn chứng và nguyên nhân', {
        group: 'global',
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
    fieldInput('range_salary_number', 'Lương bổng', {
        group: 'global',
        interface: 'input-rich-text-html',
        type: 'mini',
    }),
    fieldInput('work_env_text', 'Môi trường làm việc', {
        group: 'global',
        interface: 'input-rich-text-html',
        type: 'mini',
    }),
    fieldInput('trainning_programs_text', 'Chương trình đào tạo', {
        group: 'global',
        interface: 'input-rich-text-html',
        type: 'mini',
    }),
    fieldInput('opportunity_to_advance_text', 'Cơ hội thăng tiến', {
        group: 'global',
        interface: 'input-rich-text-html',
        type: 'mini',
    }),
]
const highLightMonth = [
    fieldGroup(groups.highLightMonth, 'Nổi bật trong tháng'),
    fieldInput('subtitle_highlight_month_text', 'Nổi bật trong tháng phụ đề', {
        group: groups.highLightMonth,
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
]

const recruitmentList = [
    fieldGroup(groups.recruitmentList, 'Các vị trí đang tuyển dụng'),
    fieldInput('recruitment_list_subtitle_text', 'Các vị trí đang tuyển dụng phụ đề', {
        group: groups.recruitmentList,
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
]

const dynamicWorking = [
    fieldGroup(groups.dynamicWorking, 'Môi trường làm việc năng động'),

    fieldInput('dynamic_working_1_text', 'Môi trường làm việc 1', {
        group: groups.dynamicWorking,
    }),
    fieldInput('dynamic_working_2_text', 'Môi trường làm việc 2', {
        group: groups.dynamicWorking,
    }),
    fieldInput('dynamic_working_3_text', 'Môi trường làm việc 3', {
        group: groups.dynamicWorking,
    }),
    fieldInput('dynamic_working_4_text', 'Môi trường làm việc 4', {
        group: groups.dynamicWorking,
    }),
    fieldInput('dynamic_working_5_text', 'Môi trường làm việc 5', {
        group: groups.dynamicWorking,
    }),
    fieldSmallFolder('dynamic_working_image', 'Hình ảnh môi trường làm việc', {
        group: groups.dynamicWorking,
        width: 'col-span-2',
    }),
    fieldInput('dynamic_working_subtitle_text', 'Môi trường làm việc phụ đề', {
        interface: 'input-rich-text-html',
        group: groups.dynamicWorking,
        width: 'col-span-2',
    }),
]

const feedback = [
    fieldGroup(groups.feedback, 'Nhân viên nói gì về NPP'),
    fieldInput('feedback_subtitle_text', 'Nhân viên nói gì về NPP phụ đề', {
        group: groups.feedback,
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
]

const news = [
    fieldGroup(groups.news, 'Màn hình tin tức'),

    fieldSmallFolder('news_detail_image', 'Hình ảnh ở góc phải tin tức', {
        group: groups.news,
        width: 'col-span-2',
    }),
    fieldInput('news_subtitle_text', 'Tin tức phụ đề', {
        group: groups.news,
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
]

const recruitmentDetailScreen = [
    fieldGroup(groups.recruitmentDetailScreen, 'Màn hình chi tiết tuyển dụng'),
    fieldInput('apply_job_now', 'Ứng tuyển ngay', {
        group: groups.recruitmentDetailScreen,
        interface: 'input-rich-text-html',
        width: 'col-span-2',
    }),
]

const recruitmentListScreen = [
    fieldGroup(groups.recruitmentListScreen, 'Màn hình danh sách tuyển dụng'),
    fieldSmallFolder('banner_recritification_list_image', 'Hình ảnh banner màn hình danh sách việc làm', {
        group: groups.recruitmentListScreen,
        width: 'col-span-2',
    }),
]

const footer = [
    fieldGroup(groups.footer, 'Chân trang'),
    fieldInput('footer_facebook_text', 'Tên facebook', {
        group: groups.footer,
    }),
    fieldInput('footer_facebook_link_text', 'link facebook', {
        group: groups.footer,
    }),
    fieldInput('footer_zalo_text', 'Tên zalo', {
        group: groups.footer,
    }),
    fieldInput('footer_link_zalo_text', 'Link Zalo', {
        group: groups.footer,
    }),
    fieldInput('phone_number_text', 'Số điện thoại hiển thị', {
        group: groups.footer,
    }),
    fieldInput('phone_number_link_text', 'Số điện thoại để gọi', {
        group: groups.footer,
    }),
]

const announcementLink = [
    fieldGroup(groups.announcementLink, 'Link thông báo'),
    fieldInput('announcement_link', 'Thông báo Xử lý dữ liệu cá nhân của Nhà phân phối', {
        group: groups.announcementLink,
        width: 'col-span-2',
    }),
]

const allFields = computed(() => {
    return [
        ...searchBoxInBanner,
        ...companyIntroduced,
        ...whyJoined,
        ...highLightMonth,
        ...recruitmentList,
        ...dynamicWorking,
        ...feedback,
        ...news,
        ...recruitmentDetailScreen,
        ...recruitmentListScreen,
        ...footer,
        ...announcementLink,
    ]
})

defineExpose({
    save,
})
</script>
