<template>
	<div class="v-card-subtitle"><slot /></div>
</template>

<style lang="scss" scoped>
.v-card-subtitle {
	margin-top: -16px;
	padding: 16px;
	padding-top: 0;
}
</style>
