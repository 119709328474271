import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useProcessTrain = defineStore('useProcessTrain', () => {
    const startExam = ref(false)

    return {
        startExam,
    }
})
