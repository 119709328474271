<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            v-model:modelValue="modelValue"
            :disabled="disabled"
            manualSortKey="tel"
            :sort="internalSort"
            :loading="loading"
            @update:sort="updateSort($event)"
            showSelect="multiple"
            @click:row="detailRowOnClick"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->

            <template #[`item.employment_status`]="{ item }">
                <c-status :collectionName="USER_COLLECTION" :id="item.employment_status"></c-status>
            </template>
            <template #[`item.date_of_birth`]="{ item }">
                {{ item.date_of_birth ? dayjs(item.date_of_birth).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.user_type`]="{ item }">
                {{ USER_TYPE_DATA[item.user_type]?.label ?? '' }}
            </template>
            <template #[`item.start_date`]="{ item }">
                {{ item.start_date ? dayjs(item.start_date).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.gender`]="{ item }">
                {{ item.gender?.opt_value }}
            </template>
            <template #[`item.last_working_date`]="{ item }">
                {{ item.last_working_date ? dayjs(item.last_working_date).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.area`]="{ item }">
                {{ item.area?.name }}
            </template>
            <template #[`item.zone`]="{ item }">
                {{ item.zone?.name }}
            </template>
            <template #[`item.route_code`]="{ item }">
                {{ item.route_code?.name }}
            </template>
            <template #[`item.parent_id`]="{ item }">
                {{
                    item.parent_id ? displayParentName(item.parent_id?.role.short_name, item.parent_id?.full_name) : ''
                }}
            </template>
            <template #[`item.reset_password`]="{ item }">
                <v-button
                    :loading="loadingCreatePass[`${item.id}`]"
                    :disabled="loadingCreatePass[`${item.id}`]"
                    class="btn-sm btn-fail"
                    @click.stop="() => resetPassword(item)"
                >
                    Tạo mật khẩu
                </v-button>
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <v-dialog v-model="isShowDialog" @esc="isShowDialog = false" placement="center custom-width">
            <v-card>
                <div class="flex flex-col items-center justify-center px-28px can-copy">
                    <div class="mt-10 mb-6"></div>
                    <div>
                        <div class="text-14px text-[#64748B]">
                            <span class="fw-bold">Họ và tên :</span>
                            {{ dcrSelected?.full_name }}
                        </div>
                        <div class="text-[#64748B]">
                            <span class="text-14px">Tên đăng nhập:</span>
                            <span class="text-20px text-primary-base ml-3 fw-bold">{{ dcrSelected?.code }}</span>
                        </div>
                        <div class="text-[#64748B]">
                            <span class="text-14px">Mật khẩu:</span>
                            <span class="text-20px text-[#ff4f00] ml-3 fw-bold">{{ passswordNew }}</span>
                        </div>
                    </div>
                    <v-card-actions>
                        <v-button secondary @click="isShowDialog = false">Đóng</v-button>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { FORMAT_TIME, USER_TYPE_DATA, USER_TYPE } from '../../../../../shared-fe/utils/constant'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { useUserStore } from '@/stores/user'

const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}
const { updatePassword } = useUserQuery()

const router = useRouter()

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'deleteItem', 'updateSort'])
const loadingCreatePass = ref(<any>[])
const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})
watch(
    () => props.items,
    () => {
        loadingCreatePass.value = []
        props.items.forEach((x: any) => {
            loadingCreatePass.value.push({
                [`${x.id}`]: false,
            })
        })
    },
    {
        immediate: true,
    }
)

const headers = ref([
    {
        text: 'STT',
        value: 'stt',
        width: 90,
    },
    {
        text: 'Vị trí',
        value: 'user_type',
        width: 140,
    },
    {
        text: 'Chi nhánh',
        value: 'zone',
        width: 120,
    },
    {
        text: 'Khu vực',
        value: 'area',
        width: 120,
    },
    {
        text: 'Nhà phân phối',
        value: 'system_providers',
        width: 200,
    },
    {
        text: 'SS',
        value: 'parent_id',
        width: 200,
    },
    {
        text: 'Mã Route',
        value: 'route_code',
        width: 120,
    },
    {
        text: 'Mã DCR',
        value: 'code',
        width: 120,
    },
    {
        text: 'Họ và tên',
        value: 'full_name',
        width: 250,
    },
    {
        text: 'Ngày sinh',
        value: 'date_of_birth',
    },
    {
        text: 'Số điện thoại',
        value: 'phone_number',
        width: 140,
    },
    {
        text: 'Ngày bắt đầu',
        value: 'start_date',
    },
    {
        text: 'Trạng thái',
        value: 'employment_status',
    },
    {
        text: 'Ngày làm việc cuối cùng',
        value: 'last_working_date',
        width: 250,
    },
])

const userStore = useUserStore()
if (userStore.currentUser?.role.short_name == 'ss') {
    headers.value.push({
        text: 'Tạo mật khẩu',
        value: 'reset_password',
    })
}

const detailRowOnClick = (data: any) => {
    router.push({
        name: data?.item?.user_type === USER_TYPE.RETAIL ? PATH_ROUTER.DCR_DETAIL.NAME : PATH_ROUTER.WSR_DETAIL.NAME,
        params: {
            id: data.item.id,
        },
    })
}

const convertKeySort = (sortKey, desc) => {
    let key = ''
    switch (sortKey) {
        case 'zone':
            key = 'zone.name'
            break
        case 'area':
            key = 'area.name'
            break
        case 'system_providers':
            key = 'system_providers.provider_id.name'
            break
        case 'parent_id':
            key = 'parent_id.full_name'
            break
        default:
            key = sortKey
            break
    }
    if (desc) key = `-${key}`
    return key
}
const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    if (sort?.by !== 'stt') {
        emit('updateSort', convertKeySort(sort?.by, sort?.desc))
    }
}
const passswordNew = ref()
const isShowDialog = ref(false)
const dcrSelected = ref(<any>{})

const generateRandomPassword = () => {
    let password = ''
    for (let i = 0; i < 6; i++) {
        const randomDigit = Math.floor(Math.random() * 10) // Số ngẫu nhiên từ 0 đến 9
        password += randomDigit.toString() // Chuyển số thành chuỗi và thêm vào mật khẩu
    }
    return password
}
const resetPassword = async (item, e) => {
    loadingCreatePass.value[`${item.id}`] = true
    passswordNew.value = generateRandomPassword()
    await updatePassword(item?.id, passswordNew.value)
    dcrSelected.value = item
    setTimeout(() => {
        loadingCreatePass.value[`${item.id}`] = false
        isShowDialog.value = true
    }, 500)
}

// const changeManualSort = (data) => {
//     // when update position (required field ID in the column)
//     // call api update position
// }
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
