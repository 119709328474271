<template>
    <c-role :canAccessPage="true">
        <private-view :title="titlHeader">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <!-- navigation end  -->
            <!-- header start -->
            <template #actions>
                
                <span class="" v-if="modelSelected?.length > 0 && channels?.length > 0 && keyNumber === 2">
                    <v-button
                        class="start-to-learn mr-5"
                        v-if="modelSelected?.length > 0"
                        outlined
                        secondary
                        @click="handleShowDialogChooseChannel()"
                    >
                        <span class="text-[#5BABAC]">{{ START_TO_TRAIN }}</span>
                    </v-button>
                    <v-button
                        :loading="postLoading.postLoading.loading"
                        class="mr-5"
                        v-if="modelSelected?.length > 0"
                        :secondary="postLoading.postLoading.loading"
                        rounded
                        icon
                        @click="isShowTrainConfirm = true"
                    >
                        <v-icon name="arrow_forward" />
                    </v-button>
                </span>
            </template>
            <div v-if="channels?.length > 0" class="border-l-0 border-r-0 border-b-1 border-t-1 border-[#E2E8F0] border-solid p-3">
                <span class="custom-button-btn flex overflow-x-scroll">
                    <v-button v-for="item in channels"  @click="() => goToModuleList(item.id)" class="learning-list mr-5">
                        <v-icon class="" name="library_books" />
                        <span class="ml-2">{{  item.name  }}</span>
                    </v-button>
                </span>
            </div>
            <div class="bg-[#f8fafc] py-[30px] md:px-9 px-4">
                <list-sessions-train-search
                    v-if="keyNumber === 3 || keyNumber === 4"
                    @update:handleSearch="handleSearch"
                    :key-number="keyNumber"
                />
                <div class="tab-header py-10">
                    <div class="grid grid-cols-1 lg:grid-cols-4 w-full">
                        <div v-for="(item, index) in tabList" class="col-span-1">
                            <button
                                :key="index"
                                @click="openTab(item.key)"
                                :class="
                                    keyNumber === item.key
                                        ? `bg-[#1E4BA1] text-white tab-button-${item.key}`
                                        : `tab-button-${item.key} bg-white text-[#64748B]`
                                "
                                class="border h-full border-solid py-3 font-medium border-[#CBD5E1] px-4 w-full"
                            >
                                {{ item.title }}
                            </button>
                        </div>
                    </div>
                    <div class="w-full" v-if="keyNumber === 1"><onboarding-regulations /></div>
                    <div class="w-full" v-if="keyNumber === 2">
                        <list-tranning
                            :items="itemsRender"
                            :modelSelected="modelSelected"
                            @update:modelSelected="modelSelectedFun"
                            :totalPages="totalPages"
                            :page="page"
                            @update:toPage="handleToPage"
                            @updateSort="handleUpdateSort"
                            :loading="loading"
                        />
                    </div>
                    <div class="w-full" v-if="keyNumber === 3">
                        <list-tranning-session :filter="filterSessions" :search="searchSessions" />
                    </div>
                    <div class="w-full" v-if="keyNumber === 4">
                        <list-exemption :search="searchSessions" :filter="filterSessions" />
                    </div>
                    <div class="w-full" v-if="keyNumber === 5">
                        <list-exemption />
                    </div>
                </div>
            </div>

            <modal-choose-channel
                :is-show="isShowTrainConfirm"
                @submit="(data: any) => handleTrain(data)"
                @close="isShowTrainConfirm = false"
            ></modal-choose-channel>

            <template #sidebar>
                <sidebar-detail icon="info" :title="''" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useItems, useStores } from '@directus/composables'
import { computed, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../shared-fe/composables/use-batch'
import { RECORD_PER_PAGE, LIST_TITLE_TAB, RESPONSE_STATUS, TYPE_OF_TRAIN, EMPLOYMENT_STATUS } from '../../../../shared-fe/utils/constant'
import { START_TO_TRAIN } from '../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import { USER_COLLECTION } from '../../../../shared-fe/utils/table-name'
import listTranningSession from './components/list-tranning-session.vue'
import listTranning from './components/list-tranning.vue'
import onboardingRegulations from './components/onboarding-regulations.vue'
import { useOnboardingDcr } from '../../../../shared-fe/services/onboard-dcr-service'
import { useTrainingQuery } from '../../../../shared-fe/services/training-service'
import ListSessionsTrainSearch from './components/list-sessions-train-search.vue'
import { useStoreLoading } from '../../../../shared-fe/stores/loading'
import listExemption from './components/list-exemption.vue'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import modalChooseChannel from './components/modal-choose-channel.vue'
import { useUserStore } from '@/stores/user'

const route = useRoute()
const router = useRouter()
const { modelSelected } = useBatch(USER_COLLECTION)
const { postItemSessionsTrains } = useOnboardingDcr()
const { getModules } = useTrainingQuery()
const filter = ref(<any>null)
const filterSessions = ref(<any>null)
const search = ref(null)
const searchSessions = ref(null)
const sort = ref(['-id'])
const page = ref(1)
const isShowTrainConfirm = ref(false)
const postLoading = useStoreLoading()
const { useAppStore } = useStores()
const confirmModal = useConfirmModal()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
const userStore = useUserStore(); 

const tabList = computed(() => {
    return  LIST_TITLE_TAB.map(x => {
        return {
            ...x, 
            title: x.title.replace("DCR", userStore?.currentUser?.label_user_type)
        }
    })
}) 
const customFilter = computed(() => {
    let _filter = filter.value
    _filter = {
        role: {
            short_name: 'dcr',
        },
        user_type: userStore.currentUser.user_type,
        onboarding_status: 'pending',
        employment_status: {
            _neq: EMPLOYMENT_STATUS.OFF
        },
    }
    return _filter
})

const channels = ref(userStore.currentUser[TYPE_OF_TRAIN.OB]);

const { items, totalPages, loading } = useItems(ref('directus_users'), {
    fields: ref([
        'id',
        '*',
        'full_name',
        'zone.*',
        'route_code.name',
        'code',
        'system_providers.provider_id.name',
        'phone_number',
        'parent_id.full_name',
        'parent_id.role.short_name',
        'email',
        'area.name',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search,
    filter: customFilter,
    page: page,
})
const itemsRender = computed(() => {
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    return items.value.map((x) => {
        index++
        return {
            ...x,
            stt: index,
            area_name: x?.area?.name,
            zone: x?.zone?.name,
            route_name: x?.route_code?.name,
            system_providers: x.system_providers?.length > 0 ? x.system_providers[0].provider_id?.name : '',
        }
    })
})
const keyNumber = ref(1)

const isName = '2'
// actions end
const titlHeader = computed(() => {
    if (route.fullPath === PATH_ROUTER.LD_LEARNING_DETAIL.PATH) {
        return `${userStore?.currentUser?.label_user_type} Onboarding`
    }
    return ''
})

const primaryKeys = computed(() => {
    return modelSelected.value?.map((x) => x.id) || []
})

const openTab = (key: number) => {
    keyNumber.value = key
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}

const handleToPage = (_toPage: number) => {
    page.value = _toPage
}

const handleTrain = async (channel: any) => {
    isShowTrainConfirm.value = false
    if (modelSelected.value.length > 0) {
        let data = modelSelected.value.map((item) => item.id)
        let res = await postItemSessionsTrains({ ids: data, channel_id: channel })
        if (res?.data?.code === RESPONSE_STATUS.SUCCESS) {
            let dataResponse = await getModules(res.data?.id)
            let modules = dataResponse?.data?.data?.course?.topics;
            if (modules?.length > 0) {
                router.push({
                    name: PATH_ROUTER.PROCESS_MODULE_TRAIN.NAME,
                    params: {
                        session_train: res.data?.id,
                        module_id: modules[0].id,
                    },
                })
            }
        } else {
            confirmModal.handleOpenWarningModal(res?.data?.message)
        }
    }
}

const handleSearch = (_data: any) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _and: any[] = []
    if (data.title) {
        _and = [
            {
                _or: [
                    {
                        user: {
                            directus_users_id: {
                                full_name: {
                                    _contains: data.title,
                                },
                            },
                        },
                    },
                    {
                        user: {
                            directus_users_id: {
                                phone_number: {
                                    _contains: data.title,
                                },
                            },
                        },
                    },
                    {
                        user: {
                            directus_users_id: {
                                personal_email: {
                                    _contains: data.title,
                                },
                            },
                        },
                    },
                    {
                        user: {
                            directus_users_id: {
                                code: {
                                    _contains: data.title,
                                },
                            },
                        },
                    },
                    {
                        user: {
                            directus_users_id: {
                                route_code: {
                                    name: {
                                        _contains: data.title,
                                    },
                                },
                            },
                        },
                    },
                    {
                        user: {
                            directus_users_id: {
                                id_card_number: {
                                    _contains: data.title,
                                },
                            },
                        },
                    },
                ],
            },
        ]
    }
    let _filter: any = {
        _and: [
            ..._and,
            {
                status: data.status || null,
            },
        ],
    }
    if (!data.status) {
        _filter = {
            _and: [..._and],
        }
    }

    filterSessions.value = _filter
}
const goToModuleList = (id: string) => {
    router.push({
        name: PATH_ROUTER.MODULE_LIST.NAME,
        params: {
            channel: id,
        },
    })
}

const handleShowDialogChooseChannel = () => {
    if(channels.value.length > 1){
        isShowTrainConfirm.value = true
    }else{
        handleTrain(channels.value[0].id)
    }
}
</script>

<style lang="scss" scoped>
.tab-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tab-button {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
}
.tab-button-1 {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.tab-button-4 {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.tab-button-item {
    background-color: aliceblue;
    .button {
        background: white !important;
    }
}

.start-to-learn {
    .outlined {
        font-weight: 500;
    }
}
</style>
