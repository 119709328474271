<template>
    <div class="table-hand-made">
        <div v-if="item" class="mx-9 mb-12 table-dcr-history box-table">
            <div class="grid grid-cols-2 items-center">
                <div class="col-span-1 mb-4 info-request font-bold text-[var(--foreground-normal-alt)] text-6">
                    Kết quả KPI 3 tháng gần nhất
                </div>
            </div>

            <div class="grid bg-[#1E4BA1] rounded-t-lg text-white grid-cols-5">
                <div class="col-span-1 border p-4">Kết quả KPI 3 tháng trước khi nghỉ</div>
                <div class="col-span-1 border p-4">{{ canWSR ? 'Combo' : 'Hero SKU' }} (% đạt)</div>
                <div class="col-span-1 border p-4">EBD (% đạt)</div>
                <div class="col-span-1 border p-4">ASO Coverage (% đạt)</div>
                <div class="col-span-1 p-4">Trưng bày (%)</div>
            </div>
            <div :style="{ borderBottom: '1px solid #E2E8F0' }" class="grid bg-white grid-cols-1">
                <div class="grid grid-cols-5">
                    <div class="col-span-1 p-4">Tháng thứ 1</div>
                    <div class="col-span-1 p-4">{{ item.hero_sku_one }}{{ addPercentUnit(item.hero_sku_one) }}</div>
                    <div class="col-span-1 p-4">{{ item.ebd_one }}{{ addPercentUnit(item.ebd_one) }}</div>
                    <div class="col-span-1 p-4">{{ item.aso_one }}{{ addPercentUnit(item.aso_one) }}</div>
                    <div class="col-span-1 p-4">{{ item.display_one }}{{ addPercentUnit(item.display_one) }}</div>
                </div>
            </div>
            <div :style="{ borderBottom: '1px solid #E2E8F0' }" class="grid bg-white grid-cols-1">
                <div class="grid grid-cols-5">
                    <div class="col-span-1 p-4">Tháng thứ 2</div>
                    <div class="col-span-1 p-4">{{ item.hero_sku_two }}{{ addPercentUnit(item.hero_sku_two) }}</div>
                    <div class="col-span-1 p-4">{{ item.ebd_two }}{{ addPercentUnit(item.ebd_two) }}</div>
                    <div class="col-span-1 p-4">{{ item.aso_two }}{{ addPercentUnit(item.aso_two) }}</div>
                    <div class="col-span-1 p-4">{{ item.display_two }}{{ addPercentUnit(item.display_two) }}</div>
                </div>
            </div>
            <div :style="{ borderBottom: '1px solid #E2E8F0' }" class="grid bg-white grid-cols-1">
                <div class="grid grid-cols-5">
                    <div class="col-span-1 p-4">Tháng thứ 3</div>
                    <div class="col-span-1 p-4">{{ item.hero_sku_three }}{{ addPercentUnit(item.hero_sku_three) }}</div>
                    <div class="col-span-1 p-4">{{ item.ebd_three }}{{ addPercentUnit(item.ebd_three) }}</div>
                    <div class="col-span-1 p-4">{{ item.aso_three }}{{ addPercentUnit(item.aso_three) }}</div>
                    <div class="col-span-1 p-4">{{ item.display_three }}{{ addPercentUnit(item.display_three) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { useAppPermissions } from '@/shared-fe/composables/use-app-permissions'

const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})

const { item } = toRefs(props)
const { canWSR } = useAppPermissions()
const dataDefault = {
    id: -1,
    full_name: '',
    exemption_case: '',
    resign_before: '',
    total_seniority: '',
    route_old: '',
    route_name: '',
    reason: '',
    hero_sku_one: '',
    hero_sku_two: '',
    hero_sku_three: '',
    ebd_one: '',
    ebd_two: '',
    ebd_three: '',
    aso_one: '',
    aso_two: '',
    aso_three: '',
    display_one: '',
    display_two: '',
    display_three: '',
}

const addPercentUnit = (data: string) => {
    if (!data) {
        return ''
    }
    const REGEX_NUMBER = /[1-9]/
    if (String(data).includes('%')) {
        return ''
    } else if (!REGEX_NUMBER.test(String(data))) {
        return ''
    }
    return '%'
}

const formData = ref(JSON.parse(JSON.stringify(dataDefault)))

const { canAccess } = usePermission()
const fullAccess = computed(() => {
    return canAccess([USER_ROLE.ADMIN_L1, USER_ROLE.ADMIN_L2])
})
</script>

<style lang="scss" scoped>
.table-dcr-history {
    .dropdown-sort {
        .v-input .input {
            border: none !important;
            &.active {
                box-shadow: none;
            }
        }
    }
}
</style>
