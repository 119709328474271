import { useApi } from '@directus/extensions-sdk'

export const useAreaQuery = () => {
    const api = useApi()

    const getAllAreaItem = async () => {
        return await api.get('/items/areas', {
            params: {
                limit: -1,
                fields: ['*'],
                filter: {
                    zone: {
                        _null: true,
                    },
                },
            },
        })
    }

    const getAllAreaAndZoneItem = async () => {
        return await api.get('/items/areas', {
            params: {
                limit: -1,
                fields: ['*'],
            },
        })
    }

    return {
        getAllAreaItem,
        getAllAreaAndZoneItem,
    }
}
