import { useApi } from '@directus/extensions-sdk'
import { CANDIDATE_COLLECTION } from '../utils/table-name'

export const useCandidateQuery = () => {
    const api = useApi()
    const getItemCandidate = async (id: any) => {
        return await api.get(`/items/${CANDIDATE_COLLECTION}/${id}`, {
            params: {
                fields: ['*', 'refer.*', 'status.opt_key'],
            },
        })
    }

    const patchItemCandidate = async (id: number, params: any) => {
        return await api.patch(`/items/${CANDIDATE_COLLECTION}/${id}`, params)
    }

    const postItemCandidate = async (params: any) => {
        return await api.post(`/items/${CANDIDATE_COLLECTION}`, params)
    }

    const postSendMailUserPool = async (candidateId: number, templateName: string) => {
        return await api.post(`/api-hr/candidate/send_mail_specify`, {
            id: candidateId,
            template: templateName,
        })
    }

    return {
        getItemCandidate,
        patchItemCandidate,
        postItemCandidate,
        postSendMailUserPool,
    }
}
