import { defineStore } from 'pinia'
import { onMounted, ref } from 'vue'
import { useProvinceQuery } from '../services/province-service'
import { useAreaQuery } from '../services/area-service'
import { useBankQuery } from '../services/bank-service'

export const useStoreCommon = defineStore('useStoreCommon', () => {
    const provinceDistrictList = ref([])
    const districtList = ref([])
    const provinceList = ref([])
    const areaList = ref([])
    const bankList = ref([])
    const loading = ref(false)
    const error = ref('')
    const submitEvent = (_payload: any) => { }
    const _useProvinceQuery = useProvinceQuery()
    const _useAreaQuery = useAreaQuery()
    const _useBankQuery = useBankQuery()
    const showDialogSucessUpdate = ref(false)
    const titleDialog = ref('')

    onMounted(() => {
        getProvincesDistrict().catch((e: Error) => { })
        getAreas().catch((e: Error) => { })
        getBanks().catch((e: Error) => { })
    })

    const getDistrictByProvinceId = (parentId: number) => {
        return districtList.value.filter((x: any) => x.parent_id === parentId)
    }

    const getProvincesDistrict = async () => {
        if (provinceDistrictList.value[0] ?? false) return
        _useProvinceQuery
            .getAllProvinceItem()
            .then((res) => {
                provinceDistrictList.value = res.data.data
                provinceList.value = res.data.data.filter((x: any) => !x.parent_id)
                districtList.value = res.data.data.filter((x: any) => x.parent_id)
            })
            .catch((e) => { })
    }

    const getAreas = async () => {
        if (areaList.value[0] ?? false) return
        _useAreaQuery
            .getAllAreaItem()
            .then((res) => {
                areaList.value = res.data.data
            })
            .catch((e) => { })
    }

    const getBanks = async () => {
        if (bankList.value[0] ?? false) return
        _useBankQuery
            .getAllBankItem()
            .then((res) => {
                bankList.value = res.data.data
            })
            .catch((e) => { })
    }

    return {
        provinceDistrictList,
        provinceList,
        areaList,
        bankList,
        districtList,
        showDialogSucessUpdate,
        titleDialog,
        loading,
        error,
        getDistrictByProvinceId,
        submitEvent,
    }
})
