import { defineInterface } from '@directus/utils';
import appQuickCreateRoom from './app-quick-create-room.vue';
import PreviewSVG from './preview.svg?raw';

export default defineInterface({
	id: 'app-quick-create-room',
	name: 'App tạo phòng thi nhanh',
	description: '',
	icon: 'arrow_right_alt',
	component: appQuickCreateRoom,
	types: ['alias'],
	localTypes: ['presentation'],
	preview: PreviewSVG,
	options: null
});
