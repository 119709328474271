<template>
    <div class="items-center mb-6">
        <template v-if="interview.ride_step === 'completed'">
            <div class="text-center flex flex-col gap-5 justify-center items-center pt-6">
                <div
                    style="--v-icon-size: 60px"
                    class="w-30 h-30 mb-5 rounded-full bg-blue-50 text-green-600 flex items-center justify-center"
                >
                    <v-icon name="check "></v-icon>
                </div>
                <div>
                    Đã hoàn thành Route Ride
                    <div class="mt-3">Ngày: {{ formatDate(interview.date_completed_ride) }}</div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="inline-block mb-8 px-2 py-1 text-[#1D4ED8] border border-solid border-[#1D4ED8] rounded-2">
                Route Ride - YCTD #{{ request_recruitment_id.length > 0 ? request_recruitment_id[0] : '' }}
            </div>

            <div v-for="(item, index) in route_ride">
                <div v-if="index + 1 === active">
                    <template v-if="item.type === 'pdf'">
                        <div class="mb-1">
                            <a target="_blank" :href="item.content_url">Xem toàn màn hình</a>
                        </div>
                        <div class="w-full p-6 border border-solid border-primary-base rounded-2">
                            <iframe :src="item.content_url" class="w-full aspect-1" frameborder="0" />
                        </div>
                    </template>

                    <div v-if="item.type === 'ppt'">
                        <iframe
                            class="w-full aspect-video object-contain"
                            :src="`https://view.officeapps.live.com/op/embed.aspx?src=${displayLink(
                                item.content_url
                            )}?ui=vi-VN&rs=vi-VN`"
                        ></iframe>
                    </div>

                    <div v-if="item.type === 'video'">
                        <c-youtube
                            ref="frameYoutube"
                            class="w-full aspect-video object-contain"
                            :url="item.content_url"
                            :start="startTime"
                            @ended="videoEnded = true"
                        ></c-youtube>
                        <!-- <c-player
                            :autoplay="true"
                            ref="frameYoutube"
                            @ended="videoEnded = true"
                            :src="src(item.content_url)"
                            :time="startTime"
                        ></c-player> -->
                    </div>

                    <div v-if="item.type === 'text'" class="custom-content" v-html="item.content"></div>

                    <div v-if="showNext">
                        <div class="flex justify-center gap-16 mt-16 mb-8 items-center">
                            <div class="flex items-center cursor-pointer gap-3 w-[140px]" @click="handlePrevFile">
                                <v-icon v-show="active > 1" name="arrow_back" />
                                <div v-show="active > 1" class="ml-2">File trước</div>
                            </div>
                            <v-button :disabled="isDisabledToCompleteRR" class="custom-btn" secondary @click="Next">
                                Hoàn thành đào tạo
                            </v-button>
                            <div class="flex items-center cursor-pointer gap-3 w-[140px]" @click="handleNextFile">
                                <div v-show="active < route_ride.length" class="ml-2">File tiếp theo</div>
                                <v-icon v-show="active < route_ride.length" name="arrow_forward" />
                            </div>
                        </div>

                        <div class="flex justify-center">Hoàn thành sau:</div>
                        <div class="flex justify-center">{{ handleFormatTimeFunc() }}</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup lang="ts">
// import CPlayer from '@/shared-fe/components/c-video/c-player.vue'
import CYoutube from '@/shared-fe/components/c-youtube/index.vue'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import { isFullScreen, toggleFullScreen } from '@/shared-fe/utils/convert-data'
import { linkFilesToView } from '@/shared-fe/utils/link-files-to-view'
import { useApi } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import dayjs from 'dayjs'
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { CONFIRM_MODAL_TEXT, FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
const api = useApi()

const emit = defineEmits(['change'])
const props = defineProps({
    request_recruitment_id: {
        type: Array<String>,
        default: '',
    },
    interview: {},
})
const { useSettingsStore } = useStores()
const appStore = useSettingsStore()
const confirmModal = useConfirmModal()
const { settings } = toRefs(appStore)
const isPaused = ref(false)
const route = useRoute()
const id = route?.params?.id
const route_ride = computed(() => {
    return settings.value.route_ride
})

const active = ref(1)
const timeLeft = ref(+settings.value.route_ride_durations ?? 1)
const btnStatusPDF = ref(true)
const intervalId = ref()
const showNext = ref(true)
const frameYoutube = ref(<any>null)
const videoEnded = ref(false)
const startTime = ref(<number>0)

const isDisabledToCompleteRR = computed(() => {
    if (btnStatusPDF.value) {
        return true
    } else {
        return false
    }
})

const handleFormatTimeFunc = () => {
    if (timeLeft.value === 0) {
        return '00:00'
    }
    if (timeLeft.value && timeLeft.value > 0) {
        const hoursLeft = Math.floor(timeLeft.value / 3600)
        const minutesLeft = Math.floor((timeLeft.value - hoursLeft * 3600) / 60)
        const secondsLeft = timeLeft.value - hoursLeft * 3600 - minutesLeft * 60
        return `${minutesLeft.toString().padStart(2, '0')} : ${secondsLeft.toString().padStart(2, '0')}`
    }
}
const handleCountDown = () => {
    timeLeft.value = Math.round(timeLeft.value)

    intervalId.value = setInterval(() => {
        if (timeLeft.value > 0) {
            if (!isPaused.value) {
                timeLeft.value = timeLeft.value - 1
            }
        } else {
            btnStatusPDF.value = false
            clearInterval(intervalId.value)
            return
        }
    }, 1000)
}

const Next = async () => {
    if (isFullScreen()) {
        toggleFullScreen()
    }

    handleConfirm(
        CONFIRM_MODAL_TEXT.ROUTE_RIDE.COMPLETE,
        async () => {
            await handleRouteRide()
            showNext.value = false
            localStorage.removeItem('ROUTE_RIDE_' + id)
        },
        true
    )
}

const handlePrevFile = () => {
    if (active.value >= 1) {
        active.value = active.value - 1
    }
}

const handleNextFile = () => {
    if (active.value < route_ride.value.length) {
        active.value++
    }
}

const handleConfirm = (title: string, action: () => void, showCLoseBtn: boolean) => {
    confirmModal.handleOpenConfirmModal(title || '', action, showCLoseBtn)
}

const handleRouteRide = async () => {
    const params: any = {
        date_completed_ride: new Date().toISOString(),
        ride_step: 'completed',
    }
    await api.patch(`/items/interview_round/${props.interview.id}`, params)
    emit('change')
}

const src = (data: string) => {
    if (data) return `youtube/${extractYouTubeVideoId(data)}`
    return linkFilesToView(data as string)
}

function extractYouTubeVideoId(link) {
    const regex = /\/embed\/([^\/?]+)/
    const match = regex.exec(`${link}?dad=3`)
    if (match && match[1]) {
        return match[1] // Trả về ID video từ nhóm con thứ nhất
    } else {
        return null // Trả về null nếu không tìm thấy hoặc không hợp lệ
    }
}

const reduceTimer = () => {
    timeLeft.value = 1
    videoEnded.value = true
}

onBeforeUnmount(() => {
    document.removeEventListener('reduce-timer', reduceTimer)
    window.removeEventListener('visibilitychange', visibilityChangeVideo)
    window.removeEventListener('blur', blurVideo)
})

function visibilityChangeVideo() {
    if (document.hidden) {
        frameYoutube?.value[0]?.rundCommand('pauseVideo')
        // frameYoutube.value[0]?.pause()
        localStorage.setItem('ROUTE_RIDE_' + id, frameYoutube.value[0]?.getCurrentTime())
        isPaused.value = true
        handleConfirm(
            CONFIRM_MODAL_TEXT.ROUTE_RIDE.CONTINUE_WATCH_VIDEO,
            async () => {
                isPaused.value = false
                frameYoutube?.value[0]?.rundCommand('playVideo')
                // frameYoutube.value[0]?.play()
            },
            false
        )
    }
}

function blurVideo() {
    frameYoutube?.value[0]?.rundCommand('pauseVideo')
    // frameYoutube.value[0]?.pause()
    localStorage.setItem('ROUTE_RIDE_' + id, frameYoutube.value[0]?.getCurrentTime())
    isPaused.value = true
    handleConfirm(
        CONFIRM_MODAL_TEXT.ROUTE_RIDE.CONTINUE_WATCH_VIDEO,
        async () => {
            isPaused.value = false
            frameYoutube?.value[0]?.rundCommand('playVideo')
            // frameYoutube.value[0]?.play()
        },
        false
    )
}

window.addEventListener('visibilitychange', visibilityChangeVideo)
window.addEventListener('blur', blurVideo)

onMounted(() => {
    handleCountDown()
    document.addEventListener('reduce-timer', reduceTimer)
})

onUnmounted(() => {
    clearInterval(intervalId.value)
})

const formatDate = (date) => {
    return dayjs(date).format(FORMAT_TIME.DDMMYYYY)
}

const displayLink = (idFile) => {
    // return `https://spvb-dcr.codihaus.io/assets/0dd2e441-94af-4cda-bbd1-dc23c22df11e`
    return `${window.location.origin}/assets/${idFile}`
}

const handleForcePlayVideoIfSafariBrowser = () => {
    // frameYoutube.value[0]?.play()
    frameYoutube?.value[0]?.rundCommand('playVideo')
}

watch(
    () => active.value,
    () => {
        if (route_ride.value && route_ride.value.length > 0) {
            const stepModel = route_ride.value[active.value - 1]
            if (stepModel.type === 'video') {
                videoEnded.value = false
                startTime.value = Number(localStorage.getItem('ROUTE_RIDE_' + id))
            } else {
                videoEnded.value = true
            }
        }
    },
    {
        immediate: true,
    }
)

defineExpose({ handleForcePlayVideoIfSafariBrowser })
</script>

<style lang="scss">
.custom-btn {
    .button {
        &:disabled {
            background-color: #b0dedf !important;
            border-color: #b0dedf !important;
        }

        background-color: var(--primary) !important;
        border-color: var(--primary) !important;
        color: white !important;
    }
}

.custom-content {
    p {
        margin-bottom: 10px;
    }
}
.ytp-endscreen-content {
    display: none;
}
</style>
