import { useApi } from '@directus/extensions-sdk'
import { USER_ACTIVITIES_COLLECTION } from '../utils/table-name'
export const useUserActivitiesQuery = () => {
    const api = useApi()
    const getUserActivites = async (limit: number, idUser: number, currentPage: number, sort: string) => {
        return await api.get(`/items/${USER_ACTIVITIES_COLLECTION}`, {
            params: {
                limit: limit,
                page: currentPage,
                sort: sort,
                fields: ['date_created', 'content', 'user_created.full_name', 'user_created.role.name'],
                meta: ['filter_count'],
                filter: {
                    user: {
                        id: idUser,
                    },
                },
            },
        })
    }

    return {
        getUserActivites,
    }
}
