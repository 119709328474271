<template>
    <c-role>
        <private-view :title="objRecruitmentDetail?.title">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.RECRUITMENT_LIST.VALUE,
                            to: PATH_ROUTER.RECRUITMENT_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <c-access :roles="[]">
                    <v-dialog v-if="objRecruitmentDetail" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <v-icon name="delete" outline />
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <c-access :roles="[USER_ROLE.HR]">
                    <div class="box-save">
                        <v-button v-tooltip.bottom="SAVE" icon rounded @click="save">
                            <v-icon name="check" />
                        </v-button>
                    </div>
                </c-access>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->
            <recruitment-detail :item="objRecruitmentDetail" />
            <!--  content end -->

            <template #sidebar>
                <sidebar-detail icon="info" :title="objRecruitmentDetail?.title" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { useRecruitmentQuery } from '../../../../../shared-fe/services/recruitment-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { MODE_EDIT, SUBMIT_EVENT, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { CANCEL, CONTENT_DELETE, LABEL_DELETE, SAVE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { RECRUITMENT_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import RecruitmentDetail from '../components/recruitment-detail.vue'
import { RecruitmentDetailModel } from '../types'

const commonStore = useStoreCommon()

const route = useRoute()
const objRecruitmentDetail = ref(<RecruitmentDetailModel>{})
const { getItemRecruitment } = useRecruitmentQuery()
onBeforeUnmount(() => {})

// get data detail (start)
onMounted(async () => {
    if (route?.params?.id) {
        await getDetail()
    }
})

const getDetail = async () => {
    let res = await getItemRecruitment(route?.params?.id.toString())
    objRecruitmentDetail.value = res?.data?.data
}

// get data detail (end)

// handle logic header (start)
const router = useRouter()
const save = () => {
    // const a: any = recruitmentDetailRef.value
    commonStore.submitEvent({
        name: SUBMIT_EVENT.RECRUITMENT_DETAIL_SUBMIT,
        payload: MODE_EDIT,
    })
}

const navigateBack = () => {
    router.push({
        name: PATH_ROUTER.RECRUITMENT_LIST.NAME,
    })
}

const batchDeleteFun = async () => {
    await batchDelete('status', objRecruitmentDetail.value.id)
    router.push({
        name: PATH_ROUTER.RECRUITMENT_LIST.NAME,
    })
}

const { confirmDelete, deleting, batchDelete, error: deleteError } = useBatch(RECRUITMENT_COLLECTION)
// handle logic header (end)
</script>

<style lang="scss" scoped></style>
