<template>
    <div class="mb-9">
        <c-expand-search
            :dataBasic="BASIC_SEARCH"
            :dataExpand="EXPAND_SEARCH"
            @update:model-value="handleSearch"
            @changeItem="changeItem"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import CExpandSearch from '../../../../../shared-fe/components/c-expand-search/c-expand-search.vue'
import { ComponentPropsModel } from '../../../../../shared-fe/types/table-type'
import { PROVINCE, RANGE_DATE_PLACEHOLDER } from '../../../../../shared-fe/utils/message'
import { useProvinceQuery } from '../../../../../shared-fe/services/province-service'
import { useOptionQuery } from '../../../../../shared-fe/services/option-service'
import { sortDataSelection } from '../../../../../shared-fe/utils/convert-data'
import { useField } from '@/shared-fe/composables/use-field'
import { CANDIDATE_COLLECTION } from '@/shared-fe/utils/table-name'
import { useUserStore } from '@/stores/user'
const props = defineProps({
    arrayStatus: {
        type: Array<Object>,
        default: [],
    },
    arrayValue: {
        type: Object,
        default: {},
    },
})
const emit = defineEmits(['update:handleSearch'])
const { getAllProvinceItem } = useProvinceQuery()
const { getDocumentCandidateStatus } = useField()
const userStore: any = useUserStore()
const arrProvince = ref(<any>[])
const arrEducationLevel = ref(<any>[])
const arrCandidateSource = ref(<any>[])
const arrCandidateStatus = ref(<any>[])
const arrProvinceAnDistricts = ref(<any>[])
const dataModel = ref(<any>props.arrayValue)

getAllProvinceItem().then((res) => {
    let _arrProvince: any = []
    let _arrProvinceAnDistricts: any = []
    if (res.data.data) {
        res.data.data.forEach((x) => {
            if (!x.parent_id) {
                _arrProvince.push({
                    text: x.name,
                    value: x.id,
                })
            }
            _arrProvinceAnDistricts.push(x)
        })
    }
    arrProvince.value = sortDataSelection(_arrProvince)
    arrProvinceAnDistricts.value = _arrProvinceAnDistricts
})

onMounted(async () => {
    const options = userStore.currentUser.options
    let _arrEducationLevel: any = []
    let _arrCandidateSource: any = []
    let _arrCandidateStatus: any = []
    let objData: any = {}
    options.forEach((x) => {
        objData = {
            text: x.opt_value,
            value: x.id,
        }
        if (x.opt_group === 'education_level') {
            _arrEducationLevel.push(objData)
        } else if (x.opt_group === 'candidate_source') {
            _arrCandidateSource.push(objData)
        } else if (x.opt_group === 'candidate_status') {
            _arrCandidateStatus.push(objData)
        }
    })
    arrCandidateSource.value = sortDataSelection(_arrCandidateSource)
    arrCandidateStatus.value = sortDataSelection(_arrCandidateStatus)
    arrEducationLevel.value = sortDataSelection(_arrEducationLevel)
})

const arrDistrict = computed(() => {
    let _arrDistrict: any = []

    if (dataModel.value.province_id) {
        arrProvinceAnDistricts.value.forEach((x) => {
            if (dataModel.value.province_id === x.parent_id) {
                _arrDistrict.push({
                    text: x?.name,
                    value: x?.id,
                })
            }
        })
    }
    return sortDataSelection(_arrDistrict)
})

watch(
    () => dataModel.value.province_id,
    () => {
        dataModel.value.district_id = ''
    }
)

watch(
    () => props.arrayValue,
    () => {
        dataModel.value = props.arrayValue || {}
    }
)

const arrStatusArchived = computed(() => {
    let data = getDocumentCandidateStatus(CANDIDATE_COLLECTION)
    return [{ text: '', value: null }, ...data]
})

const BASIC_SEARCH: ComponentPropsModel[] = [
    {
        key: 'title',
        value: dataModel.value.title,
        label: '',
        placeholder: 'Nhập Tên/ Điện thoại/ Email ...',
        type: 'v-input',
        icon: 'search',
    },
    {
        key: 'date_created',
        value: dataModel.value.date_created,
        label: '',
        placeholder: RANGE_DATE_PLACEHOLDER,
        type: 'c-range-datetime',
        data: dataModel.value.date_created || null,
    },
]

const EXPAND_SEARCH: any = computed(() => {
    return [
        {
            key: 'province_id',
            value: dataModel.value.province_id,
            label: PROVINCE,
            placeholder: PROVINCE,
            type: 'v-select',
            icon: 'search',
            data: arrProvince.value,
        },
        {
            key: 'district_id',
            value: dataModel.value.district_id,
            label: 'Quận huyện',
            placeholder: 'Quận huyện',
            type: 'v-select',
            data: arrDistrict.value,
        },
        {
            key: 'education_level',
            value: dataModel.value.education_level,
            label: 'Trình độ',
            placeholder: 'Trình độ',
            type: 'v-select',
            data: arrEducationLevel.value,
        },
        {
            key: 'source',
            value: dataModel.value.source,
            label: 'Nguồn ứng viên',
            placeholder: 'Nguồn ứng viên',
            type: 'v-select',
            data: arrCandidateSource.value,
        },
        {
            key: 'status',
            value: dataModel.value.status,
            label: 'Trạng thái',
            placeholder: 'Trạng thái',
            type: 'v-select',
            data: arrCandidateStatus.value,
        },
        {
            key: 'filter_profiles',
            value: dataModel.value.filter_profiles,
            label: 'Trạng thái lưu hồ sơ',
            placeholder: 'Trạng thái lưu hồ sơ',
            type: 'v-select',
            data: arrStatusArchived.value,
        },
    ]
})
const changeItem = (data) => {
    dataModel.value[data.key] = data.value
}

const handleSearch = (data) => {
    emit('update:handleSearch', data)
}
</script>

<style lang="scss" scoped></style>
