<template>
    <div class="flex flex-col items-start justify-center p-4 bg-[#F8FAFC]">
        <div v-if="channels?.length > 0" class="w-full border-l-0 border-r-0 border-b-1 border-t-1 border-[#E2E8F0] border-solid p-3">
            <span class="custom-button-btn flex overflow-x-scroll">
                <v-button v-for="item in channels"  @click="() => changeChannel(item.id)" class="learning-list mr-5" :class="item.id===channelId ? 'active': ''">
                    <v-icon class="" name="library_books" />
                    <span class="ml-2">{{  item.name  }}</span>
                </v-button>
            </span>
        </div>
        <div class="py-7">
            <h1 class="font-bold text-2xl text-[#1939AE] pl-5">Danh sách bài giảng Thứ 7 Học Hay</h1>
        </div>
        <div class="w-full px-6 py-10 grid grid-cols-1 card-list xl:grid-cols-3 md:grid-cols-2 gap-8">
            <div class="col-span-1 mb-5 bg-v-card hover:shadow-xl flex flex-col h-auto" v-for="item in props.lstModule">
                <div>
                    <img
                        v-if="item?.image"
                        class="object-cover aspect-16/9 w-full"
                        :src="usedImage.idToUrl(item?.image)"
                        alt="image"
                    />
                    <div v-else class="h-[208px]"></div>
                </div>
                <div class="px-4">
                    <div class="text-[#1E293B] font-semibold text-base py-5">{{ item?.title }}</div>
                    <div
                        v-if="item?.date_updated"
                        class="bg-[#F1F5F9] my-5 py-1 px-2 w-max text-[#475569] font-medium rounded-md"
                    >
                        Cập nhật ngày: {{ dayjs(item?.date_updated).format(FORMAT_TIME.DDMMYYYY) }}
                    </div>
                    <div class="icon-list text-[#1E293B] my-5">
                        <v-icon class="text-[#5BABAC]" name="schedule" />
                        Thời gian thi: {{ item?.quiz?.limit_time }}
                    </div>
                    <div class="icon-list text-[#1E293B] my-5">
                        <v-icon name="contact_support" />
                        Số câu hỏi: {{ item?.random_number || item?.quiz?.questions?.length }}
                    </div>
                    <div class="icon-list text-[#1E293B] my-5">
                        <v-icon name="check_circle_outline" />
                        Số câu đạt: {{ item?.quiz?.minimum_score }}
                    </div>
                </div>
                <div class="custom-card mt-auto">
                    <v-card-actions>
                        <div class="w-full">
                            <div class="h-[1px] w-full bg-[#E2E8F0] mb-4"></div>
                            <div class="w-full">
                                <button
                                    class="w-full col-span-1 text-[#67C2C3] icon-size border gap-3 border-[#67C2C3] border-solid rounded-lg px-2 py-1"
                                    @click="handleDetail(item)"
                                >
                                    Xem chi tiết
                                    <v-icon class="ml-2" name="arrow_forward" />
                                </button>
                            </div>
                        </div>
                    </v-card-actions>
                </div>
            </div>
        </div>

        <modal-module-download
            v-if="itemDownload"
            :overlay="downloadDoc"
            @close="downloadDoc = false"
            :items="itemDownload"
            :channelId="channelId"
            :courseId="courseId"
        />
    </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import useImage from '../../../../../shared-fe/composables/use-image'
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import modalModuleDownload from './modal-module-download.vue'
import { useUserStore } from '@/stores/user'
import { TYPE_OF_TRAIN } from '@/shared-fe/utils/constant'
const emit = defineEmits(['changeChannel']);
const userStore = useUserStore();
const usedImage = useImage()
const downloadDoc = ref(false)
const itemDownload = ref(null)
const props = defineProps({
    lstModule: {
        type: Array<Object>,
        default: [],
    },
    channelId: {
        type: Number,
        default: '',
    }
})
const channels = ref(userStore.currentUser[TYPE_OF_TRAIN.T7HH]);
const courseId = ref(0)

const changeChannel = (id) => {
    emit('changeChannel', id);
}

const handleDetail = (item: any) => {
    courseId.value = item.course
    downloadDoc.value = true
    itemDownload.value = item
}
</script>
<style lang="scss">
.icon-list {
    .v-icon {
        --v-icon-color: #5babac;
        --v-icon-size: 16px;
    }
}
.button-lesson {
    .v-icon {
        --v-icon-color: red;
        --v-icon-size: 16px;
    }
}
.router-link-active {
    --v-breadcrumb-color: #1939ae;
}
.bg-v-card {
    background: white !important;
}
.icon-size {
    .v-icon {
        --v-icon-size: 16px;
    }
}
.card-list {
    .custom-v-card {
        max-width: 100% !important;
    }
}
</style>
