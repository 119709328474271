import Joi from 'joi'
import { ref } from 'vue'

export const useRecruitmentRequestEditValidate = () => {
    const messageLabels = {
        hiring_reason: 'Lý do tuyển',
        providers: 'Nhà phân phối',
        employment_type: 'Hình thức làm việc',
        province: 'Tỉnh thành',
        quantity: 'Số lượng',
        position: 'Vị trí tuyển dụng',
        ss_id: 'Nhân viên SS',
        staff_id: 'Nhân viên DCR',
        districts: 'Quận / Huyện',
        reason_rotation: 'Lý do luân chuyển',
        rotation_date: 'Ngày luân chuyển',
        start_date: 'Ngày làm việc dự kiến',
    }
    const customMessages = {
        'number.base': 'là bắt buộc',
        'string.base': 'không được để trống',
        'string.empty': 'không được để trống',
        'any.required': 'là bắt buộc',
        'array.base': 'là bắt buộc',
        'array.optional': 'là bắt buộc',
        'array.min': 'là bắt buộc',
        'any.invalid': 'là bắt buộc',
        'string.optional': 'là bắt buộc',
    }

    const schema = Joi.object()
        .keys({
            hiring_reason: Joi.string().optional().messages(customMessages),
            providers: Joi.number().optional().messages(customMessages),
            employment_type: Joi.string().optional().messages(customMessages),
            province: Joi.string().optional().messages(customMessages),
            quantity: Joi.number().optional().messages(customMessages),
            position: Joi.string().optional().messages(customMessages),
            ss_id: Joi.string().optional().messages(customMessages),
            start_date: Joi.string().optional().messages(customMessages),
            staff_id: Joi.when('hiring_reason', {
                is: Joi.valid('replacement', 'transfer'),
                then: Joi.string().required().messages(customMessages),
                otherwise: Joi.optional(),
            }),
            districts: Joi.array().items(Joi.any()).min(1).optional().messages(customMessages),
            reason_rotation: Joi.when('hiring_reason', {
                is: 'transfer',
                then: Joi.string().required().messages(customMessages),
                otherwise: Joi.optional(),
            }),
            rotation_date: Joi.when('hiring_reason', {
                is: 'transfer',
                then: Joi.string().required().messages(customMessages),
                otherwise: Joi.optional(),
            }),
        })
        .unknown(true)

    const validate = (formData: any) => {
        const { error } = schema.validate(formData, { abortEarly: false })
        const format = {}
        if (error?.details) {
            error.details.map((detail) => {
                const customLabel = messageLabels[detail.context.key]
                format[detail.context.key] = `${customLabel}: ${detail.message}`
            })
        }

        return {
            error: !!error,
            data: format,
        }
    }

    return {
        schema,
        validate,
        // conditionSchema,
    }
}
