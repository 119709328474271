<template>
	<svg
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill-rule="evenodd"
		clip-rule="evenodd"
		stroke-linejoin="round"
		stroke-miterlimit="2"
	>
		<path
			d="M3,2 L3,11 L21,11 L3,2 Z M5,5.2363281 L12.527344,9 L5,9 L5,5.2363281 Z M3,13 L3,22 L21,13 L3,13 Z"
			transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
		></path>
	</svg>
</template>

<script lang="ts">
export default {};
</script>
