import { useApi } from '@directus/extensions-sdk'
import { addQueryToPath, addTokenToURL, getPublicURL } from '../utils/get-root-path'

export default function useImage() {
    const api = useApi()
    let access_token = api.defaults.headers.common['Authorization']?.toString().split(' ')[1] || null
    const idToUrl = (id: string, options: any = {}) => {
        return addQueryToPath(addTokenToURL(getPublicURL() + `assets/${id}`, access_token || ''), options)
    }

    const idToUrlDownload = (id: string, options: any = {}) => {
        return addQueryToPath(addTokenToURL(getPublicURL() + `assets/${id}?download=&`, access_token || ''), options)
    }

    return {
        idToUrl,
        idToUrlDownload,
    }
}
