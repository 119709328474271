<template>
    <div v-if="errors.length > 0" class="border p-2 border-solid border-red mb-3 rounded-md">
        <div class="text-xs" v-for="error in errors">
            <span class="message-error text-xs" v-if="error?.row !== 0">DÒNG {{ error.row }}:&nbsp;</span>
            <span v-if="error?.row !== 0">{{ error.message.join(' ; ') }}</span>
        </div>
    </div>

    <v-notice v-if="!relationInfo" type="warning">
        {{ t('relationship_not_setup') }}
    </v-notice>
    <v-notice v-else-if="relationInfo.relatedCollection.meta?.singleton" type="warning">
        {{ t('no_singleton_relations') }}
    </v-notice>
    <div v-else class="one-to-many">
        <div :class="{ bordered: layout === LAYOUTS.TABLE }">
            <div v-if="layout === LAYOUTS.TABLE" class="actions" :class="width">
                <v-button rounded icon v-tooltip="'Tải file lên'" @click="openFileBrowser">
                    <input
                        ref="input"
                        class="browse"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @input="onBrowseSelect"
                    />
                    <v-icon name="file_upload" />
                </v-button>

                <span class="custom-button-download-template ml-3">
                    <c-download-button :file_id="template">
                        <v-icon name="file_download" />
                        <span class="ml-2">Tải file mẫu</span>
                    </c-download-button>
                </span>
                <div class="spacer" />

                <div v-if="totalItemCount" class="item-count">
                    {{ showingCount }}
                </div>

                <div v-if="enableSearchFilter && (totalItemCount > 10 || search || searchFilter)" class="search">
                    <search-input
                        v-model="search"
                        v-model:filter="searchFilter"
                        :collection="relationInfo.relatedCollection.collection"
                    />
                </div>

                <v-button
                    v-if="!disabled && enableSelect && updateAllowed"
                    v-tooltip.bottom="updateAllowed ? t('add_existing') : t('not_allowed')"
                    rounded
                    icon
                    :secondary="enableCreate"
                    @click="selectModalActive = true"
                >
                    <v-icon name="playlist_add" />
                </v-button>

                <v-button
                    v-if="!disabled && enableCreate && createAllowed"
                    v-tooltip.bottom="createAllowed ? t('create_item') : t('not_allowed')"
                    rounded
                    icon
                    @click="createItem"
                >
                    <v-icon name="add" />
                </v-button>
            </div>

            <template v-if="uploading">
                <p class="type-label">{{ progress }}%</p>
                <p class="type-text">
                    {{ t('upload_file_indeterminate') }}
                </p>
                <v-progress-linear :value="progress" rounded />
            </template>

            <v-table
                v-if="layout === LAYOUTS.TABLE"
                v-model:sort="sort"
                v-model:headers="headers"
                :class="{ 'no-last-border': totalItemCount <= 10 }"
                :loading="loading"
                :show-manual-sort="allowDrag"
                :manual-sort-key="relationInfo?.sortField"
                :items="displayItems"
                :row-height="tableRowHeight"
                show-resize
                @click:row="editRow"
                @update:items="sortItems"
            >
                <template v-for="header in headers" :key="header.value" #[`item.${header.value}`]="{ item }">
                    <render-template
                        :title="header.value"
                        :collection="relationInfo.relatedCollection.collection"
                        :item="item"
                        :template="`{{${header.value}}}`"
                    />
                </template>

                <template #item-append="{ item }">
                    <router-link
                        v-if="enableLink"
                        v-tooltip="t('navigate_to_item')"
                        :to="getLinkForItem(item)!"
                        class="item-link"
                        :class="{ disabled: item.$type === 'created' }"
                    >
                        <v-icon name="launch" />
                    </router-link>

                    <v-icon
                        v-if="!disabled && (deleteAllowed || isLocalItem(item))"
                        v-tooltip="t(getDeselectTooltip(item))"
                        class="deselect"
                        :name="getDeselectIcon(item)"
                        @click.stop="deleteItem(item)"
                    />
                </template>
            </v-table>

            <template v-else-if="loading">
                <v-skeleton-loader
                    v-for="n in clamp(totalItemCount - (page - 1) * limit, 1, limit)"
                    :key="n"
                    :type="totalItemCount > 4 ? 'block-list-item-dense' : 'block-list-item'"
                />
            </template>

            <v-notice v-else-if="displayItems.length === 0">
                {{ t('no_items') }}
            </v-notice>

            <v-list v-else>
                <draggable
                    :force-fallback="true"
                    :model-value="displayItems"
                    item-key="id"
                    handle=".drag-handle"
                    :disabled="!allowDrag"
                    @update:model-value="sortItems($event)"
                >
                    <template #item="{ element }">
                        <v-list-item
                            block
                            clickable
                            :disabled="disabled"
                            :dense="totalItemCount > 4"
                            :class="{ deleted: element.$type === 'deleted' }"
                            @click="editItem(element)"
                        >
                            <v-icon
                                v-if="allowDrag"
                                name="drag_handle"
                                class="drag-handle"
                                left
                                @click.stop="() => {}"
                            />
                            <render-template
                                :collection="relationInfo.relatedCollection.collection"
                                :item="element"
                                :template="templateWithDefaults"
                            />
                            <div class="spacer" />

                            <router-link
                                v-if="enableLink"
                                v-tooltip="t('navigate_to_item')"
                                :to="getLinkForItem(element)!"
                                class="item-link"
                                :class="{ disabled: element.$type === 'created' }"
                                @click.stop
                            >
                                <v-icon name="launch" />
                            </router-link>
                            <v-icon
                                v-if="!disabled && (deleteAllowed || isLocalItem(element))"
                                v-tooltip="t(getDeselectTooltip(element))"
                                class="deselect"
                                :name="getDeselectIcon(element)"
                                @click.stop="deleteItem(element)"
                            />
                        </v-list-item>
                    </template>
                </draggable>
            </v-list>

            <div class="actions" :class="layout">
                <template v-if="layout === LAYOUTS.TABLE">
                    <template v-if="pageCount > 1">
                        <v-pagination
                            v-model="page"
                            :length="pageCount"
                            :total-visible="width.includes('half') ? 3 : 5"
                        />

                        <div class="spacer" />

                        <div v-if="loading === false" class="per-page">
                            <span>{{ t('per_page') }}</span>
                            <v-select v-model="limit" :items="['10', '20', '30', '50', '100']" inline />
                        </div>
                    </template>
                </template>
                <template v-else>
                    <v-button v-if="enableCreate && createAllowed" :disabled="disabled" @click="createItem">
                        {{ t('create_new') }}
                    </v-button>
                    <v-button
                        v-if="enableSelect && updateAllowed"
                        :disabled="disabled"
                        @click="selectModalActive = true"
                    >
                        {{ t('add_existing') }}
                    </v-button>
                    <div class="spacer" />
                    <v-pagination v-if="pageCount > 1" v-model="page" :length="pageCount" :total-visible="5" />
                </template>
            </div>
        </div>

        <drawer-item
            :disabled="disabled || (!updateAllowed && currentlyEditing !== '+')"
            :active="currentlyEditing !== null"
            :collection="relationInfo.relatedCollection.collection"
            :primary-key="currentlyEditing || '+'"
            :edits="editsAtStart"
            :circular-field="relationInfo.reverseJunctionField.field"
            @input="stageEdits"
            @update:active="cancelEdit"
        />

        <drawer-collection
            v-if="!disabled"
            v-model:active="selectModalActive"
            :collection="relationInfo.relatedCollection.collection"
            :filter="customFilter"
            multiple
            @input="select"
        />
    </div>
</template>

<script setup lang="ts">
import { Sort } from '@/components/v-table/types'
import { DisplayItem, RelationQueryMultiple, useRelationMultiple } from '@/composables/use-relation-multiple'
import { useRelationO2M } from '@/composables/use-relation-o2m'
import { useRelationPermissionsO2M } from '@/composables/use-relation-permissions'
import { useFieldsStore } from '@/stores/fields'
import { LAYOUTS } from '@/types/interfaces'
import { addRelatedPrimaryKeyToFields } from '@/utils/add-related-primary-key-to-fields'
import { adjustFieldsForDisplays } from '@/utils/adjust-fields-for-displays'
import { formatCollectionItemsCount } from '@/utils/format-collection-items-count'
import { parseFilter } from '@/utils/parse-filter'
import DrawerCollection from '@/views/private/components/drawer-collection.vue'
import DrawerItem from '@/views/private/components/drawer-item.vue'
import SearchInput from '@/views/private/components/search-input.vue'
import { Filter } from '@directus/types'
import { deepMap, getFieldsFromTemplate } from '@directus/utils'
import { clamp, get, isEmpty, isNil, map } from 'lodash'
import { render } from 'micromustache'
import { computed, inject, ref, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import * as XLSX from 'xlsx/xlsx.mjs'
import Draggable from 'vuedraggable'
import { useAppImportExamValidate } from './useAppImportExamValidate'
import { toLowerCaseNonAccentVietnamese } from '@/shared-fe/utils/convert-data'
import { useApi } from '@directus/composables'
import CDownloadButton from '@/shared-fe/components/c-download-button/c-download-button.vue'

const props = withDefaults(
    defineProps<{
        value?: (number | string | Record<string, any>)[] | Record<string, any>
        primaryKey: string | number
        collection: string
        field: string
        width: string
        layout?: LAYOUTS
        tableSpacing?: 'compact' | 'cozy' | 'comfortable'
        fields?: Array<string>
        template?: string | null
        disabled?: boolean
        enableCreate?: boolean
        enableSelect?: boolean
        filter?: Filter | null
        enableSearchFilter?: boolean
        enableLink?: boolean
        limit?: number
    }>(),
    {
        value: () => [],
        layout: LAYOUTS.LIST,
        tableSpacing: 'cozy',
        fields: () => ['id'],
        template: () => null,
        disabled: false,
        enableCreate: true,
        enableSelect: true,
        filter: () => null,
        enableSearchFilter: false,
        enableLink: false,
        limit: 15,
    }
)

const emit = defineEmits(['input', 'error'])
const { t } = useI18n()
const { collection, field, primaryKey } = toRefs(props)
const { relationInfo } = useRelationO2M(collection, field)
const fieldsStore = useFieldsStore()
const { getIndexOfColumnDependOnHeader, getCorrectAnswerArray, validate, columnLabels, choiceArray } =
    useAppImportExamValidate()
const errors = ref(<any[]>[])
const api = useApi()
const template = ref('')

const value = computed({
    get: () => props.value,
    set: (val) => {
        emit('input', val)
    },
})

const getProjectSettings = async () => {
    return await api.get('settings', {
        params: {
            fields: ['file_template_ob', 'file_template_ww', 'file_template_exam_import'],
        },
    })
}

getProjectSettings().then((res) => {
    if (res?.data?.data) {
        template.value = res?.data?.data?.file_template_exam_import
    }
})

const templateWithDefaults = computed(() => {
    return (
        props.template ||
        relationInfo.value?.relatedCollection.meta?.display_template ||
        `{{${relationInfo.value?.relatedPrimaryKeyField.field}}}`
    )
})

const fields = computed(() => {
    if (!relationInfo.value) return []

    let displayFields: string[] = []

    if (props.layout === LAYOUTS.TABLE) {
        displayFields = adjustFieldsForDisplays(props.fields, relationInfo.value.relatedCollection.collection)
    } else {
        displayFields = adjustFieldsForDisplays(
            getFieldsFromTemplate(templateWithDefaults.value),
            relationInfo.value.relatedCollection.collection
        )
    }

    return addRelatedPrimaryKeyToFields(relationInfo.value.relatedCollection.collection, displayFields)
})

const limit = ref(props.limit)
const page = ref(1)
const search = ref('')
const searchFilter = ref<Filter>()
const sort = ref<Sort>()
const input = ref<HTMLInputElement>()

const query = computed<RelationQueryMultiple>(() => {
    const q: RelationQueryMultiple = {
        limit: limit.value,
        page: page.value,
        fields: fields.value || ['id'],
    }

    if (!relationInfo.value) {
        return q
    }

    if (searchFilter.value) {
        q.filter = searchFilter.value
    }

    if (search.value) {
        q.search = search.value
    }

    if (sort.value) {
        q.sort = [`${sort.value.desc ? '-' : ''}${sort.value.by}`]
    }

    return q
})

watch([search, searchFilter, limit], () => {
    page.value = 1
})

const {
    create,
    update,
    remove,
    select,
    displayItems,
    totalItemCount,
    loading,
    selected,
    isItemSelected,
    isLocalItem,
    getItemEdits,
} = useRelationMultiple(value, query, relationInfo, primaryKey)
const { uploading, progress, upload, onBrowseSelect, done, numberOfFiles } = useUpload()

const { createAllowed, deleteAllowed, updateAllowed } = useRelationPermissionsO2M(relationInfo)

const pageCount = computed(() => Math.ceil(totalItemCount.value / limit.value))

const showingCount = computed(() => {
    return formatCollectionItemsCount(
        totalItemCount.value,
        page.value,
        limit.value,
        !!(search.value || searchFilter.value)
    )
})

function validFiles(files: FileList) {
    if (files.length === 0) return false

    for (const file of files) {
        if (file.size === 0) return false
        if (file.name.split('.').pop() !== 'xlsx') {
            return false
        }
    }

    return true
}

function useUpload() {
    const uploading = ref(false)
    const progress = ref(0)
    const numberOfFiles = ref(0)
    const done = ref(0)

    return { uploading, progress, upload, onBrowseSelect, numberOfFiles, done }

    async function upload(files: FileList) {
        uploading.value = true
        progress.value = 0

        try {
            if (!validFiles(files)) {
                throw new Error('Tải lên không thành công. File tải không hợp lệ.')
            }
            numberOfFiles.value = files.length
            const file = Array.from(files)[0]
            if (file) {
                const reader = new FileReader()
                reader.onload = async (e) => {
                    const data = new Uint8Array(e.target.result)
                    const workbook = XLSX.read(data, { type: 'array', cellDates: true })

                    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
                    let parseData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1,
                        defval: '',
                    })

                    let header = parseData[0].map((columnName: string) =>
                        toLowerCaseNonAccentVietnamese(columnName.trim())
                    )

                    // remove header
                    parseData.shift()
                    if (parseData.length === 0) {
                        return
                    }

                    let dataCreate: any[] = []
                    parseData.map((arrayData: any, index: number) => {
                        let question = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.question)] || ''
                        let A = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.A)] || ''
                        let B = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.B)] || ''
                        let C = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.C)] || ''
                        let D = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.D)] || ''
                        let E = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.E)] || ''
                        let F = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.F)] || ''
                        let correct_answer =
                            arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.correct_answer)] || ''
                        let explain = arrayData[getIndexOfColumnDependOnHeader(header, columnLabels.explain)] || ''

                        let dataValidation = {
                            question,
                            A,
                            B,
                            C,
                            D,
                            E,
                            F,
                            correct_answer,
                            explain,
                        }
                        // check valid data
                        let { error, data } = validate(dataValidation, header)
                        if (error) {
                            errors.value.push({
                                row: index + 2,
                                message: data,
                            })
                        } else {
                            let arrDataCorrect = getCorrectAnswerArray(correct_answer, header)

                            let result: any[] = []
                            choiceArray.map((item: string) => {
                                result.push({
                                    answer: arrayData[getIndexOfColumnDependOnHeader(header, item)],
                                    is_correct: arrDataCorrect.includes(item) ? true : undefined,
                                })
                            })
                            let answers: any[] = [...result]
                            for (let i = result.length - 1; i >= 0; i--) {
                                if (!result[i].answer) {
                                    answers.pop()
                                } else {
                                    break
                                }
                            }
                            let obj = {
                                question: question,
                                answers,
                                explain: explain,
                            }
                            dataCreate.push(obj)
                        }
                    })
                    let val = {
                        create: [...dataCreate],
                        update: [],
                        delete: value.value && value.value.length > 0 ? [...value.value] : [],
                    }
                    emit('input', val)
                }
                reader.readAsArrayBuffer(file)
            }
        } catch (err: any) {
        } finally {
            uploading.value = false
            done.value = 0
            numberOfFiles.value = 0
        }
    }

    function onBrowseSelect(event: Event) {
        const files = (event.target as HTMLInputElement)?.files

        if (files) {
            upload(files)
        }
    }
}

function openFileBrowser() {
    input.value?.click()
}

const headers = ref<Array<any>>([])

watch(
    [props, relationInfo, displayItems],
    () => {
        if (!relationInfo.value) {
            headers.value = []
            return
        }

        const relatedCollection = relationInfo.value.relatedCollection.collection

        const contentWidth: Record<string, number> = {}

        ;(displayItems.value ?? []).forEach((item: Record<string, any>) => {
            props.fields.forEach((key) => {
                if (!contentWidth[key]) {
                    contentWidth[key] = 5
                }

                if (String(item[key]).length > contentWidth[key]) {
                    contentWidth[key] = String(item[key]).length
                }
            })
        })

        headers.value = props.fields
            .map((key) => {
                const field = fieldsStore.getField(relatedCollection, key)

                // when user has no permission to this field or junction collection
                if (!field) return null

                return {
                    text: field.name,
                    value: key,
                    width: contentWidth[key] < 10 ? contentWidth[key] * 16 + 10 : 160,
                    sortable: !['json'].includes(field.type),
                }
            })
            .filter((key) => key !== null)
    },
    { immediate: true }
)

const spacings = {
    compact: 32,
    cozy: 48,
    comfortable: 64,
}

const tableRowHeight = computed(() => spacings[props.tableSpacing] ?? spacings.cozy)

const allowDrag = computed(
    () => totalItemCount.value <= limit.value && relationInfo.value?.sortField !== undefined && !props.disabled
)

function getDeselectIcon(item: DisplayItem) {
    if (item.$type === 'deleted') return 'settings_backup_restore'
    if (isLocalItem(item)) return 'delete'
    return 'close'
}

function getDeselectTooltip(item: DisplayItem) {
    if (item.$type === 'deleted') return 'undo_removed_item'
    if (isLocalItem(item)) return 'delete_item'
    return 'remove_item'
}

function sortItems(items: DisplayItem[]) {
    const info = relationInfo.value
    const sortField = info?.sortField
    if (!info || !sortField) return

    const sortedItems = items.map((item, index) => {
        const relatedId = item?.[info.relatedPrimaryKeyField.field]

        const changes: Record<string, any> = {
            $index: item.$index,
            $type: item.$type,
            $edits: item.$edits,
            ...getItemEdits(item),
            [sortField]: index + 1,
        }

        if (!isNil(relatedId)) {
            changes[info.relatedPrimaryKeyField.field] = relatedId
        }

        return changes
    })

    update(...sortedItems)
}

const selectedPrimaryKeys = computed(() => {
    if (!relationInfo.value) return []

    const relatedPkField = relationInfo.value.relatedPrimaryKeyField.field

    return selected.value.map((item) => item[relatedPkField])
})

const currentlyEditing = ref<string | null>(null)
const selectModalActive = ref(false)
const editsAtStart = ref<Record<string, any>>({})
let newItem = false

function createItem() {
    currentlyEditing.value = '+'
    editsAtStart.value = {}
    newItem = true
}

function editItem(item: DisplayItem) {
    if (!relationInfo.value) return

    const relatedPkField = relationInfo.value.relatedPrimaryKeyField.field

    newItem = false
    editsAtStart.value = getItemEdits(item)

    if (item?.$type === 'created' && !isItemSelected(item)) {
        currentlyEditing.value = '+'
    } else {
        currentlyEditing.value = item[relatedPkField]
    }
}

function editRow({ item }: { item: DisplayItem }) {
    editItem(item)
}

function stageEdits(item: Record<string, any>) {
    if (newItem) {
        create(item)
    } else {
        update(item)
    }
}

function cancelEdit() {
    currentlyEditing.value = null
}

function deleteItem(item: DisplayItem) {
    if (
        page.value === Math.ceil(totalItemCount.value / limit.value) &&
        page.value !== Math.ceil((totalItemCount.value - 1) / limit.value)
    ) {
        page.value = Math.max(1, page.value - 1)
    }

    remove(item)
}

const values = inject('values', ref<Record<string, any>>({}))

const customFilter = computed(() => {
    const filter: Filter = {
        _and: [],
    }

    const customFilter = parseFilter(
        deepMap(props.filter, (val: any) => {
            if (val && typeof val === 'string') {
                return render(val, values.value)
            }

            return val
        })
    )

    if (!isEmpty(customFilter)) filter._and.push(customFilter)

    if (!relationInfo.value) return filter

    const selectFilter: Filter = {
        _or: [
            {
                [relationInfo.value.reverseJunctionField.field]: {
                    _neq: props.primaryKey,
                },
            },
            {
                [relationInfo.value.reverseJunctionField.field]: {
                    _null: true,
                },
            },
        ],
    }

    if (selectedPrimaryKeys.value.length > 0) {
        filter._and.push({
            [relationInfo.value.relatedPrimaryKeyField.field]: {
                _nin: selectedPrimaryKeys.value,
            },
        })
    }

    if (props.primaryKey !== '+') filter._and.push(selectFilter)

    return filter
})

function getLinkForItem(item: DisplayItem) {
    if (relationInfo.value) {
        const primaryKey = get(item, relationInfo.value.relatedPrimaryKeyField.field)
        return `/content/${relationInfo.value.relatedCollection.collection}/${encodeURIComponent(primaryKey)}`
    }

    return null
}
</script>

<style lang="scss">
.one-to-many {
    .bordered {
        .render-template {
            line-height: 1;
        }

        .no-last-border {
            tr.table-row:last-child td {
                border-bottom: none;
            }
        }

        tr.table-row {
            .append {
                position: sticky;
                right: 0;
                border-left: var(--border-width) solid var(--border-subdued);
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.bordered {
    border: var(--border-width) solid var(--border-normal);
    border-radius: var(--border-radius-outline);
    padding: var(--v-card-padding);
}

.v-list {
    margin-top: 8px;
    --v-list-padding: 0 0 4px;

    .v-list-item.deleted {
        --v-list-item-border-color: var(--danger-25);
        --v-list-item-border-color-hover: var(--danger-50);
        --v-list-item-background-color: var(--danger-10);
        --v-list-item-background-color-hover: var(--danger-25);

        ::v-deep(.v-icon) {
            color: var(--danger-75);
        }
    }
}

.v-skeleton-loader,
.v-notice {
    margin-top: 8px;
}

.actions {
    display: flex;
    align-items: center;
    gap: var(--v-sheet-padding);

    .v-pagination {
        :deep(.v-button) {
            display: inline-flex;
        }
    }

    .table.v-pagination {
        margin-top: var(--v-sheet-padding);
    }

    .spacer {
        flex-grow: 1;
    }

    .search {
        position: relative;
        z-index: 1;
    }

    .item-count {
        color: var(--foreground-subdued);
        white-space: nowrap;
    }

    &.half,
    &.half-right {
        flex-wrap: wrap;

        .search {
            width: 100%;
            order: -1;

            :deep(.search-input),
            :deep(.search-badge) {
                width: 100% !important;
            }
        }
    }

    &.list {
        margin-top: 8px;
    }
}

.item-link {
    --v-icon-color: var(--foreground-subdued);
    transition: color var(--fast) var(--transition);
    margin: 0 4px;

    &:hover {
        --v-icon-color: var(--primary);
    }

    &.disabled {
        opacity: 0;
        pointer-events: none;
    }
}

.deselect {
    --v-icon-color: var(--foreground-subdued);
    transition: color var(--fast) var(--transition);
    margin: 0 4px;

    &:hover {
        --v-icon-color: var(--danger);
    }
}

.per-page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 120px;
    padding: 10px 0;
    margin-right: 2px;
    color: var(--foreground-subdued);

    span {
        width: auto;
        margin-right: 8px;
    }

    .v-select {
        color: var(--foreground-normal);
    }
}

.browse {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    // appearance: none;
    display: none;
}

.v-progress-linear {
    position: absolute;
    bottom: 30px;
    left: 32px;
    width: calc(100% - 64px);
}
</style>
