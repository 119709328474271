<template>
    <div class="mb-9">
        <c-expand-search :dataBasic="BASIC_SEARCH" :dataExpand="EXPAND_SEARCH" @update:model-value="handleSearch" />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { ComponentPropsModel } from '../../../../../shared-fe/types/table-type'
import CExpandSearch from '../../../../../shared-fe/components/c-expand-search/c-expand-search.vue'
import {
    PROVINCE,
    RANGE_DATE_PLACEHOLDER,
    SALARY,
    STATUS,
    TITLE_ALL_PLACHEHOLDER,
    TITLE_RANGE_SALARY_PLACHEHOLDER,
    TITLE_RECRUITMENT,
} from '../../../../../shared-fe/utils/message'
import { SALARYS_LIST } from '../../../../../shared-fe/utils/constant'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
const commonStore = useStoreCommon()
const emit = defineEmits(['update:handleSearch'])

const props = defineProps({
    arrayStatus: {
        type: Array<Object>,
        default: [],
    },
    arrayValue: {
        type: Object,
        default: {},
    },
})
const dataModel = ref(<any>props.arrayValue)
const status = computed(() => {
    return props.arrayStatus
})

const arrProvince = computed(() => {
    let res: any = []
    res = commonStore.provinceList.map((x: any) => {
        return { text: x.name, value: x.id }
    })
    return res
})

watch(
    () => props.arrayValue,
    () => {
        dataModel.value = props.arrayValue || {}
    }
)

const BASIC_SEARCH: ComponentPropsModel[] = [
    {
        key: 'title',
        value: dataModel.value?.title,
        label: '',
        placeholder: TITLE_RECRUITMENT,
        type: 'v-input',
        icon: 'search',
    },
    {
        key: 'date_created',
        value: '',
        label: '',
        placeholder: RANGE_DATE_PLACEHOLDER,
        type: 'c-range-datetime',
        data: dataModel.value?.date_created || null,
    },
]

const EXPAND_SEARCH: any = computed(() => {
    return [
        {
            key: 'province_id',
            value: dataModel.value?.province_id,
            label: PROVINCE,
            placeholder: PROVINCE,
            type: 'v-select',
            icon: 'search',
            data: arrProvince.value,
        },
        {
            key: 'status',
            value: dataModel.value?.status,
            label: STATUS,
            placeholder: TITLE_ALL_PLACHEHOLDER,
            type: 'v-select',
            data: status.value,
        },
    ]
})

const handleSearch = (data) => {
    emit('update:handleSearch', data)
}
</script>

<style lang="scss" scoped></style>
