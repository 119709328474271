<template>
    <v-overlay v-if="items" :active="props.overlay">
        <v-icon @click="handleClose()" class="absolute top-[35px] left-[485px] cursor-pointer" name="close" />
        <div class="py-10 px-14 bg-white rounded-md min-w-[540px]">
            <div class="flex justify-center flex-col items-center">
                <div class="text-green" v-if="showNotify">Tải tài liệu thành công</div>
                <div class="p-[30px] rounded-full bg-[#def4f5] mb-6 mt-6">
                    <v-icon style="color: #399e9f" x-large name="download" />
                </div>
                <div class="text-[#1E293B] text-xl font-bold text-center mb-2">
                    {{ step === 1 ? 'Tải tài liệu' : 'Chọn nhà phân phối để bắt đầu đào tạo' }}
                </div>
                <div v-if="step === 1" class="text-[#64748B] text-center mb-8">
                    Bạn cần tải tài liệu giảng dạy về trước khi tiến hành đào tạo
                </div>
                <v-button v-if="step === 1" @click="handleDownloadPdf(false)">Tải tài liệu</v-button>
                <v-button secondary class="mt-3" v-if="step === 1" @click="handleDownloadPdf(true)">Bỏ qua</v-button>
                <template v-if="step === 2 && listProvider">
                    <button
                        :disabled="loading"
                        class="w-2/3 text-sm text-[#67C2C3] border gap-3 border-[#67C2C3] border-solid rounded-lg px-2 py-3 mb-3 mt-2"
                        v-for="item in listProvider"
                        @click="handleChooseProvider(item.provider_id.id)"
                    >
                        {{ item.provider_id.name }}
                        <v-icon class="ml-2" name="arrow_forward" />
                    </button>
                </template>

            </div>
        </div>
    </v-overlay>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import useImage from '../../../../../shared-fe/composables/use-image'
import { useRouter } from 'vue-router'
import { useTrainingQuery } from '../../../../../shared-fe/services/training-service'
import useExamResult from '../../../../../shared-fe/composables/use-exam-result'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { USER_TYPE } from '@/shared-fe/utils/constant'
import { useConfirmModal } from '@/shared-fe/stores/confirm-modal'
import { useUserStore } from '@/stores/user'

const props = defineProps({
    items: {
        type: Object,
        default: {},
    },
    overlay: {
        type: Boolean,
        default: false,
    },
    channelId: {
        type: Number,
        default: 0,
    },
    courseId: {
        type: Number,
        default: 0,
    }
})
const router = useRouter()
const usedImage = useImage()
const step = ref(1)
const loading = ref(false)
const showNotify = ref(false)
const userStore = useUserStore()
const { postSessionsTrainEndpoint, getProviderUserBySessionIdAndModuleId } = useTrainingQuery()
const { getCurrentSaturdaySessionTrain } = useExamResult()
const listProvider = ref(<any>[])
const sessionId = ref('')
const confirmModal = useConfirmModal()
const handleDownloadPdf = async (skip: boolean) => {
    loading.value = true
    if (!skip) {
        window.open(usedImage.idToUrlDownload(props.items?.training_file))
    }
    const currentSession = await getCurrentSaturdaySessionTrain()

    // Mỗi ngày chỉ được train cho 1 module thôi
    if(currentSession && currentSession[0]?.course?.id !== props.courseId){
        emit('close')
        loading.value = false
        confirmModal.handleOpenWarningModal("Mỗi ngày chỉ được train cho 1 module")
        return;
    }
    
    let id = ''
    if (!currentSession) {
        let session = await postSessionsTrainEndpoint({ id: userStore.currentUser.id, channel_id: props.channelId })
        if (session.data.id) {
            id = session.data.id
        }
    } else if (currentSession && currentSession.length > 0) {
        id = currentSession[0]?.id
    }
    sessionId.value = id
    if(userStore.currentUser.user_type === USER_TYPE.RETAIL){
        let providers = await getProviderUserBySessionIdAndModuleId()
        if (providers.data.code === 'success') {
            listProvider.value = [...providers.data.result]
        }
        step.value = 2
    }else{
        handleChooseProvider(userStore.currentUser.system_providers[0]?.id)
    }
    
    showNotify.value = true
    loading.value = false
}

watch(
    () => showNotify.value,
    () => {
        if (showNotify.value) {
            setTimeout(() => {
                showNotify.value = false
            }, 30000)
        }
    }
)

const handleChooseProvider = (id) => {
    loading.value = true
    router.push({
        name: PATH_ROUTER.SATURDAY_LEARNING_TRAINING.NAME,
        params: {
            session_train: sessionId.value,
            module_id: props.items.id,
            provider_id: id,
        },
    })
    step.value = 1
    listProvider.value = []
    sessionId.value = ''
    emit('close')
    loading.value = false
}

const handleClose = () => {
    step.value = 1
    listProvider.value = []
    sessionId.value = ''
    emit('close')
}
const emit = defineEmits(['close'])
</script>

<style lang="scss">
.label-input {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 6px;
}
.btn-modal {
    button {
        width: 100% !important;
    }
}
</style>
