<template>
    <c-role>
        <private-view title="Thông tin WSR">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation name="3" />
            </template>
            <!-- navigation end  -->

            <!-- header start -->
            <template #title-outer:prepend>
                <v-button class="header-icon" rounded icon secondary exact @click="navigateBack">
                    <v-icon name="arrow_back" />
                </v-button>
            </template>

            <template #headline>
                <v-breadcrumb
                    :items="[
                        {
                            name: PATH_ROUTER.WSR_LIST.VALUE,
                            to: PATH_ROUTER.WSR_LIST.PATH,
                        },
                    ]"
                />
            </template>

            <template #actions>
                <c-access :roles="[USER_ROLE.AGENCY, USER_ROLE.HR]">
                    <v-dialog v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <v-icon name="delete"></v-icon>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>
                <c-access :roles="[USER_ROLE.HR, USER_ROLE.AGENCY]">
                    <div class="box-save">
                        <v-button v-tooltip.bottom="SAVE" icon rounded @click="save">
                            <v-icon name="check" />
                        </v-button>
                    </div>
                </c-access>
                <!--  -->
            </template>
            <!-- header end -->

            <!-- content start -->
            <div class="flex justify-center mb-8">
                <div class="list-btn-tab-detail">
                    <v-button
                        class="first"
                        :class="activeTabFormDetail ? 'active' : ''"
                        secondary
                        @click="activeTabFormDetail = !activeTabFormDetail"
                    >
                        <div class="i-mdi-account mr-2"></div>
                        Thông tin cá nhân
                    </v-button>
                    <v-button
                        class="last"
                        secondary
                        :class="!activeTabFormDetail ? 'active' : ''"
                        @click="activeTabFormDetail = !activeTabFormDetail"
                    >
                        <div class="i-mdi-account-multiple mr-2"></div>
                        Thông tin công tác
                    </v-button>
                </div>
            </div>
            <form-wsr-work v-if="!activeTabFormDetail && objWsrWork" :item="objWsrWork" @refetch="handleRefetch" />
            <form-wsr-detail v-if="activeTabFormDetail && objWsrDetail" :item="objWsrDetail" @refetch="handleRefetch" />
            <wsr-history v-if="objWsrDetail" ref="historyRef" :id-user="objWsrDetail?.id" />

            <!--  content end -->

            <template #sidebar>
                <sidebar-detail icon="info" title="Thông tin WSR" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CNavigation from '../../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { MODE_EDIT, SUBMIT_EVENT, USER_ROLE, USER_STATUS } from '../../../../../shared-fe/utils/constant'
import { CANCEL, CONTENT_DELETE, LABEL_DELETE, SAVE } from '../../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'

import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import WsrHistory from '../components/wsr-history.vue'
import FormWsrDetail from '../components/form-wsr-detail.vue'
import FormWsrWork from '../components/form-wsr-work.vue'
import { useResignQuery } from '@/shared-fe/services/resign-service'

const commonStore = useStoreCommon()
const route = useRoute()
const objWsrDetail = ref(null)
const activeTabFormDetail = ref(true)
const objWsrWork = ref(<any>null)
const { getUserByUserId } = useUserQuery()
const { getItemResignWsr } = useResignQuery()
const id = route?.params?.id
const historyRef = ref(null)
onBeforeUnmount(() => {})

// get data detail (start)
onMounted(async () => {
    if (id) {
        await getDetail()
    }
})

const getDetail = async () => {
    let res = await getUserByUserId(String(id))
    const resignWsr = await getItemResignWsr(id)
    let data = res?.data?.data
    let resignData = resignWsr?.data?.data
    objWsrDetail.value = {
        ...data,
        email: data?.personal_email,
    }

    objWsrWork.value = {
        id: data.id,
        note: data.note,
        area_name: data.area?.id,
        zone_name: data.zone?.id,
        ss_name: data.parent_id?.id,
        route_code: data.route_code?.name,
        code: data.code,
        code_before: data.code,
        start_date: data.start_date,
        last_working_date: data.last_working_date,
        idCandidate: data.candidates?.length > 0 ? data.candidates[0].id : -1,
        employment_status_name: data.employment_status ? USER_STATUS[data.employment_status]?.value : '',
        resignId: resignData && resignData.length > 0 ? resignData[0]?.id : null,
        interview: resignData && resignData.length > 0 ? resignData[0]?.interview : null,
        reason: resignData && resignData.length > 0 ? resignData[0]?.reason : null,
        interview_note: resignData && resignData.length > 0 ? resignData[0]?.interview_note : null,
        reason_note: resignData && resignData.length > 0 ? resignData[0]?.reason_note : null,
    }
}

// get data detail (end)

// handle logic header (start)
const router = useRouter()
const save = () => {
    commonStore.submitEvent({
        name: SUBMIT_EVENT.WSR_DETAIL_SUBMIT,
        payload: MODE_EDIT,
    })
}

const handleRefetch = async () => {
    await getDetail()
    setTimeout(() => {
        historyRef?.value?.getData()
    }, 1000)
}

const navigateBack = () => {
    router.go(-1)
}

const batchDeleteFun = async () => {
    await batchDelete('status', objWsrDetail.value.id)
    router.push({
        name: PATH_ROUTER.WSR_LIST.NAME,
    })
}

const { confirmDelete, deleting, batchDelete, error: deleteError } = useBatch(USER_COLLECTION)
// handle logic header (end)
</script>
