import { useApi } from '@directus/extensions-sdk'
import { EXEMPTION_CERTIFICATE_COLLECTION } from '../utils/table-name'

export const useExemptionCertificateQuery = () => {
    const api = useApi()
    const postItemExemptionCertificate = async (params: any) => {
        return await api.post(`/items/${EXEMPTION_CERTIFICATE_COLLECTION}`, params)
    }

    const patchItemExemptionCertificate = async (id: number, params: any) => {
        return await api.patch(`/items/${EXEMPTION_CERTIFICATE_COLLECTION}/${id}`, params)
    }

    const getItemExemptionCertificate = async (id: any) => {
        return await api.get(`/items/${EXEMPTION_CERTIFICATE_COLLECTION}/${id}`, {
            params: {
                fields: [
                    [
                        '*',
                        'user.full_name',
                        'user.route_code.name',
                        'user.code',
                        'user.zone.*',
                        'user.area.name',
                        'user.system_providers.provider_id.name',
                        'user.parent_id.full_name',
                        'user.parent_id.role.short_name',
                    ],
                ],
            },
        })
    }

    return {
        postItemExemptionCertificate,
        getItemExemptionCertificate,
        patchItemExemptionCertificate,
    }
}
