import { toLowerCaseNonAccentVietnamese } from '@/shared-fe/utils/convert-data'
import Joi from 'joi'

export const useAppImportExamValidate = () => {
    const columnLabels = {
        id: 'stt',
        question_type: 'loai cau hoi',
        is_multiple: 'dang',
        note: 'ghi chu',
        question: 'cau hoi',
        A: 'a',
        B: 'b',
        C: 'c',
        D: 'd',
        E: 'e',
        F: 'f',
        correct_answer: 'dap an dung',
        explain: 'giai thich'
    }
    const choiceArray = ['A', 'B', 'C', 'D', 'E', 'F']
    const messageLabels = {
        question: 'Cột câu hỏi',
        A: 'Cột câu trả lời A',
        B: 'Cột câu trả lời B',
        C: 'Cột câu trả lời C',
        D: 'Cột câu trả lời D',
        E: 'Cột câu trả lời E',
        F: 'Cột câu trả lời F',
        correct_answer: 'Cột đáp án đúng',
        explain: 'Cột giải thích',
    }
    const customMessages = {
        'number.base': 'lỗi định dạng',
        'string.base': 'lỗi định dạng',
        'string.empty': 'đang trống dữ liệu',
        'any.required': 'đang trống dữ liệu',
    }

    const schema = Joi.object({
        question: Joi.string().required().messages(customMessages),
        correct_answer: Joi.string().required().messages(customMessages),
        // explain: Joi.string().required().messages(customMessages),
    }).unknown(true)

    const getIndexOfColumnDependOnHeader = (headers: string[], columnName: string) => {
        let columnNameAscii = toLowerCaseNonAccentVietnamese(columnName.trim())
        return headers.indexOf(columnNameAscii)
    }

    const getDataCorrect = (data: string, header: string[]) => {
        let lowercaseData = data.toLowerCase()
        return getIndexOfColumnDependOnHeader(header, lowercaseData) || 0
    }

    const getCorrectAnswerArray = (correct_answer: string, header: string[]) => {
        let arrDataCorrect = []
        // check correct answer and push the correct answer to arrDataCorrect array
        // in case of multiple correct answers
        if (correct_answer.length > 1) {
            let arr = correct_answer.split(',')
            arr.map((item: any) => {
                let trimData = item.trim()
                if (trimData && getDataCorrect(trimData, header) !== 0) {
                    arrDataCorrect.push(trimData)
                }
            })
            // in case of single correct answers
        } else {
            if (correct_answer.trim()) {
                arrDataCorrect.push(correct_answer.trim())
            }
        }
        return arrDataCorrect
    }

    const validate = (formData: any, header: string[]) => {
        const { error } = schema.validate(formData, { abortEarly: false })
        let messages: string[] = []
        if (error?.details) {
            error.details.map((detail) => {
                const customLabel = messageLabels[detail.context.key]
                messages.push(`\"${customLabel} ${detail.message}\"`)
            })
        }

        const { A, B, C, D, E, F, correct_answer } = formData
        if (!A && !B && !C && !D && !E && !F) {
            messages.push("Câu trả lời đang trống dữ liệu")
        }

        let correctArr = getCorrectAnswerArray(correct_answer, header)
        let isMapping = false
        correctArr.map(item => {
            if (formData[item]) {
                isMapping = true
            }
        })

        if (!isMapping) {
            messages.push("Câu trả lời đúng đang không tồn tại trong danh sách câu trả lời")
        }

        return {
            error: !!error,
            data: messages,
        }
    }

    return {
        messageLabels,
        schema,
        validate,
        columnLabels,
        getCorrectAnswerArray,
        getDataCorrect,
        getIndexOfColumnDependOnHeader,
        choiceArray
    }
}
