<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            :loading="loading"
            :disabled="disabled"
            manualSortKey="tel"
            :sort="internalSort"
        >
            <template #[`item.candidate_status`]="{ item }">
                <c-status :collectionName="INTERVIEW_DETAIL_COLLECTION" :id="item.candidate_status"></c-status>
            </template>

            <template #[`item.status`]="{ item }">
                <c-status :collectionName="INTERVIEW_DETAIL_COLLECTION" :id="item.status"></c-status>
            </template>
            <template #[`item.date_created`]="{ item }">
                {{ item.date_created ? dayjs(item.date_created).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.interviewer`]="{ item }">
                {{ item.user_created?.full_name }}
            </template>
            <template #[`item.position`]="{ item }">
                {{ item.user_created?.role?.name }}
            </template>
            <template #[`item.content`]="{ item }">
                <span class="whitespace-normal">
                    {{ item.content }}
                </span>
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import { INTERVIEW_DETAIL_COLLECTION } from '../../../../../shared-fe/utils/table-name'
const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })

const emit = defineEmits(['update:modelSelected', 'update:toPage', 'deleteItem', 'updateSort'])

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'Ngày giờ',
        value: 'date_created',
    },
    {
        text: 'Trạng thái',
        value: 'candidate_status',
    },
    {
        text: 'Người phỏng vấn',
        value: 'interviewer',
    },
    {
        text: 'Vị trí',
        value: 'position',
    },
    {
        text: 'Đánh giá',
        value: 'status',
    },
    {
        text: 'Nội dung đánh giá',
        value: 'content',
        width: 400,
    },
])
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
