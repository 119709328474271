<template>
    <div class="w-full mt-10">
        <div v-if="itemsRender.length >= 0">
            <v-table
                class="c-table mt-8"
                :headers="headers"
                :items="itemsRender"
                v-model:modelValue="modelValue"
                :disabled="disabled"
                manualSortKey="tel"
                :sort="internalSort"
                @update:sort="updateSort($event)"
                :loading="props.loading"
            >
                <template #[`item.parent_id`]="{ item }">
                    {{ item.parent_id?.full_name }}
                </template>
                <template #[`item.start_date`]="{ item }">
                    {{ item.start_date ? dayjs(item.start_date).format(FORMAT_TIME.DDMMYYYY) : '' }}
                </template>
                <template #[`item.employment_status`]="{ item }">
                    <c-status :collectionName="USER_COLLECTION" :id="item.employment_status"></c-status>
                </template>
                <template #[`item.dismiss`]="{ item }">
                    <v-button style="background-color: '#DBEAFE'" rounded icon small @click="detailRowOnClick(item)">
                        <v-icon style="color: '#3B82F6'" name="visibility"></v-icon>
                    </v-button>
                </template>
            </v-table>
            <div class="footer">
                <div class="c-pagi pagination">
                    <v-pagination
                        v-if="totalPages > 1"
                        :length="totalPages"
                        :total-visible="7"
                        show-first-last
                        :model-value="page"
                        @update:model-value="toPage"
                    />
                </div>
            </div>
        </div>
    </div>
    <modal-target-detail
        :is-show="isShowDialogDetail"
        :data="dataModal"
        @close="isShowDialogDetail = false"
        :user="dataModal?.id"
        mode="user"
        @refresh="emit('refresh')"
    ></modal-target-detail>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import cStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import { USER_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import modalTargetDetail from './modal-target-detail.vue'

const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}
const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    modelSelected: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['update:modelSelected', 'update:toPage', 'updateSort', 'openDialog', 'refresh'])

const disabled = ref(false)
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const isShowDialogDetail = ref(false)
const dataModal = ref({})
const idUser = ref('')
const modelValue = computed({
    get() {
        return props.modelSelected !== undefined ? props.modelSelected : []
    },
    set(newActive: any) {
        emit('update:modelSelected', newActive)
    },
})

const itemsRender = computed(() => {
    let _items = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: 'Chi nhánh',
        value: 'zone',
    },
    {
        text: 'Khu vực',
        value: 'area_name',
    },
    {
        text: 'ASM',
        value: 'parent_id',
    },
    {
        text: 'Họ và tên SS',
        value: 'full_name',
        width: 250,
    },
    {
        text: 'Email',
        value: 'email',
        width: 300,
    },
    {
        text: 'Nhà phân phối',
        value: 'system_providers',
    },
    {
        text: 'Điện thoại',
        value: 'phone_number',
    },
    {
        text: 'Thời gian gia nhập',
        value: 'start_date',
    },
    {
        text: 'Tháng gia nhập',
        value: 'start_month',
    },
    {
        text: 'Trạng thái',
        value: 'employment_status',
    },
    {
        text: 'Chỉ tiêu',
        value: 'dismiss',
    },
])

const detailRowOnClick = (data) => {
    dataModal.value = data
    idUser.value = dataModal.value?.id
    isShowDialogDetail.value = true
}

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    let sortKey = sort?.by
    if (sort?.desc) sortKey = `-${sortKey}`
    emit('updateSort', sortKey)
}
</script>

<style lang="scss"></style>
