import { PATH_ROUTER } from '@/shared-fe/utils/path'
import '@/style.scss'
import { defineModule } from '@directus/extensions-sdk'
import 'uno.css'
import WsrList from './routes/wsr/wsr.vue'
import WsrDetail from './routes/wsr/detail/detail.vue'
import WsrAdd from './routes/wsr/add/add.vue'
import { USER_TYPE } from '@/shared-fe/utils/constant'
import WsrImport from './routes/wsr/import/import.vue'

export default defineModule({
    id: 'pepsi-agency',
    name: 'WSR Module',
    icon: 'school',
    routes: [
        {
            name: 'agency-index',
            path: '',
            redirect: PATH_ROUTER.WSR_LIST.PATH,
            children: [
                {
                    name: PATH_ROUTER.WSR_LIST.NAME,
                    path: PATH_ROUTER.WSR_LIST.PATH,
                    component: WsrList,
                    meta: {
                        permission: PATH_ROUTER.WSR_LIST.PERMISSION,
                        user_type: USER_TYPE.WSR
                    }
                },
                {
                    name: PATH_ROUTER.WSR_DETAIL.NAME,
                    path: PATH_ROUTER.WSR_DETAIL.PATH,
                    component: WsrDetail,
                    meta: {
                        permission: PATH_ROUTER.WSR_DETAIL.PERMISSION,
                        user_type: USER_TYPE.WSR
                    },
                },
                {
                    name: PATH_ROUTER.WSR_ADD.NAME,
                    path: PATH_ROUTER.WSR_ADD.PATH,
                    component: WsrAdd,
                    meta: {
                        permission: PATH_ROUTER.WSR_ADD.PERMISSION,
                        user_type: USER_TYPE.WSR
                    },
                },
                {
                    name: PATH_ROUTER.IMPORT_WSR.NAME,
                    path: PATH_ROUTER.IMPORT_WSR.PATH,
                    component: WsrImport,
                    meta: {
                        permission: PATH_ROUTER.IMPORT_WSR.PERMISSION,
                        user_type: USER_TYPE.WSR
                    },
                },
            ],
        }
    ],
})
