<template>
    <c-access :roles="[USER_ROLE.SS]">
        <sidebar-detail icon="pending" class="interview-steps" title="Route Ride">
            <v-skeleton-loader v-if="loadingInterview" class="c-loading" type="input-tall" />
            <div v-else>
                <div class="mb-2 text-[#1E293B]">
                    Chọn ngày Route Ride
                    <span class="required">*</span>
                </div>
                <div class="mb-6 box-route-ride" :class="statusDateRouteRide ? 'active' : ''">
                    <v-menu ref="dateTimeMenuCard" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                :disabled="statusDateRouteRide"
                                readonly
                                :model-value="routeRideDateDisplay"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="routeRideDate ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': routeRideDateDisplay,
                                            'today-icon': !routeRideDateDisplay,
                                        }"
                                        v-on="{ click: routeRideDate ? unsetValueDate : null }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <c-date-picker
                            type="date"
                            :include-seconds="true"
                            :use-24="true"
                            :disabled="statusDateRouteRide"
                            :model-value="routeRideDate"
                            @update:model-value="(e) => handleChangeDate(e)"
                            @close="dateTimeMenuCard?.deactivate"
                            :minDate="dayjs().format(FORMAT_TIME.YYYMMDD)"
                        />
                    </v-menu>
                    <span class="message-error" v-if="errorDate">{{ errorDate }}</span>
                </div>
                <div class="mb-2 text-[#1E293B]">Ngày kết thúc Route Ride</div>
                <div class="mb-4">
                    <v-input
                        :disabled="true"
                        readonly
                        :model-value="finishedRouteRide"
                        placeholder="Ngày kết thúc Route Ride"
                    ></v-input>
                </div>
                <div class="flex justify-start">
                    <div class="w-20px h-20px mr-2">
                        <div class="i-mdi-alert-circle-outline text-20px text-primary-base"></div>
                    </div>
                    <div>Vui lòng hoàn tất Route Ride theo quy định trước ngày đã chọn</div>
                </div>
                <div
                    class="list-btn flex gap-3 w-full mt-6 pt-6 border-t-1px border-t-solid border-[#D3DAE4]"
                    v-if="!statusDateRouteRide"
                >
                    <v-button class="w-full btn-pass" @click="() => submit()">
                        <div class="">Lưu ngày Route Ride</div>
                    </v-button>
                </div>
                <div class="mt-3 text-[#D97706]">{{ messageInterview }}</div>
            </div>
        </sidebar-detail>
    </c-access>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import CAccess from '../../../../../../shared-fe/components/c-access/c-access.vue'
import CDatePicker from '../../../../../../shared-fe/components/c-datepicker/c-datepicker.vue'
import { useInterviewDetailQuery } from '../../../../../../shared-fe/services/interview-detail-service'
import { useOptionQuery } from '../../../../../../shared-fe/services/option-service'
import { CANDIDATE_STATUS, FORMAT_TIME, USER_ROLE } from '../../../../../../shared-fe/utils/constant'
import dayjs from 'dayjs'
import { useUserStore } from '@/stores/user'
import { onMounted } from 'vue'
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})
const content = ref('')
const loadingInterview = ref(false)
const messageInterview = ref('')
const arrStatus = ref([])
const errorDate = ref('')
const routeRideDateDisplay = ref('')
const finishedRouteRide = ref('')
const { postItemInterviewDetail, sendService } = useInterviewDetailQuery()
const userStore: any = useUserStore()
const emit = defineEmits(['handleSubmit'])
const routeRideDate = ref('')
const dateTimeMenuCard = ref()
const handleChangeDate = (e) => {
    routeRideDate.value = e || ''
    routeRideDateDisplay.value = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
    finishedRouteRide.value = e ? dayjs(e).add(1, 'day').format(FORMAT_TIME.DDMMYYYY) : ''
}
const unsetValueDate = (e) => {
    e.preventDefault()
    e.stopPropagation()
    routeRideDate.value = ''
    routeRideDateDisplay.value = ''
}

const statusDateRouteRide = computed(() => {
    if (props.item.date_route_ride) {
        routeRideDateDisplay.value = dayjs(props.item.date_route_ride).format(FORMAT_TIME.DDMMYYYY)
        finishedRouteRide.value = dayjs(props.item.date_route_ride).add(1, 'day').format(FORMAT_TIME.DDMMYYYY)
    }
    return props.item.date_route_ride
})
onMounted(async () => {
    const options = userStore.currentUser.options
    let _arrStatus: any = []
    if (options.length > 0) {
        options.forEach((x) => {
            if (x.opt_group === 'candidate_status') {
                _arrStatus.push({
                    opt_key: x.opt_key,
                    id: x.id,
                })
            }
        })
    }
    arrStatus.value = _arrStatus
})
const submit = async () => {
    errorDate.value = ''
    if (!routeRideDate.value) {
        errorDate.value = 'Vui lòng nhập ngày route ride'
        return
    }

    loadingInterview.value = true
    let findIdStatus = arrStatus.value?.find((x: any) => x.opt_key === CANDIDATE_STATUS.route_ride.value)
    let params: any = {
        candidate_status: CANDIDATE_STATUS.route_ride.value,
        candidate_status_id: findIdStatus?.id,
        id: props.item.candidate,
        date_route_ride: routeRideDate.value,
        interview_round: props.item?.id,
    }

    await postItemInterviewDetail({
        ...params,
    })
    setTimeout(() => {
        messageInterview.value = 'Đã tạo ngày Route Ride'
        loadingInterview.value = false
        content.value = ''
        setTimeout(() => {
            emit('handleSubmit')
        }, 2000)
    }, 2000)
}
</script>
<style lang="scss">
.box-route-ride {
    position: relative;
    &.active {
        &::after {
            content: ' ';
            display: block;
            background: #eee;
            opacity: 0.1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
