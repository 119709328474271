<template>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9 bg-[#EFFAFA]">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Họ và tên nhân viên</div>
                <div>
                    {{ item.dcr_full_name }}
                </div>
            </div>
            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Mã DCR</div>
                <div>
                    {{ item.code }}
                </div>
            </div>
            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Mã Route</div>
                <div>
                    {{ item.route_code }}
                </div>
            </div>

            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Chi nhánh</div>
                <div>
                    {{ item.zone_name }}
                </div>
            </div>

            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Khu vực</div>
                <div>
                    {{ item.area_name }}
                </div>
            </div>

            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">SS</div>
                <div>
                    {{ item.ss_full_name }}
                </div>
            </div>

            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Nhà phân phối</div>
                <div>
                    {{ item.system_provider_name }}
                </div>
            </div>

            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Ngày bắt đầu thử việc</div>
                <div>
                    {{ item.start_date }}
                </div>
            </div>

            <div class="col-span-1">
                <div class="text-[12px] color-[#64748B]">Ngày kết thúc thử việc</div>
                <div>
                    {{ item.end_date }}
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})
console.log('item: ', props.item)
</script>
