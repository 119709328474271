import { useApi } from '@directus/extensions-sdk'
import { RECRUITMENT_REQUEST_HISTORY_COLLECTION } from '../utils/table-name'
export const useRecruitmentRequestHistoryQuery = () => {
    const api = useApi()

    const getRecruitmentRequestHistory = async (limit: number, idRecruitmentRequest: number, currentPage: number) => {
        return await api.get(`/items/${RECRUITMENT_REQUEST_HISTORY_COLLECTION}`, {
            params: {
                limit: limit,
                page: currentPage,
                fields: ['date_created', 'note', 'user_created.full_name', 'user_created.role.name'],
                meta: ['filter_count'],
                filter: {
                    recruitment_request: {
                        id: idRecruitmentRequest,
                    },
                },
            },
        })
    }

    return {
        getRecruitmentRequestHistory,
    }
}
