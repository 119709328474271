<template>
    <div class="container w-full flex justify-center bg-[#F8FAFC]">
        <div class="min-w-[500px] h-[350px] p-8 flex flex-col justify-center items-center mt-12 bg-white">
            <div class="mb-2 font-bold text-lg text-[#1E293B]">{{ title }}</div>
            <div class="mb-6 text-[#4B5563]">Đang xử lý {{ progress.progressing.progressing }}/{{ total }} item...</div>
            <v-icon class="mb-6" style="color: #cbd5e1" filled x-large name="cloud_upload"></v-icon>
            <v-progress-linear rounded :value="progressing" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { useStoreLoading } from '../../stores/loading'
import { computed, toRefs } from 'vue'
const props = defineProps({
    total: {
        type: Number,
        default: 0,
    },
    title: {
        type: String,
        default: '',
    },
})

const progress = useStoreLoading()
const { total, title } = toRefs(props)

const progressing = computed(() => {
    return progress.progressing.progressing !== 0
        ? Math.floor((progress.progressing.progressing * 100) / total.value)
        : 0
})
</script>

<style lang="scss">
.v-progress-linear .inner {
    background-color: #67c2c3 !important;
}
.container {
    height: calc(100vh - 85px);
}
</style>
