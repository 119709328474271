<template>
    <template v-if='displayImage.title'>
        <div class='info flex gap-2 items-center'>
            <v-icon-file :ext='ext'/>
            <div class="flex-full">
                <div class='title font-bold'>{{ displayImage.title }}</div>
                <div class='meta'>{{ meta }}</div>
                <div class="text-blue-500 cursor-pointer" @click="showPopup">
                    Xem chi tiết
                </div>


                <v-drawer
                    @cancel="internalActive = false"
                    v-model="internalActive"
                    :title="`Chi tiết ${displayImage.title}`"
                >
                    <template #subtitle>
                        subtitle
                    </template>


                    <div class="content px-4">
                        <div v-if="generalType === 'image'">
                            <img class="w-full" :src="src" alt="">
                        </div>
                        <div v-if="generalType === 'pdf'" >
                            <iframe class="aspect-1 border border-gray-300 rounded w-full" :src="src"></iframe>
                        </div>
                        <div v-if="generalType === 'doc' || generalType === 'ppt'">
                            <iframe class="w-full custom-h aspect-1" :src="src || ''"></iframe>
                        </div>
                    </div>
                </v-drawer>
            </div>
        </div>
    </template>
</template>

<script lang="ts" setup>
import {onMounted, ref, computed} from "vue";
import api, {addTokenToURL} from "@/api";
import {readableMimeType} from "@/utils/readable-mime-type";
const props = defineProps(["id"])

const internalActive = ref(false)
const showPopup = () =>{
    internalActive.value = true;
}

const displayImage = ref<any>({});
const src = computed(() => {
    if (!displayImage.value.id) return null

    if (displayImage.value.type.includes('svg')) {
        return '/assets/' + displayImage.value.id
    }

    if (
        displayImage.value.type.includes('image')
    || displayImage.value.type.includes('pdf')
    ) {
        const fit = props.crop ? 'cover' : 'contain'
        const url = `/assets/${displayImage.value.id}?key=system-large-${fit}&cache-buster=${displayImage.value.modified_on}`
        return addTokenToURL(url) ?? ''
    }

    if (displayImage.value.type.includes('doc') || displayImage.value.type.includes('ppt')) {
        let url = `https://view.officeapps.live.com/op/embed.aspx?src=${linkFilesToView(
            displayImage.value.id
        )}?ui=vi-VN&rs=vi-VN`
        return url ?? ''
    }

    return null
})

const generalType = computed(() => {
    if (displayImage.value.type.includes('image')) return 'image'
    if (displayImage.value.type.includes('pdf')) return 'pdf'
    if (displayImage.value.type.includes('doc')) return 'doc'
    if (displayImage.value.type.includes('ppt')) return 'ppt'

    return  false;
})

const meta = ref("")
const linkFilesToView = (idFile: string) => {
    return `${window.location.origin}/assets/${idFile}`
}

const ext = computed(() => (displayImage.value ? readableMimeType(displayImage.value.type, true) : 'unknown'))

const fetchFile = async () => {
    displayImage.value = {}
    api.get(`/files/${props.id}`, {
        params: {
            fields: ['id', 'title', 'width', 'height', 'filesize', 'type', 'filename_download', 'modified_on']
        }
    }).then(img => {
        displayImage.value = img.data.data
    }).catch(() => {
    })
}

onMounted(async () => {
    fetchFile().catch()
});
</script>
