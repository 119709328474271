<script setup lang="ts">
import CYoutube from '@/shared-fe/components/c-youtube/index.vue'
// import CPlayer from '@/shared-fe/components/c-video/c-player.vue'
import { linkFilesToView } from '@/shared-fe/utils/link-files-to-view'
import { computed, onMounted, ref } from 'vue'

interface CurrentItem {
    id: number | string
    url_embed?: string
    time?: number
    extension_file: string
}

interface PropsType {
    currentItem: CurrentItem
    posterType: string
}

const props = defineProps<PropsType>()

const src = computed(() => {
    if (props.currentItem.url_embed) return props.currentItem.url_embed

    let src = linkFilesToView(props.currentItem.id as string, !isOffice.value)
    if (isOffice.value) {
        src = `https://view.officeapps.live.com/op/embed.aspx?src=${src}&ui=vi-VN&rs=vi-VN`
    }

    return src
})

function extractYouTubeVideoId(link) {
    const regex = /\/embed\/([^\/?]+)/
    const match = regex.exec(`${link}?dad=3`)
    if (match && match[1]) {
        return match[1] // Trả về ID video từ nhóm con thứ nhất
    } else {
        return null // Trả về null nếu không tìm thấy hoặc không hợp lệ
    }
}

const isOffice = computed(() => {
    return (
        props.currentItem.extension_file === 'pdf' ||
        props.currentItem.extension_file === 'ppt' ||
        props.currentItem.extension_file === 'pptx'
    )
})

const isVideo = computed(() => {
    return ['video'].indexOf(props.currentItem.extension_file) !== -1
})

const isVideoEmbbed = computed(() => {
    return ['url_embed'].indexOf(props.currentItem.extension_file) !== -1
})
const textTip = computed(() => {
    return isOffice.value
        ? 'Sử dụng mũi tên trái phải ( &lt; > ) hoặc click để chuyển slide tiếp theo'
        : 'Bấm vào video để phát và dừng video'
})

const emit = defineEmits(['ended', 'pause', 'play'])

const key = ref(0)
const forcePlayVideo = () => {
    key.value++
}

const player = ref(null)
const video = ref(null)
defineExpose({
    play: () => {
        if (isVideo.value) {
            if (video.value) video?.value?.play()
        }
        if (isVideoEmbbed.value) {
            if (player.value) player?.value?.rundCommand('playVideo')
        }
    },
    pause: () => {
        if (isVideo.value) {
            if (video.value) video?.value?.pause()
        }
        if (isVideoEmbbed.value) {
            if (player.value) player?.value?.rundCommand('pauseVideo')
        }
    },
    getCurrentTime: () => {
        if (isVideo.value) {
            if (video.value) return Number(video?.value?.currentTime || 0)
        }
        if (isVideoEmbbed.value) {
            if (video.value) return Number(player.value?.getCurrentTime())
        }
    },
})

const bodyVideo = ref(null)
const height = ref(0)
const width = ref(0)

function calcFrame() {
    console.log(window.innerWidth < window.innerHeight)
    if (window.innerWidth < window.innerHeight) {
        width.value = window.innerWidth
        height.value = (width.value * 9) / 16
    } else {
        height.value = window.innerHeight - 105
        width.value = (height.value * 16) / 9
    }
}

const handlePlayOrPause = (e: any) => {
    if (e.target.paused) {
        e.target.currentTime = props.currentItem.time
        e.target.play()
    } else {
        emit('pause', e.target.currentTime)
        e.target.pause()
    }
}

onMounted(() => {
    calcFrame()
})

window.addEventListener('resize', () => {
    calcFrame()
})
</script>

<template>
    <div class="flex flex-col gap-0 h-full" v-if="currentItem && currentItem.id">
        <div class="flex-grow flex flex-col justify-center" ref="bodyVideo">
            <div :style="`height:${height}px; width: ${width}px`" class="mx-auto max-w-full">
                <template v-if="height > 0">
                    <template v-if="isVideo">
                        <video
                            ref="video"
                            class="w-full custom-h cursor-pointer"
                            @click="handlePlayOrPause"
                            @ended="emit('ended')"
                            :src="src"
                        ></video>
                        <!-- <c-player
                            :key="key"
                            :autoplay="true"
                            ref="player"
                            @ended="emit('ended')"
                            :src="src"
                            :time="props.currentItem.time"
                            :poster_type="props.posterType"
                        ></c-player> -->
                    </template>
                    <template v-else-if="isVideoEmbbed">
                        <c-youtube
                            :start="props.currentItem.time"
                            ref="player"
                            :url="src"
                            @ended="emit('ended')"
                        ></c-youtube>
                    </template>
                    <template v-else-if="isOffice">
                        <iframe class="w-full custom-h custom-h aspect-1" :src="src"></iframe>
                    </template>
                </template>
            </div>
        </div>

        <div class="bg-white text-center flex-none h-[40px] border-1 border-solid border-[#ddd] py-2">
            <i>{{ textTip }}</i>
            <template v-if="isVideo">
                <b class="pl-3">
                    <button class="bg-yellow-100 px-2 rounded" @click="forcePlayVideo">
                        BẤM nếu Video không chạy được
                    </button>
                </b>
            </template>
        </div>
    </div>
</template>
