<template>
    <c-role>
        <private-view title="Danh sách nghỉ việc">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation />
            </template>

            <!-- navigation end  -->
            <!-- header start -->
            <template #actions>
                <c-access :roles="[USER_ROLE.HR]">
                    <span class="custom-button-export-excel">
                        <v-button secondary @click="exportData" :loading="_export.loadingExcel.value">
                            <v-icon name="file_upload" outline />
                            <span class="inline-block ml-2">{{ EXPORT_EXCEL }}</span>
                        </v-button>
                    </span>
                </c-access>

                <c-access :roles="[]">
                    <v-dialog v-if="modelSelected?.length > 0" v-model="confirmDelete" @esc="confirmDelete = false">
                        <template #activator="{ on }">
                            <v-button
                                v-tooltip.bottom="LABEL_DELETE"
                                rounded
                                icon
                                class="action-delete danger-action"
                                secondary
                                @click="on"
                            >
                                <div class="delete_outline"></div>
                            </v-button>
                        </template>

                        <v-card>
                            <v-card-title>{{ CONTENT_DELETE }}</v-card-title>

                            <v-card-actions>
                                <v-button secondary @click="confirmDelete = false">
                                    {{ CANCEL }}
                                </v-button>
                                <v-button kind="danger" :loading="deleting" @click="batchDeleteFun">
                                    {{ LABEL_DELETE }}
                                </v-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </c-access>

                <!-- <v-button
                    v-if="modelSelected?.length > 0"
                    v-tooltip.bottom="EDIT"
                    rounded
                    icon
                    secondary
                    @click="batchEditActive = true"
                >
                    <div class="i-mdi-pencil-outline"></div>
                </v-button> -->
                <c-access :roles="[USER_ROLE.SS, USER_ROLE.ASM]" :deniedAccessAdmin="true">
                    <v-button
                        v-tooltip.bottom="CREATE_ITEM"
                        class="custom-create-button"
                        :to="PATH_ROUTER.RESIGN_ADD.PATH"
                    >
                        Tạo đơn nghỉ việc
                    </v-button>
                </c-access>
                <drawer-batch
                    v-model:active="batchEditActive"
                    :primary-keys="primaryKeys"
                    :collection="RESIGN_COLLECTION"
                    @refresh="batchRefresh"
                />
            </template>
            <!-- header end -->
            <div class="mx-9">
                <!-- searchbar start -->
                <resign-searchbar
                    :arrayValue="objSearchStore?.dataSeletedForm"
                    :arrayStatus="arrayStatus"
                    @update:handleSearch="handleSearch"
                />
                <!-- searchbar end -->

                <!-- content start -->
                <resign-table
                    :items="itemsRender"
                    :modelSelected="modelSelected"
                    @update:modelSelected="modelSelectedFun"
                    :totalPages="totalPages"
                    :page="page"
                    :loading="loading"
                    @update:toPage="handleToPage"
                    @updateSort="handleUpdateSort"
                />
                <!--  content end -->
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" title="Danh sách ứng viên" :close="false" />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { useItems } from '@directus/composables'
import { useStores } from '@directus/extensions-sdk'
import dayjs from 'dayjs'
import { computed, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import CAccess from '../../../../shared-fe/components/c-access/c-access.vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../shared-fe/composables/use-batch'
import { useExport } from '../../../../shared-fe/composables/use-export'
import { useField } from '../../../../shared-fe/composables/use-field'
import { useStoreSearch } from '../../../../shared-fe/stores/search'
import {
    DELETE_VALUE,
    FORMAT_TIME,
    RECORD_PER_PAGE,
    RESIGN_STATUS,
    USER_ROLE,
} from '../../../../shared-fe/utils/constant'
import { displayParentName, formatDateObjectToQuery, sortData } from '../../../../shared-fe/utils/convert-data'
import { CANCEL, CONTENT_DELETE, CREATE_ITEM, EXPORT_EXCEL, LABEL_DELETE } from '../../../../shared-fe/utils/message'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import { RESIGN_COLLECTION } from '../../../../shared-fe/utils/table-name'
import ResignSearchbar from './components/resign-searchbar.vue'
import ResignTable from './components/resign-table.vue'
import { useUserStore } from '@/stores/user'
const route = useRoute()
// table start
const { getSearch, updateSearch } = useStoreSearch()
let objSearchStore: any = computed(() => {
    return getSearch(route.fullPath)
})
console.log('objSearchStore: ', objSearchStore)
const { getArrayStatus } = useField()
const sort = ref(objSearchStore.value?.dataInitSearch?.sort || ['-id'])
const filter = ref(objSearchStore.value?.dataInitSearch?.filter || <any>null)
const page = ref(objSearchStore.value?.dataInitSearch?.page || 1)
const userStore = useUserStore()
const customFilter = computed(() => {
    let _filter: any = {
        status: {
            _neq: DELETE_VALUE,
        },
    }
    if (userStore?.currentUser?.role?.short_name == USER_ROLE.HR) {
        _filter = {
            ..._filter,
            user_created: {
                area: {
                    id: {
                        _in: userStore.currentUser?.ares_management?.map((a) => a.id),
                    },
                },
            },
        }
    }
    if (filter.value && Object.entries(filter.value).length > 0) {
        _filter = {
            ..._filter,
            ...filter.value,
        }
    }

    return _filter
})

const primaryKeys = computed(() => {
    return modelSelected.value?.map((x) => x.id) || []
})
const { items, totalPages, loading, getItems, getTotalCount, getItemCount } = useItems(ref(RESIGN_COLLECTION), {
    fields: ref([
        'status',
        'id',
        'staff_id.zone.name',
        'staff_id.area.name',
        'staff_id.system_providers.provider_id.name',
        'staff_id.parent_id.role.short_name',
        'staff_id.parent_id.full_name',
        'staff_id.full_name',
        'staff_id.code',
        'staff_id.route_code.name',
        'last_working_day',
        'date_created',
        'date_approved',
        'hr_id.full_name',
        'interview',
    ]),
    limit: ref(RECORD_PER_PAGE),
    sort,
    search: ref(null),
    filter: customFilter,
    page: page,
})

const itemsRender = computed(() => {
    let _itemsRender: any = []
    let index = page.value * RECORD_PER_PAGE - RECORD_PER_PAGE
    items.value.forEach((x) => {
        index++
        _itemsRender.push({
            stt: index,
            id: x.id,
            zone: x.staff_id?.zone?.name,
            area: x.staff_id?.area?.name,
            system_providers:
                x.staff_id?.system_providers?.length > 0 ? x.staff_id.system_providers[0].provider_id.name : '',
            ss: x?.staff_id?.parent_id
                ? displayParentName(x.staff_id?.parent_id?.role?.short_name, x.staff_id.parent_id?.full_name)
                : '',
            full_name: x.staff_id?.full_name,
            code: x.staff_id?.code,
            route_code: x.staff_id?.route_code?.name,
            last_working_day: x.last_working_day,
            status: x.status,
            date_created: x.date_created,
            date_approved: x.date_approved,
            hr_name: x.hr_id?.full_name,
            status_pv: x?.interview,
        })
    })

    return _itemsRender
})

const handleToPage = (_toPage: number) => {
    page.value = _toPage
    updateSearchStore({ page: _toPage })
}

const updateSearchStore = (objInit, objForm = null) => {
    objSearchStore.value.dataInitSearch = { ...objSearchStore.value?.dataInitSearch, ...objInit }
    updateSearch(route.fullPath, objSearchStore.value?.dataInitSearch, objForm)
}

const arrayStatus = computed(() => {
    return getArrayStatus(RESIGN_COLLECTION)
})

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
const refresh = () => {
    getItems()
    getTotalCount()
    getItemCount()
}

const modelSelectedFun = (data) => {
    modelSelected.value = data
}

const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    let _filter: any = {}

    _filter.staff_id = {}
    if (data.area) {
        _filter.staff_id = {
            ..._filter.staff_id,
            area: {
                id: data.area,
            },
        }
    }
    if (data.zone) {
        _filter.staff_id = {
            ..._filter.staff_id,
            zone: {
                id: data.zone,
            },
        }
    }
    if (data.system_providers) {
        _filter.staff_id = {
            ..._filter.staff_id,
            system_providers: {
                provider_id: data.system_providers,
            },
        }
    }

    if (data.last_working_day && data.last_working_day.length > 0) {
        let dateQuery = formatDateObjectToQuery(data.last_working_day[0], data.last_working_day[1])
        _filter.last_working_day = {
            _between: dateQuery,
        }
    }

    if (data.status) {
        _filter.status = data.status
    }

    if (data.ss) {
        _filter.staff_id = {
            ..._filter.staff_id,
            parent_id: data.ss,
        }
    } else if (data.asm) {
        _filter._or = [
            { staff_id: { parent_id: data.asm } },
            {
                staff_id: {
                    parent_id: {
                        parent_id: data.asm,
                    },
                },
            },
        ]
        _filter.asm = data.asm
    }

    for (const [key, value] of Object.entries(_filter)) {
        if (!value) {
            delete _filter[key]
        }
    }
    if (data.title) {
        _filter._and = [
            {
                _or: [
                    {
                        staff_id: {
                            full_name: {
                                _contains: data.title,
                            },
                        },
                    },
                    {
                        staff_id: {
                            code: {
                                _contains: data.title,
                            },
                        },
                    },
                    {
                        staff_id: {
                            route_code: {
                                name: {
                                    _contains: data.title,
                                },
                            },
                        },
                    },
                    {
                        staff_id: {
                            phone_number: {
                                _contains: data.title,
                            },
                        },
                    },
                    {
                        staff_id: {
                            id_card_number: {
                                _contains: data.title,
                            },
                        },
                    },
                ],
            },
        ]
    }
    filter.value = _filter
    delete filter.value?.asm

    updateSearchStore(
        {
            search: data.title,
            filter: {
                ..._filter,
            },
        },
        data
    )
}

// table end

// actions start

const batchRefresh = async () => {
    modelSelected.value = []
    await refresh()
}

const batchDeleteFun = async () => {
    await batchDelete('status')
    await refresh()
}

const {
    deleting,
    error: deleteError,
    batchEditActive,
    modelSelected,
    confirmDelete,
    batchDelete,
} = useBatch(RESIGN_COLLECTION)

const _export = useExport(RESIGN_COLLECTION, {
    stt: 'STT',
    id: 'Mã đơn',
    date_created: 'Ngày tạo đơn',
    'staff_id.zone.name': 'Chi nhánh',
    'staff_id.area.name': 'Khu vực',
    'staff_id.parent_id.parent_id.full_name': 'ASM',
    'staff_id.system_providers.provider_id.code': 'Mã NPP',
    'staff_id.system_providers.provider_id.name': 'Nhà phân phối',
    'staff_id.parent_id.full_name': 'SS',
    'staff_id.route_code.name': 'Mã Route',
    'staff_id.code': 'Mã DCR',
    'staff_id.full_name': 'Nhân viên',
    first_working_day: 'Ngày bắt đầu làm việc',
    last_working_day: 'Ngày lv cuối cùng',
    status: 'Trạng thái',
    interview: 'Trạng thái phỏng vấn',
    date_approved: 'Ngày duyệt đơn',
    'reason.type_of_resign': 'Loại nghỉ việc - SS',
    'reason.opt_value': 'Lý do - SS',
    note: 'Chi tiết lí do - SS',
    'interview.type_of_resign': 'Loại nghỉ việc P&C',
    'interview.opt_value': 'Lý do - P&C',
    interview_note: 'Chi tiết lí do - P&C',
    'hr_id.full_name': 'Người phụ trách',
})
_export.filename.value = 'ExportResign.xlsx'
_export.beforeGenerate.value = (items) => {
    return items.map((item, index) => {
        return {
            stt: index + 1,
            id: item.id,
            date_created: item.date_created ? new Date(item.date_created) : '',
            zone_name: item.staff_id?.zone?.name,
            area_name: item.staff_id?.area?.name,
            staff_id_parent_id_parent_id_full_name: item?.staff_id?.parent_id?.parent_id?.full_name,
            staff_id_system_providers_provider_id_id:
                item.staff_id?.system_providers?.length > 0 ? item.staff_id?.system_providers[0].provider_id?.code : '',
            system_providers:
                item.staff_id?.system_providers?.length > 0 ? item.staff_id?.system_providers[0].provider_id?.name : '',
            parent_id_full_name: item.staff_id?.parent_id?.full_name,
            route_code: item.staff_id?.route_code?.name,
            code: item.staff_id?.code,
            full_name: item.staff_id?.full_name,
            first_working_day: item?.first_working_day ? new Date(item.first_working_day) : '',
            last_working_day: item?.last_working_day ? new Date(item.last_working_day) : '',
            status: RESIGN_STATUS[item.status]?.text,
            interview_status: item?.interview ? 'Đã phỏng vấn' : 'Chưa phỏng vấn',
            date_approved: item.date_approved ? new Date(item.date_approved) : '',
            ss_resign_type: item?.reason?.type_of_resign,
            reason: item?.reason?.opt_value,
            note: item?.note,
            pc_resign_type:
                item?.interview?.opt_value === 'Không liên lạc được'
                    ? item?.reason?.type_of_resign
                    : item?.interview?.type_of_resign,
            interview: item?.interview?.opt_value,
            interview_note: item?.interview_note,
            hr_id_full_name: item.hr_id?.full_name,
        }
    })
}
const exportData = () => {
    _export.filter.value = customFilter.value
    _export.sort.value = sort.value
    _export.run()
}
// actions end
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
</script>

<style lang="scss" scoped></style>
