<template>
    <div v-if="isDisplayInterviewRound">
        <interview_1 :item="interviewItem" v-if="displayInterview1" @handleSubmit="emit('handleSubmit')"></interview_1>
        <interview_2 :item="interviewItem" v-if="displayInterview2" @handleSubmit="emit('handleSubmit')"></interview_2>
        <route-ride :item="interviewItem" v-if="displayRouteRide" @handleSubmit="emit('handleSubmit')"></route-ride>
        <train-route-ride v-if="displayTrainRR" @handleSubmit="emit('goToRouteRide')"></train-route-ride>
        <route-ride-result
            :item="interviewItem"
            v-if="displayRouteRideResult"
            @handleSubmit="emit('handleSubmit')"
        ></route-ride-result>
        <interview_3 :item="interviewItem" v-if="displayInterview3" @handleSubmit="emit('handleSubmit')"></interview_3>
        <send-mail :item="interviewItem" v-if="displaySendMail" @handleSubmit="emit('handleSubmit')"></send-mail>
        <wait-for-job
            :item="interviewItem"
            v-if="displayWaitForJob"
            @handleSubmit="emit('handleSubmit')"
        ></wait-for-job>
    </div>
</template>
<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import dayjs from 'dayjs'
import Interview_1 from './interview-items/interview-1.vue'
import Interview_2 from './interview-items/interview-2.vue'
import Interview_3 from './interview-items/interview-3.vue'
import RouteRide from './interview-items/route-ride.vue'
import RouteRideResult from './interview-items/route-ride-result.vue'
import SendMail from './interview-items/send-mail.vue'
import TrainRouteRide from './interview-items/train-route-ride.vue'
import WaitForJob from './interview-items/wait-for-job.vue'
import {CANDIDATE_STATUS, RECRUITMENT_REQUEST_STATUS} from '../../../../../shared-fe/utils/constant'

const emit = defineEmits(['handleSubmit', 'goToRouteRide'])
const props = defineProps({
    interviewItem: {
        type: Object,
        default: null,
    },
    candidateStatus: {
        type: Object,
        default: '',
    },
})
const isDisplayInterviewRound = computed(() => {
    if (props.candidateStatus?.opt_value === CANDIDATE_STATUS.hired.value) {
        return false
    }
    return true
})
const displayInterview1 = computed(() => {
    if (!isDisplayInterviewRound.value) return false
    return props.interviewItem?.pv1 === null
})

const displayInterview2 = computed(() => {
    if (!isDisplayInterviewRound.value) return false
    return props.interviewItem?.pv1 === true && props.interviewItem?.pv2 === null && props.interviewItem?.recruitment_request?.status === RECRUITMENT_REQUEST_STATUS.processing.value
})
const displayRouteRide = computed(() => {
    if (!isDisplayInterviewRound.value) return false
    if (displayRouteRideResult.value) return false

    return props.interviewItem?.pv2 === true && !props.interviewItem?.date_route_ride
})

const displayTrainRR = computed(() => {
    if (!isDisplayInterviewRound.value) return false
    if (props.interviewItem?.date_route_ride && props.interviewItem.ride_step !== 'completed') {
        return true
    }
    return false
})

const displayRouteRideResult = computed(() => {
    if (!isDisplayInterviewRound.value) return false

    if (!props.interviewItem?.date_route_ride) return false;
    if (props.interviewItem?.route_ride !== null) return false;

    const currentDate = (new Date())
    currentDate.setHours(0, 0, 0, 0)

    const targetDate = (new Date(props.interviewItem?.date_route_ride))
    targetDate.setHours(0, 0, 0, 0)

    // Chỉ cần qua ngày là có thể đánh giá RR được.
    return currentDate.getTime() > targetDate.getTime()
})

const displayInterview3 = computed(() => {
    if (!isDisplayInterviewRound.value) return false
    return props.interviewItem?.route_ride === true && props.interviewItem?.pv3 === null
})

const displaySendMail = computed(() => {
    if (!isDisplayInterviewRound.value) return false
    return props.interviewItem?.pv3 === true && !props.interviewItem?.date_send_email
})

const displayWaitForJob = computed(() => {
    if (!isDisplayInterviewRound.value) return false
    return props.interviewItem?.date_send_email && props.interviewItem?.staff === null
})
</script>
<style lang="scss">
.interview-steps {
    .list-btn {
        .btn-fail {
            .button {
                background-color: #fee2e2;
                color: #ef4444;
                border: 1px solid #f87171;
                width: 100%;
                min-width: auto;
            }
        }

        .btn-pass {
            .button {
                width: 100%;
                background-color: #10b981;
                color: white;
                min-width: auto;
            }
        }

        .btn-send-mail {
            .button {
                background-color: #1e4ba1;
                color: white;
                border: 1px solid #1e4ba1;
                width: 100%;
                min-width: auto;
            }
        }

        .btn-send-zns {
            .button {
                width: 100%;
                background-color: #60a5fa;
                border: 1px solid #60a5fa;
                color: white;
                min-width: auto;
            }
        }
    }
}
</style>
