<template>
    <div class="flex gap-16 py-12 px-[70px] h-full w-full">
        <div class="min-w-[329px]">
            <div
                class="px-4 py-3 text-[#374151] cursor-pointer"
                v-for="item in LIST_TAB_SATURDAY_REPORT"
                :key="item.key"
                :class="keyNumber === item.key ? 'bg-[#F1F5F9]' : ''"
                @click="openTab(item.key)"
            >
                {{ item.title }}
            </div>
        </div>
        <div class="vertical-dash"></div>
        <div class="w-full">
            <div class="flex gap-8 w-full mb-12">
                <div class="w-full">
                    <div class="type-label">Từ ngày</div>
                    <v-menu ref="dateTimeMenuStart" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="formData.start_date_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.start_date ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.start_date_display,
                                            'today-icon': !formData.start_date_display,
                                        }"
                                        v-on="{
                                            click:
                                                formData.start_date && !loadingExcel
                                                    ? unsetValue(e, 'start_date')
                                                    : null,
                                        }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <v-date-picker
                            type="date"
                            :disabled="loadingExcel"
                            :include-seconds="true"
                            :use-24="true"
                            :model-value="formData.start_date"
                            @update:model-value="(e) => handleChange(e, 'start_date')"
                            @close="dateTimeMenuStart?.deactivate"
                        />
                    </v-menu>
                    <div>
                        <span class="message-error" v-if="errors">Vui lòng nhập ngày bắt đầu và ngày kết thúc</span>
                    </div>
                </div>
                <div class="w-full">
                    <div class="type-label">Đến ngày</div>
                    <v-menu ref="dateTimeMenuEnd" :close-on-content-click="false" attached full-height seamless>
                        <template #activator="{ toggle, active }">
                            <v-input
                                :active="active"
                                clickable
                                readonly
                                :model-value="formData.end_date_display"
                                placeholder=""
                                @click="toggle"
                            >
                                <template #append>
                                    <v-icon
                                        :name="formData.end_date ? 'clear' : 'today'"
                                        :class="{
                                            active,
                                            'clear-icon': formData.end_date_display,
                                            'today-icon': !formData.end_date_display,
                                        }"
                                        v-on="{
                                            click:
                                                formData.end_date && !loadingExcel ? unsetValue(e, 'end_date') : null,
                                        }"
                                    />
                                </template>
                            </v-input>
                        </template>

                        <v-date-picker
                            type="date"
                            :include-seconds="true"
                            :use-24="true"
                            :disabled="loadingExcel"
                            :model-value="formData.end_date"
                            @update:model-value="(e) => handleChange(e, 'end_date')"
                            @close="dateTimeMenuEnd?.deactivate"
                        />
                    </v-menu>
                    <div>
                        <span class="message-error" v-if="errors">Vui lòng nhập ngày bắt đầu và ngày kết thúc</span>
                    </div>
                </div>
            </div>
            <div class="flex gap-5 justify-end">
                <v-button @click="handleExportExcel" :loading="loadingExcel" :disabled="loadingExcel">
                    <v-icon class="mr-2" name="file_download" />
                    Xuất Excel
                </v-button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import { EMPLOYMENT_STATUS, FORMAT_TIME } from '../../../../../shared-fe/utils/constant'
import { useApi } from '@directus/composables'
import { formatDateObjectToQuery } from '@/shared-fe/utils/convert-data'
import { DCR_EXISTS_COLLECTION, RESIGN_COLLECTION, USER_COLLECTION } from '@/shared-fe/utils/table-name'
import { DCR_ON_REPORTS, RESIGN_REPORTS } from '../headers/headers'

const dataDefault = {
    start_date: null,
    start_date_display: null,
    end_date: null,
    end_date_display: null,
}

const keyNumber = ref(1)
const dateTimeMenuStart = ref()
const dateTimeMenuEnd = ref()
const errors = ref('')
const loadingExcel = ref(false)
const pageExcel = ref(<any>1)
const dataResignsStaffId = ref(<any>[])
const dataResignsReport = ref(<any>[])
const dataDcrsOn = ref(<any>[]);
const api = useApi()

const openTab = (key: any) => {
    keyNumber.value = key
}

const formData = ref(JSON.parse(JSON.stringify(dataDefault)))

const LIST_TAB_SATURDAY_REPORT = computed(() => [
    {
        key: 1,
        title: 'Báo cáo nghỉ việc',
    },
])

const handleChange = (e: any, variable: any) => {
    if (!loadingExcel.value) {
        switch (variable) {
            case 'start_date':
                formData.value.start_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
                formData.value[variable] = e || ''
                break
            case 'end_date':
                formData.value.end_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
                formData.value[variable] = e || ''
                break
            default:
                break
        }
    }
}

const unsetValue = (e: any, variable: string) => (e) => {
    e.preventDefault()
    e.stopPropagation()

    switch (variable) {
        case 'start_date':
            formData.value.start_date = ''
            formData.value.start_date_display = ''
            break
        case 'end_date':
            formData.value.end_date = ''
            formData.value.end_date_display = ''
            break
        default:
            break
    }
}
// Report
const handleExportExcel = async () => {
    if (!formData.value.end_date || !formData.value.start_date) {
        errors.value = 'error'
        return
    }
    loadingExcel.value = true
    switch(keyNumber.value){
        // Báo cáo nghỉ việc
        case 1:
            dataResignsStaffId.value = []
            dataResignsReport.value = []
            dataDcrsOn.value = []
            await getResignUser()
            await getDataResignReport()
            await dcrsOn();
            await generateExcelKey1()
            break;
    }
    loadingExcel.value = false
}

////////////////////////////////////////////////////// tab 1
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

const getResignUser = async () => {
    // query param
    const params = {
        limit: 100,
        page: pageExcel.value,
        sort: ['user_created', 'date_created'],
        fields: ['staff_id', 'id'],
        filter: {
            _and: [
                {
                    last_working_day: {
                        _between: formatDateObjectToQuery(formData.value.start_date, formData.value.end_date),
                    },
                },
                {
                    status: {
                        _in: ['approved', 'pending'],
                    },
                },
            ],
        },
    }

    // call api
    const res = await api
        .get(`/items/${RESIGN_COLLECTION}`, {
            params: params,
        })
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })

    dataResignsStaffId.value = dataResignsStaffId.value.concat(
        res.data.map((x: any) => {
            return {
                user: x.staff_id,
                resign: x.id,
            }
        })
    )
    if (res?.data?.length > 0) {
        pageExcel.value++
        await getResignUser().catch()
    } else {
        pageExcel.value = 1
    }
}

const getDataResignReport = async () => {
    for (let i = 0; i < dataResignsStaffId.value.length; i += 100) {
        let dataReport = dataResignsStaffId.value.slice(i, i + 100)
        // call api
        const res = await api
            .post(`/api-learnings/report/resign/`, [...dataReport])
            .then((res: any) => {
                return res.data
            })
            .catch((_e: any) => {
                console.log(_e)
                return {
                    data: [],
                }
            })

        if (res?.code === 'success') {
            dataResignsReport.value = dataResignsReport.value.concat(res.result)
        }
    }
}

const generateExcelKey1 = async () => {
    const { utils, writeFile } = XLSX
  
    const transformedItemsResign = dataResignsReport.value.map((item: any) => {
        const imageLinkCell = [
            item.last_working_number_month,
            item.zone,
            item.area,
            item.provider_code,
            item.provider_name,
            item.route_name,
            item.dcr_code,
            item.dcr_name,
            item.start_date,
            item.last_working_date,
            item.last_working_string_month,
            item.is_exemption,
            item.resign_type,
            item.resign_reason_final,
            item.resign_reason_hr,
            item.resign_reason_hr_detail,
            item.resign_reason_ss,
            item.resign_reason_ss_detail,
            item.setting_resign_type,
            item.location_detail,
            item.asm_name,
            item.ss_name,
            item.working_number,
            item.seniority,
            item.modify_onboarding_result,
            item.route_type,
        ]

        return imageLinkCell
    })

    const transformedItemsDcrOn = dataDcrsOn.value.map((x?: any) => {
        const imageLinkCell = [
            x.month_str,
            x.month_number,
            x.location,
            x.provider_code,
            x.provider_name,
            x.route_id,
            x.dcr_code,
            x.dcr_name,
            x?.start_date ? new Date(x?.start_date) : '',
            x.location,
            x.location_detail,
            x.area,
            x.route_type,
        ]

        return imageLinkCell
    })

    const wb = utils.book_new()
    const wsResign = utils.aoa_to_sheet([RESIGN_REPORTS])
    const wsDcrOns = utils.aoa_to_sheet([DCR_ON_REPORTS])

    utils.sheet_add_aoa(wsResign, transformedItemsResign, { origin: -1 })
    utils.sheet_add_aoa(wsDcrOns, transformedItemsDcrOn, { origin: -1 })
    utils.book_append_sheet(wb, wsResign, `Danh sách DCR nghỉ việc`)
    utils.book_append_sheet(wb, wsDcrOns, `Dcr hiện hữu`)

    writeFile(
        wb,
        dayjs(formData.value.start_date).format(FORMAT_TIME.DDMMYYYY) +
            '_to_' +
            dayjs(formData.value.end_date).format(FORMAT_TIME.DDMMYYYY) +
            '_Details_of_resigns.xlsx'
    )
}

const getDcrsOnFromDcrExists = async (dates: any) => {
    
    // query param
    const params = {
        limit: 100,
        page: pageExcel.value,
        sort: ['-date_created'],
        fields: "*",
        filter: {
            date_created: {
                _between: dates
            },
        },
    }

    // call api
    const res = await api
        .get(`/items/${DCR_EXISTS_COLLECTION}`, {
            params: params,
        })
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })
    dataDcrsOn.value = dataDcrsOn.value.concat(res.data.map(x => {
        return {
            month_str: x?.month_str,
            month_number: x?.month_number,
            location: x?.location,
            provider_code: x?.provider_code,
            provider_name: x?.provider_name,
            route_id: x?.route_id,
            dcr_code: x?.dcr_code,
            dcr_name: x?.dcr_name,
            start_date: x?.start_date ? new Date(x?.start_date) : "",
            location_detail: x?.location_detail,
            area: x?.area,
            route_type: x?.route_type,
        }
    }))

    if (res?.data?.length > 0) {
        pageExcel.value++
        await getDcrsOnFromDcrExists(dates).catch()
    } else {
        pageExcel.value = 1
    }
}

const getDcrsOnFromUser = async () => {
    
    // query param
    const params = {
        limit: 100,
        page: pageExcel.value,
        sort: ['-date_created'],
        fields: "*",
    }

    // call api
    const res = await api
        .get(`/api-hr/report/dcr-exists`, {
            params: params,
        })
        .then((res: any) => {
            return res.data
        })
        .catch((_e: any) => {
            console.log(_e)
            return {
                data: [],
            }
        })

    dataDcrsOn.value = dataDcrsOn.value.concat(res.result.map(x => {
        return {
            ...x,
            start_date: x?.start_date ? new Date(x?.start_date) : "",
        }
    }))

    if (res.result.length > 0) {
        pageExcel.value++
        await getDcrsOnFromUser().catch()
    } else {
        pageExcel.value = 1
    }
}
const dcrsOn = async () => {
    const startDate = dayjs(formData.value.start_date);
    const endDate = dayjs(formData.value.end_date);
    const currentDate = dayjs();
    let stepMonth = startDate.get("month") + 1;
    let stepYear = startDate.get("year");
    const currentMonth = currentDate.get("month") + 1;
    const currentYear = currentDate.get("year");
    while (stepYear < endDate.get("year") || stepMonth <= endDate.get("month") + 1) {
        if(currentMonth == stepMonth && currentYear == stepYear){
            await getDcrsOnFromUser();
        }else{
           let dateFrom = dayjs().set("year", stepYear).set("month", stepMonth - 1).set("date", 1).set("hour", 10);
           let dateTo = dateFrom.set("date", new Date(dateFrom.get("year"), dateFrom.get("month") + 1, 0).getDate())
           await getDcrsOnFromDcrExists(formatDateObjectToQuery(dateFrom, dateTo));
        }

        stepMonth++;
        if (stepMonth > 12) {
            stepMonth = 1;
            stepYear++;
        }
    }
}

 
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// end tab 1
</script>

<style lang="scss" scoped>
.vertical-dash {
    width: 1px;
    background-color: #e2e8f0;
}
</style>
