<template>
    <div>
        <v-table
            class="c-table"
            :headers="headers"
            :items="itemsRender"
            show-resize
            :disabled="disabled"
            manualSortKey="tel"
            :sort="internalSort"
            :loading="loading"
            @update:sort="updateSort($event)"
        >
            <!-- @manual-sort="changeManualSort" -->
            <!-- :showManualSort="true" -->

            <template #[`item.status`]="{ item }">
                <c-status :collectionName="CANDIDATE_COLLECTION" :id="item.status?.opt_key"></c-status>
            </template>
            <template #[`item.id`]="{ item }">
                <span style="color: #62b1eb">{{ item.id }}</span>
            </template>
            <template #[`item.date_of_birth`]="{ item }">
                {{ item.date_of_birth ? dayjs(item.date_of_birth).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </template>
            <template #[`item.dismiss`]="{ item }">
                <v-button secondary @click="detailRowOnClick(item)">
                    <v-icon class="mr-2" name="visibility"></v-icon>
                    Xem chi tiết UV
                </v-button>
            </template>
        </v-table>
        <!-- <template #footer> -->
        <div class="footer">
            <div class="c-pagi pagination">
                <v-pagination
                    v-if="totalPages > 1"
                    :length="totalPages"
                    :total-visible="7"
                    show-first-last
                    :model-value="page"
                    @update:model-value="toPage"
                />
            </div>
        </div>
        <!-- </template> -->
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { Ref, computed, ref } from 'vue'
import CStatus from '../../../../../shared-fe/components/c-table/c-status/c-status.vue'
import { Sort } from '../../../../../shared-fe/components/c-table/type'
import { FORMAT_TIME, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import { CANDIDATE_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import { PATH_ROUTER } from '@/shared-fe/utils/path'
import { useRouter } from 'vue-router'
const toPage = (toPage: number) => {
    emit('update:toPage', toPage)
}

const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    totalPages: {
        type: Number,
        default: 1,
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    role: {
        type: String,
        default: '',
    },
})
const disabled = ref(false)
const router = useRouter()
const internalSort: Ref<Sort> = ref({ by: null, desc: false })

const emit = defineEmits(['update:toPage', 'updateSort'])

const itemsRender = computed(() => {
    let _items: any[] = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const detailRowOnClick = (data: any) => {
    router.push({
        name: PATH_ROUTER.CANDIDATE_DETAIL.NAME,
        params: {
            id: data.id,
        },
    })
}

const headers = computed(() => {
    let header = [
        {
            text: 'ID ứng viên',
            value: 'id',
            width: 130,
        },
        {
            text: 'Họ và tên',
            value: 'full_name',
            width: 250,
        },
        {
            text: 'Ngày sinh',
            value: 'date_of_birth',
        },
        {
            text: 'Điện thoại',
            value: 'phone_number',
        },
        {
            text: 'Trạng thái',
            value: 'status',
        },
    ]
    if (props.role === USER_ROLE.SS || props.role === USER_ROLE.ASM) {
        return header
    }
    return [
        ...header,
        {
            text: 'Thao tác',
            value: 'dismiss',
            width: 300,
        },
    ]
})

const convertKeySort = (sortKey: any, desc: any) => {
    let key = sortKey
    if (desc) key = `-${key}`
    return key
}
const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
    let sortKey = sort?.by
    if (sortKey !== 'stt') {
        emit('updateSort', convertKeySort(sortKey, sort?.desc))
    }
}
</script>

<style lang="scss">
.c-table {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
}
</style>
