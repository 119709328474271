<template>
    <c-role>
        <private-view title="Thông tin đào tạo - Thứ 7 học hay">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation name="2" />
            </template>

            <template #title>
                <div class="type-title flex items-justify">
                    <div>Cài đặt module E-Learning</div>
                </div>
            </template>

            <template #actions>
                <v-button :loading="saving" v-tooltip.bottom="'Lưu thay đổi'" icon rounded @click="save">
                    <v-icon name="check" />
                </v-button>
                <!--  -->
            </template>

            <div class="px-13 mb-4 flex gap-4 headTabs pb-8">
                <a
                    @click="module = 't7hh'"
                    :class="module === 't7hh' ? 'active' : ''"
                    class="border rounded-lg px-4 py-2 inline-block bg-gray-100 font-bold"
                    href="javascript:"
                >
                    Thứ 7 học hay
                </a>
                <a
                    @click="module = 'onboard'"
                    :class="module === 'onboard' ? 'active' : ''"
                    class="border rounded-lg px-4 py-2 inline-block bg-gray-100 font-bold"
                    href="javascript:"
                >
                    OnBoarding
                </a>
                <a
                    @click="module = 'online-library'"
                    :class="module === 'online-library' ? 'active' : ''"
                    class="border rounded-lg px-4 py-2 inline-block bg-gray-100 font-bold"
                    href="javascript:"
                >
                    Thư viện điện tử
                </a>
                <a
                    @click="module = 'general-setting'"
                    :class="module === 'general-setting' ? 'active' : ''"
                    class="border rounded-lg px-4 py-2 inline-block bg-gray-100 font-bold"
                    href="javascript:"
                >
                    Cài đặt chung
                </a>
            </div>

            <t7hh-form ref="formt7" v-if="module === 't7hh'"></t7hh-form>
            <onboarding-form ref="formOb" v-if="module === 'onboard'"></onboarding-form>
            <online-library-form ref="formLib" v-if="module === 'online-library'"></online-library-form>
            <general-setting ref="generalSetting" v-if="module === 'general-setting'"></general-setting>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useApi } from '@directus/composables'
import GeneralSetting from './components/general-setting.vue'
import OnlineLibraryForm from './components/online-library-form.vue'
import T7hhForm from './components/t7hh-form.vue'
import OnboardingForm from './components/onboarding-form.vue'

const module = ref('t7hh')

const generalSetting = ref(null)
const formt7 = ref(null)
const formOb = ref(null)
const formLib = ref(null)

const saving = ref(false)
const save = () => {
    saving.value = true
    setTimeout(() => (saving.value = false), 2000)
    if (module.value == 't7hh') {
        formt7.value.save()
    } else if (module.value == 'onboard') {
        formOb.value.save()
    } else if (module.value == 'online-library') {
        formLib.value.save()
    } else {
        generalSetting.value.save()
    }
}
</script>

<style>
.headTabs a.active {
    background: var(--primary);
    color: white;
}
</style>
