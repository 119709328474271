<template>
    <v-overlay v-if="items" :active="props.overlay">
        <div class="p-5 bg-white rounded-md max-h-[90vh] overflow-y-scroll">
            <h2
                class="text-18px border-b-1 border-solid border-t-none border-r-none border-l-none pb-5px text-[#0F172A] font-bold mt-5"
            >
                Ứng viên Không hợp lệ
            </h2>
            <div class="table-hand-made mt-4">
                <div v-if="items && items.length > 0" class="box-table max-h-260px">
                    <ul v-for="item in candidateCannotLinkedYCTD">
                        <li>{{ item?.full_name }}</li>
                    </ul>
                </div>
            </div>
            <div class="flex gap-x-5 justify-end flex-row mt-10">
                <v-button class="btn-modal w-1/3" secondary @click="emit('close')">Hủy bỏ</v-button>
                <v-button class="btn-modal w-1/3" v-if="candidateLinkedYCTD?.length > 0" @click="handleSubmit">
                    Xác nhận
                </v-button>
            </div>
        </div>
    </v-overlay>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
    overlay: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array<Object>,
        default: false,
    },
})
const emit = defineEmits(['submit', 'close'])
const handleSubmit = () => {
    emit('submit')
}
const candidateCannotLinkedYCTD = computed(() => {
    return props.items.filter((x) => x.request_id)
})
const candidateLinkedYCTD = computed(() => {
    return props.items.filter((x) => !x.request_id)
})
</script>

<style lang="scss" scoped>
.table-hand-made {
    .box-table {
        min-width: 400px !important;
    }
}
</style>
