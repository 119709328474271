<template>
    <c-role :canAccessPage="true">
        <private-view :title="infoTrainingTitle">
            <!-- navigation start  -->
            <template #navigation>
                <c-navigation :name="isName" />
            </template>
            <template #title>
                <h1 class="type-title leading-[28px]">
                    Thông tin đào tạo - {{ labelUserType }} Onboarding
                    <br />
                    <span class="text-[#475569] text-[12px] font-normal">
                        Giới thiệu tổng quan về chương trình đào tạo {{ labelUserType }} ONboarding
                    </span>
                </h1>
            </template>
            <!-- navigation end  -->
            <!-- header start -->
            <div class="w-full">
                <div
                    class="tab-header w-full h-full flex xl:flex-row flex-col justify-center items-start xl:px-28 xl:py-16 px-10 px-5 mt-[-24px]"
                    :style="{ 'background-image': `url(${imageData?.constants_image[7]})` }"
                >
                    <div class="xl:w-1/2 w-full xl:mt-0 mt-5 mr-22 flex justify-center items-center">
                        <div class="xl:w-full w-[300px] max-w-[550px]">
                            <!-- <img :src="imageData.constants_image[9]" /> -->
                            <div v-if="course?.video" class="w-full h-full">
                                <iframe
                                    :src="course?.video"
                                    class="w-full aspect-video object-contain"
                                    frameborder="0"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="xl:w-1/2 md:w-2/3 flex flex-col justify-center xl:items-start items-center mx-auto">
                        <h5 class="font-bold xl:text-[32px] text-2xl text-white xl:mt-0 mt-3 leading-10 mb-5">
                            {{ course?.title }}
                        </h5>
                        <p class="font-normal text-base text-white leading-6">
                            {{ course?.description }}
                        </p>
                        <span class="font-normal icon-size my-8 text-white leading-5">
                            <v-icon name="play_circle" />
                            {{ course?.total_topic }} bài học
                        </span>
                    </div>
                </div>
                <div
                    class="bg-process flex w-full flex-row justify-center items-center gap-8"
                    :style="{ 'background-image': `url(${imageData?.constants_image[5]})` }"
                >
                    <button
                        class="success text-[18px] leading-6 text-center pt-4 pb-5 px-4"
                        @click="openTab(1)"
                        :class="
                            keyNumber === 1
                                ? 'text-white border-b-4 border-b-[#253A84] border-b-solid'
                                : 'text-[#E2E8F0]'
                        "
                    >
                        Tiến độ đào tạo
                    </button>
                    <button
                        @click="openTab(2)"
                        class="success text-[18px] leading-6 text-center pt-4 pb-5 px-4"
                        :class="
                            keyNumber === 2
                                ? 'text-white border-b-4 border-b-[#253A84] border-b-solid'
                                : 'text-[#E2E8F0]'
                        "
                    >
                        Khung chương trình
                    </button>
                </div>
                <div v-if="keyNumber === 1">
                    <dcr-status-train-table
                        :listProcessTRaining="listProcessTRaining"
                        @update:modelSelected="modelSelectedFun"
                        @updateSort="handleUpdateSort"
                    />
                </div>
                <div v-if="keyNumber === 2">
                    <list-module :lstModule="lstModule" :channel="channel" />
                </div>
            </div>
            <template #sidebar>
                <sidebar-detail icon="info" :title="infoTrainingTitle" close />
            </template>
        </private-view>
    </c-role>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import CNavigation from '../../../../shared-fe/components/c-navigation/c-navigation.vue'
import CRole from '../../../../shared-fe/components/c-role/c-role.vue'
import { useBatch } from '../../../../shared-fe/composables/use-batch'
import { useTrainingQuery } from '../../../../shared-fe/services/training-service'
import { useConstantsImage } from '../../../../shared-fe/stores/constants-image'
import { PROCESS_TRAINING } from '../../../../shared-fe/utils/table-name'
import DcrStatusTrainTable from './components/dcr-status-train-table.vue'
import ListModule from './components/list-module.vue'
import { useStores } from '@directus/composables'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const imageStore = useConstantsImage()
const route = useRoute()
const isName = '2'
const lstModule = ref([])
const { modelSelected } = useBatch(PROCESS_TRAINING)
const sort = ref(['-id'])
const channel = ref('')
const keyNumber = ref(1)
const imageData = computed(() => {
    return { ...imageStore.imageConstants }
})
const { useAppStore } = useStores()
const appStore = useAppStore()
const { sidebarOpen } = toRefs(appStore)
sidebarOpen.value = false
const openTab = (key) => {
    keyNumber.value = key
}
const { getSessionTrains } = useTrainingQuery()

const listProcessTRaining = ref(<any>[])
const course = ref(<any>{})

const labelUserType = computed(() => {
    return userStore?.currentUser?.label_user_type
})
const infoTrainingTitle = computed(() => {
    return `Thông tin đào tạo - ${labelUserType.value} Onboarding`
})

onMounted(() => {
    if (route.params?.session_train)
        getSessionTrains(route.params?.session_train).then((res) => {
            listProcessTRaining.value = res.data.result?.dcr_lists
            course.value = res.data.result?.course
            if (listProcessTRaining.value?.length > 0 && listProcessTRaining.value[0]?.list_modules?.length > 0) {
                let _temp: any = []
                listProcessTRaining.value[0]?.list_modules.forEach((x: any) => {
                    _temp.push(x)
                })
                lstModule.value = _temp
                course.value['total_topic'] = lstModule.value.length
                channel.value = course.value['channel']
            }
        })
})
const modelSelectedFun = (data) => {
    modelSelected.value = data
}
const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
</script>

<style lang="scss" scoped>
.tab-header {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 400px;
}
.bg-process {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.icon-size {
    --v-icon-size: 16px;
}
</style>
