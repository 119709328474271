<template>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">
                    Họ và tên
                    <span class="required">*</span>
                </div>
                <v-input
                    :disabled="!fullAccess"
                    type="text"
                    v-model="formData.full_name"
                    @update:model-value="(e) => handleChange(e, 'full_name')"
                ></v-input>
                <span class="message-error" v-if="errors?.full_name">Vui lòng nhập họ tên</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Ngày sinh
                    <span class="required">*</span>
                </div>
                <!-- <v-menu ref="dateTimeMenuDateOfBirth" :close-on-content-click="false" attached full-height seamless>
                    <template #activator="{ toggle, active }">
                        <v-input
                            :active="active"
                            :disabled="!fullAccess"
                            clickable
                            readonly
                            :model-value="formData.date_of_birth_display"
                            placeholder=""
                            @click="toggle"
                        >
                            <template #append>
                                <v-icon
                                    :name="formData.date_of_birth ? 'clear' : 'today'"
                                    :class="{
                                        active,
                                        'clear-icon': formData.date_of_birth_display,
                                        'today-icon': !formData.date_of_birth_display,
                                    }"
                                    v-on="{ click: formData.date_of_birth ? unsetValueDateOfBirth : null }"
                                />
                            </template>
                        </v-input>
                    </template>

                    <c-date-picker
                        type="date"
                        :include-seconds="true"
                        :use-24="true"
                        :disabled="!fullAccess"
                        :model-value="formData.date_of_birth"
                        @update:model-value="(e) => handleChange(e, 'date_of_birth')"
                        @close="dateTimeMenuDateOfBirth?.deactivate"
                    />
                </v-menu> -->
                <c-native-date
                    :disabled="!fullAccess"
                    :value="formData.date_of_birth"
                    @input="(e) => handleChange(e, 'date_of_birth')"
                ></c-native-date>
                <span class="message-error" v-if="errors?.date_of_birth_invalid">Chưa đủ 18 tuổi để ứng tuyển</span>
                <span class="message-error" v-if="errors?.date_of_birth">Vui lòng nhập ngày sinh</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Giới tính"
                    collection="options"
                    v-model:modelValue="formData.gender"
                    field_text="opt_value"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'gender')"
                    :query-filter="{ filter: { opt_group: 'gender' } }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.gender">Vui lòng nhập giới tính</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Điện thoại
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.phone_number"
                    @update:model-value="(e) => handleChange(e, 'phone_number')"
                ></v-input>
                <span class="message-error" v-if="errors?.phone_number">
                    SĐT phải là 10 chữ số và không được để trống
                </span>
            </div>
            <div class="col-span-1">
                <div class="type-label">Số điện thoại sử dụng zalo</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.phone_number_zalo"
                    @update:model-value="(e) => handleChange(e, 'phone_number_zalo')"
                ></v-input>
                <span class="message-error" v-if="errors?.phone_number_zalo">
                    SĐT Zalo phải là 10 chữ số
                </span>
            </div>
            <div class="col-span-1">
                <div class="type-label">Email</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.email"
                    @update:model-value="(e) => handleChange(e, 'email')"
                ></v-input>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Trình độ học vấn"
                    collection="options"
                    v-model:modelValue="formData.education_level"
                    field_text="opt_value"
                    field_value="id"
                    placeholder=""
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'education_level')"
                    :query-filter="{ filter: { opt_group: 'education_level' } }"
                ></c-select-m2o>
            </div>
            <div class="col-span-1">
                <div class="type-label">Tình trạng hôn nhân</div>
                <v-select
                    :model-value="formData.married"
                    :items="arrayMarriedStatus"
                    showDeselect
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'married')"
                ></v-select>
            </div>
        </div>
        <div class="border-bottom my-8"></div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">
                    Địa chỉ hiện tại
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.address"
                    @update:model-value="(e) => handleChange(e, 'address')"
                ></v-input>
                <span class="message-error" v-if="errors?.address">Vui lòng nhập địa chỉ</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    collection="provinces"
                    v-model:model-value="formData.district"
                    field_text="name"
                    field_value="id"
                    label="Quận huyện"
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'district')"
                    :query-filter="queryFilterDistrict"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.district">Vui lòng nhập quận huyện</span>
            </div>
            <div class="col-span-1">
                <c-select-m2o
                    label="Tỉnh thành"
                    collection="provinces"
                    v-model:modelValue="formData.province"
                    field_text="name"
                    field_value="id"
                    placeholder=""
                    required
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'province')"
                    :query-filter="{ filter: { parent_id: { _null: true } } }"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.province">Vui lòng nhập tỉnh thành</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    CCCD
                    <span class="required">*</span>
                </div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.id_card_number"
                    @update:model-value="(e) => handleChange(e, 'id_card_number')"
                ></v-input>
                <span class="message-error" v-if="errors?.id_card_number">Vui lòng nhập số CCCD</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Ngày cấp
                    <span class="required">*</span>
                </div>
                <!-- <v-menu ref="dateTimeMenuCard" :close-on-content-click="false" attached full-height seamless>
                    <template #activator="{ toggle, active }">
                        <v-input
                            :active="active"
                            clickable
                            readonly
                            :disabled="!fullAccess"
                            :model-value="formData.id_card_date_display"
                            placeholder=""
                            @click="toggle"
                        >
                            <template #append>
                                <v-icon
                                    :name="formData.id_card_date ? 'clear' : 'today'"
                                    :class="{
                                        active,
                                        'clear-icon': formData.id_card_date_display,
                                        'today-icon': !formData.id_card_date_display,
                                    }"
                                    v-on="{ click: formData.id_card_date ? unsetValueCarDate : null }"
                                />
                            </template>
                        </v-input>
                    </template>

                    <c-date-picker
                        type="date"
                        :include-seconds="true"
                        :use-24="true"
                        :disabled="!fullAccess"
                        :model-value="formData.id_card_date"
                        @update:model-value="(e) => handleChange(e, 'id_card_date')"
                        @close="dateTimeMenuCard?.deactivate"
                    />
                </v-menu> -->
                <c-native-date
                    :disabled="!fullAccess"
                    :value="formData.id_card_date"
                    @input="(e) => handleChange(e, 'id_card_date')"
                ></c-native-date>
                <span class="message-error" v-if="errors?.id_card_date">Vui lòng nhập ngày cấp</span>
            </div>
            <div class="col-span-1">
                <div class="type-label">
                    Nơi cấp
                    <span class="required">*</span>
                </div>
                <v-input
                    :disabled="!fullAccess"
                    :model-value="formData.id_card_place"
                    @update:model-value="(e) => handleChange(e, 'id_card_place')"
                ></v-input>
                <span class="message-error" v-if="errors?.id_card_place">Vui lòng nhập nơi cấp</span>
            </div>
        </div>
        <div class="border-bottom my-8"></div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <c-select-m2o
                    label="Tên ngân hàng"
                    collection="banks"
                    v-model:modelValue="formData.bank_name"
                    field_text="name"
                    field_value="id"
                    placeholder=""
                    :disabled="!fullAccess"
                    @update:model-value="(e) => handleChange(e, 'bank_name')"
                ></c-select-m2o>
            </div>
            <div class="col-span-1">
                <div class="type-label">Số tài khoản</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.bank_account_number"
                    @update:model-value="(e) => handleChange(e, 'bank_account_number')"
                ></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">Chi nhánh</div>
                <v-input
                    type="text"
                    :disabled="!fullAccess"
                    v-model="formData.bank_branch"
                    @update:model-value="(e) => handleChange(e, 'bank_branch')"
                ></v-input>
            </div>
        </div>
        <div class="border-bottom my-8"></div>

        <div class="grid grid-cols-1 gap-8">
            <div class="col-span-1">
                <div class="type-label">Danh sách file</div>
                <interface-files
                    collection="directus_users"
                    :enableCreate="true"
                    :enableSelect="false"
                    :disabled="!fullAccess"
                    field="attachments"
                    :folder="undefined"
                    :limit="15"
                    :primaryKey="formData.id"
                    :template="null"
                    v-model:value="formData.attachments"
                    @input="handleChangeUploadFile"
                ></interface-files>
            </div>
            <c-access :roles="[USER_ROLE.HR]">
                <div class="col-span-1">
                    <div class="type-label">Ghi chú</div>
                    <v-textarea
                        :disabled="!fullAccess"
                        :model-value="formData.note"
                        @update:model-value="(e) => handleChange(e, 'note')"
                    />
                </div>
            </c-access>
        </div>
    </div>
    <!-- <candidate-history :idCandidate="item.id"></candidate-history> -->
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import _ from 'lodash'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import CSelectM2o from '../../../../../shared-fe/components/c-select-m2o/c-select-m2o.vue'
import CNativeDate from '../../../../../shared-fe/components/c-native-date/c-native-date.vue'
import CDatePicker from '../../../../../shared-fe/components/c-datepicker/c-datepicker.vue'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import { FORMAT_TIME, MODE_EDIT, SUBMIT_EVENT, USER_ROLE } from '../../../../../shared-fe/utils/constant'
import Joi from 'joi'
import { ageValidation } from '@/shared-fe/utils/convert-data'
// import CandidateHistory from './candidate-history.vue'
const commonStore = useStoreCommon()
const { patchItemUser } = useUserQuery()

const props = defineProps({
    item: {
        type: Object,
        default: null,
    },
})
const { canAccess } = usePermission()
const fullAccess = computed(() => {
    return canAccess([USER_ROLE.HR])
})
const dataDefault = {
    id: 0,
    full_name: null,
    date_of_birth: null,
    gender: null,
    phone_number: null,
    phone_number_zalo: null,
    email: null,
    education_level: null,
    married: null,
    province: null,
    district: null,
    address: null,
    id_card_number: null,
    id_card_date: null,
    id_card_date_display: null,
    id_card_place: null,
    note: null,
    attachments: null,
    bank_account_number: null,
    bank_branch: null,
    bank_name: null,
}

const defaultDataEdit = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const formData = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const dateTimeMenuDateOfBirth = ref()
const dateTimeMenuCard = ref()
const errors: any = ref(null)

const queryFilterDistrict = computed(() => {
    return { filter: { parent_id: formData.value.province } }
})

const schema = Joi.object({
    full_name: Joi.string().required(),
    date_of_birth: Joi.string().required(),
    gender: Joi.number().required(),
    phone_number: Joi.string()
        .required()
        .regex(/^\d{10}$/),
    province: Joi.string().required(),
    district: Joi.string().required(),
    address: Joi.string().required(),
    id_card_number: Joi.string().required(),
    id_card_date: Joi.string().required(),
    id_card_place: Joi.string().required(),
}).unknown(false)

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(formData.value)) {
        formData.value[key] = props.item[key]
    }

    if (formData.value.date_of_birth) {
        formData.value.date_of_birth = dayjs(formData.value.date_of_birth).format(FORMAT_TIME.YYYMMDD)
        formData.value.date_of_birth_display = dayjs(formData.value.date_of_birth).format(FORMAT_TIME.DDMMYYYY)
    }
    if (formData.value.id_card_date) {
        formData.value.id_card_date = dayjs(formData.value.id_card_date).format(FORMAT_TIME.YYYMMDD)
        formData.value.id_card_date_display = dayjs(formData.value.id_card_date).format(FORMAT_TIME.DDMMYYYY)
    }

    defaultDataEdit.value = JSON.parse(JSON.stringify(formData.value))
}

watch(
    () => props.item,
    async () => {
        if (props.item) {
            initData()
        }
    },
    { immediate: true }
)

const arrayMarriedStatus = [
    {
        text: 'Độc thân',
        value: false,
    },
    {
        text: 'kết hôn',
        value: true,
    },
]
const handleChange = (e: any, variable: any) => {
    switch (variable) {
        case 'date_of_birth':
            formData.value.date_of_birth_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'id_card_date':
            formData.value.id_card_date_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'province':
            if (e !== props?.item?.province) {
                formData.value[variable] = e
                formData.value.district = ''
            } else {
                formData.value.district = props.item.district
            }
            break
        default:
            formData.value[variable] = e
            break
    }
}

const validateDateOfBirth = () => {
    if (formData.value.date_of_birth) {
        if (!ageValidation(new Date(formData.value.date_of_birth))) {
            return { date_of_birth_invalid: 'Chưa đủ 18 tuổi để ứng tuyển' }
        }
    }
    return {}
}

const handleChangeUploadFile = (data) => {
    formData.value.attachments = data
}
const unsetValueDateOfBirth = (e) => {
    formData.value.date_of_birth = ''
    formData.value.date_of_birth_display = ''
    unsetValue(e)
}

const unsetValueCarDate = (e) => {
    formData.value.id_card_date = ''
    formData.value.id_card_date_display = ''
    unsetValue(e)
}

const unsetValue = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
}

const validatePhoneZalo = () => {
    if (formData.value.phone_number_zalo) {
        let pattern = /^\d{10}$/
        if (!pattern.test(formData.value.phone_number_zalo)) {
            return { phone_number_zalo: 'phone_number_zalo is invalid ' }
        }
    }
    return {}
}
const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.DCR_DETAIL_SUBMIT)
    if (payloadSubmitForm) {
        errors.value = null
        const {
            id,
            email,
            education_level,
            married,
            id_card_date_display,
            note,
            code,
            refer_name,
            attachments,
            refer,
            status,
            request_id,
            phone_number_zalo,
            experience_detail,
            is_archived,
            bank_account_number,
            bank_branch,
            bank_name,
            date_of_birth_display,
            ...res
        } = formData.value
        const { error } = schema.validate(res, { abortEarly: false })
        if (error) {
            errors.value = error.details.reduce((acc, curr) => {
                acc[curr.path[0]] = curr.message
                return acc
            }, {})
        }

        errors.value = {
            ...errors.value,
            ...validatePhoneZalo(),
            ...validateDateOfBirth(),
        }

        if (Object.keys(errors.value).length !== 0) {
            return
        }

        let params = JSON.parse(JSON.stringify(formData.value))
        delete params?.date_of_birth_display
        params = JSON.parse(JSON.stringify(params))
        if (!params.attachments?.create) {
            delete params.attachments
        }

        params['personal_email'] = params.email
        delete params.email

        if (payloadSubmitForm.payload === MODE_EDIT) {
            delete params.id
            await patchItemUser(formData.value.id, params)
            commonStore.showDialogSucessUpdate = true
        }
    }
}

const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})
</script>
