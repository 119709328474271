<template>
    <v-dialog v-model="isShow" @esc="closeDialogFunc" placement="center custom-width">
        <template #activator="{ on }">
            <div class="text-center">
                <button
                    class="bg-[#FDCE26] text-black py-2 px-8 rounded-md"
                    :style="isDisabledStartExam ? { opacity: 0.5, cursor: 'auto' } : {}"
                    :disabled="isDisabledStartExam"
                    @click="on"
                >
                    <div class="flex items-center">
                        <div class="mr-2">Mở bài thi</div>
                        <v-icon v-if="!props.loading" style="color: #000" name="check"></v-icon>
                        <v-progress-circular v-if="props.loading" :x-small="false" :small="true" indeterminate />
                    </div>
                </button>
            </div>
        </template>

        <v-card>
            <v-card-title>
                <div class="w-full h-full">
                    <div class="text-5 text-[#1E293B] font-bold mb-8">Tải hình huấn luyện và mở khóa thi</div>
                    <interface-file-image
                        collection="topics"
                        :disabled="false"
                        :from-library="false"
                        :from-url="false"
                        field="image"
                        v-model:value="imageId"
                        @input="
                            (data) => {
                                imageId = data
                            }
                        "
                        width="full"
                    />
                </div>
            </v-card-title>

            <v-card-actions>
                <v-button :disabled="!imageId" class="custom-btn" @click="nextAction">Lưu & Mở khoá thi</v-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useProcessTrain } from '../../../../../shared-fe/stores/process-train'
const props = defineProps({
    prevExamModule: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const isShow = ref(false)
const imageId = ref(<any>null)
const emit = defineEmits(['nextAction'])
const processTrainStore = useProcessTrain()
const isDisabledStartExam = computed(() => {
    return !(processTrainStore.startExam && props.prevExamModule) && !props.loading
})
const nextAction = () => {
    isShow.value = false
    setTimeout(() => {
        emit('nextAction', imageId.value)
        imageId.value = ''
    }, 500)
}
const closeDialogFunc = () => {
    isShow.value = false
    imageId.value = ''
}
</script>
