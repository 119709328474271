<template>
    <div class="ml-7">
        <v-table
            class="c-table-onboard"
            :headers="headers"
            :items="itemsRender"
            show-resize
            :disabled="disabled"
            :sort="internalSort"
            :loading="loading"
            @update:sort="updateSort($event)"
        >
            <template #[`item.result`]="{ item }">
                <c-status :collectionName="MODIFY_ONBOARDING_COLLECTION" :id="item.result"></c-status>
            </template>
            <template #[`item.tool`]="{ item }">
                <v-button rounded icon secondary @click="emit('importFile')"><v-icon name="edit"></v-icon></v-button>
            </template>
        </v-table>
    </div>
</template>
<script setup lang="ts">
import { Sort } from '@/components/v-table/types'
import { sortBy } from 'lodash'
import { computed } from 'vue'
import { Ref } from 'vue'
import { ref } from 'vue'
import { MODIFY_ONBOARDING_COLLECTION } from '@/shared-fe/utils/table-name'
import CStatus from '@/shared-fe/components/c-table/c-status/c-status.vue'
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})


const props = defineProps({
    items: {
        type: Array<Object>,
        default: [],
    },
    page: {
        type: Number,
        default: 1,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})
const internalSort: Ref<Sort> = ref({ by: null, desc: false })
const emit = defineEmits(['updateSort, importFile'])

const itemsRender = computed(() => {
    let _items: any[] = []
    if (props.items && props.items.length > 0) {
        const sortedFlows = sortBy(props.items, [internalSort.value.by])
        return internalSort.value.desc ? sortedFlows.reverse() : sortedFlows
    }
    return _items
})

const headers = ref([
    {
        text: `Họ tên ${labelUserType.value}`,
        value: 'user.full_name',
    },
    {
        text: `Mã ${labelUserType.value}`,
        value: 'user.code',
    },
    {
        text: 'Số ngày WW',
        value: 'work_with',
    },
    {
        text: 'Số ngày học Elearning',
        value: 'elearning',
        width: 200,
    },
    {
        text: 'Số ngày WW trùng\n huấn luyện Elearning',
        value: 'duplicate',
        width: 200,
    },
    {
        text: 'Kết quả Sales Training',
        value: 'result',
        width: 300,
    },
    {
        text: 'Công cụ',
        value: 'tool',
    },
])

const detailRowOnClick = (data: any) => {}

const updateSort = (sort: Sort | null) => {
    internalSort.value = sort ?? { by: null, desc: false }
}
</script>

<style lang="scss">
.c-table-onboard {
    .custom-text {
        white-space: break-spaces;
        overflow: auto;
    }
    .table-header .cell .content > span {
        white-space: pre-wrap !important;
        color: #1939ae;
    }
}
</style>
