<template>
    <div v-if="module">
        <c-wrapp-learning>
            <template #header>
                <process-module-train-header
                    :module="module"
                    @goBack="goBack"
                    @play-video="handlePlayVideoWhenBrowserIsSafari"
                >
                    <template #boxControl>
                        <div class="flex flex-row justify-center items-center">
                            <div class="min-w-100px mr-20 cursor-pointer">
                                <div @click="prevItem" v-if="currentIndex < fileByZone?.length && currentIndex > 0">
                                    <div class="flex items-center">
                                        <v-icon name="arrow_back" />
                                        <div class="ml-2">File trước</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-5 text-[#1E293B] text-ellipsis overflow-hidden whitespace-nowrap">
                                <div class="font-bold leading-[28px]">{{ module?.title }}</div>
                                <div class="text-xs text-center font-italic">
                                    Click file tiếp theo/trước để chuyển nội dung đào tạo
                                </div>
                            </div>
                            <div class="ml-20 min-w-100px cursor-pointer">
                                <div @click="nextItem" v-if="currentIndex < fileByZone?.length - 1">
                                    <div class="flex items-center">
                                        <div class="mr-2">File tiếp theo</div>
                                        <v-icon name="arrow_forward" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #btnExcuteExam>
                        <div>
                            <dialog-upload-file
                                @nextAction="nextAction"
                                :prev-exam-module="prevExamModule"
                                :loading="loadingCreateExam"
                                :title="titleCheckExamRoom"
                                @check="statusExam"
                            ></dialog-upload-file>
                        </div>
                    </template>
                </process-module-train-header>
            </template>

            <c-learning-player
                @ended="ableToNextFile = true"
                ref="learningPlayer"
                posterType="ob"
                :key="currentItem.id"
                :currentItem="currentItem"
                @pause="updateCurrentTime"
            ></c-learning-player>
        </c-wrapp-learning>

        <div v-if="isShowDialogChooseDcr">
            <dialog-choose-dcr
                :is-retry="isRetry"
                @nextAction="createdExamRoom"
                @cancel-action="isShowDialogChooseDcr = false"
                :is-show-dialog="isShowDialogChooseDcr"
                :loadingCreateExam="loadingCreateExam"
            ></dialog-choose-dcr>
        </div>
        <div v-if="isShowDialogSuccessCreateExamRoom">
            <dialog-success-create-exam-room
                @next-action="showDialogExpiredTime"
                @cancel-action="isShowDialogSuccessCreateExamRoom = false"
                :is-show-dialog="isShowDialogSuccessCreateExamRoom"
            ></dialog-success-create-exam-room>
        </div>

        <div v-if="isShowDialogExpiredTime">
            <dialog-expired-time
                :time-start="startTime"
                :totalDcr="totalDcr"
                :dcrStartExam="dcrStartExam"
                @cancel-action="isShowDialogExpiredTime = false"
                :is-show-dialog="isShowDialogExpiredTime"
            ></dialog-expired-time>
        </div>
        <notification-dialogs />
    </div>
</template>
<script setup lang="ts">
import CLearningPlayer from '@/shared-fe/components/c-learning-player/c-learning-player.vue'
import CWrappLearning from '@/shared-fe/components/c-wrapp-learning/c-wrapp-learning.vue'
import { isFullScreen, toggleFullScreen } from '@/shared-fe/utils/convert-data'
import { useUserStore } from '@/stores/user'
import { notify } from '@/utils/notify'
import NotificationDialogs from '@/views/private/components/notification-dialogs.vue'
import dayjs from 'dayjs'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTrainingQuery } from '../../../../shared-fe/services/training-service'
import { EXAM_ROOM_STATUS, FORMAT_TIME, LOCAL_STOREGE } from '../../../../shared-fe/utils/constant'
import { PATH_ROUTER } from '../../../../shared-fe/utils/path'
import DialogChooseDcr from './components/dialog-choose-dcr.vue'
import DialogExpiredTime from './components/dialog-expired-time.vue'
import DialogSuccessCreateExamRoom from './components/dialog-success-create-exam-room.vue'
import DialogUploadFile from './components/dialog-upload-file.vue'
import ProcessModuleTrainHeader from './components/process-module-train-header.vue'

const imageId = ref('')
const isShowDialogChooseDcr = ref(false)
const isShowDialogSuccessCreateExamRoom = ref(false)
const isShowDialogExpiredTime = ref(false)
const route = useRoute()
const router = useRouter()
const { getModules, openTest, getExamRoom, getExamRoomNewToday } = useTrainingQuery()
const module = ref(<any>{})
const modules = ref(<any>[])
const userStore = useUserStore()
const loadingCreateExam = ref(false)
const titleCheckExamRoom = ref('')
const ableToNextFile = ref(<boolean>true)
const currentIndex = ref(0)
const file = ref(<any>null)
const learningPlayer = ref<any>(null)

const labelUserType = computed(() => {
    return userStore.currentUser.label_user_type
})

const nextItem = () => {
    if (currentItem.value.extension_file !== 'video' && !currentItem.value.url_embed) {
        currentIndex.value += 1
    } else {
        if (ableToNextFile.value) {
            currentIndex.value += 1
        } else {
            notify({
                title: 'Thông báo',
                text: 'Bạn cần xem hết video hoặc file trước mới có thể chuyển sang file tiếp theo',
                dialog: true,
                type: 'warning',
            })
        }
    }
}
const prevItem = () => {
    if (currentItem.value?.url_embed) {
        file.value[currentIndex.value].time = Number(learningPlayer.value?.getCurrentTime())
    }
    if (currentItem.value?.extension_file === 'video') {
        file.value[currentIndex.value].time = Number(learningPlayer?.value?.getCurrentTime() || 0)
    }
    currentIndex.value -= 1
}

const updateCurrentTime = (time: number) => {
    file.value[currentIndex.value].time = time
}

/**
 * because of the safari browser, when trigger full screen event, it will pause the video
 * trigger play video when full screen
 */
const handlePlayVideoWhenBrowserIsSafari = () => {
    learningPlayer.value.play()
}

document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
        learningPlayer.value?.pause()
    }
})

window.addEventListener('blur', () => {
    learningPlayer.value?.pause()
})

const isRetry = computed(() => {
    return route.name === PATH_ROUTER.PROCESS_MODULE_RETRY_TRAIN.NAME
})
const prevExamModule = ref(false)
const statusExam = async () => {
    loadingCreateExam.value = true
    if (module.value && modules.value?.length > 0) {
        if (!isRetry.value) {
            const index = modules.value?.findIndex((x) => x.id === parseInt(route.params?.module_id))
            if (index > 0) {
                const res = await getExamRoom(route.params?.session_train, modules.value[index - 1]?.id)
                if (res.data.data?.length <= 0) {
                    prevExamModule.value = false
                    loadingCreateExam.value = false
                    titleCheckExamRoom.value = 'Chưa hoàn thành module trước đó.'
                    return
                }
            }
            const res = await getExamRoomNewToday(route.params?.session_train)

            if (res.data.data?.length > 0) {
                const data = res.data.data
                let groupedModules = data.reduce((acc: any, item: any) => {
                    if (!acc[item.topic.id]) {
                        acc[item.topic.id] = {
                            title: item.topic.title,
                            users: [],
                        }
                    }
                    acc[item.topic.id].users.push(item)
                    return acc
                }, {})

                let resultGroupModules = Object.values(groupedModules)
                if (resultGroupModules.length >= 2) {
                    let title = 'Mỗi ngày chỉ mở thi tối đa 2 module\n'
                    resultGroupModules.map((item: any) => {
                        title += `- Tên module đã mở: ${item?.title}\n`
                        item.users.map((x: any) => {
                            title += `- ${labelUserType.value} đã mở: ${x?.user?.full_name}\n`
                        })
                    })
                    prevExamModule.value = false
                    titleCheckExamRoom.value = title
                    loadingCreateExam.value = false
                    return
                }
            }
        }
        prevExamModule.value = true
        titleCheckExamRoom.value = ''
        loadingCreateExam.value = false
        return
    }
    loadingCreateExam.value = false
}

getModules(route.params?.session_train.toString()).then((res) => {
    modules.value = []
    module.value = {}
    if (res.data.data?.course?.topics?.length > 0) {
        modules.value = res.data.data?.course?.topics
        module.value = modules.value.find((x) => x.id === parseInt(route.params?.module_id.toString()))
    }
})
const fileByZone = computed(() => {
    let filesTemp: any = []
    let filesArr: any[] = []

    if (!file.value) {
        filesTemp = module.value?.file_by_zones?.find((x) => x.zone === userStore.currentUser?.zone?.id)

        if (!filesTemp) {
            filesTemp = module.value.file_by_zones?.find((x) => !x.zone)
        }

        if (!filesTemp) return []
    } else {
        filesTemp = file.value
        return filesTemp
    }

    if (filesTemp.url_embed) {
        filesArr.push({
            id: 'url_embed',
            url_embed: filesTemp.url_embed,
            extension_file: 'url_embed',
            time: 0,
        })
    }

    filesTemp?.files?.forEach((file: any) => {
        const fileModel = file.directus_files_id
        const _itemFile = {
            id: fileModel.id,
            url_embed: null,
            time: 0,
            extension_file: checkExtensionFile(fileModel.type, fileModel.filename_disk),
        }

        if (fileModel.video_embed) {
            _itemFile.url_embed = fileModel.video_embed
            _itemFile.extension_file = 'url_embed'
        }

        filesArr.push(_itemFile)
    })

    file.value = filesArr
    return filesArr
})

const currentItem = computed(() => {
    return fileByZone.value[currentIndex.value] ?? []
})

watch(
    () => currentItem.value,
    () => {
        if (currentItem.value.url_embed || currentItem.value.extension_file === 'video') {
            ableToNextFile.value = false
        }
    }
)

watch(
    () => fileByZone.value,
    () => {
        if (fileByZone.value?.length > 0) {
            let idFile = localStorage.getItem(`${LOCAL_STOREGE.ONBOARDING}_${module.value?.id}`)
            currentIndex.value = 0
            if (idFile) {
                currentIndex.value = fileByZone.value?.findIndex((x) => x?.id === idFile)
            }
        }
    },
    {
        immediate: true,
    }
)

watch(
    () => [currentIndex.value],
    () => {
        localStorage.setItem(`${LOCAL_STOREGE.ONBOARDING}_${module.value?.id}`, currentItem.value?.id)
    }
)

const checkExtensionFile = (type: string, fileName: string) => {
    if (!fileName) return ''
    if (type.includes('video')) {
        return 'video'
    }
    let res: any = fileName.split('.')
    if (res.length > 0) {
        return res[res.length - 1].toString().toLowerCase()
    }
}

const nextAction = (data) => {
    imageId.value = data
    isShowDialogChooseDcr.value = true
    if (isFullScreen()) {
        toggleFullScreen()
    }
}
const dcrStartExam = ref(0)
const totalDcr = ref(0)
const startTime = ref('')

onBeforeUnmount(() => {
    if (isFullScreen()) {
        toggleFullScreen()
    }
})

const createdExamRoom = async ({ _dcrStartExam, _totalDcr }) => {
    if (_dcrStartExam.length > 0) {
        let params = {}
        loadingCreateExam.value = true
        for (let item of _dcrStartExam) {
            params = {
                status: EXAM_ROOM_STATUS.OPENED,
                topic: module.value?.id,
                image: imageId.value,
                user: item?.id,
                session_train: route.params.session_train,
                retry: isRetry.value,
            }
            await openTest(params)
            let id = route.params?.session_train + '_' + String(route.params?.module_id)
            localStorage.removeItem('ONBOARDING_MODULE' + id)
        }

        loadingCreateExam.value = false
        isShowDialogChooseDcr.value = false
        prevExamModule.value = true

        isShowDialogSuccessCreateExamRoom.value = true
        dcrStartExam.value = _dcrStartExam.length
        totalDcr.value = _totalDcr.length
        startTime.value = dayjs().format(FORMAT_TIME.DDMMYYYYhmm)
    }
}
const showDialogExpiredTime = () => {
    isShowDialogSuccessCreateExamRoom.value = false
    isShowDialogExpiredTime.value = true
}
const goBack = () => {
    router.push({
        name: PATH_ROUTER.DCR_ONBOARDING_DETAIL.NAME,
        params: {
            session_train: route?.params?.session_train,
        },
    })
}
</script>
<style lang="scss">
.custom-h {
    height: calc(100vh - 72px - 40px);
}
</style>
