<template>
    <v-overlay v-if="items" :active="props.overlay">
        <div class="py-8 px-10 bg-white rounded-md max-h-[90vh] overflow-y-scroll">
            <h2 class="type-title">Yêu cầu tuyển dụng</h2>
            <v-input
                class="my-4"
                :model-value="inputSearch"
                @update:model-value="handleSearch"
                placeholder="Nhập mã YCTD, mã route, npp, người tạo"
            >
                <template #prepend>
                    <v-icon name="search" outline />
                </template>
            </v-input>
            <recruitment-request-table
                :items="itemsRender"
                :modelSelected="modelSelected"
                @update:modelSelected="modelSelectedFun"
                :totalPages="totalPages"
                :page="page"
                :loading="loading"
                @update:toPage="handleToPage"
                @updateSort="handleUpdateSort"
            ></recruitment-request-table>
            <div class="flex gap-x-6 flex justify-end flex-row mt-10">
                <v-button class="btn-modal w-1/4" secondary @click="emit('close')">Hủy bỏ</v-button>
                <v-button class="btn-modal w-1/4" @click="handleSubmit">Xác nhận</v-button>
            </div>
        </div>
    </v-overlay>
</template>

<script setup lang="ts">
import { useItems } from '@directus/composables'
import { computed, ref, watch } from 'vue'
import { useBatch } from '../../../../../shared-fe/composables/use-batch'
import { DELETE_VALUE, RECORD_PER_PAGE, RECRUITMENT_REQUEST_STATUS } from '../../../../../shared-fe/utils/constant'
import { RECRUITMENT_REQUEST_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import RecruitmentRequestTable from '../components/recruitment-request-table.vue'

const props = defineProps({
    overlay: {
        type: Boolean,
        default: false,
    },
    selection: {
        type: Boolean,
        default: false,
    },
})

const sort = ref(['-id'])
const search = ref(null)
const inputSearch = ref(null)
const filter = ref(<any>null)
const page = ref(1)

const modelSelected = ref([])
const emit = defineEmits(['input', 'close'])

const customFilter = computed(() => {
    let _filter = {
        _and: [
            {
                _or: [
                    {
                        status: {
                            _eq: RECRUITMENT_REQUEST_STATUS.pending.value,
                        },
                    },
                    {
                        status: {
                            _eq: RECRUITMENT_REQUEST_STATUS.processing.value,
                        },
                    },
                ],
            },
        ],
    }

    if (filter.value) {
        return {
            ...filter.value,
        }
    }
    return _filter
})

const { items, totalPages, loading, getItems, getTotalCount, getItemCount } = useItems(
    ref(RECRUITMENT_REQUEST_COLLECTION),
    {
        fields: ref([
            'providers.zone.name',
            'providers.name',
            'user_created.full_name',
            'staff_id.route_code.name',
            'id',
        ]),
        limit: ref(RECORD_PER_PAGE),
        sort,
        search,
        filter: customFilter,
        page: page,
    }
)

const itemsRender = computed(() => {
    return items.value.map((item) => {
        return {
            id: item?.id,
            recruitment_request_person: item?.user_created?.full_name,
            zone: item?.providers?.zone?.name,
            provider_name: item?.providers?.name,
            staff_route_code: item?.staff_id?.route_code?.name,
        }
    })
})

const handleSubmit = () => {
    if (modelSelected.value && modelSelected.value.length > 0) {
        emit('input', modelSelected.value)
    }
    emit('close')
}

const handleToPage = (_toPage: number) => {
    page.value = _toPage
}

const handleUpdateSort = (sortKey) => {
    sort.value = sortKey
}
const handleSearch = (_data) => {
    const data = JSON.parse(JSON.stringify(_data))
    filter.value = null
    search.value = null
    if (data) {
        let _and = [
            {
                _or: [
                    {
                        status: {
                            _eq: RECRUITMENT_REQUEST_STATUS.pending.value,
                        },
                    },
                    {
                        status: {
                            _eq: RECRUITMENT_REQUEST_STATUS.processing.value,
                        },
                    },
                ],
            },
        ]

        let _filter: any = {
            _and: [
                ..._and,
                {
                    _or: [
                        {
                            providers: {
                                name: {
                                    _contains: data,
                                },
                            },
                        },
                        {
                            staff_id: {
                                route_code: {
                                    name: {
                                        _contains: data,
                                    },
                                },
                            },
                        },
                        {
                            user_created: {
                                full_name: {
                                    _contains: data,
                                },
                            },
                        },
                        {
                            providers: {
                                zone: {
                                    name: {
                                        _contains: data,
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        }

        if (!isNaN(Number(data))) {
            search.value = data
        } else {
            filter.value = _filter
        }
    }
}

const modelSelectedFun = (data: any) => {
    modelSelected.value = [data]
}

watch(
    () => props.selection,
    () => {}
)
</script>

<style lang="scss"></style>
