import { useApi } from '@directus/extensions-sdk'
export const useProvinceQuery = () => {
    const api = useApi()
    const getAllProvinceItem = async () => {
        return await api.get('/items/provinces', {
            params: {
                limit: -1,
                fields: ['*', 'districts.*'],
                sort: ['name'],
            },
        })
    }

    return {
        getAllProvinceItem,
    }
}
