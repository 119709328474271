<template>
    <div class="mx-9 flex justify-between mb-1 mb-6" v-if="editMode">
        <div>
            <span class="font-bold text-[var(--foreground-normal-alt)]">Ngày tạo đơn:</span>
            <span class="ml-2">
                {{ itemDisplay?.date_created ? dayjs(itemDisplay?.date_created).format(FORMAT_TIME.DDMMYYYY) : '' }}
            </span>
        </div>
    </div>
    <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div class="col-span-1">
                <div class="type-label">
                    Họ và tên nhân viên
                    <span class="required">*</span>
                </div>
                <template v-if="editMode">
                    <v-input type="text" disabled :model-value="detailUser?.full_name"></v-input>
                </template>
                <template v-else>
                    <v-select
                        showDeselect
                        v-model:model-value="formData.idUser"
                        required
                        :disabled="editMode"
                        :items="userLst"
                        @update:model-value="(e) => handleChange(e, 'idUser')"
                    ></v-select>
                    <span class="message-error" v-if="errors?.idUser">Vui lòng chọn nhân viên</span>
                </template>
            </div>
            <div class="col-span-1">
                <div class="type-label">Ngày sinh</div>
                <v-input
                    type="text"
                    disabled
                    :model-value="
                        detailUser?.date_of_birth ? dayjs(detailUser?.date_of_birth).format(FORMAT_TIME.DDMMYYYY) : ''
                    "
                ></v-input>
            </div>
            <template v-if="editMode">
                <div class="col-span-1">
                    <div class="type-label">Điện thoại</div>
                    <v-input type="text" disabled :model-value="detailUser?.phone_number"></v-input>
                </div>

                <div class="col-span-1">
                    <div class="type-label">Mã đơn</div>
                    <v-input type="text" disabled :model-value="detailUser?.idResign"></v-input>
                </div>

                <div class="col-span-1">
                    <div class="type-label">Trạng thái</div>
                    <v-input type="text" disabled :model-value="detailUser?.status_name"></v-input>
                </div>
            </template>

            <div class="col-span-1">
                <div class="type-label">Người phụ trách</div>
                <v-input type="text" disabled :model-value="detailUser?.hr_name"></v-input>
            </div>

            <div class="col-span-1">
                <div class="type-label">Chi nhánh</div>
                <v-input type="text" disabled :model-value="detailUser?.zone"></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">Khu vực</div>
                <v-input type="text" disabled :model-value="detailUser?.area"></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">Nhà phân phối</div>
                <v-input type="text" disabled :model-value="detailUser?.system_providers"></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">SS</div>
                <v-input type="text" disabled :model-value="detailUser?.ss"></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">Mã Route</div>
                <v-input type="text" disabled :model-value="detailUser?.route_code"></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">Mã DCR</div>
                <v-input type="text" disabled :model-value="detailUser?.code"></v-input>
            </div>
            <div class="col-span-1">
                <div class="type-label">Ngày bắt đầu làm việc</div>
                <v-input type="text" disabled :model-value="detailUser?.first_working_day"></v-input>
            </div>

            <div class="col-span-1">
                <div class="type-label">
                    Ngày làm việc cuối cùng
                    <span class="required">*</span>
                </div>
                <v-menu ref="dateTimeMenuLastWorkingDate" :close-on-content-click="false" attached full-height seamless>
                    <template #activator="{ toggle, active }">
                        <v-input
                            :active="active"
                            clickable
                            readonly
                            :disabled="disabledEdit"
                            :model-value="formData.last_working_day_display"
                            placeholder=""
                            @click="toggle"
                        >
                            <template #append>
                                <v-icon
                                    :name="formData.last_working_day ? 'clear' : 'today'"
                                    :class="{
                                        active,
                                        'clear-icon': formData.last_working_day_display,
                                        'today-icon': !formData.last_working_day_display,
                                    }"
                                    v-on="{ click: formData.last_working_day ? unsetValueLastWorkingDate : null }"
                                />
                            </template>
                        </v-input>
                    </template>

                    <c-date-picker
                        type="date"
                        :include-seconds="true"
                        :use-24="true"
                        :disableSunday="true"
                        :specificdays="customSpecificDay"
                        :model-value="formData.last_working_day"
                        :minDate="minStartDate"
                        @update:model-value="(e) => handleChange(e, 'last_working_day')"
                        @close="dateTimeMenuLastWorkingDate?.deactivate"
                    />
                </v-menu>
                <span class="message-error" v-if="errors?.last_working_day">Vui lòng nhập ngày làm việc cuối cùng</span>
            </div>

            <div class="col-span-1">
                <c-select-m2o
                    collection="options"
                    v-model:model-value="formData.reason"
                    field_text="opt_value"
                    field_value="id"
                    label="Lý do nghỉ việc"
                    required
                    :query-filter="{ filter: queryResignReason, sort: ['opt_value'] }"
                    :disabled="editMode"
                    @update:model-value="(e) => handleChange(e, 'reason')"
                ></c-select-m2o>
                <span class="message-error" v-if="errors?.reason">Vui lòng nhập lý do nghỉ việc</span>
            </div>

            <div class="col-span-1 lg:col-span-3">
                <div class="type-label">
                    Chi tiết lý do
                    <span class="required">*</span>
                </div>
                <v-input
                    v-model="formData.note"
                    :disabled="editMode"
                    @update:model-value="(e) => handleChange(e, 'note')"
                ></v-input>
                <span class="message-error" v-if="errors?.note">Vui lòng nhập chi tiết lý do</span>
            </div>
        </div>
        <div class="my-8"></div>
        <div class="grid grid-cols-1 gap-8">
            <div class="col-span-1">
                <div class="type-label">Biên bản làm việc/ Giấy tờ liên quan</div>
                <interface-files
                    :collection="RESIGN_COLLECTION"
                    :enableCreate="true"
                    :enableSelect="false"
                    :disabled="disabledEdit"
                    field="attachments"
                    :folder="undefined"
                    :limit="15"
                    :primaryKey="formData.idResign"
                    :template="null"
                    v-model:value="formData.attachments"
                    @input="handleChangeUploadFile"
                ></interface-files>
                <span class="message-error" v-if="errors?.attachments">Vui lòng tải file đính kèm</span>
            </div>
        </div>
    </div>
    <v-dialog v-model="showDialogGotoRR" @esc="goToDetail" placement="center">
        <v-card>
            <div class="flex flex-col items-center justify-center px-28px">
                <div class="text-14px text-[#64748B] mt-10">
                    Bạn có muốn tạo yêu cầu tuyển dụng thay thế DCR này không?
                </div>
                <v-card-actions>
                    <div class="flex gap-6">
                        <v-button secondary @click="goToDetail">Đóng</v-button>
                        <v-button @click="gotoRR">Đồng ý</v-button>
                    </div>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
    <c-access :roles="[USER_ROLE.HR]">
        <div v-if="itemDisplay">
            <div class="info-request font-bold text-[var(--foreground-normal-alt)] text-6 my-8 mx-9">
                Phỏng vấn nghỉ việc
            </div>
            <div class="form-data px-[1.4rem] py-[1.7rem] rounded-lg mx-9 grid grid-cols-1 gap-8">
                <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div class="col-span-1">
                        <c-select-m2o
                            collection="options"
                            v-model:model-value="formData.interview"
                            field_text="opt_value"
                            field_value="id"
                            label="Lý do nghỉ việc"
                            :query-filter="{ filter: reasonOfHR, sort: ['opt_value'] }"
                            @update:model-value="(e) => handleChange(e, 'interview')"
                        ></c-select-m2o>
                        <span class="message-error" v-if="errors?.interview">Vui lòng nhập lý do nghỉ việc</span>
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-8">
                    <div class="col-span-1">
                        <div class="type-label">Chi tiết lý do</div>
                        <v-textarea
                            :model-value="formData.interview_note"
                            @update:model-value="(e) => handleChange(e, 'interview_note')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </c-access>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import Joi from 'joi'
import _ from 'lodash'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import CAccess from '../../../../../shared-fe/components/c-access/c-access.vue'
import CSelectM2o from '../../../../../shared-fe/components/c-select-m2o/c-select-m2o.vue'
import usePermission from '../../../../../shared-fe/composables/use-permission'
import { useResignQuery } from '../../../../../shared-fe/services/resign-service'
import { useUserQuery } from '../../../../../shared-fe/services/user-service'
import { useStoreCommon } from '../../../../../shared-fe/stores/common'
import {
    FORMAT_TIME,
    MODE_EDIT,
    RESIGN_STATUS,
    SUBMIT_EVENT,
    USER_ROLE,
    SPECIFIC_DAYS,
    RESIGN_HISTORY_STATUS,
} from '../../../../../shared-fe/utils/constant'
import { displayParentName } from '../../../../../shared-fe/utils/convert-data'
import { PATH_ROUTER } from '../../../../../shared-fe/utils/path'
import { RESIGN_COLLECTION } from '../../../../../shared-fe/utils/table-name'
import CDatePicker from '../../../../../shared-fe/components/c-datepicker/c-datepicker.vue'
import { useResignHistoryQuery } from '../../../../../shared-fe/services/resign-history-service'
import { useOptionQuery } from '../../../../../shared-fe/services/option-service'
import { useSettingQuery } from '../../../../../shared-fe/services/setting-service'
import { notify } from '@/utils/notify'
import { useUserStore } from '@/stores/user'

const dataDefault = {
    idResign: 0,
    idUser: null,
    last_working_day: null,
    last_working_day_display: null,
    reason: null,
    interview: null,
    interview_note: null,
    note: null,
}
const { postItemResignHistory } = useResignHistoryQuery()
const router = useRouter()
const { getUserByUserId, getUsersWithFilterByResign } = useUserQuery()
const commonStore = useStoreCommon()
const { patchItemResign, postItemResign } = useResignQuery()
const { canAccess } = usePermission()
const showDialogGotoRR = ref(false)
const createResignId = ref(-1)
const fullAccessCreate = computed(() => {
    return canAccess([USER_ROLE.SS, USER_ROLE.ASM])
})
const fullAccessEdit = computed(() => {
    return canAccess([USER_ROLE.HR])
})
const errors: any = ref(null)
const dateTimeMenuLastWorkingDate = ref()
const defaultDataEdit = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const formData = ref(<any>JSON.parse(JSON.stringify(dataDefault)))
const props = defineProps({
    dataForm: {
        type: Object,
        default: null,
    },
    itemDisplay: {
        type: Object,
        default: null,
    },
})
const userStore = useUserStore()
const userLst = ref(<any>[])
getUsersWithFilterByResign().then((res) => {
    userLst.value = []
    if (res?.data?.data?.length > 0) {
        res?.data?.data.forEach((element) => {
            if (element?.resigns.length <= 0) {
                userLst.value.push({
                    text: element?.full_name,
                    value: element?.id,
                })
            }
        })
    }
})

const reasonOfHR = computed(() => {
    const _fixedConditions = [
        {
            opt_group: 'resignation_reason',
            is_shown: true,
        },
    ]

    let _hasId = []
    if (formData.value.reason) {
        _hasId = [
            {
                id: {
                    _in: [formData.value.reason],
                },
            },
        ]
    }

    return {
        _or: [..._fixedConditions, ..._hasId],
    }
})

const queryResignReason = computed(() => {
    let filter = {}
    let _fixedConditions = []
    let _hasId = []
    if (fullAccessCreate.value) {
        _fixedConditions = [
            {
                opt_group: 'resignation_reason',
                is_shown: true,
                id: {
                    _neq: 3456,
                },
            },
        ]
    } else if (fullAccessEdit.value) {
        _fixedConditions = [
            {
                opt_group: 'resignation_reason',
                is_shown: true,
            },
        ]
    }

    if (formData.value.reason) {
        _hasId = [
            {
                id: {
                    _in: [formData.value.reason],
                },
            },
        ]
    }

    filter = {
        _or: [..._fixedConditions, ..._hasId],
    }

    return filter
})

const disabledEdit = computed(() => {
    // if perrmission is ASM OR SS (for create mode) => enable edit field
    if (!props.itemDisplay && fullAccessCreate.value) return false
    // if perrmission is diffirence HR or Admin (for edit mode) => enable edit field
    return props.itemDisplay?.status === RESIGN_STATUS.rejected || !fullAccessEdit.value
})

const editMode = computed(() => {
    return props.itemDisplay ? true : false
})

const detailUser = ref(<any>props.itemDisplay)

const schemaEdit = Joi.object({
    last_working_day: Joi.date().required(),
}).unknown(false)

const schemaAdd = Joi.object({
    reason: Joi.number().required(),
    note: Joi.string().required(),
    last_working_day: Joi.date().required(),
}).unknown(false)

const handleChange = (e: any, variable: any) => {
    switch (variable) {
        case 'last_working_day':
            formData.value.last_working_day_display = e ? dayjs(e).format(FORMAT_TIME.DDMMYYYY) : ''
            formData.value[variable] = e || ''
            break
        case 'idUser':
            getInforUser(e)
            formData.value[variable] = e
            break
        default:
            formData.value[variable] = e
            break
    }
}

const getInforUser = async (userId: string) => {
    if (userId) {
        let res = await getUserByUserId(userId)
        let data = res?.data.data
        detailUser.value = {
            idResign: -1,
            idUser: data?.id,
            date_of_birth: data?.date_of_birth,
            phone_number: data?.phone_number,
            zone: data?.zone?.name,
            area: data?.area?.name,
            system_providers: data?.system_providers?.length > 0 ? data?.system_providers[0].provider_id?.name : '',
            ss: data.parent_id ? displayParentName(data.parent_id?.role?.short_name, data.parent_id?.full_name) : '',
            code: data?.code,
            route_code: data?.route_code?.name,
            first_working_day: data?.start_date ? dayjs(data?.start_date).format(FORMAT_TIME.DDMMYYYY) : '',
            status: data?.status,
            system_provider_id: data?.system_providers?.length > 0 ? data?.system_providers[0].provider_id : '',
        }
    } else {
        detailUser.value = {
            idResign: '',
            idUser: '',
            date_of_birth: '',
            phone_number: '',
            zone: '',
            area: '',
            system_providers: '',
            ss: '',
            code: '',
            route_code: '',
            first_working_day: '',
            status: '',
            system_provider_id: '',
        }
    }
}

const handleChangeUploadFile = (data) => {
    formData.value.attachments = data
}

const initData = async () => {
    // fill data to form
    for (const [key, value] of Object.entries(formData.value)) {
        formData.value[key] = props.dataForm[key]
    }
    formData.value.last_working_day_display = dayjs(formData.value.last_working_day).format(FORMAT_TIME.DDMMYYYY)

    defaultDataEdit.value = JSON.parse(JSON.stringify(formData.value))
}

watch(
    () => props.dataForm,
    () => {
        if (props.dataForm) {
            initData()
        } else {
            formData.value.idResign = '+'
        }
    },
    { immediate: true }
)

const arrStatusResign = ref()

onMounted(async () => {
    const options = userStore.currentUser.options
    let _arrStatus: any = []
    if (options.length > 0) {
        options.forEach((x) => {
            if (x.opt_group === 'resignation_reason') {
                _arrStatus.push({
                    opt_key: x.opt_key,
                    opt_value: x.opt_value,
                    require_attachments: x.require_attachments,
                    id: x.id,
                })
            }
        })
    }
    arrStatusResign.value = _arrStatus
})

const unsetValueLastWorkingDate = (e) => {
    formData.value.last_working_day = ''
    formData.value.last_working_day_display = ''
    unsetValue(e)
}
const unsetValue = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
}

const validateFullName = () => {
    if (!editMode.value) {
        if (!formData.value.idUser) {
            return { idUser: 'idUser is required' }
        }
    }
    return {}
}

const validateInterview = () => {
    if (editMode.value) {
        if (formData.value.interview_note && !formData.value.interview) {
            return { interview: 'idUser is required' }
        }
    }
    return {}
}
const REASON_RESIGN_REQUIRED_ATTACHMENTS = ['']
const validateAttachments = () => {
    if (
        arrStatusResign.value?.find((x) => x.id === formData.value.reason && x.require_attachments === true) &&
        !editMode.value
    )
        if (!formData.value.attachments) {
            return { attachments: 'attachments is required' }
        }
    return {}
}
const submitForm = async (payload: any) => {
    let payloadSubmitForm = payload.args.find((x) => x.name === SUBMIT_EVENT.RESIGN_DETAIL_SUBMIT)
    if (payloadSubmitForm) {
        errors.value = null
        const { idResign, idUser, interview_note, attachments, last_working_day_display, interview, ...res } =
            formData.value

        let error
        if (payloadSubmitForm.payload === MODE_EDIT) {
            delete res.reason
            delete res.note
            const schema = schemaEdit.validate(res, { abortEarly: false })
            error = schema.error
        } else {
            const schema = schemaAdd.validate(res, { abortEarly: false })
            error = schema.error
        }

        if (error) {
            errors.value = error.details.reduce((acc, curr) => {
                acc[curr.path[0]] = curr.message
                return acc
            }, {})
        }

        errors.value = {
            ...errors.value,
            ...validateFullName(),
            ...validateInterview(),
            ...validateAttachments(),
        }

        if (Object.keys(errors.value).length !== 0) {
            return
        }
        errors.value = {}
        let params = JSON.parse(JSON.stringify(formData.value))

        if (!params.attachments?.create) {
            delete params.attachments
        }

        if (!params.last_working_day) {
            delete params.last_working_day
        }
        if (payloadSubmitForm.payload === MODE_EDIT) {
            delete params.idResign
            delete params.idUser
            delete params.last_working_day_display
            delete res.note
            delete res.reason
            await patchItemResign(formData.value.idResign, params)
            commonStore.showDialogSucessUpdate = true
        } else {
            delete params.idResign
            params.first_working_day = detailUser.value?.first_working_day
                ? dayjs(detailUser.value?.first_working_day.split('/').reverse().join('/')).format(FORMAT_TIME.YYYMMDD)
                : null
            params.staff_id = detailUser.value.idUser
            params.status = RESIGN_STATUS.pending.value
            commonStore.loading = true
            let res = await postItemResign(params)
            await postItemResignHistory({
                note: params.note,
                resign: res?.data?.data?.id,
                reason: arrStatusResign.value?.find((x: any) => x.id === params.reason)?.opt_value,
                status: RESIGN_HISTORY_STATUS.created.value,
            })

            setTimeout(() => {
                commonStore.loading = false
                showDialogGotoRR.value = true
                createResignId.value = res?.data?.data?.id
            }, 2000)
            return
        }
    }
}

const unsubscribe = commonStore.$onAction(submitForm, true)
onBeforeUnmount(() => {
    unsubscribe()
})

const minStartDate = ref('')
const { getProjectSetting } = useSettingQuery()
const customSpecificDay = ref()
getProjectSetting().then((res) => {
    if (res?.data?.data) {
        customSpecificDay.value = res?.data?.data?.holidays
            ?.split(',')
            ?.map((x) => x.replace('\n', '').split('/').reverse().join('-'))
    }
})

function findStartDate(countDate, countResult) {
    let date = dayjs().add(countDate, 'day').format(FORMAT_TIME.YYYMMDD)
    // skip => the date is the specific day
    let resultDate = customSpecificDay.value?.find((x) => x === date)
    // skip => the date is the weekend
    if (new Date(dayjs().add(countDate, 'day')).getDay() !== 0 && !resultDate) {
        countResult++
    }

    if (countResult == 3) {
        minStartDate.value = date
        return
    }

    countDate--
    findStartDate(countDate, countResult)
}

watch(
    () => customSpecificDay.value,
    () => {
        if (!userStore.currentUser?.role.admin_access && userStore.currentUser?.role.short_name !== USER_ROLE.HR) {
            findStartDate(-1, 0)
        }
    },
    {
        immediate: true,
    }
)

const goToDetail = () => {
    showDialogGotoRR.value = false
    router.push({
        name: PATH_ROUTER.RESIGN_DETAIL.NAME,
        params: {
            id: createResignId.value,
        },
    })
}

const gotoRR = () => {
    showDialogGotoRR.value = false
    router.push({
        name: PATH_ROUTER.RECRUITMENT_REQUEST_ADD.NAME,
        query: {
            id_resign: createResignId.value,
        },
    })
}
</script>
