import { useApi } from '@directus/extensions-sdk'
import { PROBATIONARY_ASSESSMENTS_COLLECTION } from '../utils/table-name'
export const useProbationaryAssessmentQuery = () => {
    const api = useApi()
    const patchItemProbationaryAssessment = async (id: number, params: any) => {
        return await api.patch(`/items/${PROBATIONARY_ASSESSMENTS_COLLECTION}/${id}`, params)
    }
    const getItemProbationaryAssessment = async (id: any) => {
        return await api.get(`/items/${PROBATIONARY_ASSESSMENTS_COLLECTION}/${id}`, {
            params: {
                fields: [
                    'id',
                    'dcr.zone.name',
                    'dcr.area.name',
                    'dcr.system_providers.provider_id.name',
                    'dcr.parent_id.full_name',
                    'dcr.parent_id.role.short_name',
                    'dcr.code',
                    'dcr.route_code.id',
                    'dcr.route_code.name',
                    'dcr.full_name',
                    'start_date',
                    'end_date',
                    'asm_status',
                    'asm_note',
                    'work_day_status',
                    'work_day',
                    'work_attitude',
                    'work_attitude_status',
                    'compliance_status',
                    'compliance',
                    'execution_point',
                    'execution_point_status',
                    'sales_volume',
                    'sales_volume_status',
                    'percentage_total',
                    'ss_note',
                    'ss_status',
                ],
            },
        })
    }
    return {
        patchItemProbationaryAssessment,
        getItemProbationaryAssessment,
    }
}
