import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

export const useConfirmModal = defineStore('useConfirmModal', () => {
    const title: Ref<string> = ref('')
    const isShowModal: Ref<boolean> = ref(false)
    const isShowCloseBtn: Ref<boolean> = ref(true)
    const action: Ref<() => void> = ref(() => { })
    const isShowWarningModal: Ref<boolean> = ref(false)

    const handleOpenConfirmModal = (titleData: string, actionData: () => void, showCloseBtn: boolean = true) => {
        title.value = titleData
        isShowModal.value = true
        action.value = actionData
        isShowCloseBtn.value = showCloseBtn
    }

    const handleCloseModal = () => {
        isShowModal.value = false
        isShowWarningModal.value = false
        title.value = ''
        action.value = () => { }
        isShowCloseBtn.value = true
    }

    const handleConfirm = () => {
        action.value()
        handleCloseModal()
    }

    const handleOpenWarningModal = (titleData: string) => {
        isShowWarningModal.value = true
        title.value = titleData || 'Có lỗi xảy ra, vui lòng thử lại sau'
    }

    return {
        title,
        isShowModal,
        isShowWarningModal,
        isShowCloseBtn,
        handleOpenConfirmModal,
        handleCloseModal,
        handleConfirm,
        handleOpenWarningModal
    }
})
