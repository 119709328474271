<template>
    <span
        :style="{
            background: objData?.background,
            color: objData?.color,
            border: objData?.background === '#fff' ? `1px solid ${objData?.color}` : '',
        }"
        class="px-2 py-1 rounded-2 inline-block"
    >
        {{ objData?.text }}
    </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import {
    RECRUITMENT_COLLECTION,
    RECRUITMENT_REQUEST_COLLECTION,
    USER_COLLECTION,
    CANDIDATE_COLLECTION,
    RESIGN_COLLECTION,
    PROBATIONARY_ASSESSMENTS_COLLECTION,
    SESSION_TRAINS_COLLECTION,
    INTERVIEW_DETAIL_COLLECTION,
    RESIGN_HISTORY_COLLECTION,
    EXEMPTION_CERTIFICATE_COLLECTION,
    MODIFY_ONBOARDING_COLLECTION,
} from '../../../utils/table-name'
import {
    RECRUITMENT_STATUS,
    NOT_FOUND_STATUS,
    RECRUITMENT_REQUEST_STATUS,
    CANDIDATE_STATUS,
    USER_STATUS,
    RESIGN_STATUS,
    PROBATIONARY_ASSESSMENTS_STATUS,
    SESSIONS_TRAIN_STATUS,
    INTERVIEW_DETAIL_STATUS,
    RESIGN_HISTORY_STATUS,
    EXEMPTION_CERTIFICATE_STATUS,
    CANDIDATES_FILTER_PROFILE,
MODIFY_ONBOARD_STATUS,
} from '../../../utils/constant'

interface Props {
    collectionName: string
    id: any
}
const props = withDefaults(defineProps<Props>(), {
    collectionName: '',
    value: '',
})

const definePropertiesCollection: Array<any> = [
    {
        collection_name: RECRUITMENT_COLLECTION,
        collection_status: RECRUITMENT_STATUS,
    },
    {
        collection_name: RECRUITMENT_REQUEST_COLLECTION,
        collection_status: RECRUITMENT_REQUEST_STATUS,
    },
    {
        collection_name: CANDIDATE_COLLECTION,
        collection_status: CANDIDATE_STATUS,
    },
    {
        collection_name: USER_COLLECTION,
        collection_status: USER_STATUS,
    },
    {
        collection_name: RESIGN_COLLECTION,
        collection_status: RESIGN_STATUS,
    },
    {
        collection_name: PROBATIONARY_ASSESSMENTS_COLLECTION,
        collection_status: PROBATIONARY_ASSESSMENTS_STATUS,
    },
    {
        collection_name: SESSION_TRAINS_COLLECTION,
        collection_status: SESSIONS_TRAIN_STATUS,
    },
    {
        collection_name: INTERVIEW_DETAIL_COLLECTION,
        collection_status: INTERVIEW_DETAIL_STATUS,
    },
    {
        collection_name: RESIGN_HISTORY_COLLECTION,
        collection_status: RESIGN_HISTORY_STATUS,
    },
    {
        collection_name: INTERVIEW_DETAIL_COLLECTION,
        collection_status: INTERVIEW_DETAIL_STATUS,
    },
    {
        collection_name: EXEMPTION_CERTIFICATE_COLLECTION,
        collection_status: EXEMPTION_CERTIFICATE_STATUS,
    },
    {
        collection_name: CANDIDATE_COLLECTION + '2',
        collection_status: CANDIDATES_FILTER_PROFILE,
    },
    {
        collection_name: MODIFY_ONBOARDING_COLLECTION,
        collection_status: MODIFY_ONBOARD_STATUS,
    },
]
const objData = computed(() => {
    let indexFind = definePropertiesCollection.findIndex((x: any) => x.collection_name === props.collectionName)
    if (indexFind >= 0) {
        if (definePropertiesCollection[indexFind].collection_status[props.id]) {
            return definePropertiesCollection[indexFind].collection_status[props.id]
        }
    }
    return ''
})
</script>
